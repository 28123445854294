import { Alert, Form, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import { useGetAllAirportQuery } from '../../Configuration/airport/endpoints/airportEndpoints';
import AirticketPaxPassportDetails from '../../IATA_AIR_TICKET/Components/AirticketPaxPassportDetails';
import { useGetInvoiceNoQuery } from '../../Invoice(Visa)/Api/Endpoints/invoiceVisaEndpoints';
import InvNonComFlightDetails from '../../Invoice_Non_Comission/components/InvNonComFlightDetails';
import InvNonComFormHeader from '../../Invoice_Non_Comission/components/InvNonComFormHeader';
import EditverviewReIssue from '../Components/EditverviewReIssue';
import InvReIssueTicketInfo from '../Components/InvReIssueTicketInfo';
import {
  IEditReissue,
  IReissueFormSubmit,
} from '../Type/InoviceReissue.interface';
import { useGetSingleReIssueInvoiceQuery } from '../api/endpoints/reIssueAirTicketInvoiceEndpoints';
import { getReIssueInvoiceFormat } from '../utils/reissueFormatDataForPost';

type Props = {};

const Edit_Invoice_Re_Issue = ({}: Props) => {
  // @States
  const [invoices, setInvoices] = useState<IReissueFormSubmit[]>([]);
  const [editIndex, setEditIndex] = useState<number | undefined>();
  const [form] = useForm();
  const { reIssueEditId } = useParams();

  // @GET INVOICE ID
  const { data: invoiceIdData } = useGetInvoiceNoQuery('ARI');
  const invoiceIdServer = invoiceIdData;

  // @AIRPORT DATA
  const { data: airport } = useGetAllAirportQuery();
  const selectAirport = airport?.data;

  // @SET DEFAULT STATES
  useEffect(() => {
    form.setFieldsValue({
      invoice_sales_date: dayjs(),
      airticket_issue_date: dayjs(),
      dialCode: '+88',
      invoice_no: invoiceIdData?.data,
    });
  }, [invoiceIdServer]);

  // field values
  let penalties = useWatch(['airticket_penalties'], form) || 0;
  let fare_difference = useWatch(['airticket_fare_difference'], form) || 0;
  let client_price = useWatch(['airticket_client_price'], form) || 0;
  let purchase_price = useWatch(['airticket_purchase_price'], form) || 0;
  let comm_percent =
    useWatch(['airticket_commission_percent'], form) / 100 || 0;

  const grossFare = penalties + fare_difference;
  const commissionAmount = Math.round(fare_difference * comm_percent);
  const ait = grossFare * 0.003;
  const actualPrice =
    Number(grossFare || purchase_price) -
    Number(commissionAmount) +
    Number(ait);

  const airTicketPrice = Number(penalties) + Number(fare_difference);

  // set purchase price
  useEffect(() => {
    form.setFieldValue('airticket_purchase_price', airTicketPrice);
    form.setFieldValue('airticket_ait', ait.toFixed(2));
  }, [grossFare]);

  //
  useEffect(() => {
    form.setFieldsValue({
      clientPriceSet: client_price,
      purchasePriceSet: actualPrice,
      airticket_profit: (client_price - actualPrice).toFixed(2),
    });
  }, [client_price, actualPrice]);

  // @HANDLE SUBMIT
  const handleSubmit = (e: IReissueFormSubmit) => {
    if (!(editIndex || editIndex === 0)) {
      setInvoices((prev) => [...prev, e]);
    } else {
      // @Handle Edit
      setInvoices(
        invoices.map((item, index) => {
          if (index === editIndex) {
            return e;
          }
          return item;
        })
      );
      setEditIndex(undefined);
    }

    // After add notification scroll to top and reset fields
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const oldValues = form.getFieldsValue([
      'invoice_combclient_id',
      'invoice_agent_id',
      'invoice_sales_date',
      'invoice_due_date',
      'invoice_no',
      'invoice_sales_man_id',
    ]);
    form.resetFields();
    form.setFieldsValue({
      invoice_sales_date: dayjs(),
      airticket_issue_date: dayjs(),
      invoice_no: oldValues.invoice_no,
      invoice_agent_id: oldValues.invoice_agent_id,
      invoice_combclient_id: oldValues.invoice_combclient_id,
      invoice_sales_man_id: oldValues.invoice_sales_man_id,
    });
  };

  // @HANDLE EDIT GET DATA AND FORMAT FOR EDIT
  const { data, isLoading: fetchIsLoading } = useGetSingleReIssueInvoiceQuery(
    Number(reIssueEditId)
  );

  useEffect(() => {
    if (data?.data && selectAirport) {
      const formatedData: IEditReissue[] = getReIssueInvoiceFormat({
        data: data.data,
        selectAirport,
      });

      // @SET EDITABLE DATA TO STATE
      setInvoices(formatedData as IReissueFormSubmit[]);

      // @SET EDIT INITIAL VALUES
      const { invoice_info: invoiceInfo } = data.data;
      form.setFieldsValue({
        invoice_combclient_id: invoiceInfo.invoice_combclient_id,
        invoice_sales_man_id: invoiceInfo.invoice_sales_man_id,
        invoice_no: invoiceInfo.invoice_no,
        invoice_sales_date: dayjs(invoiceInfo.invoice_sales_date),
        invoice_due_date: invoiceInfo.invoice_due_date
          ? dayjs(invoiceInfo.invoice_due_date)
          : undefined,
        invoice_agent_id: invoiceInfo?.invoice_agent_id || undefined,
      });
    }
  }, [data, selectAirport]);

  return (
    <ClientsStyle>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <BreadCrumb arrOfOption={['Air Ticket', 'Edit Re Issue']} />
      </div>

      {/* EDIT DATA FETCH LOADING */}
      {fetchIsLoading ? <LoadingIndicator /> : ''}

      <div className='separator'>
        <div className='left_side'>
          <Form
            form={form}
            labelAlign='left'
            layout='vertical'
            onFinish={handleSubmit}
            onFinishFailed={(value) => {
              message.error(
                `Enter all required (${value.errorFields.length}) Fields`
              );
            }}
          >
            {/* INVOICE HEADER */}
            <InvNonComFormHeader form={form} disabled />

            {editIndex || editIndex === 0 ? (
              <>
                <br />
                <Alert
                  message={`Update Active -- ${invoices[editIndex]?.airticket_ticket_no}`}
                />
              </>
            ) : (
              ''
            )}

            {/* TICKET INFORMATION */}
            <InvReIssueTicketInfo
              form={form}
              handleCalculation={() => {}}
              invoices={invoices}
              editIndex={editIndex}
              commissionAmount={commissionAmount}
            />

            {/* PAX DETAILS */}

            <AirticketPaxPassportDetails
              form={form}
              formName='pax_passport'
              ticketInfo={data?.data?.ticketInfo}
            />

            {/* FLIGHT DETAILS */}
            <InvNonComFlightDetails form={form} />

            <FormButton
              label={!(editIndex || editIndex === 0) ? 'Add New' : 'Update'}
            />
          </Form>
        </div>

        <div className='right_side'>
          {/* @PREVIEW AND CREATE POST RIGHT SIDE TABLE HERE */}
          <EditverviewReIssue
            form={form}
            invoices={invoices}
            setEditIndex={setEditIndex}
            setInvoices={setInvoices}
            editIndex={editIndex}
            edit_data={data?.data}
          />
        </div>
      </div>
    </ClientsStyle>
  );
};
export default Edit_Invoice_Re_Issue;
const ClientsStyle = styled.div`
  .separator {
    display: flex;
    gap: 25px;
  }

  .left_side {
    width: 70%;
  }

  .right_side {
    width: 25%;
    position: fixed;
    right: 10px;
  }

  .separator {
    @media only screen and (max-width: 1207px) {
      flex-direction: column !important;
    }
  }
  .left_side {
    @media only screen and (max-width: 1207px) {
      width: 100% !important;
    }
    @media only screen and (min-width: 1208px) {
      width: 67% !important;
    }
    @media only screen and (min-width: 1400px) {
      width: 68% !important;
    }
    @media only screen and (min-width: 1500px) {
      width: 69% !important;
    }
    @media only screen and (min-width: 1600px) {
      width: 70% !important;
    }
  }
  .right_side {
    @media only screen and (max-width: 1207px) {
      width: 100% !important;
      position: static;
    }
    @media only screen and (max-height: 920px) {
      position: sticky;
    }
  }
`;
