import { Button, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../../../../app/hooks';
import { Fixed2, formatAmount } from '../../../../common/utils/common.utils';
import { IConsolidateRemittance } from '../../interfaces/dashboard.interfaces';
import DashboardTitle from '../DashboardTitle';
import ConsolidateRemittanceModal from './ConsolidateRemittanceModal';

type Props = {
  data: IConsolidateRemittance[];
  iata_vendor_id: string;
};

const { Text } = Typography;

const ConsolidateRemittance = ({ data, iata_vendor_id }: Props) => {
  const navigate = useNavigate();
  const currency = useAppSelector(
    (state) => state.user?.organization_info.org_currency
  );

  const columns: ColumnsType<IConsolidateRemittance> = [
    {
      title: 'Period',
      dataIndex: 'bsp_period',
      key: 'bsp_period',
      width: 100,
      align: 'center',
      render: (curr) => <Text strong>{curr}</Text>,
    },

    {
      title: 'Remittance Date',
      key: 'remittance_date',
      dataIndex: 'remittance_date',
      render: (curr) => <>{dayjs(curr).format('DD MMM YYYY')} </>,
      width: 135,
      align: 'center',
    },
    {
      title: 'Amount to be paid',
      dataIndex: 'bsp_paid',
      key: 'bsp_paid',
      width: 150,
      align: 'right',
      render: (curr, rec) => {
        const to_be_paid =
          Fixed2(rec.total_issue_amount) - Fixed2(rec.total_refund_amount);
        return currency?.toUpperCase() + ' ' + formatAmount(to_be_paid);
      },
    },
    {
      title: 'Paid',
      dataIndex: 'bsp_paid',
      key: 'bsp_paid',
      width: 120,
      align: 'right',
      render: (curr, rec) => {
        return currency?.toUpperCase() + ' ' + formatAmount(curr);
      },
    },
    {
      title: 'Balance',
      width: 120,
      align: 'right',
      render: (curr, rec) => {
        const balance =
          Fixed2(rec.total_issue_amount) -
          Fixed2(rec.total_refund_amount) -
          Fixed2(rec.bsp_paid);
        return currency?.toUpperCase() + ' ' + formatAmount(balance);
      },
    },
    {
      title: 'Status',
      width: 120,
      align: 'center',
      render: (curr, rec) => {
        const balance =
          Fixed2(rec.total_issue_amount) -
          Fixed2(rec.total_refund_amount) -
          Fixed2(rec.bsp_paid);

        return balance <= 0 ? (
          <Text
            strong
            style={{
              background: '#b8e9b2',
              color: 'black',
              padding: '4px 15px',
            }}
          >
            COMPLETE
          </Text>
        ) : (
          <Text
            strong
            style={{
              background: '#f6c636',
              color: 'black',
              padding: '4px 15px',
            }}
          >
            PENDING
          </Text>
        );
      },
    },
    {
      title: '',
      width: 40,
      align: 'center',
      render: (curr, rec) => {
        const balance =
          Fixed2(rec.total_issue_amount) -
          Fixed2(rec.total_refund_amount) -
          Fixed2(rec.bsp_paid);
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ConsolidateRemittanceModal bsp_period={rec.bsp_period} />
            {balance > 0 ? (
              <Button
                onClick={() =>
                  navigate(
                    `vendors/payment/add/${iata_vendor_id}?period=${data[0]?.bsp_period}&due=${need_to_paid}`,
                    { state: '/vendors' }
                  )
                }
                size='small'
                type='primary'
              >
                Add Payment
              </Button>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
  ];

  const need_to_paid =
    Fixed2(data[0]?.total_issue_amount) -
    Fixed2(data[0]?.total_refund_amount) -
    Fixed2(data[0]?.bsp_paid);

  return (
    <div className='mt-10'>
      <DashboardTitle
        title='BSP BILL PAYMENT'
        state={location.pathname}
        linkTitle='View More'
        link='/bsp-upcoming-payment'
      />

      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        size='small'
        prefixCls='custom_bsp_table'
      />
    </div>
  );
};

export default ConsolidateRemittance;
