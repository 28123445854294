import { Form, FormInstance } from 'antd';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import Flight_Details_List_Info from '../../IATA_AIR_TICKET/Components/Flight_Details_List_Info';

type Props = { form: FormInstance<any> };

export default function InvNonComFlightDetails({ form }: Props) {
  return (
    <div className='border p-5 my-20'>
      <FormHeaderTitle title='Flight Details (Optional) ✈️' />
      <Form.List
        name={['flight_details']}
        initialValue={[
          {
            fltdetails_from_airport_id: undefined,
            fltdetails_to_airport_id: undefined,
            fltdetails_airline_id: undefined,
            fltdetails_flight_no: undefined,
            fltdetails_fly_date: undefined,
            fltdetails_departure_time: undefined,
            fltdetails_arrival_time: undefined,
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Flight_Details_List_Info
                add={add}
                field={field}
                index={index}
                remove={remove}
                key={index}
                form={form}
              />
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
}
