import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { SingleLoanDataType } from '../../LoanTypes/LoanTypes';

export const ViewLoanList = (): ColumnsType<SingleLoanDataType> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'serial',
    },

    {
      title: 'Date',
      key: 'loan_date',
      render: (_, record) => {
        return `${dayjs(record.loan_date).format('DD MMM YYYY')}`;
      },
    },

    {
      title: 'Loan Name',
      dataIndex: 'loan_name',
      key: 'loan_name',
    },

    {
      title: 'Loan Type',
      dataIndex: 'loan_type',
      key: 'loan_type',
    },

    {
      title: 'Installment',
      key: 'loan_installment',
      render: (_, record) => {
        const {
          loan_type,
          loan_installment_duration,
          loan_installment_per_day,
          loan_installment_per_month,
          loan_installment_type,
        } = record;

        function installment() {
          if (loan_installment_type === 'MONTHLY') {
            return (
              Number(loan_installment_per_month) +
              ' Tk' +
              '/' +
              'months ' +
              ' (' +
              Number(loan_installment_duration) +
              ' Months)'
            );
          } else if (loan_installment_type === 'DAILY') {
            return (
              Number(loan_installment_per_day) +
              ' Tk' +
              '/' +
              'day ' +
              ' (' +
              Number(loan_installment_duration) +
              ' Days)'
            );
          } else {
            return 'NO';
          }
        }

        return installment();
      },
    },

    {
      title: 'Interest (%)',
      dataIndex: 'loan_interest_percent',
      key: 'loan_interest_percent',
      render: (loan_interest_percent) => {
        return Number(loan_interest_percent) || 0;
      },
    },

    {
      title: 'Loan Amount',
      dataIndex: 'loan_payable_amount',
      key: 'loan_payable_amount',
      render(_, record) {
        return `${
          record.loan_payable_amount != undefined
            ? Number(record.loan_payable_amount)
            : Number(record.loan_receivable_amount)
        }`;
      },
    },

    {
      title: 'Due Amount',
      dataIndex: 'loan_due_amount',
      key: 'loan_due_amount',
      render(_, record) {
        return `${
          record.loan_due_amount != undefined
            ? Number(record.loan_due_amount)
            : Number(record.loan_due_amount)
        }`;
      },
    },
  ];
};
