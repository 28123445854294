import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { IAirTicketSummary } from '../Interfaces/AirTicketReport.interfaces';
import { Tag, Typography } from 'antd';
import { DollarOutlined } from '@ant-design/icons';

// export const AirTicketSummaryCol: ColumnsType<IAirTicketSummary> = [

export const AirTicketSummaryCol = ({
  pagination,
}: {
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<IAirTicketSummary> => {
  return [
    {
      title: 'SL.',
      key: 'invoice_id',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'invoice_create_date',
      key: 'invoice_create_date',
      render: (_, record) => {
        return `${dayjs(record?.invoice_create_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (invoice_no, data) => (
        <Link to={`/airticket/details/${data.invoice_id}`}>{invoice_no}</Link>
      ),
    },

    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (_, record) => (
        <Link
          to={
            record.invoice_client_id
              ? `/clients/details/client-${record.invoice_client_id}`
              : `/combineClients/details/${record.invoice_combined_id}`
          }
        >
          {record.client_name}
        </Link>
      ),
    },

    {
      title: 'PNR',
      dataIndex: 'pnr',
      key: 'pnr',
      render: (_, data) => (
        <span>
          {data?.pnr
            ?.split(',')
            .slice(0, 3)
            .map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          {data?.pnr?.split(',').length > 3 && <li>...</li>}
        </span>
      ),
    },

    {
      title: 'Gross Fare',
      dataIndex: 'total_gross_fare',
      key: 'total_gross_fare',
      align: 'right',
      render: (total_gross_fare) => <> {Fixed2(total_gross_fare)} </>,
    },

    {
      title: 'Commission',
      dataIndex: 'commission_percent_total',
      key: 'commission_percent_total',
      align: 'right',
      render: (commission_percent_total) => (
        <> {Fixed2(commission_percent_total)} </>
      ),
    },
    {
      title: 'AIT',
      dataIndex: 'ait',
      key: 'ait',
      align: 'right',
      render: (curr) => Fixed2(curr),
    },

    {
      title: 'Gross Profit/Loss',
      dataIndex: 'gross_profit',
      key: 'gross_profit',
      align: 'right',
      render: (_, data) => {
        return <> {Number(Fixed2(data.gross_profit))} </>;
      },
    },

    {
      title: 'Purchase',
      dataIndex: 'total_purchase',
      key: 'total_purchase',
      align: 'right',
      render: (_, data) => {
        return (
          <Typography.Text style={{ color: 'darkblue' }}>
            {' '}
            {Fixed2(data.total_purchase)}{' '}
          </Typography.Text>
        );
      },
    },
    /* 
    {
      title: 'T/Discount',
      dataIndex: 'discount',
      key: 'discount',
      align: 'right',
    }, */

    {
      title: 'O/Discount',
      dataIndex: 'overall_discount',
      key: 'overall_discount',
      align: 'right',
    },

    {
      title: 'Net Profit/Loss',
      dataIndex: 'net_profit_loss',
      key: 'net_profit_loss',
      align: 'right',
      render: (curr, data) => {
        const amount = Fixed2(curr);
        if (amount > 0) {
          return <span className='font-bold font-success'>{Fixed2(curr)}</span>;
        } else {
          return <span className='font-bold font-warning'>{Fixed2(curr)}</span>;
        }
      },
    },

    {
      title: 'Sales',
      dataIndex: 'client_amount',
      key: 'client_amount',
      align: 'right',
      render: (_, data) => {
        return <> {Number(Fixed2(data.client_amount))} </>;
      },
    },
    {
      title: 'Received Amount',
      dataIndex: 'receive_amount',
      key: 'receive_amount',
      align: 'right',
      render: (_, data) => {
        return <> {Number(Fixed2(data.receive_amount))} </>;
      },
    },
    {
      title: 'Due Amount',
      key: 'client_amount',
      align: 'right',
      render: (_, data) => {
        const dueAmount =
          Number(data.client_amount) - Number(data.receive_amount);
        return (
          <>
            {dueAmount > 0 ? (
              <span className='font-bold font-warning'>{dueAmount}</span>
            ) : (
              <Tag color='cyan' icon={<DollarOutlined />}>
                PAID
              </Tag>
            )}
          </>
        );
      },
    },
  ];
};
