import { Spin } from 'antd';
import React from 'react';

type Props = {
  isLoading: boolean;
  isFetching: boolean;
};

const SelectLoading = ({ isFetching, isLoading }: Props) => {
  if (isFetching || isLoading) return <Spin />;
  return null;
};

export default SelectLoading;
