import { api } from '../../../../app/baseQuery';
import { IPaginationSD } from '../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { CHEQUE_PAYMENT } from '../../../Cheque_Management/api/Constants/constants';
import {
  IRefundDeleteRestoreBody,
  ITourRefundHistoryType,
  ITourRefundInvoicebyID,
  ITourRefundInvoiceInfo,
  ITourRefundReqBody,
} from '../../RefundTypes/RefundTypes';
import { TOUR_PACKAGE_REFUND } from '../Constants/Contstants';

export const tourPackageRefundEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all tour package invoice by client ID
    getAllTourInvoiceByID: build.query<
      HTTPResponse<ITourRefundInvoicebyID[]>,
      string
    >({
      query: (id) => ({ url: `/refund/invoice-tour/${id}` }),
      providesTags: [
        { type: 'TourPackageRefund', id: TOUR_PACKAGE_REFUND },
        { type: 'INVOICETOUR' },
      ],
    }),

    // get tour refund info
    getAllTourRefundInfo: build.query<
      HTTPResponse<ITourRefundInvoiceInfo>,
      number
    >({
      query: (id) => ({ url: `/tour-package/view/${id}` }),
      providesTags: [
        { type: 'TourPackageRefund', id: TOUR_PACKAGE_REFUND },
        { type: 'INVOICETOUR' },
        'invoice_single_view',
      ],
    }),

    //create tour refund
    createTourRefund: build.mutation<void, ITourRefundReqBody>({
      query: (body) => ({
        url: '/refund/invoice-tour-refund',
        method: 'POST',
        body: body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          toasterNotification('success', 'Tour Refund Created Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'TourPackageRefund', id: TOUR_PACKAGE_REFUND },
        { type: 'Clients', id: 'CLIENT' },
        { type: 'Cheque_Payment', id: CHEQUE_PAYMENT },
        'invoices',
      ],
    }),

    // get all tour refund
    getAllTourRefunds: build.query<
      HTTPResponse<ITourRefundHistoryType[]>,
      IPaginationSD
    >({
      query: (arg) => ({
        url: `/refund/invoice-tour-refund?${arg.current}&size=${arg.pageSize}&from_date=${arg.from}&to_date=${arg.to}&search=${arg.search}`,
      }),
      providesTags: [{ type: 'TourPackageRefund', id: TOUR_PACKAGE_REFUND }],
    }),

    //delete tour refund client
    deleteToursRefundClient: build.mutation<void, IRefundDeleteRestoreBody>({
      query: (body) => ({
        url: `/refund/invoice-tour-refund/${body.refund_id}`,
        method: 'DELETE',
        body: { refund_deleted_by: body.deleted_by },
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          toasterNotification(
            'success',
            'Tours Refund(Client) Deleted Successfully'
          );
        });
      },
      invalidatesTags: () => [
        { type: 'TourPackageRefund', id: TOUR_PACKAGE_REFUND },
        { type: 'Clients', id: 'CLIENT' },
        'invoices',
      ],
    }),

    //restore tour refund client
    restoreToursRefundClient: build.mutation<void, IRefundDeleteRestoreBody>({
      query: (body) => ({
        url: `/refund/invoice-tour-refund/${body.refund_id}`,
        method: 'PATCH',
        body: { refund_restored_by: body.deleted_by },
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          toasterNotification(
            'success',
            'Tours Refund(Client) Restored Successfully'
          );
        });
      },
      invalidatesTags: () => [
        { type: 'TourPackageRefund', id: TOUR_PACKAGE_REFUND },
        { type: 'Clients', id: 'CLIENT' },
      ],
    }),
  }),
});

export const {
  useLazyGetAllTourInvoiceByIDQuery,
  useLazyGetAllTourRefundInfoQuery,
  useCreateTourRefundMutation,
  useGetAllTourRefundsQuery,
  useLazyGetAllTourRefundsQuery,
  useDeleteToursRefundClientMutation,
  useRestoreToursRefundClientMutation,
} = tourPackageRefundEndpoints;
