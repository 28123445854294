import Table, { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React from 'react';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { IMoneyReceiptDetails } from '../../Types/MoneyReceiptTypes';
import { useGetMoneyReceiptDetailsQuery } from '../../api/endpoints/moneyReceiptEndpoints';
import { Link } from 'react-router-dom';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';

type Props = {
  id: string | undefined;
  cashiercomponentRef: React.RefObject<HTMLDivElement>;
};
const MoneyReceiptDetails = (props: Props) => {
  const id = props.id;
  const { cashiercomponentRef } = props;

  const { data } = useGetMoneyReceiptDetailsQuery(String(id));

  const moneyReceiptCostColumn: ColumnsType<IMoneyReceiptDetails> = [
    {
      title: 'SL',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Payment Date',
      key: 'invclientpayment_date',
      dataIndex: 'invclientpayment_date',
      render: (data) => {
        return <>{data ? dayjs(data).format('DD-MM-YYYY') : 'N/A'}</>;
      },
    },
    {
      title: 'Invoice No',
      key: 'invoice_no',
      dataIndex: 'invoice_no',
      render: (curr, data) => {
        if (data?.invoice_category_id) {
          return (
            <Link
              to={`../${
                findInvoiceRoute(data?.invoice_category_id) +
                '/' +
                'details' +
                '/' +
                data?.invoice_id
              }`}
              state={`./airticket/details`}
            >
              {curr}
            </Link>
          );
        } else {
          return curr;
        }
      },
    },
    {
      title: 'Sales Date',
      key: 'invoice_sales_date',
      dataIndex: 'invoice_sales_date',
      render: (curr) => <>{curr && dayjs(curr).format('DD-MM-YYYY')}</>,
    },
    {
      title: 'Payment Amount',
      key: 'invclientpayment_amount',
      dataIndex: 'invclientpayment_amount',
    },
    {
      title: 'Invoice Amount',
      key: 'invoice_net_total',
      dataIndex: 'invoice_net_total',
    },
  ];

  return (
    <div
      ref={cashiercomponentRef}
      className='my-20'
      style={{ backgroundColor: '#FFFFFF', padding: '20px', minWidth: '100vh' }}
    >
      <FormHeaderTitle title='RECEIPT DETAILS' />
      <Table
        size='small'
        bordered
        rowKey={(e) => e.invclientpayment_amount}
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        dataSource={data?.data}
        columns={moneyReceiptCostColumn}
        pagination={false}
        style={{ marginBottom: '20px' }}
      />
    </div>
  );
};

export default MoneyReceiptDetails;
