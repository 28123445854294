import { FileTextOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Row, Space, Table } from 'antd';
import Modal from 'antd/es/modal/Modal';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch } from '../../../../app/hooks';
import { selectCommon, setModal } from '../../../../common/slices/commonSlice';
import { IPermission, crud } from '../../../../common/types/commonTypes';
import { getRowHightLightClass } from '../../../../common/utils/rowColorChange';
import { FormInputItem } from '../../../../components/common/FormItem/FormItems';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import {
  useGetAllCombineClientQuery,
  useLazyGetExcelReportCombineQuery,
} from '../api/endpoints/clientCombineEndpoints';
import CombineClientSmsModal from '../modal/CombineClientSmsModal';
import { ICombineClientView } from '../types/combineClientTypes';
import viewCombineColumn from '../utils/utils_tsx/viewCombineColumn';

type Props = { permission?: IPermission };

export default function CombineClientHome({ permission }: Props) {
  const [fetchExcel] = useLazyGetExcelReportCombineQuery();
  const [editInfo, setEditInfo] = useState<ICombineClientView | null>(null);
  const commonState = useSelector(selectCommon);

  // Get Data with Pagination and Search
  const [search, setSearch] = useState('');

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const {
    data: combine_client,
    isLoading,
    isFetching,
    refetch,
  } = useGetAllCombineClientQuery({ ...pagination, search });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 50,
    }));
  }, [search]);
  // ...........

  const dispatch = useAppDispatch();
  const handleModal = () => {
    dispatch(setModal(!commonState.isModal));
  };

  const handleSearch = (e: any) => {
    const search = e.target.value;
    setSearch(search);
  };

  return (
    <CombineClientStyle>
      <BreadCrumb
        arrOfOption={['Clients', 'Combined Clients']}
        refetch={() => refetch()}
        reloaderSize='small'
      />

      <Modal
        title='Send SMS to Client'
        open={commonState.isModal}
        okText={'Submit'}
        cancelText={'Close'}
        onOk={handleModal}
        onCancel={handleModal}
        footer={null}
      >
        {editInfo && (
          <CombineClientSmsModal info={editInfo} setNull={setEditInfo} />
        )}
      </Modal>

      <Row justify={'space-between'} align={'middle'}>
        <Space style={{ marginBottom: '1rem' }}>
          {permission?.[crud.create] && (
            <Link to='/combine_client/add' state={location.pathname}>
              <Button type='primary'>
                <PlusOutlined />
                Add Combine Client
              </Button>
            </Link>
          )}

          <Button
            type='primary'
            onClick={() =>
              fetchExcel({
                ...pagination,
                search: search,
              })
            }
          >
            <FileTextOutlined />
            Excel Report
          </Button>
        </Space>

        <FormInputItem
          onBlur={handleSearch}
          onPressEnter={handleSearch}
          placeholder='🔍 Search by combine'
          size={6}
        />
      </Row>

      <Table
        size='small'
        bordered
        rowClassName={(item) => getRowHightLightClass(item.combine_update_date)}
        rowKey={(e) => e.combine_id}
        columns={viewCombineColumn({
          setEditInfo,
          permission,
          handleModal,
          pagination,
        })}
        dataSource={combine_client?.data}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        pagination={
          combine_client?.count !== undefined && combine_client?.count < 20
            ? false
            : {
              ...pagination,
              total: combine_client?.count,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }
        }
      />
    </CombineClientStyle>
  );
}

const CombineClientStyle = styled.div``;
