import { FormInstance } from "antd";
import { Rule } from "antd/es/form";
import { NamePath } from "antd/es/form/interface";
import { FilterValue } from "antd/es/table/interface";
import { TimeRangePickerProps } from "antd/lib";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import dayjs from "dayjs";
import { ROUT_PREFIX } from "../../components/common/FormItem/SelectCustomFeilds";
import { IAirport } from "../../modules/Configuration/airport/types/AirportTypes";
import { idType } from "../../modules/Invoice_tour/Types/InvoiceTourTypes";
import { IPermission } from "../types/commonTypes";

export const _ = require("lodash");

type checkFunctionType = (data: any) => boolean;

export const isNotEmpty: checkFunctionType = (data) => {
  return !_.isEmpty(data);
};
export const isEmpty: checkFunctionType = (data) => {
  return _.isEmpty(data);
};

export const FormatDate = (date: string) => {
  return date ? dayjs(date).format("DD MMM YYYY") : "N/A";
};
export const FormatDatePrint = (date: string) => {
  return date ? dayjs(date).format("DD/MM/YYYY") : "N/A";
};

type rulesTypes = {
  fields: NamePath[];
  form: FormInstance<any>;
};

export const rulesFunc = ({ fields, form }: rulesTypes) => {
  let isAnyDataFinned = false;
  fields.forEach((item) => {
    if (form.getFieldValue(item)) {
      isAnyDataFinned = true;
      return;
    }
  });

  setTimeout(() => {
    if (!isAnyDataFinned) {
      form.validateFields(fields);
    }
  }, 100);

  const rules: Rule[] = [
    {
      required: isAnyDataFinned,
      message: `This field is required!`,
    },
  ];
  return rules;
};

export const pagination = (rows: number = 0) => {
  const pagination = {
    defaultPageSize: 50,
    showSizeChanger: true,
    pageSizeOptions: ["50", "100", "200", "500"],
    pageSize: 50,
  };
  return rows >= 20 ? pagination : false;
};

export const Fixed2 = (
  toNumber: string | number | undefined | null,
  toFixed?: number
) => {
  return Number(Number(toNumber || 0).toFixed(toFixed ? toFixed : 0)) || 0;
};

type tableChangeTypes = {
  args: {
    pagination: TablePaginationConfig;
    filters: Record<string, FilterValue | null>;
    sorter: any;
  };
  states: {
    setQueryData: (
      value: React.SetStateAction<{
        isTrash: "0" | "1";
        current: number;
        pageSize: number;
      }>
    ) => void;
    isTrash?: boolean;
    refetch: ({ query }: { query?: string | undefined }) => void;
    setTableParams: (value: React.SetStateAction<TableParams>) => void;
    setDataSource?: (value: React.SetStateAction<any>) => void;
    tableParams: TableParams;
  };
};

export interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue | null>;
}
export const handleTableChange = ({ args, states }: tableChangeTypes) => {
  const { filters, pagination, sorter } = args;
  const { refetch, setDataSource, setQueryData, setTableParams, tableParams } =
    states;

  setQueryData((prev) => ({
    ...prev,
    current: pagination?.current || 0,
    pageSize: pagination.pageSize || 20,
  }));

  refetch({
    query: `?page=${pagination.current}&size=${pagination.pageSize}`,
  });

  setTableParams({
    pagination,
    filters,
    ...sorter,
  });

  // `dataSource` is useless since `pageSize` changed
  if (pagination.pageSize !== tableParams.pagination?.pageSize) {
    setDataSource && setDataSource([]);
  }
};

export const getListPermission = (permission: IPermission | undefined) => {
  const listRoutePermission =
    permission?.["read:any"] ||
    permission?.["delete:any"] ||
    permission?.["update:any"];

  return listRoutePermission;
};

type TimerId = ReturnType<typeof setTimeout>;
export function debounce<T extends (...args: any[]) => void>(
  func: T,
  timeout: number = 300
) {
  let timer: TimerId;

  return function debounceFn(this: any, ...args: Parameters<T>): void {
    clearTimeout(timer);

    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

// @get airport id using ita code
type t = number | string;
export const getAirportByItaCode = (
  codes: string,
  selectAirport?: IAirport[]
) => {
  let findIdsTemp: t[] = [];
  if (codes?.includes(ROUT_PREFIX)) {
    if (codes) {
      const temp = codes.split(ROUT_PREFIX);
      const findIds: number[] = [];
      temp.forEach((item) => {
        const findId = selectAirport?.find(
          (item2) =>
            item2?.airline_iata_code.toLowerCase() === item.toLowerCase()
        );
        findIds.push(Number(findId?.airline_id || 0));
      });
      findIdsTemp = [...findIds];
    } else {
    }
  } else {
    if (codes) {
      const findId = selectAirport?.find(
        (item) => item?.airline_iata_code.toLowerCase() === codes.toLowerCase()
      );
      findIdsTemp = [findId?.airline_id || 0];
    }
  }
  return findIdsTemp.filter((item) => item);
};
// @get ita code using airport ids
export const getItaCodeByAirportIds = (
  ids: t[],
  selectAirport?: IAirport[]
) => {
  let result = "";
  if (ids?.length) {
    ids?.forEach((item) => {
      result +=
        selectAirport?.find((i) => i.airline_id == Number(item))
          ?.airline_iata_code + ROUT_PREFIX;
    });
  } else {
    const idsTemp: any = ids;
    result +=
      selectAirport?.find((i) => i.airline_id === idsTemp)?.airline_iata_code +
      ROUT_PREFIX;
  }

  return result;
};

export const separateClient = (invoice_combclient_id: string) => {
  const clientCom = invoice_combclient_id?.split("-");
  let client_id: number | null = null;
  let combined_id: number | null = null;

  if (clientCom) {
    const client_type = clientCom[0];

    if (client_type === "client") {
      client_id = Number(clientCom[1]);
    } else {
      combined_id = Number(clientCom[1]);
    }
  }
  return { client_id, combined_id };
};

export function findColumnsWithNull(obj: any) {
  const columnsWithNull = [];
  for (const key in obj) {
    if ([null, 0, "0", "0.00"].includes(obj[key]) || !obj[key]) {
      columnsWithNull.push(key);
    }
  }
  return columnsWithNull;
}

export const filterNullColumns = (data: any, columns: ColumnsType<any>) => {
  const columnsWithNullForEachRow: any[][] = [];

  data?.forEach((obj: any) => {
    const columnsWithNull = findColumnsWithNull(obj);
    columnsWithNullForEachRow.push(columnsWithNull);
  });
  const commonColumnsWithNull =
    columnsWithNullForEachRow.length > 0
      ? columnsWithNullForEachRow.reduce((intersection, columns) => {
          return intersection.filter((value) => columns.includes(value));
        })
      : [];

  let quant = 0;

  const col = columns.filter((item) => {
    const keysToRemove = commonColumnsWithNull;

    if (!keysToRemove.includes(item.key as string)) {
      return item;
    }
    quant++;
  });

  return { col, quant };
};

export const MathAbs = (value: idType | undefined) => {
  return Math.abs(Number(value || 0) || 0);
};

export const rangePresets: TimeRangePickerProps["presets"] = [
  { label: "Today", value: [dayjs(), dayjs()] },
  { label: "Yesterday", value: [dayjs().add(-1, "d"), dayjs()] },
  { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
  { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
  { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
  { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
];

type idTypes = idType | undefined;

export const formatAmount = (number: idTypes) => {
  if (!number) return `0.00`;
  // Convert the number to a string with two decimal places
  let numStr = Number(number).toFixed(2);

  // Split the number into integer and decimal parts
  let parts = numStr.split(".");
  let integerPart = parts[0];
  let decimalPart = parts[1];

  // Format the integer part with commas every three digits
  let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Concatenate the formatted integer part with the decimal part
  return formattedIntegerPart + "." + decimalPart;
};

export const formatNumber = (number: idTypes) => {
  if (!number) return `0.00`;
  // Convert the number to a string with two decimal places
  let numStr = Number(number).toFixed(2);

  // Split the number into integer and decimal parts
  let parts = numStr.split(".");
  let integerPart = parts[0];
  let decimalPart = parts[1];

  // Format the integer part with commas every three digits
  let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Concatenate the formatted integer part with the decimal part
  return formattedIntegerPart + "." + decimalPart;
};

export const formatAmountAbs = (number: idType) => {
  // Convert the number to a string with two decimal places
  let numStr = MathAbs(Number(number)).toFixed(2);

  // Split the number into integer and decimal parts
  let parts = numStr.split(".");
  let integerPart = parts[0];
  let decimalPart = parts[1];

  // Format the integer part with commas every three digits
  let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Concatenate the formatted integer part with the decimal part
  return formattedIntegerPart + "." + decimalPart;
};

export const storageBaseUrl =
  "https://m360ict.s3.ap-south-1.amazonaws.com/trabill-storage/";
