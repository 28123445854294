import { api } from '../../../../app/baseQuery';
import { IPaginationSD } from '../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { EMPLOYEEEXPENSEREPORT } from '../../../Reports/Api/constants';
import { IPayrollGetAll, IPayrollGetForEdit } from '../../types/PayrollTypes';

export const payrollEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // POST
    postEmployeeExpense: build.mutation<void, FormData>({
      query: (body) => ({
        url: '/payroll/create',
        body: body,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'Payroll' }, EMPLOYEEEXPENSEREPORT],
    }),

    // EDIT
    editEmployeeExpense: build.mutation<void, { body: FormData; id: number }>({
      query: ({ body, id }) => ({
        url: `/payroll/payroll/${id}`,
        body: body,
        method: 'PATCH',
      }),
      invalidatesTags: [{ type: 'Payroll' }, EMPLOYEEEXPENSEREPORT],
    }),

    // DELETE
    deleteEmployeeExpense: build.mutation<
      {
        success: boolean;
        message: string;
      },
      {
        body: { payroll_deleted_by: number; payroll_account_id: number };
        id: number;
      }
    >({
      query: ({ body, id }) => ({
        url: `/payroll/payroll/${id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [{ type: 'Payroll' }, EMPLOYEEEXPENSEREPORT],
    }),

    // RESTORE
    restoreEmployeeExpense: build.mutation<
      {
        success: boolean;
        message: string;
      },
      {
        body: { payroll_restored_by: number; payroll_account_id: number };
        id: number;
      }
    >({
      query: ({ body, id }) => ({
        url: `/payroll/payroll-restore/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'Payroll' }, EMPLOYEEEXPENSEREPORT],
    }),

    // GET ALL
    getAllPayroll: build.query<HTTPResponse<IPayrollGetAll[]>, IPaginationSD>({
      query: (query) => ({
        url: `/payroll/payrolls?page=${query.current}&size=${query.pageSize}&from_date=${query.from}&to_date=${query.to}&search=${query.search}`,
      }),
      providesTags: () => ['Payroll'],
    }),

    // GET ALL DELETED
    getAllPayrollDeleted: build.query<HTTPResponse<IPayrollGetAll[]>, string>({
      query: (query) => ({
        url: `/payroll/payrolls${query ? query : ''}`,
      }),
      providesTags: () => ['Payroll'],
    }),

    // GET FOR EDIT
    getForEditPayroll: build.query<HTTPResponse<IPayrollGetForEdit>, number>({
      query: (id) => ({
        url: `/payroll/payroll/${id}`,
      }),
      providesTags: () => ['Payroll'],
    }),
  }),
});

export const {
  usePostEmployeeExpenseMutation,
  useEditEmployeeExpenseMutation,
  useGetAllPayrollQuery,
  useLazyGetAllPayrollQuery,
  useDeleteEmployeeExpenseMutation,
  useGetAllPayrollDeletedQuery,
  useLazyGetAllPayrollDeletedQuery,
  useRestoreEmployeeExpenseMutation,
  useLazyGetForEditPayrollQuery,
} = payrollEndpoints;
