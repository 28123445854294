import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import MoneyReceiptForm from '../../Components/MoneyReceiptForm';

type Props = { reason: 'ADD_NEW' | 'EDIT' };
const New_Money_Receipt = ({ reason }: Props) => {
  const breadCrumb =
    reason === 'ADD_NEW' ? 'Add Money Receipt' : 'Edit Money Receipt';
  return (
    <>
      <BreadCrumb arrOfOption={[breadCrumb]} />
      <MoneyReceiptForm reason={reason} />
    </>
  );
};

export default New_Money_Receipt;
