import { DollarOutlined } from '@ant-design/icons';
import { Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Fixed2, formatAmountAbs } from '../../../../common/utils/common.utils';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';
import {
  ClientAdvanceResults,
  ITotalDueAdvanceDetails,
} from '../endpoints/DueAdvanceDetailsEndpoints';
import { IClickInfo } from '../pages/DueAdvanceDetails';

interface Props {
  pagination: {
    current: number;
    pageSize: number;
  };

  type: 'CLIENT' | 'DETAILS' | 'AIRLINE';
  setClickInfo: React.Dispatch<React.SetStateAction<IClickInfo>>;
}

const colors = ['#f50', '#2db7f5', '#87d068', '#108ee9'];

export const DueAdvanceDetailsColumn = ({
  pagination,
  type,
  setClickInfo,
}: Props) => {
  let arr: ColumnsType<ITotalDueAdvanceDetails> = [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
        1 +
        index,
    },

    {
      title: 'Sales Date',
      dataIndex: 'invoice_sales_date',
      key: 'invoice_sales_date',
      render: (curr) => dayjs(curr).format('DD MMM YYYY'),
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (curr, rec) => {
        return (
          <Link
            to={
              rec.invoice_client_id
                ? `/reports/client_ledger?id=${rec.invoice_client_id}`
                : `/reports/combined_ledger?id=${rec.invoice_combined_id}`
            }
          >
            {curr}
          </Link>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'invoice_client_id',
      key: 'invoice_client_id',
      render: (curr, rec) => {
        return (
          <Typography.Link
            onClick={() =>
              setClickInfo((prev) => ({
                ...prev,
                client_id: String(rec.invoice_client_id),
                airline_id: undefined,
              }))
            }
          >
            Cl Details
          </Typography.Link>
        );
      },
    },

    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data) => (
        <Link
          to={
            '../' +
            findInvoiceRoute(data.invoice_category_id) +
            '/' +
            'details' +
            '/' +
            data.invoice_id
          }
          state={location.pathname}
        >
          {data.invoice_no}
        </Link>
      ),
    },

    {
      title: 'Airlines',
      dataIndex: 'airlines_code',
      key: 'airlines_code',
      render: (curr, rec, index) => {
        if (type !== 'CLIENT') {
          return (
            <Typography.Link
              onClick={() =>
                setClickInfo((prev) => ({
                  ...prev,
                  airline_id: String(rec.airline_id),
                  client_id: undefined,
                }))
              }
            >
              {rec.airline_name}
            </Typography.Link>
          );
        } else {
          return (
            <span>
              {rec.airlines_code?.split(',').map((item, index) => (
                <Tag color={colors[index % colors.length]}>{item}</Tag>
              ))}
            </span>
          );
        }
      },
    },

    {
      title: 'Sales Price',
      dataIndex: 'sales',
      key: 'sales',
      align: 'right',
      render: (curr) => Fixed2(curr),
    },
    {
      title: 'Received',
      dataIndex: 'pay',
      key: 'pay',
      align: 'right',
      render: (curr) => Fixed2(curr),
    },
    {
      title: 'Invoice Due',
      dataIndex: 'due',
      key: 'due',
      align: 'right',
      render: (curr) =>
        Fixed2(curr) > 0 ? (
          <span style={{ color: 'red' }}>{Fixed2(curr)}</span>
        ) : (
          <Tag color='cyan' icon={<DollarOutlined />}>
            PAID
          </Tag>
        ),
    },
    {
      title: 'Discount',
      dataIndex: 'invoice_discount',
      key: 'invoice_discount',
      align: 'right',
      render: (curr) => Fixed2(curr),
    },

    {
      title: 'Profit/Loss',
      dataIndex: 'overall_profit',
      key: 'overall_profit',
      align: 'right',
      render: (curr) => (
        <span style={{ color: Fixed2(curr) > 0 ? 'green' : 'red' }}>
          {Fixed2(curr)}
        </span>
      ),
    },
  ];

  if (type === 'CLIENT') {
    arr = arr.filter(
      (column) => !['invoice_no', 'airline_name'].includes(column.key as string)
    );
  }
  if (type === 'AIRLINE') {
    arr = arr.filter(
      (column) =>
        ![
          'client_name',
          'invoice_no',
          'invoice_client_id',
          'last_balance',
        ].includes(column.key as string)
    );
  }
  if (type === 'DETAILS') {
    arr = arr.filter(
      (column) => !['last_balance'].includes(column.key as string)
    );
  }
  return arr;
};

interface ClientAdvanceColumn {
  pagination: {
    current: number;
    pageSize: number;
  };
}

export const ClientAdvanceColumn = ({ pagination }: ClientAdvanceColumn) => {
  let arr: ColumnsType<ClientAdvanceResults> = [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
        1 +
        index,
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',

      render: (curr, rec) => {
        return (
          <Link
            to={
              rec.client_id
                ? `/reports/client_ledger?id=${rec.client_id}`
                : `/reports/combined_ledger?id=${rec?.combine_id}`
            }
          >
            {curr}
          </Link>
        );
      },
    },
    {
      title: 'Ref Code',
      dataIndex: 'client_entry_id',
      key: 'client_entry_id',
    },
    {
      title: 'Department',
      dataIndex: 'category_title',
      key: 'category_title',
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      key: 'contact',
    },
    {
      align: 'end',
      title: 'Balance Status',
      dataIndex: 'ctrxn_lbalance',
      key: 'ctrxn_lbalance',
      render: (curr) => (
        <span style={{ color: Number(curr) > 0 ? 'green' : 'red' }}>
          {formatAmountAbs(curr)}
        </span>
      ),
    },
  ];

  return arr;
};
