import { Col, Form, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { ISetState } from '../../../../common/types/commonTypes';
import {
  DateInput,
  FormButton,
  FormInputItem,
  NumberInput,
  TextAreaInput,
} from '../../../../components/common/FormItem/FormItems';
import { IAllAccountType } from '../../../Accounts/AccountsTypes/AccountsTypes';
import { useGetAllAccountsQuery } from '../../../Accounts/Api/Endpoints/accountsEndpoints';
import { setDepositModal } from '../../states/chequeSlice';
import {
  IAllChequeDataType,
  IChequeManagementDataType,
  IChequeUpdate,
} from '../../types/ChequeManagementTypes';
import { SelectDataInput } from '../../../../components/common/FormItem/SelectCustomFeilds';
import { useUpdateChequeMutation } from '../../api/Endpoints/AllChequeManagementEndpoints';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

interface IProps {
  info: IAllChequeDataType;
  setNull?: ISetState<IChequeManagementDataType | null>;
  name?: string;
  label?: string;
  discount_receipt?: boolean;
}

function Cheque_Update_Modal({ info, setNull, name, label }: IProps) {
  const [status, setStatus] = useState('DEPOSIT');
  const [form] = Form.useForm();
  const user = useSelector(selectUser);

  const dispatch = useAppDispatch();

  const [deposit_mutation, { isSuccess, isLoading, isError }] =
    useUpdateChequeMutation();

  useEffect(() => {
    form.setFieldsValue({
      cheque_number: info.cheque_number,
      cheque_withdraw_date: dayjs(info.withdraw_date),
      cheque_bank: info.bank_name,
      // loan_name: info.loan_name,
      cheque_amount: info.cheque_amount,
    });
  }, [info]);

  const { data: allAccounts } = useGetAllAccountsQuery();

  const handleSubmit = async (values: IChequeUpdate) => {
    const body: IChequeUpdate = {
      ...values,
      cheque_no: info.cheque_number,
      cheque_id: info.cheque_id,
      cheque_type: info?.cheque_type,
      user_id: user?.user_id as number,
      cheque_status: status,
      date: dayjs(values.date).format('YYYY-MM-DD'),
      comb_vendor: info?.comb_vendor,
      comb_client: info?.comb_client,
    };

    await deposit_mutation(body);
    dispatch(setDepositModal(false));
    form.resetFields();
    // setNull(null);
  };

  useEffect(() => {
    form.setFieldValue('date', dayjs());
  }, []);

  return (
    <Form form={form} layout='vertical' onFinish={handleSubmit}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <SelectDataInput
          onChange={(value) => setStatus(value.toString())}
          defaultValue={'DEPOSIT'}
          size={12}
          label='Status'
          data={[
            { title: 'PENDING', id: 'PENDING', color: 'orange' },
            { title: 'DEPOSIT', id: 'DEPOSIT', color: 'lightgreen' },
            { title: 'BOUNCE', id: 'BOUNCE', color: 'tomato' },
            { title: 'RETURN', id: 'RETURN', color: 'tomato' },
          ]}
        />

        <FormInputItem
          disabled
          label='Cheque No.'
          name='cheque_number'
          readOnly
          size={12}
        />
        <FormInputItem
          readOnly
          label='Bank Name'
          name='cheque_bank'
          size={12}
        />
        <DateInput
          label='Cheque Withdraw Date'
          name='cheque_withdraw_date'
          size={12}
          disabled
        />

        <NumberInput
          readOnly
          label='Payment Amount'
          name='cheque_amount'
          disabled
          size={12}
          min='0'
          maxChar={14}
        />

        <Col span={8} xs={24} sm={24} md={24} lg={12}>
          <Form.Item
            name='account_id'
            label='Select Account'
            rules={[{ required: true, message: 'Account is required!!' }]}
          >
            <Select
              showSearch
              allowClear
              placeholder='Select Account'
              optionFilterProp='children'
            >
              {allAccounts?.data?.map((item: IAllAccountType, index) => {
                return (
                  <Select.Option key={index} value={item.account_id}>
                    {`${item.account_name}`}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <DateInput label='Deposit Date' name='date' required size={12} />
        <TextAreaInput label='Note' name='cheque_note' size={24} />
      </Row>
      <FormButton label='Deposit' loading={isLoading} />
      {isLoading && <LoadingIndicator />}
    </Form>
  );
}

export default Cheque_Update_Modal;
