import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, FormListFieldData, Row } from 'antd';
import { FormInstance, FormListOperation } from 'antd/lib';
import { FormInputItem, NumberInput } from '../../../FormItem/FormItems';
import { useWatch } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { Fixed2 } from '../../../../../common/utils/common.utils';

type Props = {
  subField: FormListFieldData;
  subOpt: FormListOperation;
  form: FormInstance<any>;
  subFields: FormListFieldData[];
  catID: number;
  sl: number;
};

const InvoiceBilling_input = ({
  form,
  subField,
  subOpt,
  subFields,
  catID,
  sl,
}: Props) => {
  const quantity = useWatch(
    ['edited_invoice', subField.name, 'billing_remaining_quantity'],
    form
  );
  const unitPrice = useWatch(
    ['edited_invoice', subField.name, 'billing_unit_price'],
    form
  );

  useEffect(() => {
    if (catID === 4) {
      form.setFieldValue(['edited_invoice', subField.name, 'total'], unitPrice);
    } else {
      form.setFieldValue(
        ['edited_invoice', subField.name, 'total'],
        Fixed2(quantity) * Fixed2(unitPrice)
      );
    }
  }, [unitPrice, unitPrice, form, subOpt, subFields]);

  return (
    <Row
      className='border'
      key={subField.key}
      gutter={5}
      align={'middle'}
      justify={'center'}
    >
      <Divider>Billing - {sl + 1}</Divider>

      <FormInputItem
        hidden
        name={[subField.name, 'airticket_id']}
        label='Air-ticket id'
        size={8}
        // readOnly
      />

      <FormInputItem
        name={[subField.name, 'product_name']}
        label='Product Name'
        size={6}
        readOnly
      />
      <NumberInput
        name={[subField.name, 'billing_remaining_quantity']}
        label='Quantity'
        size={6}
        readOnly
      />
      <NumberInput
        name={[subField.name, 'billing_unit_price']}
        label='Unit Price'
        size={5}
      />

      <NumberInput
        name={[subField.name, 'total']}
        label='Total'
        size={5}
        readOnly
      />

      <Col span={2} style={{ padding: 0, margin: 0, marginTop: 30 }}>
        <Form.Item>
          <Button
            danger
            icon={<MinusCircleOutlined />}
            onClick={() => subOpt.remove(subField.name)}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default InvoiceBilling_input;
