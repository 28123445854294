import { Button, Popconfirm, Space, Typography, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import {
  IPermission,
  ISetState,
  crud,
} from '../../../../common/types/commonTypes';
import { formatAmountAbs } from '../../../../common/utils/common.utils';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { IAccountDataType } from '../../AccountsTypes/AccountsTypes';
import { useDeleteAccountMutation } from '../../Api/Endpoints/accountsEndpoints';

type Props = {
  pagination: {
    current: number;
    pageSize: number;
  };
  setEditInfo: ISetState<IAccountDataType | null>;
  showModal: () => void;
  permission?: IPermission;
};

export const accountListColumns = ({
  setEditInfo,
  showModal,
  permission,
  pagination,
}: Props): ColumnsType<IAccountDataType> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const setEditAccount = (value: IAccountDataType) => {
    setEditInfo(value);
    showModal();
  };
  const user = useSelector(selectUser);

  const [deleteAccount, { isError, isSuccess }] = useDeleteAccountMutation();

  const onDelete = async (values: IAccountDataType, index: number) => {
    if (values.has_trxn !== 'false') {
      message.error("You can't delete with account transactions!");
    } else setColumnIndex(index);
    await deleteAccount({
      delete_by: user?.user_id as number,
      id: Number(values.account_id),
    });
  };
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isError) {
      setColumnIndex(null);
      toasterNotification('error', 'Something went wrong');
    }
  }, [isError, isSuccess]);

  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination?.current || 1) - 1) * (pagination?.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Name',
      dataIndex: 'account_name',
      key: 'account_name',
    },

    {
      title: 'Account Type',
      dataIndex: 'account_acctype_id',
      key: 'account_acctype_id',

      render: (paymentType) =>
        (paymentType === 1 && 'Cash ') ||
        (paymentType === 2 && 'Bank') ||
        (paymentType === 3 && 'Mobile Banking'),
    },
    {
      title: 'Account No',
      dataIndex: 'account_number',
      key: 'account_number',
    },

    {
      title: 'Bank Name',
      dataIndex: 'account_bank_name',
      key: 'account_bank_name',
    },
    {
      title: 'Routing No.',
      dataIndex: 'account_routing_no',
      key: 'account_routing_no',
    },
    {
      title: 'Branch',
      dataIndex: 'account_branch_name',
      key: 'account_branch_name',
    },
    {
      title: 'Last Balance',
      key: 'last_balance',
      align: 'right',
      render: (data, item) => {
        return (
          <span
            style={{ color: Number(item.last_balance) > 0 ? 'green' : 'red' }}
          >
            {formatAmountAbs(item.last_balance)}
          </span>
        );
      },
    },
    {
      title: 'Action',
      key: 'operation',

      render: (_, record, index) => (
        <Space size='small'>
          {permission?.[crud.update] && (
            <Button
              onClick={() => setEditAccount(record)}
              size='small'
              type='primary'
            >
              Edit
            </Button>
          )}

          <Link
            to={`/accounts/account_statement/${record.account_id}`}
            state={location.pathname}
          >
            <Button size='small' type='primary'>
              <Typography style={{ color: '#ffffff' }}>Statement</Typography>
            </Button>
          </Link>

          {permission?.[crud.delete] && (
            <Button
              disabled={record?.has_trxn === 'true'}
              danger
              size='small'
              type='primary'
              loading={columnIndex === index && true}
            >
              {record?.has_trxn === 'true' ? (
                <span>Delete</span>
              ) : (
                <Popconfirm
                  onConfirm={() => onDelete(record, index)}
                  title='Sure to delete?'
                >
                  <span>Delete</span>
                </Popconfirm>
              )}
            </Button>
          )}
        </Space>
      ),
    },
  ];
};
