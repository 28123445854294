import { api } from '../../../../app/baseQuery';
import { formatQueryParams } from '../../../../auth/utils/lib';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { report_type } from '../pages/BSPBill_Compare';
import {
  BSPBill_File_list,
  BSPBill_File_Select,
  BSPBill_Summary,
  BSPBillCheck_Refund,
  BSPBillCheck_Summary,
  BSPBillCheck_Ticket,
  UploadPDF,
} from '../type/BSPType';

export const bspBillApisEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    uploadBSPBilling: build.mutation<UploadPDF, FormData>({
      query: (body) => ({
        url: 'dashboard/bsp-bill',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['BSP_FILE_LIST'],
    }),

    getBspBillingCrossCheck: build.query<
      HTTPResponse<{
        tickets: BSPBillCheck_Ticket[] | undefined;
        summary: BSPBillCheck_Summary | undefined;
        refunds: BSPBillCheck_Refund[] | undefined;
      }>,
      { id: string | number; type: report_type }
    >({
      query: ({ id, type }) => ({
        url: `dashboard/bsp-bill-check/${id}?type=${type || 'SUMMARY'}`,
      }),
      providesTags: ['BSP_SINGLE_VIEW'],
    }),

    bspFileList: build.query<
      HTTPResponse<BSPBill_File_list[]>,
      {
        search: string | undefined;
        page: number;
        size: number;
        date: string | undefined;
      }
    >({
      query: ({ date, page, search, size }) => {
        const queryParams = formatQueryParams({
          date,
          page,
          search,
          size,
        });
        return {
          url: `dashboard/bsp-bill-list?${queryParams}`,
        };
      },
      providesTags: ['BSP_FILE_LIST'],
    }),
    selectBspFileList: build.query<
      HTTPResponse<BSPBill_File_Select[]>,
      {
        search: string | undefined;
      }
    >({
      query: ({ search }) => {
        const queryParams = formatQueryParams({
          search,
        });
        return {
          url: `/dashboard/bsp-bill?${queryParams}`,
        };
      },
      providesTags: ['BSP_FILE_LIST'],
    }),

    deleteBspFileList: build.mutation<HTTPResponse<void>, string | number>({
      query: (arg) => ({
        url: `dashboard/bsp-bill/${arg}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BSP_FILE_LIST'],
    }),
    bspBillSummary: build.query<
      HTTPResponse<BSPBill_Summary[]>,
      { ref: string }
    >({
      query: ({ ref }) => ({
        url: `dashboard/bsp-bill-summary/${ref}`,
        method: 'GET',
      }),
      providesTags: ['BSP_FILE_LIST'],
    }),
  }),
});

export const {
  useUploadBSPBillingMutation,
  useGetBspBillingCrossCheckQuery,
  useBspFileListQuery,
  useDeleteBspFileListMutation,
  useSelectBspFileListQuery,
  useBspBillSummaryQuery,
} = bspBillApisEndpoints;
