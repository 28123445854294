import { Col, Form, Row, Select, message } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import { ISetState } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import {
  FormButton,
  FormInputItem,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import {
  IAccountCategoryPaymentType,
  IAccountDataType,
} from '../../AccountsTypes/AccountsTypes';
import {
  useEditAccountMutation,
  useGetAccountsCategoryTypeQuery,
} from '../../Api/Endpoints/accountsEndpoints';

interface IProps {
  info: IAccountDataType;
  setNull: ISetState<IAccountDataType | null>;
}

const AccountsEditModal = ({ info, setNull }: IProps) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const user = useAppSelector(selectUser);

  useEffect(() => {
    form.setFieldsValue({
      account_acctype_id: info.account_acctype_id,
      account_name: info.account_name,
      account_number: info.account_number,
      account_bank_name: info.account_bank_name,
      account_branch_name: info.account_branch_name,
      account_routing_no: info.account_routing_no,
    });
  }, [info]);
  const [paymentMethod, setPaymentMethod] = useState<number | undefined>();
  useEffect(() => {
    setPaymentMethod(info.account_acctype_id);
  }, [info.account_acctype_id]);

  const [editAccount, { isError, isLoading, isSuccess }] =
    useEditAccountMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Account Edited Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const onFinish = async () => {
    assyncWrapper(async () => {
      const values = (await form.getFieldsValue()) as IAccountDataType;
      values.account_id = info.account_id;
      await editAccount({
        ...values,
        account_updated_by: user?.user_id as number,
      });
      setNull(null);
      dispatch(setModal(false));
    });
  };

  // ===============get payment method
  const { data: accountTypePaymentMethod } = useGetAccountsCategoryTypeQuery();
  return (
    <div>
      <Form form={form} onFinish={onFinish} layout='vertical'>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <Col span={6} xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              name='account_acctype_id'
              label='Account Type'
              rules={[
                { required: true, message: 'Please Select Payment Method!!' },
              ]}
              initialValue={info.account_acctype_id}
            >
              <Select
                // disabled
                placeholder='Select Payment Method'
                style={{ width: '100%' }}
                onChange={(e: number) => setPaymentMethod(e)}
                allowClear
              >
                {accountTypePaymentMethod?.data?.map(
                  (item: IAccountCategoryPaymentType) => {
                    return (
                      <Select.Option
                        key={item.acctype_id}
                        value={item.acctype_id}
                      >
                        {item.acctype_name}
                      </Select.Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
          </Col>
          <FormInputItem
            label='Account Name'
            name='account_name'
            required
            size={24}
          />
          {paymentMethod === 2 && (
            <>
              <FormInputItem
                label='Account number'
                name='account_number'
                required
                size={24}
              />
              <FormInputItem
                label='Bank name'
                name='account_bank_name'
                required
                size={24}
              />
              <FormInputItem
                label='Branch'
                name='account_branch_name'
                required
                size={24}
              />
              <FormInputItem
                label='Routing No'
                name='account_routing_no'
                size={24}
              />
            </>
          )}
          {paymentMethod === 3 && (
            <>
              <FormInputItem
                placeholder='Enter Your Valid Mobile Number'
                label='Account number'
                name='account_number'
                required
                size={24}
              />
            </>
          )}
          <FormButton label={'Edit account'} loading={isLoading} />
        </Row>
      </Form>
      {isLoading && <LoadingIndicator />}
    </div>
  );
};

export default AccountsEditModal;
