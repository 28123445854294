import { api } from '../../../../../app/baseQuery';
import { IAllInvoices } from '../../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../../common/types/commonTypes';

export interface IHajjStatus {
  body: { updated_by?: number };
  id: number;
  status: 'approved' | 'canceled';
}

export const hajjReg = api.injectEndpoints({
  endpoints: (build) => ({
    // GET ALL
    getAllInvoiceHajjReg: build.query<
      HTTPResponse<IAllInvoices[]>,
      { current: number; pageSize: number; dateYear?: string }
    >({
      query: (arg) => ({
        url: `/invoice_hajj_pre/haji_pre_reg_infos?page=${arg.current}&size=${arg.pageSize}&month=${arg.dateYear}`,
      }),
      providesTags: () => ['invoices', 'invoice-hajji'],
    }),

    // Update Status
    updateHajjRegStatus: build.mutation<HTTPResponse<void>, IHajjStatus>({
      query: ({ body, id, status }) => ({
        url: `/invoice_hajj_pre/haji_info_status/${id}?status=${status}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: [{ type: 'invoice-hajji' }],
    }),
  }),
});

export const {
  useGetAllInvoiceHajjRegQuery,
  useLazyGetAllInvoiceHajjRegQuery,
  useUpdateHajjRegStatusMutation,
} = hajjReg;
