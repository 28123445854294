import { Button, InputRef, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import LoadingIndicator from '../../components/common/spinner/LoadingIndicator';
import { ISearchType } from '../../modules/Dashboard/interfaces/dashboard.interfaces';

type Props = {
  searchResult: ISearchType[] | undefined;
  isLoading: boolean;
  handleOk: () => void;
};

interface resultType {
  invoice_id: number;
  invoice_no: string;
  invcat_title: string;
  invoice_category_id: number;
  client_name: string;
  client_mobile: string;
  airticket_ticket_no: string;
  passport_name: string;
  // invoice_net_total: number;
}

const SearchInvoices = ({ isLoading, searchResult, handleOk }: Props) => {
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    const timerId = setTimeout(() => {
      inputRef.current && inputRef.current.focus();
    }, 100);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const columns: ColumnsType<ISearchType> = [
    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (curr, data) => {
        return (
          <Link
            onClick={() => handleOk()}
            to={
              findInvoiceRoute(Number(data.invoice_category_id)) +
              '/' +
              'details' +
              '/' +
              data.invoice_id
            }
          >
            {curr}
          </Link>
        );
      },
    },
    {
      title: 'Invoice Type',
      dataIndex: 'invoice_type',
      key: 'invoice_type',
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (curr, data) => {
        return (
          <Link
            onClick={() => handleOk()}
            to={
              data?.invoice_client_id
                ? `/reports/client_ledger?id=${data?.invoice_client_id}`
                : `/reports/combined_ledger?id=${data?.invoice_combined_id}`
            }
          >
            {curr + '/' + data?.entry_id}
          </Link>
        );
      },
    },

    {
      title: 'Sales Price',
      dataIndex: 'invoice_net_total',
      key: 'invoice_net_total',
      align: 'right',
    },

    {
      title: 'Ticket No',
      dataIndex: 'ticket_no',
      key: 'ticket_no',
      render: (curr: string) => {
        return (
          <span>
            {curr?.split(',')?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </span>
        );
      },
    },

    {
      title: 'PNR',
      dataIndex: 'pnr',
      key: 'pnr',
      render: (curr: string) => {
        return (
          <span>
            {curr?.split(',')?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </span>
        );
      },
    },

    {
      title: 'Action',
      align: 'center',
      render: (_: any, data: ISearchType) => {
        return (
          <div>
            {
              <Link
                onClick={() => handleOk()}
                to={
                  findInvoiceRoute(Number(data.invoice_category_id)) +
                  '/' +
                  'details' +
                  '/' +
                  data.invoice_id
                }
              >
                <Button size='small' type='primary'>
                  Details
                </Button>
              </Link>
            }
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {isLoading && <LoadingIndicator />}

      {searchResult?.length ? (
        <Table
          style={{ marginTop: '20px' }}
          columns={columns}
          // rowKey={(e) => e?.invoice_id}
          dataSource={searchResult}
          bordered
          pagination={false}
          loading={isLoading}
          size='small'
          className='invoiceBillingTable'
          rowClassName={'invoiceBillingTd'}
        />
      ) : (
        <div style={{ width: '250px' }}>No Data Found</div>
      )}
    </div>
  );
};

export default SearchInvoices;

export const findInvoiceRoute = (
  invoiceCategoryId: number
): string | undefined => {
  const idToRouteMap: { [key: number]: string } = {
    1: 'airticket',
    2: 'noncomairticket',
    3: 'reissueairticket',
    4: 'invoiceTour',
    5: 'invoiceother',
    10: 'visa',
    26: 'invoiceumrah',
    30: 'hajjpreregistration',
    31: 'invoicehajj',
  };

  return idToRouteMap[invoiceCategoryId];
};
