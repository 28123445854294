import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Space, Tabs, theme } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useGetSingleAirticketRefundQuery } from '../../Api/Endpoints/AirTicketRefundEndpoints';
import AirTicketRefundCopy from './AirTicketRefundCopy';
import AirTicketRefundDetails from './AirTicketRefundDetails';

const AirticketRefundView = () => {
  const { id } = useParams();

  const { data: singleAirticketRefund, isLoading } =
    useGetSingleAirticketRefundQuery(Number(id));

  const airTicketInvoiceData = singleAirticketRefund?.data;

  const componentRefClient = useRef(null);
  const refs: React.MutableRefObject<HTMLDivElement[]> = useRef([]);
  const [index, setIndex] = useState<number>(0);

  const [date, setDate] = useState<string>();

  let client_total_refund_price = 0;
  let client_total_refund_charge = 0;
  let vendor_total_refund_price = 0;
  let vendor_total_refund_charge = 0;

  singleAirticketRefund?.data &&
    singleAirticketRefund?.data.refund_info.map((item: any) => {
      client_total_refund_price =
        client_total_refund_price + Number(item.crefund_total_amount);

      client_total_refund_charge =
        client_total_refund_charge +
        Number(item.rfitem_ticket_client_refund_charge);

      vendor_total_refund_price += Number(item.vrefund_total_amount);

      vendor_total_refund_charge =
        vendor_total_refund_charge + Number(item.vrefund_charge_amount);
    });

  useEffect(() => {
    if (singleAirticketRefund?.data) {
      setDate(
        dayjs(singleAirticketRefund?.data.atrefund_date).format('DD MMM YYYY')
      );
    }
  }, [singleAirticketRefund?.data]);

  const handleClientPrint = useReactToPrint({
    content: () => componentRefClient.current,
    documentTitle: `Refund${date}_$}`,
    pageStyle:
      '@page { size: a4; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 10px !important; } }',
  });
  const handleVendorPrint = useReactToPrint({
    content: () => refs.current[index],
    documentTitle: `Refund${date}_$}`,
    pageStyle:
      '@page { size: a4; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 10px !important; } }',
  });
  const [selectedForPrint, setSelectedForPrint] = React.useState<
    'viewDetails' | 'viewCopy'
  >('viewDetails');

  return (
    <div>
      <BreadCrumb arrOfOption={['Money Receipt', 'View Money Receipt']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/moneyreceipt'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Money Receipt List
          </Button>
        </Link>
      </Space>

      {isLoading ? <LoadingIndicator /> : ''}
      <Tabs
        onChange={(e) => {
          switch (Number(e)) {
            case 1:
              setSelectedForPrint && setSelectedForPrint('viewDetails');
              break;
            case 2:
              setSelectedForPrint && setSelectedForPrint('viewCopy');
              break;

            default:
              break;
          }
        }}
        type='card'
        items={[
          {
            key: '1',
            label: 'Details',
            children: (
              <ConfigProvider
                theme={{
                  algorithm: theme.defaultAlgorithm,
                }}
              >
                <AirTicketRefundDetails data={airTicketInvoiceData} />
              </ConfigProvider>
            ),
          },
          {
            key: '2',
            label: 'Print Copy',
            children: (
              <ConfigProvider
                theme={{
                  algorithm: theme.defaultAlgorithm,
                }}
              >
                <AirTicketRefundCopy data={airTicketInvoiceData} />
              </ConfigProvider>
            ),
          },
        ]}
      ></Tabs>
    </div>
  );
};

export default AirticketRefundView;
