import { Col, Row, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { IClientSales } from '../../interfaces/dashboard.interfaces';

type Props = {
  client_sales: IClientSales | undefined;
  isDarkMode: any;
  formattedStartDate: string;
  formattedEndDate: string;
};

const BSP_Client_Sales = ({
  client_sales,
  isDarkMode,
  formattedStartDate,
  formattedEndDate,
}: Props) => {
  const { Text } = Typography;

  const clientSalesStyle: React.CSSProperties = {
    flex: 1,
    textAlign: 'center',
  };

  return (
    <>
      <Row justify={'space-between'} align={'middle'}>
        <Col span={2}>
          <Typography.Title level={5} style={{ color: '#538392', fontFamily: 'sans-serif', fontWeight: 'bold', textAlign: 'left' }}>
            SALES
          </Typography.Title>
        </Col>
        <Col span={20} style={{ marginTop: 20 }}>
          <Row justify={'space-between'}>
            {['Sales', 'Purchases', 'Profit', 'Received', 'Due'].map(
              (item, i) => (
                <Text
                  style={{
                    borderBottom: `2px solid ${isDarkMode ? '#303030' : ' #c9c9c9'
                      }`,
                    textAlign: 'center',
                    flex: 1,
                  }}
                  strong
                  key={i}
                >
                  {item}
                </Text>
              )
            )}
          </Row>
          {[client_sales].map((item, index) => (
            <Row
              key={index}
              justify={'space-between'}
              style={{ marginBottom: '20px', marginTop: '5px' }}
            >
              <Col style={clientSalesStyle}>
                <Text>{Fixed2(item?.total_sales).toLocaleString()}</Text>
              </Col>
              <Col style={clientSalesStyle}>
                <Text>{Fixed2(item?.total_purchase).toLocaleString()}</Text>
              </Col>
              <Col style={clientSalesStyle}>
                <Text>
                  {(
                    Fixed2(item?.total_sales) - Fixed2(item?.total_purchase)
                  ).toLocaleString()}
                </Text>
              </Col>
              <Col style={clientSalesStyle}>
                <Text>{Fixed2(item?.total_received).toLocaleString()}</Text>
              </Col>
              <Col style={clientSalesStyle}>
                <Text>
                  {(
                    Fixed2(item?.total_sales) - Fixed2(item?.total_received)
                  ).toLocaleString()}
                </Text>
              </Col>
            </Row>
          ))}
        </Col>

        <Col span={2}>
          <Text style={{ marginLeft: 10 }}>
            <Link
              style={{ color: '#2299d5' }}
              to={`/reports/air-ticket-summary?from_date=${formattedStartDate}&to_date=${formattedEndDate}`}
            >
              VIEW ALL
            </Link>
          </Text>
        </Col>
      </Row>
    </>
  );
};

export default BSP_Client_Sales;
