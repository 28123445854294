import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { DueAdvanceDetails } from '../../Api/constants';

export const DueAdvanceDetailsEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all SaleaManCommissionReport
    getDueAdvanceDetails: build.mutation<
      HTTPResponse<ITotalDueAdvanceDetailsAll>,
      {
        comb_client: string;
        data_type: 'CLIENT' | 'DETAILS' | 'AIRLINE';
        airline_id: string;
        search: string;
        from_date?: string;
        to_date: string;
        current: number;
        pageSize: number;
      }
    >({
      query: ({
        airline_id,
        comb_client,
        data_type,
        from_date,
        search,
        to_date,
        current,
        pageSize,
      }) => ({
        url: `/report/due-advance-details?page=${current}&size=${pageSize}`,
        method: 'POST',
        body: {
          airline_id,
          comb_client,
          data_type,
          from_date,
          search,
          to_date,
        },
      }),
      invalidatesTags: [{ type: 'DueAdvanceDetails', id: DueAdvanceDetails }],
    }),

    getClientAdvance: build.query<
      HTTPResponse<IGetClientAdvance>,
      {
        current: number;
        pageSize: number;
        search?: string;
        data_type: 'ADVANCE' | 'DUE';
      }
    >({
      query: ({ current, pageSize, search, data_type }) => ({
        url: `/report/cl-due-advance?page=${current}&size=${pageSize}&search=${search}&data_type=${data_type}`,
        method: 'GET',
      }),
      providesTags: [{ type: 'DueAdvanceDetails', id: DueAdvanceDetails }],
    }),
  }),
});

export const { useGetDueAdvanceDetailsMutation, useGetClientAdvanceQuery } =
  DueAdvanceDetailsEndpoints;

export interface ITotalDueAdvanceDetailsAll {
  results: ITotalDueAdvanceDetails[];
  total: ITotalDueAdvanceDetailsTotal;
}

export interface ITotalDueAdvanceDetails {
  invoice_org_agency: number;
  invoice_client_id?: number;
  invoice_combined_id?: number | null;
  client_name?: string;
  purchase: string;
  sales: string;
  pay: string;
  due: string;
  profit: string;
  airlines_code?: string | null;
  invoice_id?: number;
  invoice_no?: string;
  invoice_category_id: number;
  invoice_sales_date?: string;
  airline_id?: number | null;
  invoice_discount: string;
  overall_profit: string;
  service_charge: string;
  airline_name?: string | null;
}

export interface ITotalDueAdvanceDetailsTotal {
  purchase: string;
  sales: string;
  pay: string;
  due: string;
  invoice_discount: string;
  profit: string;
  last_balance: string;
}

export interface IGetClientAdvance {
  results: ClientAdvanceResults[];
  total_balance: number;
}
export interface ClientAdvanceResults {
  client_id: number;
  combine_id: number;
  client_category_id: number;
  client_entry_id: string;
  client_type: string;
  client_name: string;
  client_lbalance: string;
  client_created_date: string;
}
