import { Button, Form, Input, InputNumber, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { selectCommon, setModal } from '../../../../common/slices/commonSlice';
import { ISetState } from '../../../../common/types/commonTypes';
import { useSendSMSMutation } from '../../../SMS System/Api/Endpoints/SMSEndpoints';
import { ISMSFormData } from '../../../SMS System/SMSTypes/SMSTypes';
import {
  ICombineClientView,
  ISMSCombineFormData,
} from '../types/combineClientTypes';

interface IProps {
  info: ICombineClientView;
  setNull: ISetState<ICombineClientView | null>;
}
const CombineClientSmsModal = ({ info, setNull }: IProps) => {
  const dispatch = useAppDispatch();
  const modalSlice = useSelector(selectCommon);
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const {
    combine_id,
    combine_name,
    combine_lastbalance_amount,
    combine_mobile,
  } = info;

  useEffect(() => {
    form.setFieldsValue({
      combine_name: combine_name,
      combine_lastbalance_amount: -(
        combine_lastbalance_amount != undefined && combine_lastbalance_amount
      ),
      combine_mobile: combine_mobile,
    });
  }, [info]);

  const [sendSMS, { isError }] = useSendSMSMutation();
  const handleOnSubmit = async (values: ISMSCombineFormData) => {
    const body: ISMSFormData = {
      client_mobile: values.combine_mobile,
      client_id: `comb-${values.combine_id}`,
      text_type: 'TEXT',
      message: `Dear ${values.combine_name}, You have a DUE of ${values.combine_lastbalance_amount}. Kindly clear your dues.`,
      date: dayjs().format('YYYY-MM-DD'),
      created_by: user?.user_id as number,
    };

    await sendSMS([body]);
    setNull(null);
    dispatch(setModal(false));
  };

  return (
    <div>
      <Form layout='vertical' onFinish={handleOnSubmit} form={form}>
        <Form.Item name='combine_name' label='Name:'>
          <Input readOnly />
        </Form.Item>
        <Form.Item name='combine_lastbalance_amount' label='Due Amount : '>
          <InputNumber readOnly />
        </Form.Item>
        <Form.Item name='combine_mobile' label='Mobile : '>
          <Input readOnly />
        </Form.Item>
        <Typography.Text strong>
          Are you sure to send sms to this Customer ?
        </Typography.Text>
        <Form.Item style={{ marginTop: '0.5rem' }}>
          <Button type='primary' htmlType='submit'>
            {modalSlice.isModal}Send SMS
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CombineClientSmsModal;
