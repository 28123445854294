import { ConfigProvider, Table, theme, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Link, useParams } from 'react-router-dom';
import {
  IViewInvoiceDetails,
  IViewMoneyReceipt,
} from '../../../../common/types/commonInterfaces';
import { separateClient } from '../../../../common/utils/common.utils';
import { useGetInvoicePaymentDetailsQuery } from '../../../../modules/IATA_AIR_TICKET/api/endpoints/airticketInvoiceEndpoints';
import ClientInfo from '../ClientInfo';
import { invoiceViewStyle } from './CommonViewInvoice';
import { TitleCenter } from '../../../../common/Invoice/InvoiceHeader';

type Props = {
  invoiceDetails: IViewInvoiceDetails | undefined;
  viewInvoicePaymentsRef: React.RefObject<HTMLDivElement>;
};
const TabPaymentView = ({ invoiceDetails, viewInvoicePaymentsRef }: Props) => {
  const { id } = useParams();
  const { data } = useGetInvoicePaymentDetailsQuery({ invoice_id: id! });

  const moneyReceiptColumn: ColumnsType<IViewMoneyReceipt> = [
    {
      title: 'Sl',

      render: (_, data, index) => <> {index + 1} </>,
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (value, record) => {
        const { client_id, combined_id } = separateClient(record?.comb_client);

        return (
          <Link
            to={
              client_id
                ? `/reports/client_ledger?id=${client_id}`
                : `/reports/combined_ledger?id=${combined_id}`
            }
          >
            <span>{record.client_name}</span>
          </Link>
        );
      },
      width: 150,
    },

    {
      title: 'Money Recipt No.',
      dataIndex: 'receipt_money_receipt_no',
      align: 'center',
      render: (mr_no, data) => {
        if (data.invclientpayment_moneyreceipt_id) {
          return (
            <Link
              to={`/moneyreceipt/view/${data.invclientpayment_moneyreceipt_id}`}
            >
              {mr_no}
            </Link>
          );
        } else {
          return mr_no;
        }
      },
    },
    {
      title: 'Payment To',
      align: 'center',
      dataIndex: 'receipt_payment_to',
      key: 'receipt_payment_to',
    },
    {
      title: 'Method',
      dataIndex: 'acctype_name',
      key: 'acctype_name',
      align: 'center',
    },
    {
      title: 'Note',
      dataIndex: 'receipt_note',
      key: 'receipt_note',
      align: 'center',
    },

    {
      title: 'Amount',
      dataIndex: 'receipt_total_amount',
      key: 'receipt_total_amount',
      align: 'right',
      render(value, record, index) {
        return Number(value || 0);
      },
    },

    {
      title: 'Received By',
      dataIndex: 'received_by',
      key: 'received_by',
    },

    {
      title: 'Receipt Date',
      dataIndex: 'receipt_payment_date',
      key: 'receipt_payment_date',
      align: 'center',
      render: (curr) => (
        <>
          {' '}
          {dayjs(curr).isValid()
            ? dayjs(curr).format('DD MMM YYYY')
            : 'N/A'}{' '}
        </>
      ),
    },
  ];
  return (
    <div style={invoiceViewStyle}>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <div
          style={{
            width: '8.27in',
            height: '11.69in',
            background: '#fff',
            padding: '10px',
          }}
          ref={viewInvoicePaymentsRef}
        >
          {invoiceDetails && <ClientInfo invoice_info={invoiceDetails} />}

          <TitleCenter title='PAYMENT DETAILS' />

          <Table
            size='small'
            bordered
            rowKey={(e) => e.receipt_money_receipt_no}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={data?.data}
            columns={moneyReceiptColumn}
            pagination={false}
          />
        </div>
      </ConfigProvider>
    </div>
  );
};

export default TabPaymentView;
