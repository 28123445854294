import { Button, Col, Form, FormListFieldData, Row, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Fixed2 } from '../../../common/utils/common.utils';
import {
  DateInput,
  FormInput,
  NumberInput,
} from '../../../components/common/FormItem/FormItems';
import {
  SelectCountry,
  SelectOptionsInput,
  SelectProduct,
  SelectVendors,
  SelectVisaType,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import { useLazyGetProductWiseVendorsAndCombinedQuery } from '../../vendor/api/endpoints/vendorEndpoints';
import { IBillingInfo } from '../Type/invoiceVisa.interface';
import BillingIsDeleted from './BillingIsDeleted';
import dayjs from 'dayjs';

type Props = {
  form: FormInstance<any>;
  index: number;
  remove: (index: number | number[]) => void;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  field: FormListFieldData;
  billing_information?: IBillingInfo[];
  conditionalCostVendor: boolean | undefined;
};

const BillingAndPassInfoForm = ({
  form,
  add,
  index,
  remove,
  field,
  billing_information,
  conditionalCostVendor,
}: Props) => {
  const { id } = useParams();
  const [instanceVendorSelect, setInstanceVendorSelect] = useState<number>();
  const [productWiseVendor, { data: productWiseVendors }] =
    useLazyGetProductWiseVendorsAndCombinedQuery();
  const ref = useRef(false);
  const discount: number = useWatch(['invoice_discount'], form);
  const serviceCharge: number = useWatch(['invoice_service_charge'], form);
  const vat: number = useWatch(['invoice_vat'], form);
  const netCost = useWatch(
    ['billing_information', index, 'billing_cost_price'],
    form
  );
  const netSales = useWatch(
    ['billing_information', index, 'billing_unit_price'],
    form
  );
  const quantity = useWatch(
    ['billing_information', index, 'billing_quantity'],
    form
  );
  const billing_product_id = useWatch(
    ['billing_information', index, 'billing_product_id'],
    form
  );
  const billing_information_current: IBillingInfo[] = useWatch(
    ['billing_information'],
    form
  );
  useEffect(() => {
    const tourBillingSaleSubTotal = billing_information_current?.reduce(
      (a, b) =>
        a + Fixed2(b?.is_deleted !== 1 ? Fixed2(b?.billing_subtotal) : 0),
      0
    );
    const netTotal =
      tourBillingSaleSubTotal -
      Number(discount || 0) +
      Number(serviceCharge || 0) +
      Number(vat || 0);

    form?.setFieldsValue({
      invoice_sub_total: tourBillingSaleSubTotal,
      invoice_net_total: netTotal,
    });
  }, [billing_information_current, discount, vat, serviceCharge]);

  // @SET TOTAL SALES, COST, AND SET PROFIT
  useEffect(() => {
    const totalSales = Fixed2(Number(quantity || 0) * Number(netSales || 0));
    const totalCost = Fixed2(Number(quantity || 0) * Number(netCost || 0));
    const profit = totalSales - totalCost;
    form.setFields([
      {
        name: ['billing_information', index, 'billing_total_cost_price'],
        value: totalCost,
      },
      {
        name: ['billing_information', index, 'billing_subtotal'],
        value: totalSales,
      },
      {
        name: ['billing_information', index, 'billing_profit'],
        value: profit,
      },
    ]);
  }, [quantity, netCost, netSales]);
  const [productId, setProductId] = useState<number>();
  useEffect(() => {
    if (productId) {
      ref.current = true;
      form.setFieldValue(
        ['billing_information', index, 'billing_comvendor'],
        undefined
      );
    }
  }, [productId]);
  useEffect(() => {
    if (billing_product_id !== undefined) {
      productWiseVendor(billing_product_id, true);
      if (
        !(ref.current === true) &&
        id &&
        billing_information?.length === billing_information_current?.length
      ) {
        setTimeout(() => {
          form.setFieldsValue({
            billing_information: billing_information?.length
              ? [...billing_information]?.map((item) => {
                  return {
                    ...item,
                    billing_delivery_date: item?.billing_delivery_date
                      ? dayjs(item?.billing_delivery_date)
                      : undefined,
                  };
                })
              : [{}],
          });
        }, 1000);
      }
    } else {
      // THIS IS ONLY FOR WHEN DESELECT PRODUCT, THEN VENDOR DATA WILL NULL
      productWiseVendor(5456789798765164164, true);
      form.setFieldValue(
        ['billing_information', index, 'billing_comvendor'],
        undefined
      );
    }
  }, [billing_product_id]);
  useEffect(() => {
    form.setFieldValue(
      ['billing_information', index, 'billing_product_id'],
      114
    );
  }, []);
  useEffect(() => {
    if (instanceVendorSelect) {
      form.setFieldValue(
        ['billing_information', index, 'billing_comvendor'],
        'vendor-' + instanceVendorSelect
      );
    }
  }, [instanceVendorSelect]);

  // @HANDLE APPROVED DISABLED
  const [disabled, setDisabled] = useState(false);

  const billing_status =
    billing_information && billing_information[index]?.billing_status;
  const is_deleted: 0 | 1 = useWatch(
    ['billing_information', index, 'is_deleted'],
    form
  );

  useEffect(() => {
    if (is_deleted === 1) {
      setDisabled(true);
    }
  }, [is_deleted]);

  useEffect(() => {
    if (!id) {
      form.setFields([
        {
          name: ['billing_information', index, 'billing_status'],
          value: 'Approved',
        },
      ]);
    }
  }, []);

  const isVendorSelected = useWatch(
    ['billing_information', field.name, 'billing_comvendor'],
    form
  );

  const isCostPrice = useWatch(
    ['billing_information', field.name, 'billing_cost_price'],
    form
  );

  return (
    <Row
      style={is_deleted === 1 ? { backgroundColor: '#FFC0CB' } : {}}
      align='bottom'
      gutter={[10, 0]}
    >
      <SelectProduct
        label='Product'
        name={[index, 'billing_product_id']}
        size={3}
        mdSize={8}
        required
        disabled={disabled}
        setProductId={setProductId}
      />

      <SelectCountry
        label='Country'
        name={[index, 'billing_visiting_country_id']}
        required
        size={3}
        disabled={disabled}
      />

      <SelectVisaType
        label='Visa Type'
        required
        name={[index, 'billing_visa_type_id']}
        size={3}
        mdSize={8}
        smSize={12}
        disabled={disabled}
      />

      <FormInput
        label='Token'
        name={[index, 'billing_token']}
        size={3}
        max={14}
        disabled={disabled}
      />

      <DateInput
        label='Delivery'
        name={[index, 'billing_delivery_date']}
        size={3}
        disabled={disabled}
      />

      <FormInput
        label='Visa No'
        name={[index, 'billing_visa_no']}
        size={3}
        disabled={disabled}
      />
      <FormInput
        label='Mofa No'
        name={[index, 'billing_mofa_no']}
        size={3}
        disabled={disabled}
      />
      <FormInput
        label='Okala No'
        name={[index, 'billing_okala_no']}
        size={3}
        disabled={disabled}
      />

      <NumberInput
        name={[index, 'billing_quantity']}
        label='Quantity'
        required
        size={3}
        min='0'
        maxChar={10}
        disabled={disabled}
      />

      <NumberInput
        name={[index, 'billing_unit_price']}
        label='Unit Price'
        required
        size={3}
        min='0'
        maxChar={14}
        disabled={disabled}
      />

      <NumberInput
        name={[index, 'billing_subtotal']}
        label='Total Sales'
        size={3}
        readOnly
        min='0'
        maxChar={14}
        disabled={disabled}
      />

      <NumberInput
        label='Cost Price'
        name={[index, 'billing_cost_price']}
        required={conditionalCostVendor ? isVendorSelected : true}
        size={3}
        min='0'
        maxChar={14}
        disabled={disabled}
      />
      <NumberInput
        label='Total Cost Price'
        name={[index, 'billing_total_cost_price']}
        readOnly
        size={3}
        min='0'
        maxChar={14}
        disabled={disabled}
      />

      <NumberInput
        label='Profit'
        name={[index, 'billing_profit']}
        size={3}
        readOnly
        min='0'
        maxChar={14}
        disabled={disabled}
      />

      <SelectVendors
        required={conditionalCostVendor ? isCostPrice : true}
        label='Vendor'
        name={[index, 'billing_comvendor']}
        size={3}
        dependencies={['invoice_combclient_id']}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value: string) {
              if (value && getFieldValue('invoice_combclient_id') === value) {
                return Promise.reject();
              } else {
                return Promise.resolve();
              }
            },
            message: "Client and vendor can't be same!",
          }),
        ]}
        manualData={productWiseVendors?.data || []}
        setInstanceVendorSelect={setInstanceVendorSelect}
        disabled={disabled}
      />
      <SelectOptionsInput
        label='Status'
        name={[index, 'billing_status']}
        options={['Pending', 'Approved']}
        size={3}
        required
        disabled={disabled}
      />

      {/* BILLING DELETE DETECT BY THIS is_deleted FIELD */}
      <BillingIsDeleted name={[index, 'is_deleted']} />

      {index === 0 ? (
        <Col lg={3}>
          <Form.Item label={' '}>
            <Button type='primary' onClick={() => add()}>
              <PlusOutlined />
            </Button>
          </Form.Item>
        </Col>
      ) : (
        !disabled && (
          <Col lg={3}>
            <Form.Item label={' '}>
              <Button
                type='primary'
                danger
                onClick={() => {
                  form.setFieldValue(
                    ['billing_information', index, 'is_deleted'],
                    1
                  );
                  if (id) {
                    if (
                      billing_information?.length &&
                      index >= billing_information.length
                    )
                      remove(field.name);
                  } else {
                    remove(field.name);
                  }
                }}
              >
                <MinusCircleOutlined />
              </Button>
            </Form.Item>
          </Col>
        )
      )}
    </Row>
  );
};

export default BillingAndPassInfoForm;
