import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { crud, perProps } from '../../../../common/types/commonTypes';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import { removeSpaceBeforeWord } from '../../../../components/common/Invoice/InvoiceListHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllAuthorityQuery } from '../../Api/Endpoints/AuthorityEndpoints';
import { LoanAuthorityDataType } from '../../LoanTypes/LoanTypes';
import AddAuthorityModal from '../../Modals/Authority/AddAuthorityModal';
import EditAuthorityModal from '../../Modals/Authority/EditAuthorityModal';
import { ViewAuthorityList } from '../../Utils/Authority/ViewAuthorityList';
import { rangePresets } from '../../../../common/utils/common.utils';

const ListAuthority = ({ permission }: perProps) => {
  const dispatch = useAppDispatch();
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  const [editInfo, setEditInfo] = useState<LoanAuthorityDataType | null>(null);

  const showModal = () => {
    dispatch(setModal(true));
  };

  const { data, isLoading, refetch } = useGetAllAuthorityQuery({
    ...pagination,
    from: date[0] || '',
    to: date[1] || '',
    search: search,
  });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 50,
    }));
  }, [date, search]);

  // Handle Search Operation
  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Loan Management', 'Authority List']}
        refetch={() => refetch()}
        reloaderSize='small'
      />

      <Row justify={'space-between'} style={{ marginBottom: '1rem' }}>
        {permission?.[crud.create] && (
          <Button type='primary' onClick={showModal}>
            <PlusOutlined />
            Add Authority
          </Button>
        )}

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Here...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <CommonTableHeader
        title=''
        modalTitle={editInfo ? 'Edit Authority' : 'Add Authority'}
        element={
          editInfo ? (
            <EditAuthorityModal info={editInfo} setNull={setEditInfo} />
          ) : (
            <AddAuthorityModal />
          )
        }
        setNull={setEditInfo}
      />
      <Table
        size='small'
        bordered
        columns={ViewAuthorityList({
          setEditInfo,
          showModal,
          permission,
          pagination,
        })}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        dataSource={data?.data}
        scroll={{ x: true }}
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
      />
    </div>
  );
};

export default ListAuthority;
