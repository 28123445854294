import { Col, Row, Typography } from 'antd';
import { useAppSelector } from '../../../../app/hooks';
import { ILedgerClient } from '../types/clientLedgerTypes';

type Props = {
  data?: ILedgerClient | undefined;
  date_range: any;
};

const LedgerHeader = ({ data, date_range }: Props) => {
  const { Text } = Typography;
  const currency = useAppSelector(
    (state) => state.user?.organization_info.org_currency
  );

  return (
    <Row>
      <div
        style={{
          border: '1px solid lightgray',
          borderRadius: 5,
          padding: '10px 20px',
          backgroundColor: '#f2f2f2',
          width: '100%',
        }}
      >
        <Row justify={'center'}>
          <Text
            style={{
              margin: '10px 0',
              fontWeight: 'bold',
              fontSize: 15,
              letterSpacing: 1.5,
            }}
          >
            STATEMENT OF ACCOUNTS
          </Text>
        </Row>

        <Row justify='space-between' style={{ padding: '0 20px' }}>
          <Col lg={12} className='mb-5'>
            <h4 className='tt-uppercase bold' style={{ letterSpacing: 1 }}>
              {data?.client_name}
            </h4>
            <Text style={{ letterSpacing: 1, display: 'block' }}>
              <Text strong>Reference : </Text>
              {data?.client_entry_id}
            </Text>
            <Text style={{ letterSpacing: 1, display: 'block' }}>
              <Text strong>Email : </Text>
              {data?.client_email}
            </Text>
          </Col>
          <Col lg={12} className='mb-5'>
            <Text style={{ letterSpacing: 1, display: 'block' }}>
              <Text strong>Currency : </Text>
              {currency?.toUpperCase()}
            </Text>
            <Text style={{ letterSpacing: 1, display: 'block' }}>
              <Text strong>Department : </Text>
              {data?.category_title}
            </Text>
            <Text style={{ letterSpacing: 1, display: 'block' }}>
              <Text strong>Period : </Text>
              {date_range?.length && date_range[0].format('DD/MM/YYYY')}-
              {date_range?.length && date_range[1].format('DD/MM/YYYY')}
            </Text>
          </Col>
        </Row>
      </div>
    </Row>
  );
};

export default LedgerHeader;
