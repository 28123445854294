import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { IActivityDataType } from '../types/activityLogsTypes';
import { Link } from 'react-router-dom';
import { getLedgerRefLink } from '../../ledgers/utils/ledgerUtils';

const ActivityClientColumn = (pagination: {
  current: number;
  pageSize: number;
}): ColumnsType<IActivityDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
        1 +
        index,
    },

    {
      title: 'Voucher',
      dataIndex: 'audit_voucher',
      key: 'audit_voucher',

      render: (filed, rec) => {
        const link = getLedgerRefLink(rec.audit_voucher, rec.audit_ref_id);

        return link ? (
          <Link to={link} state={`/reports/activity_logs`}>
            {filed}
          </Link>
        ) : (
          <span>{filed}</span>
        );
      },
    },

    {
      title: 'Action',
      dataIndex: 'audit_action',
      key: 'audit_action',

      render: (_, data) =>
        data.audit_action == 'update' ? (
          <Tag color='green-inverse'>{data?.audit_action.toUpperCase()}</Tag>
        ) : data?.audit_action == 'delete' ? (
          <Tag color='error'>{data?.audit_action.toUpperCase()}</Tag>
        ) : (
          <Tag color='blue'>{data?.audit_action.toUpperCase()}</Tag>
        ),
    },

    {
      title: 'Module',
      dataIndex: 'audit_module_type',
      key: 'audit_module_type',
    },

    {
      title: 'Descriptions',
      dataIndex: 'audit_content',
      key: 'audit_content',
    },

    {
      title: 'Date & Time',
      dataIndex: 'audit_action_date_time',
      key: 'audit_action_date_time',

      render: (_, record) => {
        return `${dayjs(record.audit_action_date_time).format(
          'DD/MM/YYYY hh:mm A'
        )}`;
      },
      align: 'center',
    },

    {
      title: 'User Name',
      dataIndex: 'user_name',
      key: 'user_name',
    },
  ];
};

export default ActivityClientColumn;
