import { Col, Form, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { FormInputItem } from '../../../../components/common/FormItem/FormItems';
import { usePostEditAppConfigMutation } from '../endpoint/appConfigEndpoint';
import { IGetAppConfig } from '../type/appConfig_Type';

type props = {
  configDetails: IGetAppConfig | undefined;
  setConfigDetails: React.Dispatch<
    React.SetStateAction<IGetAppConfig | undefined>
  >;
};
const TotalWorkingDay = ({ configDetails }: props) => {
  const [editPost] = usePostEditAppConfigMutation();
  const [form] = Form.useForm();

  const defaultValue = configDetails?.tac_wk_day;

  useEffect(() => {
    form.setFieldValue('tac_wk_day', defaultValue);
  }, [defaultValue]);

  const handleSubmit = (e: any) => {
    const body = {
      ...configDetails,
      tac_wk_day: e.target.value,
    };

    editPost(body);
  };

  return (
    <div
      style={{
        maxWidth: 900,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Row justify={'space-between'}>
        <Col>
          <Typography.Title level={4}>Total Working Day:</Typography.Title>
          <Typography.Text type='secondary'>
            This helps for creating payroll calculation
          </Typography.Text>
        </Col>

        <Form
          layout='vertical'
          form={form}
          onFinish={handleSubmit}
          onBlur={handleSubmit}
        >
          <Col>
            <FormInputItem
              name={'tac_wk_day'}
              placeholder='Total Working Days'
              size={24}
            />
          </Col>
        </Form>
      </Row>
    </div>
  );
};

export default TotalWorkingDay;
