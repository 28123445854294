import dayjs, { Dayjs } from 'dayjs';
import { TUser } from '../states/userSlice';

const lib = {
  getLocalStorage(key: string) {
    const localStoreItem = localStorage.getItem(key) as string;

    if (localStoreItem) {
      return JSON.parse(localStoreItem);
    } else {
      return null;
    }
  },

  setLocalStorage(key: string, value: TUser | string | number) {
    const parsedValue = JSON.stringify(value);

    localStorage.setItem(key, parsedValue);
  },

  removeLocalStorageItem(key: string) {
    localStorage.removeItem(key);
  },
};

export default lib;

export function isObjectEmpty(obj: object) {
  return Object?.keys(obj)?.length === 0;
}

export function formatQueryParams(params: any) {
  const searchParams = new URLSearchParams();

  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined && params[key] !== null && params[key]) {
      searchParams.append(key, params[key]);
    }
  });

  return searchParams.toString();
}

export const FormatDateV1 = (date: string | Dayjs | Date | undefined): any => {
  if (!date) return undefined;
  return dayjs(date).isValid() ? dayjs(date).format('YYYY-MM-DD') : undefined;
};

export const checkDate = (date?: string | null): boolean => {
  if (date === 'undefined') return false;
  if (date === '') return false;
  if (date === 'No Valid Date') return false;

  const isValidDate = dayjs(date, 'YYYY-MM-DD', true).isValid();
  return isValidDate;
};

export const formatDateRange = (dateRange: string | string[]) => {
  return {
    from_date: Array.isArray(dateRange)
      ? dayjs(dateRange[0]).format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD'),
    to_date: Array.isArray(dateRange)
      ? dayjs(dateRange[1]).format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD'),
  };
};

export const parseDateRange = (jsonString: string) => {
  try {
    const { from_date, to_date } = JSON.parse(jsonString);
    return [dayjs(from_date), dayjs(to_date)];
  } catch (error) {
    return false;
  }
};
