import { Button, Drawer, Form, message, Table } from 'antd';
import { UploadFile } from 'antd/lib';
import { useEffect, useState } from 'react';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import SignatureColumns from '../component/Signature/SignatureColumns';
import SignatureFormItem from '../component/Signature/SignatureFormItem';
import {
  useGetAllEmployeeSignatureQuery,
  useGetEmployeeSignatureStatusMutation,
  usePostEmployeeSignatureMutation,
  useUpdateEmployeeSignatureMutation,
} from '../endpoint/appConfigEndpoint';
import { IGetAllSignature } from '../type/appConfig_Type';

const Employee_Signature = () => {
  const [editInfo, setEditInfo] = useState<IGetAllSignature>();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const { data, isLoading, isFetching, refetch } =
    useGetAllEmployeeSignatureQuery({ ...pagination });

  const [changeStatus, { isLoading: statusLoading, isError: error_status }] =
    useGetEmployeeSignatureStatusMutation();

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  useEffect(() => {
    if (error_status) {
      message.error('Failed to change signature status');
    }
  }, [error_status]);

  return (
    <>
      <BreadCrumb
        arrOfOption={[
          'Configuration',
          'App Configuration',
          'Signature Details',
        ]}
        refetch={refetch}
      />
      <FormHeaderTitle title='Employee Signature Details' />
      <AddEmployeeSignatureDetails
        editInfo={editInfo}
        setEditInfo={setEditInfo}
      />
      <Table
        size='small'
        bordered
        columns={SignatureColumns({
          pagination,
          setEditInfo,
          statusLoading,
          changeStatus,
        })}
        dataSource={data?.data}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        pagination={
          data?.count && data.count > 20
            ? {
                ...pagination,
                total: data.count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
            : false
        }
      />
    </>
  );
};

export default Employee_Signature;

type Props = {
  editInfo: IGetAllSignature | undefined;
  setEditInfo: React.Dispatch<
    React.SetStateAction<IGetAllSignature | undefined>
  >;
};
const AddEmployeeSignatureDetails = ({ editInfo, setEditInfo }: Props) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [image, setImage] = useState('');

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setEditInfo(undefined);
    setOpen(false);
    setFileList([]);
  };

  const [postData, { isLoading, isSuccess, isError }] =
    usePostEmployeeSignatureMutation();

  const [
    postEdit,
    { isLoading: editLoading, isSuccess: editSuccess, isError: editError },
  ] = useUpdateEmployeeSignatureMutation();

  useEffect(() => {
    if (editInfo) {
      showDrawer();
      form.setFieldsValue({
        ...editInfo,
      });
      setFileList([
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: editInfo.sig_signature,
        },
      ]);
    }
  }, [editInfo]);

  const onSubmit = (values: EmployeeSignatureType) => {
    const { sig_signature, ...others } = values;

    const formData = new FormData();

    for (const key in others) {
      const value = values[key as keyof EmployeeSignatureType];

      if (value !== undefined && value !== null) {
        formData.append(key, value.toString());
      }
    }

    if (fileList[0]?.url || fileList[0]?.originFileObj) {
      formData.append(
        'sig_signature',
        fileList[0]?.url! || fileList[0]?.originFileObj!
      );
    }

    // for (let entry of formData.entries()) {
    //   console.log(entry[0], entry[1]);
    // }

    if (editInfo) {
      postEdit({ id: editInfo.sig_id, body: formData });
    } else {
      postData(formData);
    }
  };

  useEffect(() => {
    if (isSuccess || editSuccess) {
      onClose();
    } else if (isError || editError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, editSuccess, isError, editError]);

  return (
    <>
      <Button
        style={{ marginBottom: '10px' }}
        type='primary'
        onClick={showDrawer}
      >
        Add New Details
      </Button>

      <Drawer
        title={editInfo ? 'Edit signature details' : 'Add signature details'}
        onClose={onClose}
        open={open}
        width={500}
      >
        <Form form={form} layout='vertical' onFinish={onSubmit}>
          <SignatureFormItem
            form={form}
            setFileList={setFileList}
            fileList={fileList}
          />
          <FormButton
            label={editInfo ? 'Update' : 'Create'}
            loading={isLoading || editLoading}
          />
        </Form>
      </Drawer>
    </>
  );
};

export interface EmployeeSignatureType {
  sig_employee_id: number;
  sig_user_id: number;
  sig_type: 'AUTHORITY' | 'SALES' | 'PREPARE';
  sig_name_title: string;
  sig_position: string;
  sig_company_name: string;
  sig_address: string;
  sig_city: string;
  sig_state: string;
  sig_zip_code: string;
  string: string;
  sig_email: string;
  sig_signature: UploadFile;
}
