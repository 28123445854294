import { Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import {
  DateInput,
  FormInput,
  FormInputItem,
} from '../../../components/common/FormItem/FormItems';
import {
  SelectAirline,
  SelectAirport,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import { ITicket_information } from '../Types/InvoiceUmrahTypes';

type Props = {
  name: number;
  index: number;
  form: FormInstance<any>;
};

const TicketInfoUmrah = ({ index, name, form }: Props) => {
  return (
    <div>
      <Row gutter={[20, 20]}>
        <FormInput
          name={[name, 'ticket_no']}
          label='Ticket No'
          size={6}
          number
          rules={[
            {
              validator(rule, value, callback) {
                const fullList: ITicket_information[] = form.getFieldValue([
                  'ticketInfo',
                ]);
                const filtered = fullList?.filter((item, i) => {
                  if (value) {
                    if (item?.ticket_no === value) {
                      if (index === i) {
                        return;
                      }
                      return item;
                    }
                  }
                });

                if (filtered?.length) {
                  return Promise.reject('Cannot select duplicate ticket Num!!');
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        />

        <FormInput name={[name, 'ticket_pnr']} label='PNR' size={6} />

        <SelectAirport
          label='Route'
          name={[name, 'ticket_route']}
          size={6}
          form={form}
          fullNamePath={['passenget_info', name, 'ticket_route']}
        />

        <SelectAirline
          name={[name, 'ticket_airline_id']}
          label='Airline'
          size={6}
        />

        <FormInputItem
          label='Reference No'
          name={[name, 'ticket_reference_no']}
          size={6}
        />

        <DateInput
          name={[name, 'ticket_journey_date']}
          label='Journey Date'
          size={6}
        />

        <DateInput
          name={[name, 'ticket_return_date']}
          label='Return Date'
          size={6}
        />
      </Row>
    </div>
  );
};

export default TicketInfoUmrah;
