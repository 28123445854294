import { PrinterOutlined } from '@ant-design/icons';
import { Button, Form, Space, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { IClientDiscountFormDataType } from '../../ClientDiscount/types/ClientDiscountTypes';
import { useLazyGetExcelQuery } from '../../ledgers/endpoints/ledgerEndpoints';
import { useGetMoneyReceiptDisQuery } from '../endpoints/InvoiceMoneyRecDiscountEndpoint';
import InvoiceMoneyReceiptDisCol from '../utils/InvoiceMoneyReceiptDisCol';
import { Fixed2 } from '../../../../common/utils/common.utils';

const InvoiceMoneyRecDiscount = () => {
  const currDate = dayjs().format('YYYY-MM-DD');
  let total_net_total = 0;
  let total_discount = 0;

  const searchParams = new URLSearchParams(location.search);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const [date, setDate] = useState({ formDate: currDate, toDate: currDate });

  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: '',
    query: '',
  });

  const { data, isLoading, isFetching } = useGetMoneyReceiptDisQuery({
    formDate: date.formDate,
    toDate: date.toDate,
    page: pagination.current,
    size: pagination.pageSize,
  });

  useEffect(() => {
    if (searchParams && location.search) {
      const formDate = searchParams.get('from_date');
      const toDate = searchParams.get('to_date');
      form.setFieldValue('date_range', [dayjs(formDate), dayjs(toDate)]);
      form.submit();
    } else {
      form.setFieldValue('date_range', [dayjs(), dayjs()]);
    }
  }, []);

  const onFinish = async (values: IClientDiscountFormDataType) => {
    setDate({ formDate: values.date_range[0], toDate: values.date_range[1] });
    // setExcelQuery({
    //   Id: body.invoice_combclient_id.toString(),
    //   query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    // });
  };

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const tablePagination =
    data?.data?.length !== undefined && data?.data?.length < 20
      ? false
      : {
        ...pagination,
        total: data?.data?.length,
        showSizeChanger: true,
        pageSizeOptions: ['50', '100', '200', '500'],
        onChange: handlePaginationChange,
      };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Money Receipt Expense_Report`,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <Table
      columns={InvoiceMoneyReceiptDisCol(pagination)}
      bordered
      size='small'
      dataSource={data?.data}
      loading={{
        spinning: isLoading || isFetching,
        indicator: loadingIndicator,
      }}
      pagination={tablePagination}
      summary={(data) => {
        data.map((item) => {
          total_net_total += Number(item.invoice_net_total);
          total_discount += Number(item.invoice_discount);
        });

        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={4} align='right'>
                <Typography
                  style={{
                    textAlign: 'right',
                    marginRight: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  Total:
                </Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7} align='right'>
                <Typography
                  style={{
                    textAlign: 'right',
                    marginRight: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  {Fixed2(total_net_total).toLocaleString()}
                </Typography>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={7} align='right'>
                <Typography
                  style={{
                    textAlign: 'right',
                    marginRight: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  {Fixed2(total_discount).toLocaleString()}
                </Typography>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );

  // // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Invoice Money Receipt Discount' }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'Invoice Money Receipt Discount']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        {/* <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: 'clientDiscount',
              excelName: 'clientDiscount',
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button> */}
      </Space>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <ReportsHeader
          date_range
          required
          reports_title='Money Receipt Discount'
        />
      </Form>

      <>
        <Typography.Text>
          You have total: {data?.data?.length} rows
        </Typography.Text>

        {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
        {jsx_com}
      </>

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default InvoiceMoneyRecDiscount;
