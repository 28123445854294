import { Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { HTTPResponse } from '../../../common/types/commonTypes';
import {
  DateInput,
  FormButton,
  FormInputItem,
  FormMobileNumberItem,
  NumberInput,
} from '../../../components/common/FormItem/FormItems';
import { SelectOptionsInput } from '../../../components/common/FormItem/SelectCustomFeilds';
import { useGetAllProductsQuery } from '../../Configuration/Products/endpoints/productsEndpoints';
import { ProductDataType } from '../../Configuration/Products/types/productTypes';
import { ISingleVendor } from '../types/vendor.interfaces';
import VendorProducts from './common/VendorProducts';

type Props = {
  form: FormInstance<any>;
  vendor: HTTPResponse<ISingleVendor> | undefined;
  setSelectProducts: React.Dispatch<React.SetStateAction<number[]>>;
  isLoading?: boolean;
};
const EditVendorsForm = ({
  form,
  vendor,
  setSelectProducts,
  isLoading,
}: Props) => {
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const vendor_type = vendor?.data?.vendor_type;

  const { data: products } = useGetAllProductsQuery();
  const vendorMobile = vendor?.data?.vendor_mobile
    ? vendor?.data?.vendor_mobile.split('-')
    : undefined;
  const date = vendor?.data?.vendor_registration_date
    ?.toLocaleString()
    .slice(0, 10);

  // set updated vendor
  useEffect(() => {
    form.setFieldsValue({
      ...vendor?.data,
      vendor_address: vendor?.data?.vendor_address,
      vendor_name: vendor?.data?.vendor_name,
      vendor_email: vendor?.data?.vendor_email,
      dailCode: vendorMobile ? vendorMobile[0] : 88,
      number: vendorMobile && vendorMobile[1],
      vendor_fixed_advance: vendor?.data?.vendor_fixed_advance,
      vendor_registration_date: dayjs(date, 'YYYY-MM-DD'),
      vendor_commission_rate: vendor?.data?.vproduct_commission_rate,
      vendor_opening_balance_pay_type:
        vendor?.data?.vendor_opening_balance_pay_type?.toUpperCase(),
      vendor_opening_balance: Number(vendor?.data?.vendor_opening_balance),
      vendor_bank_guarantee:
        vendor?.data?.vendor_bank_guarantee &&
        Number(vendor?.data?.vendor_bank_guarantee),
      vendor_start_date:
        vendor?.data?.vendor_start_date &&
        dayjs(vendor?.data?.vendor_start_date),
      vendor_end_date:
        vendor?.data?.vendor_end_date && dayjs(vendor?.data?.vendor_end_date),
    });
    setCheckedList(
      vendor?.data?.vendor_products?.map((item) => item.vproduct_product_id) ||
        []
    );
  }, [vendor]);
  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
      <FormInputItem
        label='Name'
        name='vendor_name'
        size={6}
        disabled={vendor_type === 'IATA' ? true : false}
      />
      <FormInputItem
        label='Email'
        name='vendor_email'
        required={false}
        inputType='email'
        size={6}
      />
      <FormMobileNumberItem size={6} />
      <NumberInput
        label='Fixed advance:'
        name='vendor_fixed_advance'
        min='0'
        maxChar={14}
        minChar={0}
        size={6}
      />
      <DateInput
        name='vendor_registration_date'
        label='Date: '
        required
        size={6}
      />

      <FormInputItem label='Address' name='vendor_address' size={6} />

      {/* Add Opening Balance */}
      <SelectOptionsInput
        name={'vendor_opening_balance_pay_type'}
        disabled
        label='Opening Balance Type: '
        options={['Due', 'Advance']}
        size={6}
      />

      <NumberInput
        name={'vendor_opening_balance'}
        label='Opening Balance : '
        size={6}
      />

      {/* Add Vendor Credit Limit */}

      <NumberInput
        name={'vendor_credit_limit'}
        label='Credit Limit:'
        placeholder={'Credit Limit'}
        size={6}
      />

      <NumberInput
        name={'vendor_bank_guarantee'}
        label='Vendor Bank Guarantee'
        placeholder={'Bank Guarantee'}
        size={6}
      />

      <DateInput
        name={'vendor_start_date'}
        label='Vendor Start Date'
        size={6}
      />

      <DateInput name={'vendor_end_date'} label='Vendor End Date' size={6} />

      <VendorProducts
        products={products?.data as ProductDataType[]}
        setSelectProducts={(value) => setSelectProducts(value)}
        // commission={vendor?.data?.vproduct_commission_rate !== null}
        commission={
          Number(vendor?.data?.vproduct_commission_rate || 0) ||
          Number(vendor?.data?.vproduct_commission_rate || 0) === 0
            ? true
            : false
        }
        checkedList={checkedList}
        setCheckedList={setCheckedList}
      />

      <FormButton label='Update' loading={isLoading} />
    </Row>
  );
};

export default EditVendorsForm;
