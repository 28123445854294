import { FormInstance } from 'antd';
import dayjs from 'dayjs';
import {
  Fixed2,
  getAirportByItaCode,
  getItaCodeByAirportIds,
} from '../../../common/utils/common.utils';
import { IAirport } from '../../Configuration/airport/types/AirportTypes';
import {
  nonCommitionInvoicePostType,
  nonInvoice_Invoice_Info,
} from '../../Invoice_Non_Comission/types/nonInvoiceAirTicketTypes';
import {
  IEditReissue,
  IReissueFormSubmit,
} from '../Type/InoviceReissue.interface';
import { IAirticketMoneyReceiptType } from '../../IATA_AIR_TICKET/types/invoiceAirTicketTypes';

type postProps = {
  invoices: IReissueFormSubmit[];
  billing_form: FormInstance<any>;
  user_id: number;
  selectAirport?: IAirport[];
};
export const getReIssuePostFormatData = ({
  billing_form,
  invoices,
  user_id,
  selectAirport,
}: postProps) => {
  const extraData = billing_form.getFieldsValue([
    'invoice_show_passport_details',
    'invoice_note',
    'invoice_vat',
    'invoice_discount',
    'invoice_net_total',
    'invoice_service_charge',
    'invoice_show_prev_due',
    'invoice_show_discount',
    'receipt_payment_type',
    'trans_no',
    'charge_amount',
    'cheque_number',
    'cheque_withdraw_date',
    'cheque_bank_name',
    'account_id',
    'receipt_total_amount',
    'receipt_payment_date',
    'receipt_money_receipt_no',
    'invoice_agent_com_amount',
    'invoice_reference',
    'receipt_note',
  ]);

  const lastField = invoices[invoices.length - 1];

  const getTotalProfit = () => {
    let totalProfit = 0;
    invoices.forEach((el) => {
      if (el.airticket_extra_fee) {
        totalProfit =
          totalProfit +
          el.airticket_extra_fee +
          el.airticket_client_price -
          el.airticket_purchase_price;
      } else {
        totalProfit =
          totalProfit + el.airticket_client_price - el.airticket_purchase_price;
      }
    });
    return totalProfit;
  };
  const getSubTotal = (allNonComInvoices: IReissueFormSubmit[]) => {
    let subTotal = 0;
    allNonComInvoices.forEach((el) => {
      subTotal =
        subTotal + el.airticket_client_price + (el.airticket_extra_fee || 0);
    });
    return subTotal;
  };

  const getTotalVendorPrice = (allNonComInvoices: IReissueFormSubmit[]) => {
    let vendorTotal = 0;
    allNonComInvoices.forEach((el) => {
      vendorTotal =
        vendorTotal +
        el.airticket_purchase_price +
        (el.airticket_extra_fee || 0);
    });
    return vendorTotal;
  };

  const invoice_info_for_server: nonInvoice_Invoice_Info = {
    invoice_combclient_id: lastField.invoice_combclient_id,
    invoice_sales_man_id: Fixed2(lastField.invoice_sales_man_id),
    invoice_agent_id: lastField.invoice_agent_id,
    invoice_sales_date: lastField.invoice_sales_date,
    invoice_due_date: lastField.invoice_due_date,
    invoice_note: extraData.invoice_note || null,
    invoice_reference: extraData.invoice_reference || null,
    invoice_sub_total: getSubTotal(invoices),

    invoice_net_total: Fixed2(extraData.invoice_net_total),

    invoice_service_charge: Fixed2(extraData.invoice_service_charge),

    invoice_discount: Fixed2(extraData.invoice_discount),
    invoice_vat: Fixed2(extraData.invoice_vat),

    invoice_total_profit: getTotalProfit(),
    invoice_total_vendor_price: getTotalVendorPrice(invoices),
    invoice_created_by: user_id,
    invoice_agent_com_amount: extraData.invoice_agent_com_amount,
    invoice_no: lastField.invoice_no,
    invoice_show_discount: extraData?.invoice_show_discount,
    invoice_show_prev_due: extraData?.invoice_show_prev_due,
  };
  const money_receipt: IAirticketMoneyReceiptType = {
    receipt_total_amount: extraData?.receipt_total_amount || undefined,
    receipt_payment_type: extraData?.receipt_payment_type || undefined,
    account_id: extraData?.account_id || undefined,
    charge_amount: extraData?.charge_amount || undefined,
    cheque_bank_name: extraData?.cheque_bank_name || undefined,
    cheque_number: extraData?.cheque_number || undefined,
    cheque_withdraw_date: extraData.cheque_withdraw_date
      ? extraData.cheque_withdraw_date
      : undefined,
    receipt_money_receipt_no: extraData?.receipt_money_receipt_no || undefined,
    receipt_note: extraData?.receipt_note || undefined,
    invoice_reference: extraData?.invoice_reference || undefined,
    trans_no: extraData?.trans_no || undefined,
    receipt_payment_date: extraData.receipt_payment_date
      ? extraData.receipt_payment_date
      : undefined,
  };

  const tempData = {
    invoice_info: invoice_info_for_server,
    ticketInfo: invoices.map((el) => {
      return {
        ticket_details: {
          airticket_ticket_no: el.airticket_ticket_no,
          airticket_client_price: el.clientPriceSet,
          airticket_purchase_price: el.purchasePriceSet,
          airticket_comvendor: el.airticket_comvendor,
          airticket_airline_id: el.airticket_airline_id,
          airticket_extra_fee: el.airticket_extra_fee,
          airticket_penalties: el.airticket_penalties,
          airticket_ait: el.airticket_ait,
          airticket_commission_percent: el.airticket_commission_percent,
          airticket_fare_difference: el.airticket_fare_difference,
          airticket_route_or_sector: el?.airticket_route_or_sector
            ? (getAirportByItaCode(
                el?.airticket_route_or_sector as string,
                selectAirport
              ) as string[])
            : undefined,
          airticket_pnr: el?.airticket_pnr,
          airticket_issue_date: el.airticket_issue_date,
          airticket_journey_date: el.airticket_journey_date,
          airticket_return_date: el.airticket_return_date,
          airticket_classes: el.airticket_classes,
          airticket_profit: el.airticket_profit
            ? el.airticket_profit
            : el.airticket_client_price +
              (el.airticket_extra_fee || 0) -
              Number(el.purchasePriceSet),
        },

        pax_passports: el?.pax_passport?.map((item) => ({
          ...item,
          passport_name: item?.passport_name,
          passport_person_type: item?.passport_person_type,
          passport_mobile_no: item.passport_mobile_no
            ? item?.dialCode + '-' + item.passport_mobile_no
            : undefined,
          passport_email: item.passport_email,
          passport_passport_no: item.passport_passport_no,
          passport_nid_no: item.passport_nid_no,
          passport_date_of_birth: item.passport_date_of_birth
            ? item.passport_date_of_birth
            : undefined,
          passport_date_of_issue: item.passport_date_of_issue
            ? item.passport_date_of_issue
            : undefined,
          passport_date_of_expire: item.passport_date_of_expire
            ? item.passport_date_of_expire
            : undefined,
        })),

        flight_details: el?.flight_details?.map((item) => {
          return {
            ...item,
            fltdetails_from_airport_id:
              item?.fltdetails_from_airport_id &&
              typeof item?.fltdetails_from_airport_id !== 'number'
                ? ((
                    getAirportByItaCode(
                      item?.fltdetails_from_airport_id as string,
                      selectAirport
                    ) as number[]
                  )[0] as number)
                : item?.fltdetails_from_airport_id,
            fltdetails_to_airport_id:
              item?.fltdetails_to_airport_id &&
              typeof item?.fltdetails_to_airport_id !== 'number'
                ? ((
                    getAirportByItaCode(
                      item?.fltdetails_to_airport_id as string,
                      selectAirport
                    ) as number[]
                  )[0] as number)
                : item?.fltdetails_to_airport_id,
            fltdetails_fly_date: item.fltdetails_fly_date
              ? item.fltdetails_fly_date
              : undefined,
            fltdetails_arrival_time: item.fltdetails_arrival_time
              ? dayjs(item.fltdetails_arrival_time).format('HH:mm:ss')
              : undefined,
            fltdetails_departure_time: item.fltdetails_departure_time
              ? dayjs(item.fltdetails_departure_time).format('HH:mm:ss')
              : undefined,
          };
        }),
      };
    }),
    money_receipt,
  };

  return tempData;
};

export const getReIssueInvoiceFormat = ({
  data,
  selectAirport,
}: {
  data: nonCommitionInvoicePostType;
  selectAirport?: IAirport[];
}) => {
  const { invoice_info: ii, ticketInfo } = data;
  const formatedData: IEditReissue[] = ticketInfo.map((item) => {
    const { flight_details: fd, pax_passport: pp, ticket_details: td } = item;
    const pax_passport = pp.map((item_) => ({
      ...item_,
      dialCode: item_.passport_mobile_no
        ? item_.passport_mobile_no.split('-')[0]
        : undefined,
      passport_name: item_.passport_name,
      passport_person_type: item_.passport_person_type,
      passport_mobile_no: item_.passport_mobile_no
        ? item_.passport_mobile_no.split('-')[1]
        : undefined,
      passport_email: item_.passport_email,
      passport_passport_no: item_.passport_passport_no,
      passport_nid_no: item_.passport_nid_no,
      passport_date_of_birth: item_.passport_date_of_birth
        ? dayjs(item_.passport_date_of_birth)
        : undefined,
      passport_date_of_issue: item_.passport_date_of_issue
        ? dayjs(item_.passport_date_of_issue)
        : undefined,
      passport_date_of_expire: item_.passport_date_of_expire
        ? dayjs(item_.passport_date_of_expire)
        : undefined,
    }));
    const flight_details = fd?.map((_item) => ({
      fltdetails_from_airport_id: _item.fltdetails_from_airport_id
        ? getItaCodeByAirportIds(
            [_item.fltdetails_from_airport_id] as string[],
            selectAirport
          )
        : undefined,
      fltdetails_to_airport_id: _item.fltdetails_to_airport_id
        ? getItaCodeByAirportIds(
            [_item.fltdetails_to_airport_id] as number[],
            selectAirport
          )
        : undefined,
      fltdetails_airline_id: _item.fltdetails_airline_id,
      fltdetails_flight_no: _item.fltdetails_flight_no,
      fltdetails_fly_date: _item.fltdetails_fly_date
        ? dayjs(_item.fltdetails_fly_date)
        : undefined,
      fltdetails_departure_time: _item.fltdetails_departure_time
        ? dayjs(_item.fltdetails_departure_time, 'HH:mm:ss')
        : undefined,
      fltdetails_arrival_time: _item.fltdetails_arrival_time
        ? dayjs(_item.fltdetails_arrival_time, 'HH:mm:ss')
        : undefined,
    }));
    return {
      invoice_combclient_id: ii.invoice_combclient_id,
      invoice_sales_man_id: ii.invoice_sales_man_id,
      invoice_no: ii.invoice_no,
      invoice_sales_date: dayjs(ii.invoice_sales_date),
      invoice_agent_id: ii.invoice_agent_id || undefined,

      airticket_ticket_no: td.airticket_ticket_no,
      airticket_ait: Number(td.airticket_ait) || 0,
      airticket_penalties: Number(td.airticket_penalties),
      airticket_commission_percent: Number(td.airticket_commission_percent),
      airticket_fare_difference: Number(td.airticket_fare_difference),
      airticket_client_price: td.airticket_client_price,
      airticket_purchase_price: td.airticket_purchase_price,
      airticket_comvendor: td.airticket_comvendor,
      airticket_airline_id: td.airticket_airline_id,
      airticket_extra_fee: td.airticket_extra_fee,
      airticket_route_or_sector_id: td.airticket_route_or_sector,
      airticket_route_or_sector: td?.airticket_route_or_sector?.length
        ? (getItaCodeByAirportIds(
            [...td?.airticket_route_or_sector] as string[],
            selectAirport
          ) as string)
        : undefined,
      airticket_pnr: td.airticket_pnr,
      airticket_issue_date: td.airticket_issue_date
        ? dayjs(td.airticket_issue_date)
        : undefined,
      airticket_journey_date: td.airticket_journey_date
        ? dayjs(td.airticket_journey_date)
        : undefined,

      airticket_return_date: td.airticket_return_date
        ? dayjs(td.airticket_return_date)
        : undefined,

      airticket_classes: td.airticket_classes,
      clientPriceSet: td.airticket_client_price,
      purchasePriceSet: td.airticket_purchase_price,
      airticket_profit: td.airticket_profit,
      pax_passport: pax_passport?.length ? pax_passport : [{}],
      flight_details: flight_details?.length ? flight_details : [{}],
    };
  });

  return formatedData;
};
