import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { IFlights, IPaxDetails } from '../../../common/types/commonInterfaces';
import {
  Fixed2,
  FormatDate,
  filterNullColumns,
  isNotEmpty,
} from '../../../common/utils/common.utils';
import { HeightRefsType } from '../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice';
import getInvoiceViewPageController from '../../IATA_AIR_TICKET/utils/ts/getInvoiceViewPageController';
import {
  IAirticketBilling,
  IGenerateOtherBilling,
} from '../QuotationTypes/QuotationTypes';
type Props = {
  flights?: IFlights[];
  paxDetails?: IPaxDetails[];
  airticketBilling?: IAirticketBilling[];
  heightRefs: HeightRefsType;
  otherBilling: IGenerateOtherBilling[] | undefined;
  showFlightDetails?: boolean;
};
export const PAX_TYPES = {
  1: 'Adult',
  2: 'Child',
  3: 'Infant',
};

const GenerateInvoiceFlightAndPaxView = ({
  flights,
  paxDetails,
  airticketBilling,
  heightRefs,
  otherBilling,
  showFlightDetails,
}: Props) => {
  const flightsColumn: ColumnsType<IFlights> = [
    {
      title: <span className='table-column-title'> Sl.</span>,
      align: 'center',
      render(value, record, index) {
        return <>{index + 1}</>;
      },
    },
    {
      title: <span className='table-column-title'> From</span>,
      align: 'left',
      dataIndex: 'flight_from',
      key: 'flight_from',
    },
    {
      title: <span className='table-column-title'> To</span>,
      align: 'left',
      dataIndex: 'flight_to',
      key: 'flight_to',
    },
    {
      title: <span className='table-column-title'> Airline</span>,
      align: 'left',
      dataIndex: 'airline_name',
      key: 'airline_name',
    },
    {
      title: <span className='table-column-title'> D.Time</span>,
      align: 'right',
      dataIndex: 'fltdetails_departure_time',
      key: 'fltdetails_departure_time',
    },
    {
      title: <span className='table-column-title'> Arr.Time</span>,
      dataIndex: 'fltdetails_arrival_time',
      key: 'fltdetails_arrival_time',
      align: 'right',
    },
  ];

  const billingsColumn: ColumnsType<any> = [
    {
      title: <span className='table-column-title'>Sl.</span>,
      render(value, record, index) {
        return <>{index + 1}</>;
      },
      align: 'center',
    },

    {
      title: <span className='table-column-title'>Ticket No</span>,
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
      align: 'center',
      render: (text: string) => <span className='table-column'>{text}</span>,
    },

    {
      title: <span className='table-column-title'>Pax Name</span>,
      dataIndex: 'passport_name',
      key: 'passport_name',
      align: 'left',
      render: (text: string) => <span className='table-column'>{text}</span>,
    },
    {
      title: <span className='table-column-title'>Airline Name</span>,
      dataIndex: 'airline_name',
      key: 'airline_name',
      align: 'left',
      render: (text: string) => <span className='table-column'>{text}</span>,
    },
    {
      title: <span className='table-column-title'>PNR</span>,
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
      align: 'left',
      render: (text: string) => <span className='table-column'>{text} </span>,
    },
    // {
    //   title: <span className='table-column-title'> Class</span>,
    //   dataIndex: 'airticket_classes',
    //   key: 'airticket_classes',
    //   align: 'center',
    //   render: (text: string) => <span className='table-column'>{text} </span>,
    // },
    // {
    //   title: <span className='table-column-title'> Class Type</span>,
    //   dataIndex: 'airticket_ticket_type',
    //   key: 'airticket_ticket_type',
    //   align: 'center',
    //   render: (text: string) => <span className='table-column'>{text} </span>,
    // },
    {
      title: <span className='table-column-title'>Routes</span>,
      dataIndex: 'airticket_routes',
      key: 'airticket_routes',
      align: 'left',
    },

    {
      title: <span className='table-column-title'> Journey</span>,
      key: 'airticket_journey_date',
      render: (_, data) => (
        <span className='table-column'>
          {FormatDate(data.airticket_journey_date)}
        </span>
      ),
    },

    {
      title: (
        <span className='table-column-title ' style={{ textAlign: 'center' }}>
          Return Date
        </span>
      ),
      key: 'airticket_return_date',
      render: (_, data) => (
        <span className='table-column'>
          {FormatDate(data.airticket_return_date)}
        </span>
      ),
    },

    {
      title: <span className='table-column-title'> Total </span>,
      dataIndex: 'airticket_client_price',
      key: 'airticket_client_price',
      align: 'right',
      render: (text: string, record) => (
        <span className='table-column'>{Fixed2(text)}</span>
      ),
    },
  ];
  const OtherbillingColumn: ColumnsType<IGenerateOtherBilling> = [
    {
      title: 'SL',
      align: 'center',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'Pax Name',
      dataIndex: 'pax_name',
      key: 'pax_name',
      render: (paxName) => (paxName ? paxName : 'N/A'),
    },
    ...((otherBilling?.some((item) => item.billing_description) && [
      {
        title: 'Description',
        dataIndex: 'billing_description',
        key: 'billing_description',
      },
    ]) ||
      []),
    {
      title: 'Quantity',
      dataIndex: 'billing_quantity',
      key: 'billing_quantity',
      align: 'right',
    },
    {
      title: 'Unit price',
      dataIndex: 'billing_unit_price',
      width: '150px',
      align: 'right',
    },
    {
      title: 'Sub Total',
      dataIndex: 'billing_subtotal',
      key: 'billing_subtotal',
      width: '120px',
      align: 'right',
    },
  ];

  const paxDetailsColumn: ColumnsType<any> = [
    {
      title: <span className='table-column-title'> Sl. </span>,
      align: 'center',
      render(value, record, index) {
        return <>{index + 1}</>;
      },
    },
    {
      title: <span className='table-column-title'>Pax Name</span>,
      dataIndex: 'passport_name',
      key: 'passport_name',
    },
    {
      title: <span className='table-column-title'>Pax Type</span>,
      dataIndex: 'passport_person_type',
      key: 'passport_person_type',
      render(value, record, index) {
        return (
          <>
            {/* value some time get text and some time get id */}
            {typeof value === 'number' || Fixed2(value)
              ? PAX_TYPES[value as 1 | 2 | 3]
              : value}
          </>
        );
      },
    },

    {
      title: <span className='table-column-title'> Passport No </span>,
      dataIndex: 'passport_passport_no',
      key: 'passport_passport_no',
    },

    {
      title: <span className='table-column-title'>Mobile</span>,
      dataIndex: 'passport_mobile_no',
      key: 'passport_mobile_no',
      render: (text: string) => <span className='table-column'>{text}</span>,
    },
    {
      title: <span className='table-column-title'> DOB </span>,
      key: 'passport_date_of_birth',
      dataIndex: 'passport_date_of_birth',
      render: (_, data) =>
        data.passport_date_of_birth
          ? dayjs(data.passport_date_of_birth).format('D-MM-YYYY')
          : '',
    },
    {
      title: <span className='table-column-title'> Expire Date </span>,
      key: 'passport_date_of_expire',
      dataIndex: 'passport_date_of_expire',
      render: (_, data) =>
        data.passport_date_of_expire
          ? dayjs(data.passport_date_of_expire).format('D-MM-YYYY')
          : '',
    },
  ];

  // @HANDLE PRINT DOUBLE PAGE
  // states, variables and references
  const filight_ref = useRef<HTMLDivElement>(null);
  const pax_ref = useRef<HTMLDivElement>(null);
  const billing_ref = useRef<HTMLDivElement>(null);

  const [pageController, setPageController] = useState<
    {
      identifier: string;
      marginTop: number;
      bottomIdentifier: string;
    }[]
  >();

  useEffect(() => {
    const filight_ref_height = Fixed2(filight_ref.current?.clientHeight) / 96; // value in inch
    const pax_ref_height = Fixed2(pax_ref.current?.clientHeight) / 96; // value in inch
    const billing_ref_height = Fixed2(billing_ref.current?.clientHeight) / 96; // value in inch

    const header_height =
      Fixed2(heightRefs.header_ref.current?.clientHeight) / 96; // value in inch
    const signiture_height =
      Fixed2(heightRefs.signiture_ref.current?.clientHeight) / 96; // value in inch
    const subtotal_height =
      Fixed2(heightRefs.subtotal_ref.current?.clientHeight) / 96; // value in inch

    const pageControl: any = getInvoiceViewPageController({
      topHeight: header_height,
      footerHeight: subtotal_height + signiture_height,
      pageHeight: 11.7,
      items: [
        {
          height: filight_ref_height,
          identifier: 'flight',
        },
        {
          height: pax_ref_height,
          identifier: 'pax_details',
        },
        {
          height: billing_ref_height,
          identifier: 'billing',
        },
        {
          height: subtotal_height + signiture_height,
          identifier: 'footer',
        },
      ],
    });

    setPageController(pageControl);
  }, [flights, paxDetails, airticketBilling]);

  // column without null value

  const flightColumns = filterNullColumns(flights, flightsColumn);
  const billingColumns = filterNullColumns(airticketBilling, billingsColumn);
  const OtherbillingColumns = filterNullColumns(
    otherBilling,
    OtherbillingColumn
  );

  return (
    <div className='mt-20'>
      <div ref={filight_ref}>
        {isNotEmpty(flights) && showFlightDetails && (
          <>
            <Typography.Title level={5} style={{ marginBottom: '5px' }}>
              FLIGHT/ROUTE DETAILS{' '}
            </Typography.Title>
            {
              <Table
                size='small'
                bordered
                rowKey={(e) => e.fltdetails_arrival_time}
                style={{ marginBottom: '2rem' }}
                className='invoiceBillingTable'
                rowClassName={'invoiceBillingTd'}
                dataSource={flights}
                columns={flightColumns.col}
                pagination={false}
              />
            }
          </>
        )}
      </div>

      <div
        ref={billing_ref}
        style={{
          marginTop:
            pageController && pageController[2].identifier === 'billing'
              ? `${pageController[2].marginTop}in`
              : 0,
          borderTop:
            pageController &&
            pageController[2].identifier === 'billing' &&
            pageController[2].marginTop
              ? '4px dashed lightgray'
              : '',
          paddingTop:
            pageController &&
            pageController[2].identifier === 'billing' &&
            pageController[2].marginTop
              ? '0.5in'
              : '',
        }}
      >
        {isNotEmpty(airticketBilling) && (
          <>
            <Typography.Title level={5} style={{ marginBottom: '5px' }}>
              BILLING INFO
            </Typography.Title>
            <Table
              size='small'
              bordered
              rowKey={(e) => e.airticket_ticket_no}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              dataSource={airticketBilling}
              columns={billingColumns.col}
              pagination={false}
            />
          </>
        )}

        <div className='mt-20'>
          {isNotEmpty(otherBilling) && (
            <>
              <Typography.Title level={5} style={{ marginBottom: '5px' }}>
                OTHER BILLING INFO
              </Typography.Title>

              <Table
                size='small'
                bordered
                rowKey={(e) => Math.random()}
                className='invoiceBillingTable'
                rowClassName={'invoiceBillingTd'}
                dataSource={otherBilling}
                columns={OtherbillingColumns.col}
                pagination={false}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GenerateInvoiceFlightAndPaxView;
