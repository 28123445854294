import { useEffect, useRef, useState } from 'react';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  SelectProps,
  Space,
  Table,
} from 'antd';
import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import { useGetAuthorityQuery } from '../../../Loan_Management/Api/Endpoints/AuthorityEndpoints';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import dayjs from 'dayjs';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { ColumnsType } from 'antd/es/table';
import {
  TableParams,
  handleTableChange,
  rangePresets,
} from '../../../../common/utils/common.utils';
import {
  useLazyGetAllLoanReportQuery,
  useLazyGetLoanExcelQuery,
} from '../endpoints/loanReportEndpoints';
import {
  ILoanReportFormData,
  ILoanReportTypes,
} from '../types/loanReportTypes';
import { useReactToPrint } from 'react-to-print';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';

const LoanReport = () => {
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetLoanExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    authority: string;
    category: string;
    query: string;
  }>({
    authority: '',
    category: '',
    query: '',
  });
  //get all authority
  const {
    data: authority,
    isLoading,
    isFetching,
  } = useGetAuthorityQuery(undefined);
  const options: SelectProps['options'] = [];
  const allOption = {
    value: 'all',
    label: 'All',
  };
  options.unshift(allOption);
  if (authority?.data) {
    for (let i = 0; i < authority.data.length; i++) {
      options.push({
        value: authority?.data[i]?.authority_id,
        label: authority.data[i]?.authority_name,
      });
    }
  }

  const [fetchLoanReport, { data }] = useLazyGetAllLoanReportQuery();
  const onFinish = async (values: ILoanReportFormData) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      date_range: date_range,
      authority_id: values.authority_id,
      loan_category_id: values.loan_category_id,
    };
    await fetchLoanReport({
      loan_category_id: body.loan_category_id,
      authority_id: body.authority_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      authority: body.authority_id.toString(),
      category: body.loan_category_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };
  //pagination--start---
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
  }, [data]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 50 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['50', '100', '200', '500'],
      pageSize: 50,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: ILoanReportFormData = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      date_range: date_range,
      authority_id: values.authority_id,
      loan_category_id: values.loan_category_id,
    };

    fetchLoanReport({
      authority_id: body.authority_id,
      loan_category_id: body.loan_category_id,
      query: `${query}&${body.date_range}`,
    });

    setExcelQuery({
      authority: body.authority_id.toString(),
      category: body.loan_category_id.toString(),
      query: `${query}&${body.date_range}`,
    });
  };
  //pagination--end---
  const toDayDate = dayjs().format('YYYY-MM-DD');
  useEffect(() => {
    form.setFieldValue('authority_id', 'all');
    form.setFieldValue('loan_category_id', 'all');
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
    const body = {
      date_range: `from_date=${toDayDate}&to_date=${toDayDate}`,
      authority_id: 'all',
      loan_category_id: 'all',
    };
    fetchLoanReport({
      authority_id: body.authority_id,
      loan_category_id: body.loan_category_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      authority: body.authority_id.toString(),
      category: body.loan_category_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  }, []);
  const column: ColumnsType<ILoanReportTypes> = [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((queryData.current || 1) - 1) * (queryData.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Date',
      key: 'date',
      render: (_, record) => {
        return `${dayjs(record.loan_create_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Loan Authority',
      dataIndex: 'authority_name',
      key: 'authority_name',
    },

    {
      title: 'Loan Name',
      dataIndex: 'loan_name',
      key: 'loan_name',
      align: 'center',
    },
    {
      title: 'Method',
      dataIndex: 'loan_payment_type',
      key: 'loan_payment_type',
      align: 'center',
      render: (_, data) => {
        if (data.loan_payment_type == 1) {
          return 'Cash';
        } else if (data.loan_payment_type == 2) {
          return 'Bank';
        } else if (data.loan_payment_type == 3) {
          return 'Mobile Banking';
        } else {
          return 'Cheque';
        }
      },
    },

    {
      title: 'Amount',
      dataIndex: 'loan_amount',
      key: 'loan_amount',
      align: 'right',
      render: (_, data) => Number(data.loan_amount),
    },
    {
      title: 'Interest',
      dataIndex: 'loan_interest_percent',
      key: 'loan_interest_percent',
      align: 'right',
      render: (_, data) => Number(data.loan_interest_percent),
    },
    {
      title: 'Receivable',
      dataIndex: 'loan_receivable_amount',
      key: 'loan_receivable_amount',
      align: 'right',
      render: (_, data) => Number(data.loan_receivable_amount),
    },
    {
      title: 'Installment',
      dataIndex: 'loan_installment',
      key: 'loan_installment',
      align: 'center',
    },
    {
      title: 'Due',
      dataIndex: 'loan_due_amount',
      key: 'loan_due_amount',
      align: 'right',
      render: (_, data) => Number(data.loan_due_amount),
    },
    {
      title: 'Note',
      dataIndex: 'loan_note',
      key: 'loan_note',
      align: 'center',
    },
  ];
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Client_Ledger`,
    pageStyle: '',
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <Table
      size='small'
      bordered
      pagination={count > 50 ? tableParams.pagination : false}
      dataSource={data?.data}
      columns={column}
      loading={{
        spinning: isLoading || isFetching,
        indicator: loadingIndicator,
      }}
      scroll={{ x: true }}
      onChange={(args1, args2, args3) =>
        handleTableChange({
          args: {
            pagination: args1,
            filters: args2,
            sorter: args3,
          },
          states: {
            refetch,
            setQueryData,
            setTableParams,
            tableParams,
          },
        })
      }
    />
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Loan Report' }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'Loan Report']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              authority: excelQuery.authority,
              category: excelQuery.category,
              query: excelQuery.query,
              excelApiName: 'loanReports',
              excelName: 'loanReports',
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>

      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row justify={'space-between'}>
          <Col>
            <Row gutter={5}>
              <Col xs={24} sm={24} md={12} lg={6}>
                <Form.Item label='Authority' name='authority_id'>
                  <Select placeholder='Please select' options={options} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={7}>
                <Form.Item label='Loan Category' name='loan_category_id'>
                  <Select
                    placeholder='Select a loan category'
                    options={[
                      {
                        value: 'all',
                        label: 'All',
                      },
                      {
                        value: 'TAKING',
                        label: 'TAKING',
                      },
                      {
                        value: 'GIVING',
                        label: 'GIVING',
                      },
                      {
                        value: 'ALREADY_TAKEN',
                        label: 'ALREADY TAKEN',
                      },
                      {
                        value: 'ALREADY_GIVEN',
                        label: 'ALREADY GIVEN',
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={9} lg={9}>
                <Form.Item
                  label='Date Range'
                  name='date_range'
                  //   rules={[
                  //     { required: true, message: ` Date Range is required!` },
                  //   ]}
                >
                  <DatePicker.RangePicker
                    presets={rangePresets}
                    format={'DD-MM-YYYY'}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col lg={2}>
                <FormButton label='Search' icon textAlign='left' />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <FormHeaderTitle title='Loan Report' />

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};
export default LoanReport;
