import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Modal, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { crud, perProps } from '../../../../common/types/commonTypes';
import { removeSpaceBeforeWord } from '../../../../components/common/Invoice/InvoiceListHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import {
  useGetLoanQuery,
  useGetTotalLoanReportQuery,
} from '../../Api/Endpoints/LoanEndpoints';
import { ILoanTotalReport, LoanDataType } from '../../LoanTypes/LoanTypes';
import {
  commonLoan,
  setEditAlreadyGivenLoanModal,
  setEditAlreadyTakenLoanModal,
  setEditGivingLoanModal,
  setEditTakingLoanModal,
  setLoanModal,
} from '../../Loan_State/loanSlice';
import AddLoanModal from '../../Modals/Loan/AddLoanModal';
import EditLoanModal from '../../Modals/Loan/EditLoanModal';
import { TakingLoanList } from '../../Utils/Loan/TakingLoanList';
import { ColumnsType } from 'antd/es/table';
import { Fixed2, rangePresets } from '../../../../common/utils/common.utils';

const LoanList = ({ permission }: perProps) => {
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  const { data, isLoading: reportLoading } = useGetTotalLoanReportQuery();

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(setLoanModal(false));
      dispatch(setEditTakingLoanModal(false));
      dispatch(setEditGivingLoanModal(false));
      dispatch(setEditAlreadyTakenLoanModal(false));
      dispatch(setEditAlreadyGivenLoanModal(false));
    };
  }, []);

  const modalSlice = useAppSelector(commonLoan);
  const showAddLoanModal = () => {
    dispatch(setLoanModal(true));
  };
  const showEditTakingLoanModal = () => {
    dispatch(setEditTakingLoanModal(true));
  };
  const showEditGivingLoanModal = () => {
    dispatch(setEditGivingLoanModal(true));
  };
  const showEditAlreadyTakenLoanModal = () => {
    dispatch(setEditAlreadyTakenLoanModal(true));
  };
  const showEditAlreadyGivenLoanModal = () => {
    dispatch(setEditAlreadyGivenLoanModal(true));
  };

  let takingLoan_amount = 0;
  let givingLoan_amount = 0;
  let alreadyTakenLoan_amount = 0;
  let alreadyGivenLoan_amount = 0;
  let takingLoan_percent = 0;
  let givingLoan_percent = 0;
  let alreadyTakenLoan_percent = 0;
  let alreadyGivenLoan_percent = 0;
  let takingPayable = 0;
  let givingPayable = 0;
  let alreadyTakenPayable = 0;
  let alreadyGivenPayable = 0;
  let takingReceivable = 0;
  let givingReceivable = 0;
  let alreadyTakenReceivable = 0;
  let alreadyGivenReceivable = 0;
  let takingDue = 0;
  let givingDue = 0;
  let alreadyTakenDue = 0;
  let alreadyGivenDue = 0;

  const {
    data: loan,
    isLoading,
    isFetching,
    refetch,
  } = useGetLoanQuery({
    ...pagination,
    from: date[0] || '',
    to: date[1] || '',
    search: search,
  });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 50,
    }));
  }, [date, search]);

  loan?.data?.forEach((loan) => {
    if (loan.loan_type === 'TAKING') {
      takingLoan_amount += Number(loan.loan_amount);
      takingLoan_percent += Number(loan.loan_interest_percent);
      takingPayable += Number(loan.loan_payable_amount);
      takingReceivable += Number(loan.loan_receivable_amount);
      takingDue += Number(loan.loan_due_amount);
    } else if (loan.loan_type === 'GIVING') {
      givingLoan_amount += Number(loan.loan_amount);
      givingLoan_percent += Number(loan.loan_interest_percent);
      givingPayable += Number(loan.loan_payable_amount);
      givingReceivable += Number(loan.loan_receivable_amount);
      givingDue += Number(loan.loan_due_amount);
    } else if (loan.loan_type === 'ALREADY_TAKEN') {
      alreadyTakenLoan_amount += Number(loan.loan_amount);
      alreadyTakenLoan_percent += Number(loan.loan_interest_percent);
      alreadyTakenPayable += Number(loan.loan_payable_amount);
      alreadyTakenReceivable += Number(loan.loan_receivable_amount);
      alreadyTakenDue += Number(loan.loan_due_amount);
    } else if (loan.loan_type === 'ALREADY_GIVEN') {
      alreadyGivenLoan_amount += Number(loan.loan_amount);
      alreadyGivenLoan_percent += Number(loan.loan_interest_percent);
      alreadyGivenPayable += Number(loan.loan_payable_amount);
      alreadyGivenReceivable += Number(loan.loan_receivable_amount);
      alreadyGivenDue += Number(loan.loan_due_amount);
    }
  });
  const handleCancel = () => {
    dispatch(setLoanModal(false));
    dispatch(setEditTakingLoanModal(false));
    dispatch(setEditGivingLoanModal(false));
    dispatch(setEditAlreadyTakenLoanModal(false));
    dispatch(setEditAlreadyGivenLoanModal(false));
  };

  const [editTakingLoanInfo, setEditTakingLoanInfo] =
    useState<LoanDataType | null>(null);
  const [editGivingLoanInfo, setEditGivingLoanInfo] =
    useState<LoanDataType | null>(null);
  const [editAlreadyTakenLoanInfo, setEditAlreadyTakenLoanInfo] =
    useState<LoanDataType | null>(null);
  const [editAlreadyGivenLoanInfo, setEditAlreadyGivenLoanInfo] =
    useState<LoanDataType | null>(null);

  // Handle Search Operation
  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  const columns: ColumnsType<ILoanTotalReport> = [
    {
      title: 'Loan Type',
      dataIndex: 'loan_type',
      key: 'loan_type',
    },
    {
      title: 'Loan Amount',
      dataIndex: 'loan_amount',
      key: 'loan_amount',
      render: (text) => (text ? Fixed2(text) : 'N/A'), // You can customize the rendering logic
    },
    {
      title: 'Payable Amount',
      dataIndex: 'payable_amount',
      key: 'payable_amount',
      render: (text) => (text ? Fixed2(text) : 'N/A'),
    },
    {
      title: 'Receivable Amount',
      dataIndex: 'receivable_amount',
      key: 'receivable_amount',
      render: (text) => (text ? Fixed2(text) : 'N/A'),
    },
    {
      title: 'Due Amount',
      dataIndex: 'due_amount',
      key: 'due_amount',
      render: (text) => (text ? Fixed2(text) : 'N/A'),
    },
    {
      title: 'Interest Amount',
      dataIndex: 'interest_amount',
      key: 'interest_amount',
      render: (text) => (text ? Fixed2(text) : 'N/A'),
    },
  ];

  return (
    <div>
      <Modal
        title='Add Loan'
        open={modalSlice.isLoanModal}
        onCancel={handleCancel}
        footer={false}
        onOk={handleCancel}
      >
        <AddLoanModal />
      </Modal>
      <Modal
        title='Edit Taking Loan'
        open={modalSlice.isEditTakingLoanModal}
        onCancel={handleCancel}
        footer={false}
        onOk={handleCancel}
      >
        {editTakingLoanInfo && (
          <EditLoanModal
            info={editTakingLoanInfo}
            setNull={setEditTakingLoanInfo}
          />
        )}
      </Modal>
      <Modal
        title='Edit Giving Loan'
        open={modalSlice.isEditGivingLoanModal}
        onCancel={handleCancel}
        footer={false}
        onOk={handleCancel}
      >
        {editGivingLoanInfo && (
          <EditLoanModal
            info={editGivingLoanInfo}
            setNull={setEditGivingLoanInfo}
          />
        )}
      </Modal>
      <Modal
        title='Edit Already Taken Loan'
        open={modalSlice.isAlreadyTakenLoanModal}
        onCancel={handleCancel}
        footer={false}
        onOk={handleCancel}
      >
        {editAlreadyTakenLoanInfo && (
          <EditLoanModal
            info={editAlreadyTakenLoanInfo}
            setNull={setEditAlreadyTakenLoanInfo}
          />
        )}
      </Modal>
      <Modal
        title='Edit Already Given Loan'
        open={modalSlice.isAlreadyGivenLoanModal}
        onCancel={handleCancel}
        footer={false}
        onOk={handleCancel}
      >
        {editAlreadyGivenLoanInfo && (
          <EditLoanModal
            info={editAlreadyGivenLoanInfo}
            setNull={setEditAlreadyTakenLoanInfo}
          />
        )}
      </Modal>
      <BreadCrumb
        arrOfOption={['Loan Management', 'Loan List']}
        refetchArr={[refetch]}
        reloaderSize='small'
      />
      <Row justify={'space-between'} style={{ marginBottom: '1rem' }}>
        {permission?.[crud.create] && (
          <Button type='primary' onClick={showAddLoanModal}>
            <PlusOutlined />
            Add Loan
          </Button>
        )}

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Here...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <FormHeaderTitle title='Loan' margintop={1} />

      <Table
        rowKey={(e) => e.loan_id}
        size='small'
        bordered
        columns={TakingLoanList({
          setEditTakingLoanInfo,
          showEditTakingLoanModal,
          permission,
          pagination,
        })}
        pagination={
          loan?.count !== undefined && loan?.count < 20
            ? false
            : {
                ...pagination,
                total: loan?.count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        dataSource={loan?.data}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
      />

      <FormHeaderTitle title='Total Loan Summary' margintop={5} />

      <Table
        style={{ marginTop: 10 }}
        rowKey={(e) => e.loan_type}
        size='small'
        bordered
        columns={columns}
        pagination={false}
        dataSource={data?.data}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default LoanList;
