import dayjs from 'dayjs';
import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';

export const InvoiceMoneyRecDiscountEndpoint = api.injectEndpoints({
  endpoints: (build) => ({
    // get all ClientDiscount
    getMoneyReceiptDis: build.query<
      HTTPResponse<invoiceMoneyRecType[]>,
      {
        formDate: string;
        toDate: string;
        page: number;
        size: number;
      }
    >({
      query: ({ formDate, page, size, toDate }) => ({
        url: `/report/invoice-money-receipt-discount?from_date=${dayjs(
          formDate
        ).format('YYYY-MM-DD')}&to_date=${dayjs(toDate).format(
          'YYYY-MM-DD'
        )}&page=${page}&size=${size}`,
      }),
      // providesTags: [{ type: 'ClientDiscount', id: CLIENTDISCOUNT }],
    }),
  }),
});

export const { useLazyGetMoneyReceiptDisQuery, useGetMoneyReceiptDisQuery } =
  InvoiceMoneyRecDiscountEndpoint;

export interface invoiceMoneyRecType {
  invoice_id: number;
  invoice_org_agency: number;
  invoice_category_id: number;
  invoice_no: string;
  invoice_discount: string;
  invoice_net_total: string;
  date: string;
  type: string;
}
