import { Route } from 'react-router';
import PermissionRoute from '../../auth/components/PermissionRoute';
import { TState } from '../../auth/states/userSlice';
import { rolePermissionType } from '../../common/types/commonTypes';
import DashboardForOthersUser from '../../modules/Dashboard/Pages/DashboardForOthersUser';
import IataDashboard from '../../modules/Dashboard/Pages/IataDashboard';
import ShowBspSummary from '../../modules/Dashboard/Pages/ShowBspSummary';
import OthersBillingSummary from '../../modules/Dashboard/Pages/OthersBillingSummary';
import BSPUpcomingPayment from '../../modules/Dashboard/Pages/BSPUpcomingPayment';

// DASHBOARD
export const getDashboardRoute = (
  permission: rolePermissionType | undefined,
  user: TState
) => {
  const dashboard = <IataDashboard />;

  return (
    <>
      <Route
        path='/'
        element={
          <PermissionRoute
            permission={['*']}
            element={
              permission?.dashboard || user?.user_role === 'SUPER_ADMIN' ? (
                dashboard
              ) : (
                <DashboardForOthersUser />
              )
            }
          />
        }
      />
      <Route
        path='/bsp-summary'
        element={
          <PermissionRoute permission={['*']} element={<ShowBspSummary />} />
        }
      />
      <Route
        path='/bsp-upcoming-payment'
        element={
          <PermissionRoute
            permission={['*']}
            element={<BSPUpcomingPayment />}
          />
        }
      />
      <Route
        path='/other-billing-summary'
        element={
          <PermissionRoute
            permission={['*']}
            element={<OthersBillingSummary />}
          />
        }
      />
    </>
  );
};
