import { Col, Row, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import {
  IAirTicketInfo,
  IInvoiceViewReissue,
} from '../../common/types/commonInterfaces';
import {
  Fixed2,
  formatAmount,
  formatAmountAbs,
  formatNumber,
} from '../../common/utils/common.utils';
import { TableType } from '../../components/common/Invoice/InvoiceCostSubtotal';
import { sumAirticketData } from '../Dashboard/Utils/invoice.utils';
import { useGetViewInvoiceAirticketDetailsQuery } from '../IATA_AIR_TICKET/api/endpoints/airticketInvoiceEndpoints';
import { IProfitInfo } from '../IATA_AIR_TICKET/pages/ViewInvoiceInfo/ViewInvoiceAirticke';
import './invoiceAirTicketView.css';

type Props = {
  profitInfo: IProfitInfo;
};

const InoviceAirticketView = ({ profitInfo }: Props) => {
  const invoice_id = Fixed2(useParams().id);
  const { data: invoices } = useGetViewInvoiceAirticketDetailsQuery(invoice_id);

  // summary
  const result = sumAirticketData(invoices?.data?.airticket_information);

  const overallProfit =
    result?.airticket_profit -
    (profitInfo?.discount || 0) +
    (profitInfo.service_charge || 0) +
    (profitInfo.invoice_void_charge || 0);

  const billingInfoData: TableType[] = [
    {
      key: '1',
      name: 'Total Profit',
      value: formatAmount(result?.airticket_profit),
    },
    {
      key: '2',
      name: 'Discount',
      value: formatAmount(profitInfo?.discount),
    },
    {
      key: '3',
      name: 'Service Charge',
      value: profitInfo?.service_charge
        ? formatNumber(profitInfo?.service_charge)
        : undefined,
    },
    {
      key: '4',
      name: 'Void Charge',
      value: profitInfo?.invoice_void_charge
        ? formatNumber(profitInfo?.invoice_void_charge)
        : undefined,
    },
    {
      key: '5',
      name: 'Overall Profit',
      value: formatAmount(overallProfit),
    },
  ];

  const billingInfoColumn: ColumnsType<any> = [
    {
      dataIndex: 'name',
      key: 'name',
      width: '150px',
    },
    {
      dataIndex: 'value',
      key: 'value',
      width: '120px',
      align: 'right',
    },
  ];

  return (
    <div className='air-ticket-view-container'>
      {invoices?.data?.reissued && invoices?.data?.reissued.length ? (
        <>
          <Typography.Title level={5} style={{ marginBottom: '5px' }}>
            REISSUE DETAILS
          </Typography.Title>
          <Table
            dataSource={invoices?.data?.reissued}
            columns={reissueInvItemCol}
            pagination={false}
          />
        </>
      ) : (
        <></>
      )}

      <Typography.Title
        level={5}
        style={{ marginBottom: '5px', marginTop: 25 }}
      >
        TICKET DETAILS
      </Typography.Title>
      <Table
        dataSource={invoices?.data?.airticket_information}
        columns={invItemCol}
        pagination={false}
        summary={() => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={5} className='bold'>
                  SUMMARY
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {formatNumber(result?.airticket_gross_fare)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  {formatNumber(result?.airticket_base_fare)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  {formatNumber(result?.airticket_commission_percent_total)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  {formatNumber(result?.airticket_ait)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  {formatNumber(result?.airticket_extra_fee)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  {formatNumber(result?.airticket_discount_total)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                  {formatNumber(result?.airticket_client_price)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8}>
                  {formatNumber(result?.airticket_purchase_price)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9}>
                  {formatNumber(result?.airticket_total_taxes_commission)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10}>
                  {formatNumber(result?.airticket_profit)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />

      <Row justify={'end'} style={{ margin: 20 }}>
        <Col>
          <Table
            size='small'
            dataSource={billingInfoData.filter((item) => item?.value)}
            columns={billingInfoColumn}
            showHeader={false}
            pagination={false}
            style={{ width: '270px' }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default InoviceAirticketView;

const invItemCol: ColumnsType<IAirTicketInfo> = [
  {
    title: 'Sl.',
    dataIndex: 'Sl.',
    key: 'airticket_id',
    render: (_, data, index) => <>{index + 1}</>,
  },
  {
    title: 'Ticket No.',
    dataIndex: 'airticket_ticket_no',
    key: 'airticket_ticket_no',
  },
  {
    title: 'Vendor Name',
    dataIndex: 'vendor_name',
    key: 'vendor_name',
  },
  {
    title: 'Issue Date',
    dataIndex: 'airticket_issue_date',
    key: 'airticket_issue_date',
    render: (curr) => <> {dayjs(curr).format('DD-MM-YY')} </>,
    width: 90,
  },
  {
    title: 'PNR',
    dataIndex: 'airticket_pnr',
    key: 'airticket_pnr',
  },
  {
    title: 'Gross Fare',
    dataIndex: 'airticket_gross_fare',
    key: 'airticket_gross_fare',
    align: 'right',
  },
  {
    title: 'Base Fare',
    dataIndex: 'airticket_base_fare',
    key: 'airticket_base_fare',
    align: 'right',
  },
  {
    title: 'Comm. Amount',
    dataIndex: 'airticket_commission_percent_total',
    key: 'airticket_commission_percent_total',
    align: 'right',
  },
  {
    title: 'AIT',
    dataIndex: 'airticket_ait',
    key: 'airticket_ait',
    align: 'right',
  },
  {
    title: 'Extra Fee',
    dataIndex: 'airticket_extra_fee',
    key: 'airticket_extra_fee',
    align: 'right',
  },
  {
    title: 'Discount',
    dataIndex: 'airticket_discount_total',
    key: 'airticket_discount_total',
    align: 'right',
  },
  {
    title: 'Clinet Price',
    dataIndex: 'airticket_client_price',
    key: 'airticket_client_price',
    align: 'right',
  },
  {
    title: 'Purchase Price',
    dataIndex: 'airticket_purchase_price',
    key: 'airticket_purchase_price',
    align: 'right',
  },
  {
    title: 'Taxes Comm.',
    dataIndex: 'airticket_total_taxes_commission',
    key: 'airticket_total_taxes_commission',
    align: 'right',
  },
  {
    title: 'Profit/Loss',
    dataIndex: 'airticket_profit',
    key: 'airticket_profit',
    align: 'right',
    render: (curr) => {
      return Number(curr) < 0 ? (
        <span className='text-loss'>{formatAmountAbs(curr)}</span>
      ) : (
        <span className='text-profit'>{formatAmountAbs(curr)}</span>
      );
    },
  },
];

const reissueInvItemCol: ColumnsType<IInvoiceViewReissue> = [
  {
    title: 'Sl.',
    dataIndex: 'Sl.',
    key: 'airticket_id',
    render: (_, data, index) => <>{index + 1}</>,
  },
  {
    title: 'Ex. Ticket No.',
    dataIndex: 'airticket_reissue_ticket_no',
    key: 'airticket_reissue_ticket_no',
  },
  {
    title: 'New Ticket No.',
    dataIndex: 'airticket_ticket_no',
    key: 'airticket_ticket_no',
  },
  {
    title: 'Issue Date',
    dataIndex: 'airticket_issue_date',
    key: 'airticket_issue_date',
    render: (curr) => <> {dayjs(curr).format('DD-MM-YY')} </>,
    width: 90,
  },

  {
    title: 'Penalties',
    dataIndex: 'airticket_penalties',
    key: 'airticket_penalties',
    align: 'right',
  },
  {
    title: 'Fare Difference',
    dataIndex: 'airticket_fare_difference',
    key: 'airticket_fare_difference',
    align: 'right',
  },
  {
    title: 'Comm',
    dataIndex: 'airticket_commission_percent',
    key: 'airticket_commission_percent',
    align: 'right',
  },
  {
    title: 'AIT',
    dataIndex: 'airticket_ait',
    key: 'airticket_ait',
    align: 'right',
  },
  {
    title: 'Client Price',
    dataIndex: 'airticket_client_price',
    key: 'airticket_client_price',
    align: 'right',
  },

  {
    title: 'Purchase Price',
    dataIndex: 'airticket_purchase_price',
    key: 'airticket_purchase_price',
    align: 'right',
  },

  {
    title: 'Profit/Loss',
    dataIndex: 'airticket_profit',
    key: 'airticket_profit',
    align: 'right',
    render: (curr) => {
      return Number(curr) < 0 ? (
        <span className='text-loss'>{formatAmountAbs(curr)}</span>
      ) : (
        <span className='text-profit'>{formatAmountAbs(curr)}</span>
      );
    },
  },
];
