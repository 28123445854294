import { HTTPResponse } from '../../../../common/types/commonTypes';
import { api } from '../../../../app/baseQuery';

import {
  employeeDataType,
  IBlood,
  IEmployeeEditFormData,
  IEmployeeFormData,
  IEmployeeSalary,
} from '../types/employeeTypes';

const EMPLOYEE = 'EMPLOYEE';
export const employeeEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all employees

    getAllEmployees: build.query<HTTPResponse<employeeDataType[]>, void>({
      query: () => ({ url: `/configuration/employee/view-all` }),
      providesTags: () => [{ type: 'Employee', id: EMPLOYEE }],
    }),

    //get all employees with pagination
    getAllEmployeesWithPagination: build.query<
      HTTPResponse<employeeDataType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/configuration/employee?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'Employee', id: EMPLOYEE }],
    }),

    //get all blood groups
    getAllBloodGroups: build.query<HTTPResponse<IBlood[]>, void>({
      query: () => ({ url: `/configuration/employee/view-blood-group` }),
    }),

    //create employee
    createEmployee: build.mutation<
      HTTPResponse<{
        employee_id: number;
      }>,
      IEmployeeFormData
    >({
      query: (body) => ({
        url: `/configuration/employee/create`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: () => [{ type: 'Employee', id: EMPLOYEE }],
    }),

    //edit employee
    editEmployee: build.mutation<void, IEmployeeEditFormData>({
      query: (body) => ({
        url: `/configuration/employee/update/${body.employee_id}`,
        method: 'PATCH',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Employee', id: EMPLOYEE }],
    }),

    //delete employee
    deleteEmployee: build.mutation<void, employeeDataType>({
      query: (body) => ({
        url: `/configuration/employee/delete/${body.employee_id}`,
        method: 'DELETE',
        body: { deleted_by: body.deleted_by },
      }),

      invalidatesTags: () => [{ type: 'Employee', id: EMPLOYEE }],
    }),
    //employeeSalaryById employee
    employeeSalaryById: build.query<HTTPResponse<IEmployeeSalary>, number>({
      query: (id) => ({
        url: `/configuration/employee/${id}`,
      }),
      providesTags: () => [{ type: 'Employee', id: EMPLOYEE }],
    }),
    employeeDetailsById: build.query<HTTPResponse<employeeDataType>, string>({
      query: (id) => ({
        url: `/configuration/employee/${id}`,
      }),
      providesTags: () => [{ type: 'Employee', id: EMPLOYEE }],
    }),
    viewEmployeeCommission: build.query<
      HTTPResponse<{ net_total: number }>,
      { employee_id: number; month: string }
    >({
      query: (body) => ({
        url: `/payroll/employee-commission/${body.employee_id}?month=${body.month}`,
      }),
      providesTags: () => [{ type: 'Employee', id: EMPLOYEE }],
    }),
  }),
});

export const {
  useGetAllEmployeesQuery,
  useGetAllEmployeesWithPaginationQuery,
  useGetAllBloodGroupsQuery,
  useCreateEmployeeMutation,
  useEditEmployeeMutation,
  useDeleteEmployeeMutation,
  useLazyEmployeeSalaryByIdQuery,
  useLazyEmployeeDetailsByIdQuery,
  useLazyViewEmployeeCommissionQuery,
} = employeeEndpoints;
