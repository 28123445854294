import { ArrowLeftOutlined, PrinterFilled } from '@ant-design/icons';
import { Button, DatePicker, Form, Row, Space, Table } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { IAccountStateFormType } from '../../AccountsTypes/AccountsTypes';
import {
  useGetSingleAccountQuery,
  useLazyGetAccountStatementQuery,
} from '../../Api/Endpoints/accountsEndpoints';
import { ListOfAccountsStatementColumn } from '../../utils/Add_ListOfAccounts/ListOfAccountsStatementColumn';

const AccountStatement = () => {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const { id } = useParams();
  const [from, setFrom] = useState(dayjs().format('YYYY-MM-DD'));
  const [to, setTo] = useState(dayjs().format('YYYY-MM-DD'));

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const [
    fetchAccountStatement,
    { isLoading, isFetching, data: accountStatement },
  ] = useLazyGetAccountStatementQuery();

  const { isLoading: isSingleAccountLoading, data: singleAccount } =
    useGetSingleAccountQuery(Number(id));

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Account_Statement_${dayjs()}`,
    pageStyle: '',
  });

  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
  }, []);
  useEffect(() => {
    const body = {
      current: pagination.current,
      pageSize: pagination.pageSize,
      date_range: `?from_date=${from}&to_date=${to}`,
      id: Number(id),
    };
    fetchAccountStatement(body);
  }, [pagination]);

  const onFinish = (values: IAccountStateFormType) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    setFrom(setFrom_date);
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    setTo(setTo_date);

    const body: IAccountStateFormType = {
      current: pagination.current,
      pageSize: pagination.pageSize,
      date_range: `?from_date=${dayjs(values.date_range[0]).format(
        'YYYY-MM-DD'
      )}&to_date=${dayjs(values.date_range[1]).format('YYYY-MM-DD')}`,
      id: Number(id),
    };
    fetchAccountStatement(body);
  };

  // pagination
  const tablePagination =
    accountStatement?.count !== undefined && accountStatement?.count < 20
      ? false
      : {
        ...pagination,
        total: accountStatement?.count,
        showSizeChanger: true,
        pageSizeOptions: ['50', '100', '200', '500'],
        onChange: handlePaginationChange,
      };

  // @----//-----------//------------HANDLE PRINT---------//------------------//---
  const accountStatements = accountStatement?.data ? (
    <>
      {accountStatement?.data ? (
        <>
          <CommonTableHeader
            title={
              isSingleAccountLoading
                ? ''
                : `Bank Statement of ${singleAccount?.data?.account_name}`
            }
          />
          {
            <Table
              size='small'
              bordered
              rowKey={(e) => e.serial}
              columns={ListOfAccountsStatementColumn({ pagination })}
              dataSource={accountStatement?.data}
              loading={{
                spinning: isLoading || isFetching,
                indicator: loadingIndicator,
              }}
              pagination={tablePagination}
            />
          }
        </>
      ) : (
        ''
      )}
    </>
  ) : (
    <></>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={accountStatements}
        printRef={componentRef}
        title={{ info_title: '', title: 'Statement' }}
      />
    </div>
  );

  return (
    <Style>
      <BreadCrumb arrOfOption={['Accounts', 'Account Statement']} />
      <Space>
        <Link to='/accounts'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Accounts List
          </Button>
        </Link>

        <Button type='primary' onClick={handlePrint}>
          <PrinterFilled />
          Print
        </Button>
      </Space>

      <Form onFinish={onFinish} layout='vertical' form={form}>
        <Row>
          <Space>
            <Form.Item
              label='Date Range'
              name='date_range'
              required
              rules={[
                {
                  required: true,
                  message: 'date is required',
                },
              ]}
            >
              <RangePicker format={'DD-MM-YYYY'} />
            </Form.Item>
            <Form.Item label=' '>
              <Button type={'primary'} htmlType='submit'>
                Search
              </Button>
            </Form.Item>
          </Space>
        </Row>
      </Form>
      {/* @STATEMENT CONTENTS */}
      {accountStatements}
      {/* @PRINTABLE CONTENT */}
      {print_content}
    </Style>
  );
};

export default AccountStatement;
const Style = styled.div``;
