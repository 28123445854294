import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  PrinterOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  FloatButton,
  Image,
  Modal,
  Row,
  Space,
  Tabs,
  Typography,
  message,
  theme,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import { useAppSelector } from '../../../app/hooks';
import { selectUser } from '../../../auth/states/userSlice';
import {
  ReceiptHeader,
  TitleCenter,
} from '../../../common/Invoice/InvoiceHeader';
import { IPermission } from '../../../common/types/commonTypes';
import { FormatDatePrint } from '../../../common/utils/common.utils';
import { invoiceViewStyle } from '../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import NumToWord from '../../../components/number_to_words/NumToWord';
import {
  useDeleteVendorPaymentMutation,
  useLazyGetVPayForViewQuery,
} from '../api/endpoints/vendorPaymentEndpoints';
import VendorPaymentViewDetails from '../components/VendorPaymentViewDetails';
import dayjs from 'dayjs';
import { portraitPageStyle } from '../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader';

type Props = { permission?: IPermission };

const Container = styled.div`
  .underline {
    border-bottom: 2px dotted black;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
`;

export default function VendorPaymentView({ }: Props) {
  const { id } = useParams();
  const user = useSelector(selectUser);

  //view vendor payment
  const [vendorPaymentView, { data: vendorView }] =
    useLazyGetVPayForViewQuery();
  const data = vendorView?.data;
  const appConfig = useAppSelector((state) => state.configSlice);

  useEffect(() => {
    if (id) {
      vendorPaymentView(Number(id));
    }
  }, [id]);

  const cashiercomponentRef = useRef(null);

  const org_name = useAppSelector(
    (state) => state.user?.organization_info.org_name
  );
  const handleCashierPrint = useReactToPrint({
    content: () => cashiercomponentRef.current,
    documentTitle: `${org_name}-${vendorView?.data?.vouchar_no
      }-${dayjs().unix()}`,
    removeAfterPrint: true,
    pageStyle: portraitPageStyle,
  });

  // const amount =
  //   Number(data?.payment_amount || 0) + Number(data?.vendor_ait || 0);

  const qrCodeValue = `
Payment Voucher No: :${data?.vouchar_no}
Amount:${data?.payment_amount}
Date:${data?.payment_date}
      `;

  // Floating Button Operation
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const [
    deleteVendorPay,
    { isError: deleteError, isSuccess: deleteSucc, isLoading: deleteLoading },
  ] = useDeleteVendorPaymentMutation();

  const handleDelete = () => {
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    const body = { id: Number(id), updated_by: user?.user_id as number };
    deleteVendorPay(body);
  };

  const handleEdit = () => {
    navigate('../' + 'vendors/payment/edit' + '/' + id, {
      state: `/vendors/payment`,
    });
  };

  useEffect(() => {
    if (deleteSucc) {
      message.success('Vendor Payment Delete Successfully');
      setIsModalOpen(false);
      navigate(-1);
    }
  }, [deleteSucc]);

  return (
    <>
      <BreadCrumb arrOfOption={['Money Receipt', 'View Money Receipt']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/vendors/payment'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Money Receipt List
          </Button>
        </Link>
        <Button type='primary' onClick={handleCashierPrint}>
          <PrinterOutlined />
          Print
        </Button>
      </Space>

      <Tabs
        style={{ marginTop: '15px' }}
        type='card'
        items={[
          {
            key: '1',
            label: 'Invoice',
            children: (
              <ConfigProvider
                theme={{
                  algorithm: theme.defaultAlgorithm,
                }}
              >
                <div style={invoiceViewStyle}>
                  <ConfigProvider
                    theme={{
                      algorithm: theme.defaultAlgorithm,
                    }}
                  >
                    <Container
                      style={{
                        width: '8.27in',
                        margin: '0 auto',
                        background: '#fff',
                        padding: '14px',
                      }}
                    >
                      <div
                        ref={cashiercomponentRef}
                        style={{ padding: '0.3in', position: 'relative' }}
                      >
                        {appConfig.tac_signtr === 1 && (
                          <div
                            style={{
                              position: 'absolute',
                              bottom: 50,
                              right: 40,
                              pointerEvents: 'none',
                              userSelect: 'none',
                            }}
                          >
                            <Image
                              preview={false}
                              src={appConfig.tac_sig_url}
                              width={90}
                            ></Image>
                          </div>
                        )}

                        <header>
                          <Row justify={'space-between'} align='middle'>
                            <ReceiptHeader qrCodeValue={qrCodeValue} />
                          </Row>

                          <TitleCenter title='payment receipt' />
                        </header>

                        <Row
                          justify={'space-between'}
                          style={{ margin: '30px 0' }}
                        >
                          <Typography.Text className='changeFontBlock'>
                            Payment Voucher No: {data?.vouchar_no}
                          </Typography.Text>
                          <Typography.Text className='changeFontBlock'>
                            Payment   Date :
                            {data?.payment_date
                              ? FormatDatePrint(data?.payment_date)
                              : ''}
                          </Typography.Text>
                        </Row>

                        <section>
                          <Row justify={'space-between'}>
                            <Col>
                              <Typography.Text className='changeFontBlock'>
                                Payment with thanks from:
                                <span
                                  style={{
                                    fontFamily: "'Source Sans Pro', sans-serif",
                                  }}
                                  className='underline'
                                >
                                  {data?.vendor_name}
                                </span>
                              </Typography.Text>
                              <Typography.Text className='changeFontBlock mt-5'>
                                Payment for :
                                <span className='underline'>
                                  {data?.vpay_payment_to}
                                </span>
                              </Typography.Text>
                              {data?.invoice_no && (
                                <Typography.Text className='changeFontBlock mt-5'>
                                  Invoice no:{' '}
                                  <span className='underline'>
                                    {data?.invoice_no}
                                  </span>
                                </Typography.Text>
                              )}
                              <Row
                                justify={'space-between'}
                                style={{ marginTop: '10px' }}
                              >
                                <Typography.Text className='changeFontBlock'>
                                  For the purpose of :
                                  <span className='underline'>
                                    {' '}
                                    {data?.note}{' '}
                                  </span>
                                </Typography.Text>
                              </Row>
                              {/* <Typography.Text className='changeFontBlock mt-5'>
                      Account Name :
                      <span className='underline'>{data?.account_name}</span>
                    </Typography.Text> */}
                            </Col>

                            <Col>
                              <Typography.Text className='changeFontBlock mt-5'>
                                Payment Amount :
                                <span
                                  className='underline'
                                  style={
                                    Number(data?.payment_amount) > 0
                                      ? { color: 'green' }
                                      : { color: 'red' }
                                  }
                                >
                                  {data?.payment_amount}
                                </span>
                              </Typography.Text>
                              {data?.online_charge ? (
                                <Typography.Text className='changeFontBlock mt-5'>
                                  Transaction Charge :
                                  <span className='underline'>
                                    {data?.online_charge}
                                  </span>
                                </Typography.Text>
                              ) : null}
                              <Row className='mt-5' justify='space-between'>
                                {data?.payment_method_id == 1 && (
                                  <>
                                    <Typography.Text className='changeFontBlock'>
                                      Paid Via :
                                      <span className='underline'>
                                        {data?.account_name}
                                      </span>
                                    </Typography.Text>
                                  </>
                                )}
                                {data?.payment_method_id == 2 && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                    }}
                                  >
                                    <Typography.Text className='changeFontBlock'>
                                      Paid Via :
                                      <span className='underline'>
                                        {'Bank'}
                                      </span>
                                    </Typography.Text>
                                    <Typography.Text className='changeFontBlock'>
                                      Account Name :
                                      <i className='underline'>
                                        {data?.account_name}
                                      </i>
                                    </Typography.Text>
                                  </div>
                                )}
                                {data?.payment_method_id == 4 && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                    }}
                                  >
                                    <Typography.Text className='changeFontBlock'>
                                      Paid Via:
                                      <span className='underline'>
                                        {'Cheque'}
                                      </span>
                                    </Typography.Text>

                                    <Typography.Text className='changeFontBlock'>
                                      Cheque No :
                                      <span className='underline'>
                                        {data?.cheque_no}
                                      </span>
                                    </Typography.Text>
                                    <Typography.Text className='changeFontBlock'>
                                      Bank Name :
                                      <span className='underline'>
                                        {data?.vpcheque_bank_name}
                                      </span>
                                    </Typography.Text>
                                  </div>
                                )}
                                {data?.payment_method_id == 3 && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                    }}
                                  >
                                    <Typography.Text className='changeFontBlock'>
                                      Paid Via :
                                      <span className='underline'>
                                        {'Mobile Banking'}
                                      </span>
                                    </Typography.Text>
                                  </div>
                                )}
                              </Row>
                              {data?.vendor_name && (
                                <Typography.Text className='changeFontBlock mt-5'>
                                  Vendor:{' '}
                                  <span className='underline'>
                                    {data?.vendor_name}
                                  </span>
                                </Typography.Text>
                              )}
                              <Typography.Text className='changeFontBlock mt-5'>
                                <span className='underline'>
                                  {data?.payment_amount && (
                                    <NumToWord
                                      number={Number(data?.payment_amount)}
                                    />
                                  )}
                                </span>
                              </Typography.Text>
                            </Col>
                          </Row>
                        </section>

                        <Row
                          style={{ marginTop: 50 }}
                          justify='space-between'
                          align={'middle'}
                        >
                          <Col
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{
                                background: 'black',
                                margin: '0px',
                                width: '150px',
                                height: '1px',
                              }}
                            />
                            <Typography.Text className='changeFontBlock'>
                              Customer Signature
                            </Typography.Text>
                          </Col>

                          <Col
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{
                                background: 'black',
                                margin: '0px',
                                width: '150px',
                                height: '1px',
                              }}
                            />
                            <Typography.Text className='changeFontBlock'>
                              Authority Signature
                            </Typography.Text>
                          </Col>
                        </Row>
                      </div>
                    </Container>
                  </ConfigProvider>

                  <FloatButton.Group
                    trigger='click'
                    type='primary'
                    style={{ right: 24 }}
                    icon={<ThunderboltOutlined />}
                  >
                    <FloatButton
                      onClick={handleEdit}
                      tooltip={'Edit invoice'}
                      icon={<EditOutlined style={{ color: 'blue' }} />}
                    />

                    <FloatButton
                      onClick={handleDelete}
                      tooltip={'Delete invoice'}
                      icon={<DeleteOutlined style={{ color: 'red' }} />}
                    />
                  </FloatButton.Group>

                  <Modal
                    centered
                    title='Are you sure you want to delete this invoice? '
                    open={isModalOpen}
                    onOk={handleConfirmDelete}
                    onCancel={() => setIsModalOpen(false)}
                    footer={[
                      <Button
                        key='cancel'
                        onClick={() => setIsModalOpen(false)}
                      >
                        Cancel
                      </Button>,
                      <Button
                        loading={deleteLoading}
                        danger
                        key='delete'
                        type='primary'
                        onClick={handleConfirmDelete}
                      >
                        Delete
                      </Button>,
                    ]}
                  >
                    <p>This action cannot be undone.</p>
                  </Modal>
                </div>
              </ConfigProvider>
            ),
          },
          {
            key: '2',
            label: 'Details',
            children: (
              <ConfigProvider
                theme={{
                  algorithm: theme.defaultAlgorithm,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(211, 211, 211, 0.2)',
                    minHeight: '100vh',
                  }}
                >
                  <VendorPaymentViewDetails id={id} />
                </div>
              </ConfigProvider>
            ),
          },
        ]}
      ></Tabs>
    </>
  );
}
