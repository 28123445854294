import { Col, ConfigProvider, Row, Typography, theme } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { useAppSelector } from '../../../../../../app/hooks';
import { InvoiceFooter } from '../../../../../../common/Invoice/InvoiceFooter';
import {
  InvoiceHeader,
  TitleCenter,
} from '../../../../../../common/Invoice/InvoiceHeader';
import { IViewInvoiceDetails } from '../../../../../../common/types/commonInterfaces';
import { Fixed2 } from '../../../../../../common/utils/common.utils';
import ViewFakeInvoiceSubTotal from './ViewFakeInvoiceSubTotal';

export type HeightRefsType = {
  header_ref: React.RefObject<HTMLDivElement>;
  subtotal_ref: React.RefObject<HTMLDivElement>;
  signiture_ref: React.RefObject<HTMLDivElement>;
};

type Props = {
  invoiceData: IViewInvoiceDetails;
  viewInvoicePrintableRef: React.RefObject<HTMLDivElement>;
  children: JSX.Element;
  heightRefs: HeightRefsType;
};

export const officeInfo = {
  name: 'M360 ICT',
  address1: '3RD FLOOR FLAT-F2(ROAD 07 HOUSE 74)',
  address2: 'BANANI, DHAKA 1213',
  modile: ' +88-01715248942',
};

export const invoiceViewStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'rgba(211, 211, 211, 0.2)',
  minHeight: '100vh',
  padding: '20px 0',
};
export const a4sizeStyle: React.CSSProperties = {
  minHeight: '11.5in',
  width: '8.27in',
  fontSize: '11px',
  background: '#fff',
  margin: '0px auto',
  boxSizing: 'border-box',
  padding: '0 15px',
};
export const a4sizeStyleHalfPage: React.CSSProperties = {
  minHeight: '5.75in',
  width: '8.27in',
  boxSizing: 'border-box',
  fontSize: '12px',
  background: '#fff',
  margin: '0 auto',
};

const ViewFakeInvoice = ({
  invoiceData,
  viewInvoicePrintableRef,
  children,
  heightRefs,
}: Props) => {
  const {
    client_name,
    invoice_walking_customer_name,
    client_address,
    client_mobile,
    client_email,
    invoice_no,
    invoice_date,
    invoice_sales_date,
    employee_full_name,
    refunds,
    invoice_category_id,
    invoice_pay,
    invoice_net_total,
    invoice_due_date,
    company_name,
    agent_name,
    invoice_reissue_client_type,
    invoice_is_refund,
    invoice_reference,
  } = invoiceData;

  // Invoice Action
  const userID = useAppSelector((state) => state.user?.user_id);
  const appConfig = useAppSelector((state) => state.configSlice);
  const OrgInfo = useAppSelector((state) => state.user?.organization_info);
  const OrgOwnerName = useAppSelector((state) => state.user?.user_full_name);

  const navigate = useNavigate();
  const invoice_id = Fixed2(useParams().id);

  const { tac_inv_in, tac_inv_sd } = appConfig;

  return (
    <ViewInvoiceStyle style={invoiceViewStyle}>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <div
          ref={viewInvoicePrintableRef}
          style={{ ...a4sizeStyle, position: 'relative' }}
        >
          <header ref={heightRefs.header_ref}>
            <InvoiceHeader />

            <TitleCenter title='Invoice' />

            <Row justify={'space-between'} align='middle'>
              <Col
                style={{
                  color: '#fff',

                  width: '50%',
                }}
              >
                <Typography style={{ fontWeight: 'bolder' }}>
                  Invoice To
                </Typography>
                {(client_name || company_name) && (
                  <Typography.Text className='changeFontBlock fs-12'>
                    {company_name ? 'Company Name :' : 'Name :'}
                    {company_name ? company_name : client_name}
                    {invoice_walking_customer_name &&
                      '/' + invoice_walking_customer_name}
                  </Typography.Text>
                )}
                {client_address && (
                  <Typography.Text className='changeFontBlock fs-12'>
                    Address : {client_address}
                  </Typography.Text>
                )}
                {client_email && (
                  <Typography.Text className='changeFontBlock fs-12'>
                    Email : {client_email}
                  </Typography.Text>
                )}
                {client_mobile && (
                  <Typography.Text className='changeFontBlock fs-12'>
                    Mobile : {client_mobile}
                  </Typography.Text>
                )}

                {invoice_reference && (
                  <Typography.Text className='changeFontBlock fs-12'>
                    Reference : {invoice_reference}
                  </Typography.Text>
                )}
              </Col>

              <Col>
                {invoice_is_refund || refunds?.client_refund?.length ? (
                  <span style={{ color: 'red' }}>Refunded</span>
                ) : (
                  ''
                )}

                <Typography.Text className='changeFontBlock fs-12'>
                  Invoice Date : {dayjs(invoice_date).format('DD MMM YYYY')}
                </Typography.Text>
                <Typography.Text className='changeFontBlock fs-12'>
                  {tac_inv_in || 'Invoice No'}: {invoice_no}
                </Typography.Text>
                <Typography.Text className='changeFontBlock fs-12'>
                  {tac_inv_sd || 'Sales Date'} :{' '}
                  {dayjs(invoice_sales_date).format('DD MMM YYYY')}
                </Typography.Text>
                {invoice_due_date && (
                  <Typography.Text className='changeFontBlock fs-12'>
                    {'Due Date'} :{' '}
                    {dayjs(invoice_due_date).format('DD MMM YYYY')}
                  </Typography.Text>
                )}
                <Typography.Text className='changeFontBlock fs-12'>
                  Sales By : {employee_full_name}
                </Typography.Text>
                {agent_name && (
                  <Typography.Text className='changeFontBlock fs-12'>
                    {'Agent Name:'}
                    {agent_name}
                  </Typography.Text>
                )}
              </Col>
            </Row>
          </header>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',

              boxSizing: 'border-box',
              minHeight: '7.7in',
            }}
          >
            <div>
              {children}
              <div ref={heightRefs.subtotal_ref}>
                <ViewFakeInvoiceSubTotal invoice_info={invoiceData} />
              </div>
            </div>

            {/* Footer */}

            <div ref={heightRefs.signiture_ref}>
              <InvoiceFooter
                authorized_by={invoiceData.authorized_by}
                prepared_by={invoiceData.prepared_by}
              />
            </div>
          </div>
        </div>
      </ConfigProvider>
    </ViewInvoiceStyle>
  );
};

export default ViewFakeInvoice;

const ViewInvoiceStyle = styled.div``;

type CategoryID = number;
type RouteName = string;

function findInvoiceRoute(
  invoiceCategoryId: CategoryID
): RouteName | undefined {
  const categoryNames: Record<CategoryID, RouteName> = {
    1: 'airticket',
    2: 'noncomairticket',
    3: 'reissueairticket',
    4: 'tour/invoice',
    5: 'invoiceother',
    10: 'visa',
    26: 'invoiceumrah',
    30: 'hajjpreregistration',
    31: 'invoicehajj',
  };

  return categoryNames[invoiceCategoryId];
}

function findInvoiceDeleteRoute(
  invoiceCategoryId: CategoryID
): RouteName | undefined {
  const categoryNames: Record<CategoryID, RouteName> = {
    1: 'invoice-air-ticket',
    2: 'invoice-non-commission',
    3: 'reissue',
    4: 'tour-package',
    5: 'invoice-others/delete',
    10: 'invoice-visa',
    26: 'invoice-ummrah',
    30: 'invoice_hajj_pre',
    31: 'invoic-hajj/delete',
  };

  return categoryNames[invoiceCategoryId];
}
