import {
  DeleteFilled,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Space,
  Table,
  TimePicker,
  Typography,
} from 'antd';
import { TableProps } from 'antd/lib';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  IFlights,
  IPaxDetails,
} from '../../../../common/types/commonInterfaces';
import { IAirticketBillingInfo } from '../../types/invoiceAirTicketTypes';
import { filterNullColumns } from '../../../../common/utils/common.utils';

// FLIGHTS DATA

export interface IFlightData {
  flight_id: number;
  fltdetails_fly_date: string;
  fltdetails_arrival_time: string;
  fltdetails_departure_time: string;
  airline_name: string;
  flight_from: string;
  flight_to: string;
}

type DataIndex = keyof IFlightData;
// COMMON
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: DataIndex;
  title: any;
  inputType: 'number' | 'text' | 'time' | 'airline';
  record: IFlightData;
  index: number;
}

type IProps = {
  invoiceFlight: IFlights[] | undefined;
  setAllEditableData?: any;
  allEditableData: {
    flightDetails?: IFlightData[] | undefined;
    paxDetails?: IPaxDetails[] | undefined;
    billingDetails?: IAirticketBillingInfo[] | undefined;
    subtotalDetails?: any;
  };
  setIsFlightUpdate?: React.Dispatch<
    React.SetStateAction<{
      update: string;
      add: string;

      remove: string;
    }>
  >;
  isFlightUpdate?: {
    update: string;
    add: string;

    remove: string;
  };
};
const ManualEditInVoiceFlight = ({
  invoiceFlight,
  setAllEditableData,
  allEditableData,
  isFlightUpdate,
  setIsFlightUpdate,
}: IProps) => {
  const [data, setData] = useState<any>(
    invoiceFlight?.map((item, index) => ({
      ...item,
      flight_id: index + 1,
    }))
  );

  const [editingKey, setEditingKey] = useState(0);
  const [isAdding, setIsAdding] = useState(false);
  const [form] = Form.useForm();
  const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode = <Input size='small' />;

    if (inputType === 'number') {
      inputNode = <InputNumber size='small' />;
    } else if (inputType === 'time') {
      inputNode = (
        <span>
          <TimePicker
            defaultValue={dayjs(record[dataIndex], 'HH:mm:ss')}
            onChange={(date) => {
              form.setFieldsValue({
                [dataIndex]: date ? date.format('HH:mm:ss') : '',
              });
            }}
            size='small'
            format='HH:mm:ss'
          />
        </span>
      );
    } else {
      inputNode = <Input size='small' />;
    }
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            // rules={[
            //   {
            //     required: true,
            //     message: `Please Input ${title}!`,
            //   },
            // ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  const columns = [
    {
      title: 'Sl.',
      dataIndex: 'flight_id',
      editable: false,
    },
    {
      title: 'From',
      dataIndex: 'flight_from',
      key: 'flight_from',
      editable: true,
    },
    {
      title: 'To',
      dataIndex: 'flight_to',
      key: 'flight_to',
      editable: true,
    },
    {
      title: 'Airline',
      dataIndex: 'airline_name',
      key: 'airline_name',
      editable: true,
    },
    {
      title: 'DT.',
      dataIndex: 'fltdetails_departure_time',
      key: 'fltdetails_departure_time',
      editable: true,
    },
    {
      title: 'AT.',
      dataIndex: 'fltdetails_arrival_time',
      key: 'fltdetails_arrival_time',
      editable: true,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_: any, record: IFlightData, index: number) => {
        const editable = isEditing(record);
        return editable && !isAdding ? (
          <Space>
            <Typography.Link onClick={() => save(record.flight_id)}>
              ✅
            </Typography.Link>
            <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
              <a>❌</a>
            </Popconfirm>
          </Space>
        ) : isAdding ? (
          <>
            <Typography.Link onClick={() => save(record.flight_id)}>
              ✅
            </Typography.Link>
            <Typography.Link
              disabled={editingKey > record.flight_id}
              onClick={() => handleDelete(record.flight_id)}
            >
              <DeleteFilled style={{ color: 'red' }} />
            </Typography.Link>
          </>
        ) : (
          <Space>
            <EditOutlined
              style={{
                color: 'blue',
              }}
              disabled={editingKey !== 0}
              onClick={() => edit(record)}
            />
            {data?.length === index + 1 ? (
              <a onClick={() => Add(record)}>
                <PlusCircleOutlined />
              </a>
            ) : (
              <PlusCircleOutlined disabled />
            )}

            <DeleteFilled
              onClick={() => handleDelete(record.flight_id)}
              style={{ color: 'red' }}
            />
          </Space>
        );
      },
    },
  ];
  const filteredBillingColumns: any = filterNullColumns(data, columns);
  const mergedColumns: TableProps['columns'] = filteredBillingColumns?.col?.map(
    (col: any) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: IFlightData) => ({
          record,
          inputType:
            col.dataIndex === 'fltdetails_arrival_time' ||
            col.dataIndex === 'fltdetails_departure_time'
              ? 'time'
              : col.dataIndex === 'airline_name'
              ? 'airline'
              : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    }
  );
  const isEditing = (record: IFlightData) => record.flight_id === editingKey;
  const Add = (record: IFlightData & { flight_id: React.Key }) => {
    isFlightUpdate &&
      setIsFlightUpdate &&
      setIsFlightUpdate({
        ...isFlightUpdate,
        add: 'add',
      });
    setIsAdding(true);
    const newData: any = {
      flight_id: record.flight_id + 1,
      flight_from: '',
      flight_to: '',
      airline_name: '',
      fltdetails_arrival_time: dayjs('00:00:00', 'HH:mm:ss'),
      fltdetails_departure_time: dayjs('00:00:00', 'HH:mm:ss'),
    };

    form.setFieldsValue({
      flight_id: record.flight_id + 1,
      flight_from: '',
      flight_to: '',
      airline_name: '',
      fltdetails_arrival_time: dayjs('00:00:00', 'HH:mm:ss'),
      fltdetails_departure_time: dayjs('00:00:00', 'HH:mm:ss'),
    });
    setData([...data, newData]);
    setEditingKey(record.flight_id + 1);
  };
  const handleDelete = (key: any) => {
    const newData = data?.filter((item: any) => item.flight_id !== key);
    setData(newData);
    setEditingKey(0);
    setIsAdding(false);
    isFlightUpdate &&
      setIsFlightUpdate &&
      setIsFlightUpdate({
        ...isFlightUpdate,
        remove: 'remove',
        add: '',
      });
  };
  const edit = (record: IFlightData & { flight_id: React.Key }) => {
    isFlightUpdate &&
      setIsFlightUpdate &&
      setIsFlightUpdate({ ...isFlightUpdate, update: 'update' });
    form.setFieldsValue({ name: '', age: '', address: '', ...record });
    setEditingKey(record.flight_id);
  };

  const cancel = () => {
    isFlightUpdate &&
      setIsFlightUpdate &&
      setIsFlightUpdate({
        ...isFlightUpdate,
        remove: '',
        add: '',
        update: '',
      });
    setEditingKey(0);
  };

  const save = async (id: React.Key) => {
    try {
      const row = await form.validateFields();

      const newData = [...data];
      const index = newData.findIndex((item) => id === item.flight_id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey(0);
        setIsAdding(false);
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey(0);
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  useEffect(() => {
    setAllEditableData((prev: any) => ({
      ...prev,
      flightDetails: [...data],
    }));
  }, [data]);

  // Initial load of data to allEditableData
  useEffect(() => {
    setAllEditableData((prev: any) => ({
      ...prev,
      flightDetails: [...(invoiceFlight as IFlightData[])],
    }));
  }, []);
  return (
    <>
      <Typography.Title level={5} style={{ marginBottom: '5px' }}>
        FLIGHT/ROUTE DETAILS
      </Typography.Title>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          pagination={false}
          style={{ marginBottom: '2rem' }}
          className='invoiceBillingTable'
          rowClassName={'invoiceBillingTd'}
        />
      </Form>
    </>
  );
};

export default ManualEditInVoiceFlight;
