import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import {
  IAirTicketDetails,
  IAirTicketSummary,
} from '../Interfaces/AirTicketReport.interfaces';

export const AirTicketReport = api.injectEndpoints({
  endpoints: (build) => ({
    getAirTicketSummary: build.query<
      HTTPResponse<IAirTicketSummary[]>,
      {
        todDate: string;
        formDate: string;
        current: number;
        pageSize: number;
        client?: string;
      }
    >({
      query: ({ current, formDate, pageSize, todDate, client }) => {
        let url = `/report/air-ticket-summary?from_date=${formDate}&to_date=${todDate}&page=${current}&size=${pageSize}&client=${client}`;

        return {
          url,
          method: 'GET',
        };
      },

      providesTags: [{ type: 'invoice' }, 'invoices'],
    }),

    getAirTicketExcel: build.query<
      any,
      {
        formDate: string;
        todDate: string;
      }
    >({
      query: ({ formDate, todDate }) => ({
        url: `/report/air-ticket-summary-excel?from_date=${formDate}&to_date=${todDate}`,
        responseHandler: async (response) => {
          const filename = `AirTicket-Total-Summary.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
    }),

    // AirTicket Details END POINTS

    getAirTicketDetails: build.query<
      HTTPResponse<{
        list: IAirTicketDetails[];
        total_sales_price: string;
        total_purchase_price: string;
      }>,
      {
        todDate: string;
        formDate: string;
        current: number;
        pageSize: number;
        client?: string;
      }
    >({
      query: ({ current, formDate, pageSize, todDate, client }) => {
        let url = `/report/air-ticket-details?from_date=${formDate}&to_date=${todDate}&page=${current}&size=${pageSize}&client=${client}`;

        return {
          url,
          method: 'GET',
        };
      },

      providesTags: [{ type: 'invoice' }],
    }),

    getAirDetailsExcel: build.query<
      any,
      {
        formDate: string;
        todDate: string;
      }
    >({
      query: ({ formDate, todDate }) => ({
        url: `/report/air-ticket-details-excel?from_date=${formDate}&to_date=${todDate}`,
        responseHandler: async (response) => {
          const filename = `AirTicket-Details-Summary.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useLazyGetAirTicketSummaryQuery,
  useGetAirTicketSummaryQuery,
  useLazyGetAirTicketExcelQuery,

  useLazyGetAirDetailsExcelQuery,
  useGetAirTicketDetailsQuery,
} = AirTicketReport;
