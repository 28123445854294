import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Space,
  Table,
  TimePicker,
} from 'antd';

import {
  DeleteFilled,
  EditOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import { Typography } from 'antd';
import { TableProps } from 'antd/lib';
import React, { useEffect, useState } from 'react';
import {
  InvoiceHeader,
  TitleCenter,
} from '../../../../common/Invoice/InvoiceHeader';
import InvoiceBillingEdit from '../components/InvoiceBillingEdit';
import InvoicePaxEdit from '../components/InvoicePaxEdit';
import InvoiceSubtotalEdit from '../components/InvoiceSubtotalEdit';
import { InvoiceFooter } from '../../../../common/Invoice/InvoiceFooter';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import { portraitPageStyle } from '../../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader';
import PrintComponent from '../components/PrintComponent';
import InvoiceOtherBillingEdit from '../components/InvoiceOtherBillingEdit';
import { IPaxDetails } from '../../../../common/types/commonInterfaces';
import { IAirticketBillingInfo } from '../../../IATA_AIR_TICKET/types/invoiceAirTicketTypes';
import ManualEditInVoiceFlight, {
  IFlightData,
} from '../../../IATA_AIR_TICKET/Components/ManualInvoiceAirTicketEdited/ManualEditInVoiceFlight';
import ManualInvoiceCommonHeader from '../../../IATA_AIR_TICKET/Components/ManualInvoiceAirTicketEdited/ManualInvoiceCommonHeader';

const a4sizeStyle: React.CSSProperties = {
  minHeight: '11.5in',
  width: '8.27in',
  fontSize: '11px',
  background: '#fff',
  margin: '0 auto',
  boxSizing: 'border-box',
  padding: '0 15px',
};
const invoiceViewStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'rgba(211, 211, 211, 0.2)',
  padding: '20px 0',
  height: '100%',
};

const invoiceData = {
  invoice_no: 'AIT-0507',
  invoice_reference: null,
  invoice_client_previous_due: '-277702.00',
  invoice_total_profit: '5524.00',
  invoice_show_discount: null,
  invoice_show_prev_due: null,
  employee_full_name: 'RRRR',
  invoice_net_total: '105274.00',
  invoice_sub_total: '105814.00',
  invoice_total_vendor_price: '100290.00',
  invoice_category_id: 1,
  invoice_date: '2024-07-01T03:56:17.000Z',
  invoice_sales_date: '2024-06-29T18:00:00.000Z',
  invoice_due_date: null,
  invoice_vat: null,
  invoice_service_charge: null,
  invoice_discount: 540,
  agent_name: null,
  invoice_agent_com_amount: null,
  invoice_walking_customer_name: null,
  invoice_reissue_client_type: 'PNR',
  invoice_is_refund: 0,
  invoice_combclient_id: 'client-52282',
  client_name: 'Arash 45',
  client_mobile: '01705511718',
  client_email: null,
  client_address: null,
  invoice_note: 'THIS IS A SOFTWARE GENERATED INVOICE COPY/PNR',
  user_first_name: 'trabill',
  is_edited: 0,
  invoice_pay: null,
  invoice_due: null,
  billing_info: [
    {
      airticket_id: 14248,
      airticket_ticket_no: '7793045598919',
      passport_no: 'A14358824',
      passport_name: 'SUMAIYA MS AMIN',
      passport_type: 'Adult',
      passport_mobile: '01888798798',
      passport_email: 'RESERVATION.T360@GMAIL.COM',
      airticket_pnr: 'SDOEWK',
      airticket_classes: 'ECONOMY',
      airticket_ticket_type: 'NEW TKT',
      airticket_routes: 'DAC - KUL',
      airticket_journey_date: '2024-07-02T18:00:00.000Z',
      airticket_return_date: '2024-07-02T18:00:00.000Z',
      airticket_gross_fare: '29741.00',
      airticket_extra_fee: null,
      airticket_client_price: '29741.00',
      airticket_is_refund: 0,
      airticket_is_reissued: 0,
      airticket_is_void: 0,
    },
    {
      airticket_id: 14249,
      airticket_ticket_no: '7793045598920',
      passport_no: 'A02544047',
      passport_name: 'MAFRUHA MANJUR MS RASHID',
      passport_type: 'Adult',
      passport_mobile: null,
      passport_email: null,
      airticket_pnr: 'SDOEWK',
      airticket_classes: 'ECONOMY',
      airticket_ticket_type: 'NEW TKT',
      airticket_routes: 'DAC - KUL',
      airticket_journey_date: '2024-07-02T18:00:00.000Z',
      airticket_return_date: '2024-07-02T18:00:00.000Z',
      airticket_gross_fare: '29741.00',
      airticket_extra_fee: null,
      airticket_client_price: '29741.00',
      airticket_is_refund: 0,
      airticket_is_reissued: 0,
      airticket_is_void: 0,
    },
    {
      airticket_id: 14250,
      airticket_ticket_no: '7793045598921',
      passport_no: 'A14358824',
      passport_name: 'SUMAIYA MS AMIN',
      passport_type: 'Adult',
      passport_mobile: '01888798798',
      passport_email: 'RESERVATION.T360@GMAIL.COM',
      airticket_pnr: 'SDOEWK',
      airticket_classes: 'ECONOMY',
      airticket_ticket_type: 'NEW TKT',
      airticket_routes: 'KUL - DAC',
      airticket_journey_date: '2024-07-05T18:00:00.000Z',
      airticket_return_date: '2024-07-05T18:00:00.000Z',
      airticket_gross_fare: '23166.00',
      airticket_extra_fee: null,
      airticket_client_price: '23166.00',
      airticket_is_refund: 0,
      airticket_is_reissued: 0,
      airticket_is_void: 0,
    },
    {
      airticket_id: 14251,
      airticket_ticket_no: '7793045598922',
      passport_no: 'A02544047',
      passport_name: 'MAFRUHA MANJUR MS RASHID',
      passport_type: 'Adult',
      passport_mobile: null,
      passport_email: null,
      airticket_pnr: 'SDOEWK',
      airticket_classes: 'ECONOMY',
      airticket_ticket_type: 'NEW TKT',
      airticket_routes: 'KUL - DAC',
      airticket_journey_date: '2024-07-05T18:00:00.000Z',
      airticket_return_date: '2024-07-05T18:00:00.000Z',
      airticket_gross_fare: '23166.00',
      airticket_extra_fee: null,
      airticket_client_price: '23166.00',
      airticket_is_refund: 0,
      airticket_is_reissued: 0,
      airticket_is_void: 0,
    },
  ],
  authorized_by: {
    sig_signature:
      'https://trabillteststorage.blob.core.windows.net/trabillcontainer/signatures/1717042546200signature.png',
    sig_type: 'AUTHORITY',
    sig_name_title: 'Azmir',
    sig_position: 'SALES',
    sig_phone_no: null,
  },
  prepared_by: {
    sig_signature: 'trabill-file/1719486832658-905391612.png',
    sig_type: 'PREPARE',
    sig_name_title: 'TRABILL DEVELOPER',
    sig_position: 'ENGINEER',
    sig_phone_no: null,
  },
  pax_details: [
    {
      passport_date_of_birth: '1992-08-12 00:00:00.000',
      passport_date_of_expire: '2034-04-27T18:00:00.000Z',
      passport_date_of_issue: null,
      passport_email: 'RESERVATION.T360@GMAIL.COM',
      passport_mobile_no: '01888798798',
      passport_nid_no: null,
      passport_passport_no: 'A14358824',
      passport_name: 'SUMAIYA MS AMIN',
      passport_person_type: 'Adult',
    },
    {
      passport_date_of_birth: '1991-12-10 00:00:00.000',
      passport_date_of_expire: '2031-12-28T18:00:00.000Z',
      passport_date_of_issue: null,
      passport_email: null,
      passport_mobile_no: null,
      passport_nid_no: null,
      passport_passport_no: 'A02544047',
      passport_name: 'MAFRUHA MANJUR MS RASHID',
      passport_person_type: 'Adult',
    },
  ],
  flights: [
    {
      fltdetails_fly_date: '2024-07-02T18:00:00.000Z',
      fltdetails_arrival_time: '16:30:00',
      fltdetails_departure_time: '10:50:00',
      airline_name: 'BS-US-BANGLA AIRLINES LTD-779',
      flight_from: 'Dhaka - Hazrat Shahjalal International Airport',
      flight_to: 'Kuala Lumpur - International Airport',
    },
    {
      fltdetails_fly_date: '2024-07-05T18:00:00.000Z',
      fltdetails_arrival_time: '19:40:00',
      fltdetails_departure_time: '18:00:00',
      airline_name: 'BS-US-BANGLA AIRLINES LTD-779',
      flight_from: 'Kuala Lumpur - International Airport',
      flight_to: 'Dhaka - Hazrat Shahjalal International Airport',
    },
  ],
};
type DataIndex = keyof IFlightData;
// COMMON
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: DataIndex;
  title: any;
  inputType: 'number' | 'text' | 'time' | 'airline';
  record: IFlightData;
  index: number;
}

// MAIN COMPONENT
const FakeInvoice = ({
  viewEditedInvoiceRef,
}: {
  viewEditedInvoiceRef?: React.RefObject<HTMLDivElement>;
}) => {
  const header_ref = React.useRef<HTMLDivElement>(null);
  const [isPrint, setIsprint] = useState(true);
  const [allEditableData, setAllEditableData] = useState<{
    flightDetails?: IFlightData[];
    paxDetails?: IPaxDetails[];
    billingDetails?: IAirticketBillingInfo[];
    subtotalDetails?: any;
  }>({
    flightDetails: [],
    paxDetails: [],
    billingDetails: [],
    subtotalDetails: [],
  });

  const handlePrint = useReactToPrint({
    onBeforePrint() {
      return setIsprint(false);
    },
    content: () => header_ref.current,
    pageStyle: portraitPageStyle,
    removeAfterPrint: false,

    documentTitle: 'xyz',
  });

  const [isShowFlightDetails, setIsShowFlgihtDetails] = useState<boolean>(true);
  const [isShowPaxDetails, setIsShowPaxDetails] = useState<boolean>(true);
  return (
    <div style={{ ...invoiceViewStyle }}>
      <div>
        <Col className='commonBox'>
          <Space>
            <Button
              onClick={handlePrint}
              icon={<PrinterOutlined />}
              type='primary'
            >
              Print
            </Button>
            <Button
              onClick={() => setIsShowFlgihtDetails(!isShowFlightDetails)}
              type='primary'
            >
              Hide Flight Details
            </Button>
            <Button
              onClick={() => setIsShowPaxDetails(!isShowPaxDetails)}
              type='primary'
            >
              Hide Pax Details
            </Button>
          </Space>
        </Col>
        <div style={{ ...a4sizeStyle, marginTop: '10px' }}>
          <ManualInvoiceCommonHeader invoiceData={invoiceData as any} />
          {invoiceData?.flights?.length && isShowFlightDetails && (
            <ManualEditInVoiceFlight
              invoiceFlight={invoiceData?.flights}
              allEditableData={allEditableData}
              setAllEditableData={setAllEditableData}
            />
          )}
          {invoiceData?.pax_details?.length && isShowPaxDetails && (
            <InvoicePaxEdit
              invoicesPax={invoiceData?.pax_details as any}
              allEditableData={allEditableData}
              setAllEditableData={setAllEditableData}
            />
          )}
          <InvoiceBillingEdit
            invoiceBilling={invoiceData?.billing_info as any}
            allEditableData={allEditableData}
            setAllEditableData={setAllEditableData}
          />
          <InvoiceOtherBillingEdit />{' '}
          <InvoiceSubtotalEdit
            invoiceData={invoiceData as any}
            setAllEditableData={setAllEditableData}
            allEditableData={allEditableData}
          />
          <InvoiceFooter
            authorized_by={invoiceData?.authorized_by}
            prepared_by={invoiceData?.prepared_by}
          />
        </div>
      </div>

      <PrintComponent
        authorized_by={invoiceData?.authorized_by}
        prepared_by={invoiceData?.prepared_by}
        allEditableData={allEditableData}
        ref={header_ref}
        invoiceData={invoiceData as any}
      />
    </div>
  );
};

export default FakeInvoice;
