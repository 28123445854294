import { ColumnType } from 'antd/es/table';
import React from 'react';
import { IPartialRefundBodyType } from '../../../RefundTypes/RefundTypes';
import { Fixed2 } from '../../../../../common/utils/common.utils';

export const PartialRefundClientUtils =
  (): ColumnType<IPartialRefundBodyType>[] => {
    return [
      {
        title: 'Ticket No',
        key: 'ticket_no',
        dataIndex: 'ticket_no',
        align: 'center',
      },

      {
        title: 'Payment Method',
        key: 'prfnd_payment_type',
        dataIndex: 'prfnd_payment_type',
        align: 'center',
      },
      {
        title: 'Refund Charge',
        key: 'prfnd_charge_amount',
        dataIndex: 'prfnd_charge_amount',
        render: (prfnd_charge_amount: string) => {
          return <div>{Number(prfnd_charge_amount) || 0}</div>;
        },
        align: 'center',
      },

      {
        title: 'Total Amount',
        key: 'prfnd_total_amount',
        dataIndex: 'prfnd_total_amount',
        render: (curr) => {
          return <div>{Fixed2(curr)}</div>;
        },
        align: 'center',
      },

      {
        title: 'Charge Amount',
        key: 'prfnd_charge_amount',
        dataIndex: 'prfnd_charge_amount',
        render: (curr) => {
          return <div>{Fixed2(curr)}</div>;
        },
        align: 'center',
      },

      {
        title: 'Return Amount',
        key: 'prfnd_return_amount',
        dataIndex: 'prfnd_return_amount',
        render: (prfnd_return_amount: string) => {
          return <div>{Number(prfnd_return_amount) || 0}</div>;
        },
        align: 'center',
      },
    ];
  };
