import { Button, Popconfirm, Space, Typography, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { IPermission } from '../../../../common/types/commonTypes';
import { IAgentMoneyReceiptDataType } from '../types/AgentMoneyReceiptTypes';
import { useDeleteAgentMoneyReceiptMutation } from '../../api/endpoints/moneyReceiptEndpoints';

export const AgentMoneyReceipttils = ({
  permission,
  pagination,
}: {
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<IAgentMoneyReceiptDataType> => {
  const location = useLocation();
  const user = useAppSelector(selectUser);
  const [loadingId, setLoadingId] = useState<number | null>();
  // const user_id = user?.user_id;
  const [deleteMoneyReceipt, { isLoading, isError, isSuccess }] =
    useDeleteAgentMoneyReceiptMutation();
  const handleClientDelete = (id: number) => {
    setLoadingId(id);
    deleteMoneyReceipt({ id, body: { deleted_by: user?.user_id as number } });
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Agent Money Receipt hasbeen deleted');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isError, isSuccess]);
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Payment Date',
      dataIndex: 'receipt_payment_date',
      key: 'receipt_payment_date',
      align: 'center',
      render: (_, record) => {
        return `${dayjs(record.receipt_payment_date).format('DD-MMM-YYYY')}`;
      },
    },

    {
      title: 'Payment To',
      dataIndex: 'receipt_payment_to',
      key: 'receipt_payment_to',
      align: 'center',
    },
    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      align: 'center',
    },
    {
      title: 'Voucher No',
      dataIndex: 'receipt_vouchar_no',
      key: 'receipt_vouchar_no',
      align: 'center',
    },
    {
      title: 'Transaction Invoice',
      dataIndex: 'trxntype_name',
      key: 'trxntype_name',
      align: 'center',
    },
    {
      title: 'Agent Name',
      dataIndex: 'agent_name',
      key: 'agent_name',
      align: 'center',
    },

    {
      title: 'Transaction Type',
      dataIndex: 'acctype_name',
      key: 'acctype_name',
      align: 'center',
    },
    {
      title: 'Received TotalAmount',
      dataIndex: 'receipt_total_amount',
      key: 'receipt_total_amount',
      align: 'center',
      render: (_, record) => Number(record.receipt_total_amount),
    },
    {
      title: 'Action',
      key: 'operation',
      width: 100,
      render: (_, data) => (
        <Space size='small'>
          {permission?.['read:any'] && (
            <Link
              to={`/money-receipt/agents-commission-by-recept-id/${data.receipt_id}`}
              state={location.pathname}
            >
              <Button
                size='small'
                type='primary'
                style={{ backgroundColor: '#00a65a', border: 'none' }}
              >
                <Typography style={{ color: '#ffffff' }}>View</Typography>
              </Button>
            </Link>
          )}

          <Popconfirm
            title='Sure to delete?'
            onConfirm={() => handleClientDelete(data.receipt_id)}
          >
            <Button danger size='small' type='primary'>
              <Typography style={{ color: '#ffffff' }}>Delete </Typography>
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
};
