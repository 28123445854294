import { LogoutOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Typography } from 'antd';
import { useNavigate } from 'react-router';
import { TUser } from '../../auth/states/userSlice';
import { storageBaseUrl } from '../../common/utils/common.utils';
import { ChangePassword } from '../../components/common/FormItem/ChangeUserPassword';
import '../style/profile.css';

export const userContent = (
  isModalOpen: boolean,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  handleLogout: () => void,
  user: TUser,
  handleCancel: () => void
) => {
  const agencyInfo = user?.organization_info;
  const navigate = useNavigate();

  const userContent = (
    <div className='profile'>
      <img
        src={storageBaseUrl + agencyInfo?.org_logo}
        alt={''}
        className='profile-image'
      />
      <div className='profile-info'>
        <h2 className='profile-username'>{user?.user_full_name}</h2>
        <p className='profile-name'>{agencyInfo.org_name}</p>
        <div className='profile-actions'>
          <Button
            style={{ marginTop: 10, overflow: 'hidden', width: '100%' }}
            size='small'
            type='default'
            onClick={() => {
              navigate('/profile-setting');
            }}
          >
            Profile Setting
          </Button>

          <Button
            style={{ marginTop: 10, overflow: 'hidden', width: '100%' }}
            size='small'
            type='default'
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Change Password
          </Button>

          <Popconfirm
            placement='bottom'
            onConfirm={() => handleLogout()}
            title='Sure to logout?'
          >
            <Button
              style={{ marginTop: 10, width: '100%' }}
              size='small'
              type='primary'
              danger
            >
              <Typography>
                <span style={{ color: 'white' }}>
                  <LogoutOutlined /> Sign Out
                </span>
              </Typography>
            </Button>
          </Popconfirm>
        </div>
      </div>

      <Modal
        title='Change password'
        open={isModalOpen}
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={handleCancel}
        footer={null}
      >
        <ChangePassword setIsmodal={setIsModalOpen} />
      </Modal>
    </div>
  );

  return userContent;
};
