import { message } from 'antd';
import { api } from '../../../../app/baseQuery';
import { formatQueryParams } from '../../../../auth/utils/lib';
import {
  IAddClientInList,
  IAllInvoices,
  IViewInvoiceDetails,
  IViewMoneyReceipt,
  postPnrInvoice,
} from '../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { DASHBOARD } from '../../../Dashboard/Api/Constants/Constants';
import { MONEY_RECEIPT_TAG } from '../../../Money_Receipt/api/endpoints/moneyReceiptEndpoints';
import { INVALIDATE_CLIENT_VENDOR_LEDGER } from '../../../Reports/Api/constants';
import {
  AirTicket_InvoicePostType,
  GetAllInvoicesDataType,
  IInvoiceAirTaxRefundPost,
  IInvoiceAirticketTaxRefundInfo,
  ISearchBody,
  ISearchInvoiceData,
  IViewAirticketDetails,
  getDataByPnr,
} from '../../types/invoiceAirTicketTypes';

export const airticketEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // SEARCH INVOICE
    searchInvoice: build.mutation<
      HTTPResponse<ISearchInvoiceData[]>,
      ISearchBody
    >({
      query: (body) => ({
        url: 'invoice-air-ticket/search-invoice-data',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['invoices'],
    }),

    getAllAirticketInvoice: build.query<
      HTTPResponse<GetAllInvoicesDataType[]>,
      void
    >({
      query: () => ({ url: '/invoice-air-ticket/all_by_cate/1' }),
      providesTags: ['AirticketInvoice', 'invoices'],
    }),

    // Get Data By PNR....

    getDataByPnr: build.query<HTTPResponse<getDataByPnr>, string>({
      query: (pnr) => ({
        url: `/invoice-air-ticket/pnr-details/${pnr}`,
      }),
    }),

    // TICKET EXIST OR NOT
    ticketIsExist: build.query<HTTPResponse<boolean>, string>({
      query: (airticket_ticket_no) => ({
        url: `/invoice-air-ticket/is-exist/${airticket_ticket_no}`,
      }),
      providesTags: ['AirticketInvoice', 'invoices'],
    }),

    /**
     *
     * GET VIEW INVOICE DETAILS
     */
    getViewInvoice: build.query<HTTPResponse<IViewInvoiceDetails>, number>({
      query: (id) => ({
        url: `/invoice-air-ticket/invoice/${id}`,
      }),
      providesTags: () => [
        'invoice-hajji',
        'invoices',
        'AirticketInvoice',
        'invoice_single_view',
        'FAKE_INVOICE_TAG',
        'view-invoice',
      ],
    }),
    getViewInvoiceAirticketDetails: build.query<
      HTTPResponse<IViewAirticketDetails>,
      number
    >({
      query: (id) => ({
        url: `/invoice-air-ticket/details/${id}`,
      }),
      providesTags: () => [
        'invoice-hajji',
        'invoices',
        'AirticketInvoice',
        'invoice_single_view',
        'FAKE_INVOICE_TAG',
      ],
    }),

    getSimilarAirticketInvoice: build.query<
      HTTPResponse<AirTicket_InvoicePostType>,
      string | Number | undefined
    >({
      query: (id) => ({
        url: '/invoice-air-ticket/' + id,
      }),
      providesTags: ['AirticketInvoice', 'invoices'],
    }),

    // DELETE
    deleteAirticket: build.mutation<
      HTTPResponse<void>,
      { user_id?: number; id: number }
    >({
      query: ({ id, user_id }) => ({
        url: `/invoice-air-ticket/${id}`,
        method: 'DELETE',
        body: { invoice_has_deleted_by: user_id },
      }),
      invalidatesTags: [
        'invoices',
        'AirticketInvoice',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    /**
     * @COMMON_INVOICE_QUERY
     *
     */

    commonInvoiceNo: build.query<HTTPResponse<{ invoice_no: number }>, number>({
      query: (categoryId) => ({
        url: `/invoice-air-ticket/generate-invoice-no/${categoryId}`,
      }),
      providesTags: () => ['invoices', 'InvoiceHajj'],
    }),

    getAllInvoices: build.query<
      HTTPResponse<IAllInvoices[]>,
      {
        page: number;
        size: number;
        search?: string;
        to_date?: string;
        from_date?: string;
      }
    >({
      query: ({ page, size, search, from_date, to_date }) => {
        const params = formatQueryParams({ search, from_date, to_date });
        return {
          url: `/invoice-air-ticket?page=${page}&size=${size}&${params}`,
        };
      },

      providesTags: () => [
        'invoices',
        'InvoiceHajj',
        'invoices_from_money_receipt',
      ],
    }),

    getIsTicketNumberExist: build.query<HTTPResponse<boolean>, string>({
      query: (ticketNumber) => ({
        url: `/invoice-air-ticket/is-exist/${ticketNumber}`,
      }),
      providesTags: () => ['AirticketInvoice'],
    }),

    postInvoiceAirticket: build.mutation<
      HTTPResponse<{ invoice_id: number }>,
      AirTicket_InvoicePostType
    >({
      query: (body) => ({
        url: '/invoice-air-ticket',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'invoices',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        { type: MONEY_RECEIPT_TAG },
        { type: 'Dashboard', id: DASHBOARD },
      ],
    }),
    editInvoiceAirticket: build.mutation<
      HTTPResponse<void>,
      { body: AirTicket_InvoicePostType; airticketInvoiceId: string }
    >({
      query: ({ body, airticketInvoiceId }) => ({
        url: `/invoice-air-ticket/${airticketInvoiceId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        'AirticketInvoice',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        'invoices',
      ],
    }),

    getInvoiceActivityLog: build.query<
      HTTPResponse<
        {
          user_full_name: string;
          history_activity_type: string;
          history_create_date: string;
          invoice_no: string;
          invoicelog_content: string;
        }[]
      >,
      number
    >({
      query: (id) => ({
        url: `/invoice-air-ticket/view-invoice-activity/${id}`,
      }),
      providesTags: () => ['invoices', 'invoice_single_view'],
    }),

    // Void Invoice AirTicket
    voidInvAirTicket: build.mutation<
      void,
      {
        body: {
          invoice_has_deleted_by: number | undefined;
          void_charge: number;
          id: number;
        };
      }
    >({
      query: ({ body }) => ({
        url: `/invoice-air-ticket/void/${body.id}`,
        method: 'PUT',
        body: body,
      }),

      transformErrorResponse: (response) => {
        if (response.status === 400 && response.data) {
          const { message: err } = response.data as {
            message: string;
            success: boolean;
          };
          message.error(`${err}`);
        } else {
          message.error('Some things went to wrong');
        }
      },
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await queryFulfilled;
        message.success('Void Successfully');
      },

      invalidatesTags: [
        'AirticketInvoice',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        'invoices',
      ],
    }),

    //COMMON DELETE INVOICE
    CommonDeleteInvoice: build.mutation<
      HTTPResponse<void>,
      { user_id?: number; id: number; url?: string }
    >({
      query: ({ id, user_id, url }) => ({
        url: `/${url}/${id}`,
        method: 'DELETE',
        body: { invoice_has_deleted_by: user_id },
      }),
      invalidatesTags: [
        'invoices',
        'AirticketInvoice',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    sendEmail: build.mutation<
      HTTPResponse<{ invoice_id: number }>,
      { email: string; id: number }
    >({
      query: (arg) => ({
        url: `invoice-air-ticket/send-email/${arg.id}`,
        method: 'POST',
        body: { email: arg.email },
      }),
    }),

    getInvoicePaymentDetails: build.query<
      HTTPResponse<IViewMoneyReceipt[]>,
      { invoice_id: string }
    >({
      query: (arg) => ({
        url: `invoice-air-ticket/payment/${arg.invoice_id}`,
      }),
      providesTags: ['AirticketInvoice', 'invoices', { type: 'TaxRefund' }],
    }),
    postPnrInvoice: build.mutation<
      HTTPResponse<{ invoice_id: number }>,
      postPnrInvoice
    >({
      query: (body) => ({
        url: `invoice-air-ticket/pnr`,
        method: 'POST',
        body: body,
      }),

      transformErrorResponse: (response) => {
        if (response.status === 404 && response.data) {
          const { message: err } = response.data as {
            message: string;
            success: boolean;
          };
          message.error(`${err}`);
        } else {
          message.error('No bookings found for this PNR.');
        }
      },
      invalidatesTags: ['invoices'],
    }),

    addClientInList: build.mutation<
      HTTPResponse<{ invoice_id: number }>,
      IAddClientInList
    >({
      query: (body) => ({
        url: `invoice-air-ticket/invoice-client-add`,
        method: 'POST',
        body: body,
      }),

      transformErrorResponse: (response) => {
        if (response.status === 404 && response.data) {
          const { message: err } = response.data as {
            message: string;
            success: boolean;
          };
          message.error(`${err}`);
        } else {
          message.error('Something went wrong');
        }
      },
      invalidatesTags: ['invoices'],
    }),

    postInvoiceAirTicketHistory: build.mutation<
      HTTPResponse<void>,
      {
        invoice_id: number;
        content: string;
      }
    >({
      query: (body) => ({
        url: `/invoice-air-ticket/history-add`,
        method: 'POST',
        body: body,
      }),
      transformErrorResponse: (response) => {
        if (response.status === 404 && response.data) {
          const { message: err } = response.data as {
            message: string;
            success: boolean;
          };
          message.error(`something went wrong`);
        } else {
          message.error(`something went wrong`);
        }
      },
      invalidatesTags: ['invoices'],
    }),
  }),
});

export const {
  useGetAllAirticketInvoiceQuery,
  useLazyTicketIsExistQuery,
  useGetSimilarAirticketInvoiceQuery,
  useLazyGetSimilarAirticketInvoiceQuery,
  useDeleteAirticketMutation,
  useGetAllInvoicesQuery,
  useLazyGetAllInvoicesQuery,
  useCommonInvoiceNoQuery,
  useLazyGetIsTicketNumberExistQuery,
  usePostInvoiceAirticketMutation,
  useGetViewInvoiceQuery,
  useGetViewInvoiceAirticketDetailsQuery,
  useEditInvoiceAirticketMutation,
  useGetInvoiceActivityLogQuery,
  useSearchInvoiceMutation,
  useVoidInvAirTicketMutation,
  useCommonDeleteInvoiceMutation,
  useSendEmailMutation,
  useLazyGetDataByPnrQuery,
  useGetInvoicePaymentDetailsQuery,
  usePostPnrInvoiceMutation,
  useAddClientInListMutation,
  usePostInvoiceAirTicketHistoryMutation,
} = airticketEndpoints;
