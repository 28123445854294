import { FormInstance } from 'antd';
import dayjs from 'dayjs';
import {
  Fixed2,
  getAirportByItaCode,
} from '../../../../common/utils/common.utils';
import { IAirport } from '../../../Configuration/airport/types/AirportTypes';
import {
  AirTicketInvoiceData,
  AirTicket_InvoicePostType,
  AirTicket_ticket_information,
  Airticket_Invoice_Info,
  IAirticketMoneyReceiptType,
} from '../../types/invoiceAirTicketTypes';

type Props = {
  invoices: AirTicketInvoiceData[];
  billing_form: FormInstance<any>;
  user_id: number;
  selectAirport?: IAirport[];
  ticketInfoPrev?: AirTicket_ticket_information[];
  originalForm?: FormInstance<any>;
};

const getSubTotalAirTicket = (allInvoices: AirTicketInvoiceData[]) => {
  let subTotal = 0;
  allInvoices.forEach((el) => {
    subTotal = subTotal + Fixed2(el.airticket_client_price || 0);
  });
  return subTotal;
};

const getTotalVendorPriceInvoice = (
  allNonComInvoices: AirTicketInvoiceData[]
) => {
  let vendorTotal = 0;
  allNonComInvoices.forEach((el) => {
    vendorTotal = vendorTotal + Fixed2(el.airticket_purchase_price);
  });
  return vendorTotal;
};

export function postInvoiceAirticketDataFormat({
  invoices,
  billing_form,
  user_id,
  selectAirport,
  ticketInfoPrev,
  originalForm,
}: Props) {
  const extraData = billing_form.getFieldsValue([
    'invoice_show_unit',
    'invoice_show_discount',
    'invoice_show_prev_due',
    'invoice_service_charge',
    'invoice_discount',
    'invoice_vat',
    'invoice_agent_com_amount',
    'invoice_note',
    'invoice_net_total',
    'passport_mobile_no',

    // MONEY RECEIPT VALUES
    'receipt_payment_type',
    'trans_no',
    'charge_amount',
    'cheque_number',
    'cheque_withdraw_date',
    'cheque_bank_name',
    'account_id',
    'receipt_total_amount',
    'receipt_payment_date',
    'receipt_money_receipt_no',
    'receipt_note',
    'calc_profit',
    'calc_sub_total',
    'invoice_combclient_id',
  ]);

  const originalFormData = originalForm?.getFieldsValue([
    'invoice_combclient_id',
    'invoice_sales_man_id',
    'invoice_agent_id',
    'invoice_due_date',
  ]);

  const lastField = invoices[invoices.length - 1];

  const invoice_info_for_server: Airticket_Invoice_Info = {
    invoice_combclient_id:
      lastField.invoice_combclient_id ||
      originalFormData?.invoice_combclient_id,

    invoice_sales_man_id:
      Fixed2(lastField.invoice_sales_man_id) ||
      originalFormData?.invoice_sales_man_id,

    invoice_no: lastField.invoice_no as string,
    invoice_sales_date: dayjs(lastField?.invoice_sales_date).format(
      'YYYY-MM-DD'
    ),
    invoice_due_date:
      lastField.invoice_due_date || originalFormData?.invoice_due_date,

    invoice_note: extraData.invoice_note || undefined,

    invoice_agent_id:
      lastField?.invoice_agent_id ||
      originalFormData?.invoice_agent_id ||
      undefined,

    invoice_agent_com_amount: Fixed2(extraData?.invoice_agent_com_amount || 0),
    // invoice_sub_total: Fixed2(getSubTotalAirTicket(invoices)),
    invoice_sub_total: Fixed2(extraData.calc_sub_total),

    invoice_service_charge: Fixed2(extraData.invoice_service_charge),

    invoice_net_total: Fixed2(extraData.invoice_net_total),

    invoice_discount: Fixed2(extraData.invoice_discount || 0),

    invoice_vat: Fixed2(extraData.invoice_vat || 0),

    // invoice_total_profit: Fixed2(getSubTotalAirTicket(invoices)),
    invoice_total_profit: Fixed2(extraData.calc_profit),
    invoice_total_vendor_price: Fixed2(getTotalVendorPriceInvoice(invoices)),
    invoice_created_by: user_id,
    invoice_show_passport_details: extraData.invoiceShowPassportDetails || 0,
    invoice_show_prev_due: extraData.invoice_show_prev_due || 0,
    invoice_show_unit: extraData.invoice_show_unit || 0,
    invoice_show_discount: extraData.invoice_show_discount || 0,
  };

  if (lastField?.invoice_walking_customer_name)
    invoice_info_for_server.invoice_walking_customer_name =
      lastField?.invoice_walking_customer_name;

  //@ts-ignore
  const ticketInfo: AirTicket_ticket_information[] = invoices?.map((el, i) => {
    let total_taxes_commission = 0;
    if (el?.taxes_commission?.length) {
      el.taxes_commission.map((tax) => {
        total_taxes_commission += Number(tax.airline_commission);
      });
    }

    return {
      pax_passports: el.pax_passport,

      ticket_details: {
        airticket_id: ticketInfoPrev
          ? ticketInfoPrev[i]?.ticket_details?.airticket_id
          : undefined,
        is_deleted: el.is_deleted,
        airticket_ticket_no: el.airticket_ticket_no,
        airticket_gross_fare: Fixed2(el.airticket_gross_fare),
        airticket_base_fare: Fixed2(el.airticket_base_fare),
        airticket_comvendor: el.airticket_comvendor,
        airticket_airline_id: el.airticket_airline_id,
        airticket_commission_percent: el.airticket_commission_percent || 0,
        airticket_ait: Fixed2(el.airticket_ait),
        airticket_ait_from: el.airticket_ait_from,
        airticket_tax: Fixed2(el.airticket_tax) || undefined,

        airticket_commission_percent_total: Fixed2(
          el.airticket_commission_percent_total
        ),
        airticket_discount_type: el.airticket_discount_type
          ? el.airticket_discount_type
          : undefined,
        airticket_discount_total: Fixed2(el.airticket_discount_total || 0),
        airticket_extra_fee: Fixed2(el.airticket_extra_fee || 0),
        airticket_other_bonus_total: Fixed2(
          el.airticket_other_bonus_total || 0
        ),
        airticket_other_bonus_type: el.airticket_other_bonus_type,
        airticket_other_expense: Fixed2(el.airticket_other_expense || 0),
        airticket_vat: Fixed2(el.airticket_vat || 0),

        //     //   readonly values
        airticket_client_price: Fixed2(el.airticket_client_price || 0),
        airticket_purchase_price: Fixed2(el.airticket_purchase_price || 0),
        airticket_profit: Fixed2(el.airticket_profit || 0),
        //     //   end readonly values

        airticket_journey_date: el.airticket_journey_date
          ? dayjs(el.airticket_journey_date).format('YYYY-MM-DD')
          : undefined,
        airticket_return_date: el.airticket_return_date
          ? dayjs(el.airticket_return_date).format('YYYY-MM-DD')
          : undefined,
        airticket_gds_id: el.airticket_gds_id ? el.airticket_gds_id : undefined,
        airticket_classes: el.airticket_classes
          ? el.airticket_classes
          : undefined,

        airticket_ticket_type: el.airticket_ticket_type
          ? el.airticket_ticket_type
          : undefined,

        airticket_issue_date: el.airticket_issue_date
          ? dayjs(el.airticket_issue_date).format('YYYY-MM-DD')
          : undefined,
        airticket_segment: el?.airticket_segment
          ? el.airticket_segment
          : undefined,
        airticket_net_commssion: el?.airticket_net_commssion,

        airticket_route_or_sector: el?.airticket_route_or_sector
          ? (getAirportByItaCode(
              el?.airticket_route_or_sector as string,
              selectAirport
            ) as string[])
          : undefined,
        airticket_pnr: el?.airticket_pnr || undefined,

        airticket_bd_charge: Fixed2(el?.airticket_bd_charge),
        airticket_es_charge: Fixed2(el?.airticket_es_charge),
        airticket_ut_charge: Fixed2(el?.airticket_ut_charge),
        airticket_xt_charge: Fixed2(el?.airticket_xt_charge),

        airticket_e5_charge: Fixed2(el.airticket_e5_charge),
        airticket_g4_charge: Fixed2(el.airticket_g4_charge),
        airticket_ow_charge: Fixed2(el.airticket_ow_charge),
        airticket_p7_charge: Fixed2(el.airticket_p7_charge),
        airticket_p8_charge: Fixed2(el.airticket_p8_charge),
        airticket_pz_charge: Fixed2(el.airticket_pz_charge),
        airticket_qa_charge: Fixed2(el.airticket_qa_charge),
        airticket_r9_charge: Fixed2(el.airticket_r9_charge),
      },
      flight_details: el.flight_details
        ? el.flight_details.map((element, j) => {
            const temp: any = ticketInfoPrev;
            const flt_id =
              ticketInfoPrev?.length &&
              ticketInfoPrev[i]?.flight_details?.length
                ? temp[i]?.flight_details[j]?.fltdetails_id
                : undefined;

            return {
              is_deleted: element.is_deleted || undefined,
              fltdetails_id: flt_id,

              fltdetails_from_airport_id: element.fltdetails_from_airport_id
                ? element.fltdetails_from_airport_id
                : undefined,
              fltdetails_to_airport_id: element.fltdetails_to_airport_id
                ? element.fltdetails_to_airport_id
                : undefined,
              fltdetails_airline_id: element.fltdetails_airline_id
                ? Number(element.fltdetails_airline_id)
                : undefined,
              fltdetails_flight_no: element.fltdetails_flight_no
                ? element.fltdetails_flight_no
                : undefined,
              fltdetails_fly_date: element.fltdetails_fly_date
                ? dayjs(element.fltdetails_fly_date).format('YYYY-MM-DD')
                : undefined,
              fltdetails_departure_time: element.fltdetails_departure_time
                ? dayjs(element.fltdetails_departure_time, 'HH:mm:ss').format(
                    'HH:mm:ss'
                  )
                : undefined,
              fltdetails_arrival_time: element?.fltdetails_arrival_time
                ? dayjs(element.fltdetails_arrival_time, 'HH:mm:ss').format(
                    'HH:mm:ss'
                  )
                : undefined,
            };
          })
        : undefined,
      total_taxes_commission: total_taxes_commission,
      taxes_commission: el.taxes_commission,
    };
  });

  const money_receipt: IAirticketMoneyReceiptType = {
    receipt_total_amount: extraData.receipt_total_amount || undefined,
    receipt_payment_type: extraData.receipt_payment_type || undefined,
    account_id: extraData.account_id || undefined,
    charge_amount: extraData.charge_amount || undefined,
    cheque_bank_name: extraData.cheque_bank_name || undefined,
    cheque_number: extraData.cheque_number || undefined,
    cheque_withdraw_date: extraData.cheque_withdraw_date
      ? dayjs(extraData.cheque_withdraw_date).format('YYYY-MM-DD')
      : undefined,
    receipt_money_receipt_no: extraData.receipt_money_receipt_no || undefined,
    receipt_note: extraData.receipt_note || undefined,
    trans_no: extraData.trans_no || undefined,
    receipt_payment_date: extraData.receipt_payment_date
      ? dayjs(extraData.receipt_payment_date).format('YYYY-MM-DD')
      : undefined,
  };

  const tempData: AirTicket_InvoicePostType = {
    invoice_info: invoice_info_for_server,
    ticketInfo: ticketInfo,
    money_receipt: money_receipt,
  };

  return tempData;
}
