import { Spin, Table, TableColumnsType, Tooltip } from 'antd';
import { useEffect } from 'react';
import { useLazyGetAirTicketRefundRowDetailsQuery } from '../../Api/Endpoints/AirTicketRefundEndpoints';
import { IAirticketRowDetails } from '../../RefundTypes/RefundTypes';
import { Fixed2 } from '../../../../common/utils/common.utils';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

type Props = {
  id: number;
};

const AirticketRowDetails = ({ id }: Props) => {
  const columns: TableColumnsType<IAirticketRowDetails> = [
    {
      title: 'Vendor Name',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      render: (text) => <Tooltip title='Name of the vendor'>{text}</Tooltip>,
    },
    {
      title: 'Ticket No',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
      render: (text) => <Tooltip title='Ticket number'>{text}</Tooltip>,
    },

    {
      title: 'AIT',
      dataIndex: 'airticket_ait',
      key: 'airticket_ait',
      render: (text) => <Tooltip title='AIT amount'>{Fixed2(text)}</Tooltip>,
    },

    {
      title: 'Tax',
      dataIndex: 'airticket_tax',
      key: 'airticket_tax',
      render: (text) => (
        <Tooltip title='Air-ticket tax'>{Fixed2(text)}</Tooltip>
      ),
    },
    {
      title: 'Total Amount',
      dataIndex: 'vrefund_total_amount',
      key: 'vrefund_total_amount',
      render: (text) => <Tooltip title='Total amount'>{Fixed2(text)}</Tooltip>,
    },
    {
      title: 'Charge Amount',
      dataIndex: 'vrefund_charge_amount',
      key: 'vrefund_charge_amount',
      render: (text) => <Tooltip title='Charge amount'>{Fixed2(text)}</Tooltip>,
    },
    {
      title: 'Return Amount',
      dataIndex: 'vrefund_return_amount',
      key: 'vrefund_return_amount',
      render: (text) => <Tooltip title='Return amount'>{Fixed2(text)}</Tooltip>,
    },

    {
      title: 'Purchase Price',
      dataIndex: 'airticket_purchase_price',
      key: 'airticket_purchase_price',
      render: (text) => (
        <Tooltip title='Air-ticket purchase price'>{Fixed2(text)}</Tooltip>
      ),
    },

    {
      title: 'Client Price',
      dataIndex: 'airticket_client_price',
      key: 'airticket_client_price',
      render: (text) => <Tooltip title='Client price'>{Fixed2(text)}</Tooltip>,
    },
    {
      title: 'Profit',
      dataIndex: 'airticket_profit',
      key: 'airticket_profit',
      render: (text) => <Tooltip title='Profit'>{Fixed2(text)}</Tooltip>,
    },
  ];

  const [getRowDetails, { data, isLoading }] =
    useLazyGetAirTicketRefundRowDetailsQuery();

  useEffect(() => {
    if (id) {
      getRowDetails(id, true);
    }
  }, [id]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Table
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        size='small'
        dataSource={data?.data}
        columns={columns}
        loading={isLoading}
        rowKey='vrefund_id'
        pagination={false}
      />
    </>
  );
};

export default AirticketRowDetails;
