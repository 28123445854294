import { api } from '../../../app/baseQuery';
import { HTTPResponse } from '../../../common/types/commonTypes';

export const not_visible_api = api.injectEndpoints({
  endpoints: (build) => ({
    notClientLastBal: build.query<
      HTTPResponse<ClLastBalType[]>,
      { page: number; size: number }
    >({
      query: ({ page, size }) => {
        return {
          url: `/report/cl-last-balance?page=${page}&size=${size}`,
        };
      },
      providesTags: [''],
    }),
  }),
});

export const { useNotClientLastBalQuery } = not_visible_api;

export interface ClLastBalType {
  client_id: number;
  client_org_agency: number;
  client_entry_id: string;
  client_name: string;
  client_lbalance: string;
  ctrxn_lbalance: string;
}
