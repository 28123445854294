import React, { useEffect } from 'react';
import { Col, Form, Select } from 'antd';
import { commonProps } from '../../../common/types/commonTypes';
import { useLazyGetAllInVoiceByClientIDQuery } from '../Api/Endpoints/QuotationEndpoints';
import { AllInvoiceType } from '../QuotationTypes/QuotationTypes';

type PropsType = {
  clientID: string;
  setOthersRefund?: (arg: string) => void;
  setClLastBalance?: (arg: number) => void;
  setClientName?: (arg: string) => void;
  onChange?: React.Dispatch<React.SetStateAction<number | string>>;
  setSelectINV?: React.Dispatch<React.SetStateAction<any | undefined>>;
  setSpecificInvoice: React.Dispatch<React.SetStateAction<AllInvoiceType[]>>;
} & commonProps;

export const SelectAllInvoices = ({
  name,
  label,
  size,
  required,
  disabled,
  onChange,
  clientID,
  setSelectINV,
  setSpecificInvoice,
}: PropsType) => {
  // ================= allInvoice children
  const [fetchAllInvoice, { data: allInvoice }] =
    useLazyGetAllInVoiceByClientIDQuery();

  useEffect(() => {
    clientID && fetchAllInvoice(clientID);
  }, [clientID]);
  useEffect(() => {
    setSpecificInvoice &&
      setSpecificInvoice(allInvoice?.data as AllInvoiceType[]);
  }, [allInvoice?.data]);

  const selectAirTicketInvoice = allInvoice?.data;

  const uniqueInvoices: any = [];
  const seenIds = new Set();

  selectAirTicketInvoice?.forEach((item) => {
    const invoiceId = item.invoice_id;
    if (!seenIds.has(invoiceId)) {
      uniqueInvoices.push(item);
      seenIds.add(invoiceId);
    }
  });

  const airTicketInvoice: React.ReactNode[] = [];

  if (uniqueInvoices) {
    for (let i = 0; i < uniqueInvoices.length; i++) {
      airTicketInvoice.push(
        <Select.Option
          key={uniqueInvoices[i].invoice_id}
          value={uniqueInvoices[i].invoice_id}
        >
          {`${uniqueInvoices[i].invoice_no}`}
        </Select.Option>
      );
    }
  }

  const handleSelect = (value: any) => {
    const invoiceData = selectAirTicketInvoice?.find(
      (item) => item.invoice_id === value
    );
    setSelectINV && setSelectINV(invoiceData);
  };
  return (
    <Col span={6} xs={24} sm={24} md={24} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required,
            message: 'Air Ticket Invoice is required',
          },
        ]}
      >
        <Select
          mode='multiple'
          allowClear
          onSelect={handleSelect}
          disabled={disabled}
          placeholder={'Select Invoice'}
          showSearch
          onChange={onChange}
          style={{ padding: '0', margin: '0', border: '0' }}
          optionFilterProp='roleMobile'
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {airTicketInvoice}
        </Select>
      </Form.Item>
    </Col>
  );
};

export default SelectAllInvoices;
