import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Form, Row, Space } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { selectUser } from '../../../auth/states/userSlice';
import { storageBaseUrl } from '../../../common/utils/common.utils';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import { SelectClients } from '../../../components/common/FormItem/SelectCustomFeilds';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import {
  useEditPassportMutation,
  useGetSinglePassportQuery,
} from '../Api/Endpoints/PassportEndpoints';
import EditPassportInfo from '../Components/EditPassportInfo';
import { IPassportEditFormData } from '../PassportTypes/PassportTypes';

type Props = {};

const EditPassport = (props: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: singlePassport, isLoading: singleLoading } =
    useGetSinglePassportQuery(Number(id));

  const [editPassport, { isError, isLoading }] = useEditPassportMutation();

  useEffect(() => {
    form.setFieldsValue({
      client_id: singlePassport?.data?.client_id,
      passport_no: singlePassport?.data?.passport_no,
      name: singlePassport?.data?.name,
      mobile_no: singlePassport?.data?.mobile_no
        ? singlePassport?.data?.mobile_no
        : undefined,

      passport_person_type: singlePassport?.data?.passport_person_type,

      email: singlePassport?.data?.email,
      nid: singlePassport?.data?.nid,
      date_of_birth:
        singlePassport?.data?.date_of_birth &&
        dayjs(singlePassport?.data?.date_of_birth),
      date_of_issue:
        singlePassport?.data?.date_of_issue &&
        dayjs(singlePassport?.data?.date_of_issue),
      date_of_expire:
        singlePassport?.data?.date_of_expire &&
        dayjs(singlePassport?.data?.date_of_expire),
    });
  }, [singlePassport]);

  const user = useSelector(selectUser);

  const onFinish = async (values: IPassportEditFormData) => {
    const body: IPassportEditFormData = {
      ...values,
      mobile_no: values.mobile_no,
    };
    const formData = new FormData();

    formData.append('passport_no', body.passport_no.toString());
    formData.append('name', body.name.toString());
    formData.append('mobile_no', body.mobile_no.toString());
    formData.append('passport_person_type', body.passport_person_type);
    body.email && formData.append('email', body.email.toString());
    body.nid && formData.append('nid', body.nid.toString());

    body.date_of_birth &&
      formData.append(
        'date_of_birth',
        body.date_of_birth.format('YYYY-MM-DD').toString()
      );
    body.date_of_issue &&
      formData.append(
        'date_of_issue',
        body.date_of_issue.format('YYYY-MM-DD').toString()
      );
    body.date_of_expire &&
      formData.append(
        'date_of_expire',
        body.date_of_expire.format('YYYY-MM-DD').toString()
      );

    formData.append('passport_created_by', user?.user_id + '');

    if (body.scan_copy) {
      formData.append(`passport_scan_copy`, body?.scan_copy[0]?.originFileObj);
    }

    if (body.upload_photo) {
      formData.append(
        `passport_upload_photo`,
        body?.upload_photo[0]?.originFileObj
      );
    }

    if (body.upload_others) {
      formData.append(
        `passport_upload_others`,
        body?.upload_others[0]?.originFileObj
      );
    }

    await editPassport({ id: Number(id), formData });
    navigate('/passport');
  };
  return (
    <div>
      <BreadCrumb arrOfOption={['Edit Passport']} />
      <Space>
        <Link to='/passport'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Passport List
          </Button>
        </Link>
      </Space>
      <Form
        encType='multipart/form-data'
        layout='vertical'
        form={form}
        onFinish={onFinish}
      >
        {singleLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Card style={{ marginTop: '1rem' }}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                <SelectClients
                  name='client_id'
                  label='Select Client'
                  disabled
                />
              </Row>
            </Card>

            <Card title='Passport Information' style={{ marginTop: '1rem' }}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                <EditPassportInfo
                  scanCopy={
                    singlePassport?.data?.passport_scan_copy
                      ? storageBaseUrl +
                        singlePassport?.data?.passport_scan_copy
                      : undefined
                  }
                  uploadPhoto={
                    singlePassport?.data?.passport_upload_photo
                      ? storageBaseUrl +
                        singlePassport?.data?.passport_upload_photo
                      : undefined
                  }
                  uploadOthers={
                    singlePassport?.data?.passport_upload_others
                      ? storageBaseUrl +
                        singlePassport?.data?.passport_upload_others
                      : undefined
                  }
                />
              </Row>
            </Card>

            <FormButton
              style={{ marginTop: '1rem' }}
              label='Edit Passport'
              loading={isLoading}
            />
          </>
        )}
      </Form>
    </div>
  );
};

export default EditPassport;
