import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import {
  IClientAirTicketRefundType,
  IClientOtherRefundType,
} from '../../types/clientInterfaces';

export const clientDetailsAirTicketRefundUtils =
  (): ColumnsType<IClientAirTicketRefundType> => {
    return [
      {
        title: 'SL.',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: 'Return Type',
        dataIndex: 'crefund_payment_type',
        key: 'crefund_payment_type',
      },
      {
        title: 'Invoice ID',
        dataIndex: 'invoice_no',
        key: 'invoice_no',
      },

      {
        title: 'Amount',
        dataIndex: 'crefund_return_amount',
        key: 'crefund_return_amount',
        align: 'center',
        render: (_, record) => Number(record.crefund_return_amount),
      },
      {
        title: 'Date',
        dataIndex: 'atrefund_date',
        key: 'atrefund_date',
        render: (_, record) => {
          return `${dayjs(record.atrefund_date).format('DD MMM YYYY')}`;
        },
      },
    ];
  };
export const clientDetailsOtherRefundUtils =
  (): ColumnsType<IClientOtherRefundType> => {
    return [
      {
        title: 'SL.',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: 'Voucher No',
        dataIndex: 'refund_vouchar_number',
        key: 'refund_vouchar_number',
      },

      {
        title: 'Amount',
        dataIndex: 'refund_net_total',
        key: 'refund_net_total',
      },
      {
        title: 'Payment Method',
        dataIndex: 'refund_payment_type',
        key: 'refund_payment_type',
      },
    ];
  };
