import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  MutationDefinition,
} from '@reduxjs/toolkit/query';
import { Button, Col, Popconfirm, Row, TableProps } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { HTTPResponse } from '../../../../../common/types/commonTypes';
import CheckImageOrPdf from '../../../../../common/utils/CheckImageOrPdf';
import { IGetAllSignature } from '../../type/appConfig_Type';

type Props = {
  pagination: {
    current: number;
    pageSize: number;
  };
  setEditInfo: React.Dispatch<
    React.SetStateAction<IGetAllSignature | undefined>
  >;
  statusLoading: boolean;
  changeStatus: MutationTrigger<
    MutationDefinition<
      {
        id: number;
        status: 'ACTIVE' | 'INACTIVE';
      },
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
      string,
      HTTPResponse<IGetAllSignature[]>,
      'AccountsApi'
    >
  >;
};

const SignatureColumns = ({
  changeStatus,
  pagination,
  setEditInfo,
  statusLoading,
}: Props): TableProps<IGetAllSignature>['columns'] => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
        1 +
        index,
    },

    {
      title: 'Date',
      dataIndex: 'created_date',
      key: 'created_date',
      render: (_, record) => {
        return `${dayjs(record.created_date).format('DD MMM YYYY')}`;
      },
    },

    {
      title: 'Employee Name',
      dataIndex: 'employee_full_name',
      key: 'employee_full_name',
    },
    {
      title: 'Sign Title',
      dataIndex: 'sig_name_title',
      key: 'sig_name_title',
    },

    {
      title: 'Type',
      dataIndex: 'sig_type',
      key: 'sig_type',
    },

    {
      title: 'Position',
      dataIndex: 'sig_position',
      key: 'sig_position',
    },

    {
      title: 'Phone Number',
      dataIndex: 'sig_phone_no',
      key: 'sig_phone_no',
    },

    {
      title: 'Status',
      dataIndex: 'sig_status',
      key: 'sig_status',
      width: 100,
    },

    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
    },

    {
      title: 'Signature',
      dataIndex: 'sig_signature',
      key: 'sig_signature',
      align: 'center',
      render: (curr) => {
        return (
          <div>
            <CheckImageOrPdf url={curr} width={50} />
          </div>
        );
      },
    },

    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        const { sig_status, sig_id } = record;

        return (
          <Row gutter={10}>
            <Col>
              <Button
                onClick={() => setEditInfo(record)}
                type='primary'
                size='small'
              >
                Edit
              </Button>
            </Col>
            <Col>
              <Popconfirm
                title='Disable'
                description='Are you sure you want to disable ?'
                okButtonProps={{ loading: statusLoading }}
                onConfirm={() =>
                  changeStatus({
                    id: sig_id,
                    status: sig_status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
                  })
                }
              >
                <Button
                  type='primary'
                  size='small'
                  style={{
                    background: sig_status === 'ACTIVE' ? 'red' : 'green',
                  }}
                >
                  {sig_status === 'ACTIVE' ? 'Disable' : 'Enable'}
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];
};

export default SignatureColumns;
