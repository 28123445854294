import React from 'react';

export interface IAirTicketByID {
  airticket_id: number;
  airticket_ticket_no: string;
  invoice_id: number;
  invoice_no: string;
  ticket_no: string;
  invoice_category_id: number;
}

export interface IAirTicketInfoByNo {
  key?: React.Key;
  passport_name?: string;
  airticket_ticket_no: string;
  airticket_vendor_id: number;
  airticket_vendor_combine_id: number;
  vendor_name: string;
  combine_name: string;
  airticket_airline_id: number;
  airline_name: string;
  airticket_client_price: string;
  airticket_discount_total: string;
  airticket_purchase_price: string;
  airticket_profit: string;
  airticket_pnr?: string | number;
  client_charge: number;
  vrefund_charge_amount: number;
  adjust_client_discount: number;
  last_balance: string;
  vendor_last_balance: string;
  combined_last_balance: string;
  date: string;
}
export interface IAirTicketClientRefundCharge
  extends Omit<
    IAirTicketInfoByNo,
    | 'passport_name'
    | 'airticket_vendor_id'
    | 'vendor_name'
    | 'airticket_airline_id'
    | 'airline_name'
    | 'airticket_discount_total'
    | 'airticket_purchase_price'
    | 'airticket_profit'
    | 'airticket_pnr'
  > {
  client_charge: number;
}

export interface IAirTicketform_1 {
  ticket_no: string[];
  date: string;
}

export let refund_type = ['Money Return', 'Balance Adjustment'];

export interface IAirTicketGetRefundForm {
  client_id: number;
  created_by: number;
  date: string;
  vouchar_no: string;
  client_refund_info: IAirTicketClient_refund_info[];
  vendor_refund_info: IAirTicketVendor_refund_info[];
}
export interface IAirTicketRefundForm {
  client_id?: any;
  comb_client: string;
  invoice_id: number | null;
  created_by: number;
  date: string;
  vouchar_no: string;
  client_refund_info: IAirTicketClient_refund_info;
  vendor_refund_info: IAirTicketVendor_refund_info[];
  adjust_discount: number;
}

export interface IAirTicketClient_refund_info {
  crefund_adjust: number;
  crefund_charge_amount: number;
  crefund_date: string;
  crefund_payment_type: 'ADJUST' | 'MONEY_RETURN';
  crefund_return_amount: number;
  crefund_total_amount: number;
  vrefund_charge_amount: number;
  payment_type?: 'CASH' | 'CHEQUE';
  type_id?: number;
  account_id?: number;
  cheque_no?: string;
  bank_name?: string;
  withdraw_date?: string;
  crefund_note?: string;
  airticketClientInitialValues?: Object;
  advance_amount?: number;
}

export interface IAirTicketVendor_refund_info {
  vrefund_adjust: number;
  vrefund_date: string;
  vrefund_payment_type: 'ADJUST' | 'MONEY_RETURN';
  airticket_vendor_id?: string;
  airticket_vendor_combine_id?: string;
  airticket_ticket_no: string;
  payment_type?: 'CASH' | 'CHEQUE';
  type_id?: number;
  account_id?: number;
  cheque_no?: string;
  bank_name?: string;
  withdraw_date?: string;
  vrefund_return_amount: number;
  vrefund_total_amount: number;
  vrefund_charge_amount: number;
  vrefund_note?: string;
  client_charge: number;
  passport_name?: string;
  airticket_id?: number;
  airticket_invoice_id?: number;
  invoice_category_id: number;
  //to remove fields
  advance_amount?: number;
  airline_name?: string;
  airticket_airline_id?: number;
  airticket_client_price?: string;
  airticket_discount_total?: string;
  airticket_profit?: string;
  key?: number;
  last_balance?: string;
  return_amount?: number;
  vendor_last_balance?: string;
  vendor_name?: string;
  airticket_purchase_price?: string;
  airticket_pnr?: string;
  airticket_combvendor: any;
}

export interface IAirTicketRefundDataType {
  atrefund_id: number;
  atrefund_vouchar_number: string;
  atrefund_client_id: number;
  atrefund_combined_id: string;
  atrefund_date: string;
  atrefund_note: string;
  atrefund_trxn_charge: string;
  atrefund_create_date: string;
  account_name: string;
  crefund_total_amount: string;
  crefund_charge_amount: string;
  crefund_return_amount: string;
  crefund_profit: string;
  crefund_condition_type: string;
  crefund_payment_type: string;
  crefund_moneyreturn_type: string;
  invoice_id: number;
  invoice_no: string;
  invoice_category_id: number;
  client_name: string;
  is_adjust_rf: 0 | 1;
}

export interface IAirticketRowDetails {
  vrefund_id: number;
  vrefund_refund_id: number;
  vrefund_total_amount: string;
  vrefund_charge_amount: string;
  vrefund_return_amount: string;
  vrefund_condition_type: null;
  vrefund_payment_type: string;
  vrefund_moneyreturn_type: null;
  vendor_name: string;
  combine_name: null;
  account_name: null;
  airticket_ticket_no: string;
  airticket_ait: number;
  airticket_tax: number;
  airticket_client_price: string;
  airticket_discount_total: string;
  airticket_extra_fee: string;
  airticket_purchase_price: string;
  airticket_profit: string;
  airticket_pnr: null;
}

export interface IAirTicketRefundNDataType {
  key?: React.Key;
  atrefund_id: number;
  atrefund_vouchar_number: string;
  client_name: string;
  crefund_total_amount: string;
  crefund_charge_amount: string;
  crefund_return_amount: string;
  crefund_profit: string;
  crefund_payment_type: string;
  atrefund_date: string;
  atrefund_create_date: string;
  vendor_refund_info: [
    {
      comb_vendor: string;
      vrefund_total_amount: string;
      vrefund_charge_amount: string;
      vrefund_return_amount: string;
      vrefund_payment_type: string;
      vrefund_moneyreturn_type: number;
      vrefund_account_id: number;
      vrefund_airticket_id: number;
      vrefund_category_id: number;
      vrefund_vtrxn_id: number;
      vrefund_charge_vtrxn_id: number;
      vrefund_acctrxn_id: number;
    }
  ];
}

export interface IRefundDeleteRestoreBody {
  refund_id: number;
  deleted_by: number;
}
export interface IRefundRestoreBody {
  refund_id: number;
  refund_restored_by: number;
}

export interface IAirtTicketRefundSingleDataType {
  key?: React.Key;
  atrefund_id: number;
  atrefund_is_deleted: number;
  atrefund_vouchar_number: string;
  client_name: string;
  passport_name: string;
  airticket_pnr: string;
  rfitem_ticket_no: string;
  rfitem_vendor_id: string;
  refund_info: IAirTicketRefundVendorDetails[];
  atrefund_date: string;
  client_mobile: string;
  crefund_return_amount: string;
  crefund_charge_amount: string;
  crefund_total_amount: string;
}

export interface IAirtTicketRefundSingleViewDataType {
  key?: React.Key;
  atrefund_id: number;
  invoice_no: string;
  passport_name: string;
  airticket_pnr: string;
  airticket_ticket_no: string;
  airticket_routes: string[];
  atrefund_vouchar_number: string;
  client_name: string;
  crefund_total_amount: string;
  crefund_charge_amount: string;
  crefund_return_amount: string;
  crefund_profit: string;
  crefund_payment_type: string;
  atrefund_date: string;
  atrefund_create_date: string;
  dataSource: any;
  refund_info: IAirTicketRefundVendorDetails[];
}

export interface IAirticketLogsType {
  atrfndlog_content: string;
  atrfndlog_status: 'CREATED' | 'UPDATED' | 'DELETED' | 'RESTORED';
  atrfndlog_date: string;
  created_by: number;
}

export interface IAirTicketRefundVendorDetails {
  key?: React.Key;
  rfitem_ticket_no: string;
  rfitem_vendor_id: string;
  vendor_name: string;
  vendor_mobile: string;
  rfitem_ticket_vendor_refund_charge: string;
  rfitem_ticket_client_refund_charge: string;
  atrefticket_pnr: string;
  atrefticket_pax_name: string;
  atrefticket_atrefund_id: number;
  crefund_total_amount: string;
  vrefund_total_amount: string;
  vrefund_charge_amount: string;
  atrefticket_vendor_return: string;
  airticket_gross_fare: string;
  crefund_return_amount?: number;
}

export interface IOthersRefundInvoicebyID {
  client_name: string;
  lbalance_amount: string;
  invoice_id: number;
  invoice_no: string;
}

export interface IOthersRefundBillingInfo {
  key?: React.Key;
  billing_id: number;
  billing_product_id: number;
  product_name: string;
  billing_quantity: number;
  billing_remaining_quantity: number;
  billing_unit_price: number;
  billing_cost_price: number;
  // billing_subtotal: number;
  billing_vendor_id: number;
  vendor_type: string;
  billing_vendor_name: string;
  billing_vendor_last_balance: string;
  billing_created_date: string;
  billing_invoice_id: number;
}

export interface IOthersRefundInfo extends IOthersRefundBillingInfo {
  refund_quantity: number;
  client_refund_charge: number;
  vendor_refund_charge: number;
  vendor_refund_amount: number;
  client_refund_amount: number;
  vrefund_amountvendor_refund_amount: number;
  client_refund_price: number;
  vendor_refund_price: number;
  client_total_price: number;
  vendor_total_price: number;
}

export interface IOthersClientFormRefundInfo {
  client_id: string;
  invoice_id: number;
  // refund_info: IRefundInfoReqBody[];
  total_refund_amount: number;
  total_refund_charge: number;
  total_return_amount?: number;
  crefund_payment_type: 'MONEY_RETURN' | 'ADJUST';
  money_return_type?: 'CASH' | 'CHEQUE';
  account_id?: number;
  cheque_no?: string;
  bank_name?: string;
  crefund_date: string;
  withdraw_date?: string;
  return_amount: any;
}
export interface IOthersVendorFormRefundInfo {
  billing_vendor_id: string;
  billing_id?: number;
  billing_quantity?: number;
  billing_remaining_quantity: number;
  billing_product_id?: number;
  refund_quantity?: number;
  refund_charge: number;
  refund_amount: number;
  return_amount?: number;
  invoice_category_id: number;
  vrefund_payment_type: 'MONEY_RETURN' | 'ADJUST';
  money_return_type?: 'CASH' | 'CHEQUE';
  type_id?: number;
  account_id?: number;
  cheque_no?: string;
  bank_name?: string;
  date?: string;
  withdraw_date?: string;
  //unnecessary fields
  key?: React.Key;
  vendor_refund_amount?: number;
  vendor_type?: string;
  vendor_refund_charge?: number;
  vendor_refund_price?: number;
  vendor_total_price?: number;
  product_name?: string;
  client_refund_amount?: number;
  client_refund_charge?: number;
  client_refund_price?: number;
  client_total_price?: number;
  billing_vendor_last_balance?: string;
  billing_vendor_name?: string;
  billing_unit_price?: number;
  billing_subtotal?: number;
  billing_cost_price?: number;
  billing_invoice_id?: number;
  billing_create_date?: string;
  net_total?: number;
}

export interface IOthersRefundForm {
  client_id: string;
  invoice_id: number;
  comb_client: string;
  voucher_no: string;
  vendor_refund_info: IOthersVendorFormRefundInfo[];
  client_refund_info: IOthersClientFormRefundInfo;
  created_by: number;
  date: string;
}

export interface IOthersRefundClient_refund_info {
  product_id: number;
  refund_quantity: number;
  remaining_quantity: number;
  refund_charge: number;
  refund_amount: number;
  refund_price: number;
  date: string;
}

export interface IOthersRefundForm1 {
  client_id: number;
  others_invoice_id: number;
  date: string;
}

export interface IOthersRefundClientDataType {
  key?: React.Key;
  crefund_charge_amount: string;
  crefund_client_id: number;
  crefund_client_name: string;
  crefund_client_type: string;
  crefund_date: string;
  crefund_id: number;
  crefund_invoice_id: number;
  crefund_is_deleted: number;
  crefund_moneyreturn_type: string;
  crefund_payment_type: string;
  crefund_refund_id: number;
  refund_id: number;
  crefund_return_amount: string;
  crefund_total_amount: string;
  crefund_vouchar_number: string;
  invoice_no: string;
  vendor_info: IOthersRefundVendorDataType[];
  combine_id: number;
  client_id: number;
}

export interface IOthersRefundVendorDataType {
  vrefund_amount: string;
  vrefund_charge: string;
  vrefund_id: number;
  vrefund_invoice_id: number;
  vrefund_is_deleted: number;
  vrefund_moneyreturn_type: string;
  vrefund_payment_type: string;
  vrefund_quantity: number;
  vrefund_refund_id: number;
  vrefund_return_amount: string;
  vrefund_vendor_id: number;
  vrefund_vendor_name: string;
  vrefund_vendor_type: string;
  vrefund_vouchar_number: string;
}

export interface IOthersRefundSingleClientInfo {
  crefund_charge_amount: string;
  crefund_client_id: number;
  crefund_client_mobile: string;
  crefund_client_name: string;
  crefund_client_type: string;
  crefund_date: string;
  crefund_invoice_id: number;
  crefund_moneyreturn_account_id: number;
  crefund_moneyreturn_type: string;
  crefund_payment_type: string;
  crefund_refund_id: number;
  crefund_return_amount: string;
  crefund_total_amount: string;
  crefund_vouchar_number: string;
}
export interface IOthersRefundSingleVendorInfo {
  product_name: string;
  vrefund_amount: string;
  vrefund_charge: string;
  vrefund_client_refund_amount: string;
  vrefund_client_refund_charge: string;
  vrefund_id: number;
  vrefund_invoice_id: number;
  vrefund_moneyreturn_account_id: number;
  vrefund_moneyreturn_type: string;
  vrefund_payment_type: string;
  vrefund_product_id: number;
  vrefund_quantity: number;
  vrefund_refund_id: number;
  vrefund_return_amount: string;
  vrefund_vendor_id: number;
  vrefund_vendor_mobile: string;
  vrefund_vendor_name: string;
  vrefund_vendor_type: string;
  vrefund_vouchar_number: string;
}

export interface IOthersRefundClientSingleDataType {
  client_refund_info: IOthersRefundSingleClientInfo;
  vendor_refund_info: IOthersRefundSingleVendorInfo[];
  vrefund_amount: any;
}

export interface IOthersRefundClientLogsType {
  othrfndlog_status: string;
  othrfndlog_content: string;
  othrfndlog_date: string;
  created_by: string;
}

export interface IOthersRefundVendorInfo {
  key?: React.Key;
  billing_invoice_id: number;
  billing_product_id: number;
  product_name: string;
  billing_quantity: number;
  billing_remaining_quantity: number;
  billing_cost_price: string;
  billing_subtotal?: string;
  refund_quantity?: number;
  refund_charge: number;
  refund_amount: number;
  refund_price: number;
}

export interface IOthersVendorRefundForm {
  vendor_id: number;
  invoice_id: number;
  vouchar_no: string;
  refund_info: IOthersRefundVendorInfo[];
  total_refund_charge: number;
  net_total: number;
  created_by: number;
  date: string;
  withdraw_date?: string;
  refund_payment_type: 'ADJUST' | 'MONEY_RETURN';
}

export interface IOthersRefundVendorLogsType {
  othrfndlog_status: string;
  othrfndlog_content: string;
  othrfndlog_date: string;
  created_by: string;
}

export interface IOthersRefundVendorSingleDataType {
  refund_id: number;
  refund_invoice_id: number;
  invoice_no: string;
  refund_vendor_id: number;
  vendor_name: string;
  vendor_mobile: string;
  refund_vouchar_number: string;
  refund_net_total: number;
  refund_payment_type: string;
  refund_moneyreturn_payment_type: string;
  refund_create_date: string;
  refund_info: IOthersRefundSingleVendorInfo[];
}

//tour package refund
export interface ITourPackageRefundForm1 {
  client_id: number;
  tour_invoice_id: number;
  date: string;
}

export interface ITourRefundInvoicebyID {
  invoice_id: number;
  invoice_no: string;
  invoice_net_total: string;
}

export interface ITourRefundHistoryType {
  key?: React.Key;
  client_name?: string;
  create_date?: string;
  payment_type?: string;
  refund_amount?: string;
  voucher_no?: string;
  refund_charge?: string;
  crefund_is_deleted: number;
  crefund_refund_id: number;
  refund_id: number;
}

export interface ITourClientInfo {
  client_refund_charge: number;
  client_refund_amount: number;
  client_return_amount: number;
}

export interface ITourAccmdnInfo {
  accm_cost_price: string;
  accm_description: string;
  itinerary_particular: string;
  refund_item_id: number;
  vendor_name: string;
  vendor_id: string;
  vendor_last_balance: string;
  itinerary_id: number;
  vendor_refund_charge: number;
  vendor_refund_amount: number;
  vendor_return_amount: number;
  key?: React.Key;
}
export interface ITourFoodInfo {
  food_cost_price: string;
  food_description: string;
  food_total_cost: string;
  itinerary_particular: string;
  refund_item_id: number;
  vendor_name: string;
  vendor_id: string;
  vendor_last_balance: number;
  itinerary_id: number;
  vendor_refund_charge: number;
  vendor_refund_amount: number;
  vendor_return_amount: number;
  key?: React.Key;
}
export interface ITourGuideInfo {
  guide_cost_price: string;
  guide_description: string;
  guide_total_cost: string;
  itinerary_particular: string;
  refund_item_id: number;
  vendor_name: string;
  vendor_id: string;
  vendor_last_balance: number;
  itinerary_id: number;
  vendor_refund_charge: number;
  vendor_refund_amount: number;
  vendor_return_amount: number;
  key?: React.Key;
}
export interface ITourOtherTransInfo {
  itinerary_particular: string;
  other_trans_cost_price: string;
  other_trans_description: string;
  other_trans_total_cost: string;
  refund_item_id: number;
  vendor_name: string;
  vendor_id: string;
  vendor_last_balance: number;
  itinerary_id: number;
  vendor_refund_charge: number;
  vendor_refund_amount: number;
  vendor_return_amount: number;
  key?: React.Key;
}
export interface ITourTicketInfo {
  itinerary_particular: string;
  ticket_cost_price: string;
  ticket_description: string;
  ticket_total_cost: string;
  refund_item_id: number;
  vendor_name: string;
  vendor_id: string;
  vendor_last_balance: number;
  itinerary_id: number;
  vendor_refund_charge: number;
  vendor_refund_amount: number;
  vendor_return_amount: number;
  key?: React.Key;
}
export interface ITourTransportInfo {
  itinerary_particular: string;
  transport_cost_price: string;
  transport_description: string;
  itinerary_id: number;
  transport_total_cost: string;
  refund_item_id: number;
  vendor_name: string;
  vendor_id: string;
  vendor_last_balance: number;
  vendor_refund_charge: number;
  vendor_refund_amount: number;
  vendor_return_amount: number;
  key?: React.Key;
}
export interface ITourBillingInfo {
  billing_cost_price: string;
  billing_numof_room: string;
  billing_pax_name: string;
  billing_product_id: number;
  billing_profit: number;
  billing_total_pax: number;
  billing_total_sales: string;
}
export interface ITourRefundInvoiceInfo {
  invoice_combclient_id: string;
  client_name: string;
  invoice_sub_total?: string;
  invoice_category_id?: number;
  client_last_balance: number;
  tourAccms: ITourAccmdnInfo[];
  tourFoods: ITourFoodInfo[];
  tourGuide: ITourGuideInfo[];
  tourOtherTrans: ITourOtherTransInfo[];
  tourTicket: ITourTicketInfo[];
  tourTransports: ITourTransportInfo[];
  tourBilling: ITourBillingInfo;
  clientInfo?: ITourClientInfo[];
}

export interface ITourRefundFilteredItenery {
  accomodation_refund: number[];
  food_refund: number[];
  guide_refund: number[];
  other_trans_refund: number[];
  ticket_refund: number[];
  transport_refund: number[];
}
export interface ITourClientRefundReqBody {
  date: string;
  withdraw_date?: string;
  payment_method: number;
  crefund_account_id: number;
  crefund_payment_type: 'MONEY_RETURN' | 'ADJUST';
  crefund_total_amount?: number;
  crefund_charge_amount: number;
  crefund_return_amount: number;
}

export type Itineraries =
  | 'accm'
  | 'food'
  | 'guide'
  | 'other_trans'
  | 'ticket'
  | 'transport';
export interface TourRefundGeneric {
  date: string;
  vrefund_account_id: number;
  comb_vendor_id: string;
  vrefund_payment_type: 'ADJUST' | 'MONEY_RETURN';
  vrefund_total_amount: number;
  vrefund_return_amount: number;
  vrefund_charge_amount: number;
  vrefund_moneyreturn_type: number;
}
export interface ITourRefundReqBody {
  created_by: number;
  invoice_id: number;
  invoice_category_id: number;
  voucher_no: string;
  client_refund_info: ITourClientRefundReqBody;
  itineraries: [Itineraries, TourRefundGeneric[]][];
  vendor_refund_info_accm?: TourRefundGeneric[];
  vendor_refund_info_food?: TourRefundGeneric[];
  vendor_refund_info_guide?: TourRefundGeneric[];
  vendor_refund_info_other_trans?: TourRefundGeneric[];
  vendor_refund_info_ticket?: TourRefundGeneric[];
  vendor_refund_info_transport?: TourRefundGeneric[];
}

export interface IPartialRefundBodyType {
  key?: React.Key;
  prfnd_id: number;
  invoice_no: string;
  prfnd_vouchar_number: string;
  client_name: string;
  ticket_no: string;
  prfnd_date: string;
  prfnd_payment_type: 'ADJUST' | 'MONEY_RETURN';
  prfnd_total_amount: string;
  prfnd_charge_amount: string;
  prfnd_return_amount: string;
  prfnd_profit_amount: string;
  prfnd_create_date: string;
  vendor_refund_info: IPartialRefundVendorInfo[];
  client_id: number;
  combine_id: number;
}

export interface IPartialRefundVendorInfo {
  vprfnd_vendor_id: number;
  vprfnd_combine_id: string;
  vprfnd_account_id: string;
  vprfnd_payment_type: string;
  vprfnd_payment_method: string;
  vprfnd_total_amount: string;
  vprfnd_return_amount: string;
  vprfnd_charge_amount: string;
  vprfnd_ait: string;
  vprfnd_base_fare: string;
  vprfnd_used_base_fare: string;
  vprfnd_remaining_base_fare: string;
  vprfnd_tax: string;
  vprfnd_used_tax: string;
  vprfnd_remaining_tax: string;
  vprfnd_total_commission: string;
  vendor_name: string;
  vendor_mobile: string;
}

export interface IPartialRefundFormItem {
  invoice_id: number;
  comb_client: string;
  created_by: number;
  prfnd_account_id: number;
  prfnd_charge_amount: string;
  prfnd_return_amount: number;
  prfnd_total_amount: string;
  prfnd_profit_amount: number;
  prfnd_payment_method: number;
  date: string;
  note: string;
  prfnd_payment_type: 'ADJUST' | 'MONEY_RETURN';
  vendor_refund_info: IPartialRefundCreateInfo[];
}

export interface IPartialRefundCreateInfo {
  vprfnd_airticket_id: number;
  vprfnd_account_id: number;
  vprfnd_payment_type: 'ADJUST' | 'MONEY_RETURN';
  vprfnd_payment_method: number;
  vprfnd_base_fare_amount: string;
  vprfnd_used_base_fare: string;
  vprfnd_used_airticket_tax: string;
  vprfnd_penalties: number;
  vprfnd_remaining_tax: number;
  vendor_refundable_amount: number;
  vprfnd_ait: number;
  comb_vendor: string;
  vprfnd_remaining_base_fare: number;
  vprfnd_tax: number;
  vprfnd_total_commission: number;
  vprfnd_ticket_no: string;
}

export interface IPartialRefundInvoice {
  invoice_id: number;
  invoice_no: string;
  invoice_net_total: string;
  payment: string;
  invoice_category_id: number;
}
export interface IPartialRefundTickets {
  airticket_id: number;
  airticket_invoice_id: number;
  airticket_no: string;
  airticket_org_agency: number;
}

export interface IPartialRefundInfo {
  invoice_id: number;
  invoice_no: string;
  invoice_client_id: number;
  invoice_combined_id: number;
  client_name: string;
  airticket_id: number;
  airticket_ticket_no: string;
  client_price: string;
  vendor_price: string;
  vendor_id: number;
  airticket_base_fare: string;
  vendor_combine_id: number;
  taxes_ait: number;
  vendor_name: string;
  airticket_tax: string;
}

export interface IPartialRefundSubmitBody {
  airticket_id: number;
  client_charge_amount: string;
  client_id: string;
  client_account_id: number;
  client_note: string;
  client_payment_type: 'ADJUST' | 'MONEY_RETURN';
  prfnd_payment_method: number;
  client_return_amount: string;
  client_total_amount: string;
  refund_date: string;
  vprfnd_refund_vendor_id: string;
  vprfnd_account_id: number;
  ticket_no: string;
  vendor_charge_amount: string;
  vprfnd_payment_type: 'ADJUST' | 'MONEY_RETURN';
  vprfnd_payment_method: number;
  vendor_usages_fare: string;
  vendor_total_amount: string;
  vprfnd_base_fare_amount: string;
  vprfnd_used_base_fare: string;
  vprfnd_penalties: number;
  vprfnd_remaining_tax: number;
  vendor_refundable_amount: number;
  vprfnd_remaining_base_fare: number;
  vprfnd_ait: number;
  vprfnd_tax: number;
  vprfnd_total_commission: number;
}

export interface ticketDetailsByTicketNo {
  invoice_id: string;
  invoice_category_id: string;
  airticket_id: string;
  airticket_vendor_id: string;
  airticket_vendor_combine_id: string;
  airticket_client_id: string;
  airticket_combined_id: string;
  invoice_no: string;
  airticket_client_price: string;
  airticket_purchase_price: string;
  client_name: string;
  vendor_name: string;
  sales_date: string;
}

export interface AdjustRefundSubmit {
  billing_information: AdjustRefund[];
}
export interface AdjustRefund {
  client_return: number;
  vendor_return: number;
  ticket_no: string;
  invoice_id: number;
  invoice_category_id: number;
  airticket_id: number;
  airticket_vendor_id: number;
  airticket_vendor_combine_id: null;
  airticket_client_id: number;
  airticket_combined_id: null;
  invoice_no: string;
  client_name?: string;
  vendor_name?: string;
  sales_date?: string;
  airticket_client_price: number;
  airticket_purchase_price: number;
  refund_date: string;
  client_refund_charge: number;
  vendor_refund_charge: number;
}
