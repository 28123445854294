import {
  DeleteFilled,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Space,
  Table,
  Typography,
} from 'antd';
import { TableProps } from 'antd/lib';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { IPaxDetails } from '../../../../common/types/commonInterfaces';
import { IAirticketBillingInfo } from '../../../IATA_AIR_TICKET/types/invoiceAirTicketTypes';
import { IFlightData } from '../../../IATA_AIR_TICKET/Components/ManualInvoiceAirTicketEdited/ManualEditInVoiceFlight';
import { filterNullColumns } from '../../../../common/utils/common.utils';

type DataIndex = keyof IPaxDetails;
// COMMON
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: DataIndex;
  title: any;
  inputType: 'number' | 'text' | 'date';
  record: IPaxDetails;
  index: number;
}

// MAIN COMPONENT
const InvoicePaxEdit = ({
  setAllEditableData,
  allEditableData,
  invoicesPax,
  setIsPaxUpdate,
  isPaxUpdate,
}: {
  invoicesPax?: IPaxDetails[] | undefined;
  setAllEditableData?: any;
  allEditableData: {
    flightDetails?: IFlightData[] | undefined;
    paxDetails?: IPaxDetails[] | undefined;
    billingDetails?: IAirticketBillingInfo[] | undefined;
    subtotalDetails?: any;
  };
  setIsPaxUpdate?: React.Dispatch<
    React.SetStateAction<{
      update: string;
      add: string;

      remove: string;
    }>
  >;
  isPaxUpdate?: {
    update: string;
    add: string;

    remove: string;
  };
}) => {
  const [form] = Form.useForm();
  const [paxData, setPaxData] = useState<IPaxDetails[] | undefined>(
    invoicesPax?.map((item, index) => ({
      ...item,
      pax_id: index + 1,
    }))
  );
  const [editingKey, setEditingKey] = useState(0);
  const [isAdding, setIsAdding] = useState(false);
  const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === 'date' ? (
        <>
          {' '}
          <DatePicker
            size='small'
            defaultValue={dayjs(record[dataIndex])}
            onChange={(date) => {
              form.setFieldsValue({
                [dataIndex]: date ? date.format('YYYY-MM-DD') : '',
              });
            }}
            format={'DD-MM-YYYY'}
          />
        </>
      ) : (
        <Input size='small' />
      );
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            // rules={[
            //   {
            //     required: true,
            //     message: `Please Input ${title}!`,
            //   },
            // ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record: IPaxDetails) => record?.pax_id === editingKey;
  const Add = (record: IPaxDetails) => {
    isPaxUpdate &&
      setIsPaxUpdate &&
      setIsPaxUpdate({
        ...isPaxUpdate,
        add: 'add',
      });
    setIsAdding(true);
    const newData: any = {
      pax_id: (record?.pax_id as number) + 1,
      passport_passport_no: '',
      passport_name: '',
      passport_date_of_birth: dayjs(),
      passport_date_of_expire: dayjs(),
      passport_person_type: '',
    };

    form.setFieldsValue({
      pax_id: (record.pax_id as number) + 1,
      passport_passport_no: '',
      passport_name: '',
      passport_date_of_birth: dayjs(),
      passport_date_of_expire: dayjs(),
      passport_person_type: '',
    });
    setPaxData([...(paxData as IPaxDetails[]), newData]);
    setEditingKey((record?.pax_id as number) + 1);
  };

  const handleDelete = (key: any) => {
    const newData = paxData?.filter((item) => item.pax_id !== key);
    setPaxData(newData);
    setEditingKey(0);
    setIsAdding(false);
    isPaxUpdate &&
      setIsPaxUpdate &&
      setIsPaxUpdate({
        ...isPaxUpdate,
        remove: 'remove',
        add: '',
      });
  };
  const edit = (record: IPaxDetails) => {
    isPaxUpdate &&
      setIsPaxUpdate &&
      setIsPaxUpdate({ ...isPaxUpdate, update: 'update' });
    form.setFieldsValue({ ...record });
    setEditingKey(record?.pax_id as number);
  };

  const cancel = () => {
    isPaxUpdate &&
      setIsPaxUpdate &&
      setIsPaxUpdate({
        ...isPaxUpdate,
        remove: '',
        add: '',
        update: '',
      });
    setEditingKey(0);
  };

  const paxSave = async (id: number) => {
    try {
      const row = (await form.validateFields()) as IPaxDetails;
      const newData = [...(paxData as IPaxDetails[])];
      const index = newData.findIndex((item) => id === item.pax_id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });

        setPaxData(newData);
        setEditingKey(0);
        setIsAdding(false);
      } else {
        newData.push(row);
        setPaxData(newData);
        setEditingKey(0);
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const paxColumn = [
    {
      title: 'Sl.',
      dataIndex: 'pax_id',
      editable: false,
    },
    {
      title: 'Pax Name',
      dataIndex: 'passport_name',
      key: 'passport_name',
      editable: true,
    },
    {
      title: 'Pax Type',
      dataIndex: 'passport_person_type',
      key: 'passport_person_type',
      editable: true,
    },
    {
      title: 'Passport No',
      dataIndex: 'passport_passport_no',
      key: 'passport_passport_no',
      editable: true,
    },

    {
      title: 'DOB',
      dataIndex: 'passport_date_of_birth',
      key: 'passport_date_of_birth',
      editable: true,
      render: (_: any, data: any) =>
        data.passport_date_of_birth
          ? dayjs(data.passport_date_of_birth).format('D-MM-YYYY')
          : '',
    },
    {
      title: 'Expire Date',
      dataIndex: 'passport_date_of_expire',
      key: 'passport_date_of_expire',
      editable: true,
      render: (_: any, data: any) =>
        data.passport_date_of_expire
          ? dayjs(data.passport_date_of_expire).format('D-MM-YYYY')
          : '',
    },
    {
      title: 'Action',
      width: '80px',
      dataIndex: 'operation',
      render: (_: any, record: IPaxDetails, index: number) => {
        const editable = isEditing(record);
        return editable && !isAdding ? (
          <Space>
            <Typography.Link onClick={() => paxSave(record?.pax_id as number)}>
              ✅
            </Typography.Link>
            <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
              <a>❌</a>
            </Popconfirm>
          </Space>
        ) : isAdding ? (
          <>
            <Typography.Link onClick={() => paxSave(record?.pax_id as number)}>
              ✅
            </Typography.Link>
            <Typography.Link
              disabled={record?.pax_id ? editingKey > record?.pax_id : false}
              onClick={() => handleDelete(record.pax_id)}
            >
              <DeleteFilled style={{ color: 'red' }} />
            </Typography.Link>
          </>
        ) : (
          <Space>
            <EditOutlined
              style={{
                color: 'blue',
              }}
              disabled={editingKey !== 0}
              onClick={() => edit(record)}
            />
            {paxData?.length === index + 1 ? (
              <a onClick={() => Add(record)}>
                <PlusCircleOutlined />
              </a>
            ) : (
              <PlusCircleOutlined disabled />
            )}

            <DeleteFilled
              onClick={() => handleDelete(record.pax_id)}
              style={{ color: 'red' }}
            />
          </Space>
        );
      },
    },
  ];

  //column null data dont show table-------------------------
  const filteredBillingColumns: any = filterNullColumns(paxData, paxColumn);
  const paxMergedColumns: TableProps['columns'] =
    filteredBillingColumns?.col?.map((col: any) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: IPaxDetails) => ({
          record,
          inputType:
            col.dataIndex === 'passport_date_of_expire'
              ? 'date'
              : col.dataIndex === 'passport_date_of_birth'
              ? 'date'
              : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });
  // Update allEditableData when paxData changes
  useEffect(() => {
    setAllEditableData((prev: any) => ({
      ...prev,
      paxDetails: [...(paxData as IPaxDetails[])],
    }));
  }, [paxData]);

  // Initial load of paxData to allEditableData
  useEffect(() => {
    setAllEditableData((prev: any) => ({
      ...prev,
      paxDetails: [...(paxData as IPaxDetails[])],
    }));
  }, []);
  return (
    <>
      <Typography.Title level={5} style={{ marginBottom: '5px' }}>
        PAX DETAILS
      </Typography.Title>

      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={paxData}
          columns={paxMergedColumns}
          pagination={false}
          size='small'
          style={{ marginBottom: '2rem' }}
          className='invoiceBillingTable'
          rowClassName={'invoiceBillingTd'}
        />
      </Form>
    </>
  );
};

export default InvoicePaxEdit;
