import { FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_URL}/api/v1`,
});

export let OTA_URL = 'http://localhost:5173/';

type TokenType = {
  access_token: string | null;
  refresh_token: string | null;
};

type ReqType = {
  args: string | FetchArgs;
  api: BaseQueryApi;
  authorization?: 'access_token' | 'refresh_token';
  extraOptions?: object;
  customHeader?: { [key: string]: string };
};

const request = () => {
  const token: TokenType = {
    access_token: '',
    refresh_token: '',
  };

  let sessionId = localStorage.getItem('__tus');

  const reqWithAuth = async ({
    api,
    args,
    authorization,
    extraOptions = {},
    customHeader,
  }: ReqType) => {
    let headers: Headers;
    headers = new Headers({});

    if (authorization) {
      headers.set('Authorization', `Bearer ${token[authorization]}`);
      headers.set('sessionId', sessionId as string);
      headers.set('session_id', sessionId as string);

      OTA_URL = `${OTA_URL.split('?')[0]}?access_token=${
        token[authorization]
      }&sessionId=${sessionId}`;
    }

    if (customHeader) {
      const tokenValues = Object.entries(customHeader);

      tokenValues.forEach(([key, value]) => {
        headers.set(key, value);
      });
    }

    let finalArgs: string | FetchArgs = args;

    if (typeof args !== 'string') {
      finalArgs = { ...args, headers };
    } else {
      finalArgs = new Request(args, { headers });
    }
    const abc = await baseQuery(finalArgs, api, extraOptions);

    return abc;
  };

  const setToken = ({
    accessToken,
    refreshToken,
  }: {
    accessToken: string;
    refreshToken: string;
  }) => {
    token.access_token = accessToken;
    token.refresh_token = refreshToken;
  };

  const getSessionId = () => {
    return sessionId || '';
  };

  const setSessionId = (session_id: string) => {
    sessionId = session_id;
    localStorage.setItem('__tus', session_id);
  };

  return { setToken, reqWithAuth, getSessionId, setSessionId };
};

export default request;
