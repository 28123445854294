import { message } from 'antd';
import { api } from '../../../../../app/baseQuery';
import {
  IAllInvoices,
  IViewInvoiceDetails,
} from '../../../../../common/types/commonInterfaces';
import {
  HTTPResponse,
  voidType,
} from '../../../../../common/types/commonTypes';
import { MONEY_RECEIPT_TAG } from '../../../../Money_Receipt/api/endpoints/moneyReceiptEndpoints';
import { INVALIDATE_CLIENT_VENDOR_LEDGER } from '../../../../Reports/Api/constants';
import {
  IHajjRefundData,
  IHajjRefundInfoDetails,
  ISubmitHajjRefundData,
  InvoiceHajjEndPointType,
  InvoiceHajjType,
} from '../../Types/InvoiceHajjTypes';
import { INVOICE_HAJJ } from '../constants/constants';

export const invoiceHajjEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // GET ALL
    getAllInvoiceHajj: build.query<HTTPResponse<IAllInvoices[]>, string>({
      query: (query) => ({
        url: `/invoic-hajj/all/31/?${query}`,
      }),
      providesTags: () => [
        'invoices',
        INVOICE_HAJJ,
        { type: MONEY_RECEIPT_TAG },
      ],
    }),

    // VIEW DETAILIS
    getViewInvoiceHajj: build.query<HTTPResponse<IViewInvoiceDetails>, number>({
      query: (id) => ({
        url: `/invoic-hajj/view/${id}`,
      }),
      providesTags: () => [INVOICE_HAJJ, 'invoices', 'invoice_single_view'],
    }),

    // DELETE AND RESTORE
    deleteInvoiceHajj: build.mutation<
      HTTPResponse<void>,
      { query: 'delete' | 'restore'; user_id?: number; id: number }
    >({
      query: ({ id, query, user_id }) => ({
        url: `/invoic-hajj/${query}/${id}`,
        method: 'DELETE',
        body: { invoice_has_deleted_by: user_id },
      }),
      invalidatesTags: [
        'invoices',
        INVOICE_HAJJ,
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    // get invoice number
    getHajjInvioceNo: build.query<HTTPResponse<string>, void>({
      query: () => ({
        url: '/invoic-hajj/ih-invoiceno',
      }),
      providesTags: () => [{ type: INVOICE_HAJJ }],
    }),
    // invoice hajj post
    invoiceHajjPost: build.mutation<
      HTTPResponse<void>,
      { body: InvoiceHajjEndPointType }
    >({
      query: ({ body }) => ({
        url: `/invoic-hajj/post`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        { type: INVOICE_HAJJ },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    invoiceHajjEdit: build.mutation<
      HTTPResponse<void>,
      { body: InvoiceHajjEndPointType; id?: string }
    >({
      query: ({ body, id }) => ({
        url: `/invoic-hajj/edit/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        { type: INVOICE_HAJJ },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    invoiceHajjGetForEdit: build.query<
      HTTPResponse<InvoiceHajjType>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/invoic-hajj/get-for-edit/${id}`,
      }),
      providesTags: () => [{ type: INVOICE_HAJJ }],
    }),

    // Void Invoice Hajj
    voidHajjInvoiceTicket: build.mutation<HTTPResponse<void>, voidType>({
      query: (body) => ({
        url: `/invoic-hajj/void/${body.id}`,
        method: 'PUT',
        body: body,
      }),

      transformErrorResponse: (response) => {
        if (response.status === 400 && response.data) {
          const { message: err } = response.data as {
            message: string;
            success: boolean;
          };
          message.error(`${err}`);
        } else {
          message.error('Some things went to wrong');
        }
      },
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await queryFulfilled;
        message.success('Void Successfully');
      },

      invalidatesTags: [
        'invoices',
        'invoice-hajji',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    // INVOICE HAJJ REFUND ENDPOINTS ----------------
    invoiceHajjGetForRefundData: build.query<
      HTTPResponse<IHajjRefundData[]>,
      { id: string | number }
    >({
      query: ({ id }) => ({
        url: `/invoic-hajj/billing_info/${id}`,
      }),
      providesTags: () => [{ type: INVOICE_HAJJ }],
    }),

    postInvoiceHajjGetForRefundData: build.mutation<
      HTTPResponse<void>,
      ISubmitHajjRefundData
    >({
      query: (body) => ({
        url: `/invoic-hajj/refund`,
        body: body,
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: INVOICE_HAJJ }, { type: 'TaxRefund' }],
    }),

    hajjRefundDetails: build.query<
      HTTPResponse<IHajjRefundInfoDetails>,
      string | undefined
    >({
      query: (id) => ({
        url: `/invoic-hajj/refund/${id}`,
        method: 'GET',
      }),
      providesTags: [{ type: INVOICE_HAJJ }],
    }),
  }),
});

export const {
  useGetHajjInvioceNoQuery,
  useInvoiceHajjPostMutation,
  useLazyInvoiceHajjGetForEditQuery,
  useInvoiceHajjEditMutation,
  useGetAllInvoiceHajjQuery,
  useLazyGetAllInvoiceHajjQuery,
  useGetViewInvoiceHajjQuery,
  useDeleteInvoiceHajjMutation,
  useVoidHajjInvoiceTicketMutation,
  useInvoiceHajjGetForRefundDataQuery,
  usePostInvoiceHajjGetForRefundDataMutation,
  useHajjRefundDetailsQuery,
} = invoiceHajjEndpoints;
