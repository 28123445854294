import { Row, Table } from 'antd';
import { useState } from 'react';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import { setModal } from '../../../../common/slices/commonSlice';
import DesignationAddModal from '../modals/DesignationAddModal';
import DesignationEditModal from '../modals/DesignationEditModal';
import { useAppDispatch } from '../../../../app/hooks';
import { DesignationDataType } from '../types/designationTypes';
import { columns } from '../utils/utils';
import { useGetAllDesignationWithPaginationQuery } from '../endpoints/designationEndpoints';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { perProps } from '../../../../common/types/commonTypes';

const Designation = ({ permission }: perProps) => {
  const dispatch = useAppDispatch();
  const [editInfo, setEditInfo] = useState<DesignationDataType | null>(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const showModal = () => {
    dispatch(setModal(true));
  };

  const {
    isLoading,
    data: designation,
    refetch,
  } = useGetAllDesignationWithPaginationQuery(pagination, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const count = cache.data?.count;
      const dataToReturn: DesignationDataType[] = [];
      const designationNameList: string[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          designationNameList.push(element.designation_name);
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: { dataToReturn, designationNameList, count } };
    },
  });

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Configuration', 'Designations']}
        refetch={refetch}
        reloaderSize='small'
      />
      <CommonTableHeader
        width
        align
        title='Designations'
        modalTitle={editInfo ? 'Edit Designation' : 'Add New Designation'}
        button_text='Add New Designation'
        permission={permission?.['create:any']}
        element={
          editInfo ? (
            <DesignationEditModal
              designationNameList={designation.designationNameList}
              info={editInfo}
              setNull={setEditInfo}
            />
          ) : (
            <DesignationAddModal
              designationNameList={designation.designationNameList}
            />
          )
        }
        setNull={setEditInfo}
      />
      <Row justify={'center'}>
        <Table
          size='small'
          style={{ width: 900 }}
          bordered
          pagination={
            designation?.count !== undefined && designation?.count < 20
              ? false
              : {
                ...pagination,
                total: designation?.count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
          }
          columns={columns({ setEditInfo, showModal, permission, pagination })}
          dataSource={designation.dataToReturn}
          scroll={{ x: true }}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
        />
      </Row>
    </div>
  );
};

export default Designation;
