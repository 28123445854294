import {
  DeleteOutlined,
  EditOutlined,
  ThunderboltOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { Button, FloatButton, Modal, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { useAppSelector } from '../../../app/hooks';
import { selectUser } from '../../../auth/states/userSlice';
import { useSendEmailMutation } from '../../IATA_AIR_TICKET/api/endpoints/airticketInvoiceEndpoints';
import { a4sizeStyle } from '../../Loan_Management/Pages/Loan/ViewLoan';
import { IMoneyReceiptSingleDataType } from '../Types/MoneyReceiptTypes';
import { useDeleteMoneyReturnMutation } from '../api/endpoints/moneyReceiptEndpoints';
import MoneyRec_card from './MoneyRec_card';

interface ReceiptProps {
  moneyReceipt: IMoneyReceiptSingleDataType;
  cashiercomponentRef: React.RefObject<HTMLDivElement>;
  single?: boolean;
}

export const Container = styled.div`
  .underline {
    border-bottom: 2px dotted black;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
`;

export const invoiceViewTitleStyle: React.CSSProperties = {
  display: 'inline-block',
  border: '1px solid #526D82',
  padding: '0 15px',
  marginTop: 16,
  color: '#526D82',
  borderRadius: 5,
  textTransform: 'uppercase',
};

const MoneyReceiptDesign: React.FC<ReceiptProps> = ({
  moneyReceipt,
  cashiercomponentRef,
  single,
}) => {
  const {
    mobile_number,
    client_name,
    receipt_payment_date,
    receipt_total_amount,
    invoice_no,
  } = moneyReceipt;

  const { id } = useParams();

  const orgInfo = useAppSelector((state) => state.user?.organization_info);
  const user = useAppSelector(selectUser);
  const OrgOwnerName = useAppSelector((state) => state.user?.user_full_name);
  const navigate = useNavigate();

  // Email Send APIs
  const [
    sendEmail,
    { isError: emailError, isSuccess: emailSuccess, isLoading: emailLoading },
  ] = useSendEmailMutation();

  const [emailModal, setEmailModal] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEmail = () => {
    setEmailModal(!emailModal);
  };

  const confirmSendEmail = () => {
    // sendEmail({ email: client_email, id: invoice_id });
  };

  const handleEdit = () => {
    navigate(`../moneyreceipt/edit/${id}`);
  };

  // DELETE MONEY RECIPIENT FOR WITH FLOATING BUTTON
  const [deleteMoneyReceipt, { isLoading: deleteLoading }] =
    useDeleteMoneyReturnMutation();

  const handleDelete = () => {
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    await deleteMoneyReceipt({
      id: id as any,
      userId: user?.user_id as number,
    });
    navigate('../moneyreceipt');
  };

  const handleCancelDelete = () => {
    setIsModalOpen(false);
  };

  // SEND WHATSAPP MESSAGE
  const handleWhatsApp = () => {
    const message = `
Hello ${client_name},

We hope this message finds you well. This is a friendly reminder regarding your recent money receipt from ${
      orgInfo?.org_name
    }. We greatly appreciate your business and would like to provide you with the following details:

Voucher Number: ${invoice_no || 'N/A'}
Money Receipt Date: ${
      dayjs(receipt_payment_date).format('DD-MMM-YYYY') || 'N/A'
    }
Total Amount: ${receipt_total_amount || 'N/A'}

Please review the money receipt summary. If you have any questions or concerns regarding the money receipt or need assistance with the payment process, please don't hesitate to reach out to our dedicated support team at ${
      orgInfo?.org_mobile || 'N/A'
    }.

Thank you for choosing ${
      orgInfo?.org_name || 'N/A'
    } for your travel needs. We value your business and look forward to serving you again in the future.

Best regards,
${OrgOwnerName}
${orgInfo?.org_name}
${orgInfo?.org_mobile}
`;

    const whatsappUrl = `https://wa.me/${mobile_number}?text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappUrl, '_blank');
  };

  return (
    <>
      <div
        ref={cashiercomponentRef}
        style={{
          ...a4sizeStyle,
        }}
      >
        <MoneyRec_card
          moneyReceipt={moneyReceipt}
          type={!single ? '(Client Copy)' : ''}
        />

        {!single && (
          <>
            <div
              style={{
                borderWidth: '1px',
                borderBottomWidth: '1px',
                borderStyle: 'dashed',
                margin: '10px 0px 10px 0px',
              }}
            />
            <MoneyRec_card moneyReceipt={moneyReceipt} type='(Office Copy)' />
          </>
        )}
      </div>
      <FloatButton.Group
        trigger='click'
        type='primary'
        style={{ right: 24 }}
        icon={<ThunderboltOutlined />}
      >
        <FloatButton
          onClick={handleWhatsApp}
          tooltip={'Message on whatsapp'}
          icon={<WhatsAppOutlined style={{ color: '#3ec868' }} />}
        />
        {/* <FloatButton
               tooltip={'Send email'}
               onClick={handleEmail}
               icon={<FontAwesomeIcon style={iconStyle} icon={faMessage} />}
        /> */}
        <FloatButton
          onClick={handleEdit}
          tooltip={'Edit invoice'}
          icon={<EditOutlined style={{ color: 'blue' }} />}
        />

        <FloatButton
          onClick={handleDelete}
          tooltip={'Delete invoice'}
          icon={<DeleteOutlined style={{ color: 'red' }} />}
        />
      </FloatButton.Group>

      <Modal
        centered
        title='Are you sure you want to delete this money receipt? '
        open={isModalOpen}
        onOk={handleConfirmDelete}
        onCancel={handleCancelDelete}
        footer={[
          <Button key='cancel' onClick={handleCancelDelete}>
            Cancel
          </Button>,
          <Button
            loading={deleteLoading}
            danger
            key='delete'
            type='primary'
            onClick={handleConfirmDelete}
          >
            Delete
          </Button>,
        ]}
      >
        <p>This action cannot be undone.</p>
      </Modal>

      <Modal
        centered
        title='Are you sure you want to send Email?'
        open={emailModal}
        onOk={confirmSendEmail}
        onCancel={handleEmail}
        footer={[
          <Button key='cancel' onClick={handleEmail}>
            Cancel
          </Button>,
          <Button
            loading={emailLoading}
            type='primary'
            onClick={confirmSendEmail}
          >
            Send
          </Button>,
        ]}
      >
        <p>
          An email will be sent to{' '}
          <Typography.Text strong> {'client_email'} </Typography.Text>
        </p>
      </Modal>
    </>
  );
};

export default MoneyReceiptDesign;
