import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  formatAmount,
  rangePresets,
} from '../../../../common/utils/common.utils';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useLazyGetExcelQuery } from '../../ledgers/endpoints/ledgerEndpoints';
import { useGetGDSReportQuery } from '../endpoints/GdsReportEndpoints';
import { IGDSReportFromDataType } from '../types/GdsReportType';
import gdsReportColumn from '../utils/gdsReportColumn';

const GdsReport = () => {
  const [paramData, setParamData] = useState<{
    date_range: string;
    id: number | string;
  }>();
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelQuery();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const [gdsID, setGdsID] = useState('all');
  const [dateRange, setDateRange] = useState(
    `from_date=${dayjs().format('YYYY-MM-DD')}&to_date=${dayjs().format(
      'YYYY-MM-DD'
    )}`
  );

  const { data, isLoading, isFetching } = useGetGDSReportQuery({
    ...pagination,
    date_range: dateRange,
    gds: gdsID,
  });
  const onFinish = async (values: IGDSReportFromDataType) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    setDateRange(date_range);
    setGdsID(values.gds);

    setParamData({
      date_range: date_range,
      id: '',
    });
  };

  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
    form.setFieldValue('gds', 'all');
  }, []);

  const gds = useWatch('gds', form);
  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: 1,
      pageSize: 50,
    }));
  }, [gds]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `GDS_Report`,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const LastPage = Math.ceil(
    Number(data?.count || 0) / (pagination?.pageSize || 0)
  );

  // @HANDLE CONTENTS
  const jsx_com = (
    <Table
      size='small'
      bordered
      columns={gdsReportColumn(paramData, pagination)}
      dataSource={data?.data?.list}
      loading={{
        spinning: isLoading || isFetching,
        indicator: loadingIndicator,
      }}
      pagination={
        data?.count !== undefined && data?.count < 20
          ? false
          : {
              ...pagination,
              total: data?.count,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }
      }
      summary={(_) => {
        if (pagination?.current === LastPage)
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={1} colSpan={7}>
                <div className='report_total_footer'>
                  <Typography.Text strong>Total </Typography.Text>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} colSpan={1}>
                <div className='align-right'>
                  <Typography.Text strong>
                    {formatAmount(data?.data?.total_gross_fare)}
                  </Typography.Text>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} colSpan={1}>
                <div className='align-right'>
                  <Typography.Text strong>
                    {formatAmount(data?.data?.total_commission)}
                  </Typography.Text>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} colSpan={1}>
                {data?.data?.total_segment}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
      }}
    />
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'GDS Report' }}
      />
    </div>
  );
  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'GDS Report']} />

      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row gutter={24} align={'bottom'}>
          <Col md={12} sm={24}>
            <Row gutter={12}>
              <Col>
                <Form.Item name='gds' label='Select GDS' required>
                  <Select allowClear placeholder='Select GDS'>
                    <Select.Option value='all'>All</Select.Option>
                    <Select.Option value='Galileo'>Galileo</Select.Option>
                    <Select.Option value='Sabre'>Sabre</Select.Option>
                    <Select.Option value='Amadeus'>Amadeus</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  label='Date Range'
                  name='date_range'
                  rules={[
                    { required: true, message: ` Date Range is required!` },
                  ]}
                >
                  <DatePicker.RangePicker
                    style={{ width: '100%' }}
                    presets={rangePresets}
                    format={'DD-MM-YYYY'}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <FormButton label='Search' />
            </Row>
          </Col>

          <Col md={12} sm={24}>
            <Row justify={'end'} gutter={10}>
              <Col>
                <Button type='primary' onClick={handlePrint}>
                  <PrinterOutlined />
                  Print
                </Button>
              </Col>

              <Col>
                <Button
                  type='primary'
                  onClick={() => {
                    fetchExcel({
                      client_id: '',
                      query: '',
                      excelApiName: 'gdsReport',
                      excelName: 'gdsReport',
                    });
                  }}
                >
                  <FileTextOutlined />
                  Excel Reports
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <ReportsHeader date_range required reports_select showGds showAll /> */}
      </Form>

      <FormHeaderTitle title='GDS Report' />
      <Typography.Text>You have total: {data?.count} rows</Typography.Text>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default GdsReport;
