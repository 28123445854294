import { Row } from 'antd';
import {
  FormInput,
  NumberInput,
  TextAreaInput,
} from '../../../components/common/FormItem/FormItems';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'rc-field-form';
import { Fixed2 } from '../../../common/utils/common.utils';
import { useEffect } from 'react';

type Props = {
  isAgentComission: boolean;
  billingForm: FormInstance<any>;
  client_price: number;
};

function InvoiceReIssueSubTotal({
  isAgentComission,
  billingForm,
  client_price,
}: Props) {
  const invoice_sub_total = useWatch(['invoice_sub_total'], billingForm);
  const discount: number = useWatch(['invoice_discount'], billingForm);
  const serviceCharge: number = useWatch(
    ['invoice_service_charge'],
    billingForm
  );
  const vat: number = useWatch(['invoice_vat'], billingForm);

  const netTotal = Fixed2(
    Fixed2(client_price || 0) -
      Fixed2(discount || 0) +
      Fixed2(vat || 0) +
      (Fixed2(serviceCharge) || 0)
  );

  useEffect(() => {
    billingForm.setFieldValue('invoice_net_total', netTotal);
  }, [invoice_sub_total, discount, vat, serviceCharge, netTotal]);

  return (
    <Row gutter={2}>
      <NumberInput
        min='0'
        size={12}
        label={'Discount'}
        name={'invoice_discount'}
        maxChar={10}
      />

      <NumberInput
        size={12}
        label={'Service Charge'}
        name={'invoice_service_charge'}
        min='0'
        maxChar={10}
      />

      <NumberInput
        min='0'
        size={12}
        label='Vat / Tax'
        name='invoice_vat'
        maxChar={10}
      />
      <NumberInput
        size={12}
        label='Net total'
        name={'invoice_net_total'}
        readOnly
        min='0'
        maxChar={14}
      />
      <NumberInput
        size={12}
        label='Agent Commission'
        name={'invoice_agent_com_amount'}
        min='0'
        maxChar={10}
        disabled={isAgentComission}
      />
      <FormInput size={12} label='Reference' name='invoice_reference' />
      <TextAreaInput size={24} label='Note' name='invoice_note' />
    </Row>
  );
}

export default InvoiceReIssueSubTotal;
