import { Col, ConfigProvider, Row, Typography, theme } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { InvoiceFooter } from '../../../common/Invoice/InvoiceFooter';
import {
  InvoiceHeader,
  TitleCenter,
} from '../../../common/Invoice/InvoiceHeader';
import { IGenerateFlightAndPax } from '../QuotationTypes/QuotationTypes';
import GenerateInvoiceSubtotal from './GenerateInvoiceSubtotal';

export type HeightRefsType = {
  header_ref: React.RefObject<HTMLDivElement>;
  subtotal_ref: React.RefObject<HTMLDivElement>;
  signiture_ref: React.RefObject<HTMLDivElement>;
};

export const invoiceViewStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'rgba(211, 211, 211, 0.2)',
  minHeight: '100vh',
  padding: '20px 0',
};
export const a4sizeStyle: React.CSSProperties = {
  minHeight: '11in',
  width: '8.27in',
  fontSize: '11px',
  background: '#fff',
  margin: '0px auto',
  boxSizing: 'border-box',
  padding: '0 15px',
};
export const a4sizeStyleHalfPage: React.CSSProperties = {
  minHeight: '5.75in',
  width: '8.27in',
  boxSizing: 'border-box',
  fontSize: '12px',
  background: '#fff',
  margin: '0 auto',
};
type Props = {
  viewInvoicePrintableRef?: React.RefObject<HTMLDivElement>;
  children: JSX.Element;
  heightRefs: HeightRefsType | any;
  clientGIName: any;
  date: string | undefined;
  orgName: string | undefined;
  salesBy: string | undefined;
  allGenerateInvoice: IGenerateFlightAndPax | any;
  setCurrentDiscount?: React.Dispatch<React.SetStateAction<number | undefined>>;
  setCurrentPayment?: React.Dispatch<React.SetStateAction<number | undefined>>;
};
const ViewGenerateInvoice = ({
  viewInvoicePrintableRef,
  children,
  heightRefs,
  clientGIName,
  date,
  orgName,
  salesBy,
  allGenerateInvoice,
  setCurrentDiscount,
  setCurrentPayment,
}: Props) => {
  return (
    <ViewInvoiceStyle>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <div
          ref={viewInvoicePrintableRef}
          style={{ ...a4sizeStyle, position: 'relative' }}
        >
          <header ref={heightRefs.header_ref}>
            <InvoiceHeader />

            <TitleCenter title='Invoice' />

            <Row justify={'space-between'} align='middle'>
              <Col
                style={{
                  color: '#fff',
                  width: '50%',
                }}
              >
                <Typography style={{ fontWeight: 'bolder' }}>
                  Invoice To
                </Typography>
                {clientGIName && (
                  <Typography.Text className='changeFontBlock fs-12'>
                    {'Name :'}
                    {clientGIName && clientGIName}
                  </Typography.Text>
                )}
              </Col>

              <Col>
                <Typography.Text className='changeFontBlock fs-12'>
                  Invoice Date : {date && date}
                </Typography.Text>

                <Typography.Text className='changeFontBlock fs-12'>
                  Sales By : {salesBy}
                </Typography.Text>
                <Typography.Text className='changeFontBlock fs-12'>
                  Prepared By : {orgName}
                </Typography.Text>
              </Col>
            </Row>
          </header>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',

              boxSizing: 'border-box',
              minHeight: '7.7in',
              position: 'relative',
            }}
          >
            <div>
              {children}
              <div ref={heightRefs.subtotal_ref}>
                <GenerateInvoiceSubtotal
                  allGenerateInvoice={allGenerateInvoice}
                  setCurrentDiscount={setCurrentDiscount}
                  setCurrentPayment={setCurrentPayment}
                />
              </div>
            </div>

            {/* Footer */}
            <div ref={heightRefs.signiture_ref}>
              <InvoiceFooter
                authorized_by={allGenerateInvoice?.authorized_by}
                prepared_by={allGenerateInvoice?.prepared_by}
              />
            </div>
          </div>
        </div>
      </ConfigProvider>
    </ViewInvoiceStyle>
  );
};

export default ViewGenerateInvoice;

const ViewInvoiceStyle = styled.div``;
