import { MinusCircleOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Col, Popconfirm, Row } from 'antd';
import { FormInstance, FormListFieldData, Rule } from 'antd/lib/form';
import {
  DateInput,
  NumberInput,
} from '../../../components/common/FormItem/FormItems';
import {
  SelectClientsInvoicesById,
  SelectClientsInvoicesByIdForEdit,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import { Invoices } from '../../../components/notificatioin/Interfaces/Notification.interface';
import {
  IMoneyReceiptInvoices,
  IMoneyReceiptTickets,
} from '../Types/MoneyReceiptTypes';
import { useEffect, useState } from 'react';

type Props = {
  selectClient?: number | string;
  name: number;
  fields: FormListFieldData[];
  remove: (index: number | number[]) => void;
  paymentTo: 'INVOICE' | 'TICKET';
  index: number;

  form: FormInstance<any>;
  reason?: 'ADD_NEW' | 'EDIT';
  data?: Invoices;
  disableRemoveIndex?: number | false | undefined;
};

export default function NewMoneyReceiptSpecificInvoice({
  selectClient,
  name,
  fields,
  remove,
  paymentTo,
  index,
  form,
  reason,
  data,
  disableRemoveIndex,
}: Props) {
  const receipt_combclient = form.getFieldValue('receipt_combclient');
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (disableRemoveIndex && disableRemoveIndex > index) {
      setDisabled(true);
    }
  }, [disableRemoveIndex]);


  const lgSize = paymentTo === 'TICKET' ? 5 : null;



  const rules: Rule[] = [
    {
      validator(rule, value, callback) {
        if (paymentTo === 'INVOICE') {
          const fullLIst: IMoneyReceiptInvoices[] = form.getFieldValue([
            'invoices',
          ]);
          const filtered = fullLIst.filter((item, i) => {
            if (item.invoice_no === value) {
              if (index === i) {
                return;
              }
              return item;
            }
          });
          if (filtered.length) {
            return Promise.reject('Cannot select duplicate invoice!!');
          } else {
            return Promise.resolve();
          }
        } else {
          const fullLIst: IMoneyReceiptTickets[] = form.getFieldValue([
            'tickets',
          ]);
          const filtered = fullLIst.filter((item, i) => {
            if (item.ticket_no === value) {
              if (index === i) {
                return;
              }
              return item;
            }
          });
          if (filtered.length) {
            return Promise.reject('Cannot select duplicate invoice!!');
          } else {
            return Promise.resolve();
          }
        }
      },
    },
  ];

  return (
    <>

      {reason === 'ADD_NEW' && (
        <SelectClientsInvoicesById
          label={paymentTo === 'INVOICE' ? 'Invoice No.' : 'Ticket No.'}
          name={[name, paymentTo === 'INVOICE' ? 'invoice_no' : 'ticket_no']}
          client_id={receipt_combclient}
          receipt_payment_to={paymentTo}
          disabled={selectClient && !data?.invoice_no ? false : true}
          placeholder={`Select ${paymentTo === 'INVOICE' ? 'Invoice No.' : 'Ticket No.'
            }`}
          rules={rules}
          index={name}
          form={form}
          paymentTo={paymentTo}
        />
      )}
      {reason === 'EDIT' && (
        <SelectClientsInvoicesByIdForEdit
          label={paymentTo === 'INVOICE' ? 'Invoice No.' : 'Ticket No.'}
          name={[name, paymentTo === 'INVOICE' ? 'invoice_no' : 'ticket_no']}
          client_id={receipt_combclient}
          receipt_payment_to={paymentTo}
          disabled={
            disabled || (selectClient && !data?.invoice_no ? false : true)
          }
          placeholder={`Select ${paymentTo === 'INVOICE' ? 'Invoice No.' : 'Ticket No.'
            }`}
          rules={rules}
          index={name}
          form={form}
          paymentTo={paymentTo}

        />
      )}
      {paymentTo === 'INVOICE' && (
        <DateInput
          name={[name, 'invoiceDate']}
          label='Sales Date'
          size={4}
        />
      )}
      <NumberInput
        name={[name, 'netTotal']}
        label='Net Total'
        readOnly
        size={lgSize || 4}
        min='0'
        maxChar={14}
        minChar={0}
      />
      <NumberInput
        name={[name, 'paid']}
        label='Paid'
        size={lgSize || 3}
        readOnly
        min='0'
        maxChar={14}
        minChar={0}
      />
      <NumberInput
        name={[name, 'due']}
        label='Due'
        size={lgSize || 4}
        readOnly
        min='0'
        maxChar={14}
        minChar={0}
      />
      <NumberInput
        name={[name, 'invoice_amount']}
        label='Amount'
        size={4}
        min='0'
        maxChar={14}
        minChar={0}
        required
        disabled={disabled}
        maxAmount={
          reason === 'ADD_NEW'
            ? Number(
              form.getFieldValue([
                paymentTo === 'INVOICE' ? 'invoices' : 'tickets',
                name,
                'due',
              ]) || 0
            )
            : Number(
              form.getFieldValue([
                paymentTo === 'INVOICE' ? 'invoices' : 'tickets',
                name,
                'due',
              ]) || 0
            ) +
            Number(
              form.getFieldValue([
                paymentTo === 'INVOICE' ? 'invoices' : 'tickets',
                name,
                'paid',
              ]) || 0
            )
        }
        max={
          reason === 'ADD_NEW'
            ? Number(
              form.getFieldValue([
                paymentTo === 'INVOICE' ? 'invoices' : 'tickets',
                name,
                'due',
              ]) || 0
            )
            : Number(
              form.getFieldValue([
                paymentTo === 'INVOICE' ? 'invoices' : 'tickets',
                name,
                'due',
              ]) || 0
            ) +
            Number(
              form.getFieldValue([
                paymentTo === 'INVOICE' ? 'invoices' : 'tickets',
                name,
                'paid',
              ]) || 0
            )
        }
      />

      {fields.length > 1 ? (
        <Col lg={1}>
          <Popconfirm
            placement='top'
            title={'Are you sure you want to remove?'}
            onConfirm={() => {
              remove(name);
            }}
            okText='Remove'
            cancelText='Cancel'
            disabled={disableRemoveIndex ? disableRemoveIndex > index : false}
          >
            <Button danger >

              <MinusOutlined />
            </Button>
          </Popconfirm>
        </Col>
      ) : null}
    </>
  );
}
