import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import {
  IAirTicketInfo,
  IExistingTicket,
  IFlights,
  IPaxDetails,
} from '../../../common/types/commonInterfaces';
import {
  Fixed2,
  FormatDate,
  filterNullColumns,
  isNotEmpty,
} from '../../../common/utils/common.utils';
import { HeightRefsType } from '../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice';
import getInvoiceViewPageController from '../utils/ts/getInvoiceViewPageController';

type Props = {
  flights?: IFlights[];
  paxDetails?: IPaxDetails[];
  airticketDetails?: IAirTicketInfo[] | any;
  heightRefs: HeightRefsType;
  existingTicket?: IExistingTicket[];
  showFlightDetails?: boolean;
  showPaxDetails?: boolean;
};
export const PAX_TYPES = {
  1: 'Adult',
  2: 'Child',
  3: 'Infant',
};

const AirTicketFlightAndPaxView = ({
  flights,
  paxDetails,
  airticketDetails,
  heightRefs,
  existingTicket,
  showPaxDetails,
  showFlightDetails,
}: Props) => {
  const flightsColumn: ColumnsType<IFlights> = [
    {
      title: <span className='table-column-title'> Sl.</span>,
      align: 'center',
      render(value, record, index) {
        return <>{index + 1}</>;
      },
    },
    {
      title: <span className='table-column-title'> From</span>,
      align: 'left',
      dataIndex: 'flight_from',
      key: 'flight_from',
    },
    {
      title: <span className='table-column-title'> To</span>,
      align: 'left',
      dataIndex: 'flight_to',
      key: 'flight_to',
    },
    {
      title: <span className='table-column-title'> Airline</span>,
      align: 'left',
      dataIndex: 'airline_name',
      key: 'airline_name',
    },
    {
      title: <span className='table-column-title'> Departure </span>,
      dataIndex: 'fltdetails_departure_time',
      key: 'fltdetails_departure_time',
    },
    {
      title: <span className='table-column-title'> Arrival </span>,
      dataIndex: 'fltdetails_arrival_time',
      key: 'fltdetails_arrival_time',
    },
  ];

  const billingsColumn: ColumnsType<any> = [
    {
      title: <span className='table-column-title'>Sl.</span>,
      render(value, record, index) {
        return <>{index + 1}</>;
      },
      align: 'center',
    },

    {
      title: <span className='table-column-title'>Ticket No</span>,
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
      align: 'left',
    },
    {
      title: <span className='table-column-title'>Pax Name</span>,
      dataIndex: 'passport_name',
      key: 'passport_name',
      align: 'left',
    },

    {
      title: <span className='table-column-title'>PNR</span>,
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
      align: 'left',
    },
    {
      title: <span className='table-column-title'> Class</span>,
      dataIndex: 'airticket_classes',
      key: 'airticket_classes',
      align: 'center',
    },

    {
      title: <span className='table-column-title'>Route</span>,
      dataIndex: 'airticket_routes',
      key: 'airticket_routes',
      align: 'left',
      width: 70,
    },

    {
      title: <span className='table-column-title'> Journey </span>,
      key: 'airticket_journey_date',
      dataIndex: 'airticket_journey_date',
      render: (_, data) => {
        return data.airticket_journey_date
          ? dayjs(data.airticket_journey_date).format('DD-MM-YYYY')
          : '';
      },
    },
    {
      title: <span className='table-column-title'> Return</span>,
      key: 'airticket_return_date',
      dataIndex: 'airticket_return_date',
      render: (_, data) => {
        return data.airticket_return_date
          ? dayjs(data.airticket_return_date).format('DD-MM-YYYY')
          : '';
      },
    },

    {
      title: <span className='table-column-title'>Type</span>,
      dataIndex: 'airticket_id:',
      key: 'airticket_id:',
      align: 'left',
      render: (_, data) => {
        return (
          <span>
            {data.airticket_is_refund ? (
              <span style={{ color: 'red' }}>REFUND </span>
            ) : data.airticket_is_reissued ? (
              <span style={{ color: 'red' }}>REISSUED </span>
            ) : data.airticket_is_void ? (
              <span style={{ color: 'red' }}>VOID </span>
            ) : (
              <span style={{ color: 'green' }}>ISSUED </span>
            )}
          </span>
        );
      },
    },

    {
      title: <span className='table-column-title'> Total </span>,
      dataIndex: 'airticket_client_price',
      key: 'airticket_client_price',
      align: 'right',
      render: (text: string, record) => {
        return (
          <Typography.Text delete={record.airticket_is_void}>
            {Fixed2(text)}
          </Typography.Text>
        );
      },
    },
  ];
  const existingTicketColumn: ColumnsType<any> = [
    {
      title: <span className='table-column-title'>Sl.</span>,
      render(value, record, index) {
        return <>{index + 1}</>;
      },
      align: 'center',
    },

    {
      title: <span className='table-column-title'>Ticket No</span>,
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
      align: 'left',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: <span className='table-column-title'>Airline Name</span>,
      dataIndex: 'airline_name',
      key: 'airline_name',
      align: 'left',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: <span className='table-column-title'>Pax Name</span>,
      dataIndex: 'passport_name',
      key: 'passport_name',
      align: 'left',
      render: (text: string) => <span>{text}</span>,
    },

    {
      title: <span className='table-column-title'>PNR</span>,
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
      align: 'left',
      render: (text: string) => <span>{text} </span>,
    },
    {
      title: <span className='table-column-title'>Route</span>,
      dataIndex: 'airticket_routes',
      key: 'airticket_routes',
      align: 'left',
    },

    {
      title: <span className='table-column-title'>Sales Date </span>,
      key: 'sales_date',
      render: (_, data) => <span>{FormatDate(data.sales_date)}</span>,
    },
    {
      title: <span className='table-column-title'>Journey Date </span>,
      key: 'airticket_journey_date',
      render: (_, data) => (
        <span>{FormatDate(data.airticket_journey_date)}</span>
      ),
    },
    {
      title: <span className='table-column-title'> Return Date </span>,
      key: 'airticket_return_date',
      render: (_, data) => (
        <span>{FormatDate(data.airticket_return_date)}</span>
      ),
    },
    {
      title: <span className='table-column-title'> Total </span>,
      dataIndex: 'airticket_client_price',
      key: 'airticket_client_price',
      align: 'right',
      render: (text: string, record) => <span>{Fixed2(text)}</span>,
    },
  ];

  const filteredBillingColumns = filterNullColumns(
    airticketDetails,
    billingsColumn
  );

  const paxDetailsColumn: ColumnsType<any> = [
    {
      title: <span className='table-column-title'> Sl. </span>,
      align: 'center',
      render(value, record, index) {
        return <>{index + 1}</>;
      },
    },
    {
      title: <span className='table-column-title'>Pax Name</span>,
      dataIndex: 'passport_name',
      key: 'passport_name',
    },
    {
      title: <span className='table-column-title'>Pax Type</span>,
      dataIndex: 'passport_person_type',
      key: 'passport_person_type',
      render(value, record, index) {
        return (
          <>
            {/* value some time get text and some time get id */}
            {typeof value === 'number' || Fixed2(value)
              ? PAX_TYPES[value as 1 | 2 | 3]
              : value}
          </>
        );
      },
    },

    {
      title: <span className='table-column-title'> Passport No </span>,
      dataIndex: 'passport_passport_no',
      key: 'passport_passport_no',
    },

    {
      title: <span className='table-column-title'>Mobile</span>,
      dataIndex: 'passport_mobile_no',
      key: 'passport_mobile_no',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: <span className='table-column-title'> DOB </span>,
      key: 'passport_date_of_birth',
      dataIndex: 'passport_date_of_birth',
      render: (_, data) =>
        data.passport_date_of_birth
          ? dayjs(data.passport_date_of_birth).format('DD-MM-YYYY')
          : '',
    },
    {
      title: <span className='table-column-title'> Expire Date </span>,
      key: 'passport_date_of_expire',
      dataIndex: 'passport_date_of_expire',
      render: (_, data) =>
        data.passport_date_of_expire
          ? dayjs(data.passport_date_of_expire).format('DD-MM-YYYY')
          : '',
    },
  ];

  const filteredPaxColumns = filterNullColumns(paxDetails, paxDetailsColumn);

  // @HANDLE PRINT DOUBLE PAGE
  // states, variables and references
  const filight_ref = useRef<HTMLDivElement>(null);
  const pax_ref = useRef<HTMLDivElement>(null);
  const billing_ref = useRef<HTMLDivElement>(null);

  const [pageController, setPageController] = useState<
    {
      identifier: string;
      marginTop: number;
      bottomIdentifier: string;
    }[]
  >();

  useEffect(() => {
    const filight_ref_height = Fixed2(filight_ref.current?.clientHeight) / 96; // value in inch
    const pax_ref_height = Fixed2(pax_ref.current?.clientHeight) / 96; // value in inch
    const billing_ref_height = Fixed2(billing_ref.current?.clientHeight) / 96; // value in inch

    const header_height =
      Fixed2(heightRefs.header_ref.current?.clientHeight) / 96; // value in inch
    const signiture_height =
      Fixed2(heightRefs.signiture_ref.current?.clientHeight) / 96; // value in inch
    const subtotal_height =
      Fixed2(heightRefs.subtotal_ref.current?.clientHeight) / 96; // value in inch

    const pageControl: any = getInvoiceViewPageController({
      topHeight: header_height,
      footerHeight: subtotal_height + signiture_height,
      pageHeight: 11.7,
      items: [
        {
          height: filight_ref_height,
          identifier: 'flight',
        },
        {
          height: pax_ref_height,
          identifier: 'pax_details',
        },
        {
          height: billing_ref_height,
          identifier: 'billing',
        },
        {
          height: subtotal_height + signiture_height,
          identifier: 'footer',
        },
      ],
    });

    setPageController(pageControl);
  }, [flights, paxDetails, airticketDetails]);

  return (
    <div className='mt-20'>
      <div ref={filight_ref}>
        {isNotEmpty(flights) && showFlightDetails && (
          <>
            <Typography.Title level={5} style={{ marginBottom: '5px' }}>
              FLIGHT/ROUTE DETAILS
            </Typography.Title>
            {
              <Table
                size='small'
                bordered
                rowKey={(e) => e.fltdetails_arrival_time}
                style={{ marginBottom: '2rem' }}
                className='invoiceBillingTable'
                rowClassName={'invoiceBillingTd'}
                dataSource={flights}
                columns={flightsColumn}
                pagination={false}
              />
            }
          </>
        )}
      </div>
      {isNotEmpty(existingTicket) && (
        <>
          <Typography.Title level={5} style={{ marginBottom: '5px' }}>
            TICKET DETAILS
          </Typography.Title>
          {
            <Table
              size='small'
              bordered
              rowKey={(e) => e.fltdetails_arrival_time}
              style={{ marginBottom: '2rem' }}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              dataSource={existingTicket}
              columns={existingTicketColumn}
              pagination={false}
            />
          }
        </>
      )}
      <div
        ref={pax_ref}
        style={{
          marginTop:
            pageController && pageController[1].identifier === 'pax_details'
              ? `${pageController[1].marginTop}in`
              : 0,
          // Add border top for identify page end
          borderTop:
            pageController &&
            pageController[1].identifier === 'pax_details' &&
            pageController[1].marginTop
              ? '4px dashed lightgray'
              : '',
          paddingTop:
            pageController &&
            pageController[1].identifier === 'pax_details' &&
            pageController[1].marginTop
              ? '0.5in'
              : '',
        }}
      >
        {paxDetails?.length && showPaxDetails ? (
          <>
            <Typography.Title level={5} style={{ marginBottom: '5px' }}>
              PAX DETAILS
            </Typography.Title>
            {
              <Table
                size='small'
                rowKey={(e) => e.airticket_ticket_no}
                style={{ marginBottom: '2rem' }}
                className='invoiceBillingTable'
                rowClassName={'invoiceBillingTd'}
                dataSource={paxDetails}
                columns={filteredPaxColumns.col}
                pagination={false}
                bordered
              />
            }
          </>
        ) : (
          <></>
        )}
      </div>

      <div
        ref={billing_ref}
        style={{
          // billing margin control
          marginTop:
            pageController && pageController[2].identifier === 'billing'
              ? `${pageController[2].marginTop}in`
              : 0,
          borderTop:
            pageController &&
            pageController[2].identifier === 'billing' &&
            pageController[2].marginTop
              ? '4px dashed lightgray'
              : '',
          paddingTop:
            pageController &&
            pageController[2].identifier === 'billing' &&
            pageController[2].marginTop
              ? '0.5in'
              : '',
        }}
      >
        {airticketDetails?.length && (
          <>
            <Typography.Title level={5} style={{ marginBottom: '5px' }}>
              BILLING INFO
            </Typography.Title>
            <Table
              size='small'
              bordered
              rowKey={(e) => e.airticket_ticket_no}
              style={{ marginBottom: '2rem' }}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              dataSource={airticketDetails}
              columns={filteredBillingColumns.col}
              pagination={false}
            />
          </>
        )}
      </div>
      {/* THIS IS CREATE ONLY FOR CONTROL FOOTER */}
      <div
        style={{
          marginTop:
            pageController && pageController[3].identifier === 'footer'
              ? `${pageController[3].marginTop}in`
              : 0,
          borderTop:
            pageController &&
            pageController[3].identifier === 'footer' &&
            pageController[3].marginTop
              ? '4px dashed lightgray'
              : '',
          paddingTop:
            pageController &&
            pageController[3].identifier === 'footer' &&
            pageController[3].marginTop
              ? '0.5in'
              : '',
        }}
      ></div>
    </div>
  );
};

export default AirTicketFlightAndPaxView;
