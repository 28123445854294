import { Button, Card, Divider, Form, message, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { postPnrInvoice } from '../../../common/types/commonInterfaces';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { usePostPnrInvoiceMutation } from '../api/endpoints/airticketInvoiceEndpoints';
import AirticketHeaderPNR from '../Components/AirticketHeaderPNR';

type Props = {};

const InvoiceAirTicketByPNR = (props: Props) => {
  const [form] = useForm();
  const navigate = useNavigate();

  const [postPNRinvoice, { isLoading, isSuccess, isError, data }] =
    usePostPnrInvoiceMutation();

  const handleSubmit = (e: postPnrInvoice) => {
    postPNRinvoice(e);
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Invoice Created Successfully');
      navigate(`/airticket/details/${data?.data?.invoice_id}`, {
        state: location.pathname,
      });
    }
  }, [isSuccess, isError]);

  return (
    <>
      <BreadCrumb arrOfOption={['Invoice (PNR)']} />
      <div
        className='border'
        style={{ padding: 10, minHeight: '100vh', paddingTop: 30 }}
      >
        <Row justify={'center'}>
          <Card style={{ maxWidth: 500, flexGrow: 1 }} bordered={false}>
            <Divider>CREATE INVOICE BY PNR</Divider>
            <Form
              disabled={isLoading}
              form={form}
              layout='vertical'
              onFinish={handleSubmit}
              onFinishFailed={(value) => {
                message.error(
                  `Enter all required (${value.errorFields.length}) Fields`
                );
              }}
            >
              <AirticketHeaderPNR form={form} />

              <Row justify={'end'}>
                <Form.Item>
                  <Button loading={isLoading} type='primary' htmlType='submit'>
                    Create Invoice
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Card>
        </Row>
      </div>
    </>
  );
};

export default InvoiceAirTicketByPNR;
