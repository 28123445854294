import { Col, Form, Row, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { selectUser } from '../../../../auth/states/userSlice';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { getAirportByItaCode } from '../../../../common/utils/common.utils';
import postDateFormat from '../../../../common/utils/postDateFormat';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import InvoiceMoneyReceipt from '../../../../components/common/Invoice/InvoiceMoneyReceipt';
import InvoiceSubTotal from '../../../../components/common/Invoice/InvoiceSubTotal';
import InvoiceBillingInfo from '../../../../components/common/Invoice/billingInfo/InvoiceBillingInfo';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllAirportQuery } from '../../../Configuration/airport/endpoints/airportEndpoints';
import { useGetInvoiceNoQuery } from '../../../Invoice(Visa)/Api/Endpoints/invoiceVisaEndpoints';
import { IHotel_information } from '../../../InvoiceUmrah/Types/InvoiceUmrahTypes';
import NewAddHajjFormTop from '../Components/NewAddHajjFormTop';
import NewAddHajjFormTransportInfo from '../Components/NewAddHajjFormTransportInfo';
import NewAddHajjPilgrimsInfo from '../Components/NewAddHajjPilgrimsInfo';
import NewAddhajjFormHotelInfo from '../Components/NewAddhajjFormHotelInfo';
import {
  IBilling_information,
  IPilgrims_information,
  ITransport_information,
  InvoiceHajjEndPointType,
  InvoiceHajjType,
} from '../Types/InvoiceHajjTypes';
import { useInvoiceHajjPostMutation } from '../api/endpoints/invoicehajjEndpoints';
import { useCheckCreditLimitMutation } from '../../../Client/Client/api/endpoints/clientEndpoints';

type Props = {};
const NewInvoiceAddHajj = ({}: Props) => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const [updateAndPrev, setUpdateAndPrev] = useState<boolean>(false);

  const [
    invoiceHajjPost,
    {
      isError: invoiceHajjPostIsError,
      isSuccess: invoiceHajjPostIsSuccess,
      error: invoiceHajjPostError,
      isLoading: postLoading,
    },
  ] = useInvoiceHajjPostMutation();

  const [checkCreditLimit, { isLoading: limiLoading }] =
    useCheckCreditLimitMutation();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (invoiceHajjPostIsSuccess) {
      toasterNotification('success', 'Successfully Added');
      form.resetFields();
      timeoutId = setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // navigate('/invoicehajj');
        invoiceNoRefetch();
      }, 100);
    } else if (invoiceHajjPostIsError) {
      message.error("Some thing went's to wrong");
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [invoiceHajjPostIsSuccess, invoiceHajjPostIsError]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      invoice_no: invoiceNo?.data,
      invoice_sales_date: dayjs(),
    });
  }, [location]);

  const { data: airport } = useGetAllAirportQuery();
  const selectAirport = airport?.data;

  //CREATE INVOCIE HAJJ AND EDIT
  const onFinish = (values: InvoiceHajjType) => {
    const formatedData: InvoiceHajjEndPointType = {
      invoice_combclient_id: values.invoice_combclient_id,
      invoice_haji_group_id: values.invoice_haji_group_id,
      invoice_sales_man_id: values.invoice_sales_man_id,
      invoice_no: values.invoice_no,
      invoice_vat: values.invoice_vat,
      invoice_sales_date: dayjs(values.invoice_sales_date).format('YYYY-MM-DD'),
      invoice_due_date: dayjs(values.invoice_due_date).format('YYYY-MM-DD'),
      invoice_hajj_routes: getAirportByItaCode(
        values?.invoice_hajj_routes as string,
        selectAirport
      ) as number[],
      invoice_agent_id: values?.invoice_agent_id,
      invoice_agent_com_amount: values?.invoice_agent_com_amount,
      hajj_total_pax: values.hajj_total_pax,
      invoice_sub_total: values.invoice_sub_total,
      invoice_discount: values.invoice_discount,
      invoice_service_charge: values.invoice_service_charge,
      invoice_net_total: values.invoice_net_total,
      invoice_note: values.invoice_note,
      invoice_reference: values.invoice_reference,
      invoice_created_by: user?.user_id as number,
      invoice_hajj_session: dayjs(values.invoice_hajj_session).format('YYYY'),

      pilgrims_information: values?.pilgrims_information
        ?.map((el: IPilgrims_information) => {
          const anyOneExist =
            el.haji_info_passport_id ||
            el.hajiinfo_dob ||
            el.hajiinfo_gender ||
            el.hajiinfo_mobile ||
            el.hajiinfo_name ||
            el.hajiinfo_nid ||
            el.hajiinfo_serial ||
            el.hajiinfo_tracking_number;
          if (!anyOneExist) {
            return undefined;
          }
          return {
            ...el,
            hajiinfo_mobile: el.hajiinfo_mobile
              ? el.dialCode + '-' + el.hajiinfo_mobile?.substring(1)
              : undefined,
            hajiinfo_dob: postDateFormat(el.hajiinfo_dob),
            ticket_route: el?.ticket_route?.length
              ? (getAirportByItaCode(
                  el?.ticket_route as string,
                  selectAirport
                ) as string[])
              : undefined,
          };
        })
        .filter((item) => item) as IPilgrims_information[] | [],

      hotel_information: values.hotel_information[0].hotel_name
        ? values.hotel_information?.map((el: IHotel_information) => {
            return {
              ...el,
              hotel_check_in_date: postDateFormat(el.hotel_check_in_date),
              hotel_check_out_date: postDateFormat(el.hotel_check_out_date),
            };
          })
        : undefined,
      transport_info:
        JSON.stringify(values.transport_information[0]) !== '{}'
          ? values.transport_information?.map((el: ITransport_information) => {
              return {
                ...el,
                transport_dropoff_place: el.transport_dropoff_place
                  ? el.transport_dropoff_place
                  : undefined,
                transport_pickup_place: el.transport_pickup_place
                  ? el.transport_pickup_place
                  : undefined,
                transport_type_id: el.transport_type_id
                  ? el.transport_type_id
                  : undefined,
                transport_dropoff_time: el.transport_dropoff_time
                  ? el.transport_dropoff_time
                  : undefined,
                transport_pickup_time: el.transport_pickup_time
                  ? el.transport_pickup_time
                  : undefined,
              };
            })
          : undefined,
      billing_information: values.billing_information.map(
        (el: IBilling_information) => {
          delete el?.billing_total_cost_price;
          return {
            ...el,
          };
        }
      ),
      money_receipt: values.receipt_payment_type
        ? {
            receipt_total_amount: values.receipt_total_amount,
            receipt_payment_type: values.receipt_payment_type,
            account_id: values.account_id,
            charge_amount: values.charge_amount,
            cheque_bank_name: values.cheque_bank_name,
            cheque_number: values.cheque_number,
            cheque_withdraw_date: dayjs(values.cheque_withdraw_date).format(
              'YYYY-MM-DD'
            ),
            receipt_money_receipt_no: values.receipt_money_receipt_no,
            receipt_note: values.receipt_note,
            trans_no: values.trans_no,
            receipt_payment_date: dayjs(values.receipt_payment_date).format(
              'YYYY-MM-DD'
            ),
          }
        : undefined,
    };

    checkCreditLimit({
      amount: formatedData.invoice_net_total,
      combClient: formatedData.invoice_combclient_id.toString(),
    }).then((res: any) => {
      if (res?.data?.data === 1) {
        invoiceHajjPost({ body: formatedData }).then((res: any) => {
          if (res.data.data.invoice_id)
            updateAndPrev
              ? navigate(`/invoicehajj/details/${res.data.data.invoice_id}`, {
                  state: location,
                })
              : navigate('/invoicehajj');
        });
      } else {
        message.error(res?.data?.data);
      }
    });
  };

  const onFinishFailed = (values: any) => {
    toasterNotification(
      'error',
      values.errorFields.length + ' Required fields!'
    );
  };
  const [form] = Form.useForm();
  const { data: invoiceNo, refetch: invoiceNoRefetch } =
    useGetInvoiceNoQuery('IH');

  useEffect(() => {
    form.setFieldsValue({
      invoice_no: invoiceNo?.data,
      invoice_sales_date: dayjs(),
    });
  }, [invoiceNo]);

  const invoice_net_total = useWatch('invoice_net_total', form);
  return (
    <>
      {postLoading && <LoadingIndicator />}
      <BreadCrumb arrOfOption={['Invoice Hajj']} />
      {/*  */}
      <Form
        name='basic'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <NewAddHajjFormTop
          form={form}
          invoice_agent_com_amountFieldName={['invoice_agent_com_amount']}
          invoice_net_totalFieldName={['invoice_net_total']}
        />

        <NewAddHajjPilgrimsInfo form={form} />
        <NewAddhajjFormHotelInfo form={form} />
        <NewAddHajjFormTransportInfo form={form} />
        <InvoiceBillingInfo form={form} setProductId={116} conditionalCostVendor/>

        <InvoiceSubTotal form={form} />
        <InvoiceMoneyReceipt
          form={form}
          netTotal={Number(invoice_net_total || 0)}
        />

        {/* @AIRTICKET EIDT BUTTON */}
        <Row gutter={[10, 10]}>
          <Col>
            <FormButton
              loading={!updateAndPrev && (postLoading || limiLoading)}
              label='CREATE'
              onClick={() => {
                setUpdateAndPrev(false);
              }}
            />
          </Col>
          <Col>
            <FormButton
              loading={updateAndPrev && (postLoading || limiLoading)}
              label='CREATE & PREVIEW'
              onClick={() => {
                setUpdateAndPrev(true);
              }}
            />
          </Col>
        </Row>
      </Form>
      {postLoading && <LoadingIndicator />}
    </>
  );
};

export default NewInvoiceAddHajj;
