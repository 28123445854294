import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { CLIENTWISECOLLECTIONANDSALES } from '../../Api/constants';
import { IClientWiseCollectionAndSalesReportDataType } from '../types/clientWiseCollectionAndSalesReportTypes';

export const clientWiseCollectionAndSalesEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all client wise collection by id
    getClientWiseCollect_SalesbyID: build.query<
      HTTPResponse<IClientWiseCollectionAndSalesReportDataType>,
      {
        client_id: string;
        employee_id: string;
        from: string;
        to: string;
        page: number;
        size: number;
      }
    >({
      query: ({ client_id, from, page, size, to, employee_id }) => ({
        url: `/report/sales-collection/client?from_date=${from}&to_date=${to}&page=${page}&size=${size}`,
        method: 'POST',
        body: {
          client_id: client_id,
          employee_id: employee_id,
        },
      }),
      providesTags: [
        {
          type: 'ClientWiseCollectionAndSales',
          id: CLIENTWISECOLLECTIONANDSALES,
        },
      ],
    }),

    // Only for Sales Report by id
    getSalesID: build.query<
      HTTPResponse<IClientWiseCollectionAndSalesReportDataType>,
      {
        client_id: string;
        from: string;
        to: string;
        page: number;
        size: number;
      }
    >({
      query: ({ client_id, from, page, size, to }) => ({
        url: `/report/sales-collection/client?from_date=${from}&to_date=${to}&page=${page}&size=${size}`,
        method: 'POST',
        body: {
          client_id: client_id,
        },
      }),
      providesTags: [
        {
          type: 'ClientWiseCollectionAndSales',
          id: CLIENTWISECOLLECTIONANDSALES,
        },
      ],
    }),

    getExcelsClinetWiseCollandSales: build.query<
      any,
      {
        client_id: string;
        query: string;
        excelName?: string;
        excelApiName: string;
      }
    >({
      query: ({ client_id, query, excelName, excelApiName }) => ({
        url: `/report/${excelApiName}${query}`,
        method: 'POST',
        body: {
          client_id: client_id,
        },
        responseHandler: async (response) => {
          const filename = `${excelName}.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
      providesTags: [
        { type: 'invoice-other' },
        { type: 'invoices' },
        { type: 'invoice-visa' },
      ],
    }),
  }),
});

export const {
  useLazyGetClientWiseCollect_SalesbyIDQuery,
  useGetClientWiseCollect_SalesbyIDQuery,
  useGetSalesIDQuery,
  useLazyGetExcelsClinetWiseCollandSalesQuery,
} = clientWiseCollectionAndSalesEndpoints;
