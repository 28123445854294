import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IPermission } from '../../../../common/types/commonTypes';
import { rangePresets } from '../../../../common/utils/common.utils';
import { removeSpaceBeforeWord } from '../../../../components/common/Invoice/InvoiceListHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllAgentMoneyReceiptQuery } from '../api/endpoints/AgentMoneyReceiptEndPoint';
import { AgentMoneyReceipttils } from '../utils/AgentMoneyReceiptUtils';
type Props = { permission?: IPermission };

const ListofAgentMoneyReceipt = ({ permission }: Props) => {
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const { data, isLoading, isFetching, refetch } =
    useGetAllAgentMoneyReceiptQuery({
      ...pagination,
      from: date[0] || '',
      to: date[1] || '',
      search: search,
    });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 50,
    }));
  }, [date, search]);

  // Handle Search Operation
  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Agent Money Receipts']}
        refetch={() => refetch()}
        reloaderSize='small'
      />

      <Row justify='space-between' style={{ marginBottom: '1rem' }}>
        {permission?.['create:any'] && (
          <Link to='/agent_money_receipt/add' state={location.pathname}>
            <Button type='primary'>
              <PlusOutlined />
              Add Agent Money Receipt
            </Button>
          </Link>
        )}

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Here...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        size='small'
        bordered
        columns={AgentMoneyReceipttils({ permission, pagination })}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        scroll={{ x: true }}
        dataSource={data?.data}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
      />
    </div>
  );
};

export default ListofAgentMoneyReceipt;
