import { Form, Input } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Fixed2 } from '../../../../../common/utils/common.utils';
import { IAirTicketInfoByNo } from '../../../RefundTypes/RefundTypes';
import { FormInstance } from 'antd/lib';
import { useWatch } from 'antd/es/form/Form';

export const AirTicketInfoUtils = (
  onTableClientRefundChange: Function,
  onTableVendorRefundChange: Function
  // onTableDiscountAdjust: Function,
  // invoice_discount: number | undefined,
  // tableForm: FormInstance<any>
): ColumnsType<IAirTicketInfoByNo> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Ticket No',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
    },
    {
      title: 'PNR',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
    },

    {
      title: 'Sales',
      dataIndex: 'airticket_client_price',
      key: 'airticket_client_price',
      render: (curr) => Fixed2(curr),
    },

    {
      title: 'Purchase',
      dataIndex: 'airticket_purchase_price',
      key: 'airticket_purchase_price',
      render: (curr) => Fixed2(curr),
    },
    {
      title: 'Profit',
      dataIndex: 'airticket_profit',
      key: 'airticket_profit',
      render: (airticket_profit) => Fixed2(airticket_profit),
    },

    {
      title: 'Client Refund Charge',
      key: 'operation',
      dataIndex: 'client_charge',
      width: 200,
      render: (text, record, index) => (
        <Form.Item
          label={[]}
          name={[index, 'single_client_charge']}
          rules={[
            {
              validator: (_, value) => {
                if (value && record.airticket_client_price) {
                  if (value > Number(record.airticket_client_price)) {
                    return Promise.reject(
                      'Client charge cannot be more than sell price'
                    );
                  } else {
                    return Promise.resolve();
                  }
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input
            value={text}
            placeholder='Client Refund Charge'
            onChange={(v) => onTableClientRefundChange(v, index, record)}
            type={'number'}
          />
        </Form.Item>
      ),
      shouldCellUpdate: (record, prevRecord) => {
        return record.client_charge !== prevRecord.client_charge;
      },
    },
    /*     {
      title: 'Adjust Discount',
      width: 200,
      render: (text, record, index) => (
        <AdjustDiscountInput
          index={index}
          record={record}
          tableForm={tableForm}
          invoice_discount={invoice_discount}
          onTableDiscountAdjust={onTableDiscountAdjust}
        />
      ),
      shouldCellUpdate: (record, prevRecord) => {
        return (
          record.vrefund_charge_amount !== prevRecord.vrefund_charge_amount
        );
      },
    }, */

    {
      title: 'Vendor Refund Charge',
      key: 'operation',
      dataIndex: 'vrefund_charge_amount',
      width: 200,
      render: (text, record, index) => {
        return (
          <Form.Item
            label={[]}
            name={[index, 'single_vendor_charge']}
            rules={[
              {
                validator: (_, value) => {
                  if (value && record.airticket_purchase_price) {
                    if (value > Number(record.airticket_purchase_price)) {
                      return Promise.reject(
                        'Vendor charge cannot be more than purchase price'
                      );
                    } else {
                      return Promise.resolve();
                    }
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input
              placeholder='Vendor Refund Charge'
              value={text}
              onChange={(v) => onTableVendorRefundChange(v, index, record)}
              type={'number'}
            />
          </Form.Item>
        );
      },
      shouldCellUpdate: (record, prevRecord) => {
        return (
          record.vrefund_charge_amount !== prevRecord.vrefund_charge_amount
        );
      },
    },
  ];
};
const AdjustDiscountInput = ({
  index,
  record,
  tableForm,
  invoice_discount,
  onTableDiscountAdjust,
}: {
  index: any;
  record: any;
  tableForm: any;
  invoice_discount: any;
  onTableDiscountAdjust: any;
}) => {
  interface FormValues {
    [key: string]: {
      single_adjust_discount?: number;
    };
  }
  const data = useWatch([index, 'single_adjust_discount'], tableForm);

  return (
    <Form.Item
      label={[]}
      name={[index, 'single_adjust_discount']}
      rules={[
        {
          validator: (_, value) => {
            const allValue: FormValues = tableForm?.getFieldsValue() || {};
            const sum = Object?.values(allValue)?.reduce((acc, curr) => {
              if (curr?.single_adjust_discount) {
                return acc + Number(curr.single_adjust_discount);
              }
              return acc;
            }, 0);
            if (value && invoice_discount) {
              if (sum > Number(invoice_discount)) {
                return Promise.reject(
                  'Discount cannot be more than overall discount'
                );
              } else {
                return Promise.resolve();
              }
            } else {
              return Promise.resolve();
            }
          },
        },
      ]}
    >
      <Input
        placeholder='Adjust Client Discount'
        value={data}
        onChange={(v) => onTableDiscountAdjust(v, index, record)}
        type={'number'}
      />
    </Form.Item>
  );
};
