import {
  FormListFieldData,
  Col,
  Select,
  Row,
  Form,
  Card,
  InputNumber,
} from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { useEffect, useRef, useState } from 'react';
import SelectPaymentMethod from '../../../../components/common/paymentMethod/SelectPaymentMethod';
import { IaccountsByMethod } from '../../../Accounts/AccountsTypes/AccountsTypes';
import {
  useLazyGetAllAccountsByTypeQuery,
  useLazyGetSingleAccountQuery,
} from '../../../Accounts/Api/Endpoints/accountsEndpoints';
import { payment_type } from '../../../Loan_Management/LoanTypes/LoanTypes';
import { IAirTicketInfoByNo, refund_type } from '../../RefundTypes/RefundTypes';
import dayjs from 'dayjs';
import {
  DateInput,
  FormInputItem,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import { Fixed2 } from '../../../../common/utils/common.utils';

type Props = {};

const AirticketClientInfo = ({
  field,
  form,
  airticketClientInitialValues,
  clientRefundTotal,
  clientRefundChargeTotal,
}: {
  field: FormListFieldData;
  form: FormInstance<any>;
  airticketClientInitialValues?: IAirTicketInfoByNo[];
  clientRefundTotal?: number;
  clientRefundChargeTotal?: number;
}) => {
  const [clientRefundMethod, setClientRefundMethod] =
    useState<string>('ADJUST');
  const [paymentType, setPaymentType] = useState<string>();
  const isChanged = useRef(false);
  const [paymentMethod, setPaymentMethod] = useState<string>();
  const [accountID, setAccountID] = useState<number>();
  const [balance, setBalance] = useState<number>();
  let vrefund_total_charge_amount = 0;
  airticketClientInitialValues?.map((item) => {
    vrefund_total_charge_amount += item.vrefund_charge_amount;
  });

  const [fetchAccountsByPayment, { data: accountsByPayment }] =
    useLazyGetAllAccountsByTypeQuery();

  useEffect(() => {
    if (paymentMethod) {
      fetchAccountsByPayment(Number(paymentMethod));
    }
    if (isChanged.current && form) {
      form.setFields([
        {
          name: ['client_refund_info', 'account_id'],
          value: undefined,
        },
      ]);
      setBalance(undefined);
      return;
    }
  }, [paymentType, paymentMethod]);

  useEffect(() => {
    form.setFields([
      {
        name: ['client_refund_info', field.name, 'payment_method'],
        value: undefined,
      },

      {
        name: ['client_refund_info', field.name, 'crefund_account_id'],
        value: undefined,
      },
    ]);
  }, [clientRefundMethod]);

  const [fetchBalance, { data: accountBalance }] =
    useLazyGetSingleAccountQuery();

  useEffect(() => {
    if (accountID) {
      fetchBalance(accountID);
    }
  }, [accountID]);

  useEffect(() => {
    setBalance(accountBalance?.data && accountBalance?.data.amount);
  }, [accountBalance]);

  useEffect(() => {
    if (
      airticketClientInitialValues?.length
      // &&
      // Number(airticketClientInitialValues[0].last_balance) >= 0
    ) {
      form.setFields([
        {
          name: ['client_refund_info', field.name, 'advance_amount'],
          value:
            clientRefundTotal != undefined &&
            clientRefundChargeTotal != undefined &&
            Fixed2(clientRefundTotal - clientRefundChargeTotal),
        },
      ]);
    }
    if (
      Number(
        airticketClientInitialValues?.length &&
          airticketClientInitialValues[0]?.last_balance
      ) < 0
    ) {
      form.setFields([
        {
          name: ['client_refund_info', field.name, 'crefund_adjust'],
          value:
            clientRefundTotal != undefined &&
            clientRefundChargeTotal != undefined &&
            Fixed2(clientRefundTotal - clientRefundChargeTotal),
        },
      ]);
    }
    form.setFields([
      {
        name: ['client_refund_info', field.name, 'crefund_date'],
        value: dayjs(),
      },
    ]);
    form.setFields([
      {
        name: ['client_refund_info', field.name, 'crefund_total_amount'],
        value: clientRefundTotal,
      },
    ]);
    form.setFields([
      {
        name: ['client_refund_info', field.name, 'crefund_charge_amount'],
        value: clientRefundChargeTotal,
      },
    ]);
    form.setFields([
      {
        name: ['client_refund_info', field.name, 'crefund_return_amount'],
        value:
          clientRefundTotal != undefined &&
          clientRefundChargeTotal != undefined &&
          Fixed2(clientRefundTotal - clientRefundChargeTotal),
      },
    ]);
    if (
      airticketClientInitialValues?.length &&
      Number(airticketClientInitialValues[0]?.last_balance) < 0
    ) {
      form.setFields([
        {
          name: ['client_refund_info', field.name, 'crefund_payment_type'],
          value: 'ADJUST',
        },
      ]);
    }
    form.setFields([
      {
        name: ['client_refund_info', field.name, 'vrefund_charge_amount'],
        value: vrefund_total_charge_amount,
      },
    ]);
  }, []);
  return (
    <Card size='small'>
      <>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <Col span={5} xs={24} sm={24} md={24} lg={8}>
            <Form.Item
              label='Client Refund Type'
              name={[field.name, 'crefund_payment_type']}
              rules={[
                {
                  required: true,
                  message: 'Client Refund Type is required',
                },
              ]}
              initialValue='ADJUST'
            >
              <Select
                allowClear
                placeholder='Select Client Refund Type'
                style={{ width: '100%' }}
                onChange={(e: string) => {
                  setClientRefundMethod(e);
                }}
              >
                {refund_type?.map((item) => {
                  return (
                    <Select.Option
                      key={item}
                      value={item == 'Money Return' ? 'MONEY_RETURN' : 'ADJUST'}
                    >
                      {item}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          {clientRefundMethod == 'ADJUST' ? (
            <NumberInput
              name={[field.name, 'advance_amount']}
              label='Advance Amount'
              readOnly
              size={8}
              min='0'
              maxChar={14}
              minChar={0}
            />
          ) : (
            <>
              {paymentType == 'CHEQUE' ? (
                <>
                  <FormInputItem
                    label='Cheque No.'
                    required
                    name={[field.name, 'cheque_no']}
                    size={8}
                  />
                  <DateInput
                    label='Withdraw Date'
                    name={[field.name, 'withdraw_date']}
                    required
                    size={8}
                  />
                  <FormInputItem
                    label='Bank Name'
                    name={[field.name, 'bank_name']}
                    required
                    size={8}
                  />
                  <NumberInput
                    name={[field.name, 'crefund_return_amount']}
                    label='Return Amount'
                    readOnly
                    size={8}
                    min='0'
                    maxChar={14}
                    minChar={0}
                  />
                  <DateInput
                    label='Date'
                    name={[field.name, 'crefund_date']}
                    required
                    size={8}
                  />
                  <FormInputItem
                    label='Note'
                    name={[field.name, 'crefund_note']}
                    size={8}
                  />
                </>
              ) : (
                <>
                  <SelectPaymentMethod
                    name={[field.name, 'payment_method']}
                    label='Payment Method'
                    size={8}
                    onChange={(value: string) => {
                      setPaymentMethod(value);
                      if (!isChanged.current) isChanged.current = true;
                    }}
                  />
                  <Col span={6} xs={24} sm={24} md={24} lg={8}>
                    <Form.Item
                      name={[field.name, 'crefund_account_id']}
                      label='Account'
                      rules={[
                        {
                          required: true,
                          message: `Account is required!`,
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder={'Select Account'}
                        optionFilterProp='children'
                        onSelect={(e: number) => {
                          setAccountID(e);
                        }}
                      >
                        {accountsByPayment?.data?.map(
                          (item: IaccountsByMethod) => {
                            return (
                              <Select.Option
                                key={item.account_id}
                                value={item.account_id}
                              >
                                {`${item.account_name}`}
                              </Select.Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  <NumberInput
                    name={[field.name, 'crefund_return_amount']}
                    label='Return Amount'
                    readOnly
                    size={8}
                    min='0'
                    maxChar={14}
                    minChar={0}
                  />
                  <Col span={6} xs={24} sm={24} md={24} lg={8}>
                    <Form.Item label='Available Balance' required>
                      <InputNumber
                        style={{ width: '100%' }}
                        readOnly
                        value={balance}
                      />
                    </Form.Item>
                  </Col>
                  {Number(paymentMethod) === 3 ? (
                    <Col span={6} xs={24} sm={24} md={24} lg={8}>
                      <Form.Item
                        label='Transaction Charge'
                        name={[field.name, 'trxn_charge_amount']}
                        required
                      >
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  ) : (
                    <></>
                  )}
                  <DateInput
                    label='Date'
                    name={[field.name, 'crefund_date']}
                    required
                    size={8}
                  />
                  <FormInputItem
                    label='Note'
                    name={[field.name, 'crefund_note']}
                    size={8}
                  />
                </>
              )}
            </>
          )}
        </Row>
      </>
    </Card>
  );
};

export default AirticketClientInfo;
