import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { ICombineClientLedger } from '../types/clientLedgerTypes';

export const CombinedLedger = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}, isForPrint?: boolean): ColumnsType<ICombineClientLedger> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((queryData.current || 1) - 1) * (queryData.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Trxn Date',
      key: 'comtrxn_create_at',
      render: (_, record) => {
        return `${dayjs(record.comtrxn_create_at).format('DD-MMM-YYYY')}`;
      },
      align: 'center',
    },

    {
      title: 'Particulars',
      dataIndex: 'trxntype_name',
      key: 'trxntype_name',

    },

    {
      title: 'Voucher No',
      dataIndex: 'comtrxn_voucher_no',
      key: 'comtrxn_voucher_no',
      align: 'center',
    },

    {
      title: 'Ticket No.',
      dataIndex: 'comtrxn_airticket_no',
      key: 'comtrxn_airticket_no',
      align: 'center',
    },

    {
      title: 'Pax Name',
      dataIndex: 'comtrxn_pax',
      key: 'comtrxn_pax',
      align: 'center',
    },

    {
      title: 'PNR',
      dataIndex: 'comtrxn_pnr',
      key: 'comtrxn_pnr',
      align: 'center',
    },

    {
      title: 'Route',
      dataIndex: 'comtrxn_route',
      align: 'center',
      key: 'comtrxn_route',
    },

    {
      title: 'Pay Type',
      dataIndex: 'comtrxn_pay_type',
      key: 'comtrxn_pay_type',
      align: 'center',
    },

    {
      title: 'Debit',
      align: 'center',
      dataIndex: 'debit_amount',
      key: 'debit_amount',
      render: (_, data) => (
        <>
          {data.comtrxn_type === 'DEBIT' && (
            <Typography.Text>
              <span style={{ color: 'red' }}>
                {Fixed2(data.comtrxn_amount)}
              </span>
            </Typography.Text>
          )}
        </>
      ),
    },
    {
      title: 'Credit',
      align: 'center',
      dataIndex: 'credit_amount',
      key: 'credit_amount',
      render: (_, data) => (
        <>
          {data.comtrxn_type === 'CREDIT' && (
            <Typography.Text>
              <span style={{ color: 'green' }}>
                {Fixed2(data.comtrxn_amount)}
              </span>
            </Typography.Text>
          )}
        </>
      ),
    },

    {
      title: 'Balance',
      align: 'center',
      dataIndex: 'comtrxn_lbalance',
      key: 'comtrxn_lbalance',
      sorter: (a, b) => Number(a.comtrxn_lbalance) - Number(b.comtrxn_lbalance),

      render: (amount) => (
        isForPrint ?
          <>
            <Typography.Text
              style={{ color: parseInt(amount) > 0 ? 'green' : 'red' }}
            >
              {Number(amount) === 0 ? '' : Number(amount) > 0 ? '' : '-'}
              {Fixed2(Math.abs(Number(amount)))}
            </Typography.Text>
          </> : <>
            <Typography.Text
              style={{ color: parseInt(amount) > 0 ? 'green' : 'red' }}
            >
              {Fixed2(Math.abs(Number(amount)))}
            </Typography.Text>
          </>
      ),
    },

    {
      title: 'Note',
      dataIndex: 'comtrxn_note',
      key: 'comtrxn_note',
      align: 'center',
      render: (_, value) => {
        return (
          <span
            style={{ maxWidth: 250, fontSize: '12px', textAlign: 'justify' }}
          >
            {value.comtrxn_note}
          </span>
        );
      },
    },

    {
      title: 'Created Date',
      key: 'comtrxn_create_date',
      render: (_, record) => {
        return `${dayjs(record.comtrxn_create_date).format('DD-MMM-YYYY')}`;
      },
      align: 'center',
    },
  ];
};
