import { Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';

type Props = {
  title: string;
  mt?: number;
  link?: string;
  linkTitle?: string;
  mb?: string;
  state?: string;
  extra?: React.JSX.Element;
};

const DashboardTitle = ({
  title,
  mt = 0,
  link,
  mb,
  linkTitle,
  state,
  extra,
}: Props) => {


  return (
    <Row
      justify={'space-between'}
      style={{
        marginBottom: mb || 10,
        alignItems: 'center',
        marginTop: mt,
        display: 'flex',
        justifyContent: 'space-between',
        // background: color,
        background: 'linear-gradient(30deg, #239ed8, #1c79be)',
        padding: '5px 20px',
        borderRadius: 4,
      }}
    >
      <Typography.Text
        strong
        style={{ fontSize: '16px', color: '#fff', fontWeight: 'bold' }}
      >
        {title.toLocaleUpperCase()}
      </Typography.Text>

      <Row align={'middle'} justify={'center'} gutter={extra ? 12 : 0}>
        <Col>{extra && extra}</Col>
        <Col>
          {link && (
            <Link to={link} state={state && state}>
              <span
                style={{ fontSize: 14, fontFamily: 'unset', color: '#fff' }}
              >
                {linkTitle || 'View All'}
              </span>
            </Link>
          )}
        </Col>
      </Row>
    </Row>
  );
};

export default DashboardTitle;
