import { ColumnsType } from 'antd/lib/table';
import { IAirTicketInfoByNo } from '../../../RefundTypes/RefundTypes';
import { Fixed2 } from '../../../../../common/utils/common.utils';

export const ClientRefundChargeUtils = (): ColumnsType<IAirTicketInfoByNo> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
    },

    {
      title: 'Sales Price',
      dataIndex: 'airticket_client_price',
      key: 'airticket_client_price',
      render: (_, data) =>
        Fixed2(data.airticket_client_price) -
        Fixed2(data.adjust_client_discount),
    },

    {
      title: 'Return Charge From Client',
      key: 'client_charge',
      dataIndex: 'client_charge',
    },
  ];
};
