import { Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import FormListAddRemove from '../../../../common/utils/FormListAddRemove';
import OtherTransportForm from './OtherTransportForm';
import { IInvoiceTourOtherTrans } from '../../Types/InvoiceTourTypes';
import BillingIsDeleted from '../../../Invoice(Visa)/Components/BillingIsDeleted';

type Props = {
  form: FormInstance<any>;
  invComClientInfo?: string;
  tourOther?: IInvoiceTourOtherTrans[] | undefined;
};

const InvoiceTourOtherTransportForm = ({
  form,
  invComClientInfo,
  tourOther,
}: Props) => {
  return (
    <>
      <Form.List name='tourOtherTrans' initialValue={[{}]}>
        {(fields, { add, remove }) =>
          fields.map((field, index) => {
            return (
              <Row
                gutter={[10, 0]}
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyItems: 'center',
                }}
              >
                <Col md={23}>
                  <OtherTransportForm form={form} field={field} index={index} />
                </Col>
                <Col md={1}>
                  <BillingIsDeleted
                    name={[field.name, 'other_trans_is_deleted']}
                  />
                  <FormListAddRemove
                    add={add}
                    index={index}
                    name={field.name}
                    remove={remove}
                    form={form}
                    dataLength={tourOther?.length}
                    NamePath={[
                      'tourOtherTrans',
                      field.name,
                      'other_trans_is_deleted',
                    ]}
                  />
                </Col>
              </Row>
            );
          })
        }
      </Form.List>
    </>
  );
};

export default InvoiceTourOtherTransportForm;
