import { message } from 'antd';
import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { expire } from '../../../../common/utils/constants';
import { COMPANIES } from '../../../Configuration/companies/Endpoints/companiesEndpoints';
import {
  IAccountBalance,
  IAccountByType,
  IAccountCategoryPaymentType,
  IAccountCompanies,
  IAccountDataType,
  IAccountStateFormType,
  IAccountStatement,
  IAllAccountType,
  ICreateAccountEditFormData,
  ICreateAccountFormData,
  ITransferableAccount,
} from '../../AccountsTypes/AccountsTypes';
import { ACCOUNT_LIST, OPENING_BALANCE } from '../Constants/constants';

export const accountsEndpoint = api.injectEndpoints({
  endpoints: (build) => ({
    // get all accounts
    getAccounts: build.query<
      HTTPResponse<IAccountDataType[]>,
      { pageSize: number; current: number; search?: string }
    >({
      query: (arg) => ({
        url: `/accounts/all?&page=${arg.current}&size=${arg.pageSize}&search=${
          arg.search || ''
        }`,
      }),
      providesTags: [
        { type: 'Accounts', id: ACCOUNT_LIST },
        { type: 'Opening_Balance', id: OPENING_BALANCE },
      ],
    }),
    // get all accounts
    getAllAccounts: build.query<HTTPResponse<IAllAccountType[]>, void>({
      query: () => ({ url: `/accounts/account-list` }),
      providesTags: [
        { type: 'Accounts', id: ACCOUNT_LIST },
        { type: 'Opening_Balance', id: OPENING_BALANCE },
      ],
    }),

    // get single account by account-id
    getSingleAccount: build.query<HTTPResponse<IAccountBalance>, number>({
      query: (id) => ({ url: `/accounts/single-account/${id}` }),
      keepUnusedDataFor: expire.min,
      providesTags: (_res, _err, id) => [{ type: 'Accounts', id: id } as const],
    }),

    //get all accounts category type
    getAccountsCategoryType: build.query<
      HTTPResponse<IAccountCategoryPaymentType[]>,
      void
    >({ query: () => ({ url: `/accounts/account-category` }) }),

    //get all companies
    getAllCompanies: build.query<HTTPResponse<IAccountCompanies[]>, void>({
      query: () => ({ url: `/accounts/get-companies` }),
      providesTags: () => [{ type: 'Companies', id: COMPANIES }],
    }),

    //get all accounts by type
    getAllAccountsByType: build.query<HTTPResponse<IAccountByType[]>, number>({
      query: (id) => ({ url: `/accounts/account-by-type/${id}` }),
      keepUnusedDataFor: expire.min,
      providesTags: (_res, _err, id) => [
        { type: 'Non_Invoice_Income', id: id } as const,
      ],
    }),
    //get all transferable accounts
    GetAllTrsAcc: build.query<HTTPResponse<ITransferableAccount[]>, void>({
      query: () => ({ url: `/accounts/transferable-accounts` }),
      providesTags: [{ type: 'Accounts', id: ACCOUNT_LIST }],
    }),

    //get account statement
    getAccountStatement: build.query<
      HTTPResponse<IAccountStatement[]>,
      IAccountStateFormType
    >({
      query: (body) => ({
        url: `/accounts/account-statement/${body.id}${body.date_range}&trash=0&page=${body.current}&size=${body.pageSize}`,
      }),
      providesTags: [{ type: 'Accounts' } as const],
    }),

    //create account
    createAccount: build.mutation<void, ICreateAccountFormData>({
      query: (body) => ({
        url: '/accounts/create',
        method: 'POST',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Accounts', id: ACCOUNT_LIST }],
    }),

    //edit account
    editAccount: build.mutation<void, ICreateAccountEditFormData>({
      query: (body) => ({
        url: `/accounts/edit/${body.account_id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: () => [{ type: 'Accounts', id: ACCOUNT_LIST }],
    }),

    //delete account
    deleteAccount: build.mutation<
      void,
      {
        delete_by: number;
        id: number;
      }
    >({
      query: ({ delete_by, id }) => ({
        url: `/accounts/${id}`,
        method: 'DELETE',
        body: { delete_by },
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Account Deleted Successfully');
        });
      },
      invalidatesTags: () => [{ type: 'Accounts', id: ACCOUNT_LIST }],
    }),
  }),
});

export const {
  useGetAllAccountsQuery,
  useGetAccountsQuery,
  useLazyGetAccountsQuery,
  useLazyGetSingleAccountQuery,
  useGetSingleAccountQuery,
  useGetAccountsCategoryTypeQuery,
  useGetAllTrsAccQuery,
  useGetAllCompaniesQuery,
  useGetAccountStatementQuery,
  useLazyGetAccountStatementQuery,
  useDeleteAccountMutation,
  useCreateAccountMutation,
  useEditAccountMutation,
  useLazyGetAllAccountsByTypeQuery,
} = accountsEndpoint;
