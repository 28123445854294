import { Button, Space, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import useGetSearchColumnProps from '../../../common/hooks/SearchData';
import { ISetState } from '../../../common/types/commonTypes';
import { IAllChequeDataType } from '../types/ChequeManagementTypes';

type Props = {};

export const Cheque_Column = (
  setDepositInfo: ISetState<IAllChequeDataType | null>,
  showDepositModal: () => void
): ColumnsType<IAllChequeDataType> => {
  const searchColumnProps = useGetSearchColumnProps<IAllChequeDataType>();

  const setDeposit = (value: any) => {
    setDepositInfo(value);
    showDepositModal();
  };

  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'serial',
    },

    {
      title: 'Withdraw Date',
      key: 'withdraw_date',
      render: (_: number, record: any) => {
        return `${dayjs(record.withdraw_date).format('DD MMM YYYY')}`;
      },
    },

    {
      title: 'Cheque No',
      dataIndex: 'cheque_number',
      key: 'cheque_number',
      ...searchColumnProps('cheque_number'),
    },

    {
      title: 'Cheque Type',
      dataIndex: 'cheque_type',
      key: 'cheque_type',

      render: (cheque_type) => {
        return getChequeType(cheque_type);
      },
    },
    {
      title: 'Amount',
      dataIndex: 'cheque_amount',
      key: 'cheque_amount',
      ...searchColumnProps('cheque_amount'),
    },

    {
      title: 'Bank Name',
      dataIndex: 'bank_name',
      key: 'bank_name',
      ...searchColumnProps('bank_name'),
    },
    {
      title: 'Status',
      dataIndex: 'cheque_status',
      key: 'cheque_status',
      render: (_, data) => {
        let color = data.cheque_status === 'PENDING' ? 'volcano' : 'green';
        return <Tag color={color}>{data.cheque_status}</Tag>;
      },
    },

    {
      title: 'Action',
      key: 'operation',
      render: (_, record) => {
        function depositedCheque() {
          if (
            record.cheque_status == 'DEPOSIT' ||
            record.cheque_status == 'RETURN' ||
            record.cheque_status == 'BOUNCE'
          ) {
            return true;
          }
          return false;
        }

        return (
          <Space size='small'>
            <Button
              type='primary'
              size='small'
              disabled={depositedCheque()}
              onClick={() => setDeposit(record)}
            >
              Change Status
            </Button>
          </Space>
        );
      },
    },
  ];
};

export function getChequeType(cheque_type: string) {
  if (cheque_type == 'EXPENSE') {
    return 'Expense';
  } else if (cheque_type == 'MR_ADVR') {
    return 'Money Receipt Advance Return';
  } else if (cheque_type == 'LOAN') {
    return 'Loan';
  } else if (cheque_type == 'LOAN_PAYMENT') {
    return 'Loan Payment';
  } else if (cheque_type == 'LOAN_RECEIVED') {
    return 'Loan Received';
  } else if (cheque_type == 'MONEY_RECEIPT') {
    return 'Money Receipt';
  } else if (cheque_type == 'PAYROLL') {
    return 'Payroll';
  } else if (cheque_type == 'VENDOR_ADVR') {
    return 'Vendor Advance Return';
  } else if (cheque_type == 'VENDOR_PAYMENT') {
    return 'Vendor Payment';
  }
  return cheque_type;
}
