import { Button, Col, Row, Typography } from 'antd';
import { useMakeDbBackupMutation } from '../Api/Db_dowloadEndpoint';

const Db_backup = () => {
  const [downloadDB] = useMakeDbBackupMutation();

  return (
    <Row style={{ height: '70vh' }} align={'middle'} justify={'center'}>
      <div>
        <Col>
          <Typography.Title level={4}>
            Your Database Ready For Download
          </Typography.Title>
        </Col>

        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Button onClick={() => downloadDB('')} type='primary'>
            Download Now !!!
          </Button>
        </Col>
      </div>
    </Row>
  );
};

export default Db_backup;
