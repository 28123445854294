import React from 'react';
import { Col, Form, Select } from 'antd';
import { ITourFoodInfo } from '../../../RefundTypes/RefundTypes';
import { commonProps } from '../../../../../common/types/commonTypes';

type PropsType = {
  foodData?: ITourFoodInfo[];
} & commonProps;

export const SelectFoodRefund = ({
  name,
  label,
  size,
  required,
  disabled,
  foodData,
}: PropsType) => {
  const tourInvoiceDataChildren: React.ReactNode[] = [];
  if (foodData) {
    for (let i = 0; i < foodData.length; i++) {
      tourInvoiceDataChildren.push(
        <Select.Option
          key={foodData[i].itinerary_id}
          value={foodData[i].itinerary_id}
        >
          {`${foodData[i].itinerary_particular}`}
        </Select.Option>
      );
    }
  }
  return (
    <Col span={6} xs={24} sm={24} md={24} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required,
            message: 'Select Food Itenery is required',
          },
        ]}
      >
        <Select
          allowClear
          disabled={disabled}
          placeholder={label}
          showSearch
          style={{ padding: '0', margin: '0', border: '0' }}
          optionFilterProp='roleMobile'
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          mode='multiple'
        >
          {tourInvoiceDataChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};
