import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IPermission } from '../../../../common/types/commonTypes';
import { removeSpaceBeforeWord } from '../../../../components/common/Invoice/InvoiceListHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllAirTicketRefundQuery } from '../../Api/Endpoints/AirTicketRefundEndpoints';
import { AirTicketViewUtils } from '../../Utils/AirTicket Utils/AirTicketViewUtils';

import {
  filterNullColumns,
  rangePresets,
} from '../../../../common/utils/common.utils';
import AirticketRowDetails from '../../Components/Airticket/AirticketRowDetails';

type Props = { permission?: IPermission };

const AirticketRefundHistory = ({ permission }: Props) => {
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const {
    data,
    isLoading,
    isFetching,
    refetch: RefetchAgain,
  } = useGetAllAirTicketRefundQuery({
    ...pagination,
    from: date[0] || '',
    to: date[1] || '',
    search: search,
  });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 50,
    }));
  }, [search, date]);

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  // Handle Search Operation

  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  const filteredColumn = filterNullColumns(
    data?.data,
    AirTicketViewUtils({
      permission,
      pagination,
    })
  );

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Refund', 'AirTicket Refund']}
        refetch={() => RefetchAgain()}
        reloaderSize='small'
      />

      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 10 }}
        style={{ marginBottom: '25px' }}
        justify={'space-between'}
      >
        <Link to='/refund/airticket'>
          <Button type='primary'>
            <PlusOutlined />
            Add AirTicket Refund
          </Button>{' '}
        </Link>

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='Search Invoices...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <FormHeaderTitle title='AirTicket Refund' /> */}

      <Table
        size='small'
        bordered
        columns={filteredColumn.col}
        dataSource={data?.data}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        expandable={{
          rowExpandable: (record) => true,
          expandedRowRender: (record, index) => {
            return <AirticketRowDetails id={record.atrefund_id} />;
          },
        }}
        rowKey={(record, index) => index || 0}
      />
      {(isLoading || isFetching) && <LoadingIndicator />}
    </div>
  );
};

export default AirticketRefundHistory;
