import { Form, FormInstance } from 'antd';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import { AirTicket_ticket_information } from '../types/invoiceAirTicketTypes';
import AirticketPaxPassportDetailsSub from './AirticketPaxPassportDetailsSub';
// import { isRequired } from "../utils/ts/getIsRequireRule";

type Props = {
  form: FormInstance<any>;
  ticketInfo?: AirTicket_ticket_information[] | any;
  formName: string;
};

export default function AirticketPaxPassportDetails({
  form,
  ticketInfo,
  formName,
}: Props) {
  return (
    <div className='border p-5 mt-10'>
      <FormHeaderTitle title='Pax & Passport Details 🛂' />

      <Form.List name={formName || 'pax_passport'} initialValue={[{}]}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => {
              form.setFieldValue(['pax_passport', index, 'dailCode'], '+88');

              return (
                <AirticketPaxPassportDetailsSub
                  add={add}
                  field={field}
                  form={form}
                  index={index}
                  remove={remove}
                  key={index}
                  ticketInfo={ticketInfo}
                  formName={formName}
                />
              );
            })}
          </>
        )}
      </Form.List>
    </div>
  );
}
