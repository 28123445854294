import { HTTPResponse } from "../../../../common/types/commonTypes";
import { api } from "../../../../app/baseQuery";
import {
  CompanyDataType,
  ICompanyEditFormData,
  ICompanyFormData,
} from "../../companies/types/companyTypes";
import {
  ContactDataType,
  IContact,
  IContactFormData,
} from "../types/contactTypes";

export const CONTACTS = "CONTACTS";
export const contactsEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //  get all contact
    getAllContacts: build.query<
      HTTPResponse<IContact[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/configuration/account-contacts/get-all?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: "Contacts", id: CONTACTS }],
    }),

    //  get all contact with pagination
    getAllContactWithPag: build.query<
      HTTPResponse<ContactDataType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/configuration/account-contacts?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: "Contacts", id: CONTACTS }],
    }),

    //create contact
    createContact: build.mutation<void, IContactFormData>({
      query: (body) => ({
        url: `/configuration/account-contacts/create`,
        method: "POST",
        body: body,
      }),

      invalidatesTags: () => [{ type: "Contacts", id: CONTACTS }],
    }),

    //edit contact
    editContact: build.mutation<void, { body: IContactFormData; id: number }>({
      query: ({ body, id }) => ({
        url: `/configuration/account-contacts/edit-contact/${id}`,
        method: "PATCH",
        body: body,
      }),

      invalidatesTags: () => [{ type: "Contacts", id: CONTACTS }],
    }),

    //delete contact
    deleteContact: build.mutation<void, IContact>({
      query: (body) => ({
        url: `/configuration/account-contacts/delete-contact/${body.account_contact_id}`,
        method: "DELETE",
      }),

      invalidatesTags: () => [{ type: "Contacts", id: CONTACTS }],
    }),
  }),
});

export const {
  useGetAllContactsQuery,
  useCreateContactMutation,
  useEditContactMutation,
  useDeleteContactMutation,
  useGetAllContactWithPagQuery,
} = contactsEndpoints;
