import { Button, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { ISetState } from "../../../../common/types/commonTypes";
import { setModal } from "../../../../common/slices/commonSlice";
import { CompanyDataType } from "../types/companyTypes";
import { useEditCompaniesMutation } from "../Endpoints/companiesEndpoints";
import assyncWrapper from "../../../../common/utils/assyncWrapper";
import LoadingIndicator, {
  loadingIndicator,
} from "../../../../components/common/spinner/LoadingIndicator";

interface IProps {
  companiesNameList: string[];
  info: CompanyDataType;
  setNull: ISetState<CompanyDataType | null>;
}

const EditCompanyModal = ({ companiesNameList, info, setNull }: IProps) => {
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState<CompanyDataType>(info);
  const { TextArea } = Input;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(info);
  }, [info]);

  const [editCompany, { isError, isSuccess, isLoading }] =
    useEditCompaniesMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success("Companies Edited Successfully");
    } else if (isError) {
      message.error("Something went wrong");
    }
  }, [isSuccess, isError]);

  const handleSubmit = async () => {
    assyncWrapper(async () => {
      const values = (await form.getFieldsValue()) as CompanyDataType;
      values.company_id = info.company_id;
      await editCompany({ ...values });
      setNull(null);
      dispatch(setModal(false));
    });
  };
  return (
    <Form
      layout="horizontal"
      labelAlign="left"
      form={form}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleSubmit}
    >
      <Form.Item
        label="Company Name"
        name="company_name"
        rules={[
          { required: true, message: "Please enter company name!" },
          { max: 75, message: "Maximum 65 characters" },
          {
            validator(_, value) {
              if (value) {
                if (
                  companiesNameList
                    .filter((item) => item !== info.company_name)
                    .includes(value.trim())
                ) {
                  return Promise.reject("Companies Name is already Exist");
                } else {
                  return Promise.resolve();
                }
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <Input placeholder="Enter company name" />
      </Form.Item>
      <Form.Item
        label="Contact Person"
        name="company_contact_person"
        rules={[{ max: 75, message: "Maximum 65 characters" }]}
      >
        <Input placeholder="Enter company contact person" />
      </Form.Item>
      <Form.Item label="Designation" name="company_designation">
        <Input placeholder="Enter company designation" />
      </Form.Item>
      <Form.Item
        label="Phone"
        name="company_phone"
        rules={[{ max: 20, message: "Maximum 20 characters" }]}
      >
        <Input placeholder="Enter company phone!" />
      </Form.Item>
      <Form.Item label="Address" name="company_address">
        <TextArea placeholder="Enter company address" maxLength={55} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Update
        </Button>
      </Form.Item>
      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default EditCompanyModal;
