import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Row, Space } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import {
  DateInput,
  FormButton,
  FormInput,
  FormInputItem,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import {
  SelectAgent,
  SelectItem,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import SelectPaymentMethod from '../../../../components/common/paymentMethod/SelectPaymentMethod';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useLazyGetSingleAccountQuery } from '../../../Accounts/Api/Endpoints/accountsEndpoints';
import { useGetAccountByTypeQuery } from '../../../vendor/api/endpoints/vendorPaymentEndpoints';
import AccountLastBalanceInput from '../../../vendor/components/AccountLastBalanceInput';
import { layout } from '../../../vendor/pages/AdvanceReturnAdd';
import {
  useLazyGetAgentByInvoiceIDQuery,
  useLazyGetInvoiceBySearchQuery,
  usePostAgentMoneyReceiptMutation,
} from '../api/endpoints/AgentMoneyReceiptEndPoint';
import { SelectInvoiceByAgentID } from '../FormItem/SelectInvoiceByAgentID';

type Props = {
  permission?: any;
};

const AgentMoneyReceiptForm = ({ permission }: Props) => {
  const { id } = useParams();
  const [form] = Form.useForm();

  const isChanged = useRef(false);
  const [invoiceId, setInvoiceId] = useState<number>();
  const [account, setAccount] = useState<string>();
  const [search, setSearch] = useState('');

  const [payMethodCatId, setPayMethodCatId] = useState<number | undefined>(1);
  const [fetchBalance, { data: accountBalance }] =
    useLazyGetSingleAccountQuery();

  const { data: accounts, refetch: refetchAccount } = useGetAccountByTypeQuery(
    Number(payMethodCatId)
  );

  const LastBalance = accountBalance?.data?.amount || 0;
  useEffect(() => {
    if (account) {
      fetchBalance(Number(account));
    }
  }, [account, payMethodCatId]);

  useEffect(() => {
    form.setFieldsValue({ available_balance: LastBalance });
  }, [account, LastBalance, payMethodCatId]);

  useEffect(() => {
    if (isChanged.current) {
      form.setFieldsValue({ account_id: undefined });
      form.setFieldsValue({ available_balance: undefined });
    }
  }, [payMethodCatId]);

  const [fetchInvoiceInfo, { data: agents }] =
    useLazyGetAgentByInvoiceIDQuery();

  useEffect(() => {
    invoiceId && fetchInvoiceInfo(invoiceId);
    form.setFieldsValue({
      agent_name: agents?.data?.agent_name,
      receipt_agent_amount: agents?.data?.agent_amount,
    });
  }, [invoiceId, agents]);

  const [agentID, setAgentId] = useState<number>(0);
  useEffect(() => {
    form.setFieldValue('invoice_id', undefined);
  }, [agentID]);

  const [fetchInvoice, { data: allInvoice }] = useLazyGetInvoiceBySearchQuery();

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (search) {
      timer = setTimeout(() => {
        fetchInvoice(search);
      }, 300);
    } else {
      fetchInvoice(search);
    }

    return () => clearTimeout(timer);
  }, [search]);

  let invoice_agent_com_amount = 0;

  useEffect(() => {
    form.setFieldValue('receipt_agent_amount', invoice_agent_com_amount);
  }, [invoice_agent_com_amount]);

  // agent on finish handle

  const [postAgentMoney, { isError, isSuccess, isLoading, data }] =
    usePostAgentMoneyReceiptMutation();

  const user = useSelector(selectUser);

  const navigate = useNavigate();
  const agentSubmitHandler = (values: any) => {
    values.receipt_agent_id = agents?.data?.agent_id || values.receipt_agent_id;
    values.receipt_payment_date = dayjs(values?.receipt_payment_date).format(
      'YYYY-MM-DD'
    );
    values.receipt_created_by = user?.user_id;

    postAgentMoney(values);
  };

  useEffect(() => {
    if (isError) {
      message.error('There was an error');
    } else if (isSuccess) {
      navigate(
        `/money-receipt/agents-commission-by-recept-id/${data?.data?.receipt_id}`
      );
      message.success('Agent Money receipt has been added');
    }
  }, [isLoading]);

  useEffect(() => {
    if (!id) {
      form.setFieldValue('receipt_payment_date', dayjs());
    }
  }, []);

  const receipt_payment_to: 'INVOICE' | 'OVERALL' = useWatch(
    'receipt_payment_to',
    form
  );

  const available_balance = useWatch('available_balance', form);

  return (
    <div>
      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/moneyreceipt/agent'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Agent Money Receipt List
          </Button>
        </Link>
      </Space>

      <div className='border p-10'>
        <Form
          layout='horizontal'
          form={form}
          style={{ marginTop: '1rem' }}
          onFinish={agentSubmitHandler}
          labelAlign='left'
          {...layout}
        >
          <Row gutter={[16, 16]} justify='space-between'>
            <>
              <Col lg={11} md={24} sm={24} xs={24}>
                <SelectItem
                  size={24}
                  mdSize={24}
                  label='Payment To:'
                  name='receipt_payment_to'
                  required
                  items={[
                    { value: 'OVERALL', label: 'Over All' },
                    { value: 'INVOICE', label: 'Specific Invoice' },
                  ]}
                />

                {receipt_payment_to === 'INVOICE' && (
                  <SelectInvoiceByAgentID
                    name='invoice_id'
                    label='Select Invoice No.'
                    agentID={agentID}
                    required
                    size={24}
                    mdSize={24}
                    invoiceData={allInvoice?.data}
                    setInvoiceId={setInvoiceId}
                    setSearch={setSearch}
                  />
                )}
                <SelectPaymentMethod
                  size={24}
                  mdSize={24}
                  required
                  name='receipt_payment_type'
                  label='Payment Method '
                  onChange={(value: number) => {
                    setPayMethodCatId(value);
                    if (!isChanged.current) isChanged.current = true;
                  }}
                  cheque
                />

                {Number(payMethodCatId) !== 4 && (
                  <AccountLastBalanceInput
                    size={24}
                    mdSize={24}
                    required
                    onChange={(value: number) => {
                      if (!isChanged.current) isChanged.current = true;
                    }}
                    accountsLastBalance={accounts ? accounts.data : []}
                    onSelect={(value: number) => setAccount(String(value))}
                    name='account_id'
                    label='Account:'
                  />
                )}

                {Number(payMethodCatId) === 2 && (
                  <FormInputItem
                    size={24}
                    mdSize={24}
                    smSize={24}
                    name={'cheque_bank_name'}
                    label='Bank Name'
                  />
                )}

                {Number(payMethodCatId) === 3 && (
                  <FormInputItem
                    size={24}
                    mdSize={24}
                    smSize={24}
                    label='Trans No:'
                    name='trans_no'
                  />
                )}

                {Number(payMethodCatId) === 4 && (
                  <>
                    <FormInputItem
                      size={24}
                      mdSize={24}
                      smSize={24}
                      label='Cheque No'
                      name='cheque_number'
                      required
                    />

                    <DateInput
                      name='cheque_withdraw_date'
                      label='Withdraw Date'
                      required
                      size={24}
                      mdSize={24}
                      smSize={24}
                    />

                    <FormInputItem
                      name={'cheque_bank_name'}
                      label='Bank Name'
                      required
                      size={24}
                      mdSize={24}
                      smSize={24}
                    />
                  </>
                )}

                <DateInput
                  size={24}
                  mdSize={24}
                  smSize={24}
                  required
                  label='Payment Date:'
                  name={'receipt_payment_date'}
                />
              </Col>

              <Col lg={11} md={24} sm={24} xs={24}>
                {receipt_payment_to === 'OVERALL' ? (
                  <SelectAgent
                    name={'receipt_agent_id'}
                    label='Select Agent'
                    size={24}
                    mdSize={24}
                    smSize={24}
                    allowClear
                    offDropDown
                  />
                ) : (
                  <FormInput
                    size={24}
                    mdSize={24}
                    smSize={24}
                    name='agent_name'
                    label='Agent Name'
                    readonly
                  />
                )}
                {Number(payMethodCatId) !== 4 && (
                  <NumberInput
                    label='Available Balance'
                    name='available_balance'
                    readOnly
                    min='0'
                    size={24}
                    mdSize={24}
                    maxChar={14}
                    smSize={24}
                    minChar={0}
                  />
                )}

                <NumberInput
                  size={24}
                  mdSize={24}
                  max={available_balance}
                  smSize={24}
                  name='receipt_agent_amount'
                  label='Receipt Agent Amount'
                  disabled={receipt_payment_to === 'OVERALL' ? false : true}
                />

                <FormInput
                  size={24}
                  mdSize={24}
                  smSize={24}
                  label='Money reciept no'
                  name={'receipt_money_receipt_no'}
                />
              </Col>
            </>
          </Row>

          <FormButton label='Submit' size={6} />
        </Form>
        {isLoading && <LoadingIndicator />}
      </div>
    </div>
  );
};

export default AgentMoneyReceiptForm;
