import { ColumnsType } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';
import { invoiceMoneyRecType } from '../endpoints/InvoiceMoneyRecDiscountEndpoint';
import { Fixed2 } from '../../../../common/utils/common.utils';

type props = {
  current: number;
  pageSize: number;
};

const InvoiceMoneyReceiptDisCol = ({
  current,
  pageSize,
}: props): ColumnsType<invoiceMoneyRecType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((current || 1) - 1) * (pageSize || 20) + 1 + index,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => {
        return `${dayjs(record.date).format('YYYY-MM-DD')}`;
      },
    },

    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data) => {
        const { invoice_no, invoice_category_id, invoice_id } = data;
        return (
          <>
            <Link
              to={`../${
                findInvoiceRoute(invoice_category_id) +
                '/' +
                'details' +
                '/' +
                invoice_id
              }`}
              state={location.pathname}
            >
              {invoice_no}
            </Link>
          </>
        );
      },
    },

    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },

    {
      title: 'Net Total',
      dataIndex: 'invoice_net_total',
      key: 'invoice_net_total',
      render: (curr) => Fixed2(curr),
    },

    {
      title: 'Discount',
      dataIndex: 'invoice_discount',
      key: 'invoice_discount',
      render: (curr) => Fixed2(curr),
    },
  ];
};

export default InvoiceMoneyReceiptDisCol;
