import React from 'react';
import { Col, ConfigProvider, Row, Table, theme } from 'antd';
import { IViewInvoiceDetails } from '../../../../common/types/commonInterfaces';
import { pagination } from '../../../../common/utils/common.utils';

type Props = {
  invoice_info?: IViewInvoiceDetails | any;
};

const CostViewSubTotal = ({ invoice_info }: Props) => {
  console.log(invoice_info);
  let salesCost = 0;
  let total_Profit = 0;
  invoice_info.billing_information.map(
    (item: any) =>
      (salesCost += Number(item.billing_cost_price * item.billing_quantity)) &&
      (total_Profit += Number(item.billing_profit))
  );

  let netTotal = Number(invoice_info?.invoice_net_total);
  let SalesCost = salesCost;
  let SalesProfit = total_Profit;
  let serviceCharge = invoice_info?.invoice_service_charge;
  let totalProfit = total_Profit;
  let totalSales = 0;
  invoice_info.billing_information.map(
    (item: any) => (totalSales += Number(item.billing_subtotal))
  );

  const billingInfoData = [
    {
      key: '1',
      name: 'Net Total',
      value: (
        <>
          <Row justify={'end'}>
            <Col>{Number(netTotal)}</Col>
          </Row>
        </>
      ),
    },
    {
      key: '4',
      name: 'Net Cost',
      value: (
        <>
          <Row justify={'end'}>
            <Col>{Number(SalesCost)}</Col>
          </Row>
        </>
      ),
    },
    {
      key: '11',
      name: 'Service Charge',
      value: (
        <>
          <Row justify={'end'}>
            <Col>{Number(invoice_info.invoice_service_charge)}</Col>
          </Row>
        </>
      ),
    },
    {
      key: '9',
      name: 'Total Sales',
      value: (
        <>
          <Row justify={'end'}>
            <Col>{Number(totalSales)}</Col>
          </Row>
        </>
      ),
    },

    {
      key: '5',
      name: 'Sales Profit',
      value: (
        <>
          <Row justify={'end'}>
            <Col>{Number(SalesProfit)}</Col>
          </Row>
        </>
      ),
    },
    {
      key: '10',
      name: 'Discount',
      value: (
        <>
          <Row justify={'end'}>
            <Col>{Number(invoice_info.invoice_discount)}</Col>
          </Row>
        </>
      ),
    },
    {
      key: '7',
      name: 'Service Charge',
      value: serviceCharge,
    },
    {
      key: '8',
      name: 'Total Profit',
      value: (
        <>
          <Row justify={'end'}>
            <Col>
              {Number(totalProfit) - Number(invoice_info.invoice_discount)}
            </Col>
          </Row>
        </>
      ),
    },
  ];
  const billingInfoColumn = [
    {
      dataIndex: 'name',
      key: 'name',
    },
    {
      dataIndex: 'value',
      key: 'value',
    },
  ];
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
      }}
    >
      <Row justify='end' className='mt-5'>
        <Table
          size='small'
          bordered
          className='invoiceBillingTable'
          rowClassName={'invoiceBillingTd'}
          dataSource={billingInfoData.filter((item) => item.value)}
          columns={billingInfoColumn}
          showHeader={false}
          style={{ width: '300px' }}
          pagination={false}
        />
      </Row>
    </ConfigProvider>
  );
};

export default CostViewSubTotal;
