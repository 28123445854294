import { Col, Input, Row, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useAppSelector } from '../../../app/hooks';
import NumToWord from '../../../components/number_to_words/NumToWord';
import { IGenerateFlightAndPax } from '../QuotationTypes/QuotationTypes';
import { useEffect, useState } from 'react';

type Props = {
  allGenerateInvoice: IGenerateFlightAndPax;
  setCurrentDiscount?: React.Dispatch<React.SetStateAction<number | undefined>>;
  setCurrentPayment?: React.Dispatch<React.SetStateAction<number | undefined>>;
};

type TableType = {
  key: string;
  name: any;
  value?: any;
};

const GenerateInvoiceSubtotal = ({
  allGenerateInvoice,
  setCurrentDiscount,
  setCurrentPayment,
}: Props) => {
  const [discountData, setDiscountData] = useState<number>(
    Number(allGenerateInvoice?.total_discount) || 0
  );

  const [totalPaymentData, setTotalPaymentData] = useState<number>(
    Number(allGenerateInvoice?.total_payment || 0)
  );
  const [totalNetTotal, setTotalNetTotal] = useState<number>(
    Number(allGenerateInvoice?.total_net_total)
  );
  const [totalDue, setTotalDue] = useState<number>(
    Number(allGenerateInvoice?.total_net_total) -
      Number(allGenerateInvoice?.total_payment || 0)
  );

  useEffect(() => {
    setCurrentDiscount && setCurrentDiscount(discountData);
  }, [discountData]);
  useEffect(() => {
    setCurrentPayment && setCurrentPayment(totalPaymentData);
  }, [totalPaymentData]);
  let totalSumAirticketBilling = allGenerateInvoice?.air_ticket_billing?.reduce(
    (acc, item) => acc + parseFloat(item.airticket_client_price),
    0
  );
  let totalSumOtherBilling = allGenerateInvoice?.other_billing?.reduce(
    (acc, item) => acc + item?.billing_subtotal,
    0
  );
  const totalSumBilling = totalSumAirticketBilling + totalSumOtherBilling;
  const appConfig = useAppSelector((state) => state.configSlice);
  const {
    tac_due_wtr_mark,
    tac_paid_wtr_mark,
    tac_inv_curr_sym: CurrencySymbol,
    tac_invoice_footer_note,
  } = appConfig;

  const billingInfoData: TableType[] = [
    {
      key: '1',
      name: 'Sub Total',
      value: allGenerateInvoice?.total_sub_total
        ? `${CurrencySymbol || ''}${Number(
            allGenerateInvoice?.total_sub_total
          )}`
        : '',
    },

    {
      key: '2',
      name: 'Discount',
      value: '0',
    },

    {
      key: '5',
      name: 'Net Total',

      value: totalNetTotal,
    },
    {
      key: '6',
      name: 'Payment',
      value: '0',
    },
    {
      key: '7',
      name: 'Inv Due',

      value:
        String(
          Number(allGenerateInvoice?.total_net_total) -
            Number(allGenerateInvoice?.total_payment)
        ) === '0'
          ? 'Paid'
          : String(
              Number(allGenerateInvoice?.total_net_total) -
                Number(allGenerateInvoice?.total_payment)
            ),
      // Number(invoice_info?.invoice_pay || 0) >=
      // Number(invoice_info.invoice_net_total || 0)
      //   ? ''
      //   : `${CurrencySymbol || ''} ${
      //       Number(invoice_info.invoice_net_total || 0) -
      //         Number(invoice_info.invoice_pay || 0) || ''
      //     }`,
    },
  ];

  const billingInfoColumn: ColumnsType<any> = [
    {
      dataIndex: 'name',
      key: 'name',
      width: '150px',
    },
    {
      dataIndex: 'value',
      key: 'value',
      width: '220px',
      align: 'right',
      render: (_, data) => {
        return data?.name === 'Discount' ? (
          <Input
            style={{ color: '#179cc9', textAlign: 'right' }}
            bordered={false}
            size='small'
            defaultValue={discountData}
            onChange={(e) => {
              setDiscountData(Number(e.target.value));
              setTotalNetTotal(
                Number(
                  Number(allGenerateInvoice.total_sub_total) -
                    Number(e.target.value)
                )
              );
            }}
          />
        ) : data?.name === 'Payment' ? (
          <Input
            style={{ color: '#179cc9', textAlign: 'right' }}
            size='small'
            bordered={false}
            defaultValue={totalPaymentData}
            onChange={(e) => {
              // if(totalDue>totalPaymentData)
              setTotalPaymentData(Number(e.target.value));
              setTotalDue(Number(totalNetTotal) - Number(e.target.value));
            }}
          />
        ) : data?.name === 'Net Total' ? (
          <Input
            style={{ textAlign: 'right' }}
            readOnly
            bordered={false}
            size='small'
            value={Number(allGenerateInvoice.total_sub_total) - discountData}
            defaultValue={totalNetTotal}
          />
        ) : data?.name === 'Inv Due' ? (
          <Input
            style={{ textAlign: 'right' }}
            readOnly
            bordered={false}
            value={Number(totalNetTotal) - totalPaymentData}
            size='small'
            defaultValue={totalDue}
          />
        ) : (
          data?.value
        );
      },
    },
  ];

  return (
    <div>
      <Row justify='end' align={'middle'}>
        <Col>
          <Table
            size='small'
            bordered
            rowKey={(e) => e.key}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={billingInfoData.filter((item) => item?.value)}
            columns={billingInfoColumn}
            showHeader={false}
            pagination={false}
            style={{ width: '270px', marginTop: '15px' }}
          />
        </Col>
      </Row>
      <Row style={{ position: 'relative' }}>
        <Col span={8} offset={16} style={{ marginTop: '10px' }}>
          <Typography.Text
            style={{
              fontFamily: "'Source Sans Pro', sans-serif",
              fontSize: '12px',
            }}
            strong
          >
            <NumToWord number={Number(totalSumBilling)} />
          </Typography.Text>
        </Col>
      </Row>

      {tac_invoice_footer_note && <RenderHTML html={tac_invoice_footer_note} />}
    </div>
  );
};

export default GenerateInvoiceSubtotal;

const RenderHTML = ({ html }: { html: string | null }) => {
  const rawHtml = html || '';

  const sanitizedHtml = rawHtml;

  return (
    <div
      style={{ marginTop: 5 }}
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
    />
  );
};
