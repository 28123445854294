import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  Row,
  Space,
  Table,
  Typography,
  theme,
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import { useAppSelector } from '../../../app/hooks';
import {
  InvoiceHeader,
  TitleCenter,
} from '../../../common/Invoice/InvoiceHeader';
import { Fixed2 } from '../../../common/utils/common.utils';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import { PhoneParser } from '../../../helpers/PhoneFormatter';
import {
  useGetBillInfoQuery,
  useGetSingleAccumulateQuotationQuery,
  useGetSingleQuotationQuery,
} from '../Api/Endpoints/QuotationEndpoints';
import { IQuotationBillInfo } from '../QuotationTypes/QuotationTypes';
import { BillingInfoUtils } from '../Utils/BillingInfoUtils';
import QuotationSubTotal from './QuotationSubTotal';
import { useHotkeys } from 'react-hotkeys-hook';
import ViewGenerateInvoice from '../Components/ViewGenerateInvoice';
import GenerateInvoiceFlightAndPaxView from '../Components/GenerateInvoiceFlightAndPaxView';
import { selectUser } from '../../../auth/states/userSlice';
//   =============== style==============
const invoiceInfoCard: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
};
const invoiceInfoCardOuter: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
};
const officeInfo = {
  name: 'M360 ICT',
  address1: '3RD FLOOR FLAT-F2(ROAD 07 HOUSE 74)',
  address2: 'BANANI, DHAKA 1213',
  modile: ' +88-01715248942',
};

const invoiceViewStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'rgba(211, 211, 211, 0.2)',
  minHeight: '100vh',
  padding: '20px 0',
};
const a4sizeStyle: React.CSSProperties = {
  minHeight: '11.5in',
  width: '8.27in',
  fontSize: '11px',
  background: '#fff',
  margin: '0px auto',
  backgroundColor: 'red',
};
function NewAccumulatedQuotation() {
  const { id } = useParams();
  const currency = useAppSelector(
    (state) => state.user?.organization_info.org_currency
  );

  const [date, setDate] = useState<string>();

  const { data: singleQuotation, isFetching: fetchIsLoading } =
    useGetSingleAccumulateQuotationQuery(Number(id));
  const { Text } = Typography;
  const printTime = dayjs().format('MMMM Do YYYY, h:mm:ss a');
  const { data: billInfo } = useGetBillInfoQuery(Number(id), {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const dataToReturn: IQuotationBillInfo[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: dataToReturn };
    },
  });

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Quotation_${name}_${date}`,
    removeAfterPrint: true,
  });

  useHotkeys('ctrl + p', (e) => {
    e.preventDefault();
    handlePrint();
  });

  const user = useAppSelector(selectUser);
  const header_ref = React.useRef<HTMLDivElement>(null);
  const signiture_ref = React.useRef<HTMLDivElement>(null);
  const subtotal_ref = React.useRef<HTMLDivElement>(null);
  return (
    <div>
      <BreadCrumb arrOfOption={['Quotation', 'View Accumulate Quotation']} />

      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/quotation'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Quotation List
          </Button>
        </Link>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
      </Space>
      <ViewInvoiceStyle style={invoiceViewStyle}>
        <ConfigProvider
          theme={{
            algorithm: theme.defaultAlgorithm,
          }}
        >
          <div ref={componentRef}>
            <ViewGenerateInvoice
              heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
              clientGIName={singleQuotation?.data?.client_name}
              date={
                singleQuotation?.data?.sales_date
                  ? dayjs(singleQuotation?.data?.sales_date).format(
                      'DD-MMM-YYYY'
                    )
                  : ''
              }
              orgName={user?.organization_info?.org_name}
              salesBy={user?.user_full_name}
              allGenerateInvoice={singleQuotation?.data}
              children={
                <GenerateInvoiceFlightAndPaxView
                  heightRefs={{
                    header_ref,
                    signiture_ref,
                    subtotal_ref,
                  }}
                  flights={singleQuotation?.data?.flight_details}
                  airticketBilling={singleQuotation?.data?.air_ticket_billing}
                  otherBilling={singleQuotation?.data?.other_billing}
                  showFlightDetails={true}
                />
              }
            />
          </div>
        </ConfigProvider>
      </ViewInvoiceStyle>
    </div>
  );
}

export default NewAccumulatedQuotation;
const ViewInvoiceStyle = styled.div``;
