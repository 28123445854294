import { api } from '../../../../app/baseQuery';
import { formatQueryParams } from '../../../../auth/utils/lib';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { ACCOUNT_LIST } from '../../../Accounts/Api/Constants/constants';
import { CHEQUE_PAYMENT } from '../../../Cheque_Management/api/Constants/constants';
import { MONEY_RECEIPT_TAG } from '../../../Money_Receipt/api/endpoints/moneyReceiptEndpoints';
import { INVALIDATE_CLIENT_VENDOR_LEDGER } from '../../../Reports/Api/constants';
import {
  DashboardSummery,
  IActiveNotice,
  IBSPBilling,
  IBSPSummary,
  IBSPUpcomingPaymentConsolidate,
  IBestClient,
  IConsolidateDetails,
  IDashboardAccDetails,
  IExpensesData,
  ILastInvoices,
  ILoanDetails,
  IMonthlyReport,
  ISearchPostValue,
  ISearchType,
  IUpdateBalance,
  IVendorsDetails,
  IWAirTicketProfitList,
  IWeeklyMonthlyDailyMonthly,
} from '../../interfaces/dashboard.interfaces';
import { DASHBOARD } from '../Constants/Constants';

export const dashboardEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all Dashboard Summary
    getDashboardSummary: build.query<HTTPResponse<DashboardSummery>, void>({
      query: () => ({ url: `/dashboard/summary` }),
      providesTags: [
        'invoices',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        { type: MONEY_RECEIPT_TAG },
        { type: 'Dashboard', id: DASHBOARD },
      ],
    }),

    // Perform Search Operation Between All Invoices
    getSearchResult: build.query<HTTPResponse<ISearchType[]>, ISearchPostValue>(
      {
        query: (body) => ({ url: `/dashboard/search?search=${body.name}` }),
        providesTags: [
          'invoices',
          INVALIDATE_CLIENT_VENDOR_LEDGER,
          { type: MONEY_RECEIPT_TAG },
          { type: 'Dashboard', id: DASHBOARD },
        ],
      }
    ),

    accountBalance: build.query<HTTPResponse<ILoanDetails>, void>({
      query: (body) => ({ url: `/dashboard/acc-details` }),
      providesTags: [
        { type: 'Dashboard', id: DASHBOARD },
        { type: 'Accounts', id: ACCOUNT_LIST },
      ],
    }),

    expensesData: build.query<HTTPResponse<IExpensesData>, void>({
      query: (body) => ({ url: `/dashboard/expenses` }),
      providesTags: [{ type: 'Dashboard', id: DASHBOARD }],
    }),

    activeNotice: build.query<HTTPResponse<IActiveNotice>, void>({
      query: (body) => ({ url: `/agency-admin/active-notice` }),
      providesTags: [{ type: 'Notice' }],
    }),
    lastInvoices: build.query<HTTPResponse<ILastInvoices[]>, void>({
      query: (body) => ({ url: '/dashboard/last-invoices' }),
      providesTags: [{ type: 'Notice' }],
    }),

    monthReport: build.query<HTTPResponse<IMonthlyReport[]>, void>({
      query: (body) => ({ url: `/dashboard/month-report` }),
      providesTags: [{ type: 'Dashboard', id: DASHBOARD }],
    }),

    salesReport: build.query<HTTPResponse<IWeeklyMonthlyDailyMonthly>, void>({
      query: (body) => ({ url: `/dashboard/tr_info` }),
      providesTags: [{ type: 'Dashboard', id: DASHBOARD }],
    }),
    vendorDetails: build.query<HTTPResponse<IVendorsDetails[]>, void>({
      query: (body) => ({ url: `/dashboard/vendors` }),
      providesTags: [{ type: 'Dashboard', id: DASHBOARD }],
    }),
    bspBilling: build.query<
      HTTPResponse<IBSPBilling>,
      { billing_type: string; from_date?: string; to_date?: string }
    >({
      query: ({ billing_type, from_date, to_date }) => ({
        url: `/dashboard/bsp-billing?billingType=${billing_type.toLowerCase()}&from_date=${from_date}&to_date=${to_date}`,
      }),
      providesTags: [
        { type: 'Dashboard', id: DASHBOARD },
        { type: 'Payments', id: 'VENDOR_PAYMENT' },
        { type: 'Cheque_Payment', id: CHEQUE_PAYMENT },
      ],
    }),
    otherBspBilling: build.query<
      HTTPResponse<IBSPBilling>,
      { billing_type: string; from_date?: string; to_date?: string }
    >({
      query: ({ billing_type, from_date, to_date }) => ({
        url: `/dashboard/others-billing?billingType=${billing_type.toLowerCase()}&from_date=${from_date}&to_date=${to_date}`,
      }),
      providesTags: [
        { type: 'Dashboard', id: DASHBOARD },
        { type: 'Payments', id: 'VENDOR_PAYMENT' },
        { type: 'Cheque_Payment', id: CHEQUE_PAYMENT },
      ],
    }),
    bestClient: build.query<HTTPResponse<IBestClient>, void>({
      query: (body) => ({ url: `/dashboard/best-clients` }),
      providesTags: [{ type: 'Dashboard', id: DASHBOARD }],
    }),
    bestemployee: build.query<HTTPResponse<any>, void>({
      query: (body) => ({ url: `/dashboard/best-employee` }),
      providesTags: [{ type: 'Dashboard', id: DASHBOARD }],
    }),
    /* ----------dashboard accounts-details--------- */
    dashboardAccDetails: build.query<
      HTTPResponse<IDashboardAccDetails[]>,
      { accType: number; pagination: { current: number; pageSize: number } }
    >({
      query: ({ accType, pagination }) => ({
        url: `/dashboard/account-details-type/${accType}?page=${pagination.current}&size=${pagination.pageSize}`,
      }),
      providesTags: [{ type: 'Dashboard', id: DASHBOARD }],
    }),

    dailySalesDetails: build.query<
      HTTPResponse<{
        daily_purchase: number;
        daily_payment: number;
        daily_refund: number;
      }>,
      string
    >({
      query: (body) => ({ url: `/dashboard/daily-pay-pur` }),
      providesTags: [{ type: 'Dashboard', id: DASHBOARD }],
    }),

    monthlySalesDetails: build.query<
      HTTPResponse<{
        monthly_purchase: number;
        monthly_payment: number;
        monthly_refund: number;
      }>,
      string
    >({
      query: (body) => ({ url: `/dashboard/monthly-pay-pur` }),
      providesTags: [{ type: 'Dashboard', id: DASHBOARD }],
    }),

    yearlySalesDetails: build.query<
      HTTPResponse<{
        yearly_purchase: number;
        yearly_payment: number;
        yearly_refund: number;
      }>,
      string
    >({
      query: (body) => ({ url: `/dashboard/yearly-pay-pur` }),
      providesTags: [{ type: 'Dashboard', id: DASHBOARD }],
    }),

    iataLimit: build.query<
      HTTPResponse<{
        vendor_id: number;
        limit_amount: string;
        uses_amount: string;
        vendor_start_date: string;
        vendor_end_date: string;
      }>,
      void
    >({
      query: (body) => ({ url: `/dashboard/iata-limit` }),
      providesTags: [{ type: 'Dashboard', id: DASHBOARD }, 'IATA_LIMIT'],
    }),

    updateBalance: build.mutation<HTTPResponse<void>, IUpdateBalance>({
      query: (body) => ({
        url: `/dashboard/iata-balance`,
        method: 'PUT',
        body: body,
      }),

      invalidatesTags: ['IATA_LIMIT'],
    }),

    bspSummaryView: build.query<
      HTTPResponse<IBSPSummary>,
      {
        week: string;
        billingType?: string;
        from_date?: string;
        to_date?: string;
      }
    >({
      query: ({ week, billingType, from_date, to_date }) => ({
        url: `/dashboard/bsp-summary?billingType=${billingType?.toLowerCase()}${
          week === 'Default' ? '' : `&week=${week}`
        }&from_date=${from_date}&to_date=${to_date}`,
      }),
      providesTags: ['AirTicketRefund', 'BSPSummary', 'invoices', 'refunds'],
    }),

    bspUpcomingPayment: build.query<
      HTTPResponse<IBSPUpcomingPaymentConsolidate[]>,
      void
    >({
      query: () => ({
        url: `/dashboard/consolidate`,
      }),
      providesTags: ['AirTicketRefund', 'BSPSummary', 'invoices', 'refunds'],
    }),

    otherBillingSummaryView: build.query<
      HTTPResponse<IBSPSummary>,
      {
        week: string;
        billingType?: string;
        from_date?: string;
        to_date?: string;
      }
    >({
      query: ({ week, billingType, from_date, to_date }) => ({
        url: `/dashboard/others-billing-summary?billingType=${billingType?.toLowerCase()}${
          week === 'Default' ? '' : `&week=${week}`
        }&from_date=${from_date}&to_date=${to_date}`,
      }),
      providesTags: ['AirTicketRefund', 'BSPSummary', 'invoices', 'refunds'],
    }),

    airTicketSummaryTotal: build.query<
      HTTPResponse<IWAirTicketProfitList>,
      {
        billingType: 'upcoming' | 'previous';
        from_date?: string;
        to_date?: string;
        client_id?: string;
      }
    >({
      query: (arg) => {
        const { client_id, billingType, from_date, to_date } = arg;

        const queryParams = formatQueryParams({
          billingType,
          client: client_id,
          from_date,
          to_date,
        });

        return {
          url: `report/air-ticket-summary-total?${queryParams}`,
        };
      },
      providesTags: [
        { type: 'Dashboard', id: DASHBOARD },
        { type: 'invoice' },
        'invoices',
        'refunds',
      ],
    }),

    bspConsolidateDetails: build.query<
      HTTPResponse<IConsolidateDetails[]>,
      { period: string }
    >({
      query: ({ period }) => ({
        url: `dashboard/consolidate-pay-details/${period}`,
      }),
      providesTags: ['AirTicketRefund', 'BSPSummary', 'invoices', 'refunds'],
    }),
  }),
});

export const {
  useGetDashboardSummaryQuery,
  useLazyGetSearchResultQuery,
  useAccountBalanceQuery,
  useExpensesDataQuery,
  useActiveNoticeQuery,
  useLastInvoicesQuery,
  useMonthReportQuery,
  useSalesReportQuery,
  useVendorDetailsQuery,
  useBspBillingQuery,
  useBestClientQuery,
  useBestemployeeQuery,
  useDashboardAccDetailsQuery,
  useLazyDailySalesDetailsQuery,
  useLazyMonthlySalesDetailsQuery,
  useLazyYearlySalesDetailsQuery,
  useIataLimitQuery,
  useBspSummaryViewQuery,
  useAirTicketSummaryTotalQuery,
  useOtherBspBillingQuery,
  useUpdateBalanceMutation,
  useOtherBillingSummaryViewQuery,
  useBspUpcomingPaymentQuery,
  useBspConsolidateDetailsQuery,
} = dashboardEndpoints;
