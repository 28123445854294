import { Button, Form, Input, InputNumber, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { selectCommon, setModal } from '../../../../common/slices/commonSlice';
import { ISetState } from '../../../../common/types/commonTypes';
import { formatAmountAbs } from '../../../../common/utils/common.utils';
import { useSendSMSMutation } from '../../../SMS System/Api/Endpoints/SMSEndpoints';
import { ISMSFormData } from '../../../SMS System/SMSTypes/SMSTypes';
import { ClientDataType } from '../types/clientInterfaces';

interface IProps {
  info: ClientDataType;
  setNull: ISetState<ClientDataType | null>;
}
const ClientSmsModal = ({ info, setNull }: IProps) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const modalSlice = useSelector(selectCommon);
  const [form] = Form.useForm();
  const {
    client_category_id,
    client_id,
    client_name,
    client_last_balance,
    mobile,
  } = info;

  useEffect(() => {
    form.setFieldsValue({
      client_name: client_name,
      client_last_balance: -(
        client_last_balance != undefined && client_last_balance
      ),
      client_mobile: mobile,
    });
  }, [info]);

  const [sendSMS, { isError }] = useSendSMSMutation();
  const handleOnSubmit = async (values: ISMSFormData) => {
    const body: ISMSFormData = {
      client_mobile: mobile,
      client_id: `client-${client_id}`,
      text_type: 'TEXT',
      client_category_id: client_category_id as number,
      message: `Dear ${client_name},You have a due of ${formatAmountAbs(
        client_last_balance
      )}.${user?.organization_info.org_name}`,
      date: dayjs().format('YYYY-MM-DD'),
      created_by: user?.user_id as number,
    };

    await sendSMS([body]);
    setNull(null);
    dispatch(setModal(false));
  };

  return (
    <div>
      <Form layout='vertical' onFinish={handleOnSubmit} form={form}>
        <Form.Item required name='client_name' label='Name:'>
          <Input readOnly />
        </Form.Item>
        <Form.Item required name='client_last_balance' label='Due Amount : '>
          <InputNumber readOnly />
        </Form.Item>
        <Form.Item required name='client_mobile' label='Mobile : '>
          <Input readOnly />
        </Form.Item>
        <Typography.Text strong>
          Are you sure to send sms to this Customer ?
        </Typography.Text>
        <Form.Item style={{ marginTop: '0.5rem' }}>
          <Button type='primary' htmlType='submit'>
            {modalSlice.isModal}Send SMS
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ClientSmsModal;
