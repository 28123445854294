import React, { forwardRef } from 'react';
import { Table, Typography } from 'antd';
import dayjs from 'dayjs';
import { InvoiceFooter } from '../../../../common/Invoice/InvoiceFooter';
import {
  IAuthorizedBy,
  IPaxDetails,
  IPreparedBy,
  IViewInvoiceDetails,
} from '../../../../common/types/commonInterfaces';
import { IAirticketBillingInfo } from '../../../IATA_AIR_TICKET/types/invoiceAirTicketTypes';
import { IFlightData } from '../../../IATA_AIR_TICKET/Components/ManualInvoiceAirTicketEdited/ManualEditInVoiceFlight';
import ManualInvoiceCommonHeader from '../../../IATA_AIR_TICKET/Components/ManualInvoiceAirTicketEdited/ManualInvoiceCommonHeader';
import { filterNullColumns } from '../../../../common/utils/common.utils';

const PrintComponent = forwardRef<
  HTMLDivElement,
  {
    allEditableData: {
      flightDetails?: IFlightData[] | undefined;
      paxDetails?: IPaxDetails[] | undefined;
      billingDetails?: IAirticketBillingInfo[] | undefined;
      subtotalDetails?: any;
    };
    authorized_by: IAuthorizedBy | undefined;
    prepared_by: IPreparedBy | undefined;
    invoiceData: IViewInvoiceDetails | undefined;
    isShowFlightDetails?: boolean;
    isShowPaxDetails?: boolean;
  }
>(
  (
    {
      allEditableData,
      authorized_by,
      prepared_by,
      invoiceData,
      isShowFlightDetails,
      isShowPaxDetails,
    },
    ref
  ) => {
    const filteredBillingColumns = filterNullColumns(
      allEditableData.billingDetails,
      billingColumn
    );
    const totalSubTotal = allEditableData?.billingDetails?.reduce(
      (acc, curr) => acc + Number(curr.airticket_client_price),
      0
    );
    return (
      <div hidden>
        <div ref={ref} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          <ManualInvoiceCommonHeader invoiceData={invoiceData} />
          {allEditableData.flightDetails?.length && isShowFlightDetails ? (
            <>
              <Typography.Title level={5} style={{ marginBottom: '5px' }}>
                FLIGHT/ROUTE DETAILS
              </Typography.Title>

              <Table
                bordered
                dataSource={allEditableData.flightDetails}
                columns={flightDetailsolumns}
                pagination={false}
                style={{ marginBottom: '2rem' }}
                className='invoiceBillingTable'
                rowClassName={'invoiceBillingTd'}
              />
            </>
          ) : (
            <></>
          )}

          {allEditableData?.flightDetails?.length && isShowPaxDetails ? (
            <>
              <Typography.Title level={5} style={{ marginBottom: '5px' }}>
                PAX DETAILS
              </Typography.Title>

              <Table
                bordered
                dataSource={allEditableData?.paxDetails}
                columns={paxColumn}
                pagination={false}
                size='small'
                style={{ marginBottom: '2rem' }}
                className='invoiceBillingTable'
                rowClassName={'invoiceBillingTd'}
              />
            </>
          ) : (
            <></>
          )}
          <>
            <Typography.Title level={5} style={{ marginBottom: '5px' }}>
              BILLING INFO
            </Typography.Title>

            <Table
              bordered
              dataSource={allEditableData?.billingDetails}
              columns={filteredBillingColumns?.col}
              pagination={false}
              size='small'
              style={{ marginBottom: '2rem' }}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
            />
          </>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Table
              components={{
                header: {
                  row: () => null,
                },
              }}
              size='small'
              bordered
              rowKey={(record) => record.key}
              dataSource={allEditableData?.subtotalDetails}
              columns={[
                {
                  dataIndex: 'name',
                  key: 'name',
                  width: '150px',
                },
                {
                  dataIndex: 'value',
                  key: 'value',
                  width: '150px',
                  align: 'right',
                  render: (_: any, data: any) => {
                    if (data.name === 'Sub Total') {
                      return totalSubTotal;
                    } else if (data.name === 'Net Total') {
                      return allEditableData?.subtotalDetails[1]?.name ===
                        'Discount'
                        ? Number(totalSubTotal) -
                            allEditableData?.subtotalDetails[1]?.value
                        : Number(totalSubTotal);
                    } else {
                      return data.value;
                    }
                  },
                },
              ]}
              pagination={false}
              style={{ width: '270px', marginTop: '15px' }}
            />
          </div>
          <div>
            <InvoiceFooter
              authorized_by={authorized_by}
              prepared_by={prepared_by}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default PrintComponent;
export const flightDetailsolumns = [
  {
    title: 'Sl.',
    dataIndex: 'flight_id',
    editable: false,
    render: (_: any, value: any, index: number) => index + 1,
  },
  {
    title: 'From',
    dataIndex: 'flight_from',
    editable: true,
  },
  {
    title: 'To',
    dataIndex: 'flight_to',
    editable: true,
  },
  {
    title: 'Airline',
    dataIndex: 'airline_name',
    editable: true,
  },
  {
    title: 'DT.',
    dataIndex: 'fltdetails_departure_time',
    editable: true,
  },
  {
    title: 'AT.',
    dataIndex: 'fltdetails_arrival_time',
    editable: true,
  },
];
export const paxColumn = [
  {
    title: 'Sl.',
    dataIndex: 'passport_id',
    editable: false,
    render: (_: any, value: any, index: number) => index + 1,
  },
  {
    title: 'Pax Name',
    dataIndex: 'passport_name',
    editable: true,
  },
  {
    title: 'Pax Type',
    dataIndex: 'passport_person_type',
    editable: true,
  },
  {
    title: 'Passport No',
    dataIndex: 'passport_passport_no',
    editable: true,
  },

  {
    title: 'DOB',
    dataIndex: 'passport_date_of_birth',
    editable: true,
    render: (_: any, data: any) =>
      data.passport_date_of_birth
        ? dayjs(data.passport_date_of_birth).format('D-MM-YYYY')
        : '',
  },
  {
    title: 'Expire Date',
    dataIndex: 'passport_date_of_expire',
    editable: true,
    render: (_: any, data: any) =>
      data.passport_date_of_expire
        ? dayjs(data.passport_date_of_expire).format('D-MM-YYYY')
        : '',
  },
];
export const billingColumn = [
  {
    title: 'Sl.',
    dataIndex: 'airticket_id',
    key: 'airticket_id',
    editable: false,
    render: (_: any, value: any, index: number) => index + 1,
  },
  {
    title: 'Ticket No',
    dataIndex: 'airticket_ticket_no',
    key: 'airticket_ticket_no',
    editable: true,
  },
  {
    title: 'PNR',
    dataIndex: 'airticket_pnr',
    key: 'airticket_pnr',
    editable: true,
  },
  {
    title: 'Class',
    dataIndex: 'airticket_classes',
    key: 'airticket_classes',
    editable: true,
  },
  // {
  //   title: 'Class Type',
  //   dataIndex: 'airticket_ticket_type',
  //   key: 'airticket_ticket_type',
  //   editable: true,
  // },

  {
    title: 'Route',
    dataIndex: 'airticket_routes',
    key: 'airticket_routes',
    editable: true,
  },

  {
    title: 'Journey Date',
    dataIndex: 'airticket_journey_date',
    key: 'airticket_journey_date',
    editable: true,
    render: (_: any, data: any) =>
      data.airticket_journey_date
        ? dayjs(data.airticket_journey_date).format('D-MM-YYYY')
        : '',
  },
  {
    title: 'Return Date',
    dataIndex: 'airticket_return_date',
    key: 'airticket_return_date',
    editable: true,
    render: (_: any, data: any) =>
      data.airticket_return_date
        ? dayjs(data.airticket_return_date).format('D-MM-YYYY')
        : '',
  },
  {
    title: 'Type',
    dataIndex: 'airticket_id:',
    key: 'airticket_id:',
    editable: true,
    render: (_: any, data: any) => {
      return (
        <span>
          {data.airticket_is_refund ? (
            <span style={{ color: 'red' }}>REFUND </span>
          ) : data.airticket_is_reissued ? (
            <span>REISSUED </span>
          ) : data.airticket_is_void ? (
            <span style={{ color: 'red' }}>VOID </span>
          ) : (
            <span>ISSUED </span>
          )}
        </span>
      );
    },
  },
  {
    title: 'Total',
    dataIndex: 'airticket_client_price',
    key: 'airticket_client_price',
    editable: true,
    render: (_: any, data: any) => Number(data.airticket_client_price),
  },
];
