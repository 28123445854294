import { Col, Modal, Row, Table } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { perProps } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import LoadingIndicator, {
  loadingIndicator,
} from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllDepartmentsWithPaginationQuery } from '../../department/endpoints/departmentEndpoints';
import { DepartmentDataType } from '../../department/types/departmentTypes';
import {
  useGetAllEmployeesWithPaginationQuery,
  useLazyEmployeeDetailsByIdQuery,
} from '../endpoints/employeeEndpoints';
import EmployeeAddModal from '../modals/AddEmployeeModal';
import EmployeeEditModal from '../modals/EditEmployeeModal';
import { employeeDataType } from '../types/employeeTypes';
import { columns } from '../utils/utils';
const Employee = ({ permission }: perProps) => {
  const [editInfo, setEditInfo] = useState<employeeDataType | null>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const dispatch = useAppDispatch();

  const showModal = () => {
    dispatch(setModal(true));
  };
  const {
    isLoading,
    data: employee,
    refetch,
  } = useGetAllEmployeesWithPaginationQuery(pagination, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const count = cache.data?.count;
      const dataToReturn: employeeDataType[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: { dataToReturn, count } };
    },
  });

  //department column filter
  const { data: department } = useGetAllDepartmentsWithPaginationQuery(
    pagination,
    {
      selectFromResult: (cache) => {
        const data = cache.data?.data;
        const count = cache.data?.count;
        const dataToReturn: DepartmentDataType[] = [];
        const departmentNameList: string[] = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            departmentNameList.push(element.department_name);
            dataToReturn.push({
              ...element,
              key: i + 1,
            });
          }
        }
        return { ...cache, data: { dataToReturn, departmentNameList, count } };
      },
    }
  );

  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});

  //employee view details data
  const [fetchEmployee, { data, isLoading: empLoading, isFetching }] =
    useLazyEmployeeDetailsByIdQuery();
  const [open, setOpen] = useState(false);
  const handleViewModal = (employee_id: string) => {
    fetchEmployee(employee_id);
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  return (
    <>
      <BreadCrumb
        arrOfOption={['Configuration', 'Employee']}
        refetch={refetch}
        reloaderSize='small'
      />

      <CommonTableHeader
        width
        align
        title={editInfo ? 'Edit Employee' : 'Add New Employee'}
        button_text='Add New Employee'
        setNull={setEditInfo}
        permission={permission?.['create:any']}
        element={
          editInfo ? (
            <EmployeeEditModal
              info={editInfo}
              setNull={setEditInfo}
              isLoading={empLoading || isFetching}
            />
          ) : (
            <EmployeeAddModal />
          )
        }
      />
      <Modal
        title='Employee Details'
        open={open}
        onOk={hideModal}
        onCancel={hideModal}
        width={400}
        forceRender
        confirmLoading={empLoading || isFetching}
      >
        <Row justify={'start'} gutter={[10, 10]}>
          <Col span={12}>Name :</Col>
          <Col span={12}>{data?.data?.employee_full_name}</Col>
          <Col span={12}>Creation Sine :</Col>
          <Col span={12}>{data?.data?.employee_creation_sign}</Col>
          <Col span={12}>Designation :</Col>
          <Col span={12}>{data?.data?.designation_name}</Col>
          <Col span={12}>Email :</Col>
          <Col span={12}>{data?.data?.employee_email}</Col>
          <Col span={12}>Salary :</Col>
          <Col span={12}>{data?.data?.employee_salary}</Col>
          <Col span={12}>Address :</Col>
          <Col span={12}>{data?.data?.employee_address}</Col>
          <Col span={12}>Mobile :</Col>
          <Col span={12}>{data?.data?.employee_mobile}</Col>
          <Col span={12}>Joining Date :</Col>
          <Col span={12}>
            {data?.data?.employee_joining_date
              ? dayjs(data.data.employee_joining_date).format('DD-MM-YYYY')
              : ''}
          </Col>
          <Col span={12}>Appointment Date :</Col>
          <Col span={12}>
            {data?.data?.employee_apppoint_date
              ? dayjs(data.data.employee_apppoint_date).format('DD-MM-YYYY')
              : ''}
          </Col>
          <Col span={12}>Birth Date :</Col>
          <Col span={12}>
            {data?.data?.employee_birth_date
              ? dayjs(data?.data?.employee_birth_date).format('DD-MM-YYYY')
              : ''}
          </Col>
          <Col span={12}>Blood Group :</Col>
          <Col span={12}>{data?.data?.group_name}</Col>
          <Col span={12}>Employee Status :</Col>
          <Col span={12}>
            {data?.data?.employee_status == 1 ? 'Active' : 'InActive'}
          </Col>
        </Row>
        {empLoading || isFetching ? <LoadingIndicator /> : ''}
      </Modal>

      <Row justify={'center'}>
        <Table
          size='small'
          bordered
          style={{ width: 900 }}
          columns={columns({
            setEditInfo,
            showModal,
            permission,
            pagination,
            filteredInfo,
            data: department.dataToReturn,
            handleViewModal,
          })}
          dataSource={employee.dataToReturn}
          pagination={
            employee?.count !== undefined && employee?.count < 20
              ? false
              : {
                  ...pagination,
                  total: employee?.count,
                  showSizeChanger: true,
                  pageSizeOptions: ['50', '100', '200', '500'],
                  onChange: handlePaginationChange,
                }
          }
          scroll={{ x: true }}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
          onChange={(_, filters) => setFilteredInfo(filters)}
        />
      </Row>
    </>
  );
};

export default Employee;
