import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import Flight_Details_List_Info from './Flight_Details_List_Info';
import { AirTicket_ticket_information } from '../types/invoiceAirTicketTypes';

type Props = {
  form: FormInstance<any>;
  ticketInfo?: AirTicket_ticket_information[];
};

export default function AirticketFlightDetails({ form, ticketInfo }: Props) {
  return (
    <div className='border p-5 mt-10'>
      <FormHeaderTitle title='Flight Details (Optional) ✈️' />

      <Form.List name={['flight_details']} initialValue={[{}]}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Flight_Details_List_Info
                add={add}
                field={field}
                index={index}
                remove={remove}
                key={index}
                form={form}
                ticketInfo={ticketInfo}
              />
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
}
