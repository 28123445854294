import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { NumberInput } from '../../../../components/common/FormItem/FormItems';
import { SelectOptionsInput } from '../../../../components/common/FormItem/SelectCustomFeilds';
import BillingIsDeleted from '../../../Invoice(Visa)/Components/BillingIsDeleted';

type IProps = {
  name: number;
  remove: (index: number | number[]) => void;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  form: FormInstance<any>;
  index: number;
  length: number;
};
const TaxesMultipleField = ({
  name,
  remove,
  add,
  form,
  index,
  length,
}: IProps) => {
  const taxes = useWatch(['taxes_commission', name, 'airline_taxes']);

  useEffect(() => {
    if (taxes) {
      form?.setFieldValue(
        ['taxes_commission', name, 'airline_commission'],
        Math.round((Number(taxes) * 7) / 100)
      );
    } else {
      form?.setFieldValue(
        ['taxes_commission', name, 'airline_commission'],
        undefined
      );
    }
  }, [taxes]);

  return (
    <Row gutter={6}>
      <NumberInput size={6} name={[name, 'airline_taxes']} label='Tax Amount' />
      <NumberInput
        readOnly
        size={6}
        name={[name, 'airline_commission']}
        label='7% Commission'
      />
      <SelectOptionsInput
        label='Select Tax'
        name={[name, 'airline_tax_type']}
        options={['YQ', 'YR', 'ALL TAXES']}
        size={6}
      />
      <BillingIsDeleted name={[name, 'is_deleted']} />

      <Col lg={2} xl={5}>
        <Form.Item label={[]}>
          <Row gutter={6}>
            {length === index + 1 && (
              <Col sm={24} md={24} lg={6}>
                <Button
                  type='dashed'
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                />
              </Col>
            )}

            {name === 0 ? (
              ''
            ) : (
              <Col sm={24} md={24} lg={6}>
                <Button
                  className='ml-2'
                  onClick={() => {
                    remove(name);
                  }}
                  block
                  icon={<MinusCircleOutlined />}
                  danger
                />
              </Col>
            )}
          </Row>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default TaxesMultipleField;
