import { FormInstance } from 'antd';
import { useState } from 'react';

const getQuery = () => {
  if (typeof window !== 'undefined') {
    return new URLSearchParams(window.location.search);
  }
  return new URLSearchParams();
};

const getQueryStringVal = (key: string): string | null => {
  return getQuery().get(key);
};

const useQueryParam = <K extends string, T extends string | null>(
  key: K,
  defaultVal: T,
  form?: FormInstance<any>
): [T, (val: T) => void, () => void] => {
  const [query, setQuery] = useState<T>(
    (getQueryStringVal(key) || String(defaultVal)) as T
  );

  const updateUrl = (newVal: T) => {
    setQuery(newVal);

    const query = getQuery();

    if (newVal !== null && String(newVal).trim() !== '') {
      query.set(key, newVal);
    } else {
      query.delete(key);
    }

    if (typeof window !== 'undefined') {
      const { protocol, pathname, host } = window.location;
      const newUrl = `${protocol}//${host}${pathname}?${query.toString()}`;
      window.history.replaceState({}, '', newUrl);
    }
  };

  const removeUrlParam = () => {
    const query = getQuery();
    query.delete(key);

    //@ts-ignore
    updateUrl('');

    // if (typeof window !== 'undefined') {
    //   const { protocol, pathname, host } = window.location;
    //   let newUrl = `${protocol}//${host}${pathname}`;

    //   if (query.toString() !== '') {
    //     newUrl += `?${query.toString()}`;
    //   }

    //   window.history.replaceState({}, '', newUrl);
    // }
  };

  return [query, updateUrl, removeUrlParam];
};

export default useQueryParam;
