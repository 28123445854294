import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../auth/states/userSlice';
import { useVisaStatusUpdateMutation } from '../../../components/notificatioin/Api/NotificationEndpoints';
import { useLazyGetInvoiceVisaDetailsQuery } from '../Api/Endpoints/invoiceVisaEndpoints';
import { IBillingInfo } from '../Type/invoiceVisa.interface';

type Props = { id: number };

export default function VisaApproveModal({ id }: Props) {
  const [fetchVisaDetails, { data: invoiceDetails, isSuccess: success }] =
    useLazyGetInvoiceVisaDetailsQuery();

  const billing_information = invoiceDetails?.data
    ?.billing_information as IBillingInfo[];

  const [fetchVisaStatus, { isError, isLoading, isSuccess }] =
    useVisaStatusUpdateMutation();
  const user = useSelector(selectUser);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    fetchVisaDetails(id);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isError) {
      message.error("Without vendor approved can't be possible");
    } else if (isSuccess) {
      message.success('Visa Approved');
      handleOk();
    }
  }, [isError, isSuccess]);

  const column: ColumnsType<IBillingInfo> = [
    {
      title: 'SL.',
      render: (_, data, index) => <>{index + 1}</>,
      fixed: 'left',
      width: 55,
    },
    {
      title: 'Vendor Name',
      fixed: 'left',
      width: 55,
      dataIndex: 'vendor_name',
      key: 'vendor_name',
    },
    {
      title: 'Quantity',
      fixed: 'left',
      width: 55,
      dataIndex: 'billing_quantity',
      key: 'billing_quantity',
    },
    {
      title: 'Unit Price',
      fixed: 'left',
      width: 55,
      dataIndex: 'billing_unit_price',
      key: 'billing_unit_price',
    },
    {
      title: 'Sub Total',
      fixed: 'left',
      width: 55,
      dataIndex: 'billing_subtotal',
      key: 'billing_subtotal',
    },
    {
      title: 'Status',
      fixed: 'left',
      width: 55,
      render: (value, record, index) => {
        return record.billing_status === 'Pending' ? (
          <Row>
            <Space.Compact size='small' style={{ margin: '0 auto' }}>
              <Popconfirm
                title={'Sure to Approve'}
                onConfirm={() => {
                  if (record?.billing_id)
                    fetchVisaStatus({
                      body: { created_by: user?.user_id },
                      billing_id: record?.billing_id,
                      status: 'Approved',
                    }).then(() => {
                      // refetch();
                      fetchVisaDetails(id);
                    });
                }}
              >
                <Button type='primary' style={{ backgroundColor: '#87d068' }}>
                  Approve
                </Button>
              </Popconfirm>
              <Popconfirm
                title={'Sure to Rejected'}
                onConfirm={() => {
                  if (record?.billing_id)
                    fetchVisaStatus({
                      body: { created_by: user?.user_id },
                      billing_id: record?.billing_id,
                      status: 'Rejected',
                    }).then(() => {
                      // refetch();
                      fetchVisaDetails(id);
                    });
                }}
              >
                <Button type='primary' danger size='large'>
                  Reject
                </Button>
              </Popconfirm>
            </Space.Compact>
          </Row>
        ) : (
          <Typography>
            <Tag color={record.billing_status === 'Approved' ? 'green' : 'red'}>
              {record.billing_status}
            </Tag>
          </Typography>
        );
      },
    },
  ];

  return (
    <>
      <Button size='small' type='primary' onClick={showModal}>
        <EditOutlined /> Status
      </Button>
      <Modal
        title='Change Visa Status'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Table
          loading={isLoading}
          columns={column}
          dataSource={billing_information}
          pagination={false}
        />
      </Modal>
    </>
  );
}
