import { ColumnsType } from 'antd/es/table';
import Table from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { IViewBillingInfo } from '../../../../../../../common/types/commonInterfaces';
import { Fixed2 } from '../../../../../../../common/utils/common.utils';
import FormHeaderTitle from '../../../../../../../modules/Invoice(Visa)/Components/FormHeaderTitle';
import { useGetFakeInvoiceQuery } from '../../editedInvoiceApiEndpoints';

type Props = { billing_information: IViewBillingInfo[] };

interface mergerTourBillingInfo {
  product_name: string;
  country_name: string | undefined;
  billing_pax_name: string;
  billing_total_pax: number;
  billing_numof_room: number;
  billing_total_sales: number;
}

const FakeFlightViewTourBilling = ({ billing_information }: Props) => {
  const invoice_id = Fixed2(useParams().id);

  const [billingDetails, setBillingDetails] =
    useState<mergerTourBillingInfo[]>();

  const { data, isLoading, isSuccess } = useGetFakeInvoiceQuery({
    id: invoice_id,
  });

  useEffect(() => {
    const info = data?.data?.infos?.map((item, index) => ({
      product_name: billing_information[index]?.product_name,
      country_name: billing_information[index]?.country_name,
      billing_pax_name: billing_information[index]?.billing_pax_name,
      billing_total_pax: billing_information[index]?.billing_total_pax,
      billing_numof_room: billing_information[index]?.billing_numof_room,
      billing_total_sales: Fixed2(item.tii_unit_price),
    }));

    setBillingDetails(info);
  }, [data?.data, invoice_id, isSuccess]);

  const billingColumn: ColumnsType<mergerTourBillingInfo> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
      align: 'center',
    },
    {
      title: 'Country Name',
      dataIndex: 'country_name',
      key: 'country_name',
      align: 'center',
    },
    {
      title: 'Pax Name',
      dataIndex: 'billing_pax_name',
      key: 'billing_pax_name',
      align: 'center',
    },
    {
      title: 'Total Pax',
      dataIndex: 'billing_total_pax',
      key: 'billing_total_pax',
      align: 'center',
    },
    {
      title: 'Room',
      dataIndex: 'billing_numof_room',
      key: 'billing_numof_room',
      align: 'center',
    },
    {
      title: 'Total Sales',
      dataIndex: 'billing_total_sales',
      key: 'billing_total_sales',
      align: 'center',
      render: (_, data) => Fixed2(data?.billing_total_sales),
    },
  ];
  return (
    <div>
      <>
        <FormHeaderTitle title='BILLING INFORMATION' />
        <Table
          size='small'
          className='invoiceBillingTable'
          rowClassName={'invoiceBillingTd'}
          dataSource={billingDetails}
          columns={billingColumn}
          bordered
          pagination={false}
        />
      </>
    </div>
  );
};

export default FakeFlightViewTourBilling;
