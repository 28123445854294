import { Divider, Typography } from 'antd';

type Props = { title: string; margintop?: number };

function FormHeaderTitle({ title, margintop }: Props) {
  const handleClick = () => {
    console.log('HII');
  };

  return (
    <Divider orientation='left' plain>
      <Typography.Title style={{ fontFamily: "sans-serif" }} onClick={handleClick} level={5}>
        {title}
      </Typography.Title>
    </Divider>
  );
}

export default FormHeaderTitle;
