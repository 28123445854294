import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { PASSPORT_STATUS } from '../../../Passport_MGT/Api/Constants/Constants';
import {
  ISMSDataType,
  ISMSFormData
} from '../../SMSTypes/SMSTypes';
import { SMS } from '../Constants/Constants';

export const smsEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //send sms
    sendSMS: build.mutation<void, ISMSFormData[]>({
      query: (body) => ({
        url: '/sms',
        method: 'POST',
        body: body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          toasterNotification('success', 'SMS Sent Successfully');
        });
      },
      invalidatesTags: () => [{ type: 'Sms', id: SMS }],
    }),

    // get all sms by date
    getSMSByDate: build.query<HTTPResponse<ISMSDataType[]>, { query: string }>({
      query: ({ query }) => ({ url: `/sms/all${query ? query : ''}` }),
      providesTags: [
        { type: 'Sms', id: SMS },
        { type: 'Passport_Status', id: PASSPORT_STATUS },
      ],
    }),
    // get sms balance
    getSMSBalance: build.query<
      {
        ErrorCode: number;
        ErrorDescription: string;
        Data: [
          {
            PluginType: string;
            Credits: string;
          }
        ];
      },
      void
    >({
      query: (body) => ({ url: `/sms/balance` }),
      providesTags: [
        { type: 'Sms', id: SMS },
        { type: 'Passport_Status', id: PASSPORT_STATUS },
      ],
    }),
  }),
});

export const {
  useSendSMSMutation,
  useLazyGetSMSByDateQuery,
  useGetSMSBalanceQuery,
} = smsEndpoints;
