import { Col, Form, Row, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { removeUndefinedAndNull } from '../../../common/utils/removeUndefinedAndNull';
import {
  FormButton,
  FormInputItem,
  NumberInput,
  TextAreaInput,
} from '../../../components/common/FormItem/FormItems';
import { SelectDataInput } from '../../../components/common/FormItem/SelectCustomFeilds';
import InvoiceHeaderForm from '../../../components/common/Invoice/InvoiceHeaderForm';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { useGetInvoiceNoQuery } from '../../Invoice(Visa)/Api/Endpoints/invoiceVisaEndpoints';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import NewBillingInfoForm from '../Components/NewBillingInfoForm';
import { IExistingClients } from '../Components/PreviousTicketInfo';
import { IInvoice_info_Existing } from '../Type/InoviceReissue.interface';
import { useGetExistingReIssueInvoiceQuery } from '../api/endpoints/reIssueAirTicketInvoiceEndpoints';
import {
  useEditExistingClientMutation,
  useLazyGetExistingClientsQuery,
} from '../api/endpoints/reIssueExistingAirTicketEndpoints';

type Props = {};

const EditExistingReissue = (props: Props) => {
  const id = Number(useParams().id);
  const [form] = useForm();
  const navigate = useNavigate();
  ///set field value set
  const {
    data,
    isError: fetchingErr,
    isSuccess: getValueSuccess,
    isLoading: getValueLoading,
    refetch: getValueRefetch,
  } = useGetExistingReIssueInvoiceQuery(id);

  const [
    editExistingClient,
    {
      isLoading: postLoading,
      isError: postError,
      isSuccess: postSuccess,
      error,
    },
  ] = useEditExistingClientMutation();
  useEffect(() => {
    if (postSuccess) {
      message.success('Created successfully');
      navigate(`/reissueairticket`);
    } else if (postError) {
      message.error('Something went wrong');
    }
  }, [postSuccess, postError]);
  const invoice_info = data?.data;
  const air_ticket_info = invoice_info?.air_ticket_info?.map((item) => ({
    ...item,
    airticket_issue_date: dayjs(item.airticket_issue_date),
    airticket_journey_date: dayjs(item.airticket_journey_date),
    airticket_return_date: dayjs(item.airticket_return_date),
  }));

  const ticketNumber = invoice_info?.air_ticket_info?.map(
    (item) => item.airticket_existing_airticket_id
  );

  // get invoice id
  const { data: invoiceIdData } = useGetInvoiceNoQuery('ARI');
  const invoiceIdServer = invoiceIdData?.data;
  useEffect(() => {
    if (invoiceIdServer) {
      form.setFieldsValue({
        invoice_no: String(invoiceIdServer),
        invoice_sales_date: dayjs(),
      });
    }
  }, [invoiceIdServer]);

  // ================ existing clients data ==================
  const [exClientDataGet, { data: exClientsData, isLoading, isSuccess }] =
    useLazyGetExistingClientsQuery();

  const existingClient = exClientsData?.data;
  // ======================= get form value ===================
  const [selectedClient, setSelectedClient] = useState<IExistingClients[]>([]);

  const handleSelectTicket = () => {
    const ticketNo = form.getFieldValue('ticket_no') as number[];
    const selectedTickets = existingClient?.filter((item) =>
      ticketNo.includes(item.airticket_id)
    );
    if (selectedTickets && selectedTickets.length > 0) {
      setSelectedClient(selectedTickets);
    }
  };

  const handleClientChange = () => {
    const client_id = form.getFieldValue('invoice_combclient_id');
    if (client_id) {
      exClientDataGet(client_id);
    }
  };

  // Calculate

  // @Form submit
  const onFinish = async (values: IInvoice_info_Existing) => {
    const { ticket_no, air_ticket_info, ...all } =
      values as IInvoice_info_Existing;
    const cleanedData = air_ticket_info?.map((item) => {
      return Object.fromEntries(
        Object.entries(item).filter(
          ([key, value]) => value !== null && value !== undefined
        )
      );
    });

    const removeNull = removeUndefinedAndNull(all);
    const body = {
      ...removeNull,
      air_ticket_info: cleanedData,
      invoice_sales_date: dayjs(values.invoice_sales_date).format('YYYY-MM-DD'),
    };
    await editExistingClient({ body, id: id });
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error('Submit failed!');
  };

  const formattedTicketInfo =
    selectedClient?.length &&
    selectedClient?.map((item) => {
      return {
        airticket_existing_airticket_id: item?.airticket_id,
        airticket_existing_invoiceid: item?.airticket_invoice_id,
        comb_vendor: item?.comb_vendor,
        passport_name: item?.passport_name,
        airticket_issue_date: item?.airticket_issue_date
          ? dayjs(item?.airticket_issue_date)
          : undefined,
      };
    });

  useEffect(() => {
    form.setFieldValue('air_ticket_info', formattedTicketInfo);
  }, [formattedTicketInfo]);
  const client_id = form.getFieldValue('invoice_combclient_id');

  useEffect(() => {
    const air_ticket_info = invoice_info?.air_ticket_info?.map((item) => ({
      ...item,
      airticket_issue_date:
        item.airticket_issue_date && dayjs(item.airticket_issue_date),
      airticket_journey_date:
        item.airticket_journey_date && dayjs(item.airticket_journey_date),
      airticket_return_date:
        item.airticket_return_date && dayjs(item.airticket_return_date),
      airticket_fare_difference: Number(item.airticket_fare_difference),
      airticket_penalties: Number(item.airticket_penalties),
      airticket_commission_percent: Number(item.airticket_commission_percent),
    }));

    form.setFieldsValue({
      ...invoice_info,
      invoice_sales_date: dayjs(invoice_info?.invoice_sales_date),
      invoice_due_date:
        invoice_info?.invoice_due_date && dayjs(invoice_info?.invoice_due_date),
      air_ticket_info: air_ticket_info,
    });

    form.setFieldValue('ticket_no', ticketNumber);
    const ticketNo = form.getFieldValue('ticket_no') as number[];
    const existingData = async () => {
      if (client_id) {
        await exClientDataGet(client_id);
      }
    };

    existingData();
  }, [getValueSuccess, existingClient, isSuccess]);

  return (
    <ClientsStyle>
      <BreadCrumb arrOfOption={['Invoice (Air Ticket)', 'Existing']} />

      <Form
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
        layout='vertical'
      >
        <InvoiceHeaderForm
          clientOnChange={() => {
            handleClientChange();
          }}
          invoice_agent_com_amountFieldName={['invoice_agent_com_amount']}
          invoice_net_totalFieldName={['invoice_net_total']}
          form={form}
        />

        <Row justify='center' className='border py-20'>
          <SelectDataInput
            disabled={id ? true : false}
            maxCount={5}
            allowClear
            mode='multiple'
            data={existingClient?.map((item) => ({
              id: item.airticket_id,
              title: item.airticket_ticket_no,
            }))}
            size={12}
            label='Select Ticket'
            placeholder='Select ticket no'
            onChange={() => handleSelectTicket()}
            name='ticket_no'
            required
          />
        </Row>

        <div className='my-20'>
          {/* {selectedClient.length ? (
            <PreviousTicketInfo selectClient={selectedClient} />
          ) : null} */}

          <Row
            gutter={24}
            justify={'space-between'}
            style={{ position: 'relative' }}
          >
            <Col lg={18}>
              <Form.Item>
                <Form.List name={['air_ticket_info']}>
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        {fields.map((field, index) => (
                          <>
                            <NewBillingInfoForm
                              key={index}
                              form={form}
                              field={field}
                              index={index}
                              formName={'air_ticket_info'}
                            />
                          </>
                        ))}
                      </>
                    );
                  }}
                </Form.List>
              </Form.Item>
            </Col>
            <Col
              lg={6}
              style={{
                marginTop: 40,
                padding: '10px 0px !important',
              }}
              className='sticky-column border'
              prefixCls='remove_padding_col'
            >
              <FormHeaderTitle title={`Discount & Service Charge Info`} />

              <NumberInput
                size={24}
                readOnly
                label='Sub Total:'
                name={'invoice_sub_total'}
                placeholder='Overall Sub Total'
              />
              <FormInputItem
                size={24}
                label='Discount:'
                name={'invoice_discount'}
                placeholder='Overall discount'
              />
              <FormInputItem
                size={24}
                label='Service Charge:'
                name={'invoice_service_charge'}
                placeholder='Overall extra charge'
              />
              <FormInputItem
                size={24}
                label='Net Total:'
                name={'invoice_net_total'}
                placeholder='Overall net total'
              />
              <FormInputItem
                hidden
                size={24}
                label='invoice_total_vendor_price:'
                name={'invoice_total_vendor_price'}
                placeholder='Overall net total'
              />
              <FormInputItem
                hidden
                size={24}
                label='invoice_total_profit:'
                name={'invoice_total_profit'}
                placeholder='Overall net total'
              />

              <TextAreaInput size={24} label='Note' name='invoice_note' />

              <FormButton label='Submit' />
            </Col>
          </Row>
        </div>
      </Form>
    </ClientsStyle>
  );
};
export default EditExistingReissue;
const ClientsStyle = styled.div`
  .body_left {
    width: 100%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .body_right {
    width: 100%;
    margin-left: auto;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .commonBox {
    margin-bottom: 1rem;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 0.4rem;
    }
  }
  .topLeftButton {
    margin-right: 0.5rem;
    margin-bottom: auto;
  }
`;

interface ISubmit {
  invoice_combclient_id: string;
  invoice_sales_man_id: number;
  invoice_no: string;
  invoice_sales_date: string;
  invoice_due_date: string;
  invoice_agent_id: number;
  ticket_no: number;
  airticket_ticket_no: string;
  airticket_penalties: number;
  airticket_journey_date: string;
  airticket_issue_date: string;
  airticket_fare_difference: number;
  airticket_commission_percent: number;
  airticket_purchase_price: number;
  airticket_ait: string;
  airticket_return_date: string;
  airticket_classes: string;
  invoice_note: string;
  airticket_client_price: number;
  airticket_profit: number;
  comb_vendor: string;

  airticket_tax: number;
  airticket_extra_fee: number;
}
