import { Card, Col, Form, message, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Fixed2 } from '../../../../common/utils/common.utils';
import {
  DateInput,
  FormButton,
  FormInput,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import { SelectOptionsInput } from '../../../../components/common/FormItem/SelectCustomFeilds';
import SelectPaymentTypeAndAccount from '../../../Invoices/common/SelectPaymentTypeAndAccount';

import { ISubmitAirTicketSubmit } from '../../Utils/TaxRefund/AirticketTaxType';
import EditTaxRefundFormList from './components/EditTaxRefundFormList';
import {
  useGetForEditTaxRefundQuery,
  useUpdateEditedTaxRefundMutation,
} from '../../Api/Endpoints/AirTicketTaxRefundEndpoints';

const EditAirTicketRefundDetails = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [clientAccountSize, setClientAccountSize] = useState(12);
  const [vendorAccountSize, setVendorAccountSize] = useState(12);
  const { id } = useParams();

  const [
    updateTax,
    { isSuccess: updateSuccess, isError, isLoading: updateLoading },
  ] = useUpdateEditedTaxRefundMutation();

  const { data, isLoading, isSuccess } = useGetForEditTaxRefundQuery(
    { id: id! },
    { skip: id ? false : true }
  );

  // SET FIELDS VALUES
  useEffect(() => {
    if (isSuccess && data?.data) {
      form.setFieldsValue({
        ...data?.data,
        refund_date: dayjs(data.data.refund_date),
        airticket_client_price: Number(data.data.airticket_client_price),
      });
    }
  }, [data?.data, isSuccess]);

  // CALCULATION TOTAL REFUND TAX
  const ticketInfos = useWatch('ticket_info', form);
  useEffect(() => {
    if (ticketInfos) {
      form.setFieldsValue({
        vendor_total_tax_refund: ticketInfos?.reduce(
          (a: any, b: any) => a + Fixed2(b?.refund_tax_amount),
          0
        ),
      });
    }
  }, [ticketInfos]);

  const onFinish = async (values: ISubmitAirTicketSubmit) => {
    updateTax({ body: values, id: String(id) });
  };

  useEffect(() => {
    if (updateSuccess) {
      navigate('/refund/history/tax');
      message.success('Successfully updated the tax information');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isError, updateSuccess]);

  return (
    <Row justify={'center'}>
      <Card
        loading={isLoading}
        title={'Edit Invoice Air Ticket Tax Refund'}
        style={{ width: 900 }}
      >
        <Form form={form} layout='vertical' onFinish={onFinish}>
          <Card size='small' style={{ width: '100%' }}>
            <Row gutter={20}>
              <FormInput
                label='Client Name'
                name={'client_name'}
                readonly
                size={6}
              />
              <FormInput
                label='refund_invoice_id'
                name={'refund_invoice_id'}
                readonly
                size={6}
                hidden
              />
              <FormInput
                label='Invoice No.'
                name={'comb_client'}
                readonly
                size={6}
                hidden
              />
              <NumberInput
                label='Client Price'
                name={'airticket_client_price'}
                readOnly
                size={6}
              />

              <FormInput
                label='Invoice No.'
                name={'invoice_no'}
                readonly
                size={6}
              />

              <DateInput
                label='Refund Date.'
                name={'refund_date'}
                size={6}
                required
              />

              {/* Nest Form.List */}
              <Col lg={24}>
                <Form.Item>
                  <Form.List name={['ticket_info']}>
                    {(subFields, subOpt) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: 12,
                        }}
                      >
                        {subFields?.map((subField, index) => (
                          <EditTaxRefundFormList
                            key={index}
                            subField={subField}
                            subOpt={subOpt}
                            form={form}
                          />
                        ))}
                      </div>
                    )}
                  </Form.List>
                </Form.Item>
              </Col>

              {/* VENDOR REFUND INFORMATION */}
              <NumberInput
                name={'vendor_total_tax_refund'}
                label='Vendor Total Tax Refund'
                size={vendorAccountSize}
              />
              <SelectOptionsInput
                disabled
                size={vendorAccountSize}
                options={['Adjust', 'Return']}
                label='Vendor Refund Type'
                name={'vendor_refund_type'}
                onSelect={(value) =>
                  setVendorAccountSize(value === 'Adjust' ? 12 : 6)
                }
              />
              {vendorAccountSize === 6 && (
                <>
                  <SelectPaymentTypeAndAccount
                    size={vendorAccountSize}
                    form={form}
                    payMethodName='vendor_pay_type'
                    accountName='vendor_account_id'
                  />
                </>
              )}

              {/* CLIENT REFUND INFORMATION */}
              <NumberInput
                required
                label='Client Total Tax Refund'
                name={'client_total_tax_refund'}
                size={clientAccountSize}
              />
              <SelectOptionsInput
                size={clientAccountSize}
                options={['Adjust', 'Return']}
                label='Client Refund Type'
                name={'client_refund_type'}
                disabled
                onSelect={(value) =>
                  setClientAccountSize(value === 'Adjust' ? 12 : 6)
                }
              />
              {clientAccountSize === 6 && (
                <>
                  <SelectPaymentTypeAndAccount
                    size={clientAccountSize}
                    form={form}
                    payMethodName='client_pay_type'
                    accountName='client_account_id'
                  />
                </>
              )}
            </Row>
            <FormButton label='Update Tax' loading={updateLoading} />
          </Card>
        </Form>
      </Card>
    </Row>
  );
};

export default EditAirTicketRefundDetails;
