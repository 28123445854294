import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import {
  Fixed2,
  TableParams,
  handleTableChange,
  rangePresets,
} from '../../../../common/utils/common.utils';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import {
  SelectClients,
  SelectEmployee,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useLazyGetExcelsPostQuery } from '../../ledgers/endpoints/ledgerEndpoints';
import { useLazyGetAllMonthly_Sales_EarningQuery } from '../endpoints/monthly_Sales_EarningEndpoints';
import { IMonthlySalesEarningReportData } from '../types/monthly_Sales_EarningTypes';
import { Monthly_Sales_EarningUtils } from '../utils/Monthly_Sales_EarningUtils';

type Props = {};

function Monthly_Sales_Earning({}: Props) {
  const [paramData, setParamData] = useState<{
    date_range: string;
    id: number | string;
  }>();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [date_range_value, setDate_range_value] = useState<string>();
  const [fetchExcel] = useLazyGetExcelsPostQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
    employee_id: string;
  }>({
    Id: '',
    query: '',
    employee_id: '',
  });

  const [fetchMonthlySummary, { data, isLoading, isFetching, isSuccess }] =
    useLazyGetAllMonthly_Sales_EarningQuery();

  let earningAmount: number = Number(data?.data?.total_profit || 0);
  let paymentAmount: number = Number(data?.data?.cost_price || 0);
  let totalAmount: number = Number(data?.data?.sales_price || 0);
  let receiveAmount: number = Number(data?.data?.total_client_payments || 0);
  let dueAmount: number = Number(totalAmount - receiveAmount || 0);

  const onFinish = async (values: IMonthlySalesEarningReportData) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      employee_id: id ? Number(id) : values.employee_id,
      client_id: id ? Number(id) : values.invoice_combclient_id,
      date_range: date_range,
    };
    setDate_range_value(date_range);
    fetchMonthlySummary({
      client_id: body.client_id,
      employee_id: body.employee_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: body.client_id.toString(),
      employee_id: body.employee_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setParamData({
      date_range: date_range,
      id: id ? Number(id) : values.employee_id,
    });
  };

  const to_date = dayjs().format('YYYY-MM-DD');
  const previousDate1month = dayjs().format('YYYY-MM-DD');
  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
    form.setFieldValue('employee_id', 'all');
    form.setFieldValue('invoice_combclient_id', 'all');
    const date_range = `from_date=${previousDate1month}&to_date=${to_date}`;
    fetchMonthlySummary(
      {
        employee_id: 'all',
        client_id: 'all',
        query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
      },
      true
    );

    setExcelQuery({
      Id: 'all',
      employee_id: 'all',
      query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  }, []);

  //pagination--start---
  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 1, isTrash: '0', pageSize: 50 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['50', '100', '200', '500'],
      pageSize: 50,
      total: data?.count,
    },
  });

  const LastPage = Math.ceil(
    Number(data?.count || 0) / (tableParams?.pagination?.pageSize || 0)
  );

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: data?.count },
    }));
  }, [data?.count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IMonthlySalesEarningReportData = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      employee_id: id ? Number(id) : values.employee_id,
      client_id: id ? Number(id) : values.invoice_combclient_id,
      date_range: date_range,
    };

    fetchMonthlySummary({
      client_id: body.client_id,
      employee_id: body.employee_id,
      query: `${query}&${body.date_range}`,
    });
    setExcelQuery({
      Id: body.client_id.toString(),
      employee_id: body.employee_id.toString(),
      query: `${query}&${body.date_range}`,
    });
  };
  //pagination--end---

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Monthly_sales_earning_Report${date_range_value}`,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <>
      <FormHeaderTitle title='Monthly Sales Earning And Due' />
      <Table
        pagination={
          data?.count !== undefined && data?.count > 20
            ? tableParams.pagination
            : false
        }
        dataSource={data?.data?.data}
        bordered
        columns={Monthly_Sales_EarningUtils(queryData, paramData)}
        size='small'
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        onChange={(args1, args2, args3) =>
          handleTableChange({
            args: {
              pagination: args1,
              filters: args2,
              sorter: args3,
            },
            states: {
              refetch,
              setQueryData,
              setTableParams,
              tableParams,
            },
          })
        }
        summary={(_) => {
          if (tableParams.pagination?.current === LastPage)
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={6}>
                  <div className='report_total_footer'>
                    <Typography.Text strong>Total: </Typography.Text>
                  </div>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={0}>
                  <div className='report_total_footer'>
                    <Typography.Text strong>
                      {Fixed2(totalAmount)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                  <div className='report_total_footer'>
                    <Typography.Text strong>
                      {Fixed2(paymentAmount)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                  <div className='report_total_footer'>
                    <Typography.Text strong>
                      {Fixed2(earningAmount)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                  <div className='report_total_footer'>
                    <Typography.Text strong>
                      {Fixed2(receiveAmount)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                  <div className='report_total_footer'>
                    <Typography.Text strong>
                      {Fixed2(dueAmount)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
        }}
      />
    </>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Monthly Sales Earning And Due' }}
      />
    </div>
  );
  return (
    <>
      {!id && (
        <BreadCrumb arrOfOption={['Report', 'Monthly Sales Earning And Due']} />
      )}
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            if (data?.data?.data?.length)
              fetchExcel({
                client_id: excelQuery.Id,
                employee_id: excelQuery.employee_id,
                query: excelQuery.query,
                excelApiName: 'sales/monthlySalesAndEarning',
                excelName: 'Monthly_sales',
              });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <SelectClients
            name='invoice_combclient_id'
            label='Select Client'
            placeholder='Select client'
            size={4}
            showAll
            offDropDown
          />
          <SelectEmployee
            name='employee_id'
            size={4}
            label='Select sales by'
            placeholder='Select sales by'
            showAll
            offDropDown
          />
          <Col span={6} xs={24} sm={24} md={24} lg={4}>
            <Form.Item
              label='Date Range'
              name='date_range'
              rules={[{ required: true, message: ` Date Range is required!` }]}
            >
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'DD-MM-YYYY'}
                allowClear={false}
              />
            </Form.Item>
          </Col>
          <Col lg={2}>
            <FormButton
              label='Search'
              icon
              textAlign='left'
              loading={isLoading}
            />
          </Col>
        </Row>
      </Form>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
}

export default Monthly_Sales_Earning;
const Style = styled.div``;
