import { Col, Form, Select } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import {
  DateInput,
  FormInputItem,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import SelectPaymentMethod from '../../../../components/common/paymentMethod/SelectPaymentMethod';
import { useGetAccountByTypeQuery } from '../../api/endpoints/vendorPaymentEndpoints';
import { FormInstance } from 'antd/lib/form';
import { useParams } from 'react-router';
import { useWatch } from 'antd/es/form/Form';

type Props = {
  setPayMethodId: React.Dispatch<React.SetStateAction<number>>;
  payMethodId: number;
  size?: number;
  setAccountBalanced?: React.Dispatch<React.SetStateAction<number | undefined>>;
  accountId?: number;
  form?: FormInstance<any>;
  showAvailableBalanceRightSide?: Boolean;
};

const PayMethodAndAcc = ({
  setPayMethodId,
  payMethodId,
  size,
  setAccountBalanced,
  accountId,
  showAvailableBalanceRightSide,
  form,
}: Props) => {
  const { data: accounts, refetch: refetchAccount } = useGetAccountByTypeQuery(
    Number(payMethodId)
  );
  const paymentType = useWatch('advr_payment_type', form);
  const isChanged = useRef(false);

  const filteredAccountLastBalance =
    accounts?.data &&
    accounts?.data.find((item) => item.account_id == accountId);
  const vpay_availeable_balance = filteredAccountLastBalance?.last_balance;

  useEffect(() => {
    setAccountBalanced && setAccountBalanced(vpay_availeable_balance);
  }, [vpay_availeable_balance]);

  useEffect(() => {
    refetchAccount();
  }, [payMethodId]);

  useEffect(() => {
    if (isChanged.current) {
      form?.setFieldsValue({ account_id: undefined });
      return;
    }
  }, [paymentType]);

  return (
    <>
      <SelectPaymentMethod
        label='Payment method'
        name={'advr_payment_type'}
        onChange={(value: any) => {
          setPayMethodId(value);
          if (!isChanged?.current) {
            isChanged.current = true;
          }
        }}
        cheque
        size={size}
      />

      {Number(payMethodId) !== 4 && (
        <Col span={6} xs={24} sm={24} md={24} lg={size ? size : 8}>
          <Form.Item
            name={'account_id'}
            label={'Select account'}
            rules={[
              {
                required: true,
                message: `Account is required!`,
              },
            ]}
          >
            <Select allowClear placeholder='Select an account' showSearch>
              {accounts?.data &&
                accounts?.data.map((item: any) => {
                  return (
                    <Select.Option
                      value={item.account_id}
                      key={item.account_id}
                    >
                      {item.account_name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      )}

      {Number(payMethodId) !== 4 && !showAvailableBalanceRightSide && (
        <NumberInput
          label='Available Balance'
          name='vpay_availeable_balance'
          color={undefined}
          readOnly
          min='0'
          size={24}
          maxChar={14}
          minChar={0}
        />
      )}

      {Number(payMethodId) === 3 && (
        <>
          <FormInputItem
            name='trans_no'
            label='Receipt/Trans No : '
            size={size}
          />
          <FormInputItem
            name='transaction_charge'
            label='Transaction charge '
            size={size}
          />
        </>
      )}

      {Number(payMethodId) === 4 && (
        <>
          <FormInputItem
            label='Cheque No'
            name='cheque_no'
            required
            size={size}
            mdSize={24}
            smSize={24}
          />
          <DateInput
            name='vpcheque_withdraw_date'
            label='Withdraw Date '
            required
            size={size}
            mdSize={24}
            smSize={24}
          />
          <FormInputItem
            name={'bank_name'}
            label='Bank Name'
            required
            size={size}
            mdSize={24}
            smSize={24}
          />
        </>
      )}
    </>
  );
};

export default PayMethodAndAcc;
