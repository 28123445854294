import { Card, Col, Form, FormInstance, InputNumber, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { useState, useEffect, useRef } from 'react';
import {
  DateInput,
  FormInputItem,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import SelectPaymentMethod from '../../../../components/common/paymentMethod/SelectPaymentMethod';
import { IaccountsByMethod } from '../../../Accounts/AccountsTypes/AccountsTypes';
import {
  useLazyGetAllAccountsByTypeQuery,
  useLazyGetSingleAccountQuery,
} from '../../../Accounts/Api/Endpoints/accountsEndpoints';
import { refund_type } from '../../RefundTypes/RefundTypes';

function TourRefundVendorForm({
  name,
  index,
  form,
  vendorRefundTotal,
}: {
  name: string;
  index: number;
  form: FormInstance<any>;
  vendorLastBalance?: number;
  vendorRefundTotal?: number;
  vendorcharge?: number;
}) {
  const [vendorRefundMethod, setVendorRefundMethod] =
    useState<string>('ADJUST');
  const [paymentType, setPaymentType] = useState<string>();
  const isChanged = useRef(false);
  const [paymentMethod, setPaymentMethod] = useState<string>();
  const [accountID, setAccountID] = useState<number>();
  const [balance, setBalance] = useState<number>();

  const [fetchAccountsByPayment, { data: accountsByPayment }] =
    useLazyGetAllAccountsByTypeQuery();

  useEffect(() => {
    if (paymentMethod) {
      fetchAccountsByPayment(Number(paymentMethod));
    }
    if (isChanged.current && form) {
      form.setFieldsValue({ vrefund_account_id: undefined });
      setBalance(undefined);
      return;
    }
  }, [paymentType, paymentMethod]);

  const [fetchBalance, { data: accountBalance }] =
    useLazyGetSingleAccountQuery();

  useEffect(() => {
    if (accountID) {
      fetchBalance(accountID);
    }
  }, [accountID]);

  useEffect(() => {
    setBalance(accountBalance?.data && accountBalance?.data.amount);
  }, [accountBalance]);

  useEffect(() => {
    form.setFields([
      {
        name: [`vendor_refund_info_${name}`, index, 'vrefund_return_amount'],
        value: vendorRefundTotal,
      },
    ]);
    form.setFields([
      {
        name: [`vendor_refund_info_${name}`, index, 'date'],
        value: dayjs(),
      },
    ]);
    form.setFields([
      {
        name: [`vendor_refund_info_${name}`, index, 'itinerary_id'],
        value: dayjs(),
      },
    ]);
  }, []);
  return (
    <Card>
      {/* {vendorLastBalance && vendorLastBalance >= 0 ? ( */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col span={5} xs={24} sm={24} md={24} lg={8}>
          <Form.Item
            label='Vendor Refund Type'
            name={[`vendor_refund_info_${name}`, index, 'vrefund_payment_type']}
            rules={[
              {
                required: true,
                message: 'Vendor Refund Type is required',
              },
            ]}
            initialValue='ADJUST'
          >
            <Select
              allowClear
              placeholder='Select Vendor Refund Type'
              style={{ width: '100%' }}
              onChange={(e: string) => {
                setVendorRefundMethod(e);
              }}
            >
              {refund_type.map((item) => {
                return (
                  <Select.Option
                    key={item}
                    value={item == 'Money Return' ? 'MONEY_RETURN' : 'ADJUST'}
                  >
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        {vendorRefundMethod == 'ADJUST' ? (
          <NumberInput
            name={[
              `vendor_refund_info_${name}`,
              index,
              'vrefund_return_amount',
            ]}
            label='Advance Amount'
            readOnly
            size={8}
            min='0'
            maxChar={14}
            minChar={0}
          />
        ) : (
          <>
            {paymentType == 'CHEQUE' ? (
              <>
                <FormInputItem
                  label='Cheque No.'
                  required
                  name={[`vendor_refund_info_${name}`, index, 'cheque_no']}
                  size={8}
                />
                <DateInput
                  label='Withdraw Date'
                  name={[`vendor_refund_info_${name}`, index, 'withdraw_date']}
                  required
                  size={8}
                />
                <FormInputItem
                  label='Bank Name'
                  name={[`vendor_refund_info_${name}`, index, 'bank_name']}
                  required
                  size={8}
                />
                <Col span={6} xs={24} sm={24} md={24} lg={8}>
                  <Form.Item
                    name={[
                      `vendor_refund_info_${name}`,
                      index,
                      'vrefund_return_amount',
                    ]}
                    label='Return Amount'
                    rules={[
                      {
                        validator: (_, value) => {
                          if (vendorRefundTotal !== undefined) {
                            if (value > vendorRefundTotal) {
                              return Promise.reject(
                                'Return amount cannot be greater than total refund amount!!'
                              );
                            } else {
                              return Promise.resolve();
                            }
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <DateInput
                  label='Date'
                  name={[`vendor_refund_info_${name}`, index, 'date']}
                  required
                  size={8}
                />
                <FormInputItem
                  label='Note'
                  name={[`vendor_refund_info_${name}`, index, 'note']}
                  size={8}
                />
              </>
            ) : (
              <>
                <SelectPaymentMethod
                  name={[
                    `vendor_refund_info_${name}`,
                    index,
                    'vrefund_moneyreturn_type',
                  ]}
                  label='Payment Method'
                  size={8}
                  onChange={(value: string) => {
                    setPaymentMethod(value);
                    if (!isChanged.current) isChanged.current = true;
                  }}
                />
                <Col span={6} xs={24} sm={24} md={24} lg={8}>
                  <Form.Item
                    name={[
                      `vendor_refund_info_${name}`,
                      index,
                      'vrefund_account_id',
                    ]}
                    label='Account'
                    rules={[
                      {
                        required: true,
                        message: `Account is required!`,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder={'Select Account'}
                      optionFilterProp='children'
                      onSelect={(e: number) => {
                        setAccountID(e);
                      }}
                    >
                      {accountsByPayment?.data?.map(
                        (item: IaccountsByMethod) => {
                          return (
                            <Select.Option
                              key={item.account_id}
                              value={item.account_id}
                            >
                              {`${item.account_name}`}
                            </Select.Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={6} xs={24} sm={24} md={24} lg={8}>
                  <Form.Item
                    name={[
                      `vendor_refund_info_${name}`,
                      index,
                      'vrefund_return_amount',
                    ]}
                    label='Return Amount'
                    rules={[
                      {
                        validator: (_, value) => {
                          if (vendorRefundTotal !== undefined) {
                            if (value > vendorRefundTotal) {
                              return Promise.reject(
                                'Return amount cannot be greater than total refund amount!!'
                              );
                            } else {
                              return Promise.resolve();
                            }
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                </Col>

                <Col span={6} xs={24} sm={24} md={24} lg={8}>
                  <Form.Item label='Available Balance' required>
                    <InputNumber
                      style={{ width: '100%' }}
                      readOnly
                      value={balance}
                    />
                  </Form.Item>
                </Col>
                {Number(paymentMethod) === 3 ? (
                  <Col span={6} xs={24} sm={24} md={24} lg={8}>
                    <Form.Item
                      label='Transaction Charge'
                      name={[
                        `vendor_refund_info_${name}`,
                        index,
                        'trxn_charge_amount',
                      ]}
                      required
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                ) : (
                  <></>
                )}
                {Number(paymentMethod) === 3 ? (
                  <Col span={6} xs={24} sm={24} md={24} lg={8}>
                    <Form.Item
                      label='Transaction Charge'
                      name={[
                        `vendor_refund_info_${name}`,
                        index,
                        'trxn_charge_amount',
                      ]}
                      required
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                ) : (
                  <></>
                )}
                <DateInput
                  label='Date'
                  name={[`vendor_refund_info_${name}`, index, 'date']}
                  required
                  size={8}
                />
              </>
            )}
          </>
        )}
      </Row>
    </Card>
  );
}

export default TourRefundVendorForm;
