import { Col, Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import React from 'react';

type Props = { name: NamePath };

export default function BillingIsDeleted({ name }: Props) {
  return (
    <Col span={0} xs={0} style={{ contentVisibility: 'hidden' }}>
      <Form.Item label={'Deleted'} name={name}>
        <Select>
          <Select.Option key='0' value='0'>
            0
          </Select.Option>
          <Select.Option key='1' value='1'>
            1
          </Select.Option>
        </Select>
      </Form.Item>
    </Col>
  );
}
