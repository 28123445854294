import { CloudUploadOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Col, message, Modal, Row, Upload, UploadFile } from 'antd';
import { UploadProps } from 'antd/lib';
import { useEffect, useState } from 'react';
import { useUploadBSPBillingMutation } from '../api/bspBillApisEndpoints';
import { UploadPdfData } from '../type/BSPType';
import { useNavigate } from 'react-router';
import { CustomError } from '../../../../type';

const { Dragger } = Upload;

interface Props {
  setResData?: React.Dispatch<
    React.SetStateAction<UploadPdfData[] | undefined>
  >;
  noModal?: boolean;
}

const UploadBSPBilling = ({ setResData, noModal }: Props) => {
  const navigate = useNavigate();
  const [upload, { isLoading, isError, isSuccess, data, error }] =
    useUploadBSPBillingMutation();

  const CustomError = error as CustomError;

  const info = data?.data;

  useEffect(() => {
    setResData && setResData(info);
  }, [info]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    navigate('/reports/bsp-bill-list');
    // const formData = new FormData();
    // if (fileList?.length) {
    //   formData.append('file', fileList[0]?.originFileObj || '');
    //   upload(formData);
    // }
  };

  const submit = () => {
    const formData = new FormData();

    fileList?.map((item) => formData.append('file', item.originFileObj || ''));
    for (const value of formData.values()) {
      console.log(value);
    }

    upload(formData);
  };

  const handleCancel = () => {
    setFileList([]);
    setIsModalOpen(false);
  };

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    // action: '',
    beforeUpload: (e) => {
      const { type } = e;
      if (type === 'application/pdf') {
        return false;
      } else {
        message.error('Only PDF files are allowed');
      }
    },
    maxCount: 10,
    fileList: fileList,
    accept: 'application/pdf',

    onChange(info) {
      setFileList(info.fileList);

      const { status } = info.file;
      if (status !== 'uploading') {
      }
      if (status === 'done') {
        message.success(`{info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`{info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      // console.log('Dropped files', e.dataTransfer.files);
    },
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Pdf files uploaded');
      setFileList([]);
    } else if (isError) {
      // message.error('Something went wrong');
      message.error(CustomError?.data?.message);
    }
  }, [isError, isSuccess, isError]);

  let jsx = <div>Loading ...</div>;

  if (noModal) {
    jsx = (
      <>
        <Row justify={'center'}>
          <Col span={10}>
            <Dragger {...props} height={200}>
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
              <p className='ant-upload-text'>Upload New BSP PDF File</p>
              <p className='ant-upload-hint'>
                Support for a single upload: Upload BSP billing PDF document for
                checking calculation.
              </p>
            </Dragger>
          </Col>
        </Row>
        <Row
          hidden={fileList?.length ? false : true}
          justify={'end'}
          style={{ marginTop: 10 }}
        >
          <Col span={8}>
            <Button onClick={submit} type='primary'>
              Submit
            </Button>
          </Col>
        </Row>
      </>
    );
  } else {
    jsx = (
      <>
        <Button
          size='small'
          // type='primary'
          onClick={showModal}
          icon={<CloudUploadOutlined />}
          loading={isLoading}
        />
        <Modal
          title='Upload BSP Billing PDF Document'
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          destroyOnClose={true}
          confirmLoading={isLoading}
        >
          <Dragger {...props}>
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>
              Click or drag file to this area to upload
            </p>
            <p className='ant-upload-hint'>
              Support for a single upload: Upload BSP billing PDF document for
              checking calculation.
            </p>
          </Dragger>
        </Modal>
      </>
    );
  }

  return jsx;
};

export default UploadBSPBilling;
