import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import {
  IAirTicketInfo,
  IFlights,
  IPaxDetails,
} from '../../../../../../../common/types/commonInterfaces';
import {
  Fixed2,
  FormatDate,
  filterNullColumns,
  isNotEmpty,
} from '../../../../../../../common/utils/common.utils';
import getInvoiceViewPageController from '../../../../../../../modules/IATA_AIR_TICKET/utils/ts/getInvoiceViewPageController';
import LoadingIndicator from '../../../../../spinner/LoadingIndicator';
import { useGetFakeInvoiceQuery } from '../../editedInvoiceApiEndpoints';
import { HeightRefsType } from '../ViewFakeInvoice';

type FakeFlightAndPaxViewProps = {
  flights?: IFlights[];
  paxDetails?: IPaxDetails[];
  airticketDetails?: IAirTicketInfo[];
  heightRefs: HeightRefsType;
};
export const PAX_TYPES = {
  1: 'Adult',
  2: 'Child',
  3: 'Infant',
};

function getDiscountInPercentage({
  airticket_base_fare,
  airticket_discount_total,
}: {
  airticket_base_fare: number;
  airticket_discount_total: number;
}) {
  const discountPrice = (airticket_base_fare / 100) * airticket_discount_total;
  return discountPrice;
}

export interface mergeAirTicketType {
  airticket_ticket_no: string | undefined;
  airticket_pnr: string | undefined;
  airticket_classes: string | undefined;
  airticket_ticket_type: string | undefined;
  airticket_routes: string | undefined;
  airticket_journey_date?: Date | undefined;
  airticket_return_date?: string;
  new_airticket_unit_price?: number;
  new_airticket_discount?: number;
  new_airticket_total?: number;
}

export const FakeFlightAndPaxView = ({
  flights,
  paxDetails,
  airticketDetails,
  heightRefs,
}: FakeFlightAndPaxViewProps) => {
  const invoice_id = Fixed2(useParams().id);

  const [billingDetails, setBillingDetails] = useState<mergeAirTicketType[]>();

  const { data, isLoading, isSuccess } = useGetFakeInvoiceQuery({
    id: invoice_id,
  });

  useEffect(() => {
    const formateBillingDetails = data?.data?.infos.map((item, index) => ({
      airticket_ticket_no: item.tii_airticket_no,
      airticket_pnr: item.tii_airticket_pnr,
      airticket_classes: item.tii_airticket_class,
      airticket_ticket_type: item.tii_airticket_class_type,
      airticket_routes:
        airticketDetails && airticketDetails[index]?.airticket_routes,
      airticket_journey_date: item.tii_journey_date,
      airticket_return_date:
        airticketDetails && airticketDetails[index]?.airticket_return_date,
      new_airticket_unit_price: Fixed2(item.tii_unit_price),
      new_airticket_discount: Fixed2(item.tii_airticket_discount),
      new_airticket_total:
        Fixed2(item.tii_unit_price) - Fixed2(item.tii_airticket_discount),
    }));

    if (formateBillingDetails?.length) {
      setBillingDetails(formateBillingDetails);
    }
  }, [data?.data, invoice_id, isSuccess]);

  const flightsColumn: ColumnsType<IFlights> = [
    {
      title: <span className='table-column-title'> Sl.</span>,
      align: 'center',
      render(value, record, index) {
        return <>{index + 1}</>;
      },
    },
    {
      title: <span className='table-column-title'> From</span>,
      align: 'left',
      dataIndex: 'flight_from',
      key: 'flight_from',
    },
    {
      title: <span className='table-column-title'> To</span>,
      align: 'left',
      dataIndex: 'flight_to',
      key: 'flight_to',
    },
    {
      title: <span className='table-column-title'> Airline</span>,
      align: 'left',
      dataIndex: 'airline_name',
      key: 'airline_name',
    },
    {
      title: <span className='table-column-title'> Departure Time</span>,
      align: 'right',
      dataIndex: 'fltdetails_departure_time',
      key: 'fltdetails_departure_time',
    },
    {
      title: <span className='table-column-title'> Arrival Time</span>,
      dataIndex: 'fltdetails_arrival_time',
      key: 'fltdetails_arrival_time',
      align: 'right',
    },
  ];

  const billingsColumn: ColumnsType<any> = [
    {
      title: <span className='table-column-title'>Sl.</span>,
      render(value, record, index) {
        return <>{index + 1}</>;
      },
      align: 'center',
    },

    {
      title: <span className='table-column-title'>Ticket No</span>,
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
      align: 'left',
      render: (text: string) => <span className='table-column'>{text}</span>,
    },

    {
      title: <span className='table-column-title'>PNR</span>,
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
      align: 'left',
      render: (text: string) => <span className='table-column'>{text} </span>,
    },
    {
      title: <span className='table-column-title'> Class</span>,
      dataIndex: 'airticket_classes',
      key: 'airticket_classes',
      align: 'center',
      render: (text: string) => <span className='table-column'>{text} </span>,
    },
    {
      title: <span className='table-column-title'> Class Type</span>,
      dataIndex: 'airticket_ticket_type',
      key: 'airticket_ticket_type',
      align: 'center',
      render: (text: string) => <span className='table-column'>{text} </span>,
    },
    {
      title: <span className='table-column-title'>Route</span>,
      dataIndex: 'airticket_routes',
      key: 'airticket_routes',
      align: 'left',
    },

    {
      title: <span className='table-column-title'> Journey Date </span>,
      key: 'airticket_journey_date',
      render: (_, data) => (
        <span className='table-column'>
          {FormatDate(data.airticket_journey_date)}
        </span>
      ),
    },
    {
      title: <span className='table-column-title'> Return Date </span>,
      key: 'airticket_return_date',
      render: (_, data) => (
        <span className='table-column'>
          {FormatDate(data.airticket_return_date)}
        </span>
      ),
    },
    {
      title: <span className='table-column-title'> Unit Price </span>,
      dataIndex: 'new_airticket_unit_price',
      key: 'new_airticket_unit_price',
      align: 'right',
    },

    {
      title: <span className='table-column-title'> Discount </span>,
      dataIndex: 'new_airticket_discount',
      key: 'new_airticket_discount',
      align: 'right',
    },
    {
      title: <span className='table-column-title'> Total </span>,
      dataIndex: 'new_airticket_total',
      key: 'new_airticket_total',
      align: 'right',
      render: (text: string, record) => (
        <span className='table-column'>{Fixed2(text)}</span>
      ),
    },
  ];

  const filteredBillingColumns = filterNullColumns(
    airticketDetails,
    billingsColumn
  );

  const paxDetailsColumn: ColumnsType<any> = [
    {
      title: <span className='table-column-title'> Sl. </span>,
      align: 'center',
      render(value, record, index) {
        return <>{index + 1}</>;
      },
    },
    {
      title: <span className='table-column-title'>Pax Name</span>,
      dataIndex: 'passport_name',
      key: 'passport_name',
    },
    {
      title: <span className='table-column-title'>Pax Type</span>,
      dataIndex: 'passport_person_type',
      key: 'passport_person_type',
      render(value, record, index) {
        return (
          <>
            {/* value some time get text and some time get id */}
            {typeof value === 'number' || Fixed2(value)
              ? PAX_TYPES[value as 1 | 2 | 3]
              : value}
          </>
        );
      },
    },

    {
      title: <span className='table-column-title'> Passport No </span>,
      dataIndex: 'passport_passport_no',
      key: 'passport_passport_no',
    },
    {
      title: <span className='table-column-title'> NID</span>,
      dataIndex: 'passport_nid_no',
      key: 'passport_nid_no',
      align: 'center',
    },
    {
      title: <span className='table-column-title'>Mobile</span>,
      dataIndex: 'passport_mobile_no',
      key: 'passport_mobile_no',
      render: (text: string) => <span className='table-column'>{text}</span>,
    },
    {
      title: <span className='table-column-title'> Issue Date </span>,
      key: 'passport_date_of_issue',
      dataIndex: 'passport_date_of_issue',
      render: (_, data) =>
        data.passport_date_of_issue
          ? dayjs(data.passport_date_of_issue).format('D-MM-YYYY')
          : '',
    },
    {
      title: <span className='table-column-title'> Expire Date </span>,
      key: 'passport_date_of_expire',
      dataIndex: 'passport_date_of_expire',
      render: (_, data) =>
        data.passport_date_of_expire
          ? dayjs(data.passport_date_of_expire).format('D-MM-YYYY')
          : '',
    },
  ];

  // @HANDLE PRINT DOUBLE PAGE
  // states, variables and references
  const filight_ref = useRef<HTMLDivElement>(null);
  const pax_ref = useRef<HTMLDivElement>(null);
  const billing_ref = useRef<HTMLDivElement>(null);

  const [pageController, setPageController] = useState<
    {
      identifier: string;
      marginTop: number;
      bottomIdentifier: string;
    }[]
  >();

  useEffect(() => {
    const filight_ref_height = Fixed2(filight_ref.current?.clientHeight) / 96; // value in inch
    const pax_ref_height = Fixed2(pax_ref.current?.clientHeight) / 96; // value in inch
    const billing_ref_height = Fixed2(billing_ref.current?.clientHeight) / 96; // value in inch

    const header_height =
      Fixed2(heightRefs.header_ref.current?.clientHeight) / 96; // value in inch
    const signiture_height =
      Fixed2(heightRefs.signiture_ref.current?.clientHeight) / 96; // value in inch
    const subtotal_height =
      Fixed2(heightRefs.subtotal_ref.current?.clientHeight) / 96; // value in inch

    const pageControl: any = getInvoiceViewPageController({
      topHeight: header_height,
      footerHeight: subtotal_height + signiture_height,
      pageHeight: 11.7,
      items: [
        {
          height: filight_ref_height,
          identifier: 'flight',
        },
        {
          height: pax_ref_height,
          identifier: 'pax_details',
        },
        {
          height: billing_ref_height,
          identifier: 'billing',
        },
        {
          height: subtotal_height + signiture_height,
          identifier: 'footer',
        },
      ],
    });

    setPageController(pageControl);
  }, [flights, paxDetails, airticketDetails]);

  return (
    <div className='mt-20'>
      {isLoading && <LoadingIndicator />}
      <div ref={filight_ref}>
        {isNotEmpty(flights) && (
          <>
            <Typography.Title level={5} style={{ marginBottom: '5px' }}>
              FLIGHT/ROUTE DETAILS
            </Typography.Title>
            {
              <Table
                size='small'
                bordered
                rowKey={(e) => e.fltdetails_arrival_time}
                style={{ marginBottom: '2rem' }}
                className='invoiceBillingTable'
                rowClassName={'invoiceBillingTd'}
                dataSource={flights}
                columns={flightsColumn}
                pagination={false}
              />
            }
          </>
        )}
      </div>

      <div
        ref={pax_ref}
        style={{
          marginTop:
            pageController && pageController[1].identifier === 'pax_details'
              ? `${pageController[1].marginTop}in`
              : 0,
          // Add border top for identify page end
          borderTop:
            pageController &&
            pageController[1].identifier === 'pax_details' &&
            pageController[1].marginTop
              ? '4px dashed lightgray'
              : '',
          paddingTop:
            pageController &&
            pageController[1].identifier === 'pax_details' &&
            pageController[1].marginTop
              ? '0.5in'
              : '',
        }}
      >
        {paxDetails?.length ? (
          <>
            <Typography.Title level={5} style={{ marginBottom: '5px' }}>
              PAX DETAILS
            </Typography.Title>
            {
              <Table
                size='small'
                rowKey={(e) => e.airticket_ticket_no}
                style={{ marginBottom: '2rem' }}
                className='invoiceBillingTable'
                rowClassName={'invoiceBillingTd'}
                dataSource={paxDetails}
                columns={paxDetailsColumn}
                pagination={false}
                bordered
              />
            }
          </>
        ) : (
          <></>
        )}
      </div>

      <div
        ref={billing_ref}
        style={{
          // billing margin control
          marginTop:
            pageController && pageController[2].identifier === 'billing'
              ? `${pageController[2].marginTop}in`
              : 0,
          borderTop:
            pageController &&
            pageController[2].identifier === 'billing' &&
            pageController[2].marginTop
              ? '4px dashed lightgray'
              : '',
          paddingTop:
            pageController &&
            pageController[2].identifier === 'billing' &&
            pageController[2].marginTop
              ? '0.5in'
              : '',
        }}
      >
        {billingDetails?.length && (
          <>
            <Typography.Title level={5} style={{ marginBottom: '5px' }}>
              BILLING INFO
            </Typography.Title>
            <Table
              size='small'
              bordered
              rowKey={(e) => e.airticket_ticket_no}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              dataSource={billingDetails}
              columns={filteredBillingColumns.col}
              pagination={false}
            />
          </>
        )}
      </div>
      {/* THIS IS CREATE ONLY FOR CONTROLL FOOTER */}
      <div
        style={{
          // footer margin control
          marginTop:
            pageController && pageController[3].identifier === 'footer'
              ? `${pageController[3].marginTop}in`
              : 0,
          borderTop:
            pageController &&
            pageController[3].identifier === 'footer' &&
            pageController[3].marginTop
              ? '4px dashed lightgray'
              : '',
          paddingTop:
            pageController &&
            pageController[3].identifier === 'footer' &&
            pageController[3].marginTop
              ? '0.5in'
              : '',
        }}
      ></div>
    </div>
  );
};
