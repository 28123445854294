import { ColumnsType } from 'antd/es/table';
import Table from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import {
  IViewBillingInfo,
  IViewHajjiInfo,
} from '../../../../common/types/commonInterfaces';
import { Fixed2 } from '../../../../common/utils/common.utils';
import {
  HotelInformation,
  TransportInformation,
} from '../../../Invoice(Other)/Type/Invoce.other.interface';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import getInvoiceViewPageController from '../../../IATA_AIR_TICKET/utils/ts/getInvoiceViewPageController';

type Props = {
  billing_information: IViewBillingInfo[];
  haji_information: IViewHajjiInfo[];
  hotel_information: HotelInformation[];
  transport_information: TransportInformation[];
};
const ViewInvoiceHajjBilling = ({
  billing_information,
  haji_information,
  hotel_information,
  transport_information,
}: Props) => {
  const transportColumn: ColumnsType<TransportInformation> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Name',
      dataIndex: 'ttype_name',
      key: 'ttype_name',
      align: 'center',
    },
    {
      title: 'Pickup',
      dataIndex: 'transport_pickup_place',
      key: 'transport_pickup_place',
      align: 'center',
    },
    {
      title: 'Pickup Time',
      dataIndex: 'transport_pickup_time',
      key: 'transport_pickup_time',
      align: 'center',
    },
    {
      title: 'Dropoff',
      dataIndex: 'transport_dropoff_place',
      key: 'transport_dropoff_place',
      align: 'center',
    },
    {
      title: 'Dropoff Time',
      dataIndex: 'transport_dropoff_time',
      key: 'transport_dropoff_time',
      align: 'center',
    },
  ];
  const hotelColumn: ColumnsType<HotelInformation> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Name',
      dataIndex: 'hotel_name',
      key: 'hotel_name',
      align: 'center',
    },
    {
      title: 'Check In',
      dataIndex: 'hotel_check_in_date',
      key: 'hotel_check_in_date',
      align: 'center',
      render: (_, data) => (
        <>
          {' '}
          {data?.hotel_check_in_date
            ? dayjs(data?.hotel_check_in_date).format('D/MM/YYYY')
            : undefined}{' '}
        </>
      ),
    },

    {
      title: 'Check Out',
      dataIndex: 'hotel_check_out_date',
      key: 'hotel_check_out_date',
      align: 'center',
      render: (_, data) => (
        <>
          {' '}
          {data?.hotel_check_out_date
            ? dayjs(data?.hotel_check_out_date).format('D/MM/YYYY')
            : undefined}{' '}
        </>
      ),
    },
  ];
  const hajiColumn: ColumnsType<IViewHajjiInfo> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Name',
      dataIndex: 'passport_name',
      key: 'passport_name',
      align: 'center',
    },
    {
      title: 'NID',
      dataIndex: 'passport_nid_no',
      key: 'passport_nid_no',
      align: 'right',
    },

    {
      title: 'Mobile',
      dataIndex: 'passport_mobile_no',
      key: 'passport_mobile_no',
      align: 'right',
    },
    {
      title: 'Serial No',
      dataIndex: 'hajiinfo_serial',
      key: 'hajiinfo_serial',
      align: 'right',
    },
    {
      title: 'Tracking No',
      dataIndex: 'hajiinfo_tracking_number',
      key: 'hajiinfo_tracking_number',
      align: 'right',
    },
  ];

  const billingColumn: ColumnsType<IViewBillingInfo> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
      align: 'center',
    },
    {
      title: 'Pax Name',
      dataIndex: 'pax_name',
      key: 'pax_name',
      align: 'center',
    },

    ...(billing_information.some((item) => item.billing_description)
      ? ([
          {
            title: 'Description',
            dataIndex: 'billing_description',
            key: 'billing_description',
            align: 'center',
          },
        ] as ColumnsType<any>)
      : []),

    {
      title: 'Quantity',
      dataIndex: 'billing_quantity',
      key: 'billing_quantity',
      align: 'right',
    },
    {
      title: 'Unit Price',
      dataIndex: 'billing_unit_price',
      key: 'billing_unit_price',
      align: 'right',
    },
    {
      title: 'Sub Total',
      dataIndex: 'billing_subtotal',
      key: 'billing_subtotal',
      align: 'right',
    },
  ];

  const hajiTicketColumn: ColumnsType<IViewHajjiInfo> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Ticket NO',
      dataIndex: 'ticket_no',
      key: 'ticket_no',
      align: 'center',
    },
    {
      title: 'PNR',
      dataIndex: 'ticket_pnr',
      key: 'ticket_pnr',
      align: 'center',
    },

    {
      title: 'Airline',
      dataIndex: 'ticket_airline_id',
      key: 'ticket_airline_id',
      align: 'center',
    },
    {
      title: 'Reference No',
      dataIndex: 'ticket_reference_no',
      key: 'ticket_reference_no',
      align: 'center',
    },
    {
      title: 'Journey Date',
      dataIndex: 'ticket_journey_date',
      key: 'ticket_journey_date',
      align: 'center',
      render(value, record, index) {
        return value ? dayjs(value).format('DD-MM-YYYY') : '';
      },
    },
    {
      title: 'Return Date',
      dataIndex: 'ticket_return_date',
      key: 'ticket_return_date',
      align: 'center',
      render(value, record, index) {
        return value ? dayjs(value).format('DD-MM-YYYY') : '';
      },
    },
  ];

  // @HANDLE PRINT DOUBLE PAGE
  // states, variables and references
  const billing_information_ref = useRef<HTMLDivElement>(null);
  const haji_information_ref = useRef<HTMLDivElement>(null);
  const hotel_information_ref = useRef<HTMLDivElement>(null);
  const transport_information_ref = useRef<HTMLDivElement>(null);

  const [pageController, setPageController] = useState<
    {
      identifier: string;
      marginTop: number;
      bottomIdentifier: string;
    }[]
  >();

  useEffect(() => {
    const haji_information_height =
      Fixed2(haji_information_ref.current?.clientHeight) / 96; // value in inch
    const transport_information_height =
      Fixed2(transport_information_ref.current?.clientHeight) / 96; // value in inch
    const hotel_information_height =
      Fixed2(hotel_information_ref.current?.clientHeight) / 96; // value in inch
    const billing_information_height =
      Fixed2(billing_information_ref.current?.clientHeight) / 96; // value in inch

    const pageControl: any = getInvoiceViewPageController({
      topHeight: 3,
      footerHeight: 2.5,
      pageHeight: 11.7,
      items: [
        {
          height: haji_information_height,
          identifier: 'haji_information',
        },
        {
          height: transport_information_height,
          identifier: 'transport_information',
        },
        {
          height: hotel_information_height,
          identifier: 'hotel_information',
        },
        {
          height: billing_information_height,
          identifier: 'billing_information',
        },
        {
          height: 3,
          identifier: 'footer',
        },
      ],
    });

    setPageController(pageControl);
  }, [
    billing_information,
    haji_information,
    hotel_information,
    transport_information,
  ]);

  return (
    <div>
      {/* index 0 */}
      <div ref={haji_information_ref}>
        {haji_information.length ? (
          <>
            <FormHeaderTitle title='Pilgrim Information' />
            <Table
              bordered
              size='small'
              rowKey={(e) => Math.random()}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              dataSource={haji_information}
              columns={hajiColumn}
              pagination={false}
            />
            <Table
              bordered
              size='small'
              rowKey={(e) => Math.random()}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              dataSource={haji_information}
              columns={hajiTicketColumn}
              pagination={false}
              style={{ marginTop: '10px' }}
            />
          </>
        ) : (
          <></>
        )}
      </div>

      {/* index 1 */}
      <div
        ref={transport_information_ref}
        style={{
          marginTop:
            pageController &&
            pageController[1].identifier === 'transport_information'
              ? `${pageController[1].marginTop}in`
              : 0,
          // Add border top for identify page end
          borderTop:
            pageController &&
            pageController[1].identifier === 'transport_information' &&
            pageController[1].marginTop
              ? '4px dashed lightgray'
              : '',
          paddingTop:
            pageController &&
            pageController[1].identifier === 'transport_information' &&
            pageController[1].marginTop
              ? '0.5in'
              : '',
        }}
      >
        {transport_information.length ? (
          <>
            <FormHeaderTitle title='Transport Information' />
            <Table
              size='small'
              bordered
              rowKey={(e) => Math.random()}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              dataSource={transport_information}
              columns={transportColumn}
              pagination={false}
            />
          </>
        ) : (
          <></>
        )}
      </div>

      {/* index 2 */}
      <div
        ref={hotel_information_ref}
        style={{
          marginTop:
            pageController &&
            pageController[2].identifier === 'hotel_information'
              ? `${pageController[2].marginTop}in`
              : 0,
          // Add border top for identify page end
          borderTop:
            pageController &&
            pageController[2].identifier === 'hotel_information' &&
            pageController[2].marginTop
              ? '4px dashed lightgray'
              : '',
          paddingTop:
            pageController &&
            pageController[2].identifier === 'hotel_information' &&
            pageController[2].marginTop
              ? '0.5in'
              : '',
        }}
      >
        {hotel_information.length ? (
          <>
            <FormHeaderTitle title='Hotel Information' />
            <Table
              size='small'
              bordered
              rowKey={(e) => Math.random()}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              dataSource={hotel_information}
              columns={hotelColumn}
              pagination={false}
            />
          </>
        ) : (
          <></>
        )}
      </div>

      {/* index 3 */}
      <div
        ref={billing_information_ref}
        style={{
          marginTop:
            pageController &&
            pageController[3].identifier === 'billing_information'
              ? `${pageController[3].marginTop}in`
              : 0,
          // Add border top for identify page end
          borderTop:
            pageController &&
            pageController[3].identifier === 'billing_information' &&
            pageController[3].marginTop
              ? '4px dashed lightgray'
              : '',
          paddingTop:
            pageController &&
            pageController[3].identifier === 'billing_information' &&
            pageController[3].marginTop
              ? '0.5in'
              : '',
        }}
      >
        <FormHeaderTitle title='BILLING INFORMATION' />
        <Table
          size='small'
          bordered
          rowKey={(e) => Math.random()}
          className='invoiceBillingTable'
          rowClassName={'invoiceBillingTd'}
          dataSource={billing_information}
          columns={billingColumn}
          pagination={false}
        />
      </div>

      {/* THIS IS CREATE ONLY FOR CONTROLL FOOTER */}
      <div
        style={{
          // footer margin control
          marginTop:
            pageController && pageController[4].identifier === 'footer'
              ? `${pageController[4].marginTop}in`
              : 0,
          borderTop:
            pageController &&
            pageController[4].identifier === 'footer' &&
            pageController[4].marginTop
              ? '4px dashed lightgray'
              : '',
          paddingTop:
            pageController &&
            pageController[4].identifier === 'footer' &&
            pageController[4].marginTop
              ? '0.5in'
              : '',
        }}
      ></div>
    </div>
  );
};

export default ViewInvoiceHajjBilling;
