import {
  Button,
  Col,
  Divider,
  Form,
  FormListFieldData,
  message,
  Modal,
  Row,
} from 'antd';
import { FormInstance, FormListOperation } from 'antd/lib';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { IViewInvoiceDetails } from '../../../../../common/types/commonInterfaces';
import { NumberInput } from '../../../FormItem/FormItems';
import InvoiceAirTicket_Input from './InvoiceAirTicket_Input';
import InvoiceBilling_input from './InvoiceBilling_input';
import {
  FakeInvoicePostType,
  usePostFakeInvoiceMutation,
} from './editedInvoiceApiEndpoints';
import { Fixed2 } from '../../../../../common/utils/common.utils';
import { useWatch } from 'antd/es/form/Form';
import { PlusOutlined } from '@ant-design/icons';

type Props = {
  invoiceData: IViewInvoiceDetails;
  setFakeEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  fakeEditModal: boolean;
};

const MakeFakeInvoice = ({
  invoiceData,
  fakeEditModal,
  setFakeEditModal,
}: Props) => {
  const { invoice_category_id: catID } = invoiceData;
  const { id } = useParams();

  const type_1 = catID === 1 || catID === 2 || catID === 3;
  const type_2 =
    catID === 5 ||
    catID === 10 ||
    catID === 4 ||
    catID === 30 ||
    catID === 31 ||
    catID === 26;
  const [form] = Form.useForm();

  const formattedTicketInfo =
    type_1 &&
    invoiceData?.airticket_information?.map((item) => {
      return {
        airticket_ticket_no: item.airticket_ticket_no,
        airticket_client_price: item.airticket_client_price,
        airticket_discount_total: item.airticket_discount_total,
        airticket_id: item.airticket_id,
      };
    });

  const formattedBillingInfo =
    type_2 &&
    invoiceData?.billing_information?.map((item) => {
      return {
        product_name: item.product_name,
        billing_unit_price: item.billing_unit_price || item.billing_total_sales,
        billing_remaining_quantity:
          item.billing_remaining_quantity || item.billing_quantity || 'N/A',
        airticket_id: item.billing_id,
      };
    });

  const priceSummary = {
    invoice_sub_total: Fixed2(invoiceData.invoice_sub_total),
    invoice_discount: Fixed2(invoiceData.invoice_discount),
    invoice_pay: Fixed2(invoiceData.invoice_pay),
  };

  useEffect(() => {
    formattedTicketInfo &&
      form.setFieldValue('edited_invoice', formattedTicketInfo);
    formattedBillingInfo &&
      form.setFieldValue('edited_invoice', formattedBillingInfo);
    form.setFieldsValue({ ...priceSummary });
  }, [invoiceData, fakeEditModal]);

  const handleOk = () => {
    setFakeEditModal(false);
  };

  const handleCancel = () => {
    setFakeEditModal(false);
  };

  const [postFakeInvoice, { isLoading, isSuccess, isError }] =
    usePostFakeInvoiceMutation();

  const onFinish = (values: SubmitType) => {
    const body: FakeInvoicePostType = {
      ti_invoice_id: id!,
      ti_total_discount: values.invoice_discount,
      ti_total_payment: values.invoice_pay,
      ti_sub_total: values.invoice_sub_total,
      ti_net_total:
        Fixed2(values.invoice_sub_total) - Fixed2(values.invoice_discount),
      infos: values?.edited_invoice?.map((item) => ({
        tii_airticket_id: item.airticket_id,
        tii_airticket_no: item.airticket_ticket_no,
        tii_airticket_discount: item.airticket_discount_total,
        tii_unit_price: item.airticket_client_price || item.billing_unit_price,
      })),
    };

    postFakeInvoice(body);
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Edited invoice has been generated');
      handleOk();
    } else if (isError) message.error('Something went wrong');
  }, [isSuccess, isError]);

  const unitPrice = useWatch('edited_invoice', form);

  useEffect(() => {
    const subTotal = unitPrice?.reduce(
      (total: number, ticket: edited_invoice_price) =>
        total + Fixed2(ticket?.total),
      0
    );
    form.setFieldValue('invoice_sub_total', subTotal);
  }, [unitPrice]);

  const formListInput = ({
    index,
    subField,
    operation,
    subFields,
  }: FormListProps) => {
    if (formattedTicketInfo) {
      return (
        <InvoiceAirTicket_Input
          key={index}
          subField={subField}
          subOpt={operation}
          subFields={subFields}
          form={form}
          sl={index}
        />
      );
    } else if (formattedBillingInfo) {
      return (
        <InvoiceBilling_input
          key={index}
          subField={subField}
          subOpt={operation}
          subFields={subFields}
          form={form}
          catID={catID}
          sl={index}
        />
      );
    }
  };

  return (
    <>
      <Modal
        title='Generate Edited Invoice'
        open={fakeEditModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        width={650}
      >
        <Form
          onFinish={onFinish}
          layout='vertical'
          labelAlign='left'
          form={form}
          autoComplete='off'
          disabled={isLoading}
        >
          <Col lg={24}>
            <Form.List name={['edited_invoice']}>
              {(subFields, operation) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 12,
                  }}
                >
                  {subFields?.map((subField, index) =>
                    formListInput({
                      index,
                      subField,
                      operation,
                      subFields,
                      form,
                    })
                  )}

                  {!type_2 && (
                    <Form.Item>
                      <Button
                        type='dashed'
                        onClick={() => operation.add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add New Ticket
                      </Button>
                    </Form.Item>
                  )}
                </div>
              )}
            </Form.List>

            <Divider />

            <Row style={{ padding: 10 }} className='border' gutter={8}>
              <NumberInput
                name={'invoice_sub_total'}
                label='Sub Total'
                size={8}
              />
              <NumberInput
                name={'invoice_discount'}
                label='Discount'
                size={8}
              />
              <NumberInput
                name={'invoice_pay'}
                label='Total Payment'
                size={8}
              />
            </Row>
          </Col>

          <Row style={{ marginTop: 10 }} justify={'end'}>
            <Form.Item>
              <Button type='primary' htmlType='submit' loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default MakeFakeInvoice;

interface FormListProps {
  index: number;
  subField: FormListFieldData;
  operation: FormListOperation;
  subFields: FormListFieldData[];
  form: FormInstance<any>;
}

interface SubmitType {
  edited_invoice: edited_invoice_price[];
  invoice_sub_total: string;
  invoice_discount: number;
  invoice_pay: string;
}

interface edited_invoice_price {
  airticket_ticket_no: string;
  airticket_client_price: string;
  billing_unit_price?: string;
  airticket_discount_total: number;
  airticket_id: number;
  total: number;
}
