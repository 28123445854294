type Props = {};

export default function DashboardForOthersUser({}: Props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        style={{ height: "85vh" }}
        src="https://www.price2spy.com/wp-content/uploads/2023/06/Pricing-Dashboard-Hero-Image.png"
        alt=""
      />
    </div>
  );
}
