import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
  theme,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import {
  Fixed2,
  handleTableChange,
  TableParams,
} from '../../../../common/utils/common.utils';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import { SelectAgent } from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllAgentProfileQuery } from '../../../Client/Agents_Profile/api/endpoints/agentProfileEndPoints';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useLazyGetExcelQuery } from '../../ledgers/endpoints/ledgerEndpoints';
import { useLazyGetAllDueAdvanceAgentsQuery } from '../endpoints/Total_Due_Advance_Agent_Endpoints';
import {
  ITotal_Due_Advance_AgentDataType,
  ITotal_Due_Advance_FormData,
} from '../types/Total_Due_Advance_ClientTypes';
import { Total_Due_Advance_AgentUtils } from '../utils/Total_Due_Advance_AgentUtils';
import { disabledFeatureDate } from '../../../../helpers/CapitalizeFirstLetter';

type Props = {};

function Total_Due_Advance_Agent({ }: Props) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [date_range_value, setDate_range_value] = useState<string>();
  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: '',
    query: '',
  });
  let totalDue: number = 0;
  let totalAdvance: number = 0;

  const toDate = dayjs().format('YYYY-MM-DD');
  useEffect(() => {
    const date_range = `payment_date=${toDate}`;
    form.setFieldValue('agent_id', 'all');
    form.setFieldValue('date_range', dayjs());
    fetchTotalDueAdvance(
      {
        agent_id: 'all',
        query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
      },
      true
    );
    setExcelQuery({
      Id: 'all',
      query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  }, []);

  const [fetchTotalDueAdvance, { data, isLoading }] =
    useLazyGetAllDueAdvanceAgentsQuery({
      selectFromResult: (cache) => {
        const data = cache.data?.data;
        const count = cache?.data?.count;
        const dataToReturn: ITotal_Due_Advance_AgentDataType[] = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            dataToReturn.push({
              ...element,
              key: i + 1,
            });
            if (Number(data[i]?.agtrxn_lbalance) < 0) {
              totalDue = totalDue + Math.abs(Number(data[i]?.agtrxn_lbalance));
            }
            if (Number(data[i]?.agtrxn_lbalance) > 0) {
              totalAdvance =
                totalAdvance + Math.abs(Number(data[i]?.agtrxn_lbalance));
            }
          }
        }
        return { ...cache, data: { dataToReturn, count } };
      },
    });

  const onFinish = async (values: ITotal_Due_Advance_FormData) => {
    const date = dayjs(values.date_range).format('YYYY-MM-DD');
    const date_range = `payment_date=${date}`;

    const body: ITotal_Due_Advance_FormData = {
      agent_id: values.agent_id,
      date_range: date_range,
    };
    setDate_range_value(date_range);
    fetchTotalDueAdvance({
      agent_id: body.agent_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: body.agent_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };
  //pagination--start---

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 50 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['50', '100', '200', '500'],
      pageSize: 50,
      total: data.count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: data.count },
    }));
  }, [data.count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: ITotal_Due_Advance_FormData = form.getFieldsValue();
    const date = dayjs(values.date_range).format('YYYY-MM-DD');
    const date_range = `payment_date=${date}`;
    const body: ITotal_Due_Advance_FormData = {
      agent_id: values.agent_id,
      date_range: date_range,
    };

    fetchTotalDueAdvance({
      agent_id: body.agent_id,
      query: `${query}&${body.date_range}`,
    });

    setExcelQuery({
      Id: body.agent_id.toString(),
      query: `${query}&${body.date_range}`,
    });
  };
  //pagination--end---

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Due_Advance_Report_${date_range_value}`,
    pageStyle:
      '@page { size: auto; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 40px !important; } }',
  });

  const { data: agent } = useGetAllAgentProfileQuery();
  const getAllAgentProfile = agent?.data;

  // @HANDLE CONTENTS
  const jsx_com = (
    <div>
      <FormHeaderTitle title='Total Due/Advance (Agents)' />
      <ConfigProvider>
        <Typography.Text style={{ margin: 10 }}>
          You have total: {data?.count} rows
        </Typography.Text>
        <Table
          size='small'
          bordered
          pagination={
            data.count !== undefined && data.count > 20
              ? tableParams.pagination
              : false
          }
          dataSource={data.dataToReturn}
          columns={Total_Due_Advance_AgentUtils(queryData)}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
          onChange={(args1, args2, args3) =>
            handleTableChange({
              args: {
                pagination: args1,
                filters: args2,
                sorter: args3,
              },
              states: {
                refetch,
                setQueryData,
                setTableParams,
                tableParams,
              },
            })
          }
          summary={(_) => {
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={4}>
                  <div
                    style={{
                      color: 'green',
                      fontWeight: '700',
                      textAlign: 'right',
                    }}
                  >
                    <Typography.Text strong>Total: </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <div
                    style={{
                      color: 'green',
                      fontWeight: '700',
                      textAlign: 'right',
                    }}
                  >
                    <Typography.Text strong type='danger'>
                      {Fixed2(totalDue)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <div
                    style={{
                      color: 'green',
                      fontWeight: '700',
                      textAlign: 'right',
                    }}
                  >
                    <Typography.Text strong type='success'>
                      {Fixed2(totalAdvance)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  <div
                    style={{
                      color: 'green',
                      fontWeight: '700',
                      textAlign: 'right',
                    }}
                  >
                    <Typography.Text strong type='success'></Typography.Text>
                  </div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </ConfigProvider>
    </div>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Total Due/Advance (Agents)' }}
      />
    </div>
  );

  return (
    <div>
      {!id && (
        <BreadCrumb arrOfOption={['Report', 'Total Due/Advance (Agents)']} />
      )}
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            if (data?.dataToReturn.length)
              fetchExcel({
                client_id: excelQuery.Id,
                query: excelQuery.query,
                excelApiName: 'totalDueAdvance/agent',
                excelName: 'total_due_advance_agents',
              });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <SelectAgent
            required
            name='agent_id'
            size={4}
            label='Select Agents'
            placeholder='Select Agents'
            showAll
            getAllAgentProfile={getAllAgentProfile || []}
            offDropDown
          />

          <Col span={6} xs={24} sm={24} md={24} lg={4}>
            <Form.Item
              label='Select Date'
              name='date_range'
              rules={[{ required: true, message: ` Date is required!` }]}
            >
              <DatePicker
                format={'DD-MM-YYYY'}
                allowClear={false}
                style={{ width: '100%' }}
                disabledDate={disabledFeatureDate}
              />
            </Form.Item>
          </Col>
          <Col lg={2}>
            <FormButton
              label='Search'
              icon
              textAlign='left'
              loading={isLoading}
            />
          </Col>
        </Row>
      </Form>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </div>
  );
}

export default Total_Due_Advance_Agent;
