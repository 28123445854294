import { Col, Form, Radio, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { useEffect } from 'react';

type Props = { billingForm: FormInstance<any> };

export default function InvPostIsDiscountIsPrevDue({ billingForm }: Props) {
  // @HANDLE RADIO BUTTONS
  useEffect(() => {
    billingForm?.setFieldsValue({
      invoice_show_prev_due: 0,
      invoice_show_discount: 1,
    });
  }, []);

  return (
    <Row>
      <Col span={6} xs={24} sm={24} md={24} lg={8}>
        <Form.Item
          className='commonBox'
          name={['invoice_show_prev_due']}
          label='Show Prev Due'
        >
          <Radio.Group>
            <Radio value={1}>Yes</Radio>
            <Radio value={0}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={6} xs={24} sm={24} md={12} lg={8}>
        <Form.Item
          className='commonBox'
          name={['invoice_show_discount']}
          label='Show Discount'
          // colon={false}
        >
          <Radio.Group>
            <Radio value={1}>Yes</Radio>
            <Radio value={0}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
    </Row>
  );
}
