import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Row, Table } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { IPermission } from '../../../common/types/commonTypes';
import {
  TableParams,
  handleTableChange,
} from '../../../common/utils/common.utils';
import { loadingIndicator } from '../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import { useLazyGetSMSByDateQuery } from '../Api/Endpoints/SMSEndpoints';
import { ISMSDateForm } from '../SMSTypes/SMSTypes';
import { SMSLogUtils } from '../Utils/SMSLogUtils';

type Props = { permission?: IPermission };
const { RangePicker } = DatePicker;

const SMSLog = (props: Props) => {
  const [form] = Form.useForm();

  const dateFormat =
    dayjs().format('YYYY-MM-DD') + '$' + dayjs().format('YYYY-MM-DD');

  const [dateRange, setDateRange] = useState<string>(dateFormat);
  const [from_date, setFrom_date] = useState<string>(
    dayjs().format('YYYY-MM-DD')
  );
  const [to_date, setTo_date] = useState<string>(dayjs().format('YYYY-MM-DD'));

  useEffect(() => {
    setDateRange(from_date + '$' + to_date);
  }, [from_date, to_date]);

  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
  }, []);

  const [fetchSms, { isLoading, isFetching, data: smsLogs }] =
    useLazyGetSMSByDateQuery();

  const onFinish = async (values: ISMSDateForm) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `?from_date=${setFrom_date}&to_date=${setTo_date}`;
    fetchSms({ query: date_range });
  };

  // @Trash button click to data fetch
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(smsLogs?.count || 0);
  }, [smsLogs]);

  // @Handle Fetch
  useEffect(() => {
    const setFrom_date = dayjs().format('YYYY-MM-DD');
    const setTo_date = dayjs().format('YYYY-MM-DD');
    const date_range = `?from_date=${setFrom_date}&to_date=${setTo_date}`;
    fetchSms({ query: date_range });
  }, []);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 50 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['50', '100', '200', '500'],
      pageSize: 50,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    // fetchSms(`${query ? query : ''}`);
    fetchSms({ query: query ? query : '' });
  };

  return (
    <div>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Col style={{ display: 'flex', justifyContent: 'center' }}>
          <Row gutter={[10, 10]}>
            <Col>
              <Form.Item label={false} name='date_range'>
                <RangePicker allowClear={false} format={'DD-MM-YYYY'} />
              </Form.Item>
            </Col>
            <Col>
              <Button
                icon={<SearchOutlined />}
                htmlType='submit'
                type='primary'
              >
                Get Log
              </Button>
            </Col>
          </Row>
        </Col>
      </Form>

      <FormHeaderTitle title='SMS Logs' />
      <Table
        size='small'
        bordered
        dataSource={smsLogs?.data}
        columns={SMSLogUtils()}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        pagination={count > 50 ? tableParams.pagination : false}
        onChange={(args1, args2, args3) =>
          handleTableChange({
            args: {
              pagination: args1,
              filters: args2,
              sorter: args3,
            },
            states: {
              refetch,
              setQueryData,
              setTableParams,
              tableParams,
            },
          })
        }
      />
    </div>
  );
};

export default SMSLog;
