import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Table from 'antd/lib/table';
import dayjs from 'dayjs';
import {
  IViewBillingInfo,
  IViewInvoiceDetails,
  IViewPassportInfo,
} from '../../../common/types/commonInterfaces';
import { Fixed2, isNotEmpty } from '../../../common/utils/common.utils';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import { IITransportInfo } from '../../Invoice_Hajj_Pre_Reg/Types/InvoiceHajjiPre.Types';
import { HotelInformation, TicketInfo } from '../Type/Invoce.other.interface';
import CostViewSubTotal from '../../../components/common/Invoice/ViewInvoiceInfo/CostViewSubTotal';

type Props = {
  invoiceDetails: IViewInvoiceDetails;
};

const ticketInfoColumn: ColumnsType<TicketInfo> = [
  {
    title: 'SL.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Ticket No',
    dataIndex: 'ticket_no',
    key: 'ticket_no',
  },
  {
    title: 'PNR',
    dataIndex: 'ticket_pnr',
    key: 'ticket_pnr',
  },
  {
    title: 'Route',
    dataIndex: 'ticket_route_name',
    key: 'ticket_route_name',
  },
  {
    title: 'Airline',
    dataIndex: 'airline_name',
    key: 'airline_name',
    width: '150px',
  },
  {
    title: 'Reference No',
    dataIndex: 'ticket_reference_no',
    key: 'ticket_reference_no',
    width: '120px',
  },
  {
    title: 'Journey Date',
    dataIndex: 'ticket_journey_date',
    key: 'ticket_journey_date',
    width: '120px',
    render: (_, data) => {
      return <>{dayjs(data.ticket_journey_date).format('DD-MM-YYYY')}</>;
    },
  },
  {
    title: 'Return Date',
    dataIndex: 'ticket_return_date',
    key: 'ticket_return_date',
    width: '120px',
    render: (_, data) => {
      return <>{dayjs(data.ticket_return_date).format('DD-MM-YYYY')}</>;
    },
  },
];
const hotelInfoColumn: ColumnsType<HotelInformation> = [
  {
    title: 'SL.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Hotel Name',
    dataIndex: 'hotel_name',
    key: 'hotel_name',
  },
  {
    title: 'Reference No',
    dataIndex: 'hotel_reference_no',
    key: 'hotel_reference_no',
  },
  {
    title: 'Check In Date',
    dataIndex: 'hotel_check_in_date',
    key: 'hotel_check_in_date',
    render: (_, data) => {
      return <>{dayjs(data.hotel_check_in_date).format('DD-MM-YYYY')}</>;
    },
  },
  {
    title: 'Check Out Date',
    dataIndex: 'hotel_check_out_date',
    key: 'hotel_check_out_date',
    render: (_, data) => {
      return <>{dayjs(data.hotel_check_out_date).format('DD-MM-YYYY')}</>;
    },
  },
  {
    title: 'Room type',
    dataIndex: 'rtype_name',
    key: 'rtype_name',
  },
];

const clientRefundInfoColumn: ColumnsType<IITransportInfo> = [
  {
    title: 'SL.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Product',
    dataIndex: 'transport_type_id',
    key: 'transport_type_id',
  },
  {
    title: 'Qty',
    dataIndex: 'transport_reference_no',
    key: 'transport_reference_no',
  },
  {
    title: 'Unit Price',
    dataIndex: 'transport_pickup_place',
    key: 'transport_pickup_place',
  },
  {
    title: 'Total Price',
    dataIndex: 'transport_pickup_place',
    key: 'transport_pickup_place',
  },
  {
    title: 'Charge Taken from client',
    dataIndex: 'transport_pickup_place',
    key: 'transport_pickup_place',
  },
];
const transportInfoColumn: ColumnsType<IITransportInfo> = [
  {
    title: 'SL.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Transport Type',
    dataIndex: 'transport_type_id',
    key: 'transport_type_id',
  },
  {
    title: 'Reference No',
    dataIndex: 'transport_reference_no',
    key: 'transport_reference_no',
  },
  {
    title: 'Pickup Place',
    dataIndex: 'transport_pickup_place',
    key: 'transport_pickup_place',
  },
  {
    title: 'Time',
    dataIndex: 'transport_pickup_time',
    key: 'transport_pickup_time',
    render: (_, data) => {
      return <>{dayjs(data.transport_pickup_time).format('HH-mm-ss')}</>;
    },
  },
  {
    title: 'Drop Off Place',
    dataIndex: 'transport_dropoff_place',
    key: 'transport_dropoff_place',
  },
  {
    title: 'Dropoff Time',
    dataIndex: 'passport_date_of_issue',
    key: 'passport_date_of_issue',
    render: (_, data) => {
      return <>{dayjs(data.transport_dropoff_time).format('HH-mm-ss')}</>;
    },
  },
];
const billingInfoColumn: ColumnsType<IViewBillingInfo> = [
  {
    title: 'SL.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Product Name',
    dataIndex: 'product_name',
    key: 'product_name',
    align: 'center',
  },
  {
    title: 'Pax Name',
    dataIndex: 'pax_name',
    key: 'pax_name',
    align: 'center',
  },
  {
    title: 'Quantity',
    dataIndex: 'billing_quantity',
    key: 'billing_quantity',
    align: 'right',
  },
  {
    title: 'Unit Price',
    dataIndex: 'billing_unit_price',
    key: 'billing_unit_price',
    align: 'right',
  },

  {
    title: 'Cost Price',
    dataIndex: 'billing_cost_price',
    key: 'billing_cost_price',
    align: 'right',
  },
  {
    title: 'Total Sales',
    dataIndex: 'billing_unit_price',
    key: 'billing_unit_price',
    align: 'right',
    render: (text, record) => {
      const quantitys = record.billing_quantity;
      const unitPrice = record.billing_unit_price;
      const totalPrice = quantitys * unitPrice;
      return <span>{totalPrice}</span>;
    },
  },
  {
    title: 'Total Cost',
    dataIndex: 'billing_cost_price',
    key: 'billing_cost_price',
    align: 'right',
    render: (text, record) => {
      const quantity = record.billing_quantity;
      const costPrice = record.billing_cost_price;
      if (costPrice != null) {
        const totalPrice = quantity * costPrice;
        return <span>{totalPrice}</span>;
      } else {
        return <span>-</span>;
      }
    },
  },
  {
    title: 'Profit',
    dataIndex: 'billing_profit',
    key: 'billing_profit',
    align: 'right',
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor_name',
    key: 'vendor_name',
    align: 'center',
  },
];
const passportInfoColumn: ColumnsType<IViewPassportInfo> = [
  {
    title: 'SL.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Passport No',
    dataIndex: 'passport_passport_no',
    key: 'passport_passport_no',
  },
  {
    title: 'Name',
    dataIndex: 'passport_name',
    key: 'passport_name',
  },
  {
    title: 'Mobile',
    dataIndex: 'passport_mobile_no',
    key: 'passport_mobile_no',
  },
  {
    title: 'Email',
    dataIndex: 'passport_email',
    key: 'passport_email',
  },
  {
    title: 'Date of Birth',
    dataIndex: 'passport_date_of_birth',
    key: 'passport_date_of_birth',
    render: (_, data) => {
      return <>{dayjs(data.passport_date_of_birth).format('DD-MM-YYYY')}</>;
    },
  },
  {
    title: 'Date of issue',
    dataIndex: 'passport_date_of_issue',
    key: 'passport_date_of_issue',
    render: (_, data) => {
      return <>{dayjs(data.passport_date_of_issue).format('DD-MM-YYYY')}</>;
    },
  },
  {
    title: 'Date of expire',
    dataIndex: 'passport_date_of_expire',
    key: 'passport_date_of_expire',
    render: (_, data) => {
      return <>{dayjs(data.passport_date_of_expire).format('DD-MM-YYYY')}</>;
    },
  },
  {
    title: 'Visiting Country',
    dataIndex: 'country_name',
    key: 'country_name',
  },
];
const ViewInvoiceOtherDetails = ({ invoiceDetails }: Props) => {
  const {
    ticket_information,
    hotel_information,
    transport_information,
    billing_information,
    passport_information,
    refunds,
  } = invoiceDetails;

  const getOverAllProfitLoss = () =>
    billing_information.reduce((a, b) => a + Fixed2(b.billing_profit), 0) -
    Fixed2(invoiceDetails.invoice_discount) +
    Fixed2(invoiceDetails.invoice_service_charge);

  return (
    <>
      {isNotEmpty(passport_information) && (
        <>
          <FormHeaderTitle title='PASSPORT INFO' />
          <Table
            size='small'
            bordered
            rowKey={(e) => e.passport_passport_no}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={passport_information}
            columns={passportInfoColumn}
            pagination={false}
          />
        </>
      )}

      {isNotEmpty(ticket_information) && (
        <div className='mt-5'>
          <FormHeaderTitle title='TICKET INFO' />
          <Table
            size='small'
            bordered
            rowKey={(e) => e?.ticket_no as string}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={ticket_information}
            columns={ticketInfoColumn}
            pagination={false}
          />
        </div>
      )}

      {isNotEmpty(hotel_information) && (
        <div className='mt-5'>
          <FormHeaderTitle title='HOTEL INFO' />
          <Table
            size='small'
            bordered
            rowKey={(e) => e.hotel_name as string}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={hotel_information}
            columns={hotelInfoColumn}
            pagination={false}
          />
        </div>
      )}
      {isNotEmpty(transport_information) && (
        <div className='mt-5'>
          <FormHeaderTitle title='TRANSPORT INFO' />
          <Table
            size='small'
            bordered
            rowKey={(e) => e.transport_type_id}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={transport_information}
            columns={transportInfoColumn}
            pagination={false}
          />
        </div>
      )}
      {isNotEmpty(transport_information) && (
        <div className='mt-5'>
          <FormHeaderTitle title='CLIENT REFUND INFORMATION' />
          <Table
            size='small'
            rowKey={(e) => e.transport_type_id}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            // dataSource={refunds?.client_refund}
            columns={clientRefundInfoColumn}
            pagination={false}
          />
        </div>
      )}
      {isNotEmpty(billing_information) && (
        <>
          <div className='mt-5'>
            <FormHeaderTitle title='BILLING INFO' />
            <Table
              bordered
              size='small'
              rowKey={(e) => e.product_name}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              dataSource={billing_information}
              columns={billingInfoColumn}
              pagination={false}
            />
          </div>

          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'space-between',
              border: '1px solid lightgray',
            }}
          >
            <Typography.Text strong style={{ flex: 3, textAlign: 'right' }}>
              Overall Profit/Loss :
            </Typography.Text>
            <Typography.Text
              strong
              style={{
                flex: 1,
                paddingRight: 20,
                textAlign: 'center',
                color: getOverAllProfitLoss() > 0 ? 'green' : '#f74d49',
              }}
            >
              {getOverAllProfitLoss().toLocaleString()}
            </Typography.Text>
          </div>
        </>
      )}
      {/* <CostViewSubTotal invoice_info={invoiceDetails} /> */}
    </>
  );
};

export default ViewInvoiceOtherDetails;
