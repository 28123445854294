import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Row } from 'antd';
import { NumberInput } from '../../../../../components/common/FormItem/FormItems';
import { SelectAirline } from '../../../../../components/common/FormItem/SelectCustomFeilds';

type Props = {};

const Specific_airline_commission = (props: Props) => {
  return (
    <Form.List name='client_airline_commissions' initialValue={[{}]}>
      {(fields, { add, remove }) => {
        const last_filed = fields[fields.length - 1]?.name;

        return (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <Row key={key} gutter={{ xs: 8, sm: 16, md: 24, lg: 10 }}>
                  <SelectAirline
                    name={[name, 'ca_airline_id']}
                    size={6}
                    placeholder='Select Airline'
                    label='Select Airline'
                  />

                  <NumberInput
                    name={[name, 'ca_commission']}
                    size={6}
                    placeholder='Airline Commission'
                    label='Airline Commission'
                    maxChar={2}
                  />
                  <Flex gap={6} style={{ marginTop: 30 }}>
                    {last_filed === name ? (
                      <Button icon={<PlusOutlined />} onClick={() => add()} />
                    ) : null}

                    {name ? (
                      <Button
                        danger
                        icon={<MinusOutlined />}
                        onClick={() => remove(name)}
                      />
                    ) : null}
                  </Flex>
                </Row>
              );
            })}
          </>
        );
      }}
    </Form.List>
  );
};

export default Specific_airline_commission;
