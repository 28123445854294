import { Divider, FormListOperation, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance, FormListFieldData } from 'antd/lib/form';
import { useEffect } from 'react';
import { Fixed2 } from '../../../../../common/utils/common.utils';
import {
  FormInput,
  NumberInput,
} from '../../../../../components/common/FormItem/FormItems';

type Props = {
  subField: FormListFieldData;
  subOpt: FormListOperation;
  form: FormInstance<any>;
};

const EditTaxRefundFormList = ({ subField, subOpt, form }: Props) => {
  const air_ticket_tax = useWatch(
    ['ticket_info', subField.name, 'airticket_tax'],
    form
  );

  // Client Charge Calculation------------------------------

  const sales_price = useWatch(
    ['ticket_info', subField.name, 'airticket_client_price'],
    form
  );
  const client_charge = useWatch(
    ['ticket_info', subField.name, 'client_charge'],
    form
  );

  const client_refund = Fixed2(sales_price) - Fixed2(client_charge);

  // Vendor Charge Calculation------------------------------

  const purchase_price = useWatch(
    ['ticket_info', subField.name, 'airticket_purchase_price'],
    form
  );
  const vendor_charge = useWatch(
    ['ticket_info', subField.name, 'vendor_charge'],
    form
  );
  const vendor_refund = Fixed2(purchase_price) - Fixed2(vendor_charge);

  useEffect(() => {
    if (client_refund) {
      form.setFields([
        {
          name: ['ticket_info', subField.name, 'client_refund'],
          value: Fixed2(client_refund),
        },
      ]);
    }
    if (vendor_refund) {
      form.setFields([
        {
          name: ['ticket_info', subField.name, 'vendor_refund'],
          value: Fixed2(vendor_refund),
        },
      ]);
    }
  }, [client_refund, vendor_refund]);

  return (
    <Row className='border' key={subField.key} gutter={10}>
      <Divider>Refund - {subField.key + 1}</Divider>

      <FormInput
        hidden
        name={[subField.name, 'airticket_id']}
        label='Airticket Id'
        readonly
        size={6}
      />

      <FormInput
        name={[subField.name, 'airticket_ticket_no']}
        label='Ticket No'
        readonly
        size={6}
      />
      <FormInput
        name={[subField.name, 'vendor_name']}
        label='Vendor'
        readonly
        size={6}
      />
      <FormInput
        name={[subField.name, 'comb_vendor']}
        label='comb_vendor'
        readonly
        size={6}
        hidden
      />
      <FormInput
        name={[subField.name, 'refund_item_id']}
        label='refund_item_id'
        readonly
        size={6}
        hidden
      />

      <NumberInput
        name={[subField.name, 'airticket_purchase_price']}
        label='Purchase Price'
        readOnly
        size={4}
      />
      <NumberInput
        name={[subField.name, 'airticket_tax']}
        label='Air ticket Tax'
        size={4}
        readOnly
      />
      <NumberInput
        name={[subField.name, 'refund_tax_amount']}
        label='Refund Tax'
        size={4}
        required
      />
    </Row>
  );
};

export default EditTaxRefundFormList;
