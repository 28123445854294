import { Row, Table } from 'antd';
import { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { perProps } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllAgencyWithPaginationQuery } from '../endpoints/agencyEndpoints';
import AddNewAgency from '../modals/AddNewAgency';
import EditAgencyModal from '../modals/EditAgency';
import { AgencyDataType } from '../types/AgenciesTypes';
import { agencyColumns } from '../utils/utils';

const Agency = ({ permission }: perProps) => {
  const dispatch = useAppDispatch();
  const [editInfo, setEditInfo] = useState<AgencyDataType | null>(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const showModal = () => {
    dispatch(setModal(true));
  };
  const {
    isLoading,
    data: agency,
    refetch,
  } = useGetAllAgencyWithPaginationQuery(pagination, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const count = cache.data?.count;
      const dataToReturn: AgencyDataType[] = [];
      const agencyNameList: string[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          agencyNameList.push(element.agency_name);
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: { dataToReturn, agencyNameList, count } };
    },
  });

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Configuration', 'Agency']}
        refetch={refetch}
        reloaderSize='small'
      />
      <CommonTableHeader
        width
        align
        modalTitle={editInfo ? 'Edit Agency' : 'Add New Agency'}
        title='Agency'
        button_text='Add New Agency'
        permission={permission?.['create:any']}
        element={
          editInfo ? (
            <EditAgencyModal
              info={editInfo}
              agencyNameList={agency.agencyNameList}
              setNull={setEditInfo}
            />
          ) : (
            <AddNewAgency agencyNameList={agency.agencyNameList} />
          )
        }
        setNull={setEditInfo}
      />
      <Row justify={'center'}>
        <Table
          size='small'
          style={{ width: 900 }}
          pagination={
            agency?.count !== undefined && agency?.count < 20
              ? false
              : {
                ...pagination,
                total: agency?.count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
          }
          dataSource={agency.dataToReturn}
          columns={agencyColumns({
            setEditInfo,
            showModal,
            permission,
            pagination,
          })}
          scroll={{ x: true }}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
        />
      </Row>
    </div>
  );
};

export default Agency;
