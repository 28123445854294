import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { IGroupWisePassengerDataType } from '../types/groupWisePassengerListTypes';

const GroupWisePassengerListColumn = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<IGroupWisePassengerDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },
    {
      title: 'Passport No',
      dataIndex: 'passport_passport_no',
      key: 'passport_passport_no',
    },
    {
      title: 'Name',
      dataIndex: 'hajiinfo_name',
      key: 'hajiinfo_name',
    },
    {
      title: 'Mobile No',
      dataIndex: 'hajiinfo_tracking_number',
      key: 'hajiinfo_tracking_number',
    },
    {
      title: 'Tracking No',
      dataIndex: 'hajiinfo_tracking_number',
      key: 'hajiinfo_tracking_number',
    },
    {
      title: 'Date of Birth',
      dataIndex: 'hajiinfo_dob',
      key: 'hajiinfo_dob',
      render: (_, record) => {
        return `${
          record.hajiinfo_dob
            ? dayjs(record.hajiinfo_dob).format('DD MMM YYYY')
            : ''
        }`;
      },
    },
    {
      title: 'Date Of Issue',
      dataIndex: 'passport_date_of_issue',
      key: 'passport_date_of_issue',
      render: (_, record) => {
        return `${
          record.passport_date_of_issue
            ? dayjs(record.passport_date_of_issue).format('DD MMM YYYY')
            : ''
        }`;
      },
    },
    {
      title: 'Date Of Expire',
      dataIndex: 'passport_date_of_expire',
      key: 'passport_date_of_expire',
      render: (_, record) => {
        return `${
          record.passport_date_of_expire
            ? dayjs(record.passport_date_of_expire).format('DD MMM YYYY')
            : ''
        }`;
      },
    },
    {
      title: 'Email',
      dataIndex: 'passport_email',
      key: 'passport_email',
    },
  ];
};

export default GroupWisePassengerListColumn;
