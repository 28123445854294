import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Image, Input, Row, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { setModal } from '../../../common/slices/commonSlice';
import { perProps } from '../../../common/types/commonTypes';
import CommonTableHeader from '../../../components/common/CommonTableHeader';
import { SelectClients } from '../../../components/common/FormItem/SelectCustomFeilds';
import { removeSpaceBeforeWord } from '../../../components/common/Invoice/InvoiceListHeader';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../components/common/spinner/LoadingIndicator';
import {
  useDeletePassportMutation,
  useGetAllPassportQuery,
} from '../Api/Endpoints/PassportEndpoints';
import PassportStatusModal from '../Modals/Passport_Status/PassportStatusModal';
import { IPassportDataType } from '../PassportTypes/PassportTypes';
import { PassportUtils } from '../Utils/PassportUtils';
import { rangePresets } from '../../../common/utils/common.utils';

const All_Passport = ({ permission }: perProps) => {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');
  const [file, setFile] = useState<string | null>(null);
  const [filter, setFilter] = useState<string | undefined>('');

  // Handle Pagination

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const src = file as string;

  const showModal = () => {
    dispatch(setModal(true));
  };

  const {
    data: passport,
    isLoading,
    isFetching,
    refetch,
  } = useGetAllPassportQuery({
    ...pagination,
    from: date[0] || '',
    to: date[1] || '',
    search: search,
    filter: filter,
  });

  //  DELETE PASSPORT

  const [deleteVendor, { isError, isSuccess, isLoading: deleteLoading }] =
    useDeletePassportMutation();

  const handleDelete = (value: number) => {
    deleteVendor({ id: value });
  };

  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate && setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  useEffect(() => {
    form.setFieldsValue({ filter_by_name: 'all' });
  }, []);
  const [statusInfo, setStatusInfo] = useState<IPassportDataType | null>(null);
  return (
    <div>
      <BreadCrumb
        arrOfOption={['Passport']}
        refetch={refetch}
        reloaderSize='small'
      />
      <Row justify={'space-between'} align={'middle'}>
        {permission?.['create:any'] && (
          <Link to='/passport/add'>
            <Button type='primary'>
              <PlusOutlined />
              Add Passport
            </Button>
          </Link>
        )}

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form form={form}>
                <SelectClients
                  onChange={(e) => setFilter(e as string)}
                  placeholder='Filter by client'
                  name={'filter_by_name'}
                  size={24}
                  mdSize={8}
                  smSize={12}
                  offDropDown
                  showAll
                  allowClear={false}
                />
              </Form>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Here...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>
      <CommonTableHeader
        element={
          file ? (
            file?.substring(file.indexOf('.')) == '.pdf' ? (
              <iframe
                style={{
                  width: '100%',
                  height: '100%',
                }}
                title='file'
                src={src}
              />
            ) : (
              <Image preview={false} src={src} />
            )
          ) : (
            <></>
          )
        }
        setNull={setFile}
      />

      <Table
        size='small'
        bordered
        pagination={
          passport?.count !== undefined && passport?.count < 20
            ? false
            : {
                ...pagination,
                total: passport?.count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        columns={PassportUtils(
          setFile,
          showModal,
          permission,
          handleDelete,
          deleteLoading,
          pagination,
          setStatusInfo
        )}
        dataSource={passport?.data}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
      />

      {/*passport status update modal */}
      <CommonTableHeader
        title=''
        element={
          statusInfo ? (
            <PassportStatusModal info={statusInfo} setNull={setStatusInfo} />
          ) : (
            <></>
          )
        }
        setNull={setStatusInfo}
      />
    </div>
  );
};

export default All_Passport;
