import { message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';
import { IAllInvoices } from '../../../../common/types/commonInterfaces';
import { IPermission } from '../../../../common/types/commonTypes';
import InvoiceListHeader from '../../../../components/common/Invoice/InvoiceListHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import {
  useDeleteInvoiceOtherMutation,
  useGetAllInvoiceOtherQuery,
} from '../../Api/invoiceOtherEndpoints';

type Props = {
  permission: IPermission | undefined;
  addMRPermission: ['*'] | undefined;
};

const ListOfInvoiceAirticket = ({ permission, addMRPermission }: Props) => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const [dataSource, setDataSource] = useState<IAllInvoices[]>();
  const [count, setCount] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const [isTrash, setIsTrash] = useState<boolean>(false);
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  const user = useSelector(selectUser);
  // LINKS
  const createLink = '/invoiceother/add';
  const viewLink = '/invoiceother/details';
  const editLink = '/invoiceother/edit';

  // GET ALL DATA ,TRASH, DELETE AND RESTORE
  const {
    data,
    isFetching,
    isLoading: isLoadingData,
    refetch: getAllInvoices,
  } = useGetAllInvoiceOtherQuery(
    query +
      `&from_date=${date[0] || ''}&to_date=${date[1] || ''}&search=${search}`
  );

  const allData = data?.data;
  useEffect(() => {
    setCount(data?.count!);
  }, [data]);
  // DELETE HANDLER
  const [deleteInvoice, { isError, isSuccess, isLoading, error }] =
    useDeleteInvoiceOtherMutation();
  const handleOnDelete = (id: number, index: number) => {
    setColumnIndex(index);
    deleteInvoice({
      id,
      query: !isTrash ? 'delete' : 'restore',
      user_id: user?.user_id,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success(
        !isTrash
          ? 'Invoice Deleted Successfully!'
          : 'Invoice Restored Successfully!'
      );
    }
    if (isError) {
      setColumnIndex(null);
      message.error('Thare was an error');
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    setDataSource(allData);
  }, [isFetching, isTrash, allData]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    getAllInvoices();
  };

  const bodyInstance = useRef(null);

  return (
    <div ref={bodyInstance}>
      <BreadCrumb arrOfOption={['Invoice', 'Other']} />
      <InvoiceListHeader
        InvocieModalTitle={'Invoice Other'}
        data={data?.data}
        setIsTrash={setIsTrash}
        editLink={editLink}
        handleOnDelete={handleOnDelete}
        isTrash={isTrash}
        permission={permission}
        viewLink={viewLink}
        setDataSource={setDataSource}
        dataSource={dataSource}
        createLink={createLink}
        isLoading={isLoadingData || isFetching}
        deleteLoading={isLoading}
        addMRPermission={addMRPermission}
        refetch={refetch}
        setCount={setCount}
        count={count}
        setQuery={setQuery}
        setDate={setDate}
        setSearch={setSearch}
        columnIndex={columnIndex}
      />
    </div>
  );
};

export default ListOfInvoiceAirticket;
