import { Col, Form, Select } from 'antd';
const TrashListOption = () => {
  return (
    <Col span={6} xs={24} sm={24} md={24} lg={6}>
      <Form.Item name='trash_list_name' label='Trash List' required>
        <Select allowClear placeholder='Select Trash List'>
          <Select.Option key='1' value='all'>
            {'All'}
          </Select.Option>
          <Select.Option key='2' value='invoice'>
            {'Invoice'}
          </Select.Option>
          <Select.Option key='3' value='client'>
            {'Client'}
          </Select.Option>
          <Select.Option key='4' value='combine'>
            {'Combine'}
          </Select.Option>
          <Select.Option key='5' value='vendor'>
            {'Vendor'}
          </Select.Option>
          <Select.Option key='6' value='money-reciept'>
            {'Money Receipt'}
          </Select.Option>
          <Select.Option key='7' value='expense'>
            {'Expense'}
          </Select.Option>
          <Select.Option key='8' value='quotation'>
            {'Quotation'}
          </Select.Option>
          <Select.Option key='9' value='other-refund'>
            {'Other Refund'}
          </Select.Option>
          <Select.Option key='10' value='airticket-refund'>
            {'Air Ticket Refund'}
          </Select.Option>
          <Select.Option key='11' value='tour-refund'>
            {'Tour Refund'}
          </Select.Option>
        </Select>
      </Form.Item>
    </Col>
  );
};

export default TrashListOption;
