import { useAppSelector } from '../../app/hooks';
import { storageBaseUrl } from '../utils/common.utils';

type Props = {};

const WaterMark = (props: Props) => {
  const orgInfo = useAppSelector((state) => state.user?.organization_info);
  const appConfig = useAppSelector((state) => state.configSlice);
  const { tac_wtr_mark } = appConfig;
  return (
    <div>
      {tac_wtr_mark !== 0 ? (
        <div
          style={{
            position: 'absolute',
            top: '25%',
            left: 0,
            pointerEvents: 'none',
            fontSize: 150,
            opacity: 0.05,
            zIndex: 9,
            userSelect: 'none',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '8in',
              justifyContent: 'center',
            }}
          >
            <img
              src={
                appConfig?.tac_wtr_mark_url ||
                storageBaseUrl + orgInfo?.org_logo
              }
              alt={orgInfo?.org_name}
              style={{ maxWidth: 600, userSelect: 'none' }}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default WaterMark;

export const LandscapeWaterMark = (props: Props) => {
  const orgInfo = useAppSelector((state) => state.user?.organization_info);
  const appConfig = useAppSelector((state) => state.configSlice);
  const { tac_wtr_mark } = appConfig;
  return (
    <div>
      {tac_wtr_mark !== 0 ? (
        <img
          src={
            appConfig?.tac_wtr_mark_url || storageBaseUrl + orgInfo?.org_logo
          }
          alt={orgInfo?.org_name}
          style={{ maxWidth: 600, userSelect: 'none' }}
        />
      ) : (
        ''
      )}
    </div>
  );
};
