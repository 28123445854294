import { Button, Popconfirm, Space, Typography, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { IPermission, ISetState } from "../../../../common/types/commonTypes";
import { IContact } from "../types/contactTypes";
import { useDeleteContactMutation } from "../Endpoints/contactsEndpoints";

type Props = {
  setEditInfo: ISetState<IContact | null>;
  showModal: () => void;
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
};

export const contactColumns = ({
  pagination,
  setEditInfo,
  showModal,
  permission,
}: Props): ColumnsType<IContact> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const [deleteContact, { isError, isSuccess, isLoading }] =
    useDeleteContactMutation();

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success("Companies Deleted Successfully");
    } else if (isError) {
      setColumnIndex(null);
      message.error("Something went wrong");
    }
  }, [isSuccess, isError]);

  const handleDelete = async (values: IContact, index: number) => {
    setColumnIndex(index);
    await deleteContact({ ...values });
  };

  return [
    {
      title: "SL.",
      dataIndex: "key",
      key: "serial",
      render: (_, _data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: "Name",
      render: (record: IContact) => (
        <>
          <Typography>{record.ac_contact_name}</Typography>
          <Typography.Text style={{ fontSize: "11px" }}>
            ({record.joined_name})
          </Typography.Text>
        </>
      ),
    },
    {
      title: "Type",
      dataIndex: "ac_account_type",
      key: "ac_account_type",
    },
    {
      title: "Phone",
      dataIndex: "ac_contact_phone",
      key: "ac_contact_phone",
    },
    {
      title: "Email",
      dataIndex: "ac_contact_email",
      key: "ac_contact_email",
    },
    {
      title: "Address",
      dataIndex: "ac_contact_address",
      key: "ac_contact_address",
    },
    {
      title: "Remark",
      dataIndex: "ac_remark",
      key: "ac_remark",
    },

    {
      title: "Actions",
      key: "operation",
      render: (_, record, index) => (
        <Space size="small">
          {permission?.["update:any"] && (
            <Button
              size="small"
              type="primary"
              onClick={() => {
                setEditInfo(record);
                showModal();
              }}
            >
              Edit
            </Button>
          )}
          {permission?.["delete:any"] && (
            <Button
              danger
              size="small"
              type="primary"
              loading={columnIndex === index && true}
            >
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record, index)}
              >
                Delete
              </Popconfirm>
            </Button>
          )}
        </Space>
      ),
    },
  ];
};
