/*
Invoice visa edit view info
@Author Sefat Alom <sefat.m360ict@gmail.com>
*/
import { Col, Form, Row, message } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { selectUser } from '../../../auth/states/userSlice';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import InvoiceHeaderForm from '../../../components/common/Invoice/InvoiceHeaderForm';
import InvoiceVisaSubTotalForm from '../../../components/common/Invoice/InvoiceSubTotal';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import {
  useEditInvoiceVisaMutation,
  useGetInvoiceVisaDetailsQuery,
} from '../Api/Endpoints/invoiceVisaEndpoints';
import BillingFormList from '../Components/BillingFormList';
import PassportInfo from '../Components/PassportInfo';
import { IBillingInfo, InvoiceVisaEdit } from '../Type/invoiceVisa.interface';
import { Fixed2 } from '../../../common/utils/common.utils';
import { useCheckCreditLimitMutation } from '../../Client/Client/api/endpoints/clientEndpoints';

type Props = {};

const EditInvoice_Visa = (props: Props) => {
  const id = Number(useParams().id);
  const [updateAndPrev, setUpdateAndPrev] = useState<boolean>(false);
  const {
    data: invoiceDetails,
    isLoading,
    isSuccess: success,
  } = useGetInvoiceVisaDetailsQuery(id);

  const invoice = invoiceDetails?.data?.invoice;
  const billing_information = invoiceDetails?.data
    ?.billing_information as IBillingInfo[];
  const passportInfo = invoiceDetails?.data?.passportInfo;

  // =========================== form hanlder ========================
  const user = useSelector(selectUser);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      invoice_combclient_id: invoice?.invoice_combclient_id,
      invoice_no: invoice?.invoice_no,
      invoice_sales_man_id: invoice?.invoice_sales_man_id,
      invoice_vat: invoice?.invoice_vat ? invoice?.invoice_vat : undefined,
      invoice_discount: invoice?.invoice_discount
        ? invoice?.invoice_discount
        : undefined,
      invoice_sales_date: dayjs(invoice?.invoice_sales_date),
      invoice_due_date: invoice?.invoice_due_date
        ? dayjs(invoice?.invoice_due_date)
        : undefined,
      invoice_reference: invoice?.invoice_reference
        ? invoice?.invoice_reference
        : undefined,
      invoice_note: invoice?.invoice_note ? invoice?.invoice_note : undefined,
      invoice_agent_id: invoice?.invoice_agent_id,
      invoice_service_charge: invoice?.invoice_service_charge
        ? invoice?.invoice_service_charge
        : undefined,

      billing_information: billing_information?.length
        ? billing_information?.map((item) => {
            return {
              billing_product_id: item?.billing_product_id,
            };
          })
        : [{}],

      passport_information: passportInfo?.length
        ? passportInfo.map((item) => ({
            passport_id: item.passport_id,
          }))
        : [{}],
    });
  }, [invoice]);

  const [
    updatedInvoiceVisa,
    { isError, isLoading: updateLoading, isSuccess, error },
  ] = useEditInvoiceVisaMutation();

  const [checkCreditLimit, { isLoading: limiLoading }] =
    useCheckCreditLimitMutation();

  const onFinish = (values: InvoiceVisaEdit) => {
    values.invoice_created_by = user?.user_id as number;
    values.passport_information = values?.passport_information?.map(
      (item, index) => {
        delete item?.passport_email;
        delete item?.passport_name;
        delete item?.passport_passport_no;
        delete item?.passport_mobile_no;
        delete item?.passport_date_of_expire;
        delete item?.passport_date_of_issue;
        delete item?.passport_date_of_birth;
        return {
          ...item,
          visapss_details_id: passportInfo?.length
            ? passportInfo[index]?.visapss_details_id
            : undefined,
          is_deleted: item?.is_deleted,
          passport_id: item?.passport_id,
        };
      }
    );
    values.billing_information = values?.billing_information?.map(
      (item, index) => ({
        ...item,
        billing_id: billing_information[index]?.billing_id,
      })
    );

    if (id) {
      const prevNetTotal = Fixed2(invoice?.invoice_net_total);
      const currentNetTotal = Fixed2(values?.invoice_net_total);
      if (prevNetTotal >= currentNetTotal) {
        updatedInvoiceVisa({ body: values, id: id });
      } else {
        checkCreditLimit({
          amount: currentNetTotal - prevNetTotal,
          combClient: values.invoice_combclient_id,
        }).then((res: any) => {
          if (res?.data?.data === 1) {
            updatedInvoiceVisa({ body: values, id: id });
          } else {
            message.error(res?.data?.data);
          }
        });
      }
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      message.success('Invoice visa has been updated!');
      updateAndPrev ? navigate(`/visa/details/${id}`) : navigate('/visa');
    }
    if (isError) {
      message.error('Invoice visa can not updated!');
    }
  }, [updateLoading]);

  const onFinishFailed = (errorInfo: any) => {
    message.error('Submit failed!');
  };

  return (
    <>
      <BreadCrumb arrOfOption={['Invoice (Visa)', 'edit']} />

      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout='vertical'
        form={form}
      >
        <InvoiceHeaderForm
          isEdit
          invoice_agent_com_amountFieldName={['invoice_agent_com_amount']}
          invoice_net_totalFieldName={['invoice_net_total']}
          form={form}
        />

        <PassportInfo form={form} passportInfo={passportInfo} />

        <BillingFormList
          form={form}
          billing_information={billing_information}
          conditionalCostVendor
        />

        <InvoiceVisaSubTotalForm form={form} />

        {/* @AIRTICKET POST BUTTON */}
        <Row gutter={[10, 10]}>
          <Col>
            <FormButton
              loading={!updateAndPrev && (isLoading || limiLoading)}
              label='UPDATE'
              onClick={() => {
                setUpdateAndPrev(false);
              }}
            />
          </Col>
          <Col>
            <FormButton
              loading={updateAndPrev && (isLoading || limiLoading)}
              label='UPDATE & VIEW'
              onClick={() => {
                setUpdateAndPrev(true);
              }}
            />
          </Col>
        </Row>
      </Form>
      {updateLoading || isLoading ? <LoadingIndicator /> : ''}
    </>
  );
};
export default EditInvoice_Visa;
