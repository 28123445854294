import { Button, message, Space, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import Popconfirm from 'antd/lib/popconfirm';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAppSelector } from '../../../../app/hooks';
import { IPermission } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import {
  useDeleteGroupTogrouopMutation,
  useGetAllGroupToGroupQuery,
} from '../../API/Endpoints/HajjiManageMentEndspont';
import ClienttoClientViewModal from '../../Components/ClientToClientCom/ClienttoClientViewModal';
import {
  IClToClViewModal,
  IGroupToGroupAll,
} from '../../Types/HajjiManagement.interface';

type Props = {
  permission?: IPermission;
};

const ListGroupToGroup = ({ permission }: Props) => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  const [
    deleteGroupTransaction,
    { isError, isSuccess, isLoading: deleteLoading, error },
  ] = useDeleteGroupTogrouopMutation();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const {
    data: tableData,
    isLoading,
    refetch,
  } = useGetAllGroupToGroupQuery({ ...pagination });

  // ============== view modal
  const [modal1Open, setModal1Open] = useState(false);
  const [modalData, setModalData] = useState<IClToClViewModal>();

  const handleView = (data: IGroupToGroupAll) => {
    const modalData = {
      trackingNo: data.gtransfer_tracking_no,
      transferFrom: data?.gtransfer_from_name,
      transferTo: data?.transfer_to_name,
      date: data?.transfer_date,
    };
    setModalData(modalData);
    setModal1Open(true);
  };

  // =========== HANDLE DELETE ==============
  const hanldeDelete = (id: number, index: number) => {
    setColumnIndex(index);

    deleteGroupTransaction({ id: id, userID: user?.user_id });
  };

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Delete client to client success!');
    }
    if (isError) {
      setColumnIndex(null);
      message.error('Delete faild!');
    }
  }, [deleteLoading, isError]);

  // ============ TAble Column
  const columns: ColumnsType<IGroupToGroupAll> = [
    {
      title: 'SL',
      key: 'ctransfer_id',

      render: (text, data, index) => <>{index + 1}</>,
    },
    {
      title: 'Job Name',
      dataIndex: 'gtransfer_job_name',
      key: 'gtransfer_job_name',
    },
    {
      title: 'Tracking No',
      dataIndex: 'gtransfer_tracking_no',
      key: 'gtransfer_tracking_no',
    },
    {
      title: 'Transfer Date',
      dataIndex: 'transfer_date',
      key: 'transfer_date',
    },
    {
      title: 'Note',
      dataIndex: 'gtransfer_note',
      key: 'gtransfer_note',
    },

    {
      title: 'Action',
      render: (_, data, index) => (
        <Space>
          {permission?.['read:any'] && (
            <Button
              onClick={() => handleView(data)}
              size='small'
              type='primary'
              style={{ backgroundColor: '#00a65a', border: 'none' }}
            >
              <Typography style={{ color: '#ffffff' }}>View</Typography>
            </Button>
          )}

          {permission?.['update:any'] && (
            <Button
              type='primary'
              size='small'
              style={{ backgroundColor: '#00c0ef' }}
              onClick={(e) =>
                navigate(
                  `/haji/transfer/group_to_group_edit/${data.gtransfer_id}`,
                  { state: location.pathname }
                )
              }
            >
              Edit
            </Button>
          )}

          {permission?.['delete:any'] && (
            <Button
              size='small'
              type='primary'
              style={{ background: '#e90800', border: 'none' }}
              loading={columnIndex === index && true}
            >
              <Popconfirm
                onConfirm={() => hanldeDelete(data.gtransfer_id, index)}
                title='Sure to delete?'
              >
                Delete
              </Popconfirm>
            </Button>
          )}
        </Space>
      ),
    },
  ];
  return (
    <StyleComponent>
      <BreadCrumb
        arrOfOption={['List of Transfer', 'Group to group']}
        refetch={refetch}
        reloaderSize='small'
      />
      <ClienttoClientViewModal
        modal1Open={modal1Open}
        setModal1Open={setModal1Open}
        modalData={modalData}
      />

      <Table
        size='small'
        bordered
        rowKey={(e) => e.gtransfer_id}
        columns={columns}
        dataSource={tableData?.data}
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
        pagination={
          tableData?.count !== undefined && tableData?.count < 20
            ? false
            : {
              ...pagination,
              total: tableData?.count,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }
        }
      />
    </StyleComponent>
  );
};

export default ListGroupToGroup;

const StyleComponent = styled.div`
  .transfer-in-list {
    border: 1px solid lightgray;
    width: 100%;
  }

  .transfer-in-list .transfer-in-list-td > td {
    padding: 10px !important;
    border-bottom: 1px solid lightgray;
    font-size: 13px !important;
  }

  .transfer-in-list .ant-table-thead .ant-table-cell {
    padding: 7px !important;
    font-size: 13px !important;
    border-bottom: 1px solid lightgray;
  }
`;
