import { Row, Space, Table } from 'antd';
import { useState } from 'react';
import { IPermission } from '../../../../../common/types/commonTypes';
import LoadingIndicator from '../../../../../components/common/spinner/LoadingIndicator';
import { useGetAgentIncentiveIncomesQuery } from '../endpoints/agent_IncentiveIncomeEndpoints';
import Agent_incentive_Add_Modal from '../modal/agent/Agent_incentive_Add_Modal';
import { Agent_IncentiveIncomeColumn } from '../utils/agent/Agent_IncentiveIncomeColumn';

type Props = {
  tabChanged: string;
  permission?: IPermission;
};

const Agent_Incentive_Income = ({ permission, tabChanged }: Props) => {
  const [checkButtonState, setCheckButtonState] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  const { data, isLoading, isFetching } = useGetAgentIncentiveIncomesQuery({
    ...pagination,
    trash: checkButtonState ? 1 : 0,
  });
  return (
    <div>
      <Space style={{ marginBottom: '1rem' }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 8 }}>
          <Agent_incentive_Add_Modal
            type={tabChanged}
            permission={permission}
          />
        </Row>
      </Space>

      <Table
        size='small'
        bordered
        rowKey={(e) => Number(e.incentive_id)}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
              ...pagination,
              total: data?.count,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }
        }
        columns={Agent_IncentiveIncomeColumn({
          pagination,
          checkButtonState,
          permission,
        })}
        dataSource={data?.data || []}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading || isFetching,
          indicator: <LoadingIndicator />,
        }}
      />
    </div>
  );
};

export default Agent_Incentive_Income;
