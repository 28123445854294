import { api } from '../../../../app/baseQuery';
import { IPaginationSD } from '../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { CHEQUE_PAYMENT } from '../../../Cheque_Management/api/Constants/constants';
import { idType } from '../../../Invoice_tour/Types/InvoiceTourTypes';
import {
  IPartialRefundFormItem,
  IPartialRefundInfo,
  IPartialRefundBodyType,
  IPartialRefundTickets,
  IPartialRefundInvoice,
} from '../../RefundTypes/RefundTypes';
import { PARTIAL_REFUND, Refund_LOGS } from '../Constants/Contstants';

export const PartialRefundEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // GET ALL PARTIAL REFUND
    getAllPartialRefund: build.query<
      HTTPResponse<IPartialRefundBodyType[]>,
      IPaginationSD
    >({
      query: (arg) => ({
        url: `refund/partial-refund?${arg.current}&size=${arg.pageSize}&from_date=${arg.from}&to_date=${arg.to}&search=${arg.search}`,
      }),
      providesTags: [{ type: 'PartialRefund', id: PARTIAL_REFUND }],
    }),

    getSinglePartialRefund: build.query<
      HTTPResponse<IPartialRefundBodyType>,
      idType
    >({
      query: (id) => ({
        url: `/refund/partial-refund/${id}`,
      }),
      providesTags: [{ type: 'PartialRefund', id: Refund_LOGS }],
    }),

    getRefundableInvoice: build.query<
      HTTPResponse<IPartialRefundInvoice[]>,
      idType
    >({
      query: (id) => ({ url: `/refund/partial-refundable-ticket/${id}` }),
      providesTags: [{ type: 'PartialRefund', id: Refund_LOGS }],
    }),

    getRefundableTickets: build.query<
      HTTPResponse<IPartialRefundTickets[]>,
      idType
    >({
      query: (id) => ({ url: `/refund/get_partial_ticket_by_invoice/${id}` }),
      providesTags: [{ type: 'PartialRefund', id: Refund_LOGS }],
    }),

    getPartialRefundInfo: build.query<HTTPResponse<IPartialRefundInfo>, any>({
      query: (body) => ({
        url: `/refund/get_partial_refund_info`,
        method: 'POST',
        body,
      }),
    }),

    // CREATE PARTIAL REFUND
    createPartialRefund: build.mutation<void, IPartialRefundFormItem>({
      query: (body) => ({
        url: `/refund/partial-refund`,
        method: 'POST',
        body: body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          toasterNotification('success', 'Partial Refund Created Successfuly');
        });
      },

      invalidatesTags: () => [
        { type: 'PartialRefund', id: PARTIAL_REFUND },
        { type: 'PartialRefund', id: Refund_LOGS },
        { type: 'Clients', id: 'CLIENT' },
        { type: 'Vendors', id: 'VEDNOR' },
        { type: 'Cheque_Payment', id: CHEQUE_PAYMENT },
        'BSPSummary',
        'invoices',
      ],
    }),

    // DELETE PARTIAL REFUND
    deletePartialRefund: build.mutation<
      void,
      { refund_id: number; deleted_by: number }
    >({
      query: (body) => ({
        url: `/refund/partial-refund/${body.refund_id}`,
        method: 'DELETE',
        body: { deleted_by: body.deleted_by },
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          toasterNotification('success', 'Partial Refund Deleted Successfuly');
        });
      },
      invalidatesTags: () => [
        { type: 'PartialRefund', id: PARTIAL_REFUND },
        { type: 'PartialRefund', id: Refund_LOGS },
        { type: 'Clients', id: 'CLIENT' },
        { type: 'Vendors', id: 'VENDOR' },
        'invoices',
      ],
    }),
  }),
});
export const {
  useCreatePartialRefundMutation,
  useDeletePartialRefundMutation,
  useGetSinglePartialRefundQuery,
  useGetAllPartialRefundQuery,
  useGetRefundableTicketsQuery,
  useGetRefundableInvoiceQuery,
  useGetPartialRefundInfoQuery,
  useLazyGetRefundableTicketsQuery,
  useLazyGetAllPartialRefundQuery,
  useLazyGetSinglePartialRefundQuery,
  useLazyGetRefundableInvoiceQuery,
  useLazyGetPartialRefundInfoQuery,
} = PartialRefundEndpoints;
