import moment from 'moment';

export function capitalizeFirstLetter(str: string) {
  const lowerCaseStr = str?.toLowerCase();
  return lowerCaseStr?.charAt(0).toUpperCase() + lowerCaseStr?.slice(1);
}

export function disabledFeatureDate(current: any) {
  // Disable dates after today
  return current && current > moment().endOf('day');
}
