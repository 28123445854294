import dayjs from 'dayjs';
import {
  Fixed2,
  getItaCodeByAirportIds,
} from '../../../common/utils/common.utils';
import { IAirport } from '../../Configuration/airport/types/AirportTypes';
import { getDataByPnr } from '../types/invoiceAirTicketTypes';

export const formatePNRDetails = (
  pnrDetails?: getDataByPnr,
  selectAirport?: IAirport[]
): any => {
  return pnrDetails?.ticket_details?.map((ticket) => ({
    ...ticket,
    airticket_issue_date:
      ticket?.airticket_issue_date &&
      dayjs(ticket.airticket_issue_date).isValid() &&
      dayjs(ticket.airticket_issue_date),
    airticket_journey_date:
      ticket?.airticket_journey_date &&
      dayjs(ticket.airticket_journey_date).isValid() &&
      dayjs(ticket.airticket_journey_date),
    airticket_return_date:
      ticket?.airticket_return_date &&
      dayjs(ticket.airticket_return_date).isValid() &&
      dayjs(ticket.airticket_return_date),
    airticket_gross_fare: Fixed2(ticket.airticket_gross_fare),
    airticket_base_fare: Fixed2(ticket.airticket_base_fare),
    airticket_ait: Fixed2(ticket.airticket_ait),
    airticket_airline_id: Fixed2(ticket.airticket_airline_id),

    airticket_route_or_sector: ticket?.airticket_route_or_sector?.length
      ? getItaCodeByAirportIds(
          [...ticket?.airticket_route_or_sector] as string[],
          selectAirport
        )
      : undefined,

    airticket_bd_charge: ticket?.BD ?? null,
    airticket_ut_charge: ticket?.UT ?? null,
    airticket_ow_charge: ticket?.OW ?? null,
    airticket_e5_charge: ticket?.E5 ?? null,
    airticket_yq_charge: ticket?.YQ ?? null,
    airticket_p8_charge: ticket?.P8 ?? null,
    airticket_p7_charge: ticket?.P7 ?? null,
    airticket_r9_charge: ticket?.R9 ?? null,
    airticket_g4_charge: ticket?.G4 ?? null,
    airticket_qa_charge: ticket?.QA ?? null,
    airticket_pz_charge: ticket?.PZ ?? null,
    airticket_xt_charge: ticket?.XT ?? null,
    airticket_es_charge: ticket?.ES ?? null,

    pax_passport: ticket.pax_passports,

    flight_details: ticket.flight_details.map((item: any) => ({
      ...item,
      fltdetails_fly_date:
        dayjs(item.fltdetails_fly_date).isValid() &&
        dayjs(item.fltdetails_fly_date),
      fltdetails_arrival_time:
        item.fltdetails_arrival_time &&
        dayjs(item.fltdetails_arrival_time, 'HH:mm:ss'),
      fltdetails_departure_time:
        item?.fltdetails_departure_time &&
        dayjs(item.fltdetails_departure_time, 'HH:mm:ss'),
    })),
  }));
};
