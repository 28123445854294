import { Button, Card, Col, Row, Skeleton, Typography } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Fixed2 } from '../../../common/utils/common.utils';
import { useLazyGetVendorDetailsQuery } from '../../vendor/api/endpoints/vendorEndpoints';
import ViewsSingleVendor from '../../vendor/components/ViewsSingleVendor';
import { IVendorsDetails } from '../interfaces/dashboard.interfaces';
import '../style/weeklyReport.css';
import DashboardTitle from './DashboardTitle';

const VendorDetails = ({ data, isLoading, isDarkMode }: any) => {
  const vendorDetails: IVendorsDetails[] = data?.data;

  //==========================Vendor Details Modal=====================
  const [
    fetchVendorByID,
    {
      data: singleVendor,
      isSuccess: vendorSuccess,
      isLoading: fetchLoading,
      isFetching,
    },
  ] = useLazyGetVendorDetailsQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = async (vendor_id: number) => {
    setIsModalOpen(true);
    await fetchVendorByID(vendor_id);
  };

  return isLoading ? (
    <>
      <Card bodyStyle={{ padding: '12px' }}>
        <Skeleton title={false} active />
      </Card>
    </>
  ) : (
    <>
      <div className='dashboard-box-shape' style={{ height: 320 }}>
        <DashboardTitle title='Vendors' link='/vendors' />

        <div className='dashboard_details_vendor custom-scrollbar'>
          <div style={{ padding: '10px 20px' }}>
            <Row gutter={[24, 35]}>
              {vendorDetails?.map((details, index: number) => {
                const remainingAmount =
                  Number(details.vendor_bank_guarantee || 0) +
                  Number(details.vendor_lbalance || 0);

                return (
                  <Col xl={8} lg={8} md={12} sm={24} key={index}>
                    <Card
                      bordered={false}
                      className='dash_vendor_details'
                      style={{
                        boxShadow: '#B5C0D0 0px 2px 8px 0px',
                        borderRadius: 4,
                      }}
                    >
                      <div
                        style={{
                          color: '#1c79be',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        <Button
                          type='link'
                          onClick={() => showModal(details?.vendor_id)}
                        >
                          <Typography.Text
                            style={{
                              color: '#3FA2F6',
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                            }}
                          >
                            {details.vendor_name}
                          </Typography.Text>
                        </Button>
                      </div>
                      <div className={`box ${isDarkMode ? 'dark-box' : ''}`}>
                        <p>B Guarantee</p>
                        <p>
                          {Fixed2(
                            details.vendor_bank_guarantee
                          ).toLocaleString()}
                        </p>
                      </div>
                      <div className={`box ${isDarkMode ? 'dark-box' : ''}`}>
                        <p>
                          {details.vendor_lbalance > 0 ? 'Advance' : 'Purchase'}
                        </p>
                        <p>
                          {Math.abs(
                            Fixed2(details.vendor_lbalance)
                          ).toLocaleString()}
                        </p>
                      </div>
                      <div
                        className={`box ${isDarkMode ? 'dark-box' : ''}`}
                        style={{ color: 'red' }}
                      >
                        <p>{remainingAmount > 0 ? 'Remaining' : 'Due'}</p>
                        <p>
                          {Math.abs(Fixed2(remainingAmount)).toLocaleString()}
                        </p>
                      </div>
                      <div className={`box ${isDarkMode ? 'dark-box' : ''}`}>
                        <p>Start Date</p>
                        <p>
                          {details.vendor_start_date &&
                            dayjs(details.vendor_start_date).format(
                              'DD MMM YYYY '
                            )}
                        </p>
                      </div>
                      <div
                        className={`box last_box ${
                          isDarkMode ? 'dark-box' : ''
                        }`}
                      >
                        <p>End Date</p>
                        <p>
                          {details.vendor_end_date &&
                            dayjs(details.vendor_end_date).format(
                              'DD MMM YYYY '
                            )}
                        </p>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </div>

      <ViewsSingleVendor
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        singleVendor={singleVendor}
        isFetching={isFetching}
        fetchLoading={fetchLoading}
      />
    </>
  );
};

export default VendorDetails;
