import { Tabs } from 'antd';
import React from 'react';
import { useParams } from 'react-router';
import InvoiceNotFound from '../../../../auth/pages/InvoiceNotFound';
import InvoiceActivityLogs from '../../../../components/common/Invoice/ViewInvoiceInfo/ActivityLogs';
import CommonViewInvoice from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice';
import FakeFlightViewOtherBilling from '../../../../components/common/Invoice/ViewInvoiceInfo/edited_invoice/view/Billing/FakeFlightViewOtherBilling';
import ViewFakeInvoice from '../../../../components/common/Invoice/ViewInvoiceInfo/edited_invoice/view/ViewFakeInvoice';
import TabDetailsView from '../../../../components/common/Invoice/ViewInvoiceInfo/TabDetailsView';
import TabPaymentView from '../../../../components/common/Invoice/ViewInvoiceInfo/TabPaymentView';
import ViewInvoiceHeader from '../../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useGetViewInvoiceOtherQuery } from '../../Api/invoiceOtherEndpoints';
import ViewInvoiceOtherBillingInfo from '../../Components/ViewInvoiceOtherBillingInfo';
import ViewInvoiceOtherDetails from '../../Components/ViewInvoiceOtherDetails';
import { Tab } from '../../../Invoice_Re_Issue/pages/ViewInvoiceInfo/ViewInvoiceReissue';

const ViewInvoiceOther = () => {
  // ================= DATA FOR VIEW =====================
  const invoice_id = Number(useParams().id);
  // GET INVOICE AIRTICKET DETAILS
  const {
    data: { data: invoiceData } = {},
    isLoading,
    isError,
  } = useGetViewInvoiceOtherQuery(invoice_id);

  const { invoice_category_id } = invoiceData || {};

  const viewInvoicePrintableRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceCostDetailsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoicePaymentsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceAcivityRef = React.useRef<HTMLDivElement>(null);
  const viewEditedInvoiceRef = React.useRef<HTMLDivElement>(null);

  const header_ref = React.useRef<HTMLDivElement>(null);
  const signiture_ref = React.useRef<HTMLDivElement>(null);
  const subtotal_ref = React.useRef<HTMLDivElement>(null);

  const [selectedForPrint, setSelectedForPrint] = React.useState<
    'viewInvoice' | 'costDetails' | 'payments' | 'activity' | 'edited'
  >('viewInvoice');
  const { TabPane } = Tabs;

  let selectedPrintableRef = viewInvoicePrintableRef;

  switch (selectedForPrint) {
    case 'viewInvoice':
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
    case 'costDetails':
      selectedPrintableRef = viewInvoiceCostDetailsRef;
      break;
    case 'payments':
      selectedPrintableRef = viewInvoicePaymentsRef;
      break;
    case 'activity':
      selectedPrintableRef = viewInvoiceAcivityRef;
      break;
    case 'edited':
      selectedPrintableRef = viewEditedInvoiceRef;
      break;
    default:
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
  }

  if (isError) return <InvoiceNotFound />;

  const items: Tab[] = [
    {
      label: 'Invoice',
      key: '1',
      children: invoiceData && (
        <CommonViewInvoice
          invoiceData={invoiceData}
          viewInvoicePrintableRef={viewInvoicePrintableRef}
          heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
          children={
            <ViewInvoiceOtherBillingInfo
              billing_information={invoiceData?.billing_information}
              passport_information={invoiceData?.passport_information}
              ticket_information={invoiceData?.ticket_information}
              hotel_information={invoiceData?.hotel_information}
              transport_informaion={invoiceData?.transport_information}
            />
          }
        />
      ),
    },
    {
      label: 'Details',
      key: '2',
      children: invoiceData && (
        <TabDetailsView
          invoiceDetails={invoiceData}
          children={<ViewInvoiceOtherDetails invoiceDetails={invoiceData} />}
          viewInvoiceCostDetailsRef={viewInvoiceCostDetailsRef}
        />
      ),
    },
    {
      label: 'Payments',
      key: '3',
      children: invoiceData && (
        <TabPaymentView
          invoiceDetails={invoiceData}
          viewInvoicePaymentsRef={viewInvoicePaymentsRef}
        />
      ),
    },
    {
      label: 'Activity Log',
      key: '4',
      children: (
        <InvoiceActivityLogs
          id={invoice_id}
          viewInvoiceAcivityRef={viewInvoiceAcivityRef}
        />
      ),
    },
  ];

  if (invoiceData?.is_edited) {
    items.push({
      key: '5',
      label: 'Edited Invoice',
      children: (
        <>
          {invoiceData && (
            <ViewFakeInvoice
              invoiceData={invoiceData}
              viewInvoicePrintableRef={viewEditedInvoiceRef}
              heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
              children={
                <FakeFlightViewOtherBilling
                  billing_information={invoiceData?.billing_information}
                  passport_information={invoiceData?.passport_information}
                  ticket_information={invoiceData?.ticket_information}
                  hotel_information={invoiceData?.hotel_information}
                  transport_informaion={invoiceData?.transport_information}
                />
              }
            />
          )}
        </>
      ),
    });
  }
  return (
    <>
      <ViewInvoiceHeader
        invoice_category_id={invoice_category_id}
        invoice_id={invoice_id}
        selectedPrintableRef={selectedPrintableRef}
        invoiceData={invoiceData}
      />
      {isLoading && <LoadingIndicator />}

      <Tabs
        onChange={(e) => {
          switch (Number(e)) {
            case 1:
              setSelectedForPrint && setSelectedForPrint('viewInvoice');
              break;
            case 2:
              setSelectedForPrint && setSelectedForPrint('costDetails');
              break;
            case 3:
              setSelectedForPrint && setSelectedForPrint('payments');
              break;
            case 4:
              setSelectedForPrint && setSelectedForPrint('activity');
              break;
            case 5:
              setSelectedForPrint && setSelectedForPrint('edited');
              break;
            default:
              break;
          }
        }}
        type='card'
        style={{ marginTop: '15px' }}
        items={items.sort((a, b) => Number(a.key) - Number(b.key))}
      />
    </>
  );
};

export default ViewInvoiceOther;
