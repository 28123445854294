import { FileExcelFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAppDispatch } from "../../../../app/hooks";
import { selectCommon, setModal } from "../../../../common/slices/commonSlice";
import { IPermission, crud } from "../../../../common/types/commonTypes";
import { filterNullColumns } from "../../../../common/utils/common.utils";
import { getRowHightLightClass } from "../../../../common/utils/rowColorChange";
import { FormInputItem } from "../../../../components/common/FormItem/FormItems";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import {
  useActivateClientMutation,
  useGetClientsQuery,
  useLazyGetClientExcelDataQuery,
} from "../api/endpoints/clientEndpoints";
import ClientSmsModal from "../modals/ClientSmsModal";
import { ClientDataType } from "../types/clientInterfaces";
import { viewClientcolumns } from "../utils/utils_tsx/viewClientUtil";

const ClientLists = ({ permission }: { permission?: IPermission }) => {
  const [editInfo, setEditInfo] = useState<ClientDataType | null>(null);
  const dispatch = useAppDispatch();
  const commonState = useSelector(selectCommon);

  //   Excel Download
  const [fetchExcel] = useLazyGetClientExcelDataQuery();
  // Get Data with Pagination and Search
  const [search, setSearch] = useState("");

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
    // setExcelQuery({ ...pagination, search: search });
  };

  const {
    data: clients,
    isLoading: isClientLoading,
    isFetching,
    refetch,
  } = useGetClientsQuery({ ...pagination, search: search });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 50,
    }));
  }, [search]);

  const showModal = () => {
    dispatch(setModal(true));
  };

  const [activate, { isLoading }] = useActivateClientMutation();

  const handleModal = () => {
    dispatch(setModal(false));
  };

  const loading = {
    spinning: isClientLoading || isFetching,
    indicator: loadingIndicator,
  };

  const column = viewClientcolumns({
    setEditInfo,
    showModal,
    activate,
    loading: isLoading,
    permission,
    pagination,
  });

  const filterColumns = filterNullColumns(clients?.data, column);

  const handleSearch = (e: any) => {
    const search = e.target.value;
    setSearch(search);
  };

  return (
    <ClientsStyle>
      <BreadCrumb
        arrOfOption={["Clients"]}
        refetch={() => refetch()}
        reloaderSize="small"
      />
      <Modal
        title="Send SMS to Client"
        open={commonState.isModal}
        okText={"Submit"}
        cancelText={"Close"}
        onOk={handleModal}
        onCancel={handleModal}
        footer={null}
      >
        {editInfo && <ClientSmsModal info={editInfo} setNull={setEditInfo} />}
      </Modal>
      <Row justify={"space-between"}>
        <Col lg={4}>
          <Space style={{ marginBottom: "1rem" }}>
            {permission?.[crud.create] && (
              <Link to="/clients/add" state={location.pathname}>
                <Button type="primary">
                  <PlusOutlined />
                  Add Client
                </Button>
              </Link>
            )}
            <Button
              type="primary"
              onClick={() => {
                fetchExcel({ ...pagination, search: search });
              }}
            >
              <FileExcelFilled />
              Excel Report
            </Button>
          </Space>
        </Col>

        <FormInputItem
          onBlur={handleSearch}
          onPressEnter={handleSearch}
          placeholder="🔍 Search by clients"
          size={6}
        />
      </Row>

      <Table
        size="small"
        rowClassName={(item) => getRowHightLightClass(item.client_created_date)}
        bordered
        columns={filterColumns.col}
        dataSource={clients?.data}
        scroll={{ x: true }}
        loading={loading}
        pagination={
          clients?.count !== undefined && clients?.count < 20
            ? false
            : {
                ...pagination,
                total: clients?.count,
                showSizeChanger: true,
                pageSizeOptions: ["50", "100", "200", "500"],
                onChange: handlePaginationChange,
              }
        }
      />
    </ClientsStyle>
  );
};

export default ClientLists;

const ClientsStyle = styled.div``;
