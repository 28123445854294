import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Form, message, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Fixed2 } from '../../../../common/utils/common.utils';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import {
  DateInput,
  FormInput,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import {
  useGetAdjustRefundQuery,
  useUpdateAdjustRefundMutation,
} from '../../Api/Endpoints/AirTicketRefundEndpoints';
import { AdjustRefund } from '../../RefundTypes/RefundTypes';

const EditAdjustRefund = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const client_refund_charge = useWatch('client_refund_charge', form);
  const vendor_refund_charge = useWatch('vendor_refund_charge', form);

  const [updateRefund, { isLoading, isError, isSuccess }] =
    useUpdateAdjustRefundMutation();

  const {
    data,
    isLoading: dataLoading,
    isSuccess: dataSuccess,
  } = useGetAdjustRefundQuery({
    id: String(id),
  });

  const details = data?.data;

  useEffect(() => {
    form.setFields([
      {
        name: ['client_return'],
        value:
          Fixed2(details?.airticket_client_price) -
          Fixed2(client_refund_charge),
      },
      {
        name: ['vendor_return'],
        value:
          Fixed2(details?.airticket_purchase_price) -
          Fixed2(vendor_refund_charge),
      },
    ]);
  }, [client_refund_charge, vendor_refund_charge]);

  useEffect(() => {
    if (details) {
      form.setFieldsValue({
        ...details,
        refund_date: dayjs(details?.refund_date),
        sales_date: dayjs(details?.sales_date),
      });
    }
  }, [dataSuccess, details]);

  const onFinishTicketInfos = async (values: AdjustRefund) => {
    const body = {
      ...values,
      refund_date: dayjs(values.refund_date).format('YYYY-MM-DD'),
    };
    updateRefund({ body: body, id: String(id) });
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      navigate('/refund/history/airticket');
      message.success('Refund request submitted successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isError, isSuccess]);

  return (
    <>
      <BreadCrumb arrOfOption={['Refund', 'Air Ticket Refund']} />
      <Link to='/refund/history/airticket'>
        <Button type='primary' style={{ marginBottom: '1rem' }}>
          <ArrowLeftOutlined />
          Return to list
        </Button>
      </Link>

      <Card loading={dataLoading}>
        <Form layout='vertical' form={form} onFinish={onFinishTicketInfos}>
          <Row
            gutter={{ xs: 8, sm: 16, md: 12, lg: 12 }}
            className='border p-15'
            align={'middle'}
            style={{ position: 'relative' }}
          >
            <FormInput
              size={6}
              name={['invoice_id']}
              label='invoice_id'
              hidden
            />
            <FormInput
              size={6}
              name={['invoice_category_id']}
              label='invoice_category_id'
              hidden
            />
            <FormInput
              size={6}
              name={['airticket_id']}
              label='airticket_id'
              hidden
            />
            <FormInput
              size={6}
              name={['airticket_vendor_id']}
              label='airticket_vendor_id'
              hidden
            />
            <FormInput
              size={6}
              name={['airticket_vendor_combine_id']}
              label='airticket_vendor_combine_id'
              hidden
            />
            <FormInput
              size={6}
              name={['airticket_client_id']}
              label='airticket_client_id'
              hidden
            />
            <FormInput
              size={6}
              name={['airticket_combined_id']}
              label='airticket_combined_id'
              hidden
            />
            {/* ---------------------------------------------------------------------------------------- */}

            <FormInput
              label='Ticket No'
              size={6}
              name={['ticket_no']}
              readonly
            />
            <FormInput
              label='Invoice No'
              size={6}
              name={['invoice_no']}
              readonly
            />
            <DateInput
              label='Sales Date'
              size={6}
              name={['sales_date']}
              disabled
            />
            <DateInput
              label='Refund Date'
              size={6}
              name={['refund_date']}
              required
            />
            <FormInput
              label='Client Name'
              size={6}
              name={['client_name']}
              readonly
            />

            <NumberInput
              label='Sales'
              size={6}
              readOnly
              name={['airticket_client_price']}
            />

            <NumberInput
              label='Client Refund Charge'
              size={6}
              name={['client_refund_charge']}
              required
            />

            <NumberInput
              label='Client Return'
              size={6}
              name={['client_return']}
              readOnly
            />

            <FormInput
              label='Vendor Name'
              size={6}
              name={['vendor_name']}
              readonly
            />

            <NumberInput
              label='Purchase'
              size={6}
              readOnly
              name={['airticket_purchase_price']}
            />

            <NumberInput
              label='Vendor Refund Charge'
              size={6}
              name={['vendor_refund_charge']}
              required
            />
            <NumberInput
              label='Vendor Return'
              size={6}
              name={['vendor_return']}
              readOnly
            />
          </Row>

          <Row style={{ marginTop: 10, marginRight: -12 }} justify={'end'}>
            <Form.Item>
              <Button loading={isLoading} type='primary' htmlType='submit'>
                {id ? 'Update' : 'Create'} Refund
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default EditAdjustRefund;
