import { ColumnsType } from 'antd/lib/table';

import { Typography } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { IVoidDataType } from '../type/void_and_delete_type';

const { Text } = Typography;

type Props = {
  pagination: {
    current: number;
    pageSize: number;
  };
};

export const void_utils = ({
  pagination,
}: Props): ColumnsType<IVoidDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Sales Date',
      dataIndex: 'invoice_create_date',
      key: 'invoice_create_date',
      render: (invoice_create_date) =>
        dayjs(invoice_create_date).format('DD-MM-YYYY'),
    },

    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
    },
    {
      title: 'Invoice Category',
      dataIndex: 'invcat_title',
      key: 'invcat_title',
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (_, record) => (
        <Link
          to={
            record.invoice_client_id
              ? `/reports/client_ledger?id=${record.invoice_client_id}`
              : `/reports/combined_ledger?id=${record.invoice_combined_id}`
          }
        >
          {record.client_name}
        </Link>
      ),
    },

    {
      title: 'Sales Price',
      dataIndex: 'invoice_net_total',
      key: 'invoice_net_total',
      render: (invoice_net_total) => Number(invoice_net_total) || 0,
    },
    {
      title: 'Void Charge',
      dataIndex: 'invoice_void_charge',
      key: 'invoice_void_charge',
      render: (invoice_void_charge) => (
        <Typography.Text style={{ color: 'green' }}>
          {Number(invoice_void_charge)}
        </Typography.Text>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'invoices_trash_type',
      key: 'invoices_trash_type',

      render: (invoices_trash_type) => {
        function changeColor() {
          if (invoices_trash_type === 'DELETE') {
            return { color: 'red' };
          }
          return { color: 'red' };
        }
        return <span style={changeColor()}>{invoices_trash_type}</span>;
      },
    },

    {
      title: 'Created By',
      dataIndex: 'user_name',
      key: 'user_name',
    },
  ];
};
