import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Descriptions,
  Form,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Table,
} from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useLazyGetExcelQuery } from '../../ledgers/endpoints/ledgerEndpoints';
import { useLazyGetAllDailySummaryReportQuery } from '../endpoints/dailySummaryEndpoints';
import { IDailySummaryFormDataType } from '../types/dailySummaryTypes';
import {
  dailyClientRefundReportColumn,
  dailyCollectionReportColumn,
  dailyExpenseReportColumn,
  dailySalesReportColumn,
  dailyVendorPaymentReportColumn,
  dailyVendorRefundReportColumn,
} from '../utils/dailySummaryColumn';

const Daily_Summary_Report = ({
  setValue,
  value,
}: {
  setValue: React.Dispatch<React.SetStateAction<number>>;
  value: number;
}) => {
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    query: string;
  }>({
    query: '',
  });
  const [fetchDailySummary, { data, isLoading }] =
    useLazyGetAllDailySummaryReportQuery();

  const onFinish = async (values: IDailySummaryFormDataType) => {
    const date = dayjs(values.date).format('YYYY-MM-DD');
    await fetchDailySummary({
      query: `?day=${date}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      query: `?day=${date}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 50 });

  //pagination--end---
  useEffect(() => {
    form.setFieldValue('date', dayjs());
    const date = dayjs().format('YYYY-MM-DD');
    fetchDailySummary({ query: `?day=${date}` });
    setExcelQuery({
      query: `?day=${date}`,
    });
  }, []);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Daily_Summary`,
  });

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  const optionsSummaryReport = [
    { label: 'Daily Summary', value: 1 },
    { label: 'Monthly Summary', value: 2 },
  ];

  // @HANDLE CONTENTS
  const jsx_com = (
    <>
      <div>
        <FormHeaderTitle title='Daily Summary Report' />

        <Descriptions size='small' bordered column={1}>
          <DescriptionsItem label='Total Vendor Payment'>
            {Number(data?.data?.dailyVendorPayment?.total_payment) || '0'}
          </DescriptionsItem>
          <DescriptionsItem label='Total Sales'>
            {Number(data?.data?.dailySalesAmount[0]?.sales_total) || '0'}
          </DescriptionsItem>
          <DescriptionsItem label='Total Purchase'>
            {Number(data?.data?.dailyPurchase[0]?.total_cost) || '0'}
          </DescriptionsItem>

          <DescriptionsItem label='Total Expense'>
            {Number(data?.data?.dailyExpenseAmount[0]?.expense_total) || '0'}
          </DescriptionsItem>

          <DescriptionsItem label='Total Refund From Client'>
            {Number(data?.data?.dailyClientRefund[0]?.client_refund_total) ||
              '0'}
          </DescriptionsItem>

          <DescriptionsItem label='Total Refund From Vendor'>
            {Number(data?.data?.dailyVendorRefund[0]?.vendor_refund_total) ||
              '0'}
          </DescriptionsItem>
          <DescriptionsItem label='Total Employee Expense Amount'>
            {Number(data?.data?.dailyEmployeeExpenseAmount[0]?.salary) || '0'}
          </DescriptionsItem>

          <DescriptionsItem label='Total Account Collection'>
            {Number(
              data?.data?.dailyAccountCollection[0]?.account_collection
            ) || '0'}
          </DescriptionsItem>
        </Descriptions>
      </div>

      <FormHeaderTitle title='Daily Sales Report' />

      <Table
        size='small'
        dataSource={data?.data?.dailySalesReport}
        columns={dailySalesReportColumn()}
        rowKey='invoice_id'
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        bordered
        pagination={false}
      />

      <FormHeaderTitle title='Daily Expense Report' />

      <Table
        size='small'
        dataSource={data?.data?.dailyExpenseReport}
        columns={dailyExpenseReportColumn()}
        rowKey='expense_vouchar_no'
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        bordered
        pagination={false}
      />

      <FormHeaderTitle title='Daily Vendor Payment Report' />

      <Table
        size='small'
        dataSource={data?.data?.dailyVendorPaymentReport}
        columns={dailyVendorPaymentReportColumn()}
        rowKey='vouchar_no'
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        bordered
        pagination={false}
      />

      <FormHeaderTitle title='Daily Collection Report' />

      <Table
        size='small'
        dataSource={data?.data?.dailyCollectionReport}
        columns={dailyCollectionReportColumn()}
        rowKey='vouchar_no'
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        bordered
        pagination={false}
      />

      <FormHeaderTitle title='Daily Client Refund Report' />

      <Table
        size='small'
        dataSource={data?.data?.dailyClientRefundReport}
        columns={dailyClientRefundReportColumn()}
        rowKey='vouchar_no'
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        bordered
        pagination={false}
      />

      <FormHeaderTitle title='Daily Vendor Refund Report' />

      <Table
        size='small'
        dataSource={data?.data?.dailyVendorRefundReport}
        columns={dailyVendorRefundReportColumn()}
        rowKey='vouchar_no'
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        bordered
        pagination={false}
      />
    </>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Daily Summary Report' }}
      />
    </div>
  );
  return (
    <>
      <div>
        <BreadCrumb arrOfOption={['Reports', 'Daily Summary Report']} />
        <Space style={{ marginBottom: '1rem' }}>
          <Button type='primary' onClick={handlePrint}>
            <PrinterOutlined />
            Print
          </Button>
          <Button
            type='primary'
            onClick={() => {
              fetchExcel({
                client_id: '',
                query: excelQuery.query,
                excelApiName: 'daily-summery',
                excelName: 'daily-summery',
              });
            }}
          >
            <FileTextOutlined />
            Excel Report
          </Button>
          <Radio.Group
            options={optionsSummaryReport}
            onChange={onChange}
            value={value}
            optionType='button'
            buttonStyle='solid'
          />
        </Space>
        <Row></Row>

        <Form layout='vertical' form={form} onFinish={onFinish}>
          <ReportsHeader required date_select />
        </Form>
      </div>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default Daily_Summary_Report;
