import { Table } from 'antd';
import { useEffect, useState } from 'react';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useLazyGetAllOpeningBalanceQuery } from '../../Api/Endpoints/OpeningBalanceEndponts';
import { ListOfAllOpeningBalanceColumn } from '../../utils/AllOpeningBalance/ListOfAllOpeningBalanceColumn';

const All_opening_Balance = ({ openList }: { openList: string }) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });
  const [getAllOpeningBalance, { data, isLoading, isFetching }] =
    useLazyGetAllOpeningBalanceQuery();
  useEffect(() => {
    if (openList === 'all' && pagination) {
      getAllOpeningBalance(
        `page=${pagination.current}&size=${pagination.pageSize}`
      );
    } else {
      getAllOpeningBalance(
        `page=${pagination.current}&size=${pagination.pageSize}&filter=${openList}`
      );
    }
  }, [openList, pagination]);

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const knowThemeMode = localStorage.getItem('theme');
  return (
    <>
      {
        <Table
          rowKey={(e) => e.op_id}
          size='small'
          bordered
          rowClassName={
            knowThemeMode == 'darkTheme'
              ? (_, index) => (index % 2 == 0 ? '' : 'rowColor1')
              : undefined
          }
          columns={ListOfAllOpeningBalanceColumn({ pagination })}
          dataSource={data?.data}
          pagination={
            data?.count !== undefined && data?.count < 20
              ? false
              : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
          }
          loading={{
            spinning: isLoading || isFetching,
            indicator: <LoadingIndicator />,
          }}
        />
      }
    </>
  );
};

export default All_opening_Balance;
