import Radio, { RadioChangeEvent } from 'antd/es/radio';
import React from 'react';
import { IGetAppConfig } from '../type/appConfig_Type';
import { usePostEditAppConfigMutation } from '../endpoint/appConfigEndpoint';
import { Col, Row, Space, Typography } from 'antd';

type props = {
  configDetails: IGetAppConfig | undefined;
  setConfigDetails: React.Dispatch<
    React.SetStateAction<IGetAppConfig | undefined>
  >;
};
export const AutoSms = ({ configDetails, setConfigDetails }: props) => {
  const [editPost] = usePostEditAppConfigMutation();
  const defaultValue = configDetails?.tac_auto_sms;

  const handleChange = (e: RadioChangeEvent) => {
    const body = {
      ...configDetails,
      tac_auto_sms: e.target.value,
    };

    editPost(body);
  };

  return (
    <div
      style={{
        maxWidth: 900,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Row justify={'space-between'}>
        <Col>
          <Typography.Title level={4}>Auto SMS:</Typography.Title>
          <Typography.Text type='secondary'>
            Useful for sending automated SMS to your users
          </Typography.Text>
        </Col>
        <Col>
          <Radio.Group onChange={handleChange} value={defaultValue}>
            <Space direction='vertical'>
              <Radio value={0}>Auto Sms Off</Radio>
              <Radio value={1}>Auto Sms On</Radio>
            </Space>
          </Radio.Group>
        </Col>
      </Row>
    </div>
  );
};
