import React, { useState } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { setModal } from "../../../../common/slices/commonSlice";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { Table } from "antd";
import { CompanyDataType } from "../../companies/types/companyTypes";
import { companiesColumns } from "../../companies/utils/utils";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import CommonTableHeader from "../../../../components/common/CommonTableHeader";
import EditCompanyModal from "../../companies/modals/EditCompanyModal";
import { perProps } from "../../../../common/types/commonTypes";
import AddNewContactModal from "../modals/AddNewContactModal";
import { useGetAllContactsQuery } from "../Endpoints/contactsEndpoints";
import { IContact } from "../types/contactTypes";
import { contactColumns } from "../utils/utils";
import EditContactModal from "../modals/EditContactModal";

const Contacts = ({ permission }: perProps) => {
  const dispatch = useAppDispatch();
  const [editInfo, setEditInfo] = useState<IContact | null>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  const showModal = () => {
    dispatch(setModal(true));
  };

  const {
    isLoading,
    data: contacts,
    refetch,
  } = useGetAllContactsQuery({ ...pagination });

  console.log(editInfo);
  return (
    <div>
      <BreadCrumb
        arrOfOption={["Configuration", "Contacts"]}
        // refetch={refetch}
        reloaderSize="small"
      />

      <CommonTableHeader
        title="Contacts"
        modalTitle={editInfo ? "Edit Contact" : "Add New Contacts"}
        button_text="Add New Contact"
        permission={permission?.["create:any"]}
        // element={<AddNewContactModal />}
        element={
          editInfo ? (
            <EditContactModal info={editInfo} setNull={setEditInfo} />
          ) : (
            <AddNewContactModal />
          )
        }
        // setNull={setEditInfo}
      />
      <div className="table">
        <Table
          size="small"
          bordered
          rowKey={"account_contact_id"}
          pagination={
            contacts?.count !== undefined && contacts?.count < 20
              ? false
              : {
                  ...pagination,
                  total: contacts?.count,
                  showSizeChanger: true,
                  pageSizeOptions: ["50", "100", "200", "500"],
                  onChange: handlePaginationChange,
                }
          }
          dataSource={contacts?.data}
          columns={contactColumns({
            setEditInfo,
            showModal,
            permission,
            pagination,
          })}
          scroll={{ x: true }}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
        />
      </div>
    </div>
  );
};

export default Contacts;
