import { Button, ConfigProvider, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';

import JoditEditor from 'jodit-react';
import { usePostEditAppConfigMutation } from '../endpoint/appConfigEndpoint';
import { IGetAppConfig } from '../type/appConfig_Type';

type Props = {
  configDetails: IGetAppConfig | undefined;
};

const InvoiceExtraInfo = ({ configDetails }: Props) => {
  const [postEdit, { isLoading }] = usePostEditAppConfigMutation();
  const [content, setContent] = useState('');
  const editor = useRef(null);

  const prevNote = configDetails?.tac_invoice_footer_note;

  useEffect(() => {
    if (prevNote) {
      setContent(prevNote);
    }
  }, [prevNote]);

  const handleSubmit = (e: any) => {
    const body: IGetAppConfig = {
      ...configDetails,
      tac_invoice_footer_note: content,
    };

    postEdit(body);
  };
  return (
    <ConfigProvider
    // theme={{
    //   algorithm: theme.defaultAlgorithm,
    // }}
    >
      <div
        style={{
          maxWidth: 900,
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: 35,
        }}
      >
        <Typography.Title level={4}>Invoice Footer Info</Typography.Title>

        <JoditEditor
          config={{ height: 400 }}
          ref={editor}
          value={content}
          onBlur={(newContent) => setContent(newContent)}
        />

        <Button
          loading={isLoading}
          style={{ float: 'right' }}
          onClick={handleSubmit}
          type='primary'
        >
          Save
        </Button>
      </div>
    </ConfigProvider>
  );
};

export default InvoiceExtraInfo;
