import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Image, Upload } from 'antd';
import { RcFile, UploadProps } from 'antd/es/upload';
import { useState } from 'react';
import { acceptedFileTypes } from '../../modules/Passport_MGT/PassportTypes/PassportTypes';
import { toasterNotification } from './ToasterNotification';

type Props = {
  smSize?: number;
  mdSize?: number;
  size?: number;
  offset?: number;
  msg?: string;
  name?: string;
  label?: string;
  noBorder?: boolean;
  onChange?: (img: any) => void;
  imagURL?: string;
};

const FileUpload = ({
  smSize,
  mdSize,
  size,
  offset,
  label,
  msg,
  name,
  noBorder,
  onChange,
  imagURL,
}: Props) => {
  const [fileType, setFileType] = useState<RcFile>();
  const [imageFile, setImageFile] = useState<string>();

  const beforeUpload = (file: RcFile) => {
    setFileType(file);
    const isJpgOrPng = acceptedFileTypes.includes(file.type);

    setImageFile(URL.createObjectURL(file));

    if (!isJpgOrPng) {
      toasterNotification(
        'error',
        'Accepted File Formats - JPG/JPEG/PNG/WEBP/PDF'
      );
      setImageFile(undefined);
      return isJpgOrPng || Upload.LIST_IGNORE;
    }
    const limitFileSize = file.size / 1024 / 1024 < 1;
    if (!limitFileSize) {
      toasterNotification('error', 'File size must be less than 1 MB');
      setImageFile(undefined);
      return limitFileSize || Upload.LIST_IGNORE;
    }
    return false;
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    onChange && onChange(newFileList);
  };

  return (
    <Col
      xs={24}
      sm={smSize ? smSize : 12}
      md={mdSize ? mdSize : 8}
      lg={{ span: size || 8, offset: offset || 0 }}
    >
      <Form.Item
        label={label || ''}
        name={name || 'scan_copy'}
        valuePropName={name || 'scan_copy'}
      >
        <Upload
          accept={'image/jpeg,image/jpeg,image/png,image/webp,pdf'}
          maxCount={1}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          onRemove={() => {
            setImageFile(undefined);
          }}
        >
          <Button icon={<UploadOutlined style={{ width: '100%' }} />}>
            {msg || 'Your Msg Here'}
          </Button>
        </Upload>
      </Form.Item>
      {imageFile &&
        (fileType?.type == 'application/pdf' ? (
          <iframe src={imageFile} width={100} />
        ) : (
          <Image loading='lazy' src={imageFile} width={100} />
        ))}

      {!imageFile && <Image src={imagURL} width={100} />}
    </Col>
  );
};

export default FileUpload;
