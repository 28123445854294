import { Tabs } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import InvoiceActivityLogs from '../../../../components/common/Invoice/ViewInvoiceInfo/ActivityLogs';
import CommonViewInvoice from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice';
import TabDetailsView from '../../../../components/common/Invoice/ViewInvoiceInfo/TabDetailsView';
import TabPaymentView from '../../../../components/common/Invoice/ViewInvoiceInfo/TabPaymentView';
import TabUmrahRefundDetails from '../../../../components/common/Invoice/ViewInvoiceInfo/TabUmrahRefundDetails';
import ViewInvoiceHeader from '../../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader';
import FakeViewUmrahBilling from '../../../../components/common/Invoice/ViewInvoiceInfo/edited_invoice/view/Billing/FakeViewUmrahBilling';
import ViewFakeInvoice from '../../../../components/common/Invoice/ViewInvoiceInfo/edited_invoice/view/ViewFakeInvoice';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import ViewInvoiceUmrahBilling from '../../Components/ViewInvoiceUmrahBilling';
import ViewUmrahTavDetails from '../../Components/ViewUmrahTavDetails';
import { useGetViewInvoiceUmmrahQuery } from '../../api/endpoints/invoiceUmrahEndpoints';
import { Tab } from '../../../Invoice_Re_Issue/pages/ViewInvoiceInfo/ViewInvoiceReissue';

const InvoiceUmmrahView = () => {
  // ================= DATA FOR VIEW =====================
  const invoice_id = Number(useParams().id);
  // GET INVOICE AIRTICKET DETAILS
  const { data: invoices, isLoading } =
    useGetViewInvoiceUmmrahQuery(invoice_id);
  const invoiceData = invoices?.data;
  const invoice_category_id = invoiceData?.invoice_category_id;
  const viewInvoicePrintableRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceCostDetailsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoicePaymentsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceAcivityRef = React.useRef<HTMLDivElement>(null);
  const viewRefundDetailsRef = React.useRef<HTMLDivElement>(null);
  const viewEditedInvoiceRef = React.useRef<HTMLDivElement>(null);

  const header_ref = React.useRef<HTMLDivElement>(null);
  const signiture_ref = React.useRef<HTMLDivElement>(null);
  const subtotal_ref = React.useRef<HTMLDivElement>(null);

  const [selectedForPrint, setSelectedForPrint] = useState<
    '1' | '2' | '3' | '4' | '5' | '6'
  >();

  let selectedPrintableRef = viewInvoicePrintableRef;

  switch (selectedForPrint) {
    case '1':
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
    case '2':
      selectedPrintableRef = viewInvoiceCostDetailsRef;
      break;
    case '3':
      selectedPrintableRef = viewInvoicePaymentsRef;
      break;
    case '4':
      selectedPrintableRef = viewInvoiceAcivityRef;
      break;
    case '5':
      selectedPrintableRef = viewRefundDetailsRef;
      break;
    case '6':
      selectedPrintableRef = viewEditedInvoiceRef;
      break;
    default:
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
  }

  const items: Tab[] = [
    {
      label: 'Invoice',
      key: '1',
      children: isLoading ? (
        <LoadingIndicator />
      ) : (
        invoiceData && (
          <CommonViewInvoice
            invoiceData={invoiceData}
            viewInvoicePrintableRef={viewInvoicePrintableRef}
            heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
            children={<ViewInvoiceUmrahBilling invoiceDetails={invoiceData} />}
          />
        )
      ),
    },
    {
      label: 'Details',
      key: '2',
      children: invoiceData && (
        <TabDetailsView
          invoiceDetails={invoiceData}
          children={<ViewUmrahTavDetails invoiceDetails={invoiceData} />}
          viewInvoiceCostDetailsRef={viewInvoiceCostDetailsRef}
        />
      ),
    },
    {
      label: 'Payments',
      key: '3',
      children: (
        <>
          <TabPaymentView
            invoiceDetails={invoiceData}
            viewInvoicePaymentsRef={viewInvoicePaymentsRef}
          />
        </>
      ),
    },
    {
      label: 'Activity Log',
      key: '4',
      children: (
        <>
          <InvoiceActivityLogs
            id={invoice_id}
            viewInvoiceAcivityRef={viewInvoiceAcivityRef}
          />
        </>
      ),
    },

    {
      label: 'Refund Details',
      key: '5',
      children: invoiceData && (
        <TabUmrahRefundDetails viewInvoicePaymentsRef={viewRefundDetailsRef} />
      ),
    },
  ];

  if (invoiceData?.is_edited) {
    items.push({
      key: '6',
      label: 'Edited Invoice',
      children: (
        <>
          {invoiceData && (
            <ViewFakeInvoice
              invoiceData={invoiceData}
              viewInvoicePrintableRef={viewEditedInvoiceRef}
              heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
              children={<FakeViewUmrahBilling invoiceDetails={invoiceData} />}
            />
          )}
        </>
      ),
    });
  }
  return (
    <>
      <ViewInvoiceHeader
        invoice_category_id={invoice_category_id}
        invoice_id={invoice_id}
        selectedPrintableRef={selectedPrintableRef}
        invoiceData={invoiceData}
      />

      <Tabs
        style={{ marginTop: '10px' }}
        onChange={(e) => {
          switch (e) {
            case '1':
              setSelectedForPrint && setSelectedForPrint('1');
              break;
            case '2':
              setSelectedForPrint && setSelectedForPrint('2');
              break;
            case '3':
              setSelectedForPrint && setSelectedForPrint('3');
              break;
            case '4':
              setSelectedForPrint && setSelectedForPrint('4');
              break;
            case '5':
              setSelectedForPrint && setSelectedForPrint('5');
              break;
            case '6':
              setSelectedForPrint && setSelectedForPrint('6');
              break;
            default:
              break;
          }
        }}
        type='card'
        items={items.sort((a, b) => Number(a.key) - Number(b.key))}
      ></Tabs>
    </>
  );
};

export default InvoiceUmmrahView;
