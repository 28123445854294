import { Row, Table } from 'antd';
import { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { perProps } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllMaharamWithPaginationQuery } from '../endpoints/maharamEndpoints';
import AddNewMaharam from '../modals/AddNewMaharam';
import EditMaharamModal from '../modals/EditMaharam';
import { MaharamDataType } from '../types/MaharamTypes';
import { maharamCollumns } from '../utils/utils';

const Maharam = ({ permission }: perProps) => {
  const dispatch = useAppDispatch();
  const [editInfo, setEditInfo] = useState<MaharamDataType | null>(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const showModal = () => {
    dispatch(setModal(true));
  };
  const {
    isLoading,
    data: maharam,
    refetch,
  } = useGetAllMaharamWithPaginationQuery(pagination, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const count = cache.data?.count;

      const dataToReturn: MaharamDataType[] = [];
      const maharamNameList: string[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          maharamNameList.push(element.maharam_name);
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: { dataToReturn, maharamNameList, count } };
    },
  });
  return (
    <div>
      <BreadCrumb
        arrOfOption={['Configuration', 'Maharam ']}
        refetch={refetch}
        reloaderSize='small'
      />
      <CommonTableHeader
        width
        align
        modalTitle={editInfo ? 'Edit Maharam' : 'Add New Maharam'}
        title='Maharam'
        button_text='Add New Maharam'
        permission={permission?.['create:any']}
        element={
          editInfo ? (
            <EditMaharamModal
              info={editInfo}
              maharamNameList={maharam.maharamNameList}
              setNull={setEditInfo}
            />
          ) : (
            <AddNewMaharam maharamNameList={maharam.maharamNameList} />
          )
        }
        setNull={setEditInfo}
      />
      <Row justify={'center'}>
        <Table
          size='small'
          style={{ width: 900 }}
          bordered
          pagination={
            maharam?.count !== undefined && maharam?.count < 20
              ? false
              : {
                ...pagination,
                total: maharam?.count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
          }
          columns={maharamCollumns({
            setEditInfo,
            showModal,
            permission,
            pagination,
          })}
          dataSource={maharam.dataToReturn}
          scroll={{ x: true }}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
        />
      </Row>
    </div>
  );
};

export default Maharam;
