import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { TOTALDUEADVANCEMPLOYEE } from '../../Api/constants';

import { ITotal_Due_Advance_EmployeeDataType } from '../types/Total_Due_Advance_EmployeeTypes';

export const total_due_advance_employeeEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all total due_advance of EMPLOYEE
    employeeDueAdvance: build.query<
      HTTPResponse<ITotal_Due_Advance_EmployeeDataType[]>,
      {
        employee_id: string | number;
        current: number;
        pageSize: number;
      }
    >({
      query: ({ employee_id, pageSize, current }) => ({
        url: `/report/employee-due-advance/${employee_id}?page=${current}&size=${pageSize}`,
      }),
      providesTags: [
        {
          type: 'TotalDueAdvanceEmployee',
          id: TOTALDUEADVANCEMPLOYEE,
        },
      ],
    }),
  }),
});

export const { useEmployeeDueAdvanceQuery } =
  total_due_advance_employeeEndpoints;
