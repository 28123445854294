import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import {
  HajiTransferInPostType,
  HajjInformationSubmitAPI,
  IAllCancelPreReg,
  IAllTransferInData,
  IGetAllTrakingNo,
  IGetTrackingNoByGroup,
  IGroupToGroupAll,
  IHajjiManagementAll,
  IPostCancelHajiPreReg,
  IPostGroupToGroup,
  IPosthajjiManagement,
  ITrackingNoGetAll,
} from '../../Types/HajjiManagement.interface';

export const hajjiManagementEndspoint = api.injectEndpoints({
  endpoints: (build) => ({
    /**
     * =========== CLIENT TO CLIENT
     *
     */

    // @GET ALL
    getAllHajjiManagement: build.query<
      HTTPResponse<IHajjiManagementAll[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/hajji_management/client_to_client?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'hajji-management' }],
    }),

    // @GET FOR EDIT
    getClienttoClientForEdit: build.query<
      HTTPResponse<IPosthajjiManagement>,
      number
    >({
      query: (id) => ({
        url: `/hajji_management/client_to_client/${id}`,
      }),
      providesTags: () => [{ type: 'hajji-management' }],
    }),

    // @Get tracking no by client id
    getTrackingNoByClientId: build.query<
      HTTPResponse<ITrackingNoGetAll[]>,
      {
        clientId: string;
      }
    >({
      query: ({ clientId }) => ({
        url: `/hajji_management/tracking_no/${clientId}`,
      }),
      providesTags: () => [{ type: 'hajji-management' }],
    }),

    // @POST
    postHajjiManagement: build.mutation<
      HTTPResponse<void>,
      IPosthajjiManagement
    >({
      query: (body) => ({
        url: '/hajji_management/client_to_client',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'hajji-management' }],
    }),
    // @EDIT
    editClientToClient: build.mutation<
      HTTPResponse<void>,
      { body: IPosthajjiManagement; id: number }
    >({
      query: ({ body, id }) => ({
        url: `/hajji_management/client_to_client/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'hajji-management' }],
    }),

    // @DELETE
    deleteClientToClient: build.mutation<
      HTTPResponse<void>,
      { id: number; user_id?: number }
    >({
      query: ({ id, user_id }) => ({
        url: `/hajji_management/client_to_client/${id}`,
        method: 'DELETE',
        body: {
          transfer_deleted_by: user_id,
        },
      }),
      invalidatesTags: [{ type: 'hajji-management' }],
    }),

    /**
     * =============== GROUP TO GROUP =========
     *
     */

    // @POST
    postGrouptoGroup: build.mutation<HTTPResponse<void>, IPostGroupToGroup>({
      query: (body) => ({
        url: '/hajji_management/group_trans',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'hajji-management' }],
    }),

    // @GET ALL
    getAllGroupToGroup: build.query<
      HTTPResponse<IGroupToGroupAll[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/hajji_management/group_trans/all?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'hajji-management' }],
    }),

    // @GET ALL TRACKING NO BY GROUP
    trackingNoByGroupId: build.query<
      HTTPResponse<IGetTrackingNoByGroup[]>,
      string
    >({
      query: (id) => ({
        url: `hajji_management/tracking_no/group/${id}`,
      }),
      providesTags: () => [{ type: 'hajji-management' }],
    }),

    // @GET FOR EDIT
    getGroupToGroupForEdit: build.query<
      HTTPResponse<IPostGroupToGroup>,
      number
    >({
      query: (id) => ({
        url: `/hajji_management/group_trans/${id}`,
      }),
      providesTags: () => [{ type: 'hajji-management' }],
    }),

    // @EDIT
    editGroupToGroup: build.mutation<
      HTTPResponse<void>,
      { body: IPostGroupToGroup; id: number }
    >({
      query: ({ body, id }) => ({
        url: `/hajji_management/group_trans/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'hajji-management' }],
    }),

    // @DELETE
    deleteGroupTogrouop: build.mutation<
      HTTPResponse<void>,
      { id: number; userID?: number }
    >({
      query: ({ id, userID }) => ({
        url: `/hajji_management/group_trans/${id}`,
        method: 'DELETE',
        body: { deleted_by: userID },
      }),
      invalidatesTags: [{ type: 'hajji-management' }],
    }),

    // ===================@POST CANCEL HAJI PRE REG
    postCancelHajiPreReg: build.mutation<
      HTTPResponse<void>,
      IPostCancelHajiPreReg
    >({
      query: (body) => ({
        url: '/hajji_management/cancel_pre_reg',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'hajji-management' }],
    }),

    getAllHajiPreReg: build.query<HTTPResponse<IAllCancelPreReg[]>, void>({
      query: () => ({
        url: '/hajji_management/cancel_pre_reg/all',
      }),
      providesTags: () => [{ type: 'hajji-management' }],
    }),

    // @DELETE
    deleteCancelHajiPrereg: build.mutation<
      HTTPResponse<void>,
      { cancel_id: number; deleted_by: number }
    >({
      query: ({ cancel_id, deleted_by }) => ({
        url: `/hajji_management/cancel_pre_reg/${cancel_id}`,
        method: 'DELETE',
        body: { deleted_by },
      }),
      invalidatesTags: [{ type: 'hajji-management' }],
    }),

    /**
     * =================@TransferIn start
     *
     */
    getAllTransferIn: build.query<
      HTTPResponse<IAllTransferInData[]>,
      { inOrOut: 'in' | 'out' }
    >({
      query: (data) => ({
        url: `/hajji_management/transfer_in/all/${data.inOrOut}`,
      }),
      providesTags: () => [{ type: 'hajji-management' }],
    }),

    getForEditTransferIn: build.query<
      HTTPResponse<HajiTransferInPostType>,
      { id: number }
    >({
      query: (data) => ({
        url: `/hajji_management/transfer_in/get-for-edit/${data.id}`,
      }),
      providesTags: () => [{ type: 'hajji-management' }],
    }),

    postTransferIn: build.mutation<
      HTTPResponse<void>,
      HajjInformationSubmitAPI
    >({
      query: (body) => ({
        url: '/hajji_management/transfer_in',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'hajji-management' }],
    }),

    updateTransferIn: build.mutation<
      HTTPResponse<void>,
      { data: HajjInformationSubmitAPI; id?: number | string }
    >({
      query: ({ data, id }) => ({
        url: `/hajji_management/transfer_in/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [{ type: 'hajji-management' }],
    }),

    deleteTransferIn: build.mutation<
      HTTPResponse<void>,
      { id: number; userID?: number }
    >({
      query: ({ id, userID }) => ({
        url: `/hajji_management/transfer_in/${id}`,
        method: 'DELETE',
        body: { deleted_by: userID },
      }),
      invalidatesTags: [{ type: 'hajji-management' }],
    }),

    //================================ @TransferOut Start=======================
    postTransferOut: build.mutation<
      HTTPResponse<void>,
      HajjInformationSubmitAPI
    >({
      query: (body) => ({
        url: '/hajji_management/transfer_out',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'hajji-management' }],
    }),

    updateTransferOut: build.mutation<
      HTTPResponse<void>,
      { data: HajjInformationSubmitAPI; id?: number | string }
    >({
      query: ({ data, id }) => ({
        url: `/hajji_management/transfer_out/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [{ type: 'hajji-management' }],
    }),

    getPreRegTrakingNo: build.query<HTTPResponse<IGetAllTrakingNo[]>, string>({
      query: (search: string) => ({
        url: `/hajji_management/tracking_no/hajj-pre-reg?search=${search}`,
      }),
      providesTags: [{ type: 'hajji-management' }],
    }),

    getHajiInfoByTrakingNo: build.mutation<
      HTTPResponse<ITrackingNoGetAll[]>,
      { ticket_no: number[] }
    >({
      query: (body) => ({
        url: `/hajji_management/haji-info-by-traking_no`,
        method: 'POST',
        body: { ticket_no: body },
      }),
      invalidatesTags: [{ type: 'hajji-management' }],
    }),
  }),
});

export const {
  // @Client to Client
  useGetAllHajjiManagementQuery,
  usePostHajjiManagementMutation,
  useGetClienttoClientForEditQuery,
  useEditClientToClientMutation,
  useDeleteClientToClientMutation,
  useLazyGetTrackingNoByClientIdQuery,
  // @Group To Group
  usePostGrouptoGroupMutation,
  useGetAllGroupToGroupQuery,
  useGetGroupToGroupForEditQuery,
  useEditGroupToGroupMutation,
  useDeleteGroupTogrouopMutation,
  usePostCancelHajiPreRegMutation,
  useGetAllHajiPreRegQuery,
  useDeleteCancelHajiPreregMutation,
  useTrackingNoByGroupIdQuery,
  useLazyTrackingNoByGroupIdQuery,
  // @TransferIn
  useGetAllTransferInQuery,
  useLazyGetAllTransferInQuery,
  usePostTransferInMutation,
  useGetForEditTransferInQuery,
  useLazyGetForEditTransferInQuery,
  useUpdateTransferInMutation,
  useDeleteTransferInMutation,
  // @TransferOut
  usePostTransferOutMutation,
  useUpdateTransferOutMutation,
  // @cancle pre reg
  useLazyGetPreRegTrakingNoQuery,
  useGetPreRegTrakingNoQuery,
  useGetHajiInfoByTrakingNoMutation,
} = hajjiManagementEndspoint;
