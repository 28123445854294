import { Button, Form, Modal } from 'antd';
import { useState } from 'react';
import { Fixed2 } from '../../../common/utils/common.utils';
import CreateTaxRefundForm from '../../Refund/Pages/AirTicketTax/components/CreateTaxRefundForm';
import HajjRefundModalContent from './InvoiceHajjRefund/HajjRefundModalContent';
import ReIssueModalContent from './InvoiceReIssueRefund/ReIssueModalContent';
import UmrahRefundModalContent from './InvoiceUmrahRefund/UmrahRefundModalContent';

type Props = {
  title: string;
  invoiceNo: string;
  invoiceId: number;
  invoice_category_id: number | undefined;
};

const RefundModal = ({
  title,
  invoiceId,
  invoiceNo,
  invoice_category_id,
}: Props) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  let newTitle = '';
  if (invoice_category_id === 1) {
    newTitle = 'Invoice Air Ticket Tax Refund';
  }

  return (
    <>
      <Button type='primary' onClick={showModal}>
        {'Refund'}
      </Button>
      <Modal
        title={newTitle || 'Refund ' + title}
        footer={false}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={800}
      >
        {invoice_category_id === 1 && (
          <CreateTaxRefundForm
            form={form}
            handleCancel={handleCancel}
            invoiceId={invoiceId}
            invoiceNo={invoiceNo}
            invoice_category_id={Fixed2(invoice_category_id)}
          />
        )}

        {invoice_category_id === 3 && (
          <ReIssueModalContent
            form={form}
            handleCancel={handleCancel}
            invoiceId={invoiceId}
            invoiceNo={invoiceNo}
            invoice_category_id={Fixed2(invoice_category_id)}
          />
        )}

        {invoice_category_id === 31 && (
          <HajjRefundModalContent
            form={form}
            handleCancel={handleCancel}
            invoiceId={invoiceId}
            invoiceNo={invoiceNo}
            invoice_category_id={Fixed2(invoice_category_id)}
          />
        )}
        {invoice_category_id === 26 && (
          <UmrahRefundModalContent
            form={form}
            handleCancel={handleCancel}
            invoiceId={invoiceId}
            invoiceNo={invoiceNo}
            invoice_category_id={Fixed2(invoice_category_id)}
          />
        )}
      </Modal>
    </>
  );
};

export default RefundModal;
