import {
  DeleteFilled,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Space,
  Table,
  TimePicker,
  Typography,
} from 'antd';
import { TableProps } from 'antd/lib';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { IPaxDetails } from '../../../../common/types/commonInterfaces';
import { IAirticketBillingInfo } from '../../../IATA_AIR_TICKET/types/invoiceAirTicketTypes';
import { IFlightData } from '../../../IATA_AIR_TICKET/Components/ManualInvoiceAirTicketEdited/ManualEditInVoiceFlight';
import { filterNullColumns } from '../../../../common/utils/common.utils';

type DataIndex = keyof IAirticketBillingInfo;

// COMMON
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: DataIndex;
  title: any;
  inputType: 'number' | 'text' | 'date' | 'time' | 'type';
  record: IAirticketBillingInfo;
  index: number;
}

// MAIN COMPONENT
const InvoiceBillingEdit = ({
  allEditableData,
  setAllEditableData,
  invoiceBilling,
  setIsBillingUpdate,
  isBillingUpdate,
}: {
  setAllEditableData?: any;
  allEditableData: {
    flightDetails?: IFlightData[] | undefined;
    paxDetails?: IPaxDetails[] | undefined;
    billingDetails?: IAirticketBillingInfo[] | undefined;
    subtotalDetails?: any;
  };
  invoiceBilling: IAirticketBillingInfo[] | undefined;
  setIsBillingUpdate?: React.Dispatch<
    React.SetStateAction<{
      update: string;
      add: string;

      remove: string;
    }>
  >;
  isBillingUpdate?: {
    update: string;
    add: string;

    remove: string;
  };
}) => {
  const [form] = Form.useForm();
  const [paxData, setPaxData] = useState<IAirticketBillingInfo[] | undefined>(
    invoiceBilling?.map((item, index) => ({
      ...item,
      billing_id: index + 1,
    }))
  );
  const [editingKey, setEditingKey] = useState(0);
  const [isAdding, setIsAdding] = useState(false);

  const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode = <Input size='small' />;

    if (inputType === 'number') {
      inputNode = <InputNumber size='small' />;
    } else if (inputType === 'date') {
      inputNode = (
        <span>
          <DatePicker
            size='small'
            defaultValue={dayjs(record[dataIndex])}
            onChange={(date) => {
              form.setFieldsValue({
                [dataIndex]: date ? date.format('YYYY-MM-DD') : '',
              });
            }}
            format={'DD-MM-YYYY'}
          />
        </span>
      );
    } else if (inputType === 'time') {
      inputNode = <TimePicker size='small' />;
    } else {
      inputNode = <Input size='small' />;
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            // rules={[
            //   {
            //     required: true,
            //     message: `Please Input ${title}!`,
            //   },
            // ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  const isEditing = (record: IAirticketBillingInfo) =>
    record.billing_id === editingKey;
  const Add = (record: IAirticketBillingInfo) => {
    isBillingUpdate &&
      setIsBillingUpdate &&
      setIsBillingUpdate({
        ...isBillingUpdate,
        add: 'add',
      });
    setIsAdding(true);
    const newData: any = {
      billing_id: (record?.billing_id as number) + 1,
      airticket_id: '',
      airticket_ticket_no: '',
      airticket_pnr: '',
      airticket_classes: '',
      airticket_ticket_type: '',
      airticket_routes: '',
      airticket_journey_date: dayjs(),
      airticket_return_date: dayjs(),
      airticket_gross_fare: '',
      airticket_client_price: '',
      type: '',
    };
    form.setFieldsValue({
      billing_id: (record.billing_id as number) + 1,
      airticket_ticket_no: '',
      airticket_pnr: '',
      airticket_classes: '',
      airticket_ticket_type: '',
      airticket_routes: '',
      airticket_journey_date: dayjs(),
      airticket_return_date: dayjs(),
      airticket_gross_fare: '',
      airticket_client_price: '',
      type: '',
    });
    setPaxData([...(paxData as IAirticketBillingInfo[]), newData]);
    setEditingKey((record.billing_id as number) + 1);
  };
  const handleDelete = (key: any) => {
    const newData = paxData?.filter((item) => item.billing_id !== key);
    setPaxData(newData);
    setEditingKey(0);
    setIsAdding(false);
    isBillingUpdate &&
      setIsBillingUpdate &&
      setIsBillingUpdate({
        ...isBillingUpdate,
        remove: 'remove',
        add: '',
      });
  };
  const edit = (record: IAirticketBillingInfo) => {
    form.setFieldsValue({
      ...record,
      type: record.type
        ? record.type
        : record.airticket_is_refund
        ? 'REFUND'
        : record.airticket_is_reissued
        ? 'REISSUED'
        : record.airticket_is_void
        ? 'VOID'
        : 'ISSUED',
    });
    setEditingKey(record?.billing_id as number);

    isBillingUpdate &&
      setIsBillingUpdate &&
      setIsBillingUpdate({ ...isBillingUpdate, update: 'update' });
  };

  const cancel = () => {
    isBillingUpdate &&
      setIsBillingUpdate &&
      setIsBillingUpdate({
        ...isBillingUpdate,
        remove: '',
        add: '',
        update: '',
      });
    setEditingKey(0);
  };

  const paxSave = async (id: number) => {
    try {
      const row = (await form.validateFields()) as IAirticketBillingInfo;

      const newData = [...(paxData as IAirticketBillingInfo[])];
      const index = newData.findIndex((item) => id === item.billing_id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setPaxData(newData);
        setEditingKey(0);
        setIsAdding(false);
      } else {
        newData.push(row);
        setPaxData(newData);
        setEditingKey(0);
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const paxColumn = [
    {
      title: 'Sl.',
      dataIndex: 'billing_id',
      editable: false,
    },
    {
      title: 'Ticket No',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
      editable: true,
    },
    {
      title: 'Pax Name',
      dataIndex: 'passport_name',
      key: 'passport_name',
      editable: true,
    },
    {
      title: 'PNR',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
      editable: true,
    },
    {
      title: 'Class',
      dataIndex: 'airticket_classes',
      key: 'airticket_classes',
      editable: true,
    },
    // {
    //   title: 'Class Type',
    //   dataIndex: 'airticket_ticket_type',
    //   key: 'airticket_ticket_type',
    //   editable: true,
    // },

    {
      title: 'Route',
      dataIndex: 'airticket_routes',
      key: 'airticket_routes',
      editable: true,
    },

    {
      title: 'Journey Date',
      dataIndex: 'airticket_journey_date',
      key: 'airticket_journey_date',

      editable: true,
      render: (_: any, data: any) =>
        data.airticket_journey_date
          ? dayjs(data.airticket_journey_date).format('D-MM-YYYY')
          : '',
    },
    {
      title: 'Return Date',
      dataIndex: 'airticket_return_date',
      key: 'airticket_return_date',
      editable: true,
      render: (_: any, data: any) =>
        data.airticket_return_date
          ? dayjs(data.airticket_return_date).format('D-MM-YYYY')
          : '',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      editable: true,
      render: (_: any, data: any) => {
        return (
          <>
            {data?.type ? (
              data.type
            ) : (
              <span>
                {data.airticket_is_refund ? (
                  <span style={{ color: 'red' }}>REFUND </span>
                ) : data.airticket_is_reissued ? (
                  <span>REISSUED </span>
                ) : data.airticket_is_void ? (
                  <span style={{ color: 'red' }}>VOID </span>
                ) : (
                  <span>ISSUED </span>
                )}
              </span>
            )}
          </>
        );
      },
    },
    {
      title: 'Total',
      dataIndex: 'airticket_client_price',
      key: 'airticket_client_price',
      editable: true,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_: any, record: IAirticketBillingInfo, index: number) => {
        const editable = isEditing(record);
        return editable && !isAdding ? (
          <Space>
            <Typography.Link
              onClick={() => paxSave(record?.billing_id as number)}
            >
              ✅
            </Typography.Link>
            <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
              <a>❌</a>
            </Popconfirm>
          </Space>
        ) : isAdding ? (
          <>
            <Typography.Link
              onClick={() => paxSave(record.billing_id as number)}
            >
              ✅
            </Typography.Link>
            <Typography.Link
              disabled={
                record?.billing_id ? editingKey > record?.billing_id : false
              }
              onClick={() => handleDelete(record.billing_id)}
            >
              <DeleteFilled style={{ color: 'red' }} />
            </Typography.Link>
          </>
        ) : (
          <Space>
            <EditOutlined
              style={{
                color: 'blue',
              }}
              disabled={editingKey !== 0}
              onClick={() => edit(record)}
            />
            {paxData?.length === index + 1 ? (
              <a onClick={() => Add(record)}>
                <PlusCircleOutlined />
              </a>
            ) : (
              <PlusCircleOutlined disabled />
            )}

            <DeleteFilled
              onClick={() => handleDelete(record.billing_id)}
              style={{ color: 'red' }}
            />
          </Space>
        );
      },
    },
  ];

  const filteredBillingColumns: any = filterNullColumns(paxData, paxColumn);
  const paxMergedColumns: TableProps['columns'] =
    filteredBillingColumns?.col?.map((col: any) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: IAirticketBillingInfo) => ({
          record,
          inputType:
            col.dataIndex === 'airticket_journey_date' ||
            col.dataIndex === 'airticket_return_date'
              ? 'date'
              : col.dataIndex === 'airticket_client_price' ||
                col.dataIndex === 'airticket_gross_fare'
              ? 'number'
              : col.dataIndex === 'type'
              ? 'text'
              : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });

  useEffect(() => {
    setAllEditableData((prev: any) => ({
      ...prev,
      billingDetails: [...(paxData as IAirticketBillingInfo[])],
    }));
  }, [paxData]);

  // Initial load of data to allEditableData
  useEffect(() => {
    setAllEditableData((prev: any) => ({
      ...prev,
      billingDetails: [...(invoiceBilling as IAirticketBillingInfo[])],
    }));
  }, []);
  return (
    <>
      <Typography.Title level={5} style={{ marginBottom: '5px' }}>
        BILLING INFO
      </Typography.Title>

      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={paxData}
          columns={paxMergedColumns}
          pagination={false}
          size='small'
          style={{ marginBottom: '2rem' }}
          className='invoiceBillingTable'
          rowClassName={'invoiceBillingTd'}
        />
      </Form>
    </>
  );
};

export default InvoiceBillingEdit;
