import { Button, message, Popconfirm, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../auth/states/userSlice';
import { crud, IPermission } from '../../../common/types/commonTypes';
import { formatNumber } from '../../../common/utils/common.utils';
import { useDeleteVendorPaymentMutation } from '../api/endpoints/vendorPaymentEndpoints';
import { IVendorPaymentList } from '../types/vendor.interfaces';

interface Props {
  pagination: {
    current: number;
    pageSize: number;
  };
  isForPrint?: boolean;
  permission: IPermission | undefined;
}

export const VendorPaymentColumns = ({
  pagination,
  isForPrint,
  permission,
}: Props): ColumnsType<IVendorPaymentList> => {
  const { Text } = Typography;
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useSelector(selectUser);

  const [
    deleteVendorPay,
    { isError: deleteError, isSuccess: deleteSucc, isLoading: deleteLoading },
  ] = useDeleteVendorPaymentMutation();

  // ====== vendor payment delete handler
  const deleteVendorPayment = (id: number, index: number) => {
    const body = { id, updated_by: user?.user_id as number };
    setColumnIndex(index);
    deleteVendorPay(body);
  };

  // ================== notification =========================

  useEffect(() => {
    if (deleteSucc) {
      setColumnIndex(null);
      message.success('Vendor payment has been deleted!');
    }
    if (deleteError) {
      setColumnIndex(null);
      message.error('There was an error please try again');
    }
  }, [deleteSucc, deleteError]);

  return [
    {
      title: 'SL',
      key: 'key',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Voucher No',
      dataIndex: 'vouchar_no',
      key: 'vouchar_no',
      align: 'center',
      render: (_, data) => (
        <Link to={`/vendors/payment/${data.vpay_id}`} state={location.pathname}>
          {_}
        </Link>
      ),
    },

    {
      title: 'Payment Date',
      key: 'payment_date',
      align: 'center',
      render: (_, e) => (
        <>{e.payment_date && dayjs(e.payment_date).format('DD MMM YYYY')}</>
      ),
    },

    {
      title: 'Vendor',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      render: (_, data) => (
        <Link
          to={`/reports/vendor_ledger?id=${data.vpay_vendor_id}`}
          state={location.pathname}
        >
          {_}
        </Link>
      ),
    },
    {
      title: 'Account',
      dataIndex: 'account_name',
      key: 'account_name',
      render: (_, data) => (
        <Link
          to={`/accounts/account_statement/${data.vpay_account_id}`}
          state={location.pathname}
        >
          {_}
        </Link>
      ),
    },

    {
      title: 'Payment By',
      dataIndex: 'payment_by',
      key: 'payment_by',
    },
    {
      title: 'Created By',
      dataIndex: 'user_full_name',
      key: 'user_full_name',
    },
    {
      title: 'Payment Amount',
      dataIndex: 'payment_amount',
      key: 'payment_amount',
      align: 'right',
      render: (curr) => (
        <span className='color-profit'>{formatNumber(curr)}</span>
      ),
    },
    {
      title: 'Billing Period',
      dataIndex: 'vpay_bsp_period',
      key: 'vpay_bsp_period',
      align: 'center',
    },
    {
      title: 'Action',
      key: 'operation',
      hidden: isForPrint,
      width: 125,
      render: (_, data, index) => (
        <Space size='small'>
          {permission?.['read:any'] && (
            <Link
              to={`/vendors/payment/${data.vpay_id}`}
              state={location.pathname}
            >
              <Button
                size='small'
                type='primary'
                style={{ backgroundColor: '#00a65a', border: 'none' }}
              >
                <Typography style={{ color: 'white' }}>View</Typography>
              </Button>
            </Link>
          )}
          {permission?.[crud.update] && (
            <Button
              size='small'
              type='primary'
              style={{ background: '#00b4e9', border: 'none' }}
            >
              <Link
                to={`/vendors/payment/edit/${data.vpay_id}`}
                state={location.pathname}
              >
                <Typography style={{ color: 'white' }}>Edit</Typography>
              </Link>
            </Button>
          )}
          {permission?.[crud.delete] && (
            <Button
              size='small'
              type='primary'
              style={{ background: '#e90800', border: 'none' }}
              loading={columnIndex === index && true}
            >
              <Popconfirm
                onConfirm={() => deleteVendorPayment(data.vpay_id, index)}
                title='Sure to delete?'
              >
                Delete
              </Popconfirm>
            </Button>
          )}
        </Space>
      ),
    },
  ];
};
