import { message, Radio, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../auth/states/userSlice';
import { Fixed2, pagination } from '../../../common/utils/common.utils';
import BreadCrumb from '../../common/breadCrumb/BreadCrumb';
import {
  useGetAllVisaDelivaryNotificationsQuery,
  useVisaStatusUpdateMutation,
} from '../Api/NotificationEndpoints';
import {
  IVisaDeliveryInvoice,
  IVisaStatusUp,
} from '../Interfaces/Notification.interface';

const column: ColumnsType<IVisaDeliveryInvoice> = [
  {
    title: 'SL.',
    key: 'Sl.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Visa',
    dataIndex: 'product_name',
    key: 'product_name',
  },
  {
    title: 'Client Name',
    dataIndex: 'client_name',
    key: 'client_name',
  },

  {
    title: 'Mobile',
    key: 'client_mobile',
    render: (_, data) => {
      if (data?.client_mobile) {
        const mobile = JSON.parse(data?.client_mobile)[0];
        return <> {mobile && mobile[0] + '-' + mobile[1]} </>;
      }
    },
  },
  {
    title: 'Invoice No.',
    dataIndex: 'invoice_no',
    key: 'invoice_no',
  },

  {
    title: 'Visiting Country',
    dataIndex: 'visiting_country_name',
    key: 'visiting_country_name',
  },
  {
    title: 'Delivery Date',
    key: 'billing_delivery_date',
    render: (_, data) => (
      <> {dayjs(data.billing_delivery_date).format('DD MMM YYYY')} </>
    ),
  },
  {
    title: 'Created Date',
    key: 'billing_create_date',
    render: (_, data) => (
      <> {dayjs(data.billing_create_date).format('DD MMM YYYY')} </>
    ),
  },
];

const VisaDelivery = ({ visaType }: { visaType: 'Pending' | 'Delivery' }) => {
  const user = useSelector(selectUser);
  const [updateVisaStatus, { isError, isSuccess, isLoading, error }] =
    useVisaStatusUpdateMutation();

  useEffect(() => {
    if (isSuccess) {
      message.success('Visa status updated');
    } else if (isError) {
      message.error('Thare was an error');
    }
  }, [isLoading]);

  // invoice visa status pending
  const changeStatusHandler = (
    status: 'Approved' | 'Rejected',
    billingId: number
  ) => {
    const data: IVisaStatusUp = {
      body: { created_by: user?.user_id },
      billing_id: billingId,
      status: status,
    };

    updateVisaStatus(data);
  };
  // visa pending status
  const visaPendingColumn: ColumnsType<IVisaDeliveryInvoice> = [
    {
      title: 'SL.',
      key: 'Sl.',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
    },
    {
      title: 'Visa',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
    },

    {
      title: 'Visiting Country',
      dataIndex: 'visiting_country_name',
      key: 'visiting_country_name',
    },
    {
      title: 'Quantity',
      dataIndex: 'billing_quantity',
      key: 'billing_quantity',
    },
    {
      title: 'Unit Price',
      dataIndex: 'billing_unit_price',
      key: 'billing_unit_price',
    },
    {
      title: 'Cost Price',
      dataIndex: 'billing_cost_price',
      key: 'billing_cost_price',
      render: (_, data) => Fixed2(data?.billing_cost_price),
    },
    {
      title: 'Total Sales',
      dataIndex: 'billing_subtotal',
      key: 'billing_subtotal',
      render: (_, data) => Fixed2(data?.billing_subtotal),
    },
    {
      title: 'Profit',
      dataIndex: 'billing_profit',
      key: 'billing_profit',
      render: (_, data) => Fixed2(data?.billing_profit),
    },
    {
      title: 'Status',
      key: 'billing_status',
      render: (_, data) => {
        return (
          <Radio.Group size='small' buttonStyle='solid'>
            <Radio.Button
              value='Approve'
              onChange={() => changeStatusHandler('Approved', data.billing_id)}
            >
              Approve
            </Radio.Button>
            <Radio.Button
              value='Reject'
              onChange={() => changeStatusHandler('Rejected', data.billing_id)}
            >
              Reject
            </Radio.Button>
          </Radio.Group>
        );
      },
    },
  ];

  const { data: visadelivaryNotification } =
    useGetAllVisaDelivaryNotificationsQuery();

  const visaDelivery =
    visadelivaryNotification?.data &&
    visadelivaryNotification?.data?.visa_delivery.invoices;
  const visaPending =
    visadelivaryNotification?.data &&
    visadelivaryNotification?.data.visa_pending.invoices;

  let tableData: IVisaDeliveryInvoice[] = [];
  if (visaType === 'Delivery' && visaDelivery) {
    tableData = visaDelivery;
  } else if (visaType === 'Pending' && visaPending) {
    tableData = visaPending;
  }

  return (
    <div>
      <BreadCrumb arrOfOption={[`Today Visa ${visaType}`]} />

      <Table
        rowKey={(e) => e.billing_invoice_id}
        size='small'
        bordered
        dataSource={tableData}
        columns={visaType === 'Delivery' ? column : visaPendingColumn}
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        pagination={pagination(tableData.length)}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default VisaDelivery;
