import { Row, Table } from 'antd';
import { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { crud, perProps } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllProductsWithPagQuery } from '../endpoints/productsEndpoints';
import ProductAddModal from '../modals/ProductAddModal';
import ProductEditModal from '../modals/ProductEditModal';
import { ProductDataType } from '../types/productTypes';
import { columns } from '../utils/utils';

const Products = ({ permission }: perProps) => {
  const dispatch = useAppDispatch();
  const [editInfo, setEditInfo] = useState<ProductDataType | null>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const showModal = () => {
    dispatch(setModal(true));
  };

  const {
    isLoading,
    data: products,
    refetch,
  } = useGetAllProductsWithPagQuery(pagination);

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Configuration', 'Products']}
        refetch={refetch}
        reloaderSize='small'
      />
      <CommonTableHeader
        width
        align
        title='Product'
        modalTitle={editInfo ? 'Edit Product' : 'Add New Product'}
        button_text='Add New Product'
        permission={permission?.[crud.create]}
        element={
          editInfo ? (
            <ProductEditModal info={editInfo} setNull={setEditInfo} />
          ) : (
            <ProductAddModal />
          )
        }
        setNull={setEditInfo}
      />
      <Row justify={'center'}>
        <Table
          size='small'
          style={{ width: 900 }}
          bordered
          pagination={{
            ...pagination,
            total: products?.count,
            showSizeChanger: true,
            pageSizeOptions: ['50', '100', '200', '500'],
            onChange: handlePaginationChange,
          }}
          columns={columns({ setEditInfo, showModal, permission, pagination })}
          dataSource={products?.data}
          scroll={{ x: true }}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
        />
      </Row>
    </div>
  );
};

export default Products;
