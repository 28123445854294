export const getLedgerRefLink = (ref_type?: string, ref_id?: number) => {
  if (!ref_type || !ref_id) {
    return null;
  }

  let link = null;

  switch (true) {
    case ref_type.includes('MR'):
      link = '/moneyreceipt/view/' + ref_id;
      break;

    case ref_type.includes('AIT'):
      link = '/airticket/details/' + ref_id;
      break;

    case ref_type.includes('ANC'):
      link = '/noncomairticket/details/' + ref_id;
      break;

    case ref_type.includes('ARI'):
      link = '/reissueairticket/details/' + ref_id;
      break;

    case ref_type.includes('IO'):
      link = '/invoiceother/details/' + ref_id;
      break;

    case ref_type.includes('IV'):
      link = '/visa/details/' + ref_id;
      break;

    case ref_type.includes('TP'):
      link = '/invoiceTour/details/' + ref_id;
      break;

    default:
      break;
  }

  return link;
};
