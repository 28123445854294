import { ColumnsType } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { IAitReportDataType } from '../types/aitReportTypes';

const AitReportColumn = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<IAitReportDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },
    {
      title: 'Sales Date',
      key: 'date',
      render: (_, record) => {
        return `${dayjs(record.airticket_sales_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      render: (curr, rec) => {
        const { vendor_id } = rec;
        if (vendor_id)
          return (
            <Link to={`/reports/vendor_ledger?id=${rec.vendor_id}`}>
              {curr}
            </Link>
          );

        return curr;
      },
    },

    {
      title: 'Air ticket No',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
      align: 'right',
    },

    {
      title: 'PNR',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
      align: 'right',
    },
    {
      title: 'Ait Amount',
      dataIndex: 'airticket_ait',
      key: 'airticket_ait',
      align: 'right',
      render: (_, record) => Number(record?.airticket_ait),
    },
    {
      title: 'Commission',
      dataIndex: 'airticket_net_commssion',
      key: 'airticket_net_commssion',
      align: 'right',
    },
  ];
};

export default AitReportColumn;
