import { FormInstance } from 'antd/lib/form';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  FormInput,
  FormInputItem,
} from '../../../components/common/FormItem/FormItems';
import { SelectClients } from '../../../components/common/FormItem/SelectCustomFeilds';
import { ISelectClientDetails } from '../types/invoiceAirTicketTypes';
type Props = {
  form: FormInstance<any>;
};

export default function AirticketHeaderPNR({ form }: Props) {
  /* set ClientName instance when create new one */
  const [instanceClientsSelect, setInstanceClientSelect] = useState<number>();
  const [instanceAgnetSelect, setInstanceAgentSelect] = useState<number>();
  const [instanceSalesEmpSelect, setInstanceSalesEmpSelect] =
    useState<number>();
  const { airticketInvoiceId } = useParams();

  useEffect(() => {
    if (instanceClientsSelect) {
      form.setFieldValue(
        'invoice_combclient_id',
        'client-' + instanceClientsSelect
      );
    } else if (instanceAgnetSelect) {
      form.setFieldValue('invoice_agent_id', instanceAgnetSelect);
    } else if (instanceSalesEmpSelect) {
      form.setFieldValue('invoice_sales_man_id', instanceSalesEmpSelect);
    }
  }, [instanceAgnetSelect, instanceSalesEmpSelect, instanceClientsSelect]);

  const [clientDetails, setClientDetails] = useState<ISelectClientDetails>();

  const getPrevFiledValue = form.getFieldValue('invoice_walking_customer_name');

  return (
    <>
      <SelectClients
        label='Search Client:'
        name='invoice_combclient_id'
        required
        size={24}
        mdSize={8}
        xsSize={5}
        smSize={12}
        disabled={!!airticketInvoiceId}
        setClientDetails={setClientDetails}
        setInstanceClientSelect={setInstanceClientSelect}
      />
      {(clientDetails?.client_walking_customer === 1 || getPrevFiledValue) && (
        <FormInput
          size={24}
          mdSize={8}
          smSize={12}
          label='Client Name'
          name='invoice_walking_customer_name'
        />
      )}

      <FormInputItem
        label='PNR:'
        name='invoice_pnr'
        size={24}
        required
        rules={[
          {
            validator(rule, value, callback) {
              if (value.length === 6) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  new Error('PNR must be 6 characters long.')
                );
              }
            },
          },
        ]}
      />
      {/* <FormInputItem label='Vat:' name='invoice_vat' size={24} /> */}
      {/* <FormInputItem label='Discount:' name='invoice_discount' size={24} /> */}
      <FormInputItem
        label='Service Charge:'
        name='invoice_service_charge'
        size={24}
      />
    </>
  );
}
