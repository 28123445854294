import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row, Select } from 'antd';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { selectUser } from '../../../../auth/states/userSlice';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import {
  FormButton,
  FormInputItem,
  FormMobileNumberItem,
  MobileInput,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import { SelectOptionsInput } from '../../../../components/common/FormItem/SelectCustomFeilds';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllClientCategoryQuery } from '../../../Configuration/clientCategory/endpoints/clientCategoryEndpoints';
import { useGetAllProductsQuery } from '../../../Configuration/Products/endpoints/productsEndpoints';
import { ProductDataType } from '../../../Configuration/Products/types/productTypes';
import { categoryChildren } from '../../Client/utils/utils_tsx/utilAddClient';
import {
  useEditCombineClientMutation,
  useLazyGetCombineClientEditQuery,
  usePostCombineClientMutation,
} from '../api/endpoints/clientCombineEndpoints';
import CombineClientProducts from '../components/CombineClientProducts';
import { ICombineClient } from '../types/combineClientTypes';
import { useWatch } from 'antd/es/form/Form';

type Props = { reason: 'ADD' | 'EDIT' };

export default function NewClientCombine({ reason }: Props) {
  const [postCombineClient, { isError, isLoading, isSuccess, error }] =
    usePostCombineClientMutation();

  const [
    editCombineClient,
    {
      isError: isErrorEdit,
      isLoading: isLoadingEdit,
      isSuccess: isSuccessEdit,
      error: errorEdit,
    },
  ] = useEditCombineClientMutation();

  const [selectProducts, setSelectProducts] = useState<number[]>([]);
  const { data: products } = useGetAllProductsQuery();
  const [form] = Form.useForm();
  const isHideOpenBal = useWatch('opening_balance_type', form);
  const openingBalance = Number(useWatch('combine_opening_balance', form));
  const { data: clientCategories } = useGetAllClientCategoryQuery();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const editMode = useRef(false);
  const { id } = useParams();

  if (id) {
    editMode.current = true;
  } else {
    editMode.current = false;
  }

  React.useEffect(() => {
    if (isSuccess) {
      toasterNotification('success', 'Combine client created');
      form.resetFields();
      navigate('/combineds');
    }
  }, [isSuccess]);
  React.useEffect(() => {
    if (isError) {
      toasterNotification('error', 'Something wrong');
    }
  }, [isError]);

  const onFinishHandle = (values: any, reasonProp: 'ADD' | 'EDIT') => {
    if (user && values?.cproduct_product_id?.length) {
      const formatedData: ICombineClient = {
        combine_category_id: values.combine_category_id,
        combine_client_type: values.combine_client_type,
        combine_name: values.combine_name && values.combine_name,
        combine_company_name: values.combine_company_name || undefined,
        combine_gender: values.combine_gender || undefined,
        combine_email: values.combine_email || undefined,
        combine_designation: values.combine_designation || undefined,
        combine_mobile: values.number,
        combine_address: values.combine_address && values.combine_address,
        combine_opening_balance: values.combine_opening_balance
          ? Number(values.combine_opening_balance)
          : undefined,
        opening_account_id: values.opening_account_id
          ? Number(values.opening_account_id)
          : undefined,
        opening_payment_type: values.opening_payment_type
          ? Number(values.opening_payment_type)
          : undefined,

        combine_commission_rate: values.combine_commission_rate
          ? Number(values.combine_commission_rate)
          : 0,
        opening_balance_type: values.opening_balance_type
          ? values.opening_balance_type
          : undefined,
        cproduct_product_id:
          values.cproduct_product_id && values.cproduct_product_id,
        ...(reason === 'ADD'
          ? { combine_create_by: user.user_id as number }
          : { combine_update_by: user.user_id as number }),
        combine_credit_limit: values.combine_credit_limit || undefined,
      };

      if (reasonProp === 'ADD') {
        postCombineClient(formatedData);
      } else if (reasonProp === 'EDIT' && id) {
        editCombineClient({ body: formatedData, id });
      }
    } else message.error('Products is required');
  };

  // ======================== Combine Client Edit ==========================
  const [
    getCombineClientEdit,
    {
      data: combineClientEditData,
      isLoading: isLoadingEditData,
      isError: isErrorEditData,
    },
  ] = useLazyGetCombineClientEditQuery();

  React.useEffect(() => {
    if (id) {
      getCombineClientEdit(id);
    }
  }, [id]);

  React.useEffect(() => {
    if (isSuccessEdit) {
      toasterNotification('success', 'Combine client updated');
      form.resetFields();
      navigate('/combineds');
    }
  }, [isSuccessEdit]);
  React.useEffect(() => {
    if (isErrorEdit) {
      toasterNotification('error', 'Something wrong when update');
    }
  }, [isErrorEdit]);

  React.useEffect(() => {
    if (combineClientEditData?.data) {
      form.setFieldsValue({
        ...combineClientEditData.data,
        number:
          combineClientEditData?.data?.combine_mobile &&
          combineClientEditData?.data?.combine_mobile,
      });
    }
  }, [combineClientEditData]);

  return (
    <ClientAddStyle>
      <BreadCrumb arrOfOption={['Add Combine Client']} />

      <Col style={{ marginBottom: '1rem' }}>
        <Link to='/combineds'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Combined Client List
          </Button>
        </Link>
      </Col>

      <div className='border p-10' style={{ marginBottom: '1rem' }}>
        <Form
          layout='vertical'
          form={form}
          onFinish={(values) => {
            onFinishHandle(
              { ...values, cproduct_product_id: selectProducts },
              reason
            );
          }}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col span={6} xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                name={['combine_category_id']}
                label='Combine Category'
                rules={[
                  {
                    required: true,
                    message: 'Please select a category',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder='Select a Category'
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  allowClear
                >
                  {clientCategories?.data &&
                    categoryChildren(clientCategories?.data)}
                </Select>
              </Form.Item>
            </Col>
            <FormInputItem
              name={['combine_name']}
              label='Name'
              required
              size={6}
            />
            <FormInputItem
              name={['combine_company_name']}
              label='Company Name'
              size={6}
            />
            <FormInputItem
              name={['combine_email']}
              label='Email'
              required={false}
              size={6}
              inputType={'email'}
            />
            <MobileInput
              size={6}
              name={['number']}
              label='Mobile No'
              rules={[
                {
                  message: 'Invalid number format',
                  validator: (_, value) => {
                    if (
                      !value ||
                      (/^\+?[0-9+]+$/.test(value) &&
                        value.length >= 10 &&
                        value.length <= 16)
                    ) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        'Invalid number format. Must be between 10 and 16 characters.'
                      );
                    }
                  },
                },
              ]}
            />
            <SelectOptionsInput
              options={['Male', 'Female']}
              name={['combine_gender']}
              label={'Gender'}
              size={6}
            />
            <FormInputItem
              name={['combine_designation']}
              label='Designation'
              required={false}
              size={6}
            />
            <FormInputItem
              label='Address'
              name={['combine_address']}
              size={6}
            />
            {
              <Col span={6} xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  label='Opening Balance type:'
                  name={['opening_balance_type']}
                >
                  <Select
                    disabled={openingBalance > 0}
                    showSearch
                    placeholder='Select Opening Balance Type'
                    optionFilterProp='balanceChildren'
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Select.Option>Select Balance Type</Select.Option>
                    <Select.Option value='due'>Due</Select.Option>
                    <Select.Option value='advance'>Advance</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            }

            {isHideOpenBal && (
              <>
                <NumberInput
                  name={['combine_opening_balance']}
                  label='Opening Balance'
                  size={6}
                />
              </>
            )}

            <NumberInput
              name={'combine_credit_limit'}
              label='Credit Limit : '
              placeholder={'Credit Limit'}
              size={6}
            />

            {reason === 'ADD' ? (
              <CombineClientProducts
                products={products?.data as ProductDataType[]}
                setSelectProducts={(value) => setSelectProducts(value)}
                openFrom={'CombineClient'}
              />
            ) : (
              <CombineClientProducts
                products={products?.data as ProductDataType[]}
                setSelectProducts={(value) => setSelectProducts(value)}
                selectedVAlue={combineClientEditData?.data?.cproduct_product_id}
                openFrom={'CombineClient'}
                commission={
                  Number(
                    combineClientEditData?.data?.combine_commission_rate || 0
                  ) ||
                  Number(
                    combineClientEditData?.data?.combine_commission_rate || 0
                  ) === 0
                    ? true
                    : false
                }
              />
            )}
          </Row>
          {(isLoading || isLoadingEdit) && <LoadingIndicator />}
          <FormButton
            loading={isLoading || isLoadingEdit}
            label={
              reason === 'ADD' ? 'Add Combine Client' : 'Update Combine Client'
            }
          />
        </Form>
      </div>
    </ClientAddStyle>
  );
}

const ClientAddStyle = styled.div``;
