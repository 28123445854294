import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { GDSREPORT } from '../../Api/constants';
import {
  IGDSReportFromDataType,
  IGDSReportsDataType,
} from '../types/GdsReportType';

export const GdsReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all GDSReport
    getGDSReport: build.query<
      HTTPResponse<{
        list: IGDSReportsDataType[];
        total_gross_fare: string;
        total_commission: string;
        total_segment:string
      }>,
      IGDSReportFromDataType
    >({
      query: (arg) => ({
        url: `/report/gds-report/${arg.gds}?${arg.date_range}&page=${arg?.current}&size=${arg?.pageSize}`,
      }),
      providesTags: [{ type: 'GDSReport', id: GDSREPORT }],
    }),
  }),
});

export const { useLazyGetGDSReportQuery, useGetGDSReportQuery } =
  GdsReportEndpoints;
