import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  FormInstance,
  Row,
  Spin,
  Typography,
} from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { StoreValue } from 'antd/es/form/interface';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Fixed2 } from '../../../common/utils/common.utils';
import {
  DateInput,
  FormInput,
  NumberInput,
} from '../../../components/common/FormItem/FormItems';
import { SelectItem } from '../../../components/common/FormItem/SelectCustomFeilds';
import {
  useGetAirTicketsQuery,
  useLazyGetAirTicketsDetailsQuery,
} from '../Api/Endpoints/AirTicketRefundEndpoints';

type Props = {
  name: number;
  remove: (index: number | number[]) => void;
  form: FormInstance<any>;
  add: (defaultValue?: StoreValue, insertIndex?: number) => void;
  index: number;
  length: number;
};

export const AirTicketRefundFormItem = ({
  name,
  remove,
  form,
  add,
  index,
  length,
}: Props) => {
  const [ticketSearch, setTicketSearch] = useState('');
  const { id } = useParams();

  const {
    data: tickets,
    isFetching,
    isLoading,
  } = useGetAirTicketsQuery(ticketSearch);

  const [
    getTicketDetails,
    { data: ticketData, isLoading: ticketLoading, isFetching: ticketFetching },
  ] = useLazyGetAirTicketsDetailsQuery();

  const ticket_no = useWatch(['billing_information', name, 'ticket_no'], form);
  const client_refund_charge = useWatch(
    ['billing_information', name, 'client_refund_charge'],
    form
  );

  const vendor_refund_charge = useWatch(
    ['billing_information', name, 'vendor_refund_charge'],
    form
  );

  useEffect(() => {
    getTicketDetails({ ticket_no: ticket_no });
  }, [ticket_no]);

  let timeoutId: NodeJS.Timeout | null = null;
  const handleSearch = (value: any) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      if (value !== '') setTicketSearch(value);
    }, 400);
  };

  useEffect(() => {
    form.setFields([
      {
        name: ['billing_information', name, 'client_return'],
        value:
          Fixed2(ticketData?.data?.airticket_client_price) -
          Fixed2(client_refund_charge),
      },
      {
        name: ['billing_information', name, 'vendor_return'],
        value:
          Fixed2(ticketData?.data?.airticket_purchase_price) -
          Fixed2(vendor_refund_charge),
      },
    ]);
  }, [client_refund_charge, vendor_refund_charge]);

  useEffect(() => {
    if (ticketData?.data) {
      const data = ticketData.data;

      const fieldsToSet = [
        { name: 'invoice_id', value: data.invoice_id },
        { name: 'invoice_category_id', value: data.invoice_category_id },
        { name: 'airticket_id', value: data.airticket_id },
        { name: 'airticket_vendor_id', value: data.airticket_vendor_id },
        {
          name: 'airticket_vendor_combine_id',
          value: data.airticket_vendor_combine_id,
        },
        { name: 'airticket_client_id', value: data.airticket_client_id },
        { name: 'airticket_combined_id', value: data.airticket_combined_id },

        { name: 'invoice_no', value: data.invoice_no },
        { name: 'client_name', value: data.client_name },
        { name: 'vendor_name', value: data.vendor_name },
        { name: 'sales_date', value: dayjs(data.sales_date) },
        {
          name: 'airticket_client_price',
          value: Fixed2(data.airticket_client_price),
        },
        {
          name: 'airticket_purchase_price',
          value: Fixed2(data.airticket_purchase_price),
        },
        {
          name: 'refund_date',
          value: dayjs(),
        },
      ];

      form.setFields(
        fieldsToSet.map((field) => ({
          name: ['billing_information', name, field.name],
          value: field.value,
        }))
      );
    }
  }, [ticketData?.data]);

  return (
    <>
      {length > 1 && (
        <Divider
          style={{
            margin: '10px 0px 0px 0px',
          }}
        >
          <Typography.Text>
            Invoice No:{' '}
            <span className='text-primary'>
              {' '}
              {ticketData?.data?.invoice_no}
            </span>
          </Typography.Text>
        </Divider>
      )}
      <Row
        gutter={{ xs: 8, sm: 16, md: 12, lg: 12 }}
        className='border p-15'
        align={'middle'}
        style={{ position: 'relative' }}
      >
        {(ticketFetching || ticketLoading) && (
          <Spin style={{ position: 'absolute', left: 0, right: 0 }} />
        )}
        <FormInput
          size={6}
          name={[name, 'invoice_id']}
          label='invoice_id'
          hidden
        />
        <FormInput
          size={6}
          name={[name, 'invoice_category_id']}
          label='invoice_category_id'
          hidden
        />
        <FormInput
          size={6}
          name={[name, 'airticket_id']}
          label='airticket_id'
          hidden
        />
        <FormInput
          size={6}
          name={[name, 'airticket_vendor_id']}
          label='airticket_vendor_id'
          hidden
        />
        <FormInput
          size={6}
          name={[name, 'airticket_vendor_combine_id']}
          label='airticket_vendor_combine_id'
          hidden
        />
        <FormInput
          size={6}
          name={[name, 'airticket_client_id']}
          label='airticket_client_id'
          hidden
        />
        <FormInput
          size={6}
          name={[name, 'airticket_combined_id']}
          label='airticket_combined_id'
          hidden
        />
        {/* ---------------------------------------------------------------------------------------- */}
        <SelectItem
          onSearch={(e) => handleSearch(e)}
          items={tickets?.data}
          label='Select Ticket'
          size={6}
          name={[name, 'ticket_no']}
          loading={isFetching || isLoading}
          required
          disabled={id ? true : false}
        />
        <FormInput
          label='Invoice No'
          size={6}
          name={[name, 'invoice_no']}
          readonly
        />
        <DateInput
          label='Sales Date'
          size={6}
          name={[name, 'sales_date']}
          disabled
        />
        <DateInput
          label='Refund Date'
          size={6}
          name={[name, 'refund_date']}
          required
        />
        <FormInput
          label='Client Name'
          size={6}
          name={[name, 'client_name']}
          readonly
        />

        <NumberInput
          label='Sales'
          size={6}
          readOnly
          name={[name, 'airticket_client_price']}
        />

        <NumberInput
          label='Client Refund Charge'
          size={6}
          name={[name, 'client_refund_charge']}
          required
        />

        <NumberInput
          label='Client Return'
          size={6}
          name={[name, 'client_return']}
          readOnly
        />

        <FormInput
          label='Vendor Name'
          size={6}
          name={[name, 'vendor_name']}
          readonly
        />

        <NumberInput
          label='Purchase'
          size={6}
          readOnly
          name={[name, 'airticket_purchase_price']}
        />

        <NumberInput
          label='Vendor Refund Charge'
          size={6}
          name={[name, 'vendor_refund_charge']}
          required
        />
        <NumberInput
          label='Vendor Return'
          size={4}
          name={[name, 'vendor_return']}
          readOnly
        />
        {id ? undefined : (
          <Row gutter={10}>
            <Col hidden={name ? false : true}>
              <Button
                danger
                style={{ marginTop: 5 }}
                icon={<MinusCircleOutlined />}
                onClick={() => remove(name)}
              />
            </Col>

            <Col hidden={length !== index + 1 || !ticket_no}>
              <Button
                style={{ marginTop: 5 }}
                icon={<PlusCircleOutlined />}
                onClick={() => add()}
              />
            </Col>
          </Row>
        )}
      </Row>
    </>
  );
};
