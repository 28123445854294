import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ColorState {
  primaryColor: string;
  sidebarColor: string;
}

const initialState: ColorState = {
  primaryColor: localStorage.getItem('primaryColor') || '#18b4e9',
  sidebarColor: localStorage.getItem('sidebarColor') || '#001529',
};

export const colorSlice = createSlice({
  name: 'color',
  initialState,
  reducers: {
    setPrimaryColor: (state, action: PayloadAction<string>) => {
      state.primaryColor = action.payload;
      localStorage.setItem('primaryColor', action.payload);
    },

    setSidebarColor: (state, action: PayloadAction<string>) => {
      state.sidebarColor = action.payload;
      localStorage.setItem('sidebarColor', action.payload);
    },
  },
});

export const { setPrimaryColor, setSidebarColor } = colorSlice.actions;
export default colorSlice.reducer;
