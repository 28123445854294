import { Col, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance, FormListFieldData } from 'antd/lib/form';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Fixed2 } from '../../../common/utils/common.utils';
import {
  DateInput,
  FormInput,
  FormInputItem,
  NumberInput,
  NumberInputCurrencyFormat,
} from '../../../components/common/FormItem/FormItems';
import { SelectOptionsInput } from '../../../components/common/FormItem/SelectCustomFeilds';
import { useLazyTicketIsExistQuery } from '../../IATA_AIR_TICKET/api/endpoints/airticketInvoiceEndpoints';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';

type Props = {
  form: FormInstance<any>;
  field: FormListFieldData;
  index: number;
  formName: string;
};

const NewBillingInfoForm = ({ form, field, index, formName }: Props) => {
  const discount = useWatch('invoice_discount', form);
  const service_charge = useWatch('invoice_service_charge', form);

  const air_ticket_info = useWatch('air_ticket_info', form)?.reduce(
    (acc: any, curr: any) => acc + curr.airticket_client_price,
    0
  );
  const airticket_reissue_fare = useWatch('air_ticket_info', form)?.reduce(
    (acc: any, curr: any) => acc + curr.airticket_purchase_price,
    0
  );
  const invoice_total_profit = useWatch('air_ticket_info', form)?.reduce(
    (acc: any, curr: any) => acc + curr.airticket_profit,
    0
  );

  useEffect(() => {
    if (air_ticket_info || Number(service_charge) || Number(discount)) {
      form.setFieldValue('invoice_total_vendor_price', airticket_reissue_fare);
      form.setFieldValue('invoice_total_profit', invoice_total_profit);
      form.setFieldValue('invoice_sub_total', air_ticket_info || 0);
      form.setFieldValue(
        'invoice_net_total',
        (air_ticket_info || 0) +
          (Number(service_charge) || 0) +
          -Number(discount || 0)
      );
    } else {
      form.setFieldValue('invoice_sub_total', undefined);
    }
  }, [air_ticket_info, service_charge, discount]);

  const { id } = useParams();
  const [handleTicketNo, setHandleTicketNo] = useState<string>('');
  // VALIDATE INVOICE NO

  const [ticketIsExist, { data: ticketIsExisting }] =
    useLazyTicketIsExistQuery();

  useEffect(() => {
    const ticketFetcing = setTimeout(async () => {
      if (handleTicketNo) {
        await ticketIsExist(handleTicketNo);
        const ticket = form.getFieldValue([
          formName,
          index,
          'airticket_ticket_no',
        ]);
        if (ticket) {
          form.validateFields([formName, index, 'airticket_ticket_no']);
        }
      }
    }, 800);
    return () => clearTimeout(ticketFetcing);
  }, [handleTicketNo]);

  useEffect(() => {
    form.setFieldValue([formName, index, 'airticket_commission_percent'], 7);
  }, [index]);

  const penalties =
    useWatch([formName, index, 'airticket_penalties'], form) || 0;

  const fare_difference =
    useWatch([formName, index, 'airticket_fare_difference'], form) || 0;

  const tax_difference =
    useWatch([formName, index, 'airticket_tax'], form) || 0;

  const ait_amount = useWatch([formName, index, 'airticket_ait'], form) || 0;

  const extra_fee =
    useWatch([formName, index, 'airticket_extra_fee'], form) || 0;

  let comm_percent =
    useWatch([formName, index, 'airticket_commission_percent'], form) / 100 ||
    0;

  const commissionAmount = Math.round(fare_difference * comm_percent);

  const reIssueFareWithOutExtraFee =
    penalties + fare_difference + tax_difference;

  const reIssueFare = penalties + fare_difference + tax_difference + extra_fee;
  const actualReIssueFare =
    reIssueFareWithOutExtraFee - commissionAmount + ait_amount;

  const profit = Fixed2(reIssueFare - actualReIssueFare);
  const prev_ticket_no = useWatch(
    [formName, index, 'airticket_reissue_ticket_no'],
    form
  );

  useEffect(() => {
    form.setFieldValue(
      [formName, index, 'airticket_client_price'],
      reIssueFare
    );

    form.setFieldValue(
      [formName, index, 'airticket_ait'],
      Math.round(reIssueFareWithOutExtraFee * 0.003 || 0)
    );
  }, [reIssueFare, reIssueFareWithOutExtraFee]);

  useEffect(() => {
    form.setFieldValue(
      [formName, index, 'airticket_reissue_fare'],
      reIssueFareWithOutExtraFee
    );

    form.setFieldValue(
      [formName, index, 'airticket_purchase_price'],
      Math.round(actualReIssueFare)
    );

    form.setFieldValue(
      [formName, index, 'airticket_profit'],
      Math.round(profit)
    );
  }, [actualReIssueFare, reIssueFareWithOutExtraFee, profit]);

  return (
    <div className='my-20 border p-5'>
      <FormHeaderTitle
        title={`${
          id
            ? 'Edit Ticket & Billing Info'
            : `New Ticket & Billing Info : ${prev_ticket_no}`
        } `}
      />

      <Row gutter={[20, 0]}>
        <Col lg={18}>
          <Row gutter={[5, 0]}>
            <FormInput
              name={[index, 'airticket_ticket_no']}
              label='Ticket No'
              onChange={(value: string) => {
                setHandleTicketNo(value);
              }}
              size={6}
              required
              rules={[
                {
                  validator: async (_, value) => {
                    if (ticketIsExisting?.data && value) {
                      return Promise.reject();
                    } else return Promise.resolve();
                  },
                  message: 'Ticket already exist!',
                },
              ]}
            />

            <>
              <NumberInputCurrencyFormat
                label='Penalties'
                required
                name={[index, 'airticket_penalties']}
                placeholder='Penalties price here'
                size={6}
                mdSize={8}
                min='0'
                maxChar={14}
              />
              <NumberInputCurrencyFormat
                required
                label='Fare Difference'
                name={[index, 'airticket_fare_difference']}
                placeholder='Fare difference here'
                size={6}
                mdSize={8}
                min='0'
                maxChar={14}
              />
              <NumberInputCurrencyFormat
                label='Tax Difference'
                name={[index, 'airticket_tax']}
                placeholder='Tax difference here'
                size={6}
                mdSize={8}
                min='0'
                maxChar={14}
              />

              <SelectOptionsInput
                required
                label='Air-ticket class'
                name={[index, 'airticket_classes']}
                options={[
                  'Economy',
                  'Premium Economy',
                  'Business',
                  'First Class',
                ]}
                size={6}
              />

              <NumberInputCurrencyFormat
                required
                label={'Commission = ' + commissionAmount + ' /-'}
                name={[index, 'airticket_commission_percent']}
                placeholder='Fare commission here'
                size={6}
                mdSize={8}
                min='0'
                max={10}
                maxChar={1}
              />
            </>

            <NumberInputCurrencyFormat
              readOnly={true}
              required={false}
              label={'Reissue Fare'}
              name={[index, 'airticket_reissue_fare']}
              size={6}
              mdSize={8}
              smSize={12}
              maxChar={14}
            />

            <NumberInput
              label='AIT'
              name={[index, 'airticket_ait']}
              size={6}
              min='0'
              maxChar={10}
            />

            <DateInput
              label='Issue Date :'
              name={[index, 'airticket_issue_date']}
              size={6}
            />
            <DateInput
              label='Journey Date :'
              name={[index, 'airticket_journey_date']}
              size={6}
            />

            <DateInput
              label='Return Date :'
              name={[index, 'airticket_return_date']}
              size={6}
            />

            <NumberInput
              label='Extra Fee'
              name={[index, 'airticket_extra_fee']}
              size={6}
              min='0'
              maxChar={10}
            />

            {/* <NumberInput
              label='Discount Amount'
              name={[index, 'invoice_discount']}
              size={6}
              min='0'
              maxChar={10}
            /> */}

            <FormInputItem
              hidden
              label='airticket_existing_airticket_id'
              name={[index, 'airticket_existing_airticket_id']}
            />
            <FormInputItem
              hidden
              label='airticket_existing_invoiceid'
              name={[index, 'airticket_existing_invoiceid']}
            />
            <FormInputItem
              hidden
              label='comb_vendor'
              name={[index, 'comb_vendor']}
            />

            <FormInput name={[index, 'airticket_pnr']} label='PNR' size={6} />
            <FormInputItem
              disabled
              label='Vendor'
              name={[index, 'vendor_name']}
              size={6}
            />
            <FormInputItem
              disabled
              label='Airline Name'
              name={[index, 'airline_name']}
              size={6}
            />
          </Row>
        </Col>
        <Col
          lg={5}
          style={{
            border: '1px solid lightgray',
            padding: '5px 20px',
            borderRadius: '10px',
          }}
        >
          <Row gutter={[5, 0]}>
            <NumberInput
              readOnly
              label='Client Price:'
              name={[index, 'airticket_client_price']}
              placeholder='Client price here'
              size={24}
              mdSize={8}
              smSize={12}
              min='0'
              maxChar={14}
            />

            <NumberInput
              readOnly
              label='Actual Reissue Price :'
              name={[index, 'airticket_purchase_price']}
              placeholder='purchase price here'
              size={24}
              mdSize={8}
              min='0'
              maxChar={14}
            />
            <NumberInput
              readOnly
              label='Profit :'
              name={[index, 'airticket_profit']}
              placeholder='Profit here'
              size={24}
              mdSize={8}
              min='0'
              maxChar={14}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default NewBillingInfoForm;
