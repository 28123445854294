import { Card, Form, Row } from 'antd';
import { Fixed2 } from '../../../../../common/utils/common.utils';
import CreateTaxRefundForm from './CreateTaxRefundForm';

const TaxRefund_Details = () => {
  const searchParams = new URLSearchParams(location.search);
  const invoice_id = searchParams.get('invoice_id');
  const invoice_no = searchParams.get('invoice_no');
  const invoice_category_id = searchParams.get('category');
  const [form] = Form.useForm();

  return (
    <Row justify={'center'}>
      <Card title={'Invoice Air Ticket Tax Refund'} style={{ width: 900 }}>
        <CreateTaxRefundForm
          form={form}
          invoiceId={Fixed2(invoice_id)}
          invoiceNo={String(invoice_no)}
          invoice_category_id={Fixed2(invoice_category_id)}
        />
      </Card>
    </Row>
  );
};

export default TaxRefund_Details;
