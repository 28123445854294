import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { TAXREPORT } from '../../Api/constants';
import {
  IOtherTaxReportDataType,
  ITaxReportDataType,
} from '../types/TaxReportTypes';

export const TaxReportEndPoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all ClientDiscount
    getAllTaxReport: build.query<
      HTTPResponse<ITaxReportDataType[]>,
      {
        query: string;
      }
    >({
      query: ({ query }) => ({
        url: `/report/tax-report${query}`,
      }),
      providesTags: [{ type: 'TAXREPORT', id: TAXREPORT }],
    }),

    getAllOtherTaxReport: build.query<
      HTTPResponse<{ list: IOtherTaxReportDataType[]; total: string }>,
      {
        query: string;
      }
    >({
      query: ({ query }) => ({
        url: `/report/other-tax-report${query}`,
      }),
      providesTags: [{ type: 'TAXREPORT', id: TAXREPORT }],
    }),
  }),
});

export const { useLazyGetAllTaxReportQuery, useLazyGetAllOtherTaxReportQuery } =
  TaxReportEndPoints;
