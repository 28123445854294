import { FormInstance } from 'antd';
import dayjs from 'dayjs';
import {
  Fixed2,
  getAirportByItaCode,
  getItaCodeByAirportIds,
} from '../../../common/utils/common.utils';
import { IAirport } from '../../Configuration/airport/types/AirportTypes';
import {
  INonComFormSubmit,
  nonCommitionInvoicePostType,
  nonInvoice_Invoice_Info,
  nonInvoice_ticket_information,
} from '../types/nonInvoiceAirTicketTypes';
import { IAirticketMoneyReceiptType } from '../../IATA_AIR_TICKET/types/invoiceAirTicketTypes';

export const nonComFormatForPost = ({
  billing_form,
  invoices,
  user_id,
  selectAirport,
  ticketInfoPrev,
}: {
  billing_form: FormInstance<any>;
  invoices: INonComFormSubmit[];
  user_id: number;
  selectAirport?: IAirport[];
  ticketInfoPrev?: nonInvoice_ticket_information[] | undefined;
}) => {
  // @GET SUB-TOTAL
  const getSubTotal = (allNonComInvoices: INonComFormSubmit[]) => {
    let subTotal = 0;
    allNonComInvoices.forEach((el) => {
      subTotal =
        subTotal + el.airticket_client_price + (el.airticket_extra_fee || 0);
    });
    return subTotal;
  };

  // @GET TOTAL-PROFIT
  const getTotalProfit = () => {
    let totalProfit = 0;
    invoices.forEach((el) => {
      if (el.airticket_extra_fee) {
        totalProfit =
          totalProfit +
          el.airticket_extra_fee +
          el.airticket_client_price -
          el.airticket_purchase_price;
      } else {
        totalProfit =
          totalProfit + el.airticket_client_price - el.airticket_purchase_price;
      }
    });
    return totalProfit;
  };
  // @GET TOTAL VENDOR PRICE
  const getTotalVendorPrice = (allNonComInvoices: INonComFormSubmit[]) => {
    let vendorTotal = 0;
    allNonComInvoices.forEach((el) => {
      vendorTotal =
        vendorTotal +
        el.airticket_purchase_price +
        (el.airticket_extra_fee || 0);
    });
    return vendorTotal;
  };

  const extraData = billing_form.getFieldsValue([
    'invoice_show_prev_due',
    'invoice_show_discount',
    'invoice_service_charge',
    'invoice_show_passport_details',
    'invoice_note',
    'invoice_reference',
    'invoice_vat',
    'invoice_discount',
    'invoice_net_total',

    // Money receipt values
    'receipt_payment_type',
    'trans_no',
    'charge_amount',
    'cheque_number',
    'cheque_withdraw_date',
    'cheque_bank_name',
    'account_id',
    'receipt_total_amount',
    'receipt_payment_date',
    'receipt_money_receipt_no',
    'invoice_agent_com_amount',
    'receipt_note',
  ]);

  const lastField = invoices[invoices.length - 1];

  const invoice_info_for_server: nonInvoice_Invoice_Info = {
    invoice_combclient_id: lastField.invoice_combclient_id,
    invoice_sales_man_id: Fixed2(lastField.invoice_sales_man_id),
    invoice_agent_id: lastField.invoice_agent_id,
    invoice_sales_date: lastField.invoice_sales_date,
    invoice_due_date: lastField.invoice_due_date,
    invoice_reference: extraData.invoice_reference || undefined,
    invoice_note: extraData.invoice_note || undefined,
    invoice_sub_total: getSubTotal(invoices),

    invoice_net_total: Fixed2(extraData.invoice_net_total || 0),

    invoice_service_charge: Fixed2(extraData.invoice_service_charge) || 0,

    invoice_discount: Fixed2(extraData.invoice_discount || 0),
    invoice_vat: Fixed2(extraData.invoice_vat || 0),

    invoice_total_profit: getTotalProfit(),
    invoice_total_vendor_price: getTotalVendorPrice(invoices),
    invoice_created_by: user_id,
    invoice_agent_com_amount: extraData.invoice_agent_com_amount,
    invoice_show_prev_due: extraData.invoice_show_prev_due || 0,
    invoice_show_discount: extraData?.invoice_show_discount || 0,
    invoice_no: lastField.invoice_no,
  };
  const money_receipt: IAirticketMoneyReceiptType = {
    receipt_total_amount: extraData?.receipt_total_amount || undefined,
    receipt_payment_type: extraData?.receipt_payment_type || undefined,
    account_id: extraData?.account_id || undefined,
    charge_amount: extraData?.charge_amount || undefined,
    cheque_bank_name: extraData?.cheque_bank_name || undefined,
    cheque_number: extraData?.cheque_number || undefined,
    cheque_withdraw_date: extraData.cheque_withdraw_date
      ? dayjs(extraData.cheque_withdraw_date).format('YYYY-MM-DD')
      : undefined,
    receipt_money_receipt_no: extraData?.receipt_money_receipt_no || undefined,
    receipt_note: extraData?.receipt_note || undefined,
    trans_no: extraData?.trans_no || undefined,
    receipt_payment_date: extraData.receipt_payment_date
      ? dayjs(extraData.receipt_payment_date).format('YYYY-MM-DD')
      : undefined,
  };

  const tempData = {
    invoice_info: invoice_info_for_server,
    ticketInfo: invoices.map((el, i) => {
      return {
        ticket_details: {
          airticket_id: ticketInfoPrev
            ? ticketInfoPrev[i]?.ticket_details?.airticket_id
            : undefined,
          airticket_ticket_no: el.airticket_ticket_no,
          is_deleted: el.is_deleted,
          airticket_client_price: el.clientPriceSet,
          airticket_purchase_price: el.airticket_purchase_price,
          airticket_comvendor: el.airticket_comvendor,
          airticket_airline_id: el.airticket_airline_id,
          airticket_extra_fee: el.airticket_extra_fee,
          airticket_route_or_sector: el?.airticket_route_or_sector
            ? (getAirportByItaCode(
                el?.airticket_route_or_sector as string,
                selectAirport
              ) as string[])
            : undefined,
          airticket_pnr: el?.airticket_pnr || undefined,
          airticket_issue_date: el.airticket_issue_date || undefined,
          airticket_journey_date: el.airticket_journey_date || undefined,
          airticket_classes: el.airticket_classes || undefined,
          airticket_return_date: el.airticket_return_date || undefined,
          airticket_profit: el.airticket_profit
            ? el.airticket_profit
            : Fixed2(el?.clientPriceSet) +
              (el.airticket_extra_fee || 0) -
              el.airticket_purchase_price,
        },

        pax_passports: el.pax_passport,

        flight_details:
          el.flight_details !== undefined
            ? el.flight_details.map((item) => {
                return {
                  ...item,

                  fltdetails_from_airport_id:
                    item?.fltdetails_from_airport_id || undefined,
                  fltdetails_to_airport_id:
                    item?.fltdetails_to_airport_id || undefined,

                  fltdetails_fly_date: item.fltdetails_fly_date
                    ? dayjs(item.fltdetails_fly_date).format('YYYY-MM-DD')
                    : undefined,
                  fltdetails_arrival_time: item.fltdetails_arrival_time
                    ? dayjs(item.fltdetails_arrival_time, 'HH:mm:ss').format(
                        'HH:mm:ss'
                      )
                    : undefined,
                  fltdetails_departure_time: item.fltdetails_departure_time
                    ? dayjs(item.fltdetails_departure_time, 'HH:mm:ss').format(
                        'HH:mm:ss'
                      )
                    : undefined,
                };
              })
            : undefined,
      };
    }),
    money_receipt,
  };
  return tempData;
};

export const getDataFormatForEdit = ({
  data,
  selectAirport,
}: {
  data: nonCommitionInvoicePostType;
  selectAirport?: IAirport[];
}) => {
  const { invoice_info, ticketInfo } = data;

  const tempData: INonComFormSubmit[] = ticketInfo.map((item) => {
    const { flight_details, pax_passport, ticket_details } = item;
    return {
      invoice_no: invoice_info.invoice_no,
      invoice_combclient_id: invoice_info.invoice_combclient_id,
      invoice_agent_id: invoice_info.invoice_agent_id || undefined,
      invoice_sales_man_id: invoice_info.invoice_sales_man_id,
      invoice_sales_date: dayjs(invoice_info.invoice_sales_date),
      invoice_reference: invoice_info.invoice_reference || undefined,
      invoice_note: invoice_info.invoice_note || undefined,
      invoice_sub_total: Fixed2(invoice_info.invoice_sub_total),
      invoice_net_total: Fixed2(invoice_info.invoice_net_total),
      invoice_service_charge:
        Fixed2(invoice_info.invoice_service_charge) || undefined,
      invoice_total_profit: Fixed2(invoice_info.invoice_total_profit),
      invoice_total_vendor_price: Fixed2(
        invoice_info.invoice_total_vendor_price
      ),
      invoice_created_by: invoice_info.invoice_created_by,
      invoice_show_prev_due: invoice_info.invoice_show_prev_due,
      invoice_vat: Fixed2(invoice_info.invoice_vat) || undefined,
      invoice_discount: Fixed2(invoice_info.invoice_discount) || undefined,
      invoice_agent_com_amount:
        Fixed2(invoice_info.invoice_agent_com_amount) || undefined,

      airticket_ticket_no: ticket_details?.airticket_ticket_no,
      airticket_client_price: Fixed2(ticket_details?.airticket_client_price),
      airticket_purchase_price: Fixed2(
        ticket_details?.airticket_purchase_price
      ),
      airticket_comvendor: ticket_details?.airticket_comvendor,
      airticket_airline_id: ticket_details?.airticket_airline_id,
      airticket_extra_fee: Fixed2(ticket_details?.airticket_extra_fee),
      airticket_route_or_sector: ticket_details?.airticket_route_or_sector
        ?.length
        ? getItaCodeByAirportIds(
            [...ticket_details?.airticket_route_or_sector] as string[],
            selectAirport
          )
        : undefined,
      airticket_route_or_sector_id:
        item?.ticket_details?.airticket_route_or_sector,

      airticket_pnr: ticket_details?.airticket_pnr || undefined,
      airticket_issue_date: ticket_details?.airticket_issue_date
        ? dayjs(ticket_details?.airticket_issue_date)
        : undefined,
      airticket_journey_date: ticket_details?.airticket_journey_date
        ? dayjs(ticket_details?.airticket_journey_date)
        : undefined,
      airticket_return_date: ticket_details?.airticket_return_date
        ? dayjs(ticket_details?.airticket_return_date)
        : undefined,
      airticket_profit: Fixed2(ticket_details?.airticket_profit),
      airticket_classes: ticket_details?.airticket_classes,

      clientPriceSet: Fixed2(ticket_details.airticket_client_price),
      purchasePriceSet: Fixed2(ticket_details.airticket_purchase_price),

      pax_passport: pax_passport?.length
        ? pax_passport.map((item) => ({
            ...item,
            passport_name: item.passport_name || undefined,
            passport_person_type: item.passport_person_type,
            dialCode: item.passport_mobile_no?.split('-')[0] || undefined,
            number: item.number || undefined,
            passport_email: item.passport_email || undefined,
            passport_passport_no: item.passport_passport_no || undefined,
            passport_nid_no: item.passport_nid_no || undefined,
            passport_date_of_birth: item.passport_date_of_birth
              ? dayjs(item.passport_date_of_birth)
              : undefined,
            passport_date_of_issue: item.passport_date_of_issue
              ? dayjs(item.passport_date_of_issue)
              : undefined,
            passport_date_of_expire: item.passport_date_of_expire
              ? dayjs(item.passport_date_of_expire)
              : undefined,
            fltdetails_airticket_no: item.fltdetails_airticket_no,
            passport_mobile_no:
              item.passport_mobile_no?.split('-')[1] || undefined,
            passport_mobile_number: item.passport_mobile_number || undefined,
          }))
        : [{}],

      flight_details: flight_details?.length
        ? flight_details?.map((item) => ({
            // fltdetails_from_airport_id: item.fltdetails_from_airport_id
            //   ? getItaCodeByAirportIds(
            //       [item.fltdetails_from_airport_id] as number[],
            //       selectAirport
            //     )
            //   : undefined,

            // fltdetails_to_airport_id: item.fltdetails_to_airport_id
            //   ? getItaCodeByAirportIds(
            //       [item.fltdetails_to_airport_id] as number[],
            //       selectAirport
            //     )
            //   : undefined,
            fltdetails_id: item.fltdetails_id || undefined,
            fltdetails_from_airport_id: item.fltdetails_from_airport_id
              ? item.fltdetails_from_airport_id
              : undefined,

            fltdetails_to_airport_id: item.fltdetails_to_airport_id
              ? item.fltdetails_to_airport_id
              : undefined,

            fltdetails_airline_id: item.fltdetails_airline_id,
            fltdetails_flight_no: item.fltdetails_flight_no,
            fltdetails_fly_date: dayjs(item.fltdetails_fly_date) || undefined,
            fltdetails_departure_time: item.fltdetails_departure_time
              ? dayjs(item.fltdetails_departure_time, 'HH:mm:ss')
              : undefined,
            fltdetails_arrival_time: item.fltdetails_arrival_time
              ? dayjs(item.fltdetails_arrival_time, 'HH:mm:ss')
              : undefined,
            airport_from: item.airport_from,
          }))
        : [{}],
    };
  });

  return tempData;
};
