import { FormInstance, Row } from 'antd';
import {
  DateInput,
  FormInput,
  FormInputItem,
} from '../../../../components/common/FormItem/FormItems';
import {
  SelectAirline,
  SelectAirport,
  SelectAirportV1FormListID,
} from '../../../../components/common/FormItem/SelectCustomFeilds';

type Props = { name: number; index: number; form: FormInstance<any> };

export default function NewAddHajjFormTicketInfo({ form, index, name }: Props) {
  return (
    <Row gutter={[14, 14]}>
      <>
        <FormInput
          number
          name={[name, 'ticket_no']}
          label='Ticket No'
          size={6}
          rules={[
            {
              validator(rule, value, callback) {
                const fullList = form.getFieldValue(['ticketInfo']);

                const filtered = fullList?.filter((item: any, i: any) => {
                  if (value) {
                    if (item?.ticket_no === value) {
                      if (index === i) {
                        return;
                      }
                      return item;
                    }
                  }
                });

                if (filtered?.length) {
                  return Promise.reject('Cannot select duplicate serial Num!!');
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        />
        <FormInput name={[name, 'ticket_pnr']} label='PNR' size={6} />

        <SelectAirport
          label='Route'
          name={[name, 'ticket_route']}
          fullNamePath={['pilgrims_information', name, 'ticket_route']}
          size={6}
          form={form}
        />

        <SelectAirline
          name={[name, 'ticket_airline_id']}
          label='Airline'
          size={6}
        />

        <FormInputItem
          label='Reference No'
          name={[name, 'ticket_reference_no']}
          size={6}
        />

        <DateInput
          name={[name, 'ticket_journey_date']}
          label='Journey Date'
          size={6}
        />
        <DateInput
          name={[name, 'ticket_return_date']}
          label='Return Date'
          size={6}
        />
      </>
    </Row>
  );
}
