import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { crud, perProps } from '../../../../common/types/commonTypes';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import { removeSpaceBeforeWord } from '../../../../components/common/Invoice/InvoiceListHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useGetPaymentsQuery } from '../../Api/Endpoints/PaymentEndpoints';
import { ViewLoanPaymentDataType } from '../../LoanTypes/LoanTypes';
import AddPaymentModal from '../../Modals/Payment/AddPaymentModal';
import EditPaymentModal from '../../Modals/Payment/EditPaymentModal';
import { ViewPaymentList } from '../../Utils/Payment/ViewPaymentList';
import { rangePresets } from '../../../../common/utils/common.utils';

const PaymentList = ({ permission }: perProps) => {
  const dispatch = useAppDispatch();
  const [editInfo, setEditInfo] = useState<ViewLoanPaymentDataType | null>(
    null
  );

  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const showModal = () => {
    dispatch(setModal(true));
  };

  const {
    data: payments,
    isLoading,
    isFetching,
    refetch,
  } = useGetPaymentsQuery({
    ...pagination,
    from: date[0] || '',
    to: date[1] || '',
    search: search,
  });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 50,
    }));
  }, [date, search]);

  // Handle Search Operation
  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Loan Management', 'Payment List']}
        refetch={() => refetch()}
        reloaderSize='small'
      />
      <Row justify={'space-between'} style={{ marginBottom: '1rem' }}>
        {permission?.[crud.create] && (
          <Button type='primary' onClick={showModal}>
            <PlusOutlined />
            Add Payment
          </Button>
        )}

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Here...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <CommonTableHeader
        title=''
        modalTitle={editInfo ? 'Edit Payment' : 'Add Payment'}
        element={
          editInfo ? (
            <EditPaymentModal info={editInfo} setNull={setEditInfo} />
          ) : (
            <AddPaymentModal />
          )
        }
        setNull={setEditInfo}
      />

      <Table
        rowKey={(e) => e.payment_id}
        size='small'
        bordered
        scroll={{ x: true }}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        pagination={
          payments?.count !== undefined && payments?.count < 20
            ? false
            : {
                ...pagination,
                total: payments?.count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        columns={ViewPaymentList({
          setEditInfo,
          showModal,
          permission,
          pagination,
        })}
        dataSource={payments?.data}
      />
    </div>
  );
};

export default PaymentList;
