import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Table from 'antd/lib/table';
import dayjs from 'dayjs';
import {
  IViewBillingInfo,
  IViewInvoiceDetails,
} from '../../../../common/types/commonInterfaces';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import InvoiceTourCostTotal from './InvoiceTourCostTotal';
import { Fixed2 } from '../../../../common/utils/common.utils';

const billingColumn: ColumnsType<IViewBillingInfo> = [
  {
    title: 'SL.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Product',
    dataIndex: 'product_name',
    key: 'product_name',
    align: 'center',
  },
  {
    title: 'Pax Name',
    dataIndex: 'billing_pax_name',
    key: 'billing_pax_name',
    align: 'center',
  },
  {
    title: 'Total Pax',
    dataIndex: 'billing_total_pax',
    key: 'billing_total_pax',
    align: 'right',
  },

  {
    title: 'No. of Room',
    dataIndex: 'billing_numof_room',
    key: 'billing_numof_room',
    width: '100px',
    align: 'right',
  },
  {
    title: 'Total Sales',
    dataIndex: 'billing_total_sales',
    key: 'billing_total_sales',
    align: 'right',
    render(value, record, index) {
      return Number(value || 0);
    },
  },
  {
    title: 'Total Cost',
    dataIndex: 'billing_cost_price',
    key: 'billing_cost_price',
    align: 'right',
    render(value, record, index) {
      return Number(value || 0);
    },
  },
  {
    title: 'Profit',
    dataIndex: 'billing_profit',
    key: 'billing_profit',
    align: 'right',
    render(value, record, index) {
      return Number(value || 0);
    },
  },
  {
    title: 'Billing Date',
    render: (_, data) => (
      <>{dayjs(data?.billing_create_date).format('DD MMM YYYY')}</>
    ),
    key: 'billing_total_sales',
    align: 'right',
  },
];
const tourInvoieColumn: ColumnsType<any> = [
  {
    title: 'Itinerary',
    dataIndex: 'itinerary_particular',
    key: 'itinerary_particular',
  },
  {
    title: 'Package',
    dataIndex: 'passport_name',
    key: 'passport_name',
  },
  {
    title: 'Description',
    dataIndex: 'transport_cost_price',
    key: 'transport_cost_price',
    align: 'left',
  },
  {
    title: 'Cost Price',
    dataIndex: 'coast_price',
    key: 'vendor_name',
    align: 'right',
    render(value, record, index) {
      return Number(value || 0);
    },
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor_name',
    key: 'vendor_name',
  },
];
let billing_column = billingColumn.filter(
  (col: any) =>
    col.dataIndex !== 'billing_cost_price' && col.dataIndex !== 'billing_profit'
);
let table = tourInvoieColumn.filter(
  (col: any) => col.dataIndex !== 'coast_price'
);

type Props = {
  invoiceInformation: IViewInvoiceDetails;
  offSubCoastSubtotal?: boolean;
  conditionalyRender?: boolean;
};
const ViewInvocieTourDetails = ({
  invoiceInformation,
  offSubCoastSubtotal,
  conditionalyRender,
}: Props) => {
  const {
    tourAccms,
    billing_information,
    tourGuide,
    tourOtherTrans,
    tourTicket,
    tourTransports,
  } = invoiceInformation;

  return (
    <>
      {invoiceInformation?.tourFoods.length ||
      tourTransports.length ||
      tourAccms.length ||
      tourGuide.length ||
      tourTransports.length ||
      tourOtherTrans.length ||
      tourTicket.length ? (
        <Table
          size='small'
          rowKey={(record) => Number(record.key)}
          className='invoiceBillingTable'
          rowClassName={'invoiceBillingTd'}
          columns={conditionalyRender ? table : tourInvoieColumn}
          bordered
          pagination={false}
          summary={() => {
            return (
              <>
                {invoiceInformation?.tourFoods?.map((item: any, index) => {
                  return (
                    <Table.Summary.Row key={index}>
                      {index ? (
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      ) : (
                        <Table.Summary.Cell
                          index={0}
                          align='left'
                        >
                          Food
                        </Table.Summary.Cell>
                      )}

                      <Table.Summary.Cell index={1}>
                        {item.itinerary_particular}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={2}
                        align='left'
                      >
                        <Typography
                          style={{ textAlign: 'left', marginLeft: '5px' }}
                        >
                          {item.food_description}
                        </Typography>
                      </Table.Summary.Cell>
                      {!conditionalyRender && (
                        <Table.Summary.Cell index={3}>
                          {Fixed2(item.food_cost_price)}
                        </Table.Summary.Cell>
                      )}
                      <Table.Summary.Cell index={4}>
                        {item.vendor_name}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                })}

                {tourAccms?.map((item: any, index) => {
                  return (
                    <Table.Summary.Row key={index}>
                      {index ? (
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      ) : (
                        <Table.Summary.Cell index={0}>
                          Accommodation
                        </Table.Summary.Cell>
                      )}

                      <Table.Summary.Cell index={1}>
                        {item.itinerary_particular}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <Typography
                          style={{ textAlign: 'left', marginLeft: '5px' }}
                        >
                          {item.accm_description}
                        </Typography>
                      </Table.Summary.Cell>
                      {!conditionalyRender && (
                        <Table.Summary.Cell index={3}>
                          {Fixed2(item.accm_cost_price)}
                        </Table.Summary.Cell>
                      )}
                      <Table.Summary.Cell index={4}>
                        {item.vendor_name}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                })}
                {tourGuide?.map((item: any, index) => {
                  return (
                    <Table.Summary.Row key={index}>
                      {index ? (
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      ) : (
                        <Table.Summary.Cell index={0}>Guide</Table.Summary.Cell>
                      )}
                      <Table.Summary.Cell index={1}>
                        {item.itinerary_particular}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <Typography
                          style={{ textAlign: 'left', marginLeft: '5px' }}
                        >
                          {' '}
                          {item.guide_description}{' '}
                        </Typography>
                      </Table.Summary.Cell>
                      {!conditionalyRender && (
                        <Table.Summary.Cell index={3}>
                          {Fixed2(item.guide_cost_price)}
                        </Table.Summary.Cell>
                      )}
                      <Table.Summary.Cell index={4}>
                        {item.vendor_name}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                })}
                {tourTransports?.map((item: any, index) => {
                  return (
                    <Table.Summary.Row key={index}>
                      {index ? (
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      ) : (
                        <Table.Summary.Cell index={0}>
                          Transport
                        </Table.Summary.Cell>
                      )}

                      <Table.Summary.Cell index={1}>
                        {item.itinerary_particular}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <Typography
                          style={{ textAlign: 'left', marginLeft: '5px' }}
                        >
                          {item.transport_description}
                        </Typography>
                      </Table.Summary.Cell>
                      {!conditionalyRender && (
                        <Table.Summary.Cell index={3}>
                          {Fixed2(item.transport_cost_price)}
                        </Table.Summary.Cell>
                      )}
                      <Table.Summary.Cell index={4}>
                        {item.vendor_name}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                })}
                {tourOtherTrans?.map((item: any, index) => {
                  return (
                    <Table.Summary.Row key={index}>
                      {index ? (
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      ) : (
                        <Table.Summary.Cell index={0}>
                          Other Transport
                        </Table.Summary.Cell>
                      )}

                      <Table.Summary.Cell index={1}>
                        {item.itinerary_particular}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <Typography
                          style={{ textAlign: 'left', marginLeft: '5px' }}
                        >
                          {' '}
                          {item.other_trans_description}
                        </Typography>
                      </Table.Summary.Cell>
                      {!conditionalyRender && (
                        <Table.Summary.Cell index={3}>
                          {Fixed2(item.other_trans_cost_price)}
                        </Table.Summary.Cell>
                      )}
                      <Table.Summary.Cell index={4}>
                        {item.vendor_name}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                })}
                {tourTicket?.map((item: any, index) => {
                  return (
                    <Table.Summary.Row key={index}>
                      {index ? (
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      ) : (
                        <>
                          <Table.Summary.Cell index={0}>
                            Ticket
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={3}>
                            {item.itinerary_particular}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={3}>
                            <Typography
                              style={{ textAlign: 'left', marginLeft: '5px' }}
                            >
                              {' '}
                              {item.ticket_description}
                            </Typography>
                          </Table.Summary.Cell>
                          {!conditionalyRender && (
                            <Table.Summary.Cell index={3}>
                              {Fixed2(item.ticket_cost_price)}
                            </Table.Summary.Cell>
                          )}
                          <Table.Summary.Cell index={4}>
                            {item.vendor_name}
                          </Table.Summary.Cell>
                        </>
                      )}
                    </Table.Summary.Row>
                  );
                })}
              </>
            );
          }}
        />
      ) : (
        ''
      )}
      {
        <div className='mt-5'>
          <FormHeaderTitle title='BILLING INFO' />
          <Table
            size='small'
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={billing_information}
            columns={conditionalyRender ? billing_column : billingColumn}
            bordered
            pagination={false}
          />
        </div>
      }
      {!offSubCoastSubtotal && (
        <InvoiceTourCostTotal invoice_info={invoiceInformation} />
      )}
    </>
  );
};

export default ViewInvocieTourDetails;
