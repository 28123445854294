import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import {
  AGENTS_LEDGER,
  CLIENT_LEDGER,
  COMBINED_LEDGER,
  INVALIDATE_CLIENT_VENDOR_LEDGER,
  VENDOR_LEDGER,
} from '../../Api/constants';
import { IAgentsReportLedgerTypes } from '../types/agentLedgersTypes';
import {
  IClientLedgerDataType,
  ICombineClientLedger,
} from '../types/clientLedgerTypes';
import { IVendorLedgerDataType } from '../types/vendorLedgerTypes';

export const ledgerEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all client ledger by id
    getClientLedgerbyID: build.query<
      HTTPResponse<IClientLedgerDataType>,
      {
        client_id: number;
        from_date: string;
        to_date: string;
        current: number;
        pageSize: number;
      }
    >({
      query: ({ client_id, from_date, to_date, current, pageSize }) => ({
        url: `/report/client-ledger/${client_id}?from_date=${from_date}&to_date=${to_date}&page=${current}&size=${pageSize}`,
        method: 'GET',
      }),
      providesTags: [
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        CLIENT_LEDGER,
        { type: 'ClientLedger', id: CLIENT_LEDGER },
        { type: 'invoice-other' },
        { type: 'invoices' },
        { type: 'invoice-visa' },
      ],
    }),

    getVendorLedgerbyID: build.query<
      HTTPResponse<IVendorLedgerDataType>,
      { vendor_id: number; query: string }
    >({
      query: ({ vendor_id, query }) => ({
        url: `/report/vendors-ledger/${vendor_id}${query}`,
      }),
      providesTags: [
        { type: 'VendorLedger', id: VENDOR_LEDGER },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    getAllCombinedLedger: build.query<
      HTTPResponse<ICombineClientLedger[]>,
      {
        client_id: number;
        query: string;
      }
    >({
      query: ({ query, client_id }) => ({
        url: `/report/combineds-ledger/${client_id}${query}`,
        method: 'GET',
      }),
      providesTags: [
        { type: 'ClientLedger', id: COMBINED_LEDGER },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    getAgentsLedgerReportbyID: build.query<
      HTTPResponse<IAgentsReportLedgerTypes[]>,
      {
        agent_id: number;
        page: number;
        size: number;
        dateRange: string;
      }
    >({
      query: ({ agent_id, page, size, dateRange }) => ({
        url: `report/agents-report/${agent_id}?${dateRange}&page=${page}&size=${size}`,
      }),
      providesTags: [{ type: 'AgentsLedger', id: AGENTS_LEDGER }],
    }),

    getExcel: build.query<
      any,
      {
        client_id: string;
        query: string;
        excelName?: string;
        excelApiName: string;
      }
    >({
      query: ({ client_id, query, excelName, excelApiName }) => ({
        url: `/report/${excelApiName}/${client_id}${query}`,
        responseHandler: async (response) => {
          const filename = `${excelName}.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
    }),

    getExcelsPost: build.query<
      any,
      {
        client_id: string;
        query: string;
        excelName?: string;
        excelApiName: string;
        employee_id: string;
      }
    >({
      query: ({ employee_id, client_id, query, excelName, excelApiName }) => ({
        url: `/report/${excelApiName}${query}`,
        method: 'POST',
        body: {
          client_id: client_id,
          employee_id: employee_id,
        },
        responseHandler: async (response) => {
          const filename = `${excelName}.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useLazyGetClientLedgerbyIDQuery,
  useGetClientLedgerbyIDQuery,
  useLazyGetExcelQuery,
  useLazyGetExcelsPostQuery,
  useLazyGetVendorLedgerbyIDQuery,
  useLazyGetAllCombinedLedgerQuery,
  useLazyGetAgentsLedgerReportbyIDQuery,
} = ledgerEndpoints;
