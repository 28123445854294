import {
  DeleteFilled,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Space,
  Table,
  TimePicker,
  Typography,
} from 'antd';
import { TableProps } from 'antd/lib';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { IPaxDetails } from '../../../../common/types/commonInterfaces';
import { IFlightData } from '../../../IATA_AIR_TICKET/Components/ManualInvoiceAirTicketEdited/ManualEditInVoiceFlight';

const otherBillingInfo = [
  {
    billing_id: 1,
    pax_name: 'Masud',
    billing_description: 'Hello world',
    billing_quantity: 1,
    billing_remaining_quantity: 1,
    billing_unit_price: 6000,
    billing_subtotal: 6000,
    billing_cost_price: 5000,
    billing_profit: 1000,
    product_name: 'Dubai Visa',
    vendor_name: 'goverment',
  },
];

export interface IBillingEdit {
  billing_id: number;
  pax_name: string;
  billing_description: string;
  billing_quantity: number;
  billing_remaining_quantity: number;
  billing_unit_price: number;
  billing_subtotal: number;
  billing_cost_price: number;
  billing_profit: number;
  product_name: string;
  vendor_name: string;
}
type DataIndex = keyof IBillingEdit;

// COMMON
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: DataIndex;
  title: any;
  inputType: 'number' | 'text' | 'date' | 'time';
  record: IBillingEdit;
  index: number;
}

// MAIN COMPONENT
const InvoiceOtherBillingEdit = ({
  setAllEditableData,
}: {
  setAllEditableData?: React.Dispatch<
    React.SetStateAction<{
      flightDetails?: IFlightData[] | undefined;
      paxDetails?: IPaxDetails[] | undefined;
      billingDetails?: IBillingEdit[] | undefined;
      subtotalDetails?: any;
    }>
  >;
}) => {
  const [form] = Form.useForm();
  const [paxData, setPaxData] = useState(otherBillingInfo);
  const [editingKey, setEditingKey] = useState(0);
  const [isAdding, setIsAdding] = useState(false);

  const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode = <Input size='small' />;

    if (inputType === 'number') {
      inputNode = <InputNumber size='small' />;
    } else if (inputType === 'date') {
      inputNode = (
        <span>
          <DatePicker
            size='small'
            defaultValue={dayjs(record[dataIndex])}
            onChange={(date) => {
              form.setFieldsValue({
                [dataIndex]: date ? date.format('YYYY-MM-DD') : '',
              });
            }}
            format={'DD-MM-YYYY'}
          />
        </span>
      );
    } else if (inputType === 'time') {
      inputNode = <TimePicker size='small' />;
    } else {
      inputNode = <Input size='small' />;
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  const isEditing = (record: IBillingEdit) => record.billing_id === editingKey;
  const Add = (record: IBillingEdit & { billing_id: React.Key }) => {
    setIsAdding(true);
    const newData: any = {
      billing_id: record.billing_id + 1,
      pax_name: '',
      billing_description: '',
      billing_quantity: '',
      billing_remaining_quantity: '',
      billing_unit_price: '',
      billing_subtotal: '',
      billing_cost_price: '',
      billing_profit: '',
      product_name: '',
      vendor_name: '',
    };
    form.setFieldsValue({
      billing_id: record.billing_id + 1,
      pax_name: '',
      billing_description: '',
      billing_quantity: '',
      billing_remaining_quantity: '',
      billing_unit_price: '',
      billing_subtotal: '',
      billing_cost_price: '',
      billing_profit: '',
      product_name: '',
      vendor_name: '',
    });
    setPaxData([...paxData, newData]);
    setEditingKey(record.billing_id + 1);
  };
  const handleDelete = (key: any) => {
    const newData = paxData.filter((item) => item.billing_id !== key);
    setPaxData(newData);
    setEditingKey(0);
    setIsAdding(false);
  };
  const edit = (record: IBillingEdit & { billing_id: React.Key }) => {
    form.setFieldsValue({ name: '', age: '', address: '', ...record });
    setEditingKey(record.billing_id);
  };

  const cancel = () => {
    setEditingKey(0);
  };

  const paxSave = async (id: React.Key) => {
    try {
      const row = (await form.validateFields()) as IBillingEdit;
      const newData = [...paxData];
      const index = newData.findIndex((item) => id === item.billing_id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setPaxData(newData);
        setEditingKey(0);
        setIsAdding(false);
      } else {
        newData.push(row);
        setPaxData(newData);
        setEditingKey(0);
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const paxColumn = [
    {
      title: 'Sl.',
      dataIndex: 'billing_id',
      editable: false,
    },
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      editable: true,
    },
    {
      title: 'Pax Name',
      dataIndex: 'pax_name',
      editable: true,
    },

    {
      title: 'Quantity',
      dataIndex: 'billing_quantity',
      editable: true,
    },
    {
      title: 'Unit Price',
      dataIndex: 'billing_unit_price',
      editable: true,
    },

    {
      title: 'Sub Total',
      dataIndex: 'billing_subtotal',
      editable: true,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_: any, record: IBillingEdit, index: number) => {
        const editable = isEditing(record);
        return editable && !isAdding ? (
          <Space>
            <Typography.Link onClick={() => paxSave(record.billing_id)}>
              ✅
            </Typography.Link>
            <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
              <a>❌</a>
            </Popconfirm>
          </Space>
        ) : isAdding ? (
          <>
            <Typography.Link onClick={() => paxSave(record.billing_id)}>
              ✅
            </Typography.Link>
            <Typography.Link
              disabled={editingKey > record.billing_id}
              onClick={() => handleDelete(record.billing_id)}
            >
              <DeleteFilled style={{ color: 'red' }} />
            </Typography.Link>
          </>
        ) : (
          <Space>
            <EditOutlined
              style={{
                color: 'blue',
              }}
              disabled={editingKey !== 0}
              onClick={() => edit(record)}
            />
            {paxData.length === index + 1 ? (
              <a onClick={() => Add(record)}>
                <PlusCircleOutlined />
              </a>
            ) : (
              <PlusCircleOutlined disabled />
            )}

            <DeleteFilled
              onClick={() => handleDelete(record.billing_id)}
              style={{ color: 'red' }}
            />
          </Space>
        );
      },
    },
  ];

  const paxMergedColumns: TableProps['columns'] = paxColumn.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IBillingEdit) => ({
        record,
        inputType:
          col.dataIndex === 'airticket_journey_date' ||
          col.dataIndex === 'airticket_return_date'
            ? 'date'
            : col.dataIndex === 'airticket_client_price' ||
              col.dataIndex === 'airticket_gross_fare'
            ? 'number'
            : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  useEffect(() => {
    setAllEditableData &&
      setAllEditableData((prev: any) => ({
        ...prev,
        billingDetails: [...paxData],
      }));
  }, [paxData]);

  // Initial load of data to allEditableData
  useEffect(() => {
    setAllEditableData &&
      setAllEditableData((prev: any) => ({
        ...prev,
        billingDetails: [...otherBillingInfo],
      }));
  }, []);
  return (
    <>
      <Typography.Title level={5} style={{ marginBottom: '5px' }}>
        OTHER BILLING INFO
      </Typography.Title>

      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={paxData}
          columns={paxMergedColumns}
          pagination={false}
          size='small'
          style={{ marginBottom: '2rem' }}
          className='invoiceBillingTable'
          rowClassName={'invoiceBillingTd'}
        />
      </Form>
    </>
  );
};

export default InvoiceOtherBillingEdit;
