import React, { useEffect } from 'react';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import {
  IBSPSummary,
  ticket_refund,
} from '../../interfaces/dashboard.interfaces';
import { Card, Table, Typography } from 'antd';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Fixed2, separateClient } from '../../../../common/utils/common.utils';

type Props = {
  data: HTTPResponse<IBSPSummary> | undefined;
  isLoading: boolean;
  isFetching: boolean;
};

const BSP_Ticket_Refund = ({ data, isFetching, isLoading }: Props) => {
  // TICKET REFUND
  let total_charge_amount = 0;
  let total_return_amount = 0;
  let total_amount = 0;

  useEffect(() => {
    // TICKET REFUND
    total_charge_amount = 0;
    total_return_amount = 0;
    total_amount = 0;


  }, []);
  const ticket_refund: ColumnsType<ticket_refund> = [
    {
      title: 'SL',
      dataIndex: 'airticket_tax',
      key: 'airticket_tax',
      render: (_, data, index) => index + 1,
    },
    {
      title: 'Refund Date',
      dataIndex: 'airticket_tax',
      key: 'airticket_tax',
      render: (_, data) =>
        data?.vrefund_date && dayjs(data.vrefund_date).format('DD-MMM-YYYY'),
    },
    {
      title: 'Voucher No.',
      dataIndex: 'atrefund_vouchar_number',
      key: 'atrefund_vouchar_number',
      render: (_, data, index) => {
        if (data.refund_type === 'PARTIAL') {
          return (
            <Link to={`/refund/partial/view/${data?.vrefund_refund_id}`}>
              {data.atrefund_vouchar_number}
            </Link>
          );
        } else if (data.refund_type === 'AIRTICKET') {
          return (
            <Link to={`/refund/airticket/details/${data?.vrefund_refund_id}`}>
              {data.atrefund_vouchar_number}
            </Link>
          );
        }
      },
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (rec, data) => {
        const { client_id, combined_id } = separateClient(data.comb_client);

        return (
          <Link
            to={
              client_id
                ? `/reports/client_ledger?id=${client_id}`
                : `/reports/combined_ledger?id=${combined_id}`
            }
          >
            {rec}
          </Link>
        );
      },
    },
    {
      title: 'Ticket No.',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
    },
    {
      title: 'PNR',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
    },
    {
      title: 'Payment Method',
      dataIndex: 'vrefund_payment_type',
      key: 'vrefund_payment_type',
    },
    {
      title: 'Total Amount',
      dataIndex: 'vrefund_total_amount',
      key: 'vrefund_total_amount',
      render: (vrefund_total_amount) =>
        Fixed2(vrefund_total_amount).toLocaleString(),
      align: 'right',
    },
    {
      title: 'Charge Amount',
      dataIndex: 'vrefund_charge_amount',
      key: 'vrefund_charge_amount',
      render: (vrefund_charge_amount) =>
        Fixed2(vrefund_charge_amount).toLocaleString(),
      align: 'right',
    },
    {
      title: 'Return Amount',
      dataIndex: 'vrefund_return_amount',
      key: 'vrefund_return_amount',
      render: (vrefund_return_amount) => (
        <span style={{ color: 'red' }}>
          {Fixed2(vrefund_return_amount).toLocaleString()}
        </span>
      ),
      align: 'right',
    },
  ];
  return (
    <Card size='small' title='TICKET REFUND'>
      <Table
        size='small'
        bordered
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        dataSource={data?.data?.ticket_refund}
        columns={ticket_refund}
        pagination={false}
        summary={(data) => {
          data?.forEach((item) => {
            total_charge_amount += Fixed2(item.vrefund_charge_amount);
            total_return_amount += Fixed2(item.vrefund_return_amount);
            total_amount += Fixed2(item.vrefund_total_amount);
          });

          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={7}>
                <div className='report_total_footer'>
                  <Typography.Text strong>Total: </Typography.Text>
                </div>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={0}>
                <div className='report_total_footer'>
                  <Typography.Text strong>
                    {total_amount.toLocaleString()}
                  </Typography.Text>
                </div>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={7}>
                <div className='report_total_footer'>
                  <Typography.Text strong>
                    {total_charge_amount.toLocaleString()}
                  </Typography.Text>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <div className='report_total_footer'>
                  <Typography.Text strong style={{ color: '#EE4266' }}>
                    {total_return_amount.toLocaleString()}
                  </Typography.Text>
                </div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </Card>
  );
};

export default BSP_Ticket_Refund;
