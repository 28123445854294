import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Form, Row } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IPermission, crud } from '../../../../common/types/commonTypes';
import {
  DateInput,
  FormButton,
} from '../../../../components/common/FormItem/FormItems';
import { SelectClients } from '../../../../components/common/FormItem/SelectCustomFeilds';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { useLazyGetAllTourRefundInfoQuery } from '../../Api/Endpoints/TourPackageRefundEndpoints';
import { SelectAccomodationRefund } from '../../Components/TourPackage/SelectIteneries/SelectAccomodationRefund';

import { SelectFoodRefund } from '../../Components/TourPackage/SelectIteneries/SelectFoodRefund';
import { SelectGuideRefund } from '../../Components/TourPackage/SelectIteneries/SelectGuideRefund';
import { SelectOtherTransRefund } from '../../Components/TourPackage/SelectIteneries/SelectOtherTransRefund';
import { SelectTicketRefund } from '../../Components/TourPackage/SelectIteneries/SelectTicketRefund';
import { SelectTourInvoiceByClientID } from '../../Components/TourPackage/SelectIteneries/SelectTourInvoiceByClientID';
import { SelectTransportRefund } from '../../Components/TourPackage/SelectIteneries/SelectTransportRefund';
import TourRefundTable from '../../Components/TourPackage/TourRefundTable';
import {
  ITourAccmdnInfo,
  ITourBillingInfo,
  ITourFoodInfo,
  ITourGuideInfo,
  ITourOtherTransInfo,
  ITourPackageRefundForm1,
  ITourRefundFilteredItenery,
  ITourRefundInvoiceInfo,
  ITourTicketInfo,
  ITourTransportInfo,
} from '../../RefundTypes/RefundTypes';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import dayjs from 'dayjs';

type Props = { permission?: IPermission };

const TourPackageRefund = ({ permission }: Props) => {
  const [clientId, setClientId] = useState<string>();
  const [date, setDate] = useState<string>();
  const [invoice_id, setInvoice_id] = useState<number>(0);
  const [tourRefundIsVisible_Invoice, setTourRefundIsVisible_Invoice] =
    useState<boolean>(true);
  const [tourRefundIsVisible_Select, setTourRefundIsVisible_Select] =
    useState<boolean>(false);
  const [tourRefundIsVisible_RefundTable, setTourRefundIsVisible_RefundTable] =
    useState<boolean>(false);

  const [selectedIteneryData, setSelectedIteneryData] =
    useState<ITourRefundInvoiceInfo>();
  const [form] = Form.useForm();
  const [refundSelectForm] = Form.useForm();

  //invoice refund invoice data
  const [
    fetchTourRefundInfo,
    { data: refundInfo, isLoading, isUninitialized },
  ] = useLazyGetAllTourRefundInfoQuery();

  const onFinishForm1 = async (values: ITourPackageRefundForm1) => {
    const body: ITourPackageRefundForm1 = {
      ...values,
    };

    setDate(values.date);

    setInvoice_id(values.tour_invoice_id);
    isUninitialized && fetchTourRefundInfo(body.tour_invoice_id);
    setTourRefundIsVisible_Invoice(false);
    setTourRefundIsVisible_Select(true);
  };

  useEffect(() => {
    form.setFieldValue('date', dayjs());
  }, []);

  //selecting iteneries
  const onFinishForm2 = async (values: ITourRefundFilteredItenery) => {
    const tourAccms = refundInfo?.data?.tourAccms.filter(
      (item) =>
        values.accomodation_refund &&
        values.accomodation_refund.includes(item.itinerary_id)
    ) as ITourAccmdnInfo[];
    const tourFoods = refundInfo?.data?.tourFoods.filter(
      (item) =>
        values.food_refund && values.food_refund.includes(item.itinerary_id)
    ) as ITourFoodInfo[];
    const tourGuide = refundInfo?.data?.tourGuide.filter(
      (item) =>
        values.guide_refund && values.guide_refund.includes(item.itinerary_id)
    ) as ITourGuideInfo[];
    const tourOtherTrans = refundInfo?.data?.tourOtherTrans.filter(
      (item) =>
        values.other_trans_refund &&
        values.other_trans_refund.includes(item.itinerary_id)
    ) as ITourOtherTransInfo[];
    const tourTicket = refundInfo?.data?.tourTicket.filter(
      (item) =>
        values.ticket_refund && values.ticket_refund.includes(item.itinerary_id)
    ) as ITourTicketInfo[];
    const tourTransports = refundInfo?.data?.tourTransports.filter(
      (item) =>
        values.transport_refund &&
        values.transport_refund.includes(item.itinerary_id)
    ) as ITourTransportInfo[];
    const selectedData = {
      tourAccms,
      tourFoods,
      tourGuide,
      tourOtherTrans,
      tourTicket,
      tourTransports,
      tourBilling: refundInfo?.data?.tourBilling as ITourBillingInfo,
      invoice_combclient_id: refundInfo?.data?.invoice_combclient_id as string,
      client_name: refundInfo?.data?.client_name as string,
      invoice_sub_total: refundInfo?.data?.invoice_sub_total as string,
      client_last_balance: refundInfo?.data?.client_last_balance as number,
      date: date as string,
    };
    setSelectedIteneryData(selectedData);
    setTourRefundIsVisible_Select(false);
    setTourRefundIsVisible_RefundTable(true);
  };

  return (
    <div>
      <BreadCrumb arrOfOption={['Refund', 'Create Tour Package Refund']} />
      <Link to='/refund/history/tour-package'>
        <Button type='primary' style={{ marginBottom: '1rem' }}>
          <ArrowLeftOutlined />
          Return to Tour Package Refund List
        </Button>
      </Link>
      {tourRefundIsVisible_Invoice && (
        <Form layout='vertical' form={form} onFinish={onFinishForm1}>
          <Card>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
              <SelectClients
                name='client_id'
                label='Select Client'
                required
                size={8}
                onChange={(e) => setClientId(e as string)}
                offDropDown
              />
              <SelectTourInvoiceByClientID
                name='tour_invoice_id'
                label='Select Tour Package Refund Invoice'
                clientID={clientId as string}
                size={8}
                required
              />
              <DateInput label='Refund Date' name='date' size={8} />
            </Row>
            {permission?.[crud.read] && (
              <FormButton label='Submit' textAlign='left' />
            )}
          </Card>
        </Form>
      )}
      {tourRefundIsVisible_Select && (
        <Form
          layout='vertical'
          form={refundSelectForm}
          onFinish={onFinishForm2}
        >
          <FormHeaderTitle title='Tour Refund Itenery Information' />

          <Card>
            {isLoading ? <LoadingIndicator /> : ''}
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
              {refundInfo?.data?.tourAccms?.length ? (
                <SelectAccomodationRefund
                  name='accomodation_refund'
                  label='Select Accomodation'
                  size={8}
                  accomodationData={refundInfo?.data?.tourAccms}
                />
              ) : (
                ''
              )}
              {refundInfo?.data?.tourFoods?.length ? (
                <SelectFoodRefund
                  name='food_refund'
                  label='Select Food'
                  size={8}
                  foodData={refundInfo?.data?.tourFoods}
                />
              ) : (
                ''
              )}
              {refundInfo?.data?.tourGuide?.length ? (
                <SelectGuideRefund
                  name='guide_refund'
                  label='Select Guide'
                  size={8}
                  guideData={refundInfo?.data?.tourGuide}
                />
              ) : (
                ''
              )}
              {refundInfo?.data?.tourOtherTrans?.length ? (
                <SelectOtherTransRefund
                  name='other_trans_refund'
                  label='Select Other Transport'
                  size={8}
                  otherTransData={refundInfo?.data?.tourOtherTrans}
                />
              ) : (
                ''
              )}
              {refundInfo?.data?.tourTicket?.length ? (
                <SelectTicketRefund
                  name='ticket_refund'
                  label='Select Ticket'
                  size={8}
                  ticketData={refundInfo?.data?.tourTicket}
                />
              ) : (
                ''
              )}
              {refundInfo?.data?.tourTransports?.length ? (
                <SelectTransportRefund
                  name='transport_refund'
                  label='Select Transport'
                  size={8}
                  transportData={refundInfo?.data?.tourTransports}
                />
              ) : (
                ''
              )}
            </Row>
            {permission?.[crud.read] && (
              <FormButton label='Submit' textAlign='left' />
            )}
          </Card>
        </Form>
      )}
      {tourRefundIsVisible_RefundTable && (
        <TourRefundTable
          data={selectedIteneryData as ITourRefundInvoiceInfo}
          isLoading={isLoading}
          invoice_id={invoice_id}
          refund_date={date as string}
          invoice_category_id={refundInfo?.data?.invoice_category_id as number}
        />
      )}
    </div>
  );
};

export default TourPackageRefund;
