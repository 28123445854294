import {
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  message,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import useGetSearchColumnProps from '../../../../../common/hooks/SearchData';
import { IPermission } from '../../../../../common/types/commonTypes';
import BreadCrumb from '../../../../../components/common/breadCrumb/BreadCrumb';
import FormHeaderTitle from '../../../../Invoice(Visa)/Components/FormHeaderTitle';
import ViewVendors from '../../Component/ViewVendors';
import CreateCommonTourItinearyModal from '../component/CreateCommonTourItineraryModal';
import {
  useDeleteTourTicketMutation,
  useGetAllTicketWithPaginationQuery,
} from '../endpoints/tourTicketEndpoints';
import EditTourTicket from '../modal/EditTourTicket';
import {
  ITourTicketDataType,
  IitineraryVendor,
  TicketInfo,
} from '../types/TicketTypes';
import { loadingIndicator } from '../../../../../components/common/spinner/LoadingIndicator';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../auth/states/userSlice';

export type queryType =
  | 'ticket'
  | 'guide'
  | 'other-transport'
  | 'transport'
  | 'food';

type Props = {
  queryType: queryType;
  permission?: IPermission;
};
const TourGuide = ({ queryType, permission }: Props) => {
  const [editInfo, setEditInfo] = useState<TicketInfo | null>(null);
  const [form] = Form.useForm();
  const searchColumnProps = useGetSearchColumnProps<ITourTicketDataType>();
  const user = useSelector(selectUser);
  function modalTitle() {
    if (queryType == 'ticket') {
      return 'Add Ticket';
    } else if (queryType == 'guide') {
      return 'Add Guide';
    } else if (queryType == 'other-transport') {
      return 'Add Other Transport';
    } else if (queryType == 'transport') {
      return 'Add Transport';
    } else if (queryType == 'food') {
      return 'Add Food';
    }
  }

  function editTitle() {
    if (queryType == 'ticket') {
      return 'Edit Ticket';
    } else if (queryType == 'guide') {
      return 'Edit Guide';
    } else if (queryType == 'other-transport') {
      return 'Edit Other Transport';
    } else if (queryType == 'transport') {
      return 'Edit Transport';
    } else if (queryType == 'food') {
      return 'Edit Food';
    }
  }

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  //get data from api
  const {
    isLoading,
    data: tourTicket,
    refetch,
  } = useGetAllTicketWithPaginationQuery(
    { ...pagination, query: queryType },
    {
      selectFromResult: (cache) => {
        const data = cache.data?.data;
        const count = cache.data?.count;
        const dataToReturn: ITourTicketDataType[] = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            dataToReturn.push({
              ...element,
              key: i + 1,
            });
          }
        }
        return { ...cache, data: { dataToReturn, count } };
      },
    }
  );

  const [
    deleteGroupTour,
    { isError, isSuccess, isLoading: deleteTourLoading },
  ] = useDeleteTourTicketMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success(`${queryType} Deleted Successfully`);
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const handleDelete = async (values: ITourTicketDataType) => {
    await deleteGroupTour({
      body: { ...values, deleted_by: user?.user_id },
      queryType: queryType,
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const [vendor, setVendors] = useState<IitineraryVendor[]>([]);
  // ========== view vendors
  const handleViewVendors = (data: IitineraryVendor[]) => {
    showModal();
    setVendors(data);
  };

  // ============= ticket modal ===========
  const [ticketModalOpen, setTicketModalOpen] = useState(false);
  const modalOpen = (e: TicketInfo) => {
    setEditInfo(e);
    setTicketModalOpen(true);
  };

  const columns: ColumnsType<ITourTicketDataType> = [
    {
      title: 'SL',
      dataIndex: 'key',
      key: 'serial',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'place',
      dataIndex: 'place_name',
      key: 'place_name',
      ...searchColumnProps('place_name'),
    },
    {
      title: 'particular',
      dataIndex: 'itinerary_particular',
      key: 'itinerary_particular',
      ...searchColumnProps('itinerary_particular'),
    },
    {
      title: 'Vendor',
      key: 'itinerary_particular',
      render: (_, record, index) => {
        return (
          <Button
            size='small'
            type='primary'
            onClick={() => handleViewVendors(record?.itinerary_vendor)}
          >
            View Vendors
          </Button>
        );
      },
    },

    {
      title: 'Create date',
      key: 'address',
      render: (_, data) => {
        return <> {dayjs(data.itinerary_create_date).format('YYYY-MM-DD')} </>;
      },
    },
    {
      title: 'Status',
      key: 'itinerary_status',
      dataIndex: 'itinerary_status',
      render: (_, { itinerary_status }) => (
        <Tag
          color={itinerary_status === 0 ? 'red' : 'green'}
          key={itinerary_status}
        >
          {itinerary_status === 0 ? 'Inactive' : 'Active'}
        </Tag>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      render: (_: any, record: any) => (
        <Space size='small'>
          {permission?.['update:any'] && record.agency_id && (
            <Button
              size='small'
              type='primary'
              onClick={() => modalOpen(record)}
            >
              Edit
            </Button>
          )}

          {permission?.['delete:any'] && record.agency_id && (
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleDelete(record)}
            >
              <Button
                size='small'
                type='primary'
                danger
                loading={deleteTourLoading}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
  return (
    <>
      <ViewVendors
        setIsModalOpen={setIsModalOpen}
        showModal={showModal}
        isModalOpen={isModalOpen}
        vendors={vendor}
      />
      <BreadCrumb
        arrOfOption={['Configuration', queryType]}
        refetch={refetch}
        reloaderSize='small'
      />
      {permission?.['create:any'] && (
        <CreateCommonTourItinearyModal
          queryType={queryType}
          modalTitle={modalTitle()}
        />
      )}
      {editInfo && permission?.['update:any'] && (
        <EditTourTicket
          editTitle={editTitle()}
          queryType={queryType}
          info={editInfo}
          setIsModalOpen={setTicketModalOpen}
          isModalOpen={ticketModalOpen}
        />
      )}

      <FormHeaderTitle title={queryType.toUpperCase()} />
      <Table
        size='small'
        bordered
        dataSource={tourTicket.dataToReturn}
        columns={columns}
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
        pagination={
          tourTicket?.count !== undefined && tourTicket?.count < 20
            ? false
            : {
              ...pagination,
              total: tourTicket?.count,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }
        }
      />
    </>
  );
};

export default TourGuide;
