import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Card, ConfigProvider, Space, Typography, theme } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { InvoiceHeader } from '../../../../common/Invoice/InvoiceHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { portraitPageStyle } from '../../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader';
import { useGetViewIncentiveIncomeQuery } from './endpoints/incentiveIncomeEndpoints';
import Style from './style/incentive.module.css';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { NumToWord_raw } from '../../../../components/number_to_words/NumToWord';
import { useAppSelector } from '../../../../app/hooks';
import { InvoiceFooter } from '../../../../common/Invoice/InvoiceFooter';

const a4sizeStyle: React.CSSProperties = {
  minHeight: '5.75in',
  width: '8.27in',
  background: '#fff',
  margin: '0px auto',
};

const ViewIncentiveIncome = () => {
  const { id } = useParams();

  const { data, isLoading } = useGetViewIncentiveIncomeQuery(Number(id));
  const { Text, Title } = Typography;
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `incentive_income_${dayjs()}`,
    pageStyle: portraitPageStyle,
  });
  const currencyName = useAppSelector(
    (state) => state.user?.organization_info?.org_currency
  );

  const result = data?.data?.data;

  return (
    <>
      <BreadCrumb arrOfOption={['Accounts', 'Vendor Incentive Income']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/accounts/incentive'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Incentive List
          </Button>
        </Link>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
      </Space>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <div
          style={{
            background: '#f6f6f6',
            minHeight: '100vh',
            padding: '20px 0px',
            position: 'relative',
          }}
        >
          <Card
            className={Style.card}
            style={{
              ...a4sizeStyle,
            }}
            ref={componentRef}
          >
            <header>
              <InvoiceHeader />

              <Title
                level={5}
                style={{
                  border: '2px solid #041E42',
                  padding: '7px',
                  borderRadius: '4px',
                  textAlign: 'center',
                  width: '180px',
                  margin: '10px auto',
                  color: '#041E42',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                Incentive Income
              </Title>
            </header>

            <div style={{ marginTop: '45px' }} className={Style.info1}>
              <div className={Style.disFlex}>
                <p style={{ marginRight: 5 }}>Receipt No:</p>
                <span className={Style.spanText}>
                  {result?.incentive_vouchar_no}
                </span>
              </div>

              <div className={Style.disFlex}>
                <p style={{ marginRight: 5 }}>Date: </p>
                <span className={Style.spanText2}>
                  {dayjs(result?.incentive_date).format('DD-MMM-YYYY')}
                </span>
              </div>
            </div>

            <div style={{ gap: 10 }} className={Style.section1}>
              <div className={Style.disFlex} style={{ flexGrow: 2 }}>
                <p>Received with thanks from:</p>
                <span className={Style.spanText} style={{ flexGrow: 1 }}>
                  <span style={{ fontWeight: 'bold' }} className={Style.ms_1}>
                    {result?.vendor_name}
                  </span>
                </span>
              </div>
            </div>

            <div className={Style.f_mt2}>
              <p>Amount of TK (In Word):</p>
              <span className={Style.spanText} style={{ flexGrow: 1 }}>
                <span className={Style.ms_1}>
                  {Fixed2(result?.incentive_amount) && (
                    <NumToWord_raw
                      number={Number(result?.incentive_amount || 0)}
                    />
                  )}
                </span>
              </span>
            </div>

            <div style={{ gap: 10 }} className={Style.section1}>
              <div className={Style.disFlex} style={{ flexGrow: 1 }}>
                <p>Payment For:</p>
                <span className={Style.spanText} style={{ flexGrow: 1 }}>
                  <span className={Style.ms_1}>{'Incentive Income'}</span>
                </span>
              </div>

              {/* <div className={Style.disFlex} style={{ flexGrow: 1 }}>
                <p>Balance:</p>
                <span className={Style.spanText} style={{ flexGrow: 1 }}>
                  <span className={Style.ms_1}>{250}</span>
                </span>
              </div> */}
            </div>

            {/* {acctype_name == 'Cash' && ( */}
            {/* <div style={{ gap: 10 }} className={Style.section1}>
              <div className={Style.disFlex} style={{ flexGrow: 1 }}>
                <p>Paid Via:</p>
                <span className={Style.spanText} style={{ flexGrow: 1 }}>
                  <span className={Style.ms_1}>{'acctype_name'}</span>
                </span>
              </div>

              <div className={Style.disFlex} style={{ flexGrow: 1 }}>
                <p>Account Name:</p>
                <span className={Style.spanText} style={{ flexGrow: 1 }}>
                  <span className={Style.ms_1}>{'account_name'}</span>
                </span>
              </div>
            </div> */}
            {/* )} */}
            {/* 
            {acctype_name == 'Bank' && (
              <div style={{ gap: 10 }} className={Style.section1}>
                <div className={Style.disFlex} style={{ flexGrow: 1 }}>
                  <p>Paid Via:</p>
                  <span className={Style.spanText} style={{ flexGrow: 1 }}>
                    <span className={Style.ms_1}>{acctype_name}</span>
                  </span>
                </div>

                <div className={Style.disFlex} style={{ flexGrow: 1 }}>
                  <p>Account Name:</p>
                  <span className={Style.spanText} style={{ flexGrow: 1 }}>
                    <span className={Style.ms_1}>{account_name}</span>
                  </span>
                </div>
              </div>
            )}

            {acctype_name == 'Cheque' && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 10,
                }}
              >
                <div style={{ flexGrow: 1 }} className={Style.disFlex}>
                  <p>Paid Via:</p>
                  <span style={{ flexGrow: 1 }} className={Style.spanText}>
                    <span className={Style.ms_1}>{acctype_name}</span>
                  </span>
                </div>

                <div style={{ flexGrow: 1 }} className={Style.disFlex}>
                  <p>Chq No:</p>
                  <span style={{ flexGrow: 1 }} className={Style.spanText}>
                    <span className={Style.ms_1}>{cheque_number}</span>
                  </span>
                </div>

                <div style={{ flexGrow: 1 }} className={Style.disFlex}>
                  <p>Bank Name:</p>
                  <span style={{ flexGrow: 1 }} className={Style.spanText}>
                    <span className={Style.ms_1}>{bank_name}</span>
                  </span>
                </div>
              </div>
            )}

            {acctype_name == 'Mobile banking' && (
              <div style={{ gap: 10 }} className={Style.section1}>
                <div className={Style.disFlex} style={{ flexGrow: 1 }}>
                  <p>Paid Via:</p>
                  <span className={Style.spanText} style={{ flexGrow: 1 }}>
                    <span className={Style.ms_1}>{acctype_name}</span>
                  </span>
                </div>

                <div className={Style.disFlex} style={{ flexGrow: 1 }}>
                  <p>Account Name:</p>
                  <span className={Style.spanText} style={{ flexGrow: 1 }}>
                    <span className={Style.ms_1}>{account_name}</span>
                  </span>
                </div>
              </div>
            )} */}

            <div className={Style.f_mt2} style={{ flexGrow: 1 }}>
              <p>For the purpose of:</p>
              <span className={Style.spanText} style={{ flexGrow: 1 }}>
                <span className={Style.ms_1}>{result?.incentive_note}</span>
              </span>
            </div>

            <div
              style={{
                display: 'flex',
                gap: 10,
              }}
            >
              <div>
                <p className={Style.footerAmount}>
                  Amount: {Fixed2(result?.incentive_amount).toLocaleString()}{' '}
                  {currencyName?.toUpperCase()}
                </p>
              </div>
            </div>

            <div style={{ position: 'relative', padding: 10, top: 60 }}>
              <InvoiceFooter
                authorized_by={data?.data?.authorized_by}
                prepared_by={data?.data?.prepared_by}
              />
            </div>
          </Card>
        </div>
      </ConfigProvider>
    </>
  );
};

export default ViewIncentiveIncome;
