import { ColumnsType } from 'antd/lib/table';
import {
  IDailyAccountLastBalanceType,
  IDailyAccountOpeningBalanceType,
  IDailyClientRefundReport,
  IDailyCollectionReport,
  IDailyExpenseReport,
  IDailySalesReport,
  IDailyVendorPaymentReport,
  IDailyVendorRefundType,
} from '../types/dailySummaryTypes';
import dayjs from 'dayjs';
import { Fixed2 } from '../../../../common/utils/common.utils';

export const dailySummaryColumn = () => {
  return [];
};
export const openingBalanceColumn =
  (): ColumnsType<IDailyAccountOpeningBalanceType> => {
    return [
      {
        title: 'SL.',
        dataIndex: 'key',
        key: 'key',
        align: 'center',
      },
      {
        title: 'Name',
        dataIndex: 'account_name',
        key: 'account_name',
        align: 'center',
      },
      {
        title: 'Amount',
        dataIndex: 'actransaction_amount',
        key: 'actransaction_amount',
        align: 'center',
        render: (_, record) => Number(record.actransaction_amount),
      },
    ];
  };
export const closingBalanceColumn =
  (): ColumnsType<IDailyAccountLastBalanceType> => {
    return [
      {
        title: 'SL.',
        dataIndex: 'key',
        key: 'key',
        align: 'center',
      },
      {
        title: 'Name',
        dataIndex: 'account_name',
        key: 'account_name',
        align: 'center',
      },
      {
        title: 'Amount',
        dataIndex: 'actransaction_last_balance',
        key: 'actransaction_last_balance',
        align: 'center',
        render: (_, record) => Number(record.actransaction_last_balance),
      },
    ];
  };

export const dailySalesReportColumn = (): ColumnsType<IDailySalesReport> => {
  return [
    {
      title: 'Sales Date',
      dataIndex: 'invoice_sales_date',
      key: 'invoice_sales_date',
      render: (invoice_sales_date) =>
        dayjs(invoice_sales_date).format('DD-MM-YYYY'),
    },

    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
    },
    {
      title: 'Net Total',
      dataIndex: 'invoice_net_total',
      key: 'invoice_net_total',
      render: (invoice_net_total) => Fixed2(invoice_net_total),
    },
  ];
};

export const dailyExpenseReportColumn =
  (): ColumnsType<IDailyExpenseReport> => {
    return [
      {
        title: 'Voucher No',
        dataIndex: 'expense_vouchar_no',
        key: 'expense_vouchar_no',
      },
      {
        title: 'Head Name',
        dataIndex: 'head_name',
        key: 'head_name',
      },
      {
        title: 'Amount',
        dataIndex: 'expdetails_amount',
        key: 'expdetails_amount',
        render: (expdetails_amount) => Fixed2(expdetails_amount),
      },
    ];
  };
export const dailyVendorPaymentReportColumn =
  (): ColumnsType<IDailyVendorPaymentReport> => {
    return [
      {
        title: 'Voucher No',
        dataIndex: 'vouchar_no',
        key: 'vouchar_no',
      },
      {
        title: 'Vendor Name',
        dataIndex: 'vendor_name',
        key: 'vendor_name',
      },
      {
        title: 'Payment Amount',
        dataIndex: 'payment_amount',
        key: 'payment_amount',
      },
    ];
  };

export const dailyCollectionReportColumn =
  (): ColumnsType<IDailyCollectionReport> => {
    return [
      {
        title: 'Account ID',
        dataIndex: 'account_id',
        key: 'account_id',
      },
      {
        title: 'Account Name',
        dataIndex: 'account_name',
        key: 'account_name',
      },
      {
        title: 'Collection',
        dataIndex: 'account_collection',
        key: 'account_collection',
      },
    ];
  };

export const dailyClientRefundReportColumn =
  (): ColumnsType<IDailyClientRefundReport> => {
    return [
      {
        title: 'Invoice No',
        dataIndex: 'invoice_no',
        key: 'invoice_no',
      },
      {
        title: 'Client Name',
        dataIndex: 'client_name',
        key: 'client_name',
      },
      {
        title: 'Total Amount',
        dataIndex: 'crefund_total_amount',
        key: 'crefund_total_amount',
      },
    ];
  };

export const dailyVendorRefundReportColumn =
  (): ColumnsType<IDailyVendorRefundType> => {
    return [
      {
        title: 'Voucher No',
        dataIndex: 'vouchar_no',
        key: 'vouchar_no',
      },
      {
        title: 'Vendor Name',
        dataIndex: 'vendor_name',
        key: 'vendor_name',
      },
      {
        title: 'Payment Amount',
        dataIndex: 'payment_amount',
        key: 'payment_amount',
      },
    ];
  };
