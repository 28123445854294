import { Card, Col, Form, FormInstance, InputNumber, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { useState, useRef, useEffect } from 'react';
import {
  DateInput,
  FormInputItem,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import SelectPaymentMethod from '../../../../components/common/paymentMethod/SelectPaymentMethod';
import { IaccountsByMethod } from '../../../Accounts/AccountsTypes/AccountsTypes';
import {
  useLazyGetAllAccountsByTypeQuery,
  useLazyGetSingleAccountQuery,
} from '../../../Accounts/Api/Endpoints/accountsEndpoints';
import { refund_type } from '../../RefundTypes/RefundTypes';

type Props = {};

const TourRefundClientForm = ({
  form,
  clientLastBalance,
  clientRefundTotal,
  clientRefundChargeTotal,
}: {
  form: FormInstance<any>;
  clientLastBalance?: number;
  clientRefundTotal?: number;
  clientRefundChargeTotal?: number;
}) => {
  const [clientRefundMethod, setClientRefundMethod] =
    useState<string>('ADJUST');
  const [paymentType, setPaymentType] = useState<string>();
  const isChanged = useRef(false);
  const [paymentMethod, setPaymentMethod] = useState<string>();
  const [accountID, setAccountID] = useState<number>();
  const [balance, setBalance] = useState<number>();
  const [fetchAccountsByPayment, { data: accountsByPayment }] =
    useLazyGetAllAccountsByTypeQuery();

  useEffect(() => {
    if (paymentMethod) {
      fetchAccountsByPayment(Number(paymentMethod));
    }
    if (isChanged.current && form) {
      form.setFields([
        {
          name: ['client_refund_info', 'account_id'],
          value: undefined,
        },
      ]);
      setBalance(undefined);
      return;
    }
  }, [paymentType, paymentMethod]);

  const [fetchBalance, { data: accountBalance }] =
    useLazyGetSingleAccountQuery();

  useEffect(() => {
    if (accountID) {
      fetchBalance(accountID);
    }
  }, [accountID]);

  useEffect(() => {
    setBalance(accountBalance?.data && accountBalance?.data.amount);
  }, [accountBalance]);

  useEffect(() => {
    form.setFields([
      {
        name: ['client_refund_info', 'return_amount'],
        value: clientRefundTotal,
      },
    ]);
    form.setFields([
      {
        name: ['client_refund_info', 'date'],
        value: dayjs(),
      },
    ]);
  }, []);

  return (
    <Card>
      {/* {clientLastBalance && clientLastBalance >= 0 ? ( */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col
          span={5}
          xs={24}
          sm={24}
          md={24}
          lg={8}
        >
          <Form.Item
            label='Client Refund Type'
            name={['client_refund_info', 'refund_payment_type']}
            rules={[
              {
                required: true,
                message: 'Client Refund Type is required',
              },
            ]}
            initialValue='ADJUST'
          >
            <Select
              allowClear
              placeholder='Select Client Refund Type'
              style={{ width: '100%' }}
              onChange={(e: string) => {
                setClientRefundMethod(e);
              }}
            >
              {refund_type.map((item) => {
                return (
                  <Select.Option
                    key={item}
                    value={item == 'Money Return' ? 'MONEY_RETURN' : 'ADJUST'}
                  >
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        {clientRefundMethod == 'ADJUST' ? (
          <NumberInput
            name={['client_refund_info', 'return_amount']}
            label='Advance Amount'
            readOnly
            size={8}
            min='0'
            maxChar={14}
            minChar={0}
          />
        ) : (
          <>
            {/* <Col span={6} xs={24} sm={24} md={24} lg={8}>
                <Form.Item
                  name={['client_refund_info', 'money_return_type']}
                  label='Payment Method'
                  rules={[
                    {
                      required: true,
                      message: 'Payment Method is required!!',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder='Select Payment Method'
                    style={{ width: '100%' }}
                    onChange={(e: string) => {
                      setPaymentType(e);
                      if (!isChanged.current) isChanged.current = true;
                    }}
                  >
                    {payment_type.map((item) => {
                      return (
                        <Select.Option
                          key={item}
                          value={item == 'Cheque' ? 'CHEQUE' : 'CASH'}
                        >
                          {item}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col> */}
            {paymentType == 'CHEQUE' ? (
              <>
                <FormInputItem
                  label='Cheque No.'
                  required
                  name={['client_refund_info', 'cheque_no']}
                  size={8}
                />
                <DateInput
                  label='Withdraw Date'
                  name={['client_refund_info', 'withdraw_date']}
                  required
                  size={8}
                />
                <FormInputItem
                  label='Bank Name'
                  name={['client_refund_info', 'bank_name']}
                  required
                  size={8}
                />
                <Col
                  span={6}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                >
                  <Form.Item
                    name={['client_refund_info', 'return_amount']}
                    label='Return Amount'
                    rules={[
                      {
                        validator: (_, value) => {
                          if (clientRefundTotal !== undefined) {
                            if (value > clientRefundTotal) {
                              return Promise.reject(
                                'Return amount cannot be greater than total refund amount!!'
                              );
                            } else {
                              return Promise.resolve();
                            }
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <DateInput
                  label='Date'
                  name={['client_refund_info', 'date']}
                  required
                  size={8}
                />
                <FormInputItem
                  label='Note'
                  name={['client_refund_info', 'crefund_note']}
                  size={8}
                />
              </>
            ) : (
              <>
                <SelectPaymentMethod
                  name={['client_refund_info', 'payment_method']}
                  label='Payment Method'
                  size={8}
                  onChange={(value: string) => {
                    setPaymentMethod(value);
                    if (!isChanged.current) isChanged.current = true;
                  }}
                />
                <Col
                  span={6}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                >
                  <Form.Item
                    name={['client_refund_info', 'account_id']}
                    label='Account'
                    rules={[
                      {
                        required: true,
                        message: `Account is required!`,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder={'Select Account'}
                      optionFilterProp='children'
                      onSelect={(e: number) => {
                        setAccountID(e);
                      }}
                    >
                      {accountsByPayment?.data?.map(
                        (item: IaccountsByMethod) => {
                          return (
                            <Select.Option
                              key={item.account_id}
                              value={item.account_id}
                            >
                              {`${item.account_name}`}
                            </Select.Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  span={6}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                >
                  <Form.Item
                    name={['client_refund_info', 'return_amount']}
                    label='Return Amount'
                    rules={[
                      {
                        validator: (_, value) => {
                          if (clientRefundTotal !== undefined) {
                            if (value > clientRefundTotal) {
                              return Promise.reject(
                                'Return amount cannot be greater than total refund amount!!'
                              );
                            } else {
                              return Promise.resolve();
                            }
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col
                  span={6}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                >
                  <Form.Item
                    label='Available Balance'
                    required
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      readOnly
                      value={balance}
                    />
                  </Form.Item>
                </Col>
                {Number(paymentMethod) === 3 ? (
                  <Col
                    span={6}
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                  >
                    <Form.Item
                      label='Transaction Charge'
                      name={['client_refund_info', 'trxn_charge_amount']}
                      required
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                ) : (
                  <></>
                )}
                <DateInput
                  label='Date'
                  name={['client_refund_info', 'date']}
                  required
                  size={8}
                />
                <FormInputItem
                  label='Note'
                  name={['client_refund_info', 'crefund_note']}
                  size={8}
                />
              </>
            )}
          </>
        )}
      </Row>
      {/* ) : (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <NumberInput
            name={['client_refund_info', 'return_amount']}
            label='Client Due Adjustment Amount'
            readOnly
            size={8}
            required
            min='0'
            maxChar={14}
            minChar={0}
          />
        </Row>
      )} */}
    </Card>
  );
};

export default TourRefundClientForm;
