import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Row,
  Space,
  Table,
  Typography,
  theme,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
  InvoiceHeader,
  TitleCenter,
} from '../../../../common/Invoice/InvoiceHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import NumToWord from '../../../../components/number_to_words/NumToWord';
import { useGetSinglePaymentQuery } from '../../Api/Endpoints/PaymentEndpoints';
import { SinglePaymentList } from '../../Utils/Payment/SinglePaymentList';

export const a4sizeStyle: React.CSSProperties = {
  minHeight: '11.5in',
  width: '8.27in',
  fontSize: '11px',
  background: '#fff',
  margin: '0px auto',
};

function ViewPayment() {
  const { id } = useParams();

  const { data: singlePayment } = useGetSinglePaymentQuery(Number(id));
  const { Text } = Typography;

  const printTime = moment().format('ll LTS');

  const { isLoading, data } = useGetSinglePaymentQuery(Number(id));

  const singlePaymentTableData = data?.data;

  const [amount, setAmount] = useState<number>();
  const [name, setName] = useState<string>();
  const [date, setDate] = useState<string>();

  useEffect(() => {
    if (singlePayment?.data && singlePayment?.data[0].payment_amount) {
      setAmount(Number(singlePayment?.data[0].payment_amount));
      setName(singlePayment.data[0].loan_name);
      setDate(dayjs(singlePayment?.data[0].payment_date).format('DD MMM YYYY'));
    }
  }, [singlePayment?.data]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Loan_${name}_${date}`,
  });

  useHotkeys('ctrl + p', (e) => {
    e.preventDefault();
    handlePrint();
  });

  return (
    <>
      <BreadCrumb arrOfOption={['Loan Management', 'View Payment']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/loan/payment'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Payment List
          </Button>
        </Link>
        <Button
          type='primary'
          onClick={handlePrint}
        >
          <PrinterOutlined />
          Print
        </Button>
      </Space>

      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <div>
          <Card
            style={a4sizeStyle}
            ref={componentRef}
          >
            <InvoiceHeader />
            <TitleCenter title='Loan Payment' />

            <Row
              justify={'space-between'}
              style={{ margin: '30px 0' }}
            >
              <Col>
                <Typography>
                  Name :
                  {singlePayment?.data?.length &&
                    singlePayment?.data[0]?.authority_name}
                </Typography>
                <Typography>
                  Type :
                  {singlePayment?.data?.length &&
                    singlePayment.data[0]?.loan_type}
                </Typography>
              </Col>

              <Typography>
                Date :
                {dayjs(
                  singlePayment?.data?.length &&
                    singlePayment?.data[0]?.payment_date
                ).format('YYYY-MM-DD')}
              </Typography>
            </Row>

            <div style={{ minHeight: '600px' }}>
              <Table
                size='small'
                bordered
                scroll={{ x: true }}
                pagination={false}
                columns={SinglePaymentList()}
                className='invoiceBillingTable'
                rowClassName={'invoiceBillingTd'}
                dataSource={singlePaymentTableData}
                loading={{ spinning: isLoading, indicator: loadingIndicator }}
              />

              {amount && (
                <Row style={{ marginTop: '25px' }}>
                  <Typography.Text
                    strong
                    className='changeFont'
                  >
                    <NumToWord number={amount} />
                  </Typography.Text>
                </Row>
              )}
              <Row>
                <Typography.Text
                  className='changeFont'
                  strong
                >
                  Note :
                </Typography.Text>
              </Row>
            </div>

            {/* ================== signature ===================== */}
            <Row
              className='signature'
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '120px',
              }}
            >
              <Typography.Text
                className='changeFont'
                style={{ borderTop: '1px solid black', color: 'black' }}
              >
                Received By
              </Typography.Text>
              <Typography.Text
                className='changeFont'
                style={{ borderTop: '1px solid black', color: 'black' }}
              >
                Prepared By
              </Typography.Text>
              <Typography.Text
                className='changeFont'
                style={{ borderTop: '1px solid black', color: 'black' }}
              >
                Approved By
              </Typography.Text>
            </Row>
            <Row
              justify={'center'}
              style={{ marginTop: '25px' }}
            >
              <Typography.Text className='changeFont'>
                {printTime}
              </Typography.Text>
            </Row>
          </Card>
        </div>
      </ConfigProvider>
    </>
  );
}

export default ViewPayment;
