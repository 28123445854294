import { FormListFieldData, Row } from 'antd';
import { FormInstance, useWatch } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import {
  FormInputItem,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import { SelectVendors } from '../../../../components/common/FormItem/SelectCustomFeilds';
import { useLazyGetItineraryVendorsInfoQuery } from '../../../Configuration/Tour/Ticket/endpoints/tourTicketEndpoints';
import { SelectTourItinerary } from '../Components/SelectTourItinerary';
import { useParams } from 'react-router';

type Props = {
  field: FormListFieldData;
  form: FormInstance<any>;
  index: number;
};

const OtherTransportForm = ({ field, form, index }: Props) => {
  const { id } = useParams();
  //setField vendor name
  const [instanceVendorSelect, setInstanceVendorSelect] = useState<number>();
  const [instanceOtherTransportSelect, setInstanceOtherTransportSelect] =
    useState<number>();
  /* if any field select other field are auto required */
  const other_trans_itinerary_ids = useWatch(
    ['tourOtherTrans', field.name, 'other_trans_itinerary_id'],
    form
  );
  const other_trans_cost_price = useWatch(
    ['tourOtherTrans', field.name, 'other_trans_cost_price'],
    form
  );
  const other_trans_comvendor_id = useWatch(
    ['tourOtherTrans', field.name, 'other_trans_comvendor_id'],
    form
  );
  const other_trans_description = useWatch(
    ['tourOtherTrans', field.name, 'other_trans_description'],
    form
  );
  const requiredField =
    other_trans_comvendor_id ||
    other_trans_cost_price ||
    other_trans_description ||
    other_trans_itinerary_ids;

  const other_trans_itinerary_id: number | undefined = useWatch(
    ['tourOtherTrans', field.name, 'other_trans_itinerary_id'],
    form
  );
  const [isRequired, setIsRequired] = useState<boolean>(false);
  useEffect(() => {
    if (other_trans_itinerary_id) {
      setIsRequired(true);
    } else {
      setIsRequired(false);
    }
  }, [other_trans_itinerary_id]);

  // itinerary
  const [itineraryId, setItineraryId] = useState<number | undefined>();
  const [vendorId, setVendorId] = useState<number>();
  const [vcostPrice, setVCostPrice] = useState<number>();

  const [getItineraryVendorsInfo, { data: vendors, isFetching, isSuccess }] =
    useLazyGetItineraryVendorsInfoQuery();

  //
  useEffect(() => {
    //this for when create tour food,its not work for editing
    if (!id) {
      const selectVendor = vendors?.data?.find(
        (item) => item.itnrvendor_vendor_id === vendorId
      );

      if (selectVendor?.itnrvendor_cost_price) {
        form.setFields([
          {
            name: ['tourOtherTrans', field.name, 'other_trans_cost_price'],
            value: Number(selectVendor?.itnrvendor_cost_price),
          },
        ]);
      } else {
        form.setFields([
          {
            name: ['tourOtherTrans', field.name, 'other_trans_cost_price'],
            value: undefined,
          },
        ]);
      }
    }

    //this only work for when editing
    if (
      (id &&
        other_trans_comvendor_id &&
        other_trans_itinerary_id &&
        itineraryId) ||
      (id && other_trans_comvendor_id && other_trans_itinerary_id && vendorId)
    ) {
      const selectVendor = vendors?.data?.find(
        (item) =>
          item.itnrvendor_vendor_id ===
          Number(other_trans_comvendor_id?.toString().split('-')[1])
      );

      if (selectVendor?.itnrvendor_cost_price) {
        form.setFields([
          {
            name: ['tourOtherTrans', field.name, 'other_trans_cost_price'],
            value: Number(selectVendor?.itnrvendor_cost_price),
          },
        ]);
      } else {
        form.setFields([
          {
            name: ['tourOtherTrans', field.name, 'other_trans_cost_price'],
            value: undefined,
          },
        ]);
      }
    }
  }, [
    other_trans_itinerary_id,
    other_trans_comvendor_id,
    vendors?.data,
    vendorId,
    itineraryId,
  ]);

  //
  useEffect(() => {
    if (other_trans_itinerary_id) {
      getItineraryVendorsInfo(other_trans_itinerary_id);
    }
  }, [other_trans_itinerary_id]);

  //
  useEffect(() => {
    if (vcostPrice) {
      form.setFields([
        {
          name: ['tourOtherTrans', field.name, 'other_trans_cost_price'],
          value: Number(vcostPrice),
        },
      ]);
    }
  }, [vcostPrice]);

  //setField vendor name
  useEffect(() => {
    if (instanceVendorSelect) {
      form.setFieldValue(
        ['tourOtherTrans', field.name, 'other_trans_comvendor_id'],
        'vendor-' + instanceVendorSelect
      );
    } else if (instanceOtherTransportSelect) {
      form.setFields([
        {
          name: ['tourOtherTrans', field.name, 'other_trans_itinerary_id'],
          value: instanceOtherTransportSelect,
        },
      ]);
    }
  }, [instanceVendorSelect, instanceOtherTransportSelect]);
  //-----------------------------
  const [disabled, setDisabled] = useState(false);
  const other_trans_is_deleted: 0 | 1 = useWatch(
    ['tourOtherTrans', index, 'other_trans_is_deleted'],
    form
  );
  useEffect(() => {
    if (other_trans_is_deleted === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [other_trans_is_deleted]);
  return (
    <Row
      style={
        other_trans_is_deleted === 1
          ? {
              backgroundColor: '#FFC0CB',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }
          : { display: 'flex', alignItems: 'center', width: '100%' }
      }
      gutter={[5, 5]}
    >
      <SelectTourItinerary
        label='other-transport'
        name={[field.name, 'other_trans_itinerary_id']}
        onChange={setItineraryId}
        isRequired={requiredField}
        setInstanceTransportSelect={setInstanceOtherTransportSelect}
      />
      <FormInputItem
        label='Description'
        name={[field.name, 'other_trans_description']}
        size={6}
      />

      <NumberInput
        label='Cost Price'
        name={[field.name, 'other_trans_cost_price']}
        size={6}
        required={requiredField}
        maxChar={14}
      />
      <SelectVendors
        label='Vendor'
        size={6}
        name={[field.name, 'other_trans_comvendor_id']}
        onChange={(e) => setVendorId(Number(e?.toString().split('-')[1]))}
        required={requiredField}
        dependencies={['invoice_combclient_id']}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value: string) {
              if (getFieldValue('invoice_combclient_id')) {
                if (getFieldValue('invoice_combclient_id') === value) {
                  return Promise.reject();
                } else {
                  return Promise.resolve();
                }
              }
              return Promise.resolve();
            },
            message: "Client and vendor can't be same!",
          }),
        ]}
        setInstanceVendorSelect={setInstanceVendorSelect}
      />
    </Row>
  );
};

export default OtherTransportForm;
