import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';
import { IMonthlySalesTableData } from '../types/monthly_Sales_EarningTypes';

export const Monthly_Sales_EarningUtils = (
  queryData: {
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  },
  paramData:
    | {
        date_range: string;
        id: number | string;
      }
    | undefined
): ColumnsType<IMonthlySalesTableData> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((queryData.current || 1) - 1) * (queryData.pageSize || 20) +
            1 +
            index}
        </>
      ),
      align: 'center',
    },
    {
      title: 'Date',
      key: 'invoice_sales_date',
      render: (_, record) => {
        return `${dayjs(record?.invoice_sales_date).format('DD MMM YYYY')}`;
      },
      align: 'center',
    },

    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data) => {
        const { invoice_no, invoice_category_id, invoice_id } = data;
        return (
          <>
            <Link
              to={`../${
                findInvoiceRoute(invoice_category_id) +
                '/' +
                'details' +
                '/' +
                invoice_id
              }`}
            >
              {invoice_no}
            </Link>
          </>
        );
      },
    },
    {
      title: 'Client Name',
      dataIndex: 'guest_name',
      key: 'guest_name',
      render: (_, record) => (
        <Link
          to={
            record.invoice_client_id
              ? `/clients/details/client-${record.invoice_client_id}`
              : `/combineClients/details/${record.invoice_combined_id}`
          }
        >
          {record.guest_name}
        </Link>
      ),
    },
    {
      title: 'Sales By',
      dataIndex: 'employee_name',
      key: 'employee_name',
      render: (value, record, index) => (
        <Link
          to={`/reports/sales_man_collection_report?${paramData?.date_range}&employee_id=${record.invoice_sales_man_id}`}
        >
          {record.employee_name}
        </Link>
      ),
    },
    {
      title: 'Sales Category',
      dataIndex: 'sales_category',
      key: 'sales_category',
    },
    {
      title: 'Total Amount',
      dataIndex: 'invoice_net_total',
      key: 'invoice_net_total',
      render: (_, record) => Number(record.invoice_net_total),
      align: 'right',
    },
    {
      title: 'Purchase Amount',
      dataIndex: 'cost_price',
      key: 'cost_price',
      render: (_, record) => Number(record.cost_price),
      align: 'right',
    },
    {
      title: 'Earning Amount',
      dataIndex: 'total_profit',
      key: 'total_profit',
      render: (_, record) => Number(record.total_profit),
      align: 'right',
    },
    {
      title: 'Collected Amount',
      dataIndex: 'total_client_payments',
      key: 'total_client_payments',
      render: (_, record) => Number(record.total_client_payments),
      align: 'right',
    },
    {
      title: 'Due Amount',
      dataIndex: 'invoice_client_previous_due',
      key: 'invoice_client_previous_due',
      align: 'right',
      render: (_, record) =>
        Number(record.invoice_net_total) -
          Number(record.total_client_payments) || 0,
    },
  ];
};
