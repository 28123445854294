import { Col, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import { useAppSelector } from '../../../../app/hooks';
import {
  InvoiceHeader,
  TitleCenter,
} from '../../../../common/Invoice/InvoiceHeader';
import { IViewInvoiceDetails } from '../../../../common/types/commonInterfaces';

type IProps = {
  invoiceData: IViewInvoiceDetails | undefined;
};
const ManualInvoiceCommonHeader = ({ invoiceData }: IProps) => {
  const {
    client_name,
    invoice_walking_customer_name,
    client_address,
    client_mobile,
    client_email,
    invoice_no,
    invoice_date,
    invoice_sales_date,
    employee_full_name,
    refunds,
    invoice_due_date,
    company_name,
    agent_name,
    invoice_is_refund,
    invoice_reference,
  } = (invoiceData as IViewInvoiceDetails) || {};
  const appConfig = useAppSelector((state) => state.configSlice);
  const { tac_inv_in, tac_inv_sd } = appConfig;
  return (
    <header>
      <InvoiceHeader />
      <TitleCenter title='Invoice' />
      <Row justify={'space-between'} align='middle'>
        <Col
          style={{
            color: '#fff',

            width: '50%',
          }}
        >
          <Typography style={{ fontWeight: 'bolder' }}>INVOICE TO</Typography>
          {(client_name || company_name) && (
            <Typography.Text className='changeFontBlock fs-12'>
              {company_name ? 'Company Name :' : 'Name :'}
              {company_name ? company_name : client_name}
              {invoice_walking_customer_name &&
                '/' + invoice_walking_customer_name}
            </Typography.Text>
          )}
          {client_address && (
            <Typography.Text className='changeFontBlock fs-12'>
              Address : {client_address}
            </Typography.Text>
          )}
          {client_email && (
            <Typography.Text className='changeFontBlock fs-12'>
              Email : {client_email}
            </Typography.Text>
          )}
          {client_mobile && (
            <Typography.Text className='changeFontBlock fs-12'>
              Mobile : {client_mobile}
            </Typography.Text>
          )}

          {invoice_reference && (
            <Typography.Text className='changeFontBlock fs-12'>
              Reference : {invoice_reference}
            </Typography.Text>
          )}
        </Col>

        <Col>
          {invoice_is_refund || refunds?.client_refund?.length ? (
            <span style={{ color: 'red' }}>Refunded</span>
          ) : (
            ''
          )}

          <Typography.Text className='changeFontBlock fs-12'>
            Invoice Date : {dayjs(invoice_date).format('DD MMM YYYY')}
          </Typography.Text>
          <Typography.Text className='changeFontBlock fs-12'>
            {tac_inv_in || 'Invoice No'}: {invoice_no}
          </Typography.Text>
          <Typography.Text className='changeFontBlock fs-12'>
            {tac_inv_sd || 'Sales Date'} :{' '}
            {dayjs(invoice_sales_date).format('DD MMM YYYY')}
          </Typography.Text>
          {invoice_due_date && (
            <Typography.Text className='changeFontBlock fs-12'>
              {'Due Date'} : {dayjs(invoice_due_date).format('DD MMM YYYY')}
            </Typography.Text>
          )}
          <Typography.Text className='changeFontBlock fs-12'>
            Sales By : {employee_full_name}
          </Typography.Text>
          {agent_name && (
            <Typography.Text className='changeFontBlock fs-12'>
              {'Agent Name:'}
              {agent_name}
            </Typography.Text>
          )}
        </Col>
      </Row>
    </header>
  );
};

export default ManualInvoiceCommonHeader;
