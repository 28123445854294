import { api } from '../../../../app/baseQuery';
import { IPaginationSD } from '../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { expire } from '../../../../common/utils/constants';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { CHEQUE_LOAN } from '../../../Cheque_Management/api/Constants/constants';
import { DASHBOARD } from '../../../Dashboard/Api/Constants/Constants';
import {
  ILoanDeleteRestoreBody,
  ILoanTotalReport,
  LoanDataType,
  LoanFormDataType,
  LoanFormEditDataType,
  SingleLoanDataType,
} from '../../LoanTypes/LoanTypes';
import { LOAN, PAYMENT, RECEIVE } from '../Constants/constants';

export const LoanEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all loan
    getLoan: build.query<HTTPResponse<LoanDataType[]>, IPaginationSD>({
      query: (query) => ({
        url: `/loan-management/loan?page=${query.current}&size=${query.pageSize}&from_date=${query.from}&to_date=${query.to}&search=${query.search}`,
      }),
      providesTags: [
        { type: 'Loan', id: LOAN },
        { type: 'Payment', id: PAYMENT },
        { type: 'Receive', id: RECEIVE },
      ],
    }),

    // get single loan by loan-id
    getSingleLoan: build.query<HTTPResponse<SingleLoanDataType[]>, number>({
      query: (id) => ({ url: `/loan-management/loan/${id}` }),
      keepUnusedDataFor: expire.min,
      providesTags: (_res, _err, id) => [
        { type: 'Loan', id: id } as const,
        { type: 'Loan', id: LOAN },
      ],
    }),

    getTotalLoanReport: build.query<HTTPResponse<ILoanTotalReport[]>, void>({
      query: (id) => ({ url: `/report/loan-summary/report` }),
      keepUnusedDataFor: expire.min,
      providesTags: () => [
        { type: 'Loan', id: LOAN },
        { type: 'Accounts' },
        { type: 'Dashboard', id: DASHBOARD },
      ],
    }),

    //create loan
    createLoan: build.mutation<void, LoanFormDataType>({
      query: (body) => ({
        url: `/loan-management/loan`,
        method: 'POST',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          toasterNotification('success', 'Loan Created Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Loan', id: LOAN },
        { type: 'Accounts' },
        { type: 'Dashboard', id: DASHBOARD },
      ],
    }),

    //edit loan
    editLoan: build.mutation<void, LoanFormEditDataType>({
      query: (body) => ({
        url: `/loan-management/loan/${body.loan_id}`,
        method: 'PATCH',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          toasterNotification('success', 'Loan Edited Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Loan', id: LOAN },
        { type: 'Cheque_Loan', id: CHEQUE_LOAN },
        { type: 'Accounts' },
        { type: 'Dashboard', id: DASHBOARD },
      ],
    }),

    //delete loan
    deleteLoan: build.mutation<
      void,
      { body: ILoanDeleteRestoreBody; id: number }
    >({
      query: (body) => ({
        url: `/loan-management/loan/${body.id}`,
        method: 'DELETE',
        body: body.body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          toasterNotification('success', 'Loan Deleted Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Loan', id: LOAN },
        { type: 'Accounts' },
        { type: 'Dashboard', id: DASHBOARD },
      ],
    }),
  }),
});

export const {
  useLazyGetLoanQuery,
  useGetLoanQuery,
  useGetSingleLoanQuery,
  useLazyGetSingleLoanQuery,
  useCreateLoanMutation,
  useEditLoanMutation,
  useDeleteLoanMutation,
  useGetTotalLoanReportQuery,
} = LoanEndpoints;
