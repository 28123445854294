import { Button, Popconfirm, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { selectUser } from '../../../auth/states/userSlice';
import { IPermission } from '../../../common/types/commonTypes';

import CheckImageOrPdf from '../../../common/utils/CheckImageOrPdf';
import { storageBaseUrl } from '../../../common/utils/common.utils';
import { useDeleteExpenseMutation } from '../Api/Endpoints/ExpenseEndpoints';
import {
  IExpenseDataType,
  IExpenseDeleteRestoreBody,
} from '../ExpenseTypes/Expensetypes';

interface Status {
  expcheque_status: string;
  account_name: string;
  acctype_name: string;
}

export const ExpenseUtils = ({
  permission,
  checkButtonState,
  pagination,
}: {
  permission?: IPermission;
  checkButtonState?: boolean;
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<IExpenseDataType> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const user = useAppSelector(selectUser);
  const location = useLocation();

  const [deleteExpense, { isLoading, isSuccess, isError }] =
    useDeleteExpenseMutation();

  const handleExpenseDelete = async (
    values: IExpenseDataType,
    index: number
  ) => {
    const IExpenseDeleteBody: IExpenseDeleteRestoreBody = {
      expense_created_by: user?.user_id as number,
      expense_date: dayjs(values.expense_date).format('YYYY-MM-DD'),
    };
    setColumnIndex(index);
    await deleteExpense({
      id: values.expense_id,
      IExpenseDeleteBody,
    });
  };
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isError) {
      setColumnIndex(null);
    }
  }, [isSuccess, isError]);
  const column: ColumnsType<IExpenseDataType> = [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Date',
      key: 'date',

      render: (_, record) => {
        return `${dayjs(record.expense_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Voucher No.',
      dataIndex: 'expense_vouchar_no',
      key: 'expense_vouchar_no',
    },

    {
      title: 'Account Name',
      dataIndex: 'acctype_name',

      render: (_, record) => {
        let color = record.expcheque_status === 'DEPOSIT' ? 'green' : 'red';
        function status(record: Status) {
          if (record.expcheque_status != null) {
            return <span style={{ color }}> {record.expcheque_status} </span>;
          } else {
            return `${record.account_name}${
              record.acctype_name ? ' - (' + record.acctype_name + ')' : ''
            }`;
          }
        }

        return status(record);
      },
    },
    {
      title: 'Image 1',
      dataIndex: 'expense_voucher_url_1',
      key: 'expense_voucher_url_1',
      align: 'center',
      render: (_, data) =>
        data.expense_voucher_url_1 && (
          <CheckImageOrPdf
            url={storageBaseUrl + String(data.expense_voucher_url_1)}
          />
        ),
    },

    {
      title: 'Image 2',
      dataIndex: 'expense_voucher_url_2',
      key: 'expense_voucher_url_2',
      align: 'center',
      render: (_, data) =>
        data.expense_voucher_url_2 && (
          <CheckImageOrPdf
            url={storageBaseUrl + String(data.expense_voucher_url_2)}
          />
        ),
    },

    {
      title: 'Total Amount',
      dataIndex: 'expense_total_amount',
      key: 'expense_total_amount',
      align: 'center',
      render: (_, record) => Number(record.expense_total_amount),
    },
  ];
  if (!checkButtonState) {
    const action: ColumnsType<IExpenseDataType> = [
      {
        title: 'Action',
        key: 'operation',

        render: (_, record, index) => {
          return (
            <Space size='small'>
              {!checkButtonState && (
                <Link
                  to={`/expense/view/${record.expense_id}`}
                  state={location.pathname}
                >
                  <Button size='small' type='primary'>
                    <Typography style={{ color: '#ffffff' }}>View</Typography>
                  </Button>
                </Link>
              )}
              {!checkButtonState &&
                permission?.['update:any'] &&
                record.expcheque_status !== 'DEPOSIT' && (
                  <Link
                    to={`/expense/edit/${record.expense_id}`}
                    state={location.pathname}
                  >
                    <Button size='small' type='primary'>
                      Edit
                    </Button>
                  </Link>
                )}

              {permission?.['delete:any'] &&
                record.expense_is_deleted === 0 && (
                  <Popconfirm
                    title='Sure to delete?'
                    onConfirm={() => handleExpenseDelete(record, index)}
                  >
                    <Button
                      danger
                      size='small'
                      type='primary'
                      loading={columnIndex === index && true}
                    >
                      <Typography style={{ color: '#ffffff' }}>
                        Delete
                      </Typography>
                    </Button>
                  </Popconfirm>
                )}
            </Space>
          );
        },
      },
    ];
    column.push(action[0]);
  }

  return column;
};
