import { message } from 'antd';
import { api } from '../../../app/baseQuery';
import {
  IAllInvoices,
  IViewInvoiceDetails,
} from '../../../common/types/commonInterfaces';
import { HTTPResponse, voidType } from '../../../common/types/commonTypes';
import { DASHBOARD } from '../../Dashboard/Api/Constants/Constants';
import { MONEY_RECEIPT_TAG } from '../../Money_Receipt/api/endpoints/moneyReceiptEndpoints';
import { INVALIDATE_CLIENT_VENDOR_LEDGER } from '../../Reports/Api/constants';
import {
  IInvoiceDetails,
  IInvoiceOtherFormattedValueType,
} from '../Type/Invoce.other.interface';

export const invoiceVisaEndponts = api.injectEndpoints({
  endpoints: (build) => ({
    /**
     * get all advance return vendors
     *
     */

    // GET ALL
    getAllInvoiceOther: build.query<HTTPResponse<IAllInvoices[]>, string>({
      query: (query) => ({
        url: `/invoice-others/all?${query}`,
      }),
      providesTags: () => [
        'invoices',
        'invoice-other',
        { type: MONEY_RECEIPT_TAG },
      ],
    }),

    // VIEW DETAILIS
    getViewInvoiceOther: build.query<HTTPResponse<IViewInvoiceDetails>, number>(
      {
        query: (id) => ({
          url: `/invoice-others/view/${id}`,
        }),
        providesTags: () => [
          'invoice-other',
          'invoices',
          'invoice_single_view',
        ],
      }
    ),

    // DELETE AND RESTORE
    deleteInvoiceOther: build.mutation<
      HTTPResponse<void>,
      { query: 'delete' | 'restore'; user_id?: number; id: number }
    >({
      query: ({ id, query, user_id }) => ({
        url: `/invoice-others/${query}/${id}`,
        method: 'DELETE',
        body: { invoice_has_deleted_by: user_id },
      }),
      invalidatesTags: [
        'invoices',
        'invoice-other',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        DASHBOARD,
      ],
    }),

    getTransportType: build.query<
      HTTPResponse<{ ttype_id: number; ttype_name: string }[]>,
      void
    >({
      query: () => ({
        url: '/invoice-others/transport-type',
      }),
      providesTags: () => [{ type: 'invoice-other' }],
    }),

    getHotelRoomType: build.query<
      HTTPResponse<{ rtype_id: number; rtype_name: string }[]>,
      void
    >({
      query: () => ({
        url: '/hotel-room/get-all',
      }),
      providesTags: () => [{ type: 'invoice-other' }],
    }),

    getInvoiceOtherDetails: build.query<HTTPResponse<IInvoiceDetails>, number>({
      query: (invoice_id) => ({
        url: `invoice-others/view/invoice-all-data-by-id/${invoice_id}`,
      }),
      providesTags: () => [{ type: 'invoice-other' }],
    }),

    postInvoiceOther: build.mutation<
      HTTPResponse<any>,
      IInvoiceOtherFormattedValueType
    >({
      query: (body) => ({
        url: '/invoice-others/post',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        { type: 'invoice-other' },
        { type: 'invoices' },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    editInvoiceOther: build.mutation<
      HTTPResponse<void>,
      { id: number; body: IInvoiceOtherFormattedValueType }
    >({
      query: ({ id, body }) => ({
        url: `/invoice-others/edit/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        { type: 'invoice-other' },
        { type: 'invoices' },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    //Void Invoice Other AirTicket
    voidOtherInvAirTicket: build.mutation<void, voidType>({
      query: (body) => ({
        url: `/invoice-others/void/${body.id}`,
        method: 'PUT',
        body: body,
      }),

      transformErrorResponse: (response) => {
        if (response.status === 400 && response.data) {
          const { message: err } = response.data as {
            message: string;
            success: boolean;
          };
          message.error(`${err}`);
        } else {
          message.error('Some things went to wrong');
        }
      },
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await queryFulfilled;
        message.success('Void Successfully');
      },

      invalidatesTags: [
        'invoice-other',
        'invoices',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),
  }),
});

export const {
  useLazyGetAllInvoiceOtherQuery,
  useGetAllInvoiceOtherQuery,
  useDeleteInvoiceOtherMutation,
  useEditInvoiceOtherMutation,
  useGetHotelRoomTypeQuery,
  useGetInvoiceOtherDetailsQuery,
  useGetTransportTypeQuery,
  useGetViewInvoiceOtherQuery,
  usePostInvoiceOtherMutation,
  useVoidOtherInvAirTicketMutation,
} = invoiceVisaEndponts;
