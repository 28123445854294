import { Button, Col, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { InvoiceFooter } from '../../../../common/Invoice/InvoiceFooter';
import {
  IPaxDetails,
  IViewInvoiceDetails,
} from '../../../../common/types/commonInterfaces';
import { isNotEmpty } from '../../../../common/utils/common.utils';
import InvoiceBillingEdit from '../../../fake_invoices/air_ticket_fake_invoic/components/InvoiceBillingEdit';
import InvoicePaxEdit from '../../../fake_invoices/air_ticket_fake_invoic/components/InvoicePaxEdit';
import InvoiceSubtotalEdit from '../../../fake_invoices/air_ticket_fake_invoic/components/InvoiceSubtotalEdit';
import PrintComponent from '../../../fake_invoices/air_ticket_fake_invoic/components/PrintComponent';
import { usePostInvoiceAirTicketHistoryMutation } from '../../api/endpoints/airticketInvoiceEndpoints';
import { IAirticketBillingInfo } from '../../types/invoiceAirTicketTypes';
import ManualEditInVoiceFlight, {
  IFlightData,
} from './ManualEditInVoiceFlight';
import ManualInvoiceCommonHeader from './ManualInvoiceCommonHeader';

const a4sizeStyle: React.CSSProperties = {
  minHeight: '11.5in',
  width: '8.27in',
  fontSize: '11px',
  background: '#fff',
  margin: '0 auto',
  boxSizing: 'border-box',
  padding: '0 15px',
};
const invoiceViewStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'rgba(211, 211, 211, 0.2)',
  padding: '10px 0',
  height: '100%',
};
const ManualEditInvoiceAirTicket = ({
  viewEditedInvoiceRef,
  invoiceData,
  isClickPrint,
  selectedForPrint,
}: {
  invoiceData: IViewInvoiceDetails | undefined;
  viewEditedInvoiceRef?: React.RefObject<HTMLDivElement>;
  isClickPrint: boolean;
  selectedForPrint:
    | 'viewInvoice'
    | 'costDetails'
    | 'payments'
    | 'edited'
    | 'activity';
}) => {
  const { id } = useParams();
  const [postInvoiceHistory] = usePostInvoiceAirTicketHistoryMutation();
  const [isShowFlightDetails, setIsShowFlgihtDetails] = useState<boolean>(true);
  const [isShowPaxDetails, setIsShowPaxDetails] = useState<boolean>(true);
  const [isShowDiscount, setIsShowDiscount] = useState<boolean>(true);
  const [isShowPayment, setIsShowPayment] = useState<boolean>(true);
  const [allEditableData, setAllEditableData] = useState<{
    flightDetails?: IFlightData[];
    paxDetails?: IPaxDetails[];
    billingDetails?: IAirticketBillingInfo[];
    subtotalDetails?: any;
  }>({
    flightDetails: [],
    paxDetails: [],
    billingDetails: [],
    subtotalDetails: [],
  });

  const [isFlightUpdate, setIsFlightUpdate] = useState<{
    update: string;
    add: string;
    remove: string;
  }>({
    update: '',
    add: '',
    remove: '',
  });
  const [isPaxUpdate, setIsPaxUpdate] = useState<{
    update: string;
    add: string;
    remove: string;
  }>({
    update: '',
    add: '',
    remove: '',
  });
  const [isBillingUpdate, setIsBillingUpdate] = useState<{
    update: string;
    add: string;
    remove: string;
  }>({
    update: '',
    add: '',
    remove: '',
  });

  useEffect(() => {
    if (selectedForPrint === 'edited' && isClickPrint) {
      const data: any = {
        invoice_id: id,
        content: `${
          isFlightUpdate.add === 'add' ? 'ADD FLIGHT & ROUTES,' : ''
        } ${
          isFlightUpdate.update === 'update'
            ? 'EDITED: UPDATE FLIGHT & ROUTES,'
            : ''
        }${
          isFlightUpdate.remove === 'remove'
            ? 'EDITED: REMOVE FLIGHT & ROUTES,'
            : ''
        }
        ${isPaxUpdate.add === 'add' ? ' EDITED: ADD PAX INFORMATION,' : ''} ${
          isPaxUpdate.update === 'update'
            ? 'EDITED: UPDATE PAX INFORMATION,'
            : ''
        }${
          isPaxUpdate.remove === 'remove'
            ? 'EDITED: REMOVE PAX INFORMATION,'
            : ''
        }
         ${
           isBillingUpdate.add === 'add' ? 'EDITED: ADD BILL INFORMATION,' : ''
         } ${
          isBillingUpdate.update === 'update'
            ? 'EDITED: UPDATE BILL INFORMATION,'
            : ''
        }${
          isBillingUpdate.remove === 'remove'
            ? 'EDITED: REMOVE BILL INFORMATION'
            : ''
        }
        ${
          Number(allEditableData?.subtotalDetails[1]?.value) >
          Number(invoiceData?.invoice_discount)
            ? `EDITED: UPDATE DISCOUNT ${Number(
                invoiceData?.invoice_discount
              )} to ${Number(allEditableData?.subtotalDetails[1]?.value)},`
            : ''
        }
        ${
          Number(allEditableData?.subtotalDetails[3]?.value) >
          Number(invoiceData?.invoice_pay)
            ? `EDITED: UPDATE PAYMENT ${Number(
                invoiceData?.invoice_pay
              )} to ${Number(allEditableData?.subtotalDetails[3]?.value)}`
            : ''
        }
        `,
      };
      postInvoiceHistory(data);
    }
  }, [isClickPrint, selectedForPrint]);
  return (
    <div style={invoiceViewStyle}>
      <div>
        <Row justify={'end'} className='mb-5'>
          <Col className='commonBox'>
            <Space>
              <Button
                size='small'
                onClick={() => setIsShowFlgihtDetails(!isShowFlightDetails)}
              >
                {isShowFlightDetails
                  ? ' Hide Flight Details'
                  : 'Show Flight Details'}
              </Button>
              <Button
                size='small'
                onClick={() => setIsShowPaxDetails(!isShowPaxDetails)}
              >
                {isShowPaxDetails ? 'Hide Pax Details' : 'Show Pax Details'}
              </Button>
              <Button
                size='small'
                onClick={() => setIsShowDiscount(!isShowDiscount)}
              >
                {isShowDiscount ? 'Hide Discount' : 'Show Discount'}
              </Button>
            </Space>
          </Col>
        </Row>

        <div style={{ ...a4sizeStyle, marginTop: '10px' }}>
          <ManualInvoiceCommonHeader invoiceData={invoiceData} />
          {isNotEmpty(invoiceData?.flights) && isShowFlightDetails && (
            <ManualEditInVoiceFlight
              invoiceFlight={invoiceData?.flights}
              allEditableData={allEditableData}
              setAllEditableData={setAllEditableData}
              setIsFlightUpdate={setIsFlightUpdate}
              isFlightUpdate={isFlightUpdate}
            />
          )}
          {isNotEmpty(invoiceData?.pax_details) && isShowPaxDetails && (
            <InvoicePaxEdit
              invoicesPax={invoiceData?.pax_details}
              allEditableData={allEditableData}
              setAllEditableData={setAllEditableData}
              setIsPaxUpdate={setIsPaxUpdate}
              isPaxUpdate={isPaxUpdate}
            />
          )}
          <InvoiceBillingEdit
            invoiceBilling={invoiceData?.billing_info}
            allEditableData={allEditableData}
            setAllEditableData={setAllEditableData}
            setIsBillingUpdate={setIsBillingUpdate}
            isBillingUpdate={isBillingUpdate}
          />

          {/* <InvoiceOtherBillingEdit />{' '} */}

          <InvoiceSubtotalEdit
            invoiceData={invoiceData}
            setAllEditableData={setAllEditableData}
            allEditableData={allEditableData}
            isShowPayment={isShowPayment}
            isShowDiscount={isShowDiscount}
          />

          <InvoiceFooter
            authorized_by={invoiceData?.authorized_by}
            prepared_by={invoiceData?.prepared_by}
          />
        </div>
      </div>

      <PrintComponent
        authorized_by={invoiceData?.authorized_by}
        prepared_by={invoiceData?.prepared_by}
        allEditableData={allEditableData}
        ref={viewEditedInvoiceRef}
        invoiceData={invoiceData}
        isShowFlightDetails={isShowFlightDetails}
        isShowPaxDetails={isShowPaxDetails}
      />
    </div>
  );
};

export default ManualEditInvoiceAirTicket;
