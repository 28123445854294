import { Card, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { Fixed2, separateClient } from '../../../../common/utils/common.utils';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';
import {
  IBSPSummary,
  ticket_issue,
} from '../../interfaces/dashboard.interfaces';

type Props = {
  data: HTTPResponse<IBSPSummary> | undefined;
  isLoading: boolean;
  isFetching: boolean;
};

const { Text } = Typography;

const BSP_Ticket_Issue = ({ data, isLoading, isFetching }: Props) => {
  // TICKET ISSUE
  let total_gross_fare = 0;
  let total_base_fare = 0;
  let total_purchase_price = 0;

  useEffect(() => {
    // TICKET ISSUE
    total_gross_fare = 0;
    total_base_fare = 0;
    total_purchase_price = 0;
  }, []);

  const ticket_issue: ColumnsType<ticket_issue> = [
    {
      title: 'SL',
      dataIndex: 'airticket_tax',
      key: 'airticket_tax',
      render: (_, data, index) => index + 1,
    },
    {
      title: 'Sales Date',
      dataIndex: 'airticket_tax',
      key: 'airticket_tax',
      render: (_, data) =>
        data?.airticket_sales_date &&
        dayjs(data.airticket_sales_date).format('DD-MMM-YYYY'),
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data, index) => (
        <Link
          to={`../${
            findInvoiceRoute(data.invoice_category_id) +
            '/' +
            'details' +
            '/' +
            data.invoice_id
          }`}
        >
          {data.invoice_no}
        </Link>
      ),
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (rec, data) => {
        const { client_id, combined_id } = separateClient(data.comb_client);

        return (
          <Link
            to={
              client_id
                ? `/reports/client_ledger?id=${client_id}`
                : `/reports/combined_ledger?id=${combined_id}`
            }
          >
            {rec}
          </Link>
        );
      },
    },
    {
      title: 'Ticket No.',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
    },
    {
      title: 'PNR',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
    },
    {
      title: 'Airline',
      dataIndex: 'airline_name',
      key: 'airline_name',
    },
    {
      title: 'Gross Fare',
      dataIndex: 'airticket_gross_fare',
      key: 'airticket_gross_fare',
      render: (airticket_gross_fare) =>
        Fixed2(airticket_gross_fare).toLocaleString(),
      align: 'right',
    },
    {
      title: 'Base Fare',
      dataIndex: 'airticket_base_fare',
      key: 'airticket_base_fare',
      render: (airticket_base_fare) =>
        Fixed2(airticket_base_fare).toLocaleString(),
      align: 'right',
    },
    {
      title: 'Purchase Price',
      dataIndex: 'airticket_purchase_price',
      key: 'airticket_purchase_price',
      render: (airticket_purchase_price) => (
        <span style={{ color: 'red' }}>
          {Fixed2(airticket_purchase_price).toLocaleString()}
        </span>
      ),
      align: 'right',
    },
  ];
  return (
    <Card
      size='small'
      title={
        <div
          style={{
            display: 'flex ',
            justifyContent: 'space-between',
          }}
        >
          <span>TICKET ISSUE</span>{' '}
          <span>
            ISSUE DATE RANGE :{' '}
            <Text type='secondary'>
              {' '}
              {`${
                data?.data?.sales_from_date &&
                dayjs(data?.data?.sales_from_date).format('DD-MM-YYYY')
              } `}{' '}
            </Text>{' '}
            TO
            <Text type='secondary'>
              {` ${
                data?.data?.sales_to_date &&
                dayjs(data?.data?.sales_to_date).format('DD-MM-YYYY')
              }`}
            </Text>
          </span>
          <span></span>
        </div>
      }
    >
      <Table
        size='small'
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        bordered
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        dataSource={data?.data?.ticket_issue}
        columns={ticket_issue}
        pagination={false}
        summary={(data) => {
          data?.map((item) => {
            total_gross_fare += Fixed2(item.airticket_gross_fare);
            total_base_fare += Fixed2(item.airticket_base_fare);
            total_purchase_price += Fixed2(item.airticket_purchase_price);
          });

          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={7}>
                <div className='report_total_footer'>
                  <Typography.Text strong>Total: </Typography.Text>
                </div>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={0}>
                <div className='report_total_footer'>
                  <Typography.Text strong>
                    {total_gross_fare.toLocaleString()}
                  </Typography.Text>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <div className='report_total_footer'>
                  <Typography.Text strong>
                    {total_base_fare.toLocaleString()}
                  </Typography.Text>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <div className='report_total_footer'>
                  <Typography.Text strong style={{ color: '#EE4266' }}>
                    {total_purchase_price.toLocaleString()}
                  </Typography.Text>
                </div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </Card>
  );
};

export default BSP_Ticket_Issue;
