import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { IJourneyDateWiseClientsDataType } from '../types/JourneyDateWiseClientsTypes';
import { Link } from 'react-router-dom';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';

const JourneyDateWiseClientsColumn = (
  queryData: {
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  },
  paramData:
    | {
        date_range: string;
        id: number | string;
      }
    | undefined
): ColumnsType<IJourneyDateWiseClientsDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },
    {
      title: 'Invoice Date',
      dataIndex: 'create_date',
      key: 'create_date',

      render: (create_date) => {
        return `${dayjs(create_date).format('DD-MM-YYYY')}`;
      },
    },
    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data) => {
        const { invoice_no, invoice_category_id, airticket_invoice_id } = data;
        return (
          <>
            <Link
              to={`../${
                findInvoiceRoute(invoice_category_id) +
                '/' +
                'details' +
                '/' +
                airticket_invoice_id
              }`}
            >
              {invoice_no}
            </Link>
          </>
        );
      },
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (_, record) => (
        <Link
          to={
            record.airticket_client_id
              ? `/clients/details/client-${record.airticket_client_id}`
              : `/combineClients/details/${record.airticket_combined_id}`
          }
        >
          {record.client_name}
        </Link>
      ),
    },
    // {
    //   title: 'Vendor Name',
    //   dataIndex: 'vendor_name',
    //   key: 'vendor_name',
    // },
    {
      title: 'Pax Name',
      dataIndex: 'pass_name',
      key: 'pass_name',
    },
    {
      title: 'Journey Date',
      dataIndex: 'airticket_journey_date',
      key: 'airticket_journey_date',

      render: (_, record) => {
        return `${_ ? dayjs(_).format('DD-MM-YYYY') : ''}`;
      },
    },
    {
      title: 'Return Date',
      dataIndex: 'airticket_return_date',
      key: 'airticket_return_date',

      render: (_, record) => {
        return `${_ ? dayjs(_).format('DD-MM-YYYY') : ''}`;
      },
    },

    {
      title: 'Airline Name',
      dataIndex: 'airline_name',
      key: 'airline_name',
      render: (_, record) => (
        <Link
          to={`/reports/airline_wise_sales_report?${paramData?.date_range}&airline_id=${record.airticket_airline_id}`}
        >
          {' '}
          {record.airline_name}
        </Link>
      ),
    },

    {
      title: 'Ticket No',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',

      render: (_, record) => (
        <Link
          to={`/reports/ticket_wise?${paramData?.date_range}&airticket_id=${record.airticket_id}`}
        >
          {' '}
          {record.airticket_ticket_no}
        </Link>
      ),
    },
    {
      title: 'Pnr',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
    },
    {
      title: 'Route',
      dataIndex: 'airticket_routes',
      key: 'airticket_routes',
    },
  ];
};

export default JourneyDateWiseClientsColumn;
