import { UndoOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { selectUser } from '../../../auth/states/userSlice';
import { IPermission, crud } from '../../../common/types/commonTypes';
import {
  useDeleteQuotationMutation,
  useRestoreQuotationMutation,
} from '../Api/Endpoints/QuotationEndpoints';
import Confirm_quotation_modal from '../Components/Confirm_quotation_modal';
import { IQuotationDataType } from '../QuotationTypes/QuotationTypes';

type Props = {};

export const QuotationUtils = (
  pagination: {
    current: number;
    pageSize: number;
  },
  permission?: IPermission
): ColumnsType<IQuotationDataType> => {
  const user = useAppSelector(selectUser);
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const [deleteQuotation, { isLoading, isSuccess, isError }] =
    useDeleteQuotationMutation();
  const [restoreQuotation] = useRestoreQuotationMutation();

  const handleQuotationDelete = async (
    values: IQuotationDataType,
    index: number
  ) => {
    setColumnIndex(index);
    await deleteQuotation({
      quotation_id: values.quotation_id,
      quotation_deleted_by: user?.user_id as number,
    });
  };
  const handleQuotationRestore = async (values: IQuotationDataType) => {
    await restoreQuotation({
      quotation_id: values.quotation_id,
      quotation_deleted_by: user?.user_id as number,
    });
  };
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isError) {
      setColumnIndex(null);
    }
  }, [isSuccess, isError]);
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Quotation Date',
      key: 'quotation_date',
      render: (_, record) => {
        return `${dayjs(record.quotation_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Quotation No',
      dataIndex: 'quotation_no',
      key: 'quotation_no',
    },
    {
      title: 'Client Name',
      key: 'client_name',
      dataIndex: 'client_name',
    },
    {
      title: 'Q Type',
      key: 'quotation_type ',
      dataIndex: 'quotation_type ',
      render: (_, record, sd) => {
        return `${
          record.quotation_type === 'ACCUMULATE' ? 'Accumulate' : 'Quotation'
        }`;
      },
    },
    {
      title: 'Mobile',
      dataIndex: 'client_mobile',
      key: 'client_mobile',
    },
    {
      title: 'Net Total',
      dataIndex: 'quotation_net_total',
      key: 'quotation_net_total',
    },

    {
      title: 'Action',
      key: 'operation',

      render: (_, record, index) => (
        <Space size='small'>
          {
            <Link
              to={
                record.quotation_type === 'ACCUMULATE'
                  ? `/accumulated/details/${record.quotation_id}`
                  : `/quotation/details/${record.quotation_id}`
              }
              state={location.pathname}
            >
              <Button size='small' type='primary'>
                View
              </Button>
            </Link>
          }
          {permission?.[crud.update] && record?.quotation_is_confirm !== 1 && (
            <Confirm_quotation_modal id={record.quotation_id} />
          )}
          {/* { (
            <Link
              to={`/quotation/logs/${record.quotation_id}`}
              state={location.pathname}
            >
              <Button size='small' type='primary'>
                Logs
              </Button>
            </Link>
          )} */}
          {permission?.[crud.update] && record?.quotation_is_confirm !== 1 && (
            <Link
              to={`/quotation/edit/${record.quotation_id}`}
              state={location.pathname}
            >
              <Button size='small' type='primary'>
                Edit
              </Button>
            </Link>
          )}
          {permission?.['delete:any'] && record.quotation_is_deleted === 0 && (
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleQuotationDelete(record, index)}
            >
              <Button
                danger
                size='small'
                type='primary'
                loading={columnIndex === index && true}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
          {permission?.[crud.update] && record.quotation_is_deleted !== 0 && (
            <Popconfirm
              title='Sure to Restore?'
              onConfirm={() => handleQuotationRestore(record)}
            >
              <Button
                size='small'
                type='primary'
                style={{ background: '#52C41A', border: 'none' }}
              >
                <UndoOutlined /> Restore
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
};
