import { Card, Col, Form, message, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import {
  SelectClients,
  SelectInvoiceByClientID,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { IPartialRefundInvoice } from '../../RefundTypes/RefundTypes';

type Props = {};

const CreateAirTicketTaxRefund = (props: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const client_id = useWatch('client_id', form);
  const [selectedINV, setSelectINV] = useState<IPartialRefundInvoice>();

  const onFinish = (value: ISubmit) => {
    const activeInvoiceId = [1, 2, 3];

    if (activeInvoiceId.includes(Number(selectedINV?.invoice_category_id))) {
      navigate(
        `/refund/tax-details?invoice_id=${selectedINV?.invoice_id}&invoice_no=${selectedINV?.invoice_no}&category=${selectedINV?.invoice_category_id}`,
        { state: location.pathname }
      );
    } else {
      message.info("You can't refund tax for this invoice");
    }
  };

  return (
    <Row justify={'center'}>
      <Form
        form={form}
        onFinish={onFinish}
        layout='vertical'
        style={{ width: 800 }}
      >
        <FormHeaderTitle title='Airticket Tax Refund' />
        <Card>
          <Row justify={'center'}>
            <Col span={12}>
              <SelectClients
                name='client_id'
                label='Select Client'
                required
                size={24}
                offDropDown
              />

              <SelectInvoiceByClientID
                name='invoice_id'
                label='Select Invoice'
                clientId={client_id as string}
                size={24}
                required
                getSelectedInvoice={setSelectINV}
              />
              <FormButton label='Submit' textAlign='end' />
            </Col>
          </Row>
        </Card>
      </Form>
    </Row>
  );
};

export default CreateAirTicketTaxRefund;

export interface ISubmit {
  client_id: string;
  invoice_id: number;
}
