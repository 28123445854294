import React from 'react';

export interface LoanAuthorityDataType {
  key?: React.Key;
  authority_id: number;
  authority_name: string;
  authority_mobile: string;
  authority_address: string;
  authority_create_date: string;
}

export interface LoanAuthorityFormData {
  authority_id: number;
  name: string;
  contact: string;
  address: string;
  created_by: number;
}

export interface LoanAuthorityEditFormData extends LoanAuthorityFormData {}

export interface ILoanDeleteRestoreBody {
  user_id?: number;
  deleted_by?: number;
}

export interface LoanDataType {
  key?: React.Key;
  loan_id: number;
  authority_name: string;
  authority_id: number;
  loan_name: string;
  loan_type: string;
  loan_amount: number;
  loan_due_amount: number;
  loan_interest_percent: number;
  loan_payable_amount: number;
  loan_receivable_amount: number;
  loan_installment: string;
  loan_installment_type: string;
  loan_installment_per_day: number;
  loan_installment_per_month: number;
  loan_installment_duration: number;
  loan_payment_type: string;
  loan_accategory_id: number;
  loan_account_id: number;
  loan_cheque_no: string;
  loan_withdraw_date: string;
  loan_bank_name: string;
  loan_date: string;
  loan_note: string;
  pay_details: string;
  loan_pay_type: string;
  loan_actransaction_id: number;
}

export interface SingleLoanDataType {
  key?: React.Key;
  loan_id: number;
  authority_name: string;
  loan_name: string;
  loan_type: string;
  loan_payable_amount: string;
  loan_receivable_amount: string;
  loan_interest_percent: number;
  loan_due_amount: string;
  loan_installment: string;
  loan_date: string;
  loan_vouchar: string;
  loan_note: string;
  loan_installment_per_day: string;
  loan_installment_per_month: string;
  loan_installment_duration: number;
  loan_installment_type: string;
}

export interface LoanSummary {
  key: React.Key;
  loan_type: string;
  loan_amount: number;
  interest_amount: number;
  payable: number;
  receivable: number;
  pay_received: number;
  due: number;
}
export interface LoanFormDataType {
  loan_id: number;
  loan_created_by: number;
  authority_id: number;
  name: string;
  type: string;
  amount: number;
  interest_percent: number;
  pay_amount: number | undefined;
  receive_amount: number | undefined;
  installment: string;
  installment_type: string;
  installment_duration: number;
  installment_per_day: number | undefined;
  installment_per_month: number | undefined;
  payment_type: string;
  accategory_id: number;
  account_id: number;
  date: string;
  withdraw_date: string;
  note: string;
  loan_actransaction_id: number;
}

export interface ILoanTotalReport {
  loan_type: string;
  loan_amount: null | string;
  payable_amount: null | string;
  receivable_amount: null | string;
  due_amount: null | string;
  interest_amount: null | string;
}

export interface LoanFormEditDataType
  extends Omit<LoanFormDataType, 'loan_created_by'> {}

export interface ViewLoanPaymentDataType {
  key?: React.Key;
  payment_id: number;
  authority_id: number;
  authority_name: string;
  loan_id: number;
  loan_name: string;
  payment_amount: string;
  payment_type: string;
  payment_cheque_no: string;
  payment_bank_name: string;
  type_id: number;
  account_id: number;
  account_name: string;
  payment_date: string;
  payment_withdraw_date: string;
  payment_note: string;
  pay_details: string;
}

export interface SinglePaymentDataType {
  key?: React.Key;
  payment_id: number;
  authority_name: string;
  loan_name: string;
  loan_type: string;
  payment_amount: string;
  loan_due_amount: string;
  account_name: string;
  payment_date: string;
}

export interface PaymentLoans {
  loan_authority_id: number;
  authority_name: string;
  loan_id: number;
  loan_name: string;
  loan_type: string;
  loan_payable_amount: string;
  loan_receivable_amount: string;
}
export interface PaymentFormData {
  payment_id: number;
  payment_created_by: number;
  loan_authority_id: number;
  authority_name: string;
  loan_id: number;
  loan_name: string;
  loan_type: string;
  loan_payable_amount: string;
  loan_receivable_amount: string;

  payment_date: string;
  withdraw_date: string;
  created_by: number;
}

export interface PaymentEditFormData
  extends Omit<PaymentFormData, 'payment_created_by'> {}

export interface ViewLoanReceiveDataType {
  key?: React.Key;
  received_id: number;
  authority_id: number;
  authority_name: string;
  loan_id: number;
  loan_name: string;
  received_amount: string;
  payment_type: string;
  received_cheque_no: string;
  received_bank_name: string;
  type_id: number;
  account_id: number;
  account_name: string;
  received_date: string;
  received_withdraw_date: string;
  received_note: string;
  pay_details: string;
}
export interface SingleReceiveDataType {
  key?: React.Key;
  payment_id: number;
  authority_name: string;
  loan_name: string;
  loan_type: string;
  received_amount: string;
  account_name: string;
  payment_date: string;
  received_date: string;
  loan_amount: number;
  loan_due_amount: string;
}

export interface ReceivedLoans {
  loan_authority_id: number;
  authority_name: string;
  loan_id: number;
  loan_name: string;
  loan_type: string;
  loan_payable_amount: string;
  loan_receivable_amount: string;
}

export interface ReceivedFormData {
  received_id: number;
  received_created_by: number;
  loan_authority_id: number;
  authority_name: string;
  loan_id: number;
  loan_name: string;
  loan_type: string;
  loan_payable_amount: string;
  loan_receivable_amount: string;
  received_date: string;
  withdraw_date: string;
  created_by: number;
}

export interface ReceivedEditFormData extends Omit<ReceivedFormData, ''> {}

export let loan_type_options = [
  'TAKING',
  'GIVING',
  'ALREADY_TAKEN',
  'ALREADY_GIVEN',
];
export let payment_type = ['Cheque', 'Cash/Bank/Mobile Banking'];
