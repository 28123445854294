import { PrinterOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space, message } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useAppSelector } from '../../../app/hooks';
import { selectUser } from '../../../auth/states/userSlice';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import {
  DateInput,
  FormButton,
} from '../../../components/common/FormItem/FormItems';
import { SelectClients } from '../../../components/common/FormItem/SelectCustomFeilds';
import {
  useGetSingleQuotationQuery,
  usePostGenerateInvoiceMutation,
  useSaveAccumulatedInvoiceMutation,
} from '../Api/Endpoints/QuotationEndpoints';
import GenerateInvoiceFlightAndPaxView from '../Components/GenerateInvoiceFlightAndPaxView';
import SelectAllInvoices from '../Components/SelectAllInvoices';
import ViewGenerateInvoice, {
  invoiceViewStyle,
} from '../Components/ViewGenerateInvoice';
import { AllInvoiceType } from '../QuotationTypes/QuotationTypes';
import { useGetInvoiceNoQuery } from '../../Invoice(Visa)/Api/Endpoints/invoiceVisaEndpoints';

const GenerateInvoice = () => {
  const [form] = Form.useForm();
  const user = useAppSelector(selectUser);
  const [specificInvoice, setSpecificInvoice] = useState<AllInvoiceType[]>([]);
  const [clientId, setClientId] = useState<string>();
  const [date, setDate] = useState<string>();
  const [clinetGName, setGClientName] = useState<any>();
  const [currentDiscount, setCurrentDiscount] = useState<number>();
  const [currentPayment, setCurrentPayment] = useState<number>();
  const [saveAccumulated, setAccumulated] = useState<{
    q_number: string;
    sales_date: string;
    comb_client: string;
    discount: number;
    payment: number;
    invoices: {
      invoices_id: number;
      category_id: number;
    }[];
  }>();
  const [postGenerateInvoice, { data, isSuccess, isError }] =
    usePostGenerateInvoiceMutation();
  const [
    saveAccumulatedInvoice,
    { data: sAccData, isSuccess: sAccSuc, isError: sAccErr },
  ] = useSaveAccumulatedInvoiceMutation();
  console.log(sAccErr, sAccSuc);
  const header_ref = React.useRef<HTMLDivElement>(null);
  const signiture_ref = React.useRef<HTMLDivElement>(null);
  const subtotal_ref = React.useRef<HTMLDivElement>(null);
  const selectedPrintableRef = React.useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => selectedPrintableRef.current,
  });
  const onFinish = async (value: {
    client_id: string;
    invoice_id: number[];
    date: any;
    q_number: string;
  }) => {
    const date = dayjs(value.date).format('DD-MMM-YYYY');
    setDate(date);
    const filteredIds = specificInvoice
      .filter((item) => value?.invoice_id?.includes(item.invoice_id))
      .map((item) => ({
        category_id: item.category_id,
        invoices_id: item.invoice_id,
      }));
    setAccumulated({
      discount: currentDiscount as number,
      payment: currentPayment as number,
      q_number: value.q_number,
      sales_date: value.date,
      invoices: filteredIds,
      comb_client: value.client_id,
    });
    await postGenerateInvoice({ body: filteredIds });
  };
  const [showFlightDetails, setShowFlightDetails] = useState(true);
  const { data: quatation } = useGetInvoiceNoQuery('QT');
  useEffect(() => {
    form?.setFieldValue('q_number', quatation?.data);
  }, [quatation]);

  const saveAccumulatedInvoices = () => {
    if (saveAccumulated) {
      saveAccumulatedInvoice({
        body: {
          ...saveAccumulated,
          discount: currentDiscount as number,
          payment: currentPayment as number,
        },
      });
    }
  };

  useEffect(() => {
    if (sAccData?.data) {
      message.success('Successfully Saved Accumulated Invoice');
    } else if (sAccErr) {
      message.error('Something went wrong');
    }
  }, [sAccErr, sAccErr, sAccData]);
  return (
    <div>
      <BreadCrumb arrOfOption={['Generate Invoice']} />

      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row
          justify={'center'}
          gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
          className='border p-5 my-20'
        >
          <Col lg={5}>
            <Form.Item label='Quotation No :' name='q_number'>
              <Input readOnly />
            </Form.Item>
          </Col>
          <SelectClients
            name='client_id'
            label='Select Client'
            required
            size={5}
            onChange={(e) => {
              setClientId(e as string);
              form.setFieldValue('invoice_id', undefined);
            }}
            setGClientName={setGClientName}
            offDropDown
          />

          <SelectAllInvoices
            name='invoice_id'
            label='Select Invoice'
            clientID={clientId as string}
            size={5}
            required
            setSpecificInvoice={setSpecificInvoice}
          />

          <DateInput label='Select Date' required name={'date'} size={5} />
          <FormButton label='Submit' textAlign='left' />
        </Row>

        {data?.data ? (
          <Row justify={'center'} style={invoiceViewStyle}>
            <div>
              <Row justify={'space-between'}>
                <Space>
                  <Col className='commonBox' style={{ marginBottom: '15px' }}>
                    <Button
                      onClick={handlePrint}
                      icon={<PrinterOutlined />}
                      type='primary'
                    >
                      Print
                    </Button>
                  </Col>

                  <Col className='commonBox' style={{ marginBottom: '15px' }}>
                    <Button
                      onClick={() => setShowFlightDetails(!showFlightDetails)}
                      icon={<PrinterOutlined />}
                      type='primary'
                    >
                      {!showFlightDetails
                        ? 'ShowFlightDetails'
                        : 'HideFlightDetails'}
                    </Button>
                  </Col>
                </Space>
                <Col className='commonBox' style={{ marginBottom: '15px' }}>
                  <Button onClick={saveAccumulatedInvoices} type='primary'>
                    Save
                  </Button>
                </Col>
              </Row>
              <div ref={selectedPrintableRef}>
                <ViewGenerateInvoice
                  heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
                  clientGIName={clinetGName?.children?.split('-')[0]}
                  date={date}
                  orgName={user?.organization_info?.org_name}
                  salesBy={user?.user_full_name}
                  allGenerateInvoice={data?.data}
                  setCurrentDiscount={setCurrentDiscount}
                  setCurrentPayment={setCurrentPayment}
                  children={
                    <GenerateInvoiceFlightAndPaxView
                      heightRefs={{
                        header_ref,
                        signiture_ref,
                        subtotal_ref,
                      }}
                      flights={data?.data?.flight_details}
                      paxDetails={data?.data?.pax_details}
                      airticketBilling={data?.data?.air_ticket_billing}
                      otherBilling={data?.data?.other_billing}
                      showFlightDetails={showFlightDetails}
                    />
                  }
                />
              </div>
            </div>
          </Row>
        ) : (
          <></>
        )}
      </Form>
    </div>
  );
};

export default GenerateInvoice;
