import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';

import { message } from 'antd';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import {
  IChangePassword,
  IResetPasswordType,
} from '../../../../components/common/header/interface/headerTypes';
import {
  IRole,
  IUser,
  IUserDataType,
  IUserFormData,
  RoleFormData,
  UserResponse,
} from '../types/user.types';

const USER = 'USER';
export const userEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all roles
    getAllRoles: build.query<HTTPResponse<UserResponse[]>, void>({
      query: (body) => ({
        url: `/configuration/user/roles`,
        body,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'User', id: USER }],
    }),

    //get all roles with pagination
    getAllRolesWithPagination: build.query<
      HTTPResponse<UserResponse[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `configuration/user/all-roles?page=${arg.current}&size=${arg.pageSize}`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'User', id: USER }],
    }),

    //get all users
    getAllUsers: build.query<HTTPResponse<IUserDataType[]>, void>({
      query: () => ({ url: `/configuration/user/all` }),
      providesTags: () => [{ type: 'User', id: USER }],
    }),

    //get all users with pagination

    getAllUsersWithPagination: build.query<
      HTTPResponse<IUserDataType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/configuration/user/?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'User', id: USER }],
    }),
    //get user by id

    getUserById: build.query<HTTPResponse<IUser>, number>({
      query: (id) => ({ url: `/configuration/user/all/${id}` }),
      providesTags: () => [{ type: 'User', id: USER }],
    }),
    //GET ROLE BY ID

    getRoleByID: build.query<HTTPResponse<IRole>, number>({
      query: (id) => ({ url: `/configuration/user/roles/${id}` }),
      providesTags: () => [{ type: 'User', id: USER }],
    }),

    //add role
    addRole: build.mutation<HTTPResponse<{ role_id: number }>, RoleFormData>({
      query: (body) => ({
        url: `/configuration/user/roles`,
        body,
        method: 'POST',
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await queryFulfilled;
        message.success('Role created successfully');
      },
      invalidatesTags: () => [{ type: 'User', id: USER }],
    }),

    //create user
    createUser: build.mutation<void, IUserFormData>({
      query: (body) => ({
        url: `/configuration/user/create-user`,
        method: 'POST',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('User Created Successfully');
        });
      },
      invalidatesTags: () => [{ type: 'User', id: USER }],
    }),

    //UPDATE USER
    updateUser: build.mutation<void, { body: IUserFormData; id: string }>({
      query: ({ body, id }) => ({
        url: `/configuration/user/update-user/${id}`,
        method: 'PATCH',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          message.success('User has been updated successfully');
        });
      },
      invalidatesTags: () => [{ type: 'User', id: USER }],
    }),

    //DELETE USER
    deleteUser: build.mutation<void, string>({
      query: (id) => ({
        url: `/configuration/user/delete-user/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          message.success('User has been deleted');
        });
      },
      invalidatesTags: () => [{ type: 'User', id: USER }],
    }),
    //DELETE USER
    deleteRole: build.mutation<void, string>({
      query: (id) => ({
        url: `/configuration/user/roles/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          message.success('User role has been deleted');
        });
      },
      invalidatesTags: () => [{ type: 'User', id: USER }],
    }),

    //change user password
    changeUserPass: build.mutation<void, IChangePassword>({
      query: (body) => ({
        url: `/configuration/user/change-password/${body.user_id}`,
        method: 'PUT',
        body: body,
      }),
      transformErrorResponse: (response) => {
        if (response.status === 400 && response.data) {
          const { message } = response.data as {
            message: string;
            success: boolean;
          };
          toasterNotification('error', `${message}`);
        } else {
          message.error('User name or password is incorrect!');
        }
      },
      invalidatesTags: () => [{ type: 'User', id: USER }],
    }),

    //reset user password
    resetUserPassword: build.mutation<void, IResetPasswordType>({
      query: (body) => ({
        url: `/configuration/user/reset-user-password/${body.user_id}`,
        method: 'POST',
        body: body,
      }),

      transformErrorResponse: (response) => {
        if (response.status === 400 && response.data) {
          const { message } = response.data as {
            message: string;
            success: boolean;
          };
          toasterNotification('error', `${message}`);
        } else {
          message.error('User name or password is incorrect!');
        }
      },

      invalidatesTags: () => [{ type: 'User', id: USER }],
    }),

    // Check Agency Username Available or Not
    checkAgencyUsername: build.query<HTTPResponse<boolean>, string>({
      query: (arg) => ({
        url: `/agency-admin/isunique/username?search_text=${arg}`,
      }),
      providesTags: () => [{ type: 'User', id: USER }],
    }),
    // @ Check Role name is unique
    roleNameIsUnique: build.query<HTTPResponse<{ is_uniqe: boolean }>, string>({
      query: (arg) => ({
        url: `configuration/user/roles/is-exist/${arg}`,
      }),
      providesTags: () => [{ type: 'User', id: USER }],
    }),
  }),
});

export const {
  useAddRoleMutation,
  useCreateUserMutation,
  useResetUserPasswordMutation,
  useGetAllRolesQuery,
  useGetAllUsersQuery,
  useGetUserByIdQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useLazyCheckAgencyUsernameQuery,
  useGetAllUsersWithPaginationQuery,
  useGetAllRolesWithPaginationQuery,
  useGetRoleByIDQuery,
  useDeleteRoleMutation,
  useLazyRoleNameIsUniqueQuery,
  useChangeUserPassMutation,
} = userEndpoints;
