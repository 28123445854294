import { message } from 'antd';
import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { expire } from '../../../../common/utils/constants';
import {
  AllInvoiceType,
  IGenerateFlightAndPax,
  IQuotationAddFormData,
  IQuotationBillInfo,
  IQuotationDataType,
  IQuotationEditFormData,
  IQuotationSingleDataType,
  ISaveAccumulatedInvoices,
  ISingleAccumulatedQuotation,
} from '../../QuotationTypes/QuotationTypes';
import { QUOTATION, Quotation_LOGS } from '../Constants/Constants';
import { IInvoiceOtherFormattedValueType } from '../../../Invoice(Other)/Type/Invoce.other.interface';
import { INVALIDATE_CLIENT_VENDOR_LEDGER } from '../../../Reports/Api/constants';
import { IPaginationSD } from '../../../../common/types/commonInterfaces';

export const quotationEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all quotation
    getAllQuotation: build.query<
      HTTPResponse<IQuotationDataType[]>,
      IPaginationSD
    >({
      query: (arg) => ({
        url: `/quotation?${arg.current}&size=${arg.pageSize}&from_date=${arg.from}&to_date=${arg.to}&search=${arg.search}`,
      }),
      providesTags: [{ type: 'Quotation', id: QUOTATION }],
    }),

    // get all trashed quotation
    getAllTrashedQuotation: build.query<
      HTTPResponse<IQuotationDataType[]>,
      void
    >({
      query: () => ({ url: `/quotation?trash=1` }),
      providesTags: [{ type: 'Quotation', id: QUOTATION }],
    }),

    // get single quotation bill-info
    getBillInfo: build.query<HTTPResponse<IQuotationBillInfo[]>, number>({
      query: (id) => ({ url: `/quotation/bill-infos/${id}` }),
      providesTags: [{ type: 'Quotation', id: QUOTATION }],
    }),

    // get single quotation by quotation id
    getSingleQuotation: build.query<
      HTTPResponse<IQuotationSingleDataType>,
      number
    >({
      query: (id) => ({ url: `/quotation/${id}` }),
      keepUnusedDataFor: expire.min,
      providesTags: (_res, _err, id) => [
        { type: 'Quotation', id: id } as const,
      ],
    }),
    //create quotation
    createQuotation: build.mutation<
      HTTPResponse<{ quotationId: number }>,
      IQuotationAddFormData
    >({
      query: (body) => ({
        url: '/quotation',
        method: 'POST',
        body: body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Quotation Created Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Quotation', id: QUOTATION },
        { type: 'Quotation', id: Quotation_LOGS },
      ],
    }),

    //edit quotation
    editQuotation: build.mutation<
      void,
      { id: number; body: IQuotationEditFormData }
    >({
      query: (body) => ({
        url: `/quotation/${body.id}`,
        method: 'PATCH',
        body: body.body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Quotation Edited Successfully');
        });
      },
      invalidatesTags: (_res, _err, { id }) => [
        { type: 'Quotation', id: QUOTATION },
        { type: 'Quotation', id },
        { type: 'Quotation', id: 'LOGS' },
      ],
    }),

    // Quotation confirmation
    postQuotationConfirmation: build.mutation<
      HTTPResponse<any>,
      { body: IInvoiceOtherFormattedValueType; quotation_id: number }
    >({
      query: ({ body, quotation_id }) => ({
        url: `/quotation/${quotation_id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_res, _err, { quotation_id }) => [
        { type: 'invoice-other' },
        { type: 'invoices' },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        { type: 'Quotation', id: QUOTATION },
        { type: 'Quotation', quotation_id },
        { type: 'Quotation', id: 'LOGS' },
      ],
    }),

    //delete quotation
    deleteQuotation: build.mutation<
      void,
      { quotation_id: number; quotation_deleted_by: number }
    >({
      query: (body) => ({
        url: `/quotation/${body.quotation_id}`,
        method: 'DELETE',
        body: body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Quotation Deleted Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Quotation', id: QUOTATION },
        { type: 'Quotation', id: Quotation_LOGS },
      ],
    }),

    //restore quotation
    restoreQuotation: build.mutation<
      void,
      { quotation_id: number; quotation_deleted_by: number }
    >({
      query: (body) => ({
        url: `/quotation/restore/${body.quotation_id}`,
        method: 'PATCH',
        body: body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Quotation Restored Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Quotation', id: QUOTATION },
        { type: 'Quotation', id: Quotation_LOGS },
      ],
    }),

    getAllInVoiceByClientID: build.query<
      HTTPResponse<AllInvoiceType[]>,
      number | string
    >({
      query: (id) => ({ url: `/quotation/invoices?client=${id}` }),
      providesTags: [{ type: 'GenerateInvoice' }],
    }),

    postGenerateInvoice: build.mutation<
      HTTPResponse<IGenerateFlightAndPax>,
      { body: { invoices_id: number; category_id: number }[] }
    >({
      query: ({ body }) => ({
        url: `/quotation/get-billing`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_res, _err) => [{ type: 'GenerateInvoice' }],
    }),
    saveAccumulatedInvoice: build.mutation<
      HTTPResponse<any>,
      { body: ISaveAccumulatedInvoices }
    >({
      query: ({ body }) => ({
        url: `/quotation/accumulate`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_res, _err) => [{ type: 'GenerateInvoice' }],
    }),
    getSingleAccumulateQuotation: build.query<
      HTTPResponse<ISingleAccumulatedQuotation>,
      number
    >({
      query: (id) => ({ url: `/quotation/accumulate/${id}` }),

      providesTags: (_res, _err, id) => [{ type: 'Quotation' }],
    }),
  }),
});

export const {
  useGetAllQuotationQuery,
  useLazyGetAllTrashedQuotationQuery,
  useGetSingleQuotationQuery,
  useLazyGetSingleQuotationQuery,
  useGetBillInfoQuery,
  useCreateQuotationMutation,
  useEditQuotationMutation,
  useDeleteQuotationMutation,
  useRestoreQuotationMutation,
  usePostQuotationConfirmationMutation,
  useLazyGetAllInVoiceByClientIDQuery,
  usePostGenerateInvoiceMutation,
  useSaveAccumulatedInvoiceMutation,
  useGetSingleAccumulateQuotationQuery,
} = quotationEndpoints;
