import { Button, Popconfirm, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { IPermission, ISetState } from '../../../common/types/commonTypes';
import CheckImageOrPdf from '../../../common/utils/CheckImageOrPdf';
import { storageBaseUrl } from '../../../common/utils/common.utils';
import { IPassportDataType } from '../PassportTypes/PassportTypes';

export const PassportUtils = (
  setFile: ISetState<string | null>,
  showModal: () => void,
  permission: IPermission | undefined,
  handleDelete: (value: number) => void,
  deleteLoading: boolean,
  pagination: {
    current: number;
    pageSize: number;
  },
  setStatusInfo: ISetState<IPassportDataType | null>
): ColumnsType<IPassportDataType> => {
  const handleClick = (record: IPassportDataType) => {
    console.log(record);
    setStatusInfo(record);
    showModal();
  };
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination?.current || 1) - 1) * (pagination?.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Created Date',
      key: 'passport_create_date',
      align: 'center',
      render: (_, data) => (
        <>{dayjs(data.passport_create_date).format('DD MMM YYYY')} </>
      ),
    },
    {
      title: 'Passport No',
      dataIndex: 'passport_passport_no',
      key: 'passport_passport_no',
    },
    {
      title: 'Name',
      dataIndex: 'passport_name',
      key: 'passport_name',
    },
    {
      title: 'Mobile No',
      dataIndex: 'passport_mobile_no',
      key: 'passport_mobile_no',
    },
    /*  {
      title: 'Date Of Birth',
      key: 'date_of_birth',
      render: (_, record) => {
        return (
          record.passport_date_of_birth &&
          `${dayjs(record.passport_date_of_birth).format('DD MMM YYYY')}`
        );
      },
    },
    {
      title: 'Date Of Issue',
      key: 'date_of_issue',
      render: (_, record) => {
        return (
          record.passport_date_of_issue &&
          `${dayjs(record.passport_date_of_issue).format('DD MMM YYYY')}`
        );
      },
    }, */
    {
      title: 'Date Of Expire',
      key: 'date_of_expire',
      render: (_, record) => {
        return (
          record.passport_date_of_expire &&
          `${dayjs(record.passport_date_of_expire).format('DD MMM YYYY')}`
        );
      },
    },
    {
      title: 'Remaining',
      key: 'passport_date_of_expire',
      render: (_, record) => {
        const passportExpiryStatus = record?.passport_date_of_expire
          ? dayjs(record.passport_date_of_expire).diff(
              dayjs(record.passport_date_of_issue),
              'day'
            )
          : '';

        return passportExpiryStatus
          ? passportExpiryStatus + ' Days'
          : 'Expired';
      },
    },
    /*     {
      title: 'Email',
      dataIndex: 'passport_email',
      key: 'passport_email',
    }, */

    {
      title: 'Scan Copy',
      key: 'scan_copy',
      align: 'center',
      render: (_, data) => {
        return data.passport_scan_copy ? (
          <CheckImageOrPdf url={storageBaseUrl + data.passport_scan_copy} />
        ) : (
          <></>
        );
      },
    },

    /*     {
      title: 'Others Doc',
      key: 'scan_copy',
      align: 'center',
      render: (_, data) => {
        return data.passport_upload_others ? (
          <CheckImageOrPdf url={storageBaseUrl + data.passport_upload_others} />
        ) : (
          <></>
        );
      },
    }, */

    {
      title: 'Image',
      key: 'scan_copy',
      align: 'center',
      render: (_, data) =>
        data.passport_upload_photo ? (
          <CheckImageOrPdf url={storageBaseUrl + data.passport_upload_photo} />
        ) : (
          <></>
        ),
    },
    {
      title: 'Passport Status',
      key: 'pstatus_name',
      dataIndex: 'pstatus_name',
      align: 'center',
    },

    {
      title: 'Action',
      key: 'action',
      render: (_, record, index) => (
        <Space>
          <Button
            disabled={!permission?.['update:any']}
            type='primary'
            size='small'
            onClick={() => handleClick(record)}
          >
            Change Status
          </Button>
          {permission?.['update:any'] && (
            <Link
              to={`/passport/edit/${record.passport_id}`}
              state={location.pathname}
            >
              <Button onClick={showModal} type='primary' size='small'>
                Edit
              </Button>
            </Link>
          )}
          {permission?.['delete:any'] && (
            <Button size='small' danger type='primary'>
              <Popconfirm
                title='Sure to delete?'
                onConfirm={() => handleDelete(record.passport_id)}
              >
                Delete
              </Popconfirm>
            </Button>
          )}
        </Space>
      ),
    },
  ];
};
