import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { Fixed2 } from '../../../common/utils/common.utils';
import {
  ClLastBalType,
  useNotClientLastBalQuery,
} from '../api/not_visible_api';

type Props = {};

const Not_Visible_Cl = (props: Props) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const { data } = useNotClientLastBalQuery({
    page: pagination.current,
    size: pagination.pageSize,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const column: ColumnsType<ClLastBalType> = [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Client ID',
      key: 'client_id',
      dataIndex: 'client_id',
    },
    {
      title: 'Entry ID',
      dataIndex: 'client_entry_id',
      key: 'client_entry_id',
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
    },

    {
      title: 'Client Last Balance',
      dataIndex: 'client_lbalance',
      key: 'client_lbalance',
      align: 'left',
      render: (curr) => Fixed2(curr),
    },
    {
      title: 'Transaction Last Balance',
      dataIndex: 'ctrxn_lbalance',
      key: 'ctrxn_lbalance',
      align: 'left',
    },
  ];

  return (
    <Table
      dataSource={data?.data}
      columns={column}
      size='small'
      bordered
      pagination={
        data?.count !== undefined && data?.count > 50
          ? {
              ...pagination,
              total: data?.count,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }
          : false
      }
    />
  );
};

export default Not_Visible_Cl;
