import { Table, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Fixed2, formatAmountAbs } from '../../../../common/utils/common.utils';
import { BSPBillCheck_Refund } from '../type/BSPType';

type Props = {
  bspData: BSPBillCheck_Refund[] | undefined;
  isLoading: boolean;
};

const BSP_Refund = ({ bspData, isLoading }: Props) => {
  const calculateRowSpan = (
    data: BSPBillCheck_Refund[],
    ticket_sl: string | number,
    rowIndex: number | undefined
  ) => {
    const firstIndex = data.findIndex((item) => item.sl === ticket_sl);
    const lastIndex = data.map((item) => item.sl).lastIndexOf(ticket_sl as any);
    if (firstIndex === rowIndex) {
      return lastIndex - firstIndex + 1;
    }
    return 0;
  };

  const refundMap = new Map();

  bspData?.forEach((item, index) => {
    // Populate refundMap
    if (!refundMap.has(item.sl)) {
      refundMap.set(item.sl, []);
    }
    refundMap.get(item.sl).push(Math.abs(Fixed2(item.return_amount)));
  });

  // Create a new array with additional properties
  const formattedData = bspData?.map((item, index) => {
    const rowSpan = calculateRowSpan(bspData, item.sl, index);
    const prices = refundMap.get(item.sl);

    const return_difference =
      prices.length > 1 ? Math.max(...prices) - Math.min(...prices) : 0;

    return {
      ...item,
      rowSpan,
      return_difference,
    };
  });

  const columns: ColumnsType<BSPBillCheck_Refund> = [
    {
      title: 'Sl No',
      dataIndex: 'sl',
      key: 'sl',
      align: 'center',
      rowScope: 'row',
      onCell: (record, rowIndex) => {
        return {
          rowSpan: record?.rowSpan,
          className: record.rowSpan === 1 ? 'singeTableRow' : 'white',
        };
      },
    },
    {
      title: 'Ticket',
      dataIndex: 'ticket_no',
      key: 'ticket_no',
      align: 'center',
      rowScope: 'row',
      onCell: (record, rowIndex) => {
        return {
          rowSpan: record?.rowSpan,
          className: record.rowSpan === 1 ? 'singeTableRow' : 'white',
        };
      },

      render: (curr, data, index) => {
        if (data.refund_type === 'PARTIAL') {
          return (
            <Link to={`/refund/partial/view/${data?.refund_id}`}>{curr}</Link>
          );
        } else if (data.refund_type === 'AIRTICKET') {
          return (
            <Link to={`/refund/airticket/details/${data?.refund_id}`}>
              {curr}
            </Link>
          );
        } else if (data.refund_type === 'TAX') {
          return <Link to={`/refund/tax-view/${data?.refund_id}`}>{curr}</Link>;
        } else {
          return curr;
        }
      },
    },

    {
      title: 'Refund Date',
      dataIndex: 'date',
      key: 'date',
      render: (curr) => dayjs(curr).format('DD-MMM-YYYY'),
    },
    {
      title: 'Return Amount',
      dataIndex: 'return_amount',
      key: 'return_amount',
      render: (curr) => (
        <span style={{ color: '#219C90' }}>{formatAmountAbs(curr)}</span>
      ),
      align: 'right',
    },
    {
      title: 'Difference Amount',
      dataIndex: 'return_difference',
      key: 'return_difference',
      rowScope: 'row',
      align: 'center',
      onCell: (record, rowIndex) => {
        return {
          rowSpan: record?.rowSpan,
          className: record.rowSpan === 1 ? 'singeTableRow' : 'white',
        };
      },
      render(value, record, index) {
        if (record.rowSpan === 1) {
          return '❌';
        }
        return (
          <Typography.Text style={{ color: 'red' }}>
            {value ? value : '✅'}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      render: (curr) => {
        if (curr === 'IATA')
          return (
            <Tag color='blue' style={{ width: 60, textAlign: 'center' }}>
              IATA
            </Tag>
          );
        if (curr === 'DB')
          return (
            <Tag color='#007bff' style={{ width: 60, textAlign: 'center' }}>
              TRABILL
            </Tag>
          );
      },
    },
  ];
  return (
    <div className='bsp-table'>
      <Table
        rowClassName={(record, index) => {
          const { type } = record;
          let className = '';

          if (type === 'IATA') {
            className += 'IATA_BILL ';
          }

          if (record.rowSpan === 1) {
            className += 'singeTableRow';
          }

          return className.trim();
        }}
        // loading={{
        //   spinning: isLoading,
        //   indicator: loadingIndicator,
        // }}
        columns={columns}
        dataSource={formattedData}
        pagination={false}
        bordered
        size='small'
        className='invoiceBillingTable'
      />
    </div>
  );
};

export default BSP_Refund;
