import { ColumnsType } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { formatAmount } from '../../../../common/utils/common.utils';
import { IGDSReportsDataType } from '../types/GdsReportType';

const gdsReportColumn = (
  paramData:
    | {
        date_range: string;
        id: number | string;
      }
    | undefined,
  pagination: {
    current: number;
    pageSize: number;
  }
): ColumnsType<IGDSReportsDataType> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
      width: 50,
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'GDS Name',
      dataIndex: 'gds_name',
      key: 'gds_name',
      width: 50,
    },
    {
      title: 'Ticket No',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
      width: 50,
      render: (_, record) => (
        <Link
          to={`/reports/ticket_wise?${paramData?.date_range}&airticket_id=${record.airticket_id}`}
        >
          {record.airticket_ticket_no}
        </Link>
      ),
    },
    {
      title: 'Airline Name',
      dataIndex: 'airline_name',
      key: 'airline_name',
      width: 50,
    },
    {
      title: 'Route',
      dataIndex: 'airline_iata_code',
      key: 'airline_iata_code',
      width: 50,
    },
    {
      title: 'PNR',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
      width: 50,
    },
    {
      title: 'Fly Date',
      dataIndex: 'airticket_journey_date',
      key: 'airticket_journey_date',
      width: 50,
      render: (curr, record) => {
        return `${
          dayjs(curr).isValid() ? dayjs(curr).format('DD MM YYYY') : 'N/A'
        }`;
      },
    },
    {
      title: 'Gross Fare',
      dataIndex: 'airticket_gross_fare',
      key: 'airticket_gross_fare',
      width: 50,
      render: (curr) => formatAmount(curr),
      align: 'right',
    },
    {
      title: 'Commission',
      dataIndex: 'airticket_commission_percent_total',
      key: 'airticket_commission_percent_total',
      width: 50,
      render: (curr) => formatAmount(curr),
      align: 'right',
    },
    {
      title: 'Total Segment',
      dataIndex: 'airticket_segment',
      key: 'airticket_segment',
      width: 50,
      align: 'center',
    },
  ];
};

export default gdsReportColumn;
