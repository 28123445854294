import { FormListFieldData, Row } from 'antd';
import { FormInstance, useWatch } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import {
  DateInput,
  FormInputItem,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import {
  SelectRoomType,
  SelectVendors,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import { useLazyGetItineraryVendorsInfoQuery } from '../../../Configuration/Tour/Ticket/endpoints/tourTicketEndpoints';
import SelectTourAccommodation from '../Components/SelectTourAccommodation';
import { useParams } from 'react-router';

type Props = {
  field: FormListFieldData;
  form: FormInstance<any>;
  invComClientInfo: string | undefined;
  index: number;
};

const AccommodationForm = ({ field, form, invComClientInfo, index }: Props) => {
  const { id } = useParams();
  //setField vendor name
  const [instanceVendorSelect, setInstanceVendorSelect] = useState<number>();
  const [instanceAccommodationSelect, setInstanceAccommodationSelect] =
    useState<number>();
  /* if any field select other field are auto required */
  const accm_itinerary_id: number | undefined = useWatch(
    ['tourAccms', field.name, 'accm_itinerary_id'],
    form
  );
  const accm_cost_price = useWatch(
    ['tourAccms', field.name, 'accm_cost_price'],
    form
  );
  const accm_description = useWatch(
    ['tourAccms', field.name, 'accm_description'],
    form
  );
  const accm_comvendor_id = useWatch(
    ['tourAccms', field.name, 'accm_comvendor_id'],
    form
  );

  const accm_room_type_id = useWatch(
    ['tourAccms', field.name, 'accm_room_type_id'],
    form
  );

  const accm_place = useWatch(['tourAccms', field.name, 'accm_place'], form);

  const accm_num_of_room = useWatch(
    ['tourAccms', field.name, 'accm_num_of_room'],
    form
  );

  const accm_checkin_date = useWatch(
    ['tourAccms', field.name, 'accm_checkin_date'],
    form
  );

  const accm_checkout_date = useWatch(
    ['tourAccms', field.name, 'accm_checkout_date'],
    form
  );

  const requiredField =
    accm_cost_price ||
    accm_description ||
    accm_itinerary_id ||
    accm_room_type_id ||
    accm_place ||
    accm_num_of_room ||
    accm_checkin_date ||
    accm_checkout_date;
  // itinerary
  const [itineraryId, setItineraryId] = useState<number | undefined>();
  const [vendorId, setVendorId] = useState<number>();
  const [getItineraryVendorsInfo, { data: vendors, isFetching, isSuccess }] =
    useLazyGetItineraryVendorsInfoQuery();
  const [isRequired, setIsRequired] = useState<boolean>(false);
  useEffect(() => {
    if (accm_itinerary_id) {
      setIsRequired(true);
    } else {
      setIsRequired(false);
    }
  }, [accm_itinerary_id]);
  useEffect(() => {
    //this for when create tour food,its not work for editing
    if (!id) {
      const selectVendor = vendors?.data?.find(
        (item) => item.itnrvendor_vendor_id === vendorId
      );
      if (selectVendor?.itnrvendor_cost_price) {
        form.setFields([
          {
            name: ['tourAccms', field.name, 'accm_cost_price'],
            value: Number(selectVendor?.itnrvendor_cost_price),
          },
        ]);
      } else {
        form.setFields([
          {
            name: ['tourAccms', field.name, 'accm_cost_price'],
            value: undefined,
          },
        ]);
      }
    }
    //this only work for when editing
    if (
      (id && accm_comvendor_id && accm_itinerary_id && itineraryId) ||
      (id && accm_comvendor_id && accm_itinerary_id && vendorId)
    ) {
      const selectVendor = vendors?.data?.find(
        (item) =>
          item.itnrvendor_vendor_id ===
          Number(accm_comvendor_id?.toString().split('-')[1])
      );

      if (selectVendor?.itnrvendor_cost_price) {
        form.setFields([
          {
            name: ['tourAccms', field.name, 'accm_cost_price'],
            value: Number(selectVendor?.itnrvendor_cost_price),
          },
        ]);
      } else {
        form.setFields([
          {
            name: ['tourAccms', field.name, 'accm_cost_price'],
            value: undefined,
          },
        ]);
      }
    }
  }, [
    accm_itinerary_id,
    accm_comvendor_id,
    vendors?.data,
    vendorId,
    itineraryId,
  ]);

  useEffect(() => {
    if (accm_itinerary_id) {
      getItineraryVendorsInfo(Number(accm_itinerary_id));
    }
  }, [accm_itinerary_id]);
  //setField vendor name
  useEffect(() => {
    if (instanceVendorSelect) {
      form.setFieldValue(
        ['tourAccms', field.name, 'accm_comvendor_id'],
        'vendor-' + instanceVendorSelect
      );
    } else if (instanceAccommodationSelect) {
      form.setFieldValue(
        ['tourAccms', field.name, 'accm_itinerary_id'],
        instanceAccommodationSelect
      );
    }
  }, [instanceVendorSelect, instanceAccommodationSelect]);
  //-----------------------------
  const [disabled, setDisabled] = useState(false);
  const accm_is_deleted: 0 | 1 = useWatch(
    ['tourAccms', index, 'accm_is_deleted'],
    form
  );
  useEffect(() => {
    if (accm_is_deleted === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [accm_is_deleted]);
  return (
    <Row
      style={
        accm_is_deleted === 1
          ? {
              backgroundColor: '#FFC0CB',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }
          : { display: 'flex', alignItems: 'center', width: '100%' }
      }
      gutter={[5, 5]}
    >
      <SelectTourAccommodation
        name={[field.name, 'accm_itinerary_id']}
        onChange={setItineraryId}
        isRequired={requiredField}
        size={4}
        setInstanceAccommodationSelect={setInstanceAccommodationSelect}
      />

      <SelectRoomType
        name={[field.name, 'accm_room_type_id']}
        label='Room type	'
        size={4}
      />

      <FormInputItem
        label='No of Room '
        name={[field.name, 'accm_num_of_room']}
        size={4}
      />

      <FormInputItem
        label='Place '
        name={[field.name, 'accm_place']}
        size={4}
      />

      <FormInputItem
        label='Description'
        name={[field.name, 'accm_description']}
        size={4}
      />

      <NumberInput
        label='Cost Price'
        name={[field.name, 'accm_cost_price']}
        size={4}
        required={requiredField}
        min='0'
        maxChar={14}
      />

      <DateInput
        name={[field.name, 'accm_checkin_date']}
        label='Check In Date'
        size={4}
      />

      <DateInput
        name={[field.name, 'accm_checkout_date']}
        label='Check Out Date'
        size={4}
      />

      <SelectVendors
        label='Vendor'
        size={4}
        name={[field.name, 'accm_comvendor_id']}
        onChange={(e) => setVendorId(Number(e?.toString().split('-')[1]))}
        required={requiredField}
        dependencies={['invoice_combclient_id']}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value: string) {
              if (getFieldValue('invoice_combclient_id')) {
                if (getFieldValue('invoice_combclient_id') === value) {
                  return Promise.reject();
                } else {
                  return Promise.resolve();
                }
              }
              return Promise.resolve();
            },
            message: "Client and vendor can't be same!",
          }),
        ]}
        setInstanceVendorSelect={setInstanceVendorSelect}
      />
    </Row>
  );
};

export default AccommodationForm;
