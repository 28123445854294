import { Col, Form, Row, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../app/hooks';
import { selectUser } from '../../../../../auth/states/userSlice';
import { toasterNotification } from '../../../../../common/utils/ToasterNotification';
import {
  DateInput,
  FormButton,
  NumberInput,
  TextAreaInput,
} from '../../../../../components/common/FormItem/FormItems';
import SelectPaymentMethod from '../../../../../components/common/paymentMethod/SelectPaymentMethod';
import LoadingIndicator from '../../../../../components/common/spinner/LoadingIndicator';
import { useGetAccountByTypeQuery } from '../../../../vendor/api/endpoints/vendorPaymentEndpoints';
import AccountLastBalanceInput from '../../../../vendor/components/AccountLastBalanceInput';
import { setClientModal } from '../../../AccountsState/accountSlice';
import { IClientOpeningBalanceFormData } from '../../../AccountsTypes/AccountsTypes';
import { useCreateClientOpeningBalanceMutation } from '../../../Api/Endpoints/OpeningBalanceEndponts';
import Select_Client from '../../../components/Select_Client';

type Props = { setOpen: React.Dispatch<React.SetStateAction<boolean>> };

const Client_Opening_Balance_Add_Modal = ({ setOpen }: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const user = useSelector(selectUser);

  const [payMethodCatId, setPayMethodCatId] = useState<number | undefined>(1);
  const { data: accounts, refetch: refetchAccount } = useGetAccountByTypeQuery(
    Number(payMethodCatId)
  );

  useEffect(() => {
    form.setFieldValue('date', dayjs());
  }, []);
  const [addClientOpeningBalance, { isError, isLoading, isSuccess }] =
    useCreateClientOpeningBalanceMutation();

  const handleSubmit = async (values: IClientOpeningBalanceFormData) => {
    const body: IClientOpeningBalanceFormData = {
      ...values,
      date: dayjs(values.date).format('YYYY-MM-DD'),
      transaction_created_by: user?.user_id as number,
    };

    await addClientOpeningBalance(body);
    form.resetFields();
    dispatch(setClientModal(false));
  };
  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
    } else if (isError) {
      toasterNotification('error', 'Something went wrong');
    }
  }, [isError, isSuccess]);
  const opening_balance_type: 'due' | 'advance' = useWatch(
    'opening_balance_type',
    form
  );
  return (
    <Form onFinish={handleSubmit} layout='vertical' form={form}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Select_Client />
        <Col span={6} xs={24} sm={24} md={24} lg={12}>
          <Form.Item label='Payment Method' name='transaction_type'>
            <Select
              options={[
                { value: 'DEBIT', label: 'DEBIT' },
                { value: 'CREDIT', label: 'CREDIT' },
              ]}
              placeholder='Select Payment Method'
            />
          </Form.Item>
        </Col>
        <NumberInput
          name='amount'
          label='Amount'
          required
          size={12}
          min='0'
          maxChar={14}
        />

        {opening_balance_type === 'advance' && (
          <>
            <SelectPaymentMethod
              size={12}
              required
              name='opening_payment_type'
              label='Payment Method'
              onChange={(value: number) => {
                form.setFieldValue('opening_account_id', undefined);
                setPayMethodCatId(value);
              }}
            />
            <AccountLastBalanceInput
              size={12}
              required
              accountsLastBalance={accounts ? accounts.data : []}
              name='opening_account_id'
              label='Account:'
            />
          </>
        )}

        <DateInput name='date' label='Date' size={12} />

        <TextAreaInput name='note' label='Note' size={24} />
      </Row>
      <FormButton label='Add Client Bill Adjustment' loading={isLoading} />
      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default Client_Opening_Balance_Add_Modal;
