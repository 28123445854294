import { Col, Form, InputNumber, Select } from 'antd';
import { useEffect, useState } from 'react';
import {
  useLazyGetAllCombClientsQuery,
  useLazyGetClientsBySearchQuery,
  useLazyGetSingleClientsQuery,
} from '../../Client/Client/api/endpoints/clientEndpoints';

import { useLazyGetClLastBalanceQuery } from '../../Client/Client/api/endpoints/clientEndpoints';

type Props = {
  show_balance?: boolean;
  setBalance?: (arg: number) => void;
  size?: number;
  mdSize?: number;
  smSize?: number;
  name?: string;
  label?: React.ReactNode;
  disabled?: boolean;
};

// Only Client
function Select_Client({
  show_balance,
  setBalance,
  size,
  name,
  label,
  smSize,
  mdSize,
}: Props) {
  const [clientID, setClientID] = useState<string>();

  const [fetchClientByID, { data: singleClient }] =
    useLazyGetSingleClientsQuery();

  const [fetchClient, { data: clients, isLoading }] =
    useLazyGetClientsBySearchQuery();

  let timeoutId: NodeJS.Timeout | null = null;

  const handleSearch = (value: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      fetchClient(value);
    }, 300);
  };

  useEffect(() => {
    fetchClient('');
  }, []);

  let balance = 0;

  if (singleClient?.data?.client_lbalance) {
    if (Number(singleClient.data.client_lbalance) < 0) {
      balance = Number(singleClient.data.client_lbalance) * -1;
    } else {
      balance = Number(singleClient?.data?.client_lbalance);
    }
  }
  useEffect(() => {
    setBalance && setBalance(balance);
  }, [singleClient]);

  return (
    <>
      <Col
        span={6}
        xs={24}
        sm={smSize ? smSize : 24}
        md={mdSize ? mdSize : 24}
        lg={size || 12}
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: `Client is required!`,
            },
          ]}
          name={name || 'client_id'}
          label={label === false ? false : label || 'Client'}
        >
          <Select
            showSearch
            loading={isLoading}
            onSearch={handleSearch}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                ?.toLowerCase()
                .includes(input?.toLowerCase())
            }
            placeholder={`Select Client`}
            onSelect={(e: string) => {
              setClientID(e);
            }}
            allowClear
          >
            {clients &&
              clients.data?.map((item, index) => {
                return (
                  <Select.Option value={item.client_id} key={index}>
                    {item.client_name}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
      </Col>
      {show_balance && (
        <Col span={6} xs={24} sm={24} md={24} lg={size || 12}>
          <Form.Item
            required
            name={'due_advance'}
            label={
              singleClient?.data?.client_lbalance &&
              Number(singleClient?.data?.client_lbalance) >= 0
                ? 'Present Balance: '
                : 'Present Due'
            }
          >
            <InputNumber
              disabled
              value={
                singleClient?.data?.client_lbalance &&
                Number(singleClient?.data?.client_lbalance) < 0
                  ? Number(singleClient?.data?.client_lbalance) * -1
                  : singleClient?.data?.client_lbalance
              }
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      )}
    </>
  );
}

export default Select_Client;

// Client with Combine

export function Select_Client_And_Combined({
  show_balance,
  setBalance,
  size,
  name,
  label,
  disabled,
}: Props) {
  const [clientID, setClientID] = useState<string>();

  const [fetchComClient, { data: clients }] = useLazyGetAllCombClientsQuery();

  let timeoutId: NodeJS.Timeout | null = null;
  const handleSearch = (value: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      if (value !== '') fetchComClient(value, true);
    }, 300);
  };

  useEffect(() => {
    fetchComClient('', true);
  }, []);

  const [fetchClientBalanceByID, { data: singleClient }] =
    useLazyGetClLastBalanceQuery();

  let balance = 0;

  useEffect(() => {
    if (clientID) {
      fetchClientBalanceByID(clientID);
    }
    setClientID(undefined);
  }, [clientID]);

  if (singleClient?.data?.client_last_balance) {
    if (Number(singleClient.data.client_last_balance) < 0) {
      balance = Number(singleClient.data.client_last_balance);
    } else {
      balance = Number(singleClient?.data?.client_last_balance);
    }
  }
  useEffect(() => {
    setBalance && setBalance(balance);
  }, [singleClient]);

  return (
    <>
      <Col span={6} xs={24} sm={24} md={24} lg={size || 12}>
        <Form.Item
          rules={[
            {
              required: true,
              message: `Client is required!`,
            },
          ]}
          name={name || 'client_id'}
          label={label || 'Client'}
        >
          <Select
            disabled={disabled}
            placeholder={`Select Client`}
            onSelect={(e) => {
              setClientID(e);
            }}
            allowClear
            showSearch
            onSearch={handleSearch}
            optionFilterProp='roleMobile'
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                ?.toLowerCase()
                .includes(input?.toLowerCase())
            }
          >
            {clients &&
              clients.data?.map((item, index) => {
                return (
                  <Select.Option
                    value={`${item.client_type}-${item.client_id}`}
                    key={index}
                  >
                    {item.client_name}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
      </Col>
      {show_balance && (
        <Col span={6} xs={24} sm={24} md={24} lg={size || 12}>
          <Form.Item
            required
            name={'due_advance'}
            label={
              singleClient?.data?.client_last_balance &&
              Number(singleClient?.data?.client_last_balance) >= 0
                ? 'Present Balance: '
                : 'Present Due'
            }
          >
            <InputNumber
              readOnly
              value={
                singleClient?.data?.client_last_balance &&
                Number(singleClient?.data?.client_last_balance) < 0
                  ? Number(singleClient?.data?.client_last_balance) * -1
                  : singleClient?.data?.client_last_balance
              }
              style={{
                width: '100%',
                borderColor:
                  balance !== undefined && balance > 0
                    ? 'green'
                    : balance == 0
                    ? 'black'
                    : 'red',
              }}
            />
          </Form.Item>
        </Col>
      )}
    </>
  );
}
