import {
  FileTextOutlined,
  PlusOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Row,
  Table,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { perProps } from '../../../common/types/commonTypes';
import { formatNumber, rangePresets } from '../../../common/utils/common.utils';
import { FormInputItem } from '../../../components/common/FormItem/FormItems';
import CommonViewReport from '../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import { landscapePageStyle } from '../../Reports/ledgers/pages/ClientLedgers';
import {
  useGetAllVendorPaymentQuery,
  useLazyDownloadVendorPayExcelQuery,
} from '../api/endpoints/vendorPaymentEndpoints';
import { VendorPaymentColumns } from '../utils/VendorPaymentColumns';
import { useForm, useWatch } from 'antd/es/form/Form';

const ListOfVendPayment = ({ permission }: perProps) => {
  const [form] = useForm();
  const componentRef = useRef(null);
  // Get Data with Pagination and Search
  const [search, setSearch] = useState('');
  const [date, setDate] = useState([]);

  const date_range = useWatch('date_range', form);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const {
    data: allVendors,
    isLoading,
    refetch,
  } = useGetAllVendorPaymentQuery({
    ...pagination,
    search,
    from_date: Array.isArray(date_range)
      ? dayjs(date_range[0]).format('YYYY-MM-DD')
      : undefined,
    to_date: Array.isArray(date_range)
      ? dayjs(date_range[1]).format('YYYY-MM-DD')
      : undefined,
  });

  const [downloadExcel] = useLazyDownloadVendorPayExcelQuery();

  const totalAmount = allVendors?.data?.reduce((prev, curr) => {
    return prev + curr.payment_amount;
  }, 0);

  const handleSearch = (e: any) => {
    const search = e.target.value;
    setSearch(search);
  };

  const handleDateRangeChange = (date: any, dateString: any) => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 50,
    }));
    setDate(dateString);
  };

  const jsx = (col: any) => {
    return (
      <Table
        bordered
        size='small'
        rowKey={(e) => e.vpay_id}
        columns={col}
        dataSource={allVendors?.data}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading,
          indicator: <LoadingIndicator />,
        }}
        pagination={
          allVendors?.count !== undefined && allVendors?.count < 20
            ? false
            : {
                ...pagination,
                total: allVendors?.count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        summary={() => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={7} className='bold'>
                  TOTAL
                </Table.Summary.Cell>

                <Table.Summary.Cell index={2}>
                  <div style={{ textAlign: 'right' }}>
                    <Typography.Text className='color-profit bold'>
                      {formatNumber(totalAmount)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={0} colSpan={2} />
              </Table.Summary.Row>
            </>
          );
        }}
      />
    );
  };

  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx(
          VendorPaymentColumns({
            pagination,
            isForPrint: true,
            permission,
          })
        )}
        printRef={componentRef}
        title={{ info_title: 'Vendor Payment List', title: '' }}
      />
    </div>
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Vendor_payment_list_$${dayjs()}`,
    pageStyle: landscapePageStyle,
  });

  const handleExcel = () => {
    downloadExcel({ from_date: date[0], to_date: date[1] });
  };

  useEffect(() => {
    form.setFieldsValue({
      date_range: [dayjs().subtract(15, 'days'), dayjs()],
    });
  }, []);

  return (
    <>
      <BreadCrumb
        arrOfOption={['List Of Vendor Payments']}
        refetch={refetch}
        reloaderSize='small'
      />

      <Row justify={'space-between'}>
        <Col lg={4}>
          <Flex gap={5}>
            {permission?.['create:any'] && (
              <Link to='/vendors/payment/add' state={location.pathname}>
                <Button type='primary' icon={<PlusOutlined />}>
                  Add Payment
                </Button>
              </Link>
            )}
            <Button
              icon={<PrinterOutlined />}
              type='primary'
              onClick={handlePrint}
            >
              Print
            </Button>

            <Button
              icon={<FileTextOutlined />}
              type='primary'
              onClick={handleExcel}
            >
              Excel
            </Button>
          </Flex>
        </Col>

        <Col span={12}>
          <Form form={form}>
            <Row justify={'end'} gutter={10}>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item name='date_range'>
                  <DatePicker.RangePicker
                    presets={rangePresets}
                    format={'YYYY-MM-DD'}
                    onChange={handleDateRangeChange}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <FormInputItem
                  onBlur={handleSearch}
                  onPressEnter={handleSearch}
                  placeholder='🔍 Search by combine'
                  size={24}
                  allowClear={true}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      {jsx(
        VendorPaymentColumns({
          pagination,
          isForPrint: false,
          permission,
        })
      )}

      {print_content}
    </>
  );
};

export default ListOfVendPayment;
