import { IAirTicketInfo } from '../../../common/types/commonInterfaces';

export const sumAirticketData=(dataArray?:IAirTicketInfo[])=> {

    let initData = {
        airticket_commission_percent_total: 0,
        airticket_gross_fare: 0,
        airticket_base_fare: 0,
        airticket_client_price: 0,
        airticket_discount_total: 0,
        airticket_extra_fee: 0,
        airticket_purchase_price: 0,
        airticket_total_taxes_commission: 0,
        airticket_ait: 0,
        airticket_profit: 0,
      }


    if(dataArray){
        return dataArray.reduce((acc, item) => {
          acc.airticket_commission_percent_total += parseFloat(item.airticket_commission_percent_total);
          acc.airticket_gross_fare += parseFloat(item.airticket_gross_fare);
          acc.airticket_base_fare += parseFloat(item.airticket_base_fare);
          acc.airticket_client_price += parseFloat(item.airticket_client_price);
          acc.airticket_discount_total += parseFloat(item.airticket_discount_total);
          acc.airticket_extra_fee += parseFloat(item.airticket_extra_fee);
          acc.airticket_purchase_price += parseFloat(item.airticket_purchase_price);
          acc.airticket_total_taxes_commission += parseFloat(item.airticket_total_taxes_commission);
          acc.airticket_ait += parseFloat(item.airticket_ait);
          acc.airticket_profit += parseFloat(item.airticket_profit);
      
          return acc;
        }, initData);

    }else{

        return initData

    }
  }
  
  // Example usage:
  const dataArray = [
    {
      airticket_commission_percent_total: 10,
      airticket_gross_fare: "1000.50",
      airticket_base_fare: "800.25",
      airticket_client_price: "1200.75",
      airticket_discount_total: "50.50",
      airticket_extra_fee: "20.00",
      airticket_purchase_price: "900.00",
      airticket_total_taxes_commission: 100,
      airticket_ait: "15.00",
      airticket_profit: "300.25",
    },
    // Add more objects here...
  ];
  
