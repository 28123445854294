import { Button, Col, Divider, Form, FormListFieldData, Row } from 'antd';
import { FormInstance, FormListOperation } from 'antd/lib';
import { FormInputItem, NumberInput } from '../../../FormItem/FormItems';
import { useWatch } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { Fixed2 } from '../../../../../common/utils/common.utils';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

type Props = {
  subField: FormListFieldData;
  subOpt: FormListOperation;
  form: FormInstance<any>;
  subFields: FormListFieldData[];
  sl: number;
};

const InvoiceAirTicket_Input = ({
  form,
  subField,
  subOpt,
  subFields,
  sl,
}: Props) => {
  const discount = useWatch(
    ['edited_invoice', subField.name, 'airticket_discount_total'],
    form
  );
  const unitPrice = useWatch(
    ['edited_invoice', subField.name, 'airticket_client_price'],
    form
  );

  useEffect(() => {
    const total = Fixed2(unitPrice) - Fixed2(discount);
    form.setFieldValue(['edited_invoice', subField.name, 'total'], total);
  }, [discount, unitPrice]);

  return (
    <Row
      className='border'
      key={subField.key}
      gutter={10}
      align={'middle'}
      justify={'center'}
    >
      <Divider>Ticket - {sl + 1}</Divider>

      <FormInputItem
        hidden
        name={[subField.name, 'airticket_id']}
        label='Air-ticket id'
        size={8}
        readOnly
      />
      <FormInputItem
        name={[subField.name, 'airticket_ticket_no']}
        label='Air-ticket No'
        size={6}
        // readOnly
      />

      <NumberInput
        name={[subField.name, 'airticket_client_price']}
        label='Unit Price'
        size={6}
      />
      <NumberInput
        name={[subField.name, 'airticket_discount_total']}
        label='Discount'
        size={5}
      />

      <NumberInput
        name={[subField.name, 'total']}
        label='Total'
        size={5}
        readOnly
      />
      <Col span={2} style={{ padding: 0, margin: 0, marginTop: 30 }}>
        <Form.Item>
          <Button
            danger
            icon={<MinusCircleOutlined />}
            onClick={() => subOpt.remove(subField.name)}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default InvoiceAirTicket_Input;
