import { ArrowLeftOutlined, DollarOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Row, Space, Table, Tag } from 'antd';
import Popconfirm from 'antd/lib/popconfirm';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { selectUser } from '../../../auth/states/userSlice';
import { IAllInvoices } from '../../../common/types/commonInterfaces';
import { IPermission } from '../../../common/types/commonTypes';
import {
  FormatDate,
  TableParams,
  formatAmount,
  rangePresets,
  separateClient,
} from '../../../common/utils/common.utils';
import { removeSpaceBeforeWord } from '../../../components/common/Invoice/InvoiceListHeader';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import Reload from '../../../components/common/reload/Reload';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import InvoiceOtherAddMoneyModal from '../../Invoice(Other)/Modals/InvoiceOtherAddMoneyModal';
import {
  useDeleteInvoiceTourMutation,
  useGetAllInvoiceTourQuery,
} from '../Endpoints/GenerateInvoiceEndpoints';
import AddCostingFormModal from '../Modals/AddCostingFormModal';
type Props = {
  permission: IPermission | undefined;
  addMRPermission: ['*'] | undefined;
};
const AllInvoiceTours = ({ permission, addMRPermission }: Props) => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const [dataSource, setDataSource] = useState<IAllInvoices[]>();
  const [query, setQuery] = useState<string>('');

  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  const {
    data: allInvoiceTour,
    isFetching,
    isLoading,
    refetch: getAllInvoices,
  } = useGetAllInvoiceTourQuery(
    query +
      `&from_date=${date[0] || ''}&to_date=${date[1] || ''}&search=${search}`
  );

  const [isTrash, setIsTrash] = useState(false);
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();

  const [deleteTour, { isSuccess, isLoading: deleteLoading, isError }] =
    useDeleteInvoiceTourMutation();

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isError) {
      setColumnIndex(null);
    }
  }, [isSuccess, isError]);
  const handleClientDelete = async (values: IAllInvoices, index: number) => {
    const body = {
      tour_invoice_id: values.invoice_id,
      invoice_has_deleted_by: user?.user_id as number,
    };
    setColumnIndex(index);
    await deleteTour(body);
  };

  //column
  const location = useLocation();
  const column: ColumnsType<IAllInvoices> = [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((tableParams?.pagination?.current || 1) - 1) *
            (tableParams?.pagination?.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      align: 'center',
      width: 120,
    },
    {
      title: 'Sales Dates',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      align: 'center',
      render: (_, data) => <>{FormatDate(data.invoice_date)}</>,
      width: 135,
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (value, record) => {
        const { client_id, combined_id } = separateClient(record?.comb_client);

        return (
          <Link
            to={
              client_id
                ? `/clients/details/${record?.comb_client}`
                : `/combineClients/details/${combined_id}`
            }
          >
            <span>
              {record.client_name}
              {record.mobile ? ' ' + record.mobile : ''}
            </span>
          </Link>
        );
      },
    },
    {
      title: 'Sales Price',
      dataIndex: 'net_total',
      key: 'net_toal',
      width: 120,
      align: 'right',
      sorter: (a, b) => Number(a.net_total) - Number(b.net_total),

      render: (curr) => (
        <span className='color-sales'>{formatAmount(curr)}</span>
      ),
    },
    {
      title: 'Rec Amount',
      dataIndex: 'invclientpayment_amount',
      key: 'invclientpayment_amount',
      align: 'right',
      width: 120,
      render: (curr) => (
        <span className='color-collection'>{formatAmount(curr)}</span>
      ),
    },
    {
      title: 'Due Amount',
      align: 'right',
      width: 120,
      render(_, record) {
        const dueAmount =
          Number(record?.net_total) - Number(record?.invclientpayment_amount);

        return dueAmount > 0 ? (
          <span style={{ color: 'red' }}>
            {formatAmount(
              Number(record?.net_total) -
                Number(record?.invclientpayment_amount)
            )}
          </span>
        ) : (
          <Tag color='cyan' icon={<DollarOutlined />}>
            PAID
          </Tag>
        );
      },
      sorter: (a, b) => Number(a.net_total) - Number(b.net_total),
    },
    {
      title: 'MR. No.',
      dataIndex: 'money_receipt_num',
      key: 'money_receipt_num',
      width: 120,
      render: (_, data) => (
        <span>
          {data?.money_receipt_num?.split(',').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: 'Sales by',
      dataIndex: 'sales_by',
      key: 'sales_by',
      width: 150,
    },
    {
      title: 'Action',
      key: 'operation',
      width: 400,
      render: (_, data, index) => (
        <Space size='small'>
          {permission?.['read:any'] && !isTrash && (
            <Link
              state={location.pathname}
              to={`/invoiceTour/details/${data.invoice_id}`}
            >
              <Button size='small' type='primary'>
                View
              </Button>
            </Link>
          )}

          {permission?.['update:any'] && !isTrash && (
            <Button
              size='small'
              type='primary'
              onClick={() => {
                navigate(`/tour/invoice/edit/${data.invoice_id}`, {
                  state: location.pathname,
                });
              }}
            >
              Edit
            </Button>
          )}

          {permission?.['delete:any'] &&
            (Number(data?.invclientpayment_amount) <= 0 ? (
              <Popconfirm
                title='Sure to delete?'
                onConfirm={() => handleClientDelete(data, index)}
              >
                <Button
                  danger
                  size='small'
                  type='primary'
                  loading={columnIndex === index && true}
                >
                  Delete
                </Button>
              </Popconfirm>
            ) : (
              <Button disabled size='small'>
                Delete
              </Button>
            ))}

          <AddCostingFormModal invoiceId={data?.invoice_id} />

          {addMRPermission &&
            (Number(data?.net_total || 0) !==
            Number(data?.invclientpayment_amount || 0) ? (
              <InvoiceOtherAddMoneyModal
                disabled={data?.net_total === data?.invclientpayment_amount}
                invoice_id={data?.invoice_id}
                InvocieModalTitle={'Invoice Tour'}
              />
            ) : (
              <Button disabled size='small'>
                Money Receipt
              </Button>
            ))}
        </Space>
      ),
    },
  ];

  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(allInvoiceTour?.count || 0);
    setDataSource(allInvoiceTour?.data);
  }, [allInvoiceTour, isTrash, date]);

  // @Handle pagination
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['50', '100', '200', '500'],
      pageSize: 50,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // Perform Search Operation

  const handleDateRangeChange = (date: any, dateString: any) => {
    setQuery(`page=1&size=50`);

    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        total: count,
        current: 1,
      },
    }));

    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setQuery(`page=1&size=50`);

    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        total: count,
        current: 1,
      },
    }));

    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <div>
      <BreadCrumb arrOfOption={['Invoice Tour', 'Lists']} />
      {/* HEADER */}
      {(isLoading || deleteLoading || isFetching) && <LoadingIndicator />}
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 10 }}
        style={{ marginBottom: '25px' }}
        justify={'space-between'}
      >
        <Col span={12}>
          <Row gutter={10}>
            {permission?.['create:any'] && (
              <Col xs={24} sm={12} md={8} lg={8}>
                <Link to={'/tour/add'}>
                  <Button
                    type='primary'
                    icon={<ArrowLeftOutlined />}
                    style={{ width: '100%' }}
                  >
                    Create
                  </Button>
                </Link>
              </Col>
            )}

            <Col xs={24} sm={12} md={8} lg={8} style={{ display: 'flex' }}>
              <Col>
                <Reload refetch={getAllInvoices} />
              </Col>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Invoices...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* table */}

      <Table
        rowClassName={(record) => {
          const { invoice_is_reissued: reissue, invoice_is_refund: refund } =
            record;
          let className = '';

          if (reissue) return (className = 'reissued-row-style');
          if (refund) return (className = 'refund-row-style');

          return className;
        }}
        size='small'
        bordered
        rowKey={(e) => e.invoice_id}
        dataSource={dataSource}
        columns={column}
        scroll={{ x: true }}
        pagination={count > 50 ? tableParams.pagination : false}
        onChange={(args1, args2, args3) => {
          setTableParams((prev) => ({ ...prev, pagination: args1 }));
          setQuery &&
            args1.current &&
            args1?.pageSize &&
            setQuery(
              `?trash=${!isTrash}&page=${args1?.current}&size=${
                args1?.pageSize
              }`
            );
        }}
      />
    </div>
  );
};

export default AllInvoiceTours;
