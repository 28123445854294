import { FileTextOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { perProps } from '../../../common/types/commonTypes';
import { FormInputItem } from '../../../components/common/FormItem/FormItems';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import {
  useGetVendorsQuery,
  useLazyGetVendorExcelReportQuery,
} from '../api/endpoints/vendorEndpoints';
import ListOfVendorsTable from '../components/ListOfVendorsTable';

const ListOfVendors = ({ permission }: perProps) => {
  // Get Data with Pagination and Search
  const [search, setSearch] = useState('');

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const {
    isLoading,
    data: vendors,
    isFetching,
    refetch,
  } = useGetVendorsQuery({ ...pagination, search });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 50,
    }));
  }, [search]);

  const [fetchExcel, { isLoading: excelLoading }] =
    useLazyGetVendorExcelReportQuery();
  const handleSearch = (e: any) => {
    const search = e.target.value;
    setSearch(search);
  };

  return (
    <>
      <BreadCrumb
        arrOfOption={['Vendors']}
        refetch={() => refetch()}
        reloaderSize='small'
      />
      <Row justify={'space-between'}>
        <Col lg={4}>
          <Space style={{ marginBottom: '1rem' }}>
            {permission?.['create:any'] && (
              <Link to='/vendors/add' state={location.pathname}>
                <Button type='primary' icon={<PlusOutlined />}>
                  Add Vendor
                </Button>
              </Link>
            )}
            <Button
              type='primary'
              onClick={() => {
                fetchExcel({
                  ...pagination,
                  search,
                });
              }}
              loading={excelLoading}
            >
              <FileTextOutlined />
              Excel Report
            </Button>
          </Space>
        </Col>

        <FormInputItem
          onBlur={handleSearch}
          onPressEnter={handleSearch}
          placeholder='🔍 Search by vendor'
          size={6}
          allowClear
        />
      </Row>

      <ListOfVendorsTable
        permission={permission}
        refetch={refetch}
        pagination={pagination}
        handlePaginationChange={handlePaginationChange}
        listOfVendors={vendors?.data}
        count={vendors?.count}
      />

      {(isLoading || isFetching) && <LoadingIndicator />}
    </>
  );
};

export default ListOfVendors;
