import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Col } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { Link, useNavigate } from 'react-router-dom';
import Reload, { refetchArr } from '../reload/Reload';

type Props = {
  arrOfOption: string[];
  refetch?: () => any;
  refetchArr?: refetchArr[];
  reloaderSize?: SizeType;
};

const BreadCrumb = ({
  arrOfOption,
  refetch,
  refetchArr,
  reloaderSize,
}: Props) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        padding: '10px',
        marginBottom: '20px',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Breadcrumb separator='>'>
        <Breadcrumb.Item>
          <ArrowLeftOutlined
            onClick={() => {
              navigate(-1);
            }}
            className='icon_hover'
            style={{
              padding: '4px',
              borderRadius: '2px',
            }}
          />
          <Link to={'/'}>
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={'/'}>DASHBOARD</Link>
        </Breadcrumb.Item>
        <Breadcrumb separator='>' >
          {arrOfOption.map((item) => (
            <Breadcrumb.Item key={item}>{item.toUpperCase()}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <ArrowRightOutlined
          className={'icon_hover'}
          style={{
            padding: '4px',
            marginLeft: '5px',
            borderRadius: '2px',
          }}
          onClick={() => {
            navigate(1);
          }}
        />
      </Breadcrumb>
      {refetch || refetchArr ? (
        <Col>
          <Reload
            refetch={refetch}
            refetchArr={refetchArr}
            reloaderSize={reloaderSize}
          />
        </Col>
      ) : (
        ''
      )}
    </div>
  );
};

export default BreadCrumb;
