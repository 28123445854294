import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { IAirTicketInfo } from '../../../common/types/commonInterfaces';
import {
  Fixed2,
  formatAmount,
  formatNumber,
} from '../../../common/utils/common.utils';
import { findInvoiceRoute } from '../../../layout/components/SearchInvoices';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';

type Props = {
  tickets?: IAirTicketInfo[];
  category_id: number;
  discount: number | undefined;
};

const NonCommissionCostInfo = ({ tickets, category_id, discount }: Props) => {
  const flightsColumn: ColumnsType<IAirTicketInfo> = [
    {
      title: 'Sl',
      align: 'center',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Ticket No.',
      align: 'center',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
    },
    {
      title: 'Ex. Ticket No.',
      align: 'center',
      dataIndex: 'airticket_reissue_ticket_no',
      key: 'airticket_reissue_ticket_no',
      hidden: category_id === 3 ? false : true,

      render: (curr, rec) => {
        if (curr) {
          return (
            <Link
              to={`../${
                findInvoiceRoute(rec?.invoice_category_id) +
                '/' +
                'details' +
                '/' +
                rec?.airticket_existing_invoiceid
              }`}
              state={`/airticket/details/${rec?.airticket_existing_invoiceid}`}
            >
              {curr}
            </Link>
          );
        }
      },
    },

    // {
    //   title: 'PNR',
    //   align: 'center',
    //   dataIndex: 'airticket_pnr',
    //   key: 'airticket_pnr',
    // },

    {
      title: 'Vendor',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      align: 'center',
    },

    {
      title: 'Airline',
      dataIndex: 'airline_name',
      key: 'airline_name',
      align: 'center',
    },

    {
      title: 'Issue Date',
      render: (_, data) => (
        <>{dayjs(data?.airticket_issue_date).format('DD MMM YYYY')}</>
      ),
      key: 'airticket_issue_date',
      align: 'center',
    },

    {
      title: 'Extra Fee',
      dataIndex: 'airticket_extra_fee',
      key: 'airticket_extra_fee',
      align: 'center',
      render: (curr) => <span>{formatAmount(curr)}</span>,
    },
    {
      title: 'Sales',
      dataIndex: 'airticket_client_price',
      key: 'airticket_client_price',
      align: 'right',
      render: (curr) => (
        <span className='color-sales'>{formatAmount(curr)}</span>
      ),
    },

    {
      title: 'Purchase',
      dataIndex: 'airticket_purchase_price',
      key: 'airticket_purchase_price',
      align: 'right',
      render: (curr) => (
        <span className='color-purchase'>{formatAmount(curr)}</span>
      ),
    },

    {
      title: 'Profit',
      dataIndex: 'airticket_profit',
      key: 'airticket_profit',
      align: 'right',
      render: (curr) => (
        <span className={curr > 0 ? 'color-profit' : 'color-loss'}>
          {formatAmount(curr)}
        </span>
      ),
    },
  ];

  const getSoldPrice = (data?: IAirTicketInfo[]) => {
    return data
      ? data.reduce((a, b) => a + Number(b.airticket_client_price || 0), 0)
      : 0;
  };
  const getPurchasePrice = (data?: IAirTicketInfo[]) => {
    return data
      ? data.reduce((a, b) => a + Number(b.airticket_purchase_price || 0), 0)
      : 0;
  };
  const getProfitPrice = (data?: IAirTicketInfo[]) => {
    return data
      ? data.reduce((a, b) => a + Number(b.airticket_profit || 0), 0)
      : 0;
  };

  return (
    <div className='mt-20'>
      <FormHeaderTitle title='COST INFORMATION' />
      <Table
        rowKey={(e) => e.airticket_ticket_no}
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        dataSource={tickets}
        columns={flightsColumn}
        bordered
        summary={(rec) => {
          const totalSold = getSoldPrice(tickets);
          const totalPurchese = getPurchasePrice(tickets);
          const totalProfit = getProfitPrice(tickets);
          const overAllProfit = totalProfit - Fixed2(discount);

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell
                  index={0}
                  colSpan={Math.floor(flightsColumn.length) + category_id - 6}
                >
                  <Typography className='bold'>Summary</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Typography className='align-right color-sales'>{`${formatNumber(
                    totalSold
                  )}`}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Typography className='align-right color-purchase'>{`${formatNumber(
                    totalPurchese
                  )}`}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  <Typography className='align-right color-profit'>{`${formatNumber(
                    totalProfit
                  )}`}</Typography>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell
                  index={0}
                  colSpan={Math.floor(flightsColumn.length) - 3}
                >
                  <Typography className='bold'>Over All Discount</Typography>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={3} colSpan={3}>
                  <Typography className='align-right color-discount'>{`${formatNumber(
                    discount
                  )}`}</Typography>
                </Table.Summary.Cell>
              </Table.Summary.Row>

              <Table.Summary.Row>
                <Table.Summary.Cell
                  index={0}
                  colSpan={Math.floor(flightsColumn.length) - 3}
                >
                  <Typography className='bold'>Over All Profit/Loss</Typography>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={3} colSpan={3}>
                  <Typography
                    className={`align-right ${
                      overAllProfit > 0 ? 'color-profit' : 'color-loss'
                    } `}
                  >{`${formatNumber(overAllProfit)}`}</Typography>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
        pagination={false}
      />
    </div>
  );
};

export default NonCommissionCostInfo;
