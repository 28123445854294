import { Card, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useActiveNoticeQuery } from "../Api/Endpoints/DashboardEndpoints";

const NoticeBar = () => {
  const { isLoading, data } = useActiveNoticeQuery();

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (data?.data) {
      setShow(true);
    }
  }, [data?.data]);

  const handleCardClose = () => {
    setShow(false);
  };

  if (!show) {
    return null;
  }

  if (isLoading) {
    return (
      <Card style={{ height: "auto" }}>
        <Skeleton title={false} paragraph={{ rows: 4 }} active />
      </Card>
    );
  }

  return (
    <div
      style={{
        background: `${data?.data?.ntc_txt_bg || "#FF6969"}`,
        height: 70,
        padding: "0 100px",
        color: `${data?.data?.ntc_txt_colr || "#FFF"}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 20,
      }}
    >
      <span>
        <strong>NOTICE :</strong>
        <span>{data?.data?.ntc_txt}</span>
      </span>
      <button
        style={{
          color: `${data?.data?.ntc_txt_bg || "#FF6969"}`,
          background: `${data?.data?.ntc_txt_colr || "#FFF"}`,
          border: "none",
          padding: "10px 25px",
          fontWeight: "bolder",
          cursor: "pointer",
          marginLeft: 20,
        }}
        onClick={handleCardClose}
      >
        CLOSE
      </button>
    </div>
  );
};

export default NoticeBar;
