export enum RoleResources {
  dashboard = "dashboard",
  invoice_airticket = "invoice_airticket",
  invoice_non_commission = "invoice_non_commission",
  invoice_reissue = "invoice_reissue",
  invoice_other = "invoice_other",
  invoice_visa = "invoice_visa",
  invoice_tour_package = "invoice_tour_package",
  invoice_hajj = "invoice_hajj",
  invoice_hajj_pre_reg = "invoice_hajj_pre_reg",
  invoice_ummrah = "invoice_ummrah",
  hajji_management = "hajji_management",
  hajji_management_client_to_client = "hajji_management_client_to_client",
  hajji_management_group_to_group = "hajji_management_group_to_group",
  hajji_management_transfer_inout = "hajji_management_transfer_inout",
  hajji_management_cancel_pre_reg = "hajji_management_cancel_pre_reg",
  refund_module = "refund_module",
  refund_airticket = "refund_airticket",
  refund_partial = "refund_partial",
  refund_tax = "refund_tax",
  refund_other_invoice = "refund_other_invoice",
  refund_tour_package = "refund_tour_package",
  money_receipt = "money_receipt",
  money_receipt_advr = "money_receipt_advr",
  accounts_module = "accounts_module",
  account_opening_balance = "account_opening_balance",
  account_balance_transfer = "account_balance_transfer",
  account_non_invoice_income = "account_non_invoice_income",
  account_investments = "account_investments",
  account_incentive_income = "account_incentive_income",
  account_transaction_history = "account_transaction_history",
  account_balance_status = "account_balance_status",
  account_bill_adjustment = "account_bill_adjustment",
  cheque_management = "cheque_management",
  payroll = "payroll",
  expense = "expense",
  loan_management_module = "loan_management_module",
  loan_management_authority = "loan_management_authority",
  loan_management_loan = "loan_management_loan",
  loan_management_payment = "loan_management_payment",
  loan_management_receive = "loan_management_receive",
  sms_system = "sms_system",
  clients = "clients",
  combined_clients = "combined_clients",
  vendors = "vendors",
  vendors_payment = "vendors_payment",
  vendor_advr = "vendor_advr",
  agents = "agents",
  quotation = "quotation",
  passport_management = "passport_management",
  report_module = "report_module",
  loan_report = "loan_report",
  online_transaction_charge_report = "online_transaction_charge_report",
  report_ledgers = "report_ledgers",
  report_total_due_advance = "report_total_due_advance",
  sales_report = "sales_report",
  profit_loss_report = "profit_loss_report",
  expense_report = "expense_report",
  passport_report = "passport_report",
  passenger_list_report = "passenger_list_report",
  vendor_wise_purchase_payment = "vendor_wise_purchase_payment",
  client_discount = "client_discount",
  journey_date_wise_report = "journey_date_wise_report",
  ait_report = "ait_report",
  accounts_report = "accounts_report",
  summary_daily_and_monthly_report = "summary_daily_and_monthly_report",
  preregistration_report = "preregistration_report",
  refund_report = "refund_report",
  summary = "summary",
  country_wise_report = "country_wise_report",
  trash_list = "trash_list",
  user_login_history = "user_login_history",
  audit_trail = "audit_trail",
  configuration_module = "configuration_module",
  client_category = "client_category",
  airports = "airports",
  products = "products",
  visa_type = "visa_type",
  departments = "departments",
  room_types = "room_types",
  transport_types = "transport_types",
  designations = "designations",
  employee = "employee",
  users = "users",
  users_role = "users_role",
  tour_itinerary = "tour_itinerary",
  passport_status = "passport_status",
  groups = "groups",
  maharam = "maharam",
  agency = "agency",
  companies = "companies",
  contacts = "contacts",
  airline = "airline",
  expense_head = "expense_head",
  database_backup = "database_backup",

  // Disable Edit on Previous Billing
  invoice_other_billing = "invoice_other_billing",
  mony_receipt_specific_invoice = "mony_receipt_specific_invoice",
  mony_receipt_specific_ticket = "mony_receipt_specific_ticket",
  vendor_payment_specific = "vendor_payment_specific",
}
export enum Configuration_Roles {
  configuration668 = "configuration668",
  configuration_module = "configuration_module",
  client_category = "client_category",
  airports = "airports",
  products = "products",
  departments = "departments",
  room_types = "room_types",
  transport_types = "transport_types",
  designations = "designations",
  employee = "employee",
  users_role = "users_role",
  tour_itinerary = "tour_itinerary",
  passport_status = "passport_status",
  maharam = "maharam",
  agency = "agency",
  airline = "airline",
  expense_head = "expense_head",
  companies = "companies",
  contacts = "contacts",
}

export enum Report_Roles {
  report_module = "report_module",
  loan_report = "loan_report",
  report_ledgers = "report_ledgers",
  report_total_due_advance = "report_total_due_advance",
  online_transaction_charge_report = "online_transaction_charge_report",
  sales_report = "sales_report",
  profit_loss_report = "profit_loss_report",
  expense_report = "expense_report",
  passport_report = "passport_report",
  passenger_list_report = "passenger_list_report",
  vendor_wise_purchase_payment = "vendor_wise_purchase_payment",
  client_discount = "client_discount",
  journey_date_wise_report = "journey_date_wise_report",
  ait_report = "ait_report",
  accounts_report = "accounts_report",
  summary_daily_and_monthly_report = "summary_daily_and_monthly_report",
  preregistration_report = "preregistration_report",
  refund_report = "refund_report",
  summary = "summary",
  country_wise_report = "country_wise_report",
  trash_list = "trash_list",
  user_login_history = "user_login_history",
  audit_trail = "audit_trail",
}
