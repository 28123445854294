import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { ITotal_Due_Advance_EmployeeDataType } from '../types/Total_Due_Advance_EmployeeTypes';

const { Text } = Typography;
export const Total_Due_Advance_EmployeeUtils = (pagination: {
  current: number;
  pageSize: number;
}): ColumnsType<ITotal_Due_Advance_EmployeeDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Employee Name',
      dataIndex: 'employee_full_name',
      key: 'employee_full_name',
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (value, record, index) => (
        <Link to={`/clients/details/client-${record.invoice_client_id}`}>
          {record.client_name + '/' + record?.client_entry_id}
        </Link>
      ),
    },
    {
      title: 'Cleint Mobile',
      dataIndex: 'client_mobile',
      key: 'client_mobile',
    },

    {
      title: 'Present Due',
      dataIndex: 'client_lbalance',
      key: 'client_lbalance',
      align: 'right',
      sorter: (a, b) => Fixed2(a.client_lbalance) - Fixed2(b.client_lbalance),
      render: (_, record) => {
        return (
          <Text type='danger'>
            {Number(record.client_lbalance) < 0
              ? Fixed2(Math.abs(Number(record.client_lbalance)))
              : '00'}
          </Text>
        );
      },
    },
    {
      title: 'Present Advance',
      dataIndex: 'client_lbalance',
      key: 'client_lbalance',
      align: 'right',
      sorter: (a, b) => Fixed2(a.client_lbalance) - Fixed2(b.client_lbalance),
      render: (_, record) => {
        return (
          <Text type='success'>
            {Number(record.client_lbalance) > 0
              ? Number(record.client_lbalance)
              : '00'}
          </Text>
        );
      },
    },
    {
      title: 'Last Balance',
      dataIndex: 'client_lbalance',
      key: 'client_lbalance',
      align: 'center',
      sorter: (a, b) => Fixed2(a.client_lbalance) - Fixed2(b.client_lbalance),
      render: (amount) => {
        return (
          <Text
            style={{
              color:
                Number(amount) === 0
                  ? '#18b4e9'
                  : Number(amount) > 0
                  ? 'green'
                  : 'red',
            }}
          >
            {Fixed2(Math.abs(Number(amount)))}
          </Text>
        );
      },
    },
  ];
};
