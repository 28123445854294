import { Table } from 'antd';
import { useState } from 'react';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import {
  useDeleteRoleMutation,
  useGetAllRolesWithPaginationQuery,
} from '../endpoints/userEndpoints';
import { UserResponse } from '../types/user.types';
import { RoleUtils } from '../utils/RoleUtils';
import AddRoleDrawer from './AddRoleDrawer';

const ViewRoles = () => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const {
    data: roles,
    isLoading,
    refetch,
  } = useGetAllRolesWithPaginationQuery(pagination, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const count = cache.data?.count;
      const dataToReturn: UserResponse[] = [];

      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];

          dataToReturn.push({ ...element, key: i + 1 });
        }
      }

      return { ...cache, data: { dataToReturn, count } };
    },
  });

  const [deleteRole, { isLoading: isDeleteLoading }] = useDeleteRoleMutation();

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Users', 'Roles']}
        refetch={refetch}
        reloaderSize='small'
      />
      <AddRoleDrawer />
      <FormHeaderTitle title='Roles' />
      <Table
        size='small'
        bordered
        pagination={
          roles?.count !== undefined && roles?.count < 20
            ? false
            : {
              ...pagination,
              total: roles?.count,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }
        }
        columns={RoleUtils({ pagination, deleteRole, isDeleteLoading })}
        dataSource={roles.dataToReturn}
        loading={{
          spinning: isLoading,
          indicator: loadingIndicator,
        }}
      />
    </div>
  );
};

export default ViewRoles;
