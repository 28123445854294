import { api } from '../../../../../app/baseQuery';
import { HTTPResponse } from '../../../../../common/types/commonTypes';

export const fakeInvoiceTags = 'FAKE_INVOICE_TAG';

export const editedInvoiceApiEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //POST FAKE INVOICE
    postFakeInvoice: build.mutation<HTTPResponse<void>, FakeInvoicePostType>({
      query: (body) => ({
        url: `invoice-air-ticket/info`,
        body: body,
        method: 'POST',
      }),

      invalidatesTags: [
        fakeInvoiceTags,
        'AirticketInvoice',
        'InvoiceNonComission',
        'invoice_single_view',
      ],
    }),

    //GET FAKE INVOICE
    getFakeInvoice: build.query<
      HTTPResponse<FakeInvoicePostType>,
      { id: string | number }
    >({
      query: ({ id }) => ({
        url: `invoice-air-ticket/info/${id}`,
        method: 'GET',
      }),
      providesTags: [fakeInvoiceTags],
    }),
  }),
});

export const { usePostFakeInvoiceMutation, useGetFakeInvoiceQuery } =
  editedInvoiceApiEndpoints;

export interface FakeInvoicePostType {
  ti_invoice_id: string;
  ti_invoice_total_due?: number;
  ti_net_total?: number;
  ti_sub_total?: number | string;
  ti_total_discount: number;
  ti_total_payment: string;
  infos: FakeInvoiceInfo[];
}

export interface FakeInvoiceInfo {
  tii_billing_id?: number;
  tii_airticket_id?: number;
  tii_airticket_no?: string;
  tii_airticket_discount?: number;
  tii_airticket_class?: string;
  tii_airticket_class_type?: string;
  tii_airticket_pnr?: string;
  tii_airticket_route?: string;
  tii_total_discount?: number;
  tii_product_name?: string;
  tii_pax_name?: string;
  tii_quantity?: number;
  tii_unit_price?: string;
  tii_sub_total?: number;
  tii_visiting_country?: string;
  tii_visa_type?: string;
  tii_token_no?: string;
  tii_status?: string;
  tii_journey_date?: Date;
  tii_total_room?: number;
}
