import { FormInstance, Row } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { NumberInput } from '../../../../components/common/FormItem/FormItems';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useWatch } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { AitCalculationProps } from '../../pages/NewInvoiceAirTicket';

type Props = {
  gutter?: Gutter | [Gutter, Gutter];
  form: FormInstance<any>;
  ut_name: NamePath;
  bd_name: NamePath;
  es_name: NamePath;
  xt_name: NamePath;
  ow_name: NamePath;
  e5_name: NamePath;
  qa_name: NamePath;
  pz_name: NamePath;
  g4_name: NamePath;
  p7_name: NamePath;
  p8_name: NamePath;
  r9_name: NamePath;
  handleCalculation: ({}: AitCalculationProps) => void;
};

export default function InvoicesCountryCharge({
  gutter,
  form,
  ut_name,
  bd_name,
  es_name,
  xt_name,
  e5_name,
  g4_name,
  ow_name,
  pz_name,
  qa_name,
  p7_name,
  p8_name,
  r9_name,
  handleCalculation,
}: Props) {
  const [charge, setCharge] = useState(0);
  const ut_name_cg = useWatch(ut_name, form);
  const bd_name_cg = useWatch(bd_name, form);
  const es_name_cg = useWatch(es_name, form);
  const xt_name_cg = useWatch(xt_name, form);
  const e5_name_cg = useWatch(e5_name, form);
  const g4_name_cg = useWatch(g4_name, form);
  const ow_name_cg = useWatch(ow_name, form);
  const pz_name_cg = useWatch(pz_name, form);
  const qa_name_cg = useWatch(qa_name, form);
  const p7_name_cg = useWatch(p7_name, form);
  const p8_name_cg = useWatch(p8_name, form);
  const r9_name_cg = useWatch(r9_name, form);

  useEffect(() => {
    const chargeSum =
      Fixed2(ut_name_cg) + Fixed2(bd_name_cg) + Fixed2(e5_name_cg);
    // Fixed2(es_name_cg) +
    // Fixed2(xt_name_cg) +
    // Fixed2(g4_name_cg) +
    // Fixed2(ow_name_cg) +
    // Fixed2(pz_name_cg) +
    // Fixed2(qa_name_cg) +
    // Fixed2(p7_name_cg) +
    // Fixed2(p8_name_cg) +
    // Fixed2(r9_name_cg);

    //@AIT IS 0.3%
    setCharge(Fixed2(chargeSum * 0.003));
  }, [
    ut_name_cg,
    bd_name_cg,
    es_name_cg,
    xt_name_cg,
    e5_name_cg,
    g4_name_cg,
    ow_name_cg,
    pz_name_cg,
    qa_name_cg,
    p7_name_cg,
    p8_name_cg,
    r9_name_cg,
  ]);

  return (
    <Row className='border p-5 mt-10' gutter={gutter}>
      <FormHeaderTitle
        title={`Country Taxes: ${charge ? `(AIT: ${charge})` : ''}`}
      />
      <NumberInput
        label='BD:'
        name={bd_name}
        placeholder='Enter BD Tax'
        size={4}
        min='0'
        maxChar={10}
        onChange={() => {
          handleCalculation({ isAitManual: false, isTaxManual: true });
        }}
      />
      <NumberInput
        label='UT:'
        name={ut_name}
        placeholder='Enter UT Tax'
        size={4}
        min='0'
        maxChar={10}
        onChange={() => {
          handleCalculation({ isAitManual: false, isTaxManual: true });
        }}
      />
      <NumberInput
        label='E5:'
        name={e5_name}
        placeholder='Enter E5 Tax'
        size={4}
        min='0'
        maxChar={10}
        onChange={() => {
          handleCalculation({ isAitManual: false, isTaxManual: true });
        }}
      />
      <NumberInput
        label='ES:'
        name={es_name}
        placeholder='Enter ES Tax'
        size={4}
        min='0'
        maxChar={10}
        onChange={() => {
          handleCalculation({ isAitManual: false, isTaxManual: true });
        }}
      />
      <NumberInput
        label='XT:'
        name={xt_name}
        placeholder='Enter XT Tax'
        size={4}
        min='0'
        maxChar={10}
        onChange={() => {
          handleCalculation({ isAitManual: false, isTaxManual: true });
        }}
      />
      {/* Add new */}
      <NumberInput
        label='OW:'
        name={ow_name}
        placeholder='Enter OW Tax'
        size={4}
        min='0'
        maxChar={10}
        onChange={() => {
          handleCalculation({ isAitManual: false, isTaxManual: true });
        }}
      />

      <NumberInput
        label='QA:'
        name={qa_name}
        placeholder='Enter QA Tax'
        size={4}
        min='0'
        maxChar={10}
        onChange={() => {
          handleCalculation({ isAitManual: false, isTaxManual: true });
        }}
      />
      <NumberInput
        label='PZ:'
        name={pz_name}
        placeholder='Enter PZ Tax'
        size={4}
        min='0'
        maxChar={10}
        onChange={() => {
          handleCalculation({ isAitManual: false, isTaxManual: true });
        }}
      />
      <NumberInput
        label='G4:'
        name={g4_name}
        placeholder='Enter G4 Tax'
        size={4}
        min='0'
        maxChar={10}
        onChange={() => {
          handleCalculation({ isAitManual: false, isTaxManual: true });
        }}
      />
      <NumberInput
        label='P7:'
        name={p7_name}
        placeholder='Enter P7 Tax'
        size={4}
        min='0'
        maxChar={10}
        onChange={() => {
          handleCalculation({ isAitManual: false, isTaxManual: true });
        }}
      />
      <NumberInput
        label='P8:'
        name={p8_name}
        placeholder='Enter P8 Tax'
        size={4}
        min='0'
        maxChar={10}
        onChange={() => {
          handleCalculation({ isAitManual: false, isTaxManual: true });
        }}
      />
      <NumberInput
        label='R9:'
        name={r9_name}
        placeholder='Enter R9 Tax'
        size={4}
        min='0'
        maxChar={10}
        onChange={() => {
          handleCalculation({ isAitManual: false, isTaxManual: true });
        }}
      />
    </Row>
  );
}
