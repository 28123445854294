import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Form, InputNumber, message, Row, Space } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import { EdataType } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import {
  DateInput,
  FormButton,
  NumberInput,
  TextAreaInput,
} from '../../../../components/common/FormItem/FormItems';
import { SelectClients } from '../../../../components/common/FormItem/SelectCustomFeilds';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useLazyGetClLastBalanceQuery } from '../../../Client/Client/api/endpoints/clientEndpoints';
import PayMethodAndAcc from '../../../vendor/components/common/PayMethodAndAcc';
import { layout } from '../../../vendor/pages/AdvanceReturnAdd';
import { presentBalAndColorType } from '../../../vendor/types/vendor.interfaces';
import { usePostAdvanceReturnMRMutation } from '../../api/endpoints/moneyReceiptEndpoints';
import { IMoneyReceiptAdvanceReturn } from '../../Types/MoneyReceiptTypes';
import { Fixed2 } from '../../../../common/utils/common.utils';
type Props = {};
const AddAdvanceReturnMoneyReceipt = (props: Props) => {
  /* set account Last Balance */
  const [accountsBalanced, setAccountBalanced] = useState<number>();

  useEffect(() => {
    form.setFieldValue('vpay_availeable_balance', accountsBalanced);
  }, [accountsBalanced]);

  // ================= @ client last balance ===========

  const [color, setColor] = useState<'green' | 'red' | undefined>(undefined);

  const [presentBalanceLabel, setPresentBalanceLabel] =
    useState<string>('Present balance');

  const setPresetnBalAndColor: presentBalAndColorType = (balance, color) => {
    setPresentBalanceLabel(balance);
    setColor(color);
  };

  const aviableBalanceStyle: React.CSSProperties = {
    width: '100%',
    color,
    // backgroundColor: '#E9E9E9	',

    border: color === 'red' ? '0.5px solid red' : '',
  };

  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const [payMethodId, setPayMethodId] = useState<number>(1);

  const accountId = useWatch('account_id', form);
  const paymentType = useWatch('advr_payment_type', form);

  // ===================== submit handle ============================
  const [post, { isError, isSuccess, isLoading, error }] =
    usePostAdvanceReturnMRMutation();

  const onFinish = async () => {
    const {
      advr_payment_date,
      advr_combclient,
      advr_payment_type,
      account_id,
      advr_amount,
      trans_no,
      advr_note,
      cheque_no,
      vpcheque_withdraw_date,
      bank_name,
      transaction_charge,
    } = form.getFieldsValue();

    let postData: IMoneyReceiptAdvanceReturn = {
      advr_combclient,
      advr_payment_type,
      advr_account_id: account_id,
      advr_amount,
      advr_payment_date: advr_payment_date?.format('YYYY-MM-DD'),
      advr_note,
      advr_created_by: user?.user_id,
    };

    if (advr_payment_type === 3) {
      postData = {
        ...postData,
        advr_trxn_charge: transaction_charge,
        advr_trxn_no: trans_no,
      };
    }
    if (advr_payment_type === 4) {
      postData = {
        ...postData,
        cheque_number: cheque_no,
        cheque_withdraw_date: vpcheque_withdraw_date?.format('YYYY-MM-DD'),
        cheque_bank_name: bank_name,
      };
    }

    post(postData);
    form.setFieldsValue({
      advr_payment_date: dayjs(),
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      advr_payment_date: dayjs(),
    });
  }, []);

  //Clear field when account changed

  useEffect(() => {
    form.setFieldsValue({
      account_id: undefined,
    });
  }, [paymentType]);

  let Emessage: string;

  if (error) {
    if ('status' in error) {
      Emessage = (error.data as EdataType).message;
    }
  }

  // ================== notification =========================
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      message.success('Advance return has been crated!');
      form.resetFields();
      navigate('/moneyreceipt/advance');
    }
    if (isError) {
      message.error(Emessage);
    }
  }, [isLoading]);

  // GET CLIENT LAST BALANCE

  const [clientLBalance, setClientLBalance] = useState(0);
  const [getClientLastBalance, { data: clientLBlance }] =
    useLazyGetClLastBalanceQuery();

  const advr_combclient = useWatch(['advr_combclient'], form);
  useEffect(() => {
    if (advr_combclient !== undefined) {
      getClientLastBalance(advr_combclient);
    }
  }, [advr_combclient]);

  // SET CLIENT LAST BALANCE
  useEffect(() => {
    form?.setFieldsValue({
      clientAviableBalance: clientLBlance?.data?.client_last_balance,
    });
    setClientLBalance(Number(clientLBlance?.data?.client_last_balance));
    if (clientLBlance) {
      setPresetnBalAndColor('Advance', undefined);
    }
    if (
      clientLBlance?.data?.client_last_balance &&
      clientLBlance?.data?.client_last_balance < 0
    ) {
      setPresetnBalAndColor('Due', 'red');
    }
  }, [clientLBlance]);

  const advance_amount = useWatch('clientAviableBalance', form);
  const available_balance = useWatch('vpay_availeable_balance', form);
  const clientAviableBalance = useWatch('clientAviableBalance', form);

  const disableSubmit = () => {
    if (Fixed2(clientAviableBalance) >= 0) {
      return false;
    }

    return true;
  };

  return (
    <>
      <BreadCrumb arrOfOption={['Return Advance Payment']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/moneyreceipt/advance'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Advanced Payment List
          </Button>
        </Link>
      </Space>
      <div className='border p-10'>
        <Form
          layout='horizontal'
          style={{ marginTop: '1rem' }}
          onFinish={onFinish}
          form={form}
          labelAlign='left'
          {...layout}
        >
          <Row gutter={[16, 16]} justify='space-between'>
            <Col span={11}>
              <SelectClients
                name={'advr_combclient'}
                label='Select Client'
                placeholder='Select a client'
                required
                size={24}
              />

              <PayMethodAndAcc
                payMethodId={payMethodId}
                setPayMethodId={setPayMethodId}
                setAccountBalanced={setAccountBalanced}
                size={24}
                accountId={accountId}
                showAvailableBalanceRightSide
              />

              <NumberInput
                maxAmount={Number(accountsBalanced || 0)}
                label='Advance amount'
                name='advr_amount'
                required
                min='0'
                maxChar={14}
                minChar={0}
                size={24}
              />
            </Col>

            <Col span={11}>
              <Form.Item
                name={'clientAviableBalance'}
                label={presentBalanceLabel}
              >
                <InputNumber
                  style={aviableBalanceStyle}
                  readOnly
                  defaultValue={0}
                />
              </Form.Item>
              {Number(payMethodId) !== 4 && (
                <NumberInput
                  label='Available Balance'
                  name='vpay_availeable_balance'
                  color={undefined}
                  readOnly
                  min='0'
                  size={24}
                  maxChar={14}
                  minChar={0}
                />
              )}

              <DateInput
                name='advr_payment_date'
                label='Return date'
                required
                size={24}
              />

              <TextAreaInput label='Return note' name='advr_note' size={24} />

              <FormButton
                disabled={disableSubmit()}
                label='Return Advance Payment'
                loading={isLoading}
              />
            </Col>
            {isLoading && <LoadingIndicator />}
          </Row>
        </Form>
      </div>
    </>
  );
};

export default AddAdvanceReturnMoneyReceipt;
