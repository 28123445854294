import { Col, Form, Row, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { selectUser } from '../../../auth/states/userSlice';
import { toasterNotification } from '../../../common/utils/ToasterNotification';
import {
  getAirportByItaCode,
  getItaCodeByAirportIds,
} from '../../../common/utils/common.utils';
import postDateFormat from '../../../common/utils/postDateFormat';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import InvoiceVisaSubTotalForm from '../../../components/common/Invoice/InvoiceSubTotal';
import InvoiceBillingInfo from '../../../components/common/Invoice/billingInfo/InvoiceBillingInfo';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import { useCheckCreditLimitMutation } from '../../Client/Client/api/endpoints/clientEndpoints';
import { useGetAllAirportQuery } from '../../Configuration/airport/endpoints/airportEndpoints';
import { useGetInvoiceNoQuery } from '../../Invoice(Visa)/Api/Endpoints/invoiceVisaEndpoints';
import NewAddUmrahFormHotelInfo from '../Components/NewAddUmrahFormHotelInfo';
import NewAddUmrahFormTop from '../Components/NewAddUmrahFormTop';
import NewAddUmrahFromPassengerInfo from '../Components/NewAddUmrahFromPassengerInfo';
import {
  InvoiceUmrahFormatedDataType,
  InvoiceUmrahType,
} from '../Types/InvoiceUmrahTypes';
import {
  useInvoiceUmrahEditMutation,
  useLazyInvoiceUmrahGetForEditQuery,
} from '../api/endpoints/invoiceUmrahEndpoints';

type Props = {};
const EditInvoiceUmrah = ({}: Props) => {
  const [form] = Form.useForm();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const { id } = useParams();
  const [updateAndPrev, setUpdateAndPrev] = useState<boolean>(false);

  // @airport data
  const { data: airport } = useGetAllAirportQuery();
  const selectAirport = airport?.data;

  // INVOICE UMRAH EDIT
  const [
    invoiceUmrahEdit,
    {
      isError: invoiceUmrahEditIsError,
      isSuccess: invoiceUmrahEditIsSuccess,
      error: invoiceHajjEditError,
      isLoading: editLoading,
    },
  ] = useInvoiceUmrahEditMutation();
  const [checkCreditLimit, { isLoading: limiLoading }] =
    useCheckCreditLimitMutation();
  const [
    invoiceHajjGetForEdit,
    {
      isSuccess: hajjEditIsSuccessFetch,
      isLoading: hajjEditIsLoading,
      error: hajjEditError,
      data: invoiceHajjEditingDataTemp,
    },
  ] = useLazyInvoiceUmrahGetForEditQuery();
  const editData: InvoiceUmrahType | undefined =
    invoiceHajjEditingDataTemp?.data;

  useEffect(() => {
    if (id) {
      invoiceHajjGetForEdit({ id: Number(id) });
    }
  }, [id]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (invoiceUmrahEditIsSuccess) {
      toasterNotification('success', 'Successfully Updated');
      form.resetFields();
      timeoutId = setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        updateAndPrev
          ? navigate(`/invoiceumrah/details/${id}`)
          : navigate('/invoiceumrah');
      }, 100);
    } else if (invoiceUmrahEditIsError) {
      message.error('Thare was an error');
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [invoiceUmrahEditIsSuccess, invoiceUmrahEditIsError]);

  useEffect(() => {
    let timeoutId1: NodeJS.Timeout;

    if (editData?.billing_information && selectAirport?.length) {
      form.setFieldsValue({
        invoice_service_charge: editData?.invoice_service_charge,
        invoice_vat: editData?.invoice_vat,
        invoice_combclient_id: editData?.invoice_combclient_id,
        invoice_haji_group_id: editData?.invoice_haji_group_id,
        invoice_sales_man_id: editData?.invoice_sales_man_id,
        invoice_agent_id: editData?.invoice_agent_id,
        invoice_no: editData?.invoice_no,
        invoice_sales_date: dayjs(editData?.invoice_sales_date),
        invoice_due_date: editData.invoice_due_date
          ? dayjs(editData?.invoice_due_date)
          : undefined,
        invoice_sub_total: Number(editData?.invoice_sub_total),
        invoice_discount: Number(editData?.invoice_discount),
        invoice_net_total: Number(editData?.invoice_net_total),
        invoice_note: editData?.invoice_note || undefined,
        invoice_created_by: editData?.invoice_created_by,

        passenget_info: editData?.passenget_info?.length
          ? editData?.passenget_info?.map((el) => {
              return {
                ...el,
                passenger_passport_id: el?.passenger_passport_id,

                ticket_route: el?.ticket_route?.length
                  ? getItaCodeByAirportIds(
                      el?.ticket_route as string[],
                      selectAirport
                    )
                  : undefined,

                ticket_route_id: el?.ticket_route,

                ticket_journey_date: el?.ticket_journey_date
                  ? dayjs(el?.ticket_journey_date)
                  : undefined,
                ticket_return_date: el?.ticket_return_date
                  ? dayjs(el?.ticket_return_date)
                  : undefined,
              };
            })
          : [{}],

        hotel_information: editData?.hotel_information?.length
          ? editData?.hotel_information?.map((el) => {
              return {
                ...el,
                hotel_check_in_date: el.hotel_check_in_date
                  ? dayjs(el.hotel_check_in_date)
                  : undefined,
                hotel_check_out_date: el.hotel_check_out_date
                  ? dayjs(el.hotel_check_out_date)
                  : undefined,
              };
            })
          : [{}],

        billing_information: editData?.billing_information?.length
          ? editData.billing_information.map((el) => {
              return {
                billing_product_id: el.billing_product_id,
              };
            })
          : [{}],
      });

      setTimeout(() => {
        form.setFieldsValue({
          billing_information: editData?.billing_information?.map((el) => {
            return {
              pax_name: el.pax_name,
              billing_description: el.billing_description,
              billing_comvendor: el.billing_comvendor,
              billing_product_id: el.billing_product_id,
              billing_quantity: el.billing_quantity,
              billing_subtotal: el.billing_subtotal,
              billing_unit_price: el.billing_unit_price,
              billing_cost_price: el.billing_cost_price,
            };
          }),
        });
      }, 500);
    }
    return () => {
      clearTimeout(timeoutId1);
    };
  }, [hajjEditIsSuccessFetch, editData, selectAirport]);

  const onFinish = (values: InvoiceUmrahType) => {
    const formatedData: InvoiceUmrahFormatedDataType = {
      invoice_combclient_id: values.invoice_combclient_id,
      invoice_haji_group_id: values.invoice_haji_group_id,
      invoice_sales_man_id: values.invoice_sales_man_id,
      invoice_service_charge: values.invoice_service_charge,
      invoice_vat: values.invoice_vat,
      invoice_agent_id: values?.invoice_agent_id,
      invoice_agent_com_amount: values?.invoice_agent_com_amount,
      invoice_no: values.invoice_no,
      invoice_sales_date: dayjs(values.invoice_sales_date).format('YYYY-MM-DD'),
      invoice_due_date: dayjs(values.invoice_due_date).format('YYYY-MM-DD'),
      invoice_no_passenger: values.invoice_no_passenger,
      receipt_payment_type: values.receipt_payment_type,
      invoice_sub_total: values.invoice_sub_total,
      invoice_discount: values.invoice_discount,
      invoice_net_total: values.invoice_net_total,
      invoice_note: values.invoice_note,
      invoice_created_by: user?.user_id as number,

      passenget_info: values.passenget_info?.map((el: any) => {
        delete el?.passport_email;
        delete el?.passport_name;
        delete el?.dialCode;
        delete el?.passport_mobile_no;
        delete el?.passport_date_of_expire;
        delete el?.passport_date_of_issue;

        return {
          ...el,
          passenger_tracking_number: el.passenger_tracking_number || undefined,
          passenger_passport_id: el.passenger_passport_id || undefined,

          ticket_route: el?.ticket_route?.length
            ? (getAirportByItaCode(
                el?.ticket_route as string,
                selectAirport
              ) as string[])
            : undefined,

          passenger_id: el?.passenger_id || undefined,
          ticket_pnr: el?.ticket_pnr || undefined,
          ticket_airline_id: el?.ticket_airline_id || undefined,
          ticket_no: el?.ticket_no || undefined,
          ticket_reference_no: el?.ticket_reference_no || undefined,
        };
      }),

      hotel_information: values.hotel_information?.length
        ? values?.hotel_information?.map((el: any, index) => {
            delete el?.hotel_room_type;
            return {
              ...el,
              hotel_check_in_date: postDateFormat(el.hotel_check_in_date),
              hotel_check_out_date: postDateFormat(el.hotel_check_out_date),
              hotel_id: editData?.hotel_information?.length
                ? el.hotel_id
                : undefined,
              hotel_name: el?.hotel_name || undefined,
              hotel_room_type_id: el?.hotel_room_type_id || undefined,
            };
          })
        : undefined,
      billing_information: values.billing_information.map((el: any, index) => {
        delete el?.billing_total_cost_price;
        return {
          ...el,
          billing_id: editData?.billing_information[index]?.billing_id,
        };
      }),

      money_receipt: values?.receipt_payment_type
        ? {
            receipt_total_amount: values.receipt_total_amount,
            receipt_payment_type: values.receipt_payment_type,
            account_id: values.account_id,
            charge_amount: values.charge_amount,
            cheque_bank_name: values.cheque_bank_name,
            cheque_number: values.cheque_number,
            cheque_withdraw_date: dayjs(values.cheque_withdraw_date).format(
              'YYYY-MM-DD'
            ),
            receipt_money_receipt_no: values.receipt_money_receipt_no,
            receipt_note: values.receipt_note,
            trans_no: values.trans_no,
            receipt_payment_date: dayjs(values.receipt_payment_date).format(
              'YYYY-MM-DD'
            ),
          }
        : undefined,
    };

    console.log('button clicked ');

    const prevNetTotal = editData?.invoice_net_total || 0;
    const currentNetTotal = formatedData.invoice_net_total;
    if (prevNetTotal >= currentNetTotal) {
      invoiceUmrahEdit({ body: formatedData, id: Number(id) });
    } else {
      checkCreditLimit({
        amount: currentNetTotal - prevNetTotal,
        combClient: formatedData.invoice_combclient_id.toString(),
      }).then((res: any) => {
        if (res?.data?.data === 1) {
          invoiceUmrahEdit({ body: formatedData, id: Number(id) });
        } else {
          message.error(res?.data?.data);
        }
      });
    }
  };

  const onFinishFailed = (values: any) => {
    message.error(values.errorFields.length + ' Required fields!');
  };

  const { data: invoiceNo, refetch: invoiceNoRefetch } =
    useGetInvoiceNoQuery('IU');

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      invoice_no: invoiceNo?.data,
      invoice_sales_date: dayjs(),
    });
  }, [location]);

  useEffect(() => {
    form.setFieldsValue({
      invoice_no: invoiceNo?.data,
      invoice_sales_date: dayjs(),
    });
  }, [invoiceNo]);

  const invoice_net_total = useWatch('invoice_net_total', form);

  return (
    <>
      <BreadCrumb arrOfOption={['Invoice (Umrah)']} />

      {/*  */}
      <Form
        name='basic'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        {hajjEditIsLoading ? <LoadingIndicator /> : ''}

        <NewAddUmrahFormTop
          invoice_agent_com_amountFieldName={['invoice_agent_com_amount']}
          invoice_net_totalFieldName={['invoice_net_total']}
          form={form}
        />

        <NewAddUmrahFromPassengerInfo form={form} />
        <NewAddUmrahFormHotelInfo
          form={form}
          hotel_information={editData?.hotel_information}
        />
        <InvoiceBillingInfo
          form={form}
          setProductId={118}
          conditionalCostVendor
        />

        <InvoiceVisaSubTotalForm form={form} />

        {/* @AIRTICKET POST BUTTON */}
        <Row gutter={[10, 10]}>
          <Col>
            <FormButton
              loading={!updateAndPrev && (hajjEditIsLoading || limiLoading)}
              label='UPDATE'
              onClick={() => {
                setUpdateAndPrev(false);
              }}
            />
          </Col>
          <Col>
            <FormButton
              loading={updateAndPrev && (hajjEditIsLoading || limiLoading)}
              label='UPDATE & VIEW'
              onClick={() => {
                setUpdateAndPrev(true);
              }}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditInvoiceUmrah;
