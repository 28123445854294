import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Form, message, Row } from 'antd';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FormatDateV1 } from '../../../../auth/utils/lib';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { usePostAdjustRefundMutation } from '../../Api/Endpoints/AirTicketRefundEndpoints';
import { AirTicketRefundFormItem } from '../../Components/AirTicketRefundFormItem';
import { AdjustRefundSubmit } from '../../RefundTypes/RefundTypes';

const AirTicketRefund = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [postAdjustRefund, { isLoading, isError, isSuccess }] =
    usePostAdjustRefundMutation();

  const onFinishTicketInfos = async (values: AdjustRefundSubmit) => {
    const data = values.billing_information;

    const formattedData = data?.map(
      ({ client_name, vendor_name, sales_date, ...rest }) => ({
        ...rest,
        refund_date: FormatDateV1(rest.refund_date),
      })
    );

    postAdjustRefund(formattedData);
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      message.success('Refund request submitted successfully');
      navigate('/refund/history/airticket');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isError, isSuccess]);

  return (
    <>
      <BreadCrumb arrOfOption={['Refund', 'Air Ticket Refund']} />
      <Link to='/refund/history/airticket'>
        <Button type='primary' style={{ marginBottom: '1rem' }}>
          <ArrowLeftOutlined />
          Return to list
        </Button>
      </Link>

      <Card>
        <Form layout='vertical' form={form} onFinish={onFinishTicketInfos}>
          <Form.List name='billing_information' initialValue={[{}]}>
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map(({ key, name }, index) => {
                    return (
                      <Row
                        key={index}
                        style={{ display: 'flex', alignItems: 'center' }}
                        gutter={[10, { xs: 8, sm: 16, md: 24, lg: 20 }]}
                      >
                        <AirTicketRefundFormItem
                          key={key}
                          name={name}
                          remove={remove}
                          form={form}
                          add={add}
                          index={index}
                          length={fields?.length}
                        />
                      </Row>
                    );
                  })}
                </>
              );
            }}
          </Form.List>

          <Row style={{ marginTop: 10, marginRight: -12 }} justify={'end'}>
            <Form.Item>
              <Button loading={isLoading} type='primary' htmlType='submit'>
                Create Refund
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default AirTicketRefund;
