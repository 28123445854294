import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { selectUser } from '../../../auth/states/userSlice';
import {
  DateInput,
  FormButton,
  NumberInput,
  TextAreaInput,
} from '../../../components/common/FormItem/FormItems';
import { SelectVendors } from '../../../components/common/FormItem/SelectCustomFeilds';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import { usePostAdvanceReturnMutation } from '../api/endpoints/advanceEndpoints';
import { useLazyGetSingleVendorCombineAmountQuery } from '../api/endpoints/vendorEndpoints';
import PayMethodAndAcc from '../components/common/PayMethodAndAcc';

export const layout = {
  labelCol: { lg: 10, md: 12 },
  wrapperCol: { lg: 20, md: 20 },
};

type Props = {};
const AdvanceReturnAdd = (props: Props) => {
  const user = useSelector(selectUser);
  const [form] = Form.useForm();

  const [payMethodId, setPayMethodId] = useState<number>(1);
  const [accountsBalanced, setAccountBalanced] = useState<number>();

  const [presentBalanceLabel, setPresentBalanceLabel] =
    useState<string>('Present balance');

  const [presentBalColor, setPresentBalColor] = useState<
    'green' | 'red' | undefined
  >(undefined);

  const [fetchingId, setFetchingId] = useState();

  // Fetching Combine Last Balance
  const [
    getSingleVendorAmount,
    { data: vendorAmount, isSuccess: vendorAmountSucc },
  ] = useLazyGetSingleVendorCombineAmountQuery();

  useEffect(() => {
    if (fetchingId) {
      getSingleVendorAmount(fetchingId);
    }
  }, [fetchingId]);
  const lastBalance = Number(vendorAmount?.data);

  const accountId = useWatch('account_id', form);
  const paymentType = useWatch('advr_payment_type', form);

  // Changing Field Color According to Balance
  useEffect(() => {
    if (lastBalance) {
      setPresentBalColor('green');
      setPresentBalanceLabel('Present balance');
    }
    if (lastBalance && lastBalance < 0) {
      setPresentBalColor('red');
      setPresentBalanceLabel('Present due');
    }
  }, [lastBalance]);

  useEffect(() => {
    form.setFieldsValue({
      vpay_availeable_balance: accountsBalanced,
    });
  }, [accountsBalanced]);

  useEffect(() => {
    form.setFieldsValue({
      vpay_present_due: Math.abs(lastBalance) || 0,
      date: dayjs(),
    });
  }, [lastBalance]);

  useEffect(() => {
    form.setFieldsValue({
      advr_payment_type: 1,
    });
  }, []);

  // handle submit

  const [post, { isError, isSuccess, isLoading, error }] =
    usePostAdvanceReturnMutation();

  const onFinish = async () => {
    const values = form.getFieldsValue();

    values.date = values.date?.format('YYYY-MM-DD');
    values.advr_created_by = user?.user_id;
    post(values);
    // form.resetFields();
  };

  //Clear field when account changed
  useEffect(() => {
    form.setFieldsValue({
      account_id: undefined,
    });
  }, [paymentType]);

  // ================== notification =========================
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      message.success('Vendor advance returned successfully!');
      navigate('/vendors/advance');
    }
    if (isError) {
      message.error('Vendor advance return with a problem!');
    }
  }, [isLoading]);

  return (
    <Style>
      <BreadCrumb arrOfOption={['Vendor', 'Advance return payment']} />
      <Link to='/vendors/advance'>
        <Button type='primary' style={{ marginBottom: '0.5rem' }}>
          <ArrowLeftOutlined />
          List of Advance Return
        </Button>
      </Link>
      {/* <Card> */}
      <div className='border p-10'>
        <Form
          form={form}
          onFinish={onFinish}
          style={{ marginTop: '0.5rem' }}
          labelAlign='left'
          {...layout}
        >
          <Row gutter={[16, 16]} justify='space-between'>
            <>
              <Col span={11}>
                <SelectVendors
                  name='comb_vendor'
                  label='Select vendor'
                  selectVendor={setFetchingId}
                  size={24}
                  mdSize={24}
                  smSize={24}
                />

                <PayMethodAndAcc
                  size={24}
                  payMethodId={payMethodId}
                  setPayMethodId={setPayMethodId}
                  setAccountBalanced={setAccountBalanced}
                  accountId={accountId}
                  showAvailableBalanceRightSide
                />

                <DateInput
                  name='date'
                  label='Return date'
                  required
                  size={24}
                  mdSize={24}
                  smSize={24}
                />
              </Col>

              <Col span={11}>
                <NumberInput
                  label={presentBalanceLabel}
                  name={'vpay_present_due'}
                  color={presentBalColor}
                  readOnly
                  min='0'
                  size={24}
                  mdSize={24}
                  smSize={24}
                  maxChar={14}
                  minChar={0}
                />
                {Number(payMethodId) !== 4 && (
                  <NumberInput
                    label='Available Balance'
                    name='vpay_availeable_balance'
                    color={undefined}
                    readOnly
                    min='0'
                    size={24}
                    mdSize={24}
                    smSize={24}
                    maxChar={14}
                    minChar={0}
                  />
                )}

                <NumberInput
                  label='Advance amount'
                  name='advance_amount'
                  size={24}
                  mdSize={24}
                  smSize={24}
                  required
                  min='0'
                />

                <TextAreaInput
                  label='Return note'
                  name='note'
                  size={24}
                  mdSize={24}
                  smSize={24}
                />
                <FormButton
                  label='Return Advance Payment'
                  size={24}
                  loading={isLoading}
                />
              </Col>
            </>
          </Row>
        </Form>
      </div>
      {isLoading && <LoadingIndicator />}
    </Style>
  );
};

export default AdvanceReturnAdd;

const Style = styled.div``;
