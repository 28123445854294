import { Col, Row, UploadFile } from 'antd';
import React from 'react';
import { ImageUpload } from '../../../Profile/components/ImageUpload';
import {
  SelectEmployee,
  SelectOptionsInput,
  SelectUsers,
} from '../../../../../components/common/FormItem/SelectCustomFeilds';
import { FormInputItem } from '../../../../../components/common/FormItem/FormItems';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib';

type Props = {
  form: FormInstance<any>;
  setFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>;
  fileList: UploadFile<any>[];
};

const SignatureFormItem = ({ fileList, form, setFileList }: Props) => {
  const sig_type = useWatch<'AUTHORITY' | 'SALES' | 'PREPARE'>(
    'sig_type',
    form
  );
  return (
    <Row gutter={12}>
      <Col span={24}>
        <Row justify={'center'}>
          <ImageUpload
            name='sig_signature'
            label=''
            setFileList={setFileList}
            fileList={fileList}
            offPrefixCls
            buttonText='Add Signature'
            style={{ width: '200px' }}
            required
          />
        </Row>
      </Col>
      <SelectOptionsInput
        name='sig_type'
        label='Signature Type'
        options={['AUTHORITY', 'SALES', 'PREPARE']}
        size={24}
        required
      />

      {sig_type === 'SALES' && (
        <SelectEmployee
          label='Select Employee'
          name={['sig_employee_id']}
          required
          size={12}
        />
      )}

      {sig_type === 'PREPARE' && (
        <SelectUsers
          label='Select User'
          name={['sig_user_id']}
          required
          size={12}
        />
      )}

      <FormInputItem
        name='sig_name_title'
        label='Signature Name'
        size={12}
        required
      />
      <FormInputItem name='sig_position' label='Position' size={12} required />
      <FormInputItem name='sig_phone_no' label='Phone No' size={12} />
      <FormInputItem name='sig_company_name' label='Company Name' size={12} />
      <FormInputItem name='sig_address' label='Address' size={12} />
      <FormInputItem name='sig_state' label='State' size={12} />
      <FormInputItem name='sig_zip_code' label='Zip Code' size={12} />
      <FormInputItem
        name='sig_email'
        label='Email'
        size={12}
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
        ]}
      />
    </Row>
  );
};

export default SignatureFormItem;
