import { message } from 'antd';
import { api } from '../../../../app/baseQuery';
import {
  IAllInvoices,
  IViewInvoiceDetails,
} from '../../../../common/types/commonInterfaces';
import {
  HTTPResponse,
  getInvoiceType,
  voidType,
} from '../../../../common/types/commonTypes';
import { NotificationConst } from '../../../../components/notificatioin/Api/NotificationEndpoints';
import { DASHBOARD } from '../../../Dashboard/Api/Constants/Constants';
import { MONEY_RECEIPT_TAG } from '../../../Money_Receipt/api/endpoints/moneyReceiptEndpoints';
import { INVALIDATE_CLIENT_VENDOR_LEDGER } from '../../../Reports/Api/constants';
import {
  IInvoiceVisaDetails,
  InvoiceVisaEdit,
  InvoiceVisaPost,
} from '../../Type/invoiceVisa.interface';

export const invoiceVisaEndponts = api.injectEndpoints({
  endpoints: (build) => ({
    // GET ALL
    getAllInvoiceVisa: build.query<HTTPResponse<IAllInvoices[]>, string>({
      query: (query) => ({
        url: `/invoice-visa?${query}`,
      }),
      providesTags: () => [
        'invoices',
        'invoice-visa',
        { type: NotificationConst },
        { type: MONEY_RECEIPT_TAG },
      ],
    }),

    // VIEW DETAILIS
    getViewInvoiceVisa: build.query<HTTPResponse<IViewInvoiceDetails>, number>({
      query: (id) => ({
        url: `invoice-visa/view/${id}`,
      }),
      providesTags: () => ['invoice-visa', 'invoices', 'invoice_single_view'],
    }),

    // DELETE AND RESTORE
    deleteInvoiceVisa: build.mutation<
      HTTPResponse<void>,
      { id: number; user_id: number }
    >({
      query: ({ id, user_id }) => ({
        url: `invoice-visa/${id}`,
        method: 'DELETE',
        body: { invoice_has_deleted_by: user_id },
      }),
      invalidatesTags: [
        'invoices',
        'invoice-visa',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    getInvoiceNo: build.query<HTTPResponse<string>, getInvoiceType>({
      query: (type) => ({
        url: `/common/voucher/${type}`,
      }),
      providesTags: () => [{ type: 'invoice-visa' }],
    }),

    postInvoiceVisa: build.mutation<HTTPResponse<void>, InvoiceVisaPost>({
      query: (body) => ({
        url: '/invoice-visa',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'invoice-visa',
        NotificationConst,
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        { type: 'Dashboard', id: DASHBOARD },
      ],
    }),

    editInvoiceVisa: build.mutation<
      HTTPResponse<void>,
      { body: InvoiceVisaEdit; id: number }
    >({
      query: ({ body, id }) => ({
        url: `invoice-visa/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        'invoice-visa',
        NotificationConst,
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        { type: 'Dashboard', id: DASHBOARD },
      ],
    }),

    getInvoiceVisaDetails: build.query<
      HTTPResponse<IInvoiceVisaDetails>,
      number
    >({
      query: (invoice_id) => ({
        url: `invoice-visa/${invoice_id}`,
      }),
      providesTags: () => [{ type: 'invoice-visa' }],
    }),

    // Void Invoice Visa
    voidVisaInAirTicket: build.mutation<void, voidType>({
      query: (body) => ({
        url: `/invoice-visa/void/${body.id}`,
        method: 'PUT',
        body: body,
      }),

      transformErrorResponse: (response) => {
        if (response.status === 400 && response.data) {
          const { message: err } = response.data as {
            message: string;
            success: boolean;
          };
          message.error(`${err}`);
        } else {
          message.error('Some things went to wrong');
        }
      },
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await queryFulfilled;
        message.success('Void Successfully');
      },

      invalidatesTags: [
        'invoices',
        'invoice-visa',
        NotificationConst,
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),
  }),
});

export const {
  useGetAllInvoiceVisaQuery,
  useLazyGetAllInvoiceVisaQuery,
  useLazyGetInvoiceNoQuery,
  useGetInvoiceNoQuery,
  usePostInvoiceVisaMutation,
  useDeleteInvoiceVisaMutation,
  useEditInvoiceVisaMutation,
  useGetViewInvoiceVisaQuery,
  useGetInvoiceVisaDetailsQuery,
  useLazyGetInvoiceVisaDetailsQuery,
  useVoidVisaInAirTicketMutation,
} = invoiceVisaEndponts;
