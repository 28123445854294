import { Typography } from 'antd';
import { ToWords } from 'to-words';
import { useAppSelector } from '../../app/hooks';

type Props = {
  number: number;
  style?: React.CSSProperties;
};

export const InWord = (value: number) => {
  const currencyName = useAppSelector(
    (state) => state.user?.organization_info?.org_currency
  );

  const toWords = new ToWords({
    localeCode: 'en-BD',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: true,
      currencyOptions: {
        name: currencyName?.toLocaleUpperCase() || '',
        plural: currencyName?.toLocaleUpperCase() || '',
        symbol: '₹',
        fractionalUnit: {
          name: 'Paisa',
          plural: 'Paisa',
          symbol: '',
        },
      },
    },
  });

  return toWords.convert(value || 0);
};

const NumToWord = ({ number, style }: Props) => {
  const currencyName = useAppSelector(
    (state) => state.user?.organization_info?.org_currency
  );

  const toWords = new ToWords({
    localeCode: 'en-BD',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: true,
      currencyOptions: {
        name: currencyName?.toLocaleUpperCase() || '',
        plural: currencyName?.toLocaleUpperCase() || '',
        symbol: '₹',
        fractionalUnit: {
          name: 'Paisa',
          plural: 'Paisa',
          symbol: '',
        },
      },
    },
  });

  return (
    <div style={style}>
      <Typography.Text strong>{toWords.convert(number || 0)}</Typography.Text>
    </div>
  );
};

export default NumToWord;

export const NumToWord_raw = ({ number }: Props): any => {
  const currencyName = useAppSelector(
    (state) => state.user?.organization_info?.org_currency
  );

  const toWords = new ToWords({
    localeCode: 'en-BD',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: true,
      currencyOptions: {
        name: currencyName?.toLocaleUpperCase() || '',
        plural: currencyName?.toLocaleUpperCase() || '',
        symbol: '₹',
        fractionalUnit: {
          name: 'Paisa',
          plural: 'Paisa',
          symbol: '',
        },
      },
    },
  });

  return `${toWords.convert(number || 0)}`;
};
