import { Tag } from 'antd'

export const GetPayMethod = ({ field }: { field: string }) => {

    switch (field.toLowerCase()) {
        case "cash":
            return <Tag color="#2db7f5">   {field}</Tag>
        case "bank":
            return <Tag color="#87d068">   {field}</Tag>
        case "mobile banking":
            return <Tag color="#108ee9">   {field}</Tag>
        default:
            return <Tag color="#f50">   {field}</Tag>
    }
}