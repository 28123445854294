import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { SinglePaymentDataType } from '../../LoanTypes/LoanTypes';

export const SinglePaymentList = (): ColumnsType<SinglePaymentDataType> => {
  return [
    {
      title: 'Date',
      key: 'loan_date',
      render: (_, record) => {
        return `${dayjs(record.payment_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Authority Name',
      dataIndex: 'authority_name',
      key: 'authority_name',
    },
    {
      title: 'Loan Name',
      dataIndex: 'loan_name',
      key: 'loan_name',
    },

    {
      title: 'Loan Type',
      dataIndex: 'loan_type',
      key: 'loan_type',
    },
    {
      title: 'Account',
      dataIndex: 'account_name',
      key: 'account_name',
    },

    {
      title: 'Loan Amount',
      dataIndex: 'loan_amount',
      key: 'loan_amount',
      render: (loan_amount) => {
        return Number(loan_amount) || 0;
      },
    },
    {
      title: 'Pay Amount',
      dataIndex: 'payment_amount',
      key: 'payment_amount',
      render: (payment_amount) => {
        return Number(payment_amount) || 0;
      },
    },
    {
      title: 'Due Amount',
      dataIndex: 'loan_due_amount',
      key: 'loan_due_amount',
      render: (loan_due_amount) => {
        return Number(loan_due_amount) || 0;
      },
    },
  ];
};
