import { api } from '../../../../app/baseQuery';
import { formatQueryParams } from '../../../../auth/utils/lib';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { submitType } from '../page/CollectionReport';
import { CollectionType } from '../utils/CollectionTypes';

export const CollectionReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getCollectionReport: build.query<
      HTTPResponse<{
        results: CollectionType[];
        summary: {
          total_amount: number | null;
          total_charge_amount: number | null;
        };
      }>,
      submitType & {
        page: number;
        size: number;
        from_date: string;
        to_date: string;
      }
    >({
      query: ({
        account_id,
        client,
        employee_id,
        search,
        user_id,
        from_date,
        to_date,
        page,
        size,
      }) => {
        const queryParams = formatQueryParams({
          account_id,
          client,
          employee_id,
          search,
          user_id,
          from_date,
          to_date,
        });
        return {
          url: `/report/collection?page=${page}&size=${size}&${queryParams}`,
        };
      },
      providesTags: ['collection_report'],
    }),

    getCollectionExcel: build.query<
      any,
      submitType & {
        from_date: string;
        to_date: string;
      }
    >({
      query: (arg) => {
        const queryParams = formatQueryParams(arg);
        return {
          url: `/report/collection/excel?${queryParams}`,
          method: 'POST',
          // body: {
          //   client_id: client_id,
          // },
          responseHandler: async (response) => {
            const filename = `collection_report (${arg.from_date}-${arg.to_date}).xlsx`;
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          },
          cache: 'no-cache',
        };
      },
      providesTags: [
        { type: 'invoice-other' },
        { type: 'invoices' },
        { type: 'invoice-visa' },
      ],
    }),
  }),
});

export const { useGetCollectionReportQuery, useLazyGetCollectionExcelQuery } =
  CollectionReportEndpoints;
