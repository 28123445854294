import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { INVALIDATE_CLIENT_VENDOR_LEDGER } from '../../Api/constants';
import {
  IDeleteType,
  IVoidDataType,
  VoidDeleteQueryType,
} from '../type/void_and_delete_type';

export const void_and_delete_endpoint = api.injectEndpoints({
  endpoints: (build) => ({
    // Get All Void List
    getAllVoid: build.query<HTTPResponse<IVoidDataType[]>, VoidDeleteQueryType>(
      {
        query: (arg) => ({
          url: `/report/void-invoices?from_date=${arg.from_date}&&to_date=${arg.to_date}&page=${arg.current}&size=${arg.pageSize}`,
        }),

        providesTags: [
          'AirticketInvoice',
          INVALIDATE_CLIENT_VENDOR_LEDGER,
          'invoices',
        ],
      }
    ),

    // Get All Delete List
    getAllDelete: build.query<HTTPResponse<IDeleteType[]>, VoidDeleteQueryType>(
      {
        query: (arg) => ({
          // url: `/report/delete-invoices?from_date=${arg.from_date}&&to_date=${arg.to_date}&page=${arg.current}&size=${arg.pageSize}`,
          url: '/void-invoices?from_date=2021-06-22&&to_date=2023-06-22&page=1&size=50',
        }),
        providesTags: [
          'AirticketInvoice',
          INVALIDATE_CLIENT_VENDOR_LEDGER,
          'invoices',
        ],
      }
    ),
  }),
});

export const {
  useGetAllVoidQuery,
  useLazyGetAllVoidQuery,
  useGetAllDeleteQuery,
} = void_and_delete_endpoint;
