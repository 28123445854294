import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import {
  TableParams,
  filterNullColumns,
  handleTableChange,
  pagination,
  rangePresets,
} from '../../../../common/utils/common.utils';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import { SelectCombined } from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { getDataWithIndex } from '../../../../helpers/getDataWithIndex';
import { useLazyGetSingleCombineClientsQuery } from '../../../Client/combined_clients/api/endpoints/clientCombineEndpoints';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import {
  useLazyGetAllCombinedLedgerQuery,
  useLazyGetExcelQuery,
} from '../endpoints/ledgerEndpoints';
import {
  IClientLedgerFormData,
  ICombineClientLedger,
} from '../types/clientLedgerTypes';
import { CombinedLedger } from '../utils/CombinedLedger.utils';
import { landscapePageStyle } from './ClientLedgers';

type Props = {};
function CombinedLedgerReport({}: Props) {
  const { id } = useParams();

  const searchParams = new URLSearchParams(location.search);
  const combined_id = searchParams.get('id');

  const [form] = Form.useForm();
  const [date_range_value, setDate_range_value] = useState<string>();
  const [combID, setCombId] = useState<string>(id as string);
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: '',
    query: '',
  });
  const [fetchExcel] = useLazyGetExcelQuery();
  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
  }, []);
  useEffect(() => {
    if (id || combined_id) {
      form.setFieldValue('date_range', [
        dayjs().subtract(1, 'months'),
        dayjs(),
      ]);
      form.setFieldValue('client_id', Number(id || combined_id));
    }
    (id || combined_id) && form.submit();
  }, [id, combined_id]);

  const [
    fetchcombibedLedger,
    {
      data: combibedLedger,
      isLoading: clientFetchLoading,
      isFetching,
      isSuccess: clientFetchSuccess,
    },
  ] = useLazyGetAllCombinedLedgerQuery();

  const [
    fetchSingleCombinedDetails,
    { data: combinedDetails, isLoading: isCombinedLoading },
  ] = useLazyGetSingleCombineClientsQuery();

  const combinedLedgerOnFinish = async (values: IClientLedgerFormData) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;

    const body: IClientLedgerFormData = {
      client_id: id ? Number(id) : (values.client_id as number),
      date_range: date_range,
    };

    setDate_range_value(date_range);
    fetchcombibedLedger({
      client_id: body.client_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    }),
      combID && fetchSingleCombinedDetails(Number(combID));
    setExcelQuery({
      Id: body.client_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };

  //pagination
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(combibedLedger?.count || 0);
  }, [combibedLedger]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 50 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['50', '100', '200', '500'],
      pageSize: 50,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IClientLedgerFormData = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body: IClientLedgerFormData = {
      client_id: id ? Number(id) : (values.client_id as number),
      date_range: date_range,
    };

    fetchcombibedLedger({
      client_id: body.client_id,
      query: `${query}&${body.date_range}`,
    });
    setExcelQuery({
      Id: body.client_id.toString(),
      query: `${query}&${body.date_range}`,
    });
  };
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Combined_Ledger_${date_range_value}`,
    pageStyle: landscapePageStyle,
  });

  const combinedInfoData = [
    {
      key: '1',
      name: 'Name',
      value: combinedDetails?.data && combinedDetails?.data?.combine_name,
    },
    {
      key: '2',
      name: 'Email',
      value: combinedDetails?.data && combinedDetails?.data?.combine_email,
    },
    {
      key: '3',
      name: 'Mobile',
      value: combinedDetails?.data && combinedDetails?.data?.combine_mobile,
    },
  ];
  const combinedColumn = [
    {
      dataIndex: 'name',
      key: 'name',
      width: '150px',
    },
    {
      dataIndex: 'value',
      key: 'value',
      width: '120px',
      render: (text: string, record: any) => {
        if (record.key === '5') {
          return (
            <span
              style={{
                color:
                  Number(combinedDetails?.data?.combine_lastbalance_amount) > 0
                    ? 'green'
                    : 'red',
              }}
            >
              {text}
            </span>
          );
        } else {
          return text;
        }
      },
    },
  ];

  // -------//-----------//-------HANDLE PRINT-----------//--------------//----

  const filteredLedColumns = filterNullColumns(
    getDataWithIndex(combibedLedger?.data),
    CombinedLedger(queryData)
  );

  const printFilteredLedColumns = filterNullColumns(
    getDataWithIndex(combibedLedger?.data),
    CombinedLedger(queryData, true)
  );

  const ledger_content = (columns: ColumnsType<ICombineClientLedger>) =>
    combibedLedger?.data?.length ? (
      <>
        <Typography.Text style={{ margin: 10 }}>
          You have total: {count} rows
        </Typography.Text>
        <Table
          size='small'
          bordered
          className='invoiceBillingTable'
          rowClassName={'invoiceBillingTd'}
          pagination={count > 50 ? tableParams.pagination : false}
          dataSource={getDataWithIndex(combibedLedger?.data)}
          columns={columns}
          scroll={{ x: 400 }}
          loading={{
            spinning: clientFetchLoading || isFetching,
            indicator: loadingIndicator,
          }}
          onChange={(args1, args2, args3) =>
            handleTableChange({
              args: {
                pagination: args1,
                filters: args2,
                sorter: args3,
              },
              states: {
                refetch,
                setQueryData,
                setTableParams,
                tableParams,
              },
            })
          }
        />{' '}
      </>
    ) : (
      <></>
    );

  // @HANDLE PRINT WITH HEADER FOOTER
  const combined_info = combinedDetails?.data;
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={ledger_content(printFilteredLedColumns.col)}
        printRef={componentRef}
        extraInfo={{
          address: combined_info?.combine_name || '',
          email: combined_info?.combine_email || '',
          mobile: combined_info?.combine_mobile || '',
          name: combined_info?.combine_name || '',
        }}
        title={{ info_title: 'Comb Vendor Details', title: 'Combined Ledger' }}
      />
    </div>
  );
  return (
    <div>
      {!id && <BreadCrumb arrOfOption={['Report', 'Combined Ledger']} />}
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            if (combibedLedger?.data?.length)
              fetchExcel({
                client_id: excelQuery.Id,
                query: excelQuery.query,
                excelApiName: 'combined_ledger_excel',
                excelName: 'Combined_ledger',
              });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>

      <Row justify={'space-between'} gutter={[16, 16]}>
        <Form layout='vertical' form={form} onFinish={combinedLedgerOnFinish}>
          <Row gutter={16}>
            <SelectCombined
              required
              name='client_id'
              size={10}
              disabled={id ? true : false}
              label='Select Combine'
              placeholder='Select Client'
              setCombId={setCombId}
              offDropDown
            />
            <Col span={6} xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                label='Date Range'
                name='date_range'
                rules={[
                  { required: true, message: ` Date Range is required!` },
                ]}
              >
                <DatePicker.RangePicker
                  presets={rangePresets}
                  format={'DD-MM-YYYY'}
                  allowClear={false}
                  // onChange={onDateRangeChange}
                />
              </Form.Item>
            </Col>
            <Col lg={2} xs={2} sm={2} md={2}>
              <FormButton
                label='Search'
                icon
                textAlign='left'
                loading={clientFetchLoading || isFetching}
              />
            </Col>
          </Row>
        </Form>
        {combinedDetails?.data && (
          <Col lg={8} xl={8} className='p-5'>
            <FormHeaderTitle title='Combined Reports Details' />
            <Table
              className='invoiceBillingTable'
              bordered
              rowClassName={'invoiceBillingTd'}
              showHeader={false}
              size='small'
              pagination={pagination(combinedInfoData.length)}
              columns={combinedColumn}
              dataSource={combinedInfoData}
              locale={{ emptyText: ' ' }}
              loading={{
                spinning: isCombinedLoading,
                indicator: loadingIndicator,
              }}
            />
          </Col>
        )}
      </Row>

      <div className='p-5 my-20 '>
        {/* @LEDGET CONTENT */}
        {ledger_content(filteredLedColumns.col)}
      </div>

      {/* @PRINT CONTENT*/}
      {print_content}
    </div>
  );
}

export default CombinedLedgerReport;
