import { Col, Form, Select } from 'antd';
import { useGetAllRolesQuery } from '../endpoints/userEndpoints';

type Props = { name: string; label: string; required?: boolean; size?: number };
export const SelectRoles = ({ name, label, required, size }: Props) => {
  // ================= role children
  const { data: roles } = useGetAllRolesQuery();
  const selectRoles = roles?.data;
  const rolesChildren: React.ReactNode[] = [];
  if (selectRoles) {
    for (let i = 0; i < selectRoles.length; i++) {
      rolesChildren.push(
        <Select.Option
          key={selectRoles[i].role_name + '  ' + selectRoles[i].role_id}
          value={selectRoles[i].role_id}
        >
          {`${selectRoles[i].role_name}`}
        </Select.Option>
      );
    }
  }
  return (
    <Col span={6} xs={24} sm={24} md={24} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: `${label} is required` }]}
      >
        <Select
          placeholder='Select user role'
          showSearch
          style={{ padding: '0', margin: '0', border: '0' }}
          optionFilterProp='roleMobile'
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          allowClear
        >
          {rolesChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};
