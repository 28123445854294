import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Row,
  Space,
  Table,
  Typography,
  theme,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
  InvoiceHeader,
  TitleCenter,
} from '../../../../common/Invoice/InvoiceHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import NumToWord from '../../../../components/number_to_words/NumToWord';
import { useGetSingleLoanQuery } from '../../Api/Endpoints/LoanEndpoints';
import { SingleLoanDataType } from '../../LoanTypes/LoanTypes';
import { ViewLoanList } from '../../Utils/Loan/ViewLoanList';

export const a4sizeStyle: React.CSSProperties = {
  minHeight: '11.5in',
  width: '8.27in',
  fontSize: '11px',
  background: '#fff',
  margin: '0px auto',
};

function ViewLoan() {
  const { id } = useParams();
  const { data: singleLoan } = useGetSingleLoanQuery(Number(id));

  const { isLoading, data: singleLoanTableData } = useGetSingleLoanQuery(
    Number(id),
    {
      selectFromResult: (cache) => {
        const data = cache.data?.data;
        const dataToReturn: SingleLoanDataType[] = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            dataToReturn.push({
              ...element,
              key: i + 1,
            });
          }
        }
        return { ...cache, data: dataToReturn };
      },
    }
  );

  const printTime = moment().format('ll LTS');

  const [amount, setAmount] = useState<number>();
  const [name, setName] = useState<string>();
  const [date, setDate] = useState<string>();
  useEffect(() => {
    if (singleLoan?.data && singleLoan?.data[0].loan_payable_amount) {
      setAmount(Number(singleLoan?.data[0].loan_payable_amount));
      setName(singleLoan.data[0].loan_name);
      setDate(dayjs(singleLoan?.data[0].loan_date).format('DD MMM YYYY'));
    }
    if (singleLoan?.data && singleLoan?.data[0].loan_receivable_amount) {
      setAmount(Number(singleLoan?.data[0].loan_receivable_amount));
      setName(singleLoan.data[0].loan_name);
      setDate(dayjs(singleLoan?.data[0].loan_date).format('DD MMM YYYY'));
    }
  }, [singleLoan?.data]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Loan_${name}_${date}`,
  });
  const { Text, Title } = Typography;

  useHotkeys('ctrl + p', (e) => {
    e.preventDefault();
    handlePrint();
  });

  return (
    <div>
      <BreadCrumb arrOfOption={['Dashboard', 'Loan Management', 'View Loan']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/loan'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Loan List
          </Button>
        </Link>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
      </Space>

      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <div ref={componentRef}>
          <div>
            <Card style={a4sizeStyle}>
              <InvoiceHeader />

              <TitleCenter title='Loan' />

              <Row justify={'end'} align='middle' style={{ marginBottom: 10 }}>
                <Col>
                  {singleLoan?.data?.length ? (
                    <Typography
                      style={{
                        fontSize: '12px',
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      Vouchar No : {singleLoan?.data[0]?.loan_vouchar || '----'}
                    </Typography>
                  ) : null}

                  <Text
                    style={{
                      fontFamily: "'Source Sans Pro', sans-serif",
                    }}
                  >
                    Date :
                    {dayjs(
                      singleLoan?.data?.length && singleLoan?.data[0]?.loan_date
                    ).format('DD MMM YYYY')}
                  </Text>
                </Col>
              </Row>

              <div style={{ minHeight: '700px' }}>
                <Table
                  size='small'
                  bordered
                  columns={ViewLoanList()}
                  className='invoiceBillingTable'
                  rowClassName={'invoiceBillingTd'}
                  pagination={false}
                  dataSource={singleLoanTableData}
                  loading={{ spinning: isLoading, indicator: loadingIndicator }}
                />
                <Row style={{ marginTop: '25px' }}>
                  <Typography.Text strong className='changeFont'>
                    <NumToWord number={Number(amount || 0)} />
                  </Typography.Text>
                </Row>
                <Row>
                  <Typography.Text className='changeFont' strong>
                    Note :{singleLoanTableData[0]?.loan_note}
                  </Typography.Text>
                </Row>
              </div>
              {/* ================== signature ===================== */}
              <Row
                className='signature'
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '40px',
                }}
              >
                <Typography.Text
                  className='changeFont'
                  style={{ borderTop: '1px solid black', color: 'black' }}
                >
                  Received By
                </Typography.Text>
                <Typography.Text
                  className='changeFont'
                  style={{ borderTop: '1px solid black', color: 'black' }}
                >
                  Prepared By
                </Typography.Text>
                <Typography.Text
                  className='changeFont'
                  style={{ borderTop: '1px solid black', color: 'black' }}
                >
                  Approved By
                </Typography.Text>
              </Row>
              <Row justify={'center'} style={{ marginTop: '25px' }}>
                <Typography.Text className='changeFont'>
                  {printTime}
                </Typography.Text>
              </Row>
            </Card>
          </div>
        </div>
      </ConfigProvider>
    </div>
  );
}

export default ViewLoan;
