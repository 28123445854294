import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetAppConfig } from '../../modules/Configuration/appConfig/type/appConfig_Type';

const initialState: IGetAppConfig = {
  tac_inv_cs: 'Customer Signature',
  tac_inv_as: 'Authority Signature',
  tac_inv_iw: 'In Word',
  tac_inv_in: 'Invoice No',
  tac_inv_sd: 'Sales Date',
  tac_inv_mob: 'Mobile',
  tac_ait_cal: '1',
  tac_wtr_mark: 0,
  tac_sig_url: '',
  tac_wtr_mark_url: '',
  tac_signtr: 0,
  tac_due_wtr_mark: 0,
  tac_paid_wtr_mark: 0,
  tac_inv_curr_sym: '',
  tac_invoice_footer_note: null,
  tac_airticket_type: null,
  tac_auto_email: 0,
  tac_auto_sms: 0,
  tac_disable_edit: [],
  tac_wk_day: 23,
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Partial<IGetAppConfig>>) => {
      return { ...state, ...action.payload };
    },
    resetData: (state) => {
      return { ...initialState };
    },
  },
});

export const { setData, resetData } = dataSlice.actions;

export default dataSlice.reducer;
