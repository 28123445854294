import { Alert, Button, Card, Descriptions, Form, Popconfirm } from 'antd';
import DescriptionsItem from 'antd/es/descriptions/Item';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectUser } from '../../../../auth/states/userSlice';
import { useCreateTourRefundMutation } from '../../Api/Endpoints/TourPackageRefundEndpoints';
import {
  ITourClientRefundReqBody,
  ITourRefundInvoiceInfo,
  ITourRefundReqBody,
  Itineraries,
  TourRefundGeneric,
} from '../../RefundTypes/RefundTypes';
import TourRefundClientForm from './TourRefundClientForm';
import TourRefundVendorForm from './TourRefundVendorForm';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';

type Props = {
  invoice_id: number;
  refund_date: string;
  data?: ITourRefundInvoiceInfo;
  invoice_category_id: number;
};

const TourRefundForm = ({
  data,
  invoice_id,
  refund_date,
  invoice_category_id,
}: Props) => {
  const user = useSelector(selectUser);

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [createTourRefund, { isError, isLoading, isSuccess }] =
    useCreateTourRefundMutation();

  const onRefundFormFinish = async () => {
    const values = form.getFieldsValue();
    const voucher_no: string = 'TR_REF_' + dayjs().unix();
    let tourRefundVendor: [Itineraries, TourRefundGeneric[]][] = [];
    const client_refund_info: ITourClientRefundReqBody = {
      // ...values.client_refund_info,

      date: dayjs(refund_date).format('YYYY-MM-DD'),
      payment_method: values?.client_refund_info?.payment_method,
      crefund_account_id: values?.client_refund_info?.account_id,
      crefund_payment_type: values?.client_refund_info?.refund_payment_type,
      crefund_total_amount:
        data?.clientInfo && data?.clientInfo[0].client_refund_amount,
      crefund_return_amount:
        values.client_refund_info.refund_payment_type === 'MONEY_RETURN'
          ? values?.client_refund_info?.return_amount
          : 0,
      // crefund_charge_amount: values?.client_refund_info?.total_refund_charge,
      crefund_charge_amount: data?.clientInfo
        ? data?.clientInfo[0]?.client_refund_charge
        : 0,
      withdraw_date: values?.client_refund_info?.withdraw_date
        ? dayjs(values?.client_refund_info?.withdraw_date).format('YYYY-MM-DD')
        : undefined,
    };

    if (values.vendor_refund_info_accm) {
      const itinerary = 'accm';
      const refundVendorBody: [Itineraries, TourRefundGeneric[]] = [
        itinerary,
        values.vendor_refund_info_accm,
      ];
      tourRefundVendor.push([
        refundVendorBody[0],
        refundVendorBody[1].map((item, index) => ({
          ...item,
          vrefund_charge_amount: data?.tourAccms[index]
            .vendor_refund_charge as number,
          vrefund_total_amount: data?.tourAccms[index]
            .vendor_refund_amount as number,
          comb_vendor_id: data?.tourAccms[index].vendor_id as string,
        })),
      ]);
    }
    if (values.vendor_refund_info_food) {
      const itinerary = 'food';
      const refundVendorBody: [Itineraries, TourRefundGeneric[]] = [
        itinerary,
        values.vendor_refund_info_food,
      ];

      tourRefundVendor.push([
        refundVendorBody[0],
        refundVendorBody[1].map((item, index) => ({
          ...item,
          vrefund_charge_amount: data?.tourFoods[index]
            .vendor_refund_charge as number,
          vrefund_total_amount: data?.tourFoods[index]
            .vendor_refund_amount as number,
          comb_vendor_id: data?.tourFoods[index].vendor_id as string,
        })),
      ]);
    }
    if (values.vendor_refund_info_guide) {
      const itinerary = 'guide';
      const refundVendorBody: [Itineraries, TourRefundGeneric[]] = [
        itinerary,
        values.vendor_refund_info_guide,
      ];
      tourRefundVendor.push([
        refundVendorBody[0],
        refundVendorBody[1].map((item, index) => ({
          ...item,
          vrefund_charge_amount: data?.tourGuide[index]
            .vendor_refund_charge as number,
          vrefund_total_amount: data?.tourGuide[index]
            .vendor_refund_amount as number,
          comb_vendor_id: data?.tourGuide[index].vendor_id as string,
        })),
      ]);
    }
    if (values.vendor_refund_info_other_trans) {
      const itinerary = 'other_trans';
      const refundVendorBody: [Itineraries, TourRefundGeneric[]] = [
        itinerary,
        values.vendor_refund_info_other_trans,
      ];
      tourRefundVendor.push([
        refundVendorBody[0],
        refundVendorBody[1].map((item, index) => ({
          ...item,
          vrefund_charge_amount: data?.tourOtherTrans[index]
            .vendor_refund_charge as number,
          vrefund_total_amount: data?.tourOtherTrans[index]
            .vendor_refund_amount as number,
          comb_vendor_id: data?.tourOtherTrans[index].vendor_id as string,
        })),
      ]);
    }
    if (values.vendor_refund_info_ticket) {
      const itinerary = 'ticket';
      const refundVendorBody: [Itineraries, TourRefundGeneric[]] = [
        itinerary,
        values.vendor_refund_info_ticket,
      ];
      tourRefundVendor.push([
        refundVendorBody[0],
        refundVendorBody[1].map((item, index) => ({
          ...item,
          vrefund_charge_amount: data?.tourTicket[index]
            .vendor_refund_charge as number,
          vrefund_total_amount: data?.tourTicket[index]
            .vendor_refund_amount as number,
          comb_vendor_id: data?.tourTicket[index].vendor_id as string,
        })),
      ]);
    }
    if (values.vendor_refund_info_transport) {
      const itinerary = 'transport';
      const refundVendorBody: [Itineraries, TourRefundGeneric[]] = [
        itinerary,
        values.vendor_refund_info_transport,
      ];
      tourRefundVendor.push([
        refundVendorBody[0],
        refundVendorBody[1].map((item, index) => ({
          ...item,
          vrefund_charge_amount: data?.tourTransports[index]
            .vendor_refund_charge as number,
          vrefund_total_amount: data?.tourTransports[index]
            .vendor_refund_amount as number,
          comb_vendor_id: data?.tourTransports[index].vendor_id as string,
        })),
      ]);
    }

    const body: ITourRefundReqBody = {
      ...values,
      voucher_no,
      comb_client: data?.invoice_combclient_id,
      created_by: user?.user_id as number,
      invoice_id: invoice_id,
      invoice_category_id: invoice_category_id,
      itineraries: tourRefundVendor,
      client_refund_info: client_refund_info,
      date: dayjs(refund_date).format('YYYY-MM-DD'),
    };

    delete body.vendor_refund_info_accm;
    delete body.vendor_refund_info_food;
    delete body.vendor_refund_info_guide;
    delete body.vendor_refund_info_other_trans;
    delete body.vendor_refund_info_ticket;
    delete body.vendor_refund_info_transport;
    await createTourRefund(body);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate('/refund/history/tour-package');
    }
  }, [isLoading]);

  return (
    <div>
      <Form form={form} layout='vertical'>
        <FormHeaderTitle title='Client Refund Information :' />
        <Card>
          <Descriptions
            size='small'
            bordered
            column={1}
            style={{ marginBottom: '1rem' }}
          >
            <DescriptionsItem label='Client Name'>
              {data?.client_name}
            </DescriptionsItem>
            <DescriptionsItem label='Total Refund'>
              {data?.clientInfo && data?.clientInfo[0].client_refund_amount}
            </DescriptionsItem>
            <DescriptionsItem label='Refund Charge'>
              {data?.clientInfo && data?.clientInfo[0].client_refund_charge}
            </DescriptionsItem>
            <DescriptionsItem label='Net Refund Amount'>
              {data?.clientInfo && data?.clientInfo[0].client_return_amount}
            </DescriptionsItem>
            <DescriptionsItem label='Client Last Balance'>
              {data?.client_last_balance}
            </DescriptionsItem>
          </Descriptions>
          {form.getFieldValue(['client_refund_info', 'refund_payment_type']) ===
          'MONEY_RETURN' ? (
            <Alert
              message='Money Return can be manually refunded'
              style={{ marginBottom: '1rem' }}
            />
          ) : (
            <></>
          )}
          <TourRefundClientForm
            form={form}
            clientLastBalance={data?.client_last_balance}
            clientRefundTotal={
              data?.clientInfo && data?.clientInfo[0].client_return_amount
            }
            clientRefundChargeTotal={
              data?.clientInfo && data?.clientInfo[0].client_refund_charge
            }
          />
        </Card>
        <FormHeaderTitle title='Vendor Refund Information :' />
        {data?.tourAccms.length ? (
          <Card title='Accomodation Refund Information'>
            {data?.tourAccms.map((item, index) => (
              <React.Fragment key={index}>
                <Descriptions
                  size='small'
                  bordered
                  column={1}
                  style={{ marginBottom: '1rem' }}
                >
                  <DescriptionsItem label='Vendor Name'>
                    {item.vendor_name}
                  </DescriptionsItem>
                  <DescriptionsItem label='Total Refund'>
                    {item.vendor_refund_amount}
                  </DescriptionsItem>
                  <DescriptionsItem label='Refund Charge'>
                    {item.vendor_refund_charge}
                  </DescriptionsItem>
                  <DescriptionsItem label='Net Refund Amount'>
                    {item.vendor_return_amount}
                  </DescriptionsItem>
                  <DescriptionsItem label='Vendor Last Balance'>
                    {item.vendor_last_balance}
                  </DescriptionsItem>
                </Descriptions>
                {form.getFieldValue([
                  'vendor_refund_info',
                  index,
                  'refund_payment_type',
                ]) === 'MONEY_RETURN' ? (
                  <Alert
                    message='Money Return can be manually refunded'
                    style={{ marginBottom: '1rem' }}
                  />
                ) : (
                  <></>
                )}
                <TourRefundVendorForm
                  index={index}
                  form={form}
                  name='accm'
                  vendorLastBalance={Number(item.vendor_last_balance)}
                  vendorRefundTotal={item.vendor_return_amount}
                  vendorcharge={item.vendor_refund_charge}
                />
              </React.Fragment>
            ))}
          </Card>
        ) : (
          <></>
        )}
        {data?.tourFoods.length ? (
          <Card title='Food Refund Information'>
            {data?.tourFoods.map((item, index) => (
              <React.Fragment key={index}>
                <Descriptions
                  size='small'
                  bordered
                  column={1}
                  style={{ marginBottom: '1rem' }}
                >
                  <DescriptionsItem label='Vendor Name'>
                    {item.vendor_name}
                  </DescriptionsItem>
                  <DescriptionsItem label='Total Refund'>
                    {item.vendor_refund_amount}
                  </DescriptionsItem>
                  <DescriptionsItem label='Refund Charge'>
                    {item.vendor_refund_charge}
                  </DescriptionsItem>
                  <DescriptionsItem label='Net Refund Amount'>
                    {item.vendor_return_amount}
                  </DescriptionsItem>
                  <DescriptionsItem label='Vendor Last Balance'>
                    {item.vendor_last_balance}
                  </DescriptionsItem>
                </Descriptions>
                {form.getFieldValue([
                  'vendor_refund_info',
                  index,
                  'refund_payment_type',
                ]) === 'MONEY_RETURN' ? (
                  <Alert
                    message='Money Return can be manually refunded'
                    style={{ marginBottom: '1rem' }}
                  />
                ) : (
                  <></>
                )}
                <TourRefundVendorForm
                  index={index}
                  name='food'
                  form={form}
                  vendorLastBalance={Number(item.vendor_last_balance)}
                  vendorRefundTotal={item.vendor_return_amount}
                  vendorcharge={item.vendor_refund_charge}
                />
              </React.Fragment>
            ))}
          </Card>
        ) : (
          <></>
        )}
        {data?.tourGuide.length ? (
          <Card title='Guide Refund Information'>
            {data?.tourGuide.map((item, index) => (
              <React.Fragment key={index}>
                <Descriptions
                  size='small'
                  bordered
                  column={1}
                  style={{ marginBottom: '1rem' }}
                >
                  <DescriptionsItem label='Vendor Name'>
                    {item.vendor_name}
                  </DescriptionsItem>
                  <DescriptionsItem label='Total Refund'>
                    {item.vendor_refund_amount}
                  </DescriptionsItem>
                  <DescriptionsItem label='Refund Charge'>
                    {item.vendor_refund_charge}
                  </DescriptionsItem>
                  <DescriptionsItem label='Net Refund Amount'>
                    {item.vendor_return_amount}
                  </DescriptionsItem>
                  <DescriptionsItem label='Vendor Last Balance'>
                    {item.vendor_last_balance}
                  </DescriptionsItem>
                </Descriptions>
                {form.getFieldValue([
                  'vendor_refund_info',
                  index,
                  'refund_payment_type',
                ]) === 'MONEY_RETURN' ? (
                  <Alert
                    message='Money Return can be manually refunded'
                    style={{ marginBottom: '1rem' }}
                  />
                ) : (
                  <></>
                )}
                <TourRefundVendorForm
                  index={index}
                  name='guide'
                  form={form}
                  vendorLastBalance={Number(item.vendor_last_balance)}
                  vendorRefundTotal={item.vendor_return_amount}
                  vendorcharge={item.vendor_refund_charge}
                />
              </React.Fragment>
            ))}
          </Card>
        ) : (
          <></>
        )}
        {data?.tourOtherTrans.length ? (
          <Card title='Other Transport Refund Information'>
            {data?.tourOtherTrans.map((item, index) => (
              <React.Fragment key={index}>
                <Descriptions
                  size='small'
                  bordered
                  column={1}
                  style={{ marginBottom: '1rem' }}
                >
                  <DescriptionsItem label='Vendor Name'>
                    {item.vendor_name}
                  </DescriptionsItem>
                  <DescriptionsItem label='Total Refund'>
                    {item.vendor_refund_amount}
                  </DescriptionsItem>
                  <DescriptionsItem label='Refund Charge'>
                    {item.vendor_refund_charge}
                  </DescriptionsItem>
                  <DescriptionsItem label='Net Refund Amount'>
                    {item.vendor_return_amount}
                  </DescriptionsItem>
                  <DescriptionsItem label='Vendor Last Balance'>
                    {item.vendor_last_balance}
                  </DescriptionsItem>
                </Descriptions>
                {form.getFieldValue([
                  'vendor_refund_info',
                  index,
                  'refund_payment_type',
                ]) === 'MONEY_RETURN' ? (
                  <Alert
                    message='Money Return can be manually refunded'
                    style={{ marginBottom: '1rem' }}
                  />
                ) : (
                  <></>
                )}
                <TourRefundVendorForm
                  index={index}
                  name='other_trans'
                  form={form}
                  vendorLastBalance={Number(item.vendor_last_balance)}
                  vendorRefundTotal={item.vendor_return_amount}
                  vendorcharge={item.vendor_refund_charge}
                />
              </React.Fragment>
            ))}
          </Card>
        ) : (
          <></>
        )}
        {data?.tourTicket.length ? (
          <Card title='Ticket Refund Information'>
            {data?.tourTicket.map((item, index) => (
              <React.Fragment key={index}>
                <Descriptions
                  size='small'
                  bordered
                  column={1}
                  style={{ marginBottom: '1rem' }}
                >
                  <DescriptionsItem label='Vendor Name'>
                    {item.vendor_name}
                  </DescriptionsItem>
                  <DescriptionsItem label='Total Refund'>
                    {item.vendor_refund_amount}
                  </DescriptionsItem>
                  <DescriptionsItem label='Refund Charge'>
                    {item.vendor_refund_charge}
                  </DescriptionsItem>
                  <DescriptionsItem label='Net Refund Amount'>
                    {item.vendor_return_amount}
                  </DescriptionsItem>
                  <DescriptionsItem label='Vendor Last Balance'>
                    {item.vendor_last_balance}
                  </DescriptionsItem>
                </Descriptions>
                {form.getFieldValue([
                  'vendor_refund_info',
                  index,
                  'refund_payment_type',
                ]) === 'MONEY_RETURN' ? (
                  <Alert
                    message='Money Return can be manually refunded'
                    style={{ marginBottom: '1rem' }}
                  />
                ) : (
                  <></>
                )}
                <TourRefundVendorForm
                  index={index}
                  name='ticket'
                  form={form}
                  vendorLastBalance={Number(item.vendor_last_balance)}
                  vendorRefundTotal={item.vendor_return_amount}
                  vendorcharge={item.vendor_refund_charge}
                />
              </React.Fragment>
            ))}
          </Card>
        ) : (
          <></>
        )}
        {data?.tourTransports.length ? (
          <Card title='Transport Refund Information'>
            {data?.tourTransports.map((item, index) => (
              <React.Fragment key={index}>
                <Descriptions
                  size='small'
                  bordered
                  column={1}
                  style={{ marginBottom: '1rem' }}
                >
                  <DescriptionsItem label='Vendor Name'>
                    {item.vendor_name}
                  </DescriptionsItem>
                  <DescriptionsItem label='Total Refund'>
                    {item.vendor_refund_amount}
                  </DescriptionsItem>
                  <DescriptionsItem label='Refund Charge'>
                    {item.vendor_refund_charge}
                  </DescriptionsItem>
                  <DescriptionsItem label='Net Refund Amount'>
                    {item.vendor_return_amount}
                  </DescriptionsItem>
                  <DescriptionsItem label='Vendor Last Balance'>
                    {item.vendor_last_balance}
                  </DescriptionsItem>
                </Descriptions>
                {form.getFieldValue([
                  'vendor_refund_info',
                  index,
                  'refund_payment_type',
                ]) === 'MONEY_RETURN' ? (
                  <Alert
                    message='Money Return can be manually refunded'
                    style={{ marginBottom: '1rem' }}
                  />
                ) : (
                  <></>
                )}
                <TourRefundVendorForm
                  index={index}
                  name='transport'
                  form={form}
                  vendorLastBalance={Number(item.vendor_last_balance)}
                  vendorRefundTotal={item.vendor_return_amount}
                  vendorcharge={item.vendor_refund_charge}
                />
              </React.Fragment>
            ))}
          </Card>
        ) : (
          <></>
        )}

        {/* {Permission?.[crud.update] && ( */}
        <Popconfirm
          title='Sure to Confirm Refund?'
          onConfirm={() => {
            onRefundFormFinish();
          }}
        >
          <Button
            style={{ marginTop: '1rem' }}
            type='primary'
            htmlType='submit'
            loading={isLoading}
          >
            Submit
          </Button>
        </Popconfirm>
        {/* } */}
      </Form>
    </div>
  );
};

export default TourRefundForm;
