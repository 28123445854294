export const reportsTags = [
  'ClientLedger',
  'VendorLedger',
  'ClientWiseCollectionAndSales',
  'TotalDueAdvanceClients',
  'VendorWisePaymentAndPurchase',
  'TotalDueAdvanceVendors',
  'MonthlySalesAndEarning',
  'OverAllProfitLoss',
  'SingalProductWiseProfit',
  'ActivityLogs',
  'UserLoginHistory',
  'TrashList',
  'PreRegistration',
  'ClientWisePassenger',
  'VendorCataAndDestination',
  'CountryWiseReport',
  'PassportWiseReport',
  'PassportStatusReport',
  'RefundReport',
  'DateWiseExpenseReport',
  'DailySalesReport',
  'AccountReport',
  'SalesCollectionDue',
  'SalesReportSalesMan',
  'salesReportItemAndSalesman',
  'GDSReport',
  'AitReport',
  'SaleaManCommissionReport',
  'ClientDiscount',
  'JourneyDateWiseClients',
  'ExpenseReport',
  'HeadWiseExpenseReport',
  'ProductWiseProfitLoss',
  'SessionWiseProfitLoss',
  'TicketWiseProfitLoss',
  'VisaWiseProfitLoss',
  'GroupWisePassenerList',
  'MonthlySummary',
  'DailySummary',
  'AllTicketNo',
  'AgentsLedger',
];
export const COMBINED_LEDGER = 'COMBINED_LEDGER';
export const CLIENT_LEDGER = 'CLIENT_LEDGER';
export const INVALIDATE_CLIENT_VENDOR_LEDGER =
  'INVALIDATE_CLIENT_AND_VENDOR_LEDGER';
export const VENDOR_LEDGER = 'VENDOR_LEDGER';
export const AGENTS_LEDGER = 'AGENTS_LEDGER';
export const CLIENTWISECOLLECTIONANDSALES = 'CLIENTWISECOLLECTIONANDSALES';
export const TOTALDUEADVANCECLIENTS = 'TOTALDUEADVANCECLIENTS';
export const TOTALDUEADVANCEAGENTS = 'TOTALDUEADVANCEAGENTS';
export const VENDORWISEPAYMENTANDPURCHASE = 'VENDORWISEPAYMENTANDPURCHASE';
export const TOTALDUEADVANCEVENDORS = 'TOTALDUEADVANCEVENDORS';
export const TOTALDUEADVANCECOMBINED = 'TOTALDUEADVANCECOMBINED';
export const MONTHLYSALESANDEARNING = 'MONTHLYSALESANDEARNING';
export const OVERALLPROFITLOSS = 'OVERALLPROFITLOSS';
export const VISAWISEPROFITLOSS = 'VISAWISEPROFITLOSS';
export const SINGALPRODUCTWISEPROFITLOSS = 'PRODUCTWISEPROFITLOSS';
export const ACTIVITYLOGS = 'ACTIVITYLOGS';
export const USERLOGINHISTORY = 'USERLOGINHISTORY';
export const TRASHLIST = 'TRASHLIST';
export const PREREGISTRATION = 'PREREGISTRATION';
export const CLIENTWISEPASSENGER = 'CLIENTWISEPASSENGER';
export const VENDORCATAANDDESTINATION = 'VENDORCATAANDDESTINATION';
export const COUNTRYWISEREPORT = 'COUNTRYWISEREPORT';
export const PASSPORTWISEREPORT = 'PASSPORTWISEREPORT';
export const PASSPORTSTATUSREPORT = 'PASSPORTSTATUSREPORT';
export const REFUNDREPORT = 'REFUNDREPORT';
export const DATEWISEEXPENSE = 'DATEWISEEXPENSE';
export const ACCOUNTREPORT = 'ACCOUNTREPORT';
export const TOTALDUEADVANCEMPLOYEE = 'TOTALDUEADVANCEMPLOYEE';
export const SALESCOLLECTIONANDDUE = 'SALESCOLLECTIONANDDUE';
export const DAILYSALESREPORT = 'DAILYSALESREPORT';
export const SALESMANSALESREPORT = 'SALESMANSALESREPORT';
export const SALEREPORTITEMANDSALSES = 'SALEREPORTITEMANDSALSES';
export const GDSREPORT = 'GDSREPORT';
export const JOURNEYDATEWISECLIENTS = 'JOURNEYDATEWISECLIENTS';
export const AITREPORT = 'AITREPORT';
export const SALESMANCOMMISSIONREPORT = 'SALESMANCOMMISSIONREPORT';
export const DueAdvanceDetails = 'DueAdvanceDetails';
export const CLIENTDISCOUNT = 'CLIENTDISCOUNT';
export const TAXREPORT = 'TAXREPORT';
export const EXPENSEREPORT = 'EXPENSEREPORT';
export const HEADWISEEXPENSEREPORT = 'HEADWISEEXPENSEREPORT';
export const EMPLOYEEEXPENSEREPORT = 'EMPLOYEEEXPENSEREPORT';
export const SESSIONWISEPROFITLOSS = ' SESSIONWISEPROFITLOSS';
export const PRODUCTWISEPROFITLOSS = ' PRODUCTWISEPROFITLOSS';
export const GROUPWISEPROFITLOSS = ' GROUPWISEPROFITLOSS';
export const TICKETWISEPASSENGERLIST = ' TICKETWISEPASSENGERLIST';
export const TICKETWISEPROFITLOSS = ' TICKETWISEPROFITLOSS';
export const MONTHLYSUMMARY = ' MONTHLYSUMMARY';
export const DAILYSUMMARY = ' DAILYSUMMARY';
export const ALLTICKETNO = 'ALLTICKETNO';
export const PURCHASEREPORT = 'PURCHASEREPORT';
export const PAYROLLREPORT = 'PAYROLLREPORT';
