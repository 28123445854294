import { message } from 'antd';
import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { expire } from '../../../../common/utils/constants';
import { NotificationConst } from '../../../../components/notificatioin/Api/NotificationEndpoints';
import {
  IAmountTotal,
  IExpenseDataType,
  IExpenseDeleteRestoreBody,
  IExpenseEditFormData,
  IExpenseFormData,
  IExpenseSingleDataType,
} from '../../ExpenseTypes/Expensetypes';
import { EXPENSE, Expense_LOGS } from '../Constants/Constants';
import { IPaginationSD } from '../../../../common/types/commonInterfaces';
import { DASHBOARD } from '../../../Dashboard/Api/Constants/Constants';

export const expenseEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all expenses
    getAllExpense: build.query<HTTPResponse<IExpenseDataType[]>, void>({
      query: () => ({ url: `/expense` }),
      providesTags: [{ type: 'Expense', id: EXPENSE }],
    }),

    getAllExpenses: build.query<
      HTTPResponse<IExpenseDataType[]>,
      IPaginationSD
    >({
      query: (query) => ({
        url: `/expense?page=${query.current}&size=${query.pageSize}&from_date=${query.from}&to_date=${query.to}&search=${query.search}`,
      }),
      providesTags: [{ type: 'Expense', id: EXPENSE }],
    }),

    // get all trashed expenses
    getAllTrashedExpense: build.query<HTTPResponse<IExpenseDataType[]>, void>({
      query: () => ({ url: `/expense/trashed` }),
      providesTags: [{ type: 'Expense', id: EXPENSE }],
    }),

    // get single expense details
    getExpenseDetails: build.query<HTTPResponse<IAmountTotal[]>, number>({
      query: (id) => ({ url: `/expense/expense-infos/${id}` }),
      providesTags: [{ type: 'Expense', id: EXPENSE }],
    }),

    // get single expense by expense id
    getSingleExpense: build.query<HTTPResponse<IExpenseSingleDataType>, number>(
      {
        query: (id) => ({ url: `/expense/${id}` }),
        keepUnusedDataFor: expire.min,
        providesTags: [{ type: 'Expense', id: EXPENSE }],
      }
    ),
    //create expense
    createExpense: build.mutation<any, FormData>({
      query: (body) => ({
        url: '/expense',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: () => [
        { type: 'Expense', id: EXPENSE },
        { type: 'Expense', id: Expense_LOGS },
        { type: 'Accounts' },
        { type: 'Dashboard', id: DASHBOARD },
        NotificationConst,
      ],
    }),

    //edit expense
    editExpense: build.mutation<void, { data: FormData; id: number }>({
      query: ({ data, id }) => ({
        url: `/expense/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: () => [
        { type: 'Expense', id: EXPENSE },
        { type: 'Expense', id: Expense_LOGS },
        { type: 'Accounts' },
        NotificationConst,
      ],
    }),

    //delete expense
    deleteExpense: build.mutation<
      void,
      { id: number; IExpenseDeleteBody: IExpenseDeleteRestoreBody }
    >({
      query: (body) => ({
        url: `/expense/${body.id}`,
        method: 'DELETE',
        body: body.IExpenseDeleteBody,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Expense Deleted Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Expense', id: EXPENSE },
        { type: 'Expense', id: Expense_LOGS },
        { type: 'Accounts' },
        { type: 'Dashboard', id: DASHBOARD },
        NotificationConst,
      ],
    }),

    //restore expense
    restoreExpense: build.mutation<
      void,
      { id: number; IExpenseDeleteBody: IExpenseDeleteRestoreBody }
    >({
      query: (body) => ({
        url: `/expense/restore/${body.id}`,
        method: 'PATCH',
        body: body.IExpenseDeleteBody,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Expense Restored Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Expense', id: EXPENSE },
        { type: 'Expense', id: Expense_LOGS },
        { type: 'Accounts' },
        NotificationConst,
      ],
    }),
  }),
});

export const {
  useGetAllExpenseQuery,
  useGetAllExpensesQuery,
  useLazyGetAllTrashedExpenseQuery,
  useGetSingleExpenseQuery,
  useGetExpenseDetailsQuery,
  useCreateExpenseMutation,
  useEditExpenseMutation,
  useDeleteExpenseMutation,
  useRestoreExpenseMutation,
} = expenseEndpoints;
