import { Button, Col, Divider, Row } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import {
  useGetAlreadyLoginInfoQuery,
  useRemoveLoginDeviceMutation,
} from '../../app/baseQuery';

type Props = {
  setManyDevice: React.Dispatch<React.SetStateAction<boolean>>;
  user_id: number | undefined;
};

const TooManyLogin = ({ setManyDevice, user_id }: Props) => {
  const [removeDevice] = useRemoveLoginDeviceMutation();

  const { data } = useGetAlreadyLoginInfoQuery(
    { user_id: user_id! },
    { skip: user_id ? false : true }
  );

  return (
    <div
      style={{
        background: 'white',
        boxShadow: '0 0 40px rgba(0, 0, 0, 0.16)',
        borderRadius: '12px',
        padding: '30px',
        maxWidth: '1000px',
      }}
    >
      <Row justify={'space-between'} align={'middle'} gutter={24}>
        <Col xl={12}>
          <h1
            style={{
              fontSize: '24px',
              fontWeight: 'bold',
              marginBottom: '20px',
            }}
          >
            Multiple Device Login Attempts!
          </h1>
          <p style={{ fontSize: '16px', color: '#555', lineHeight: '1.6' }}>
            Multiple device logins detected! You can log in on up to 3 devices
            at a time. Please log out from other devices to continue. Manage
            your active sessions from here.
          </p>

          <Button onClick={() => setManyDevice(false)} type='primary'>
            Back to Login
          </Button>
        </Col>

        <Col hidden={data?.data?.length ? false : true} xl={12}>
          <div className='device-list'>
            {data?.data?.map((device, index) => (
              <Row
                key={index}
                className='device-item'
                justify={'space-between'}
                align={'middle'}
              >
                <Col>
                  <div>
                    <div className='device-name'>{device.login_device}</div>
                    <div className='device-details'>
                      <div>
                        <strong>Browser:</strong> {device.login_browser}
                      </div>
                      <div>
                        <strong>OS:</strong> {device.login_os}
                      </div>
                    </div>
                  </div>
                  <div className='device-time'>
                    <strong>Last Login:</strong>{' '}
                    {dayjs(device.login_time).format('DD MMM YYYY, HH:mm:ss')}
                  </div>
                </Col>
                <Col>
                  <Button
                    onClick={() =>
                      removeDevice({
                        login_id: device.login_id,
                        body: {
                          is_all_delete: false,
                          login_id: device.login_id,
                          user_id: user_id!,
                        },
                      })
                    }
                    size='small'
                  >
                    Log out
                  </Button>
                </Col>
              </Row>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TooManyLogin;
