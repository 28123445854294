import { Button, Form, message, Modal, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect } from 'react';
import {
  IAddClientInList,
  IAllInvoices,
} from '../../../../common/types/commonInterfaces';
import {
  SelectClients,
  SelectEmployee,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import { useAddClientInListMutation } from '../../api/endpoints/airticketInvoiceEndpoints';
import { separateClient } from '../../../../common/utils/common.utils';

type Props = {
  setAddClientModal: React.Dispatch<React.SetStateAction<boolean>>;
  addClientModal: boolean;
  invoiceInfo: IAllInvoices | undefined;
};

const AddClientListInvoice = ({
  addClientModal,
  setAddClientModal,
  invoiceInfo,
}: Props) => {
  const [form] = useForm();
  console.log(invoiceInfo);

  const [addClient, { isError, isSuccess, isLoading }] =
    useAddClientInListMutation();

  const handleOk = () => {
    setAddClientModal(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setAddClientModal(false);
    form.resetFields();
  };

  const submit = (e: IAddClientInList) => {
    const body: IAddClientInList = {
      invoice_id: invoiceInfo?.invoice_id!,
      comb_client: e.comb_client,
      invoice_sales_man_id: e.invoice_sales_man_id,
    };
    addClient(body);
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Successfully added');
      handleOk();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (invoiceInfo) {
      const { client_id, combined_id } = separateClient(
        invoiceInfo?.comb_client
      );
      form.setFieldsValue({
        invoice_sales_man_id: invoiceInfo?.invoice_sales_man_id,
      });
      if (client_id)
        form.setFieldsValue({ comb_client: `client-${client_id}` });
      if (combined_id) {
        form.setFieldsValue({ comb_client: `combined-${combined_id}` });
      }
    }
  }, [invoiceInfo]);

  return (
    <>
      <Modal
        title={`INVOICE NO ${invoiceInfo?.invoice_no}`}
        open={addClientModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        width={300}
      >
        <Form layout='vertical' form={form} onFinish={submit}>
          <Row justify={'center'}>
            <SelectClients
              required
              name={'comb_client'}
              size={24}
              offDropDown
              label='Select Client'
              placeholder='Attach client to this invoice'
            />

            <SelectEmployee
              name={'invoice_sales_man_id'}
              size={24}
              offDropDown
              label='Select Salesman'
              placeholder='Attach salesman to this invoice'
            />
          </Row>
          <Row justify={'end'} align={'middle'} style={{ height: 25 }}>
            <Form.Item>
              <Button loading={isLoading} htmlType='submit' type='primary'>
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AddClientListInvoice;
