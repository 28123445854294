import {
  Button,
  Col,
  DatePicker,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
  message,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setModal } from '../../../common/slices/commonSlice';
import { IPermission, crud } from '../../../common/types/commonTypes';
import CheckImageOrPdf from '../../../common/utils/CheckImageOrPdf';
import { removeSpaceBeforeWord } from '../../../components/common/Invoice/InvoiceListHeader';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import {
  useDeleteEmployeeExpenseMutation,
  useGetAllPayrollQuery,
  useLazyGetForEditPayrollQuery,
} from '../api/endpoints/payrollEndpoints';
import AddEmployeeExpense from '../modals/AddPayroll';
import { IPayrollGetAll } from '../types/PayrollTypes';
import { rangePresets } from '../../../common/utils/common.utils';
type Props = { permission?: IPermission | undefined };

export default function Payroll({ permission }: Props) {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const [editOrAdd, setEditOrAdd] = useState<{
    reason: 'ADD' | 'EDIT';
    id?: number;
  }>({ reason: 'ADD' });

  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  // GET ALL
  const {
    data: payrollData,
    isLoading: payrollIsLoading,
    isFetching: isFetchingPayroll,
    refetch,
  } = useGetAllPayrollQuery({
    ...pagination,
    from: date[0] || '',
    to: date[1] || '',
    search: search,
  });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 50,
    }));
  }, [date, search]);

  // Handle Search Operation
  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  // GET FOR EDIT
  const [
    getForEditPayroll,
    { data: getForEditData, isFetching, isSuccess, error, isLoading },
  ] = useLazyGetForEditPayrollQuery();

  // DELETE
  const [
    deleteEmployeeExpense,
    {
      isLoading: deleteIsLoading,
      isSuccess: deleteIsSuccess,
      data: deleteData,
      isError: deleteIsError,
    },
  ] = useDeleteEmployeeExpenseMutation();
  useEffect(() => {
    if (deleteIsSuccess) {
      setColumnIndex(null);
      message.success(deleteData?.message || 'Deleted');
    } else if (deleteIsError) {
      setColumnIndex(null);
    }
  }, [deleteIsSuccess, deleteIsError]);
  useEffect(() => {
    if (deleteIsError) {
      message.error('Delete faild!');
    }
  }, [deleteIsError]);
  // HANDLE RESTORE BUTTON
  const [checkButtonState, setCheckButtonState] = useState(false);

  const columns: ColumnsType<IPayrollGetAll> = [
    {
      title: 'Sl.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
      align: 'center',
    },
    {
      title: 'Vouchar No.',
      dataIndex: 'payroll_vouchar_no',
      key: 'payroll_vouchar_no',
    },
    {
      title: 'Salary Date',
      dataIndex: 'payroll_date',
      key: 'payroll_date',
      render: (_, __) => <>{dayjs(__.payroll_date).format('DD-MM-YYYY')}</>,
    },
    {
      title: 'Employee Name',
      dataIndex: 'employee_full_name',
      key: 'employee_full_name',
    },

    {
      title: 'Designation',
      dataIndex: 'designation_name',
      key: 'designation_name',
    },

    {
      title: 'Pay. Method',
      dataIndex: 'payroll_pay_method',
      key: 'payroll_pay_type',
    },

    {
      title: 'Account Name',
      dataIndex: 'bank_account_name',
      key: 'bank_account_name',

      render: (_, record) => {
        let color = record.bank_account_name === 'DEPOSIT' ? 'green' : 'red';
        function status(record: any) {
          if (
            record.bank_account_name == 'DEPOSIT' ||
            record.bank_account_name == 'PENDING' ||
            record.bank_account_name == 'BOUNCE'
          ) {
            return <span style={{ color }}> {record.bank_account_name} </span>;
          } else {
            return `${record.bank_account_name}`;
          }
        }

        return status(record);
      },
    },
    {
      title: 'Base Salary',
      dataIndex: 'payroll_salary',
      key: 'payroll_salary',
      render: (_, data) => <>{Number(data.payroll_salary)}</>,
    },
    {
      title: 'Attendance',
      dataIndex: 'payroll_attendance',
      key: 'payroll_attendance',
    },
    {
      title: 'Gross Salary',
      dataIndex: 'gross_salary',
      key: 'gross_salary',
      render: (_, data) => <>{Number(data.gross_salary)}</>,
    },
    {
      title: 'Total Salary',
      dataIndex: 'payroll_net_amount',
      key: 'payroll_net_amount',
      render: (_, data) => <>{Number(data.payroll_net_amount)}</>,
    },
    {
      title: 'Image',
      dataIndex: 'payroll_image_url',
      key: 'payroll_image_url',
      render: (_, data) =>
        data.payroll_image_url && (
          <CheckImageOrPdf url={String(data.payroll_image_url) || ''} />
        ),
    },

    {
      title: 'Actions',
      render: (_, __, index) => {
        return (
          <>
            <Space size='small'>
              <Link to={`/payroll/${__.payroll_id}`} state={location.pathname}>
                {!checkButtonState && permission?.['read:any'] && (
                  <Button size='small' type='primary'>
                    <Typography style={{ color: '#ffffff' }}>View</Typography>
                  </Button>
                )}
              </Link>

              {permission?.[crud.update] &&
                !checkButtonState &&
                __?.bank_account_name !== 'DEPOSIT' && (
                  <Button
                    size='small'
                    type='primary'
                    onClick={async () => {
                      setEditOrAdd({ reason: 'EDIT', id: __.payroll_id });
                      await getForEditPayroll(__.payroll_id);
                      dispatch(setModal(true));
                    }}
                  >
                    Edit
                  </Button>
                )}

              {permission?.[crud?.delete] && (
                <>
                  {!checkButtonState ? (
                    <Popconfirm
                      onConfirm={() => {
                        if (user?.user_id) {
                          deleteEmployeeExpense({
                            body: {
                              payroll_deleted_by: user.user_id,
                              payroll_account_id: __.payroll_account_id,
                            },
                            id: __.payroll_id,
                          });
                          setColumnIndex(index);
                        }
                      }}
                      title='Sure to delete?'
                    >
                      <Button
                        size='small'
                        type='primary'
                        danger
                        loading={columnIndex === index && true}
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  ) : (
                    ''
                  )}
                </>
              )}
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <PayRollStyle>
      <BreadCrumb
        arrOfOption={['Payroll']}
        refetch={() => refetch()}
        reloaderSize='small'
      />
      <Row justify={'space-between'} style={{ marginBottom: '1rem' }}>
        {permission?.[crud.create] && (
          <AddEmployeeExpense
            setEditOrAdd={setEditOrAdd}
            editOrAdd={editOrAdd}
            getForEditData={getForEditData}
          />
        )}

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Here...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        size='small'
        bordered
        rowKey={(e) => e.payroll_id}
        columns={columns}
        dataSource={payrollData?.data}
        loading={{
          spinning: payrollIsLoading || isFetchingPayroll || isFetching,
          indicator: <LoadingIndicator />,
        }}
        pagination={
          payrollData?.count !== undefined && payrollData?.count < 20
            ? false
            : {
                ...pagination,
                total: payrollData?.count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
      />
    </PayRollStyle>
  );
}

const PayRollStyle = styled.div``;
