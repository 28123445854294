import { Table, TableColumnsType, Typography } from 'antd';
import { invoiceViewStyle } from '../../../Quotation/Components/ViewGenerateInvoice';
import {
  IAirTicketRefundVendorDetails,
  IAirtTicketRefundSingleDataType,
} from '../../RefundTypes/RefundTypes';

const AirTicketRefundDetails = ({
  data,
}: {
  data?: IAirtTicketRefundSingleDataType;
}) => {
  const columns: TableColumnsType<IAirtTicketRefundSingleDataType> = [
    { title: 'Voucher', dataIndex: 'atrefund_vouchar_number' },
    { title: 'Invoice', dataIndex: 'invoice_no' },
    { title: 'Cleint Name', dataIndex: 'client_name' },
    { title: 'Charge', dataIndex: 'crefund_charge_amount' },
    { title: 'Return', dataIndex: 'crefund_return_amount' },
    { title: 'Profit', dataIndex: 'crefund_profit' },
    { title: 'Refund Date', dataIndex: 'atrefund_date' },
    { title: 'Create Date', dataIndex: 'atrefund_create_date' },
  ];
  const itemColumns: TableColumnsType<IAirTicketRefundVendorDetails> = [
    { title: 'Ticket', dataIndex: 'airticket_ticket_no' },
    { title: 'Routes', dataIndex: 'airticket_routes' },
    { title: 'Pnr', dataIndex: 'airticket_pnr' },
    { title: 'Vendor Name', dataIndex: 'vendor_name' },
    { title: 'Total', dataIndex: 'vrefund_total_amount' },
    { title: 'Charge', dataIndex: 'vrefund_charge_amount' },
    { title: 'Return', dataIndex: 'vrefund_return_amount' },
  ];

  return (
    <div style={invoiceViewStyle}>
      <div>
        <Typography.Title level={5} style={{ marginBottom: '5px' }}>
          REFUND INFO
        </Typography.Title>
        <Table
          columns={columns}
          dataSource={data ? [data] : []}
          pagination={false}
        />
        <Typography.Title
          level={5}
          style={{ marginBottom: '5px', marginTop: 20 }}
        >
          REFUND ITEMS
        </Typography.Title>
        <Table
          columns={itemColumns}
          dataSource={data ? data?.refund_info : []}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default AirTicketRefundDetails;
