import { Button, Popconfirm, Row, Space, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';
import useGetSearchColumnProps from '../../../../common/hooks/SearchData';
import { IAllInvoices } from '../../../../common/types/commonInterfaces';
import { IPermission, crud } from '../../../../common/types/commonTypes';
import { isMoallem } from '../../../../components/common/Invoice/InvoiceListHeader';
import { PhoneParser } from '../../../../helpers/PhoneFormatter';
import { useUpdateHajjRegStatusMutation } from '../API/Endpoints/HajjRegEndspoint';
import { useEffect } from 'react';

type Props = {
  pagination: {
    current: number;
    pageSize: number;
  };
  permission: IPermission | undefined;
};

export const Hajj_Reg_Col = ({
  pagination,
  permission,
}: Props): ColumnsType<IAllInvoices> => {
  const searchColumnProps = useGetSearchColumnProps<IAllInvoices>();

  const user = useSelector(selectUser);
  const [updateStatus, { isSuccess, isError }] =
    useUpdateHajjRegStatusMutation();

  useEffect(() => {
    if (isSuccess) {
      message.success('Status Change Successful');
    } else if (isError) {
      message.error('Something went to wrong');
    }
  }, [isSuccess, isError]);

  return [
    {
      title: 'SL.',
      width: 55,
      render: (_, data, index) => (
        <>
          {((pagination?.current || 1) - 1) * (pagination?.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Invoice',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      width: 120,
      ...searchColumnProps('invoice_no'),
    },
    {
      title: 'Sales Date',
      key: 'invoice_sales_date',

      render: (_, data) => (
        <>{moment(data.invoice_sales_date).format('DD MMM YYYY')} </>
      ),
      sorter: (a, b) =>
        moment(a.invoice_sales_date).unix() -
        moment(b.invoice_sales_date).unix(),
      width: 135,
      align: 'center',
    },
    {
      title: !isMoallem ? 'Client' : 'Moallem',
      dataIndex: 'client_name',
      key: 'client_name',
      width: 150,
      align: 'center',
      ...searchColumnProps('client_name'),
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
      width: 160,
      align: 'center',
      ...searchColumnProps('mobile'),
      render: (mobile) => PhoneParser(mobile),
    },
    {
      title: 'Net Total',
      dataIndex: 'net_total',
      key: 'net_toal',
      width: 120,
      align: 'center',
      sorter: (a, b) => Number(a.net_total) - Number(b.net_total),
      render: (_, record) => Number(record.net_total),
    },

    {
      title: 'Action',
      key: 'operation',
      width: 300,
      render: (_, data) => {
        return (
          <Row>
            {permission?.[crud.update] &&
              data.haji_info_status !== 'canceled' && (
                <Space.Compact size='small' style={{ margin: '0 auto' }}>
                  <Popconfirm
                    title={'Sure to Approve'}
                    onConfirm={() =>
                      updateStatus({
                        id: data.invoice_id,
                        status: 'approved',
                        body: { updated_by: Number(user?.user_id) },
                      })
                    }
                  >
                    <Button
                      type='primary'
                      style={{ backgroundColor: '#87d068' }}
                    >
                      Approve
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    title={'Sure to Rejected'}
                    onConfirm={() =>
                      updateStatus({
                        id: data.invoice_id,
                        status: 'canceled',
                        body: { updated_by: Number(user?.user_id) },
                      })
                    }
                  >
                    <Button type='primary' danger size='large'>
                      Reject
                    </Button>
                  </Popconfirm>
                </Space.Compact>
              )}
          </Row>
        );
      },
    },
  ];
};
