import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import useGetSearchColumnProps from '../../../../common/hooks/SearchData';
import { Fixed2, separateClient } from '../../../../common/utils/common.utils';
import { ITicketWiseProfitLossDataType } from '../types/ticketWiseProfitLossTypes';
import { Link } from 'react-router-dom';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';
export const ticketWiseProfitColumn = ({
  pagination,
  paramData,
}: {
  pagination: {
    current: number;
    pageSize: number;
  };
  paramData:
    | {
        date_range: string;
        id: number | string;
      }
    | undefined;
}): ColumnsType<ITicketWiseProfitLossDataType> => {
  const searchColumnProps =
    useGetSearchColumnProps<ITicketWiseProfitLossDataType[]>();
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
      render: (_, record, index) =>
        ((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
        1 +
        index,
    },

    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data) => {
        const { invoice_no, invoice_category_id, invoice_id } = data;
        return (
          <>
            <Link
              to={`../${
                findInvoiceRoute(invoice_category_id) +
                '/' +
                'details' +
                '/' +
                invoice_id
              }`}
            >
              {invoice_no}
            </Link>
          </>
        );
      },
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoice_create_date',
      key: 'invoice_create_date',
      render: (_, record) => {
        return dayjs(record.invoice_create_date).format('YYYY-MM-DD');
      },
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (value, record) => {
        const { client_id, combined_id } = separateClient(
          record?.invoice_combclient_id
        );

        return (
          <Link
            to={
              client_id
                ? `/clients/details/${record?.invoice_combclient_id}`
                : `/combineClients/details/${combined_id}`
            }
          >
            <span>{record.client_name}</span>
          </Link>
        );
      },
    },

    {
      title: 'PNR',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
    },
    {
      title: 'Ticket No',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
    },

    {
      title: 'Passport Name',
      dataIndex: 'passport_name',
      key: 'passport_name',
    },
    {
      title: 'Airline Name',
      dataIndex: 'airline_name',
      key: 'airline_name',
      render: (_, record) => (
        <Link
          to={`/reports/airline_wise_sales_report?${paramData?.date_range}&airline_id=${record.airticket_airline_id}`}
        >
          {' '}
          {record.airline_name}
        </Link>
      ),
    },

    {
      title: 'Sales Amount',
      dataIndex: 'airticket_client_price',
      key: 'airticket_client_price',
      render(value, record, index) {
        return Fixed2(value);
      },
    },

    {
      title: 'Purchase Amount',
      dataIndex: 'airticket_purchase_price',
      key: 'airticket_purchase_price',
      render(value, record, index) {
        return Fixed2(value);
      },
      align: 'right',
    },

    {
      title: 'Profit/Loss',
      dataIndex: 'airticket_profit',
      key: 'airticket_profit',
      align: 'right',
      render(value, record, index) {
        return Fixed2(value);
      },
    },
  ];
};
