import { Button, Popconfirm, Space, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { IPermission, ISetState } from '../../../../common/types/commonTypes';
import { useDeleteExpenseHeadMutation } from '../Endpoints/expenseHeadEndpoints';
import { ExpenseHeadDataType } from '../types/ExpenseHeadTypes';
import useGetSearchColumnProps from '../../../../common/hooks/SearchData';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';

type Props = {
  setEditInfo: ISetState<ExpenseHeadDataType | null>;
  showModal: () => void;
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
};

export const expenseHeadsColumns = ({
  pagination,
  setEditInfo,
  showModal,
  permission,
}: Props): ColumnsType<ExpenseHeadDataType> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const searchColumnProps = useGetSearchColumnProps<ExpenseHeadDataType>();
  const handleClick = (record: ExpenseHeadDataType) => {
    setEditInfo(record);
    showModal();
  };

  const user = useSelector(selectUser);

  const [
    deleteExpenseHead,
    { isError, isSuccess, isLoading: isDeleteLoading },
  ] = useDeleteExpenseHeadMutation();
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Expense Head Deleted Successfully');
    } else if (isError) {
      setColumnIndex(null);
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleDelete = async (value: ExpenseHeadDataType, index: number) => {
    setColumnIndex(index);
    console.log({ index });
    await deleteExpenseHead({
      deleted_by: user?.user_id as number,
      head_id: value.head_id,
    });
  };
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'serial',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Head Name',
      dataIndex: 'head_name',
      key: 'head_name',
      ...searchColumnProps('head_name'),
    },

    {
      title: 'Create Date',
      key: 'head_create_date',
      render: (_, record) => {
        return `${dayjs(record.head_create_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Action',
      key: 'operation',
      render: (_, record, index) => (
        <Space size='small'>
          {permission?.['update:any'] && (
            <Button
              size='small'
              type='primary'
              onClick={() => handleClick(record)}
            >
              Edit
            </Button>
          )}
          {permission?.['delete:any'] && (
            <Button
              danger
              size='small'
              type='primary'
              loading={columnIndex === index && true}
            >
              <Popconfirm
                title='Sure to delete?'
                onConfirm={() => handleDelete(record, index)}
              >
                Delete
              </Popconfirm>
            </Button>
          )}
        </Space>
      ),
    },
  ];
};
